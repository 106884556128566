/* eslint-disable */
import TextArea from 'antd/es/input/TextArea';
import React, { useEffect, useState } from 'react';
import Rcookie from 'react-cookies';
import PropTypes from 'prop-types';
import { Upload } from 'antd';
import SvgLoader from '../../../../common/components/SvgLoader';
import axios from 'axios';

function InputStudyProtocols({ inputHandler, allInputs, isDisabled }) {
  const inputStyleList = ['Textual Input', 'Upload a Document'];
  const [inputStyle, setInputStyle] = useState(inputStyleList[0]);
  const [inputText, setInputText] = useState('');
  const [newFormData, setNewFormData] = useState(null);
  const [isFileSelected, setIsFileSelected] = useState(false);

  useEffect(() => {
    setInputText(allInputs.drugIndication || '');
  }, []);

  useEffect(() => {
    const inputs = {
      drugIndication: inputText,
      isUploadFile: isFileSelected,
    };
    inputHandler(inputs);
  }, [inputText]);

  useEffect(() => {
    const inputs = {
      pairsFile: newFormData,
      isUploadFile: isFileSelected,

    };
    inputHandler(inputs);
  }, [newFormData]);

  const onInputFileChange = (fileList) => {
    if (fileList.length > 1) {
      fileList.shift();
    }
    const files = fileList;
    if (files.length) {
      const formData = new FormData();
      formData.append('file', files[0].originFileObj);
      setIsFileSelected(true);
      setNewFormData(formData);
    }
  };

  const downloadTemplateFile = async () => {
    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = {
      Authorization: Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`) ? Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`) : '',
      'Content-Type': 'blob',
      'Access-Control-Allow-Origin': `${process.env.applicationUrl}`,
    };
    const apiUrl = `${process.env.apiUrl_ctp}/templateDownload`;

    try {
      const response = await axios({
        method: 'GET',
        url: apiUrl,
        responseType: 'arraybuffer',
        headers,
      });
      const outputFilename = 'hctp_input_template.xlsx';

      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      throw Error(error);
    }
  };

  const props = {
    name: 'file',
    accept: '.xls,.xlsx',
    multiple: true,
    progress: true,

    beforeUpload: () => false,
    onChange(info) {
      if (info.file.status !== 'uploading') {
        onInputFileChange(info.fileList);
      }
    },
    onRemove() {
      setIsFileSelected(false);
      setNewFormData('');
    },
  };
  return (
    <div className={`input-study-protocols-main${isDisabled ? ' disabled' : ''}`}>
      <div className="provide-input-container">
        <div className={`input-container ${isDisabled ? 'disabled' : ''}`}>
          <div className="input-fields">
            <div className="select-dataset" style={{ paddingTop: '0px' }}>
              <div className="select-title" style={{ paddingBottom: '10px' }}>
                Select Input Style
              </div>
              <div className="select-dataset-footer">
                <div className="select-dataset-footer-btn">
                  <button type="button" className={`select-button ${inputStyleList[0] === inputStyle ? 'active' : ''}`} onClick={() => setInputStyle(inputStyleList[0])} disabled={isDisabled}>
                    {inputStyleList[0]}
                  </button>
                </div>
                <div className="select-dataset-footer-btn">
                  <button type="button" className={`select-button ${inputStyleList[1] === inputStyle ? 'active' : ''}`} onClick={() => setInputStyle(inputStyleList[1])} disabled={isDisabled}>
                    {inputStyleList[1]}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="provide-input-container">
        <div className={`input-container ${isDisabled ? 'disabled' : ''}`}>
          <div className="input-title-row" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div className="input-container-title">Provide Input</div>
            <div className="try-example">Try Example</div>
          </div>
          {inputStyle === inputStyleList[0] ? <div className="input-fields">
            <div className="input-fields-value">
              <TextArea rows={8} placeholder={'Enter Drug~Indication pairs here, each pair on new line\nLine 1: Drug Name 1 ~ Indication 1\nLine 2: Drug Name 2 ~ Indication 2\nLine 3: Drug Name 3 ~ Indication 3'} type="textarea" onChange={(e) => setInputText(e.target.value)} />
            </div>
          </div> :
            <div className="upload-document-view">
              <div className="upload-document-title">
                # To upload the document, first update your data in the provided template file, and then upload it.
                <span className="download-template-text" style={{ cursor: 'pointer' }} onClick={() => downloadTemplateFile()} aria-hidden>
                  Download the template file.
                </span>
              </div>
              <div className="upload-document">
                <div className={`input-fields-upload${isFileSelected ? ' upload-file-item' : ''}`}>
                  <Upload {...props} showUploadList={newFormData !== ''}>
                    {!isFileSelected && (
                      <p className="ant-upload-drag-icon">
                        <SvgLoader width={216} height={72} svgName="upload-file" />
                      </p>
                    )}
                    {!isFileSelected && (
                      <p className="ant-upload-text">
                        <span className="ant-upload-text-grey">Drag & drop files here or</span>
                        <span className="ant-upload-text-blue">Browse</span>
                      </p>
                    )}
                    {!isFileSelected && <p className="ant-upload-hint">The file should be in .xlsx format only, and the maximum size limit is 50MB.</p>}
                  </Upload>
                </div>
              </div>
            </div>}
        </div>
      </div>
    </div>
  );
}

InputStudyProtocols.propTypes = {
  inputHandler: PropTypes.func.isRequired,
  allInputs: PropTypes.shape({
    drugIndication: PropTypes.string.isRequired,
    indication: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    dataset: PropTypes.string.isRequired,
  }).isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default InputStudyProtocols;
