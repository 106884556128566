import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import InputDropdown from '../../components/InputDropdown';

function InputIndication({ inputHandler, allInputs, isDisabled }) {
  const [indication, setIndication] = useState('');
  const [defaultIndication, setDefaultIndication] = useState('');

  // const handleIdValidation = () => {
  //   setIsEmptyId(indication !== '');
  // };

  const handleChange = (value) => {
    setIndication(value);
  };

  useEffect(() => {
    setIndication(allInputs.indication || '');
    setDefaultIndication(allInputs.indication || '');
  }, []);

  useEffect(() => {
    const inputs = {
      indication,
    };
    inputHandler(inputs);
  }, [indication]);

  return (
    <div className="provide-input-container">
      <div className="input-container">
        <div className="input-container-title">Provide Input</div>
        <div className="input-fields">
          <div className="input-fields-value">
            <div className="dropdown-container">
              <InputDropdown index={0} changeHandler={handleChange} indicationName={defaultIndication} setDefaultIndication={setDefaultIndication} isDisabled={isDisabled} />
            </div>
          </div>
          <div className="input-fields-value try-example-section">
            <text className="try-example-section-title">Try Examples: </text>
            <text
              style={{ cursor: 'pointer', color: 'blue' }}
              onClick={() => {
                setDefaultIndication('Lung Cancer');
              }}
            >
              Lung Cancer
            </text>
            <text style={{ color: 'blue' }}> | </text>
            <text
              style={{ cursor: 'pointer', color: 'blue' }}
              onClick={() => {
                setDefaultIndication('Liver Cancer');
              }}
            >
              Liver Cancer
            </text>
            <text style={{ color: 'blue' }}> | </text>
            <text
              style={{ cursor: 'pointer', color: 'blue' }}
              onClick={() => {
                setDefaultIndication('Asthma');
              }}
            >
              Asthma
            </text>
          </div>
        </div>
      </div>
    </div>
  );
}

InputIndication.propTypes = {
  inputHandler: PropTypes.func.isRequired,
  allInputs: PropTypes.shape({
    indication: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    dataset: PropTypes.string.isRequired,
  }).isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default InputIndication;
