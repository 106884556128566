/* eslint-disable */
import { Button, Modal, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { FullscreenOutlined } from '@ant-design/icons';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import FullScreenPDBViewer from '../../MolViewer/FullScreenPDBViewer';
import Loader from '../../../../../common/components/Loader';
import RawDataTableView from '../../RawDataTableView';
import PDBViewer from '../../MolViewer/PDBViewer';
import useAppSelector from '../../../../../utils/redux-hook';
import { aeGetSequencesAction } from './logic';

function RawDataAndDockingResults({ setPageIndex }) {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [detailedData, setDetailedData] = useState(null);
  const dispatch = useDispatch();
  const aeGetSequencesResponse = useAppSelector((state) => state.aeGetSequencesResponse);

  useEffect(() => {
    if (selectedData) {
      //   setDetailedData(selectedData);
      const request = {
        sequence_name: '123'
      };
      dispatch(aeGetSequencesAction(queryString.stringify(request)));
    }
  }, [selectedData]);

  useEffect(() => {
    if (aeGetSequencesResponse.flag && !aeGetSequencesResponse.loading) {
      setDetailedData(aeGetSequencesResponse?.data?.data);
    }
  }, [aeGetSequencesResponse]);

  return isFullScreen ? (
    <Modal rootClassName="uti-molecule-root-modal" className="uti-molecule-fullscreen-modal" open={isFullScreen} onCancel={() => setIsFullScreen(false)} Header={null} footer={null}>
      <FullScreenPDBViewer id="full-screen" closed={() => setIsFullScreen(false)} selectedData={selectedData} />
    </Modal>
  ) : (
    <div>
      <Loader loading={false} error={null} width="100%" height="1000px">
        <div className="data-container">
          <div className={`data-row ${(selectedData ? 'active' : '')}`}>
            <div className="data-table-container">
              <div className="antibody-engineering-output-main">
                <div className="main-title">Raw data analysis and docking results</div>
                <div className="legends-row">
                  <div className="legend-duplicate">
                    <div className="legend-color-duplicate" />
                    <div className="legend-title">Duplicate</div>
                  </div>
                  <div className="legend-duplicate-different-value">
                    <div className="legend-color-duplicate-different-value" />
                    <div className="legend-title">Duplicate but different value</div>
                  </div>
                  <div className="legend-incorrect">
                    <div className="legend-color-incorrect" />
                    <div className="legend-title">Incorrect</div>
                  </div>
                </div>
              </div>
              <RawDataTableView selectedData={selectedData} setSelectedData={setSelectedData} />
            </div>
            { selectedData && (
              <div className="structure-box-3d">
                <div className="structure-main">
                  <div className="structure-title">3D Structure</div>
                  <div className="structure-fullscreen">
                    <div role="presentation" onClick={() => setIsFullScreen(true)}>
                      <FullscreenOutlined style={{ fontSize: '20px', marginTop: '5px' }} />
                    </div>
                  </div>
                </div>
                <PDBViewer id={0} selectedData={selectedData} />
              </div>
            )}
          </div>
          { selectedData && (
            <div className="data-bottom">
              <div className="dr-output-list inside-view-details">
                <div key={crypto.randomUUID()} className="combinations-output-item">
                  <div className="item-title">Molecular structure details </div>
                  <Loader loading={aeGetSequencesResponse?.loading} error={aeGetSequencesResponse?.error} height="250px">

                    <div className="combinations-item-content">
                      <div className="combinations-drugs-list">
                        <div className="combinations-drugs-info">
                          {/* <div className="info-component-row info-component-row-full">
                          <div className="info-component">
                            <div className="info-component-main">
                              <div className="info-component-heading info-add">
                                PROTAC smile
                                <span className="info-add-icon" />
                              </div>
                              <div className="smile-component">
                                <Tooltip placement="topLeft" title={true ? 'copied' : 'copy'}>
                                  <div className="smile-copy-sec">
                                    <div className="info-copy-btn" style={{ cursor: 'pointer' }} onClick={() => copyToClipboard(individualItem.chemical_data?.smile ?? '-')} />
                                  </div>
                                </Tooltip>
                                <div className="info-component-value">
                                  <Tooltip placement="topLeft" title="individualItem.chemical_data?.smile">
                                    -
                                  </Tooltip>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}
                          <div className="info-component-row">
                            <div className="info-component">
                              <div className="info-component-main">
                                <div className="info-component-heading info-add">
                                  B_hydrophobic_count
                                  {/* <span className="info-add-icon" /> */}
                                </div>
                                <div className="info-component-value">
                                  <Tooltip placement="topLeft" title={0}>
                                    {0}
                                  </Tooltip>
                                </div>
                              </div>
                            </div>
                            <div className="info-component">
                              <div className="info-component-main">
                                <div className="info-component-heading info-add">
                                  B_polar_count
                                  {/* <span className="info-add-icon" /> */}
                                </div>
                                <div className="info-component-value">
                                  <Tooltip placement="topLeft" title={0}>
                                    {0}
                                  </Tooltip>
                                </div>
                              </div>
                            </div>
                            <div className="info-component">
                              <div className="info-component-main">
                                <div className="info-component-heading info-add">
                                  B_weak_polar_count
                                  {/* <span className="info-add-icon" /> */}
                                </div>
                                <div className="info-component-value">
                                  <Tooltip placement="topLeft" title={0}>
                                    {0}
                                  </Tooltip>
                                </div>
                              </div>
                            </div>
                            <div className="info-component">
                              <div className="info-component-main">
                                <div className="info-component-heading info-add">
                                  B_carbonyl_count
                                  {/* <span className="info-add-icon" /> */}
                                </div>
                                <div className="info-component-value">
                                  <Tooltip placement="topLeft" title={0}>
                                    {0}
                                  </Tooltip>
                                </div>
                              </div>
                            </div>
                            <div className="info-component">
                              <div className="info-component-main">
                                <div className="info-component-heading info-add">
                                  B_ionic_count
                                  {/* <span className="info-add-icon" /> */}
                                </div>
                                <div className="info-component-value">
                                  <Tooltip placement="topLeft" title={0}>
                                    {0}
                                  </Tooltip>
                                </div>
                              </div>
                            </div>
                            <div className="info-component">
                              <div className="info-component-main">
                                <div className="info-component-heading info-add">B_aromatic_count</div>
                                <div className="info-component-value">
                                  <Tooltip placement="topLeft" title={0}>
                                    {0}
                                  </Tooltip>
                                </div>
                              </div>
                            </div>
                            <div className="info-component">
                              <div className="info-component-main">
                                <div className="info-component-heading info-add">B_hbond_count </div>
                                <div className="info-component-value">
                                  <Tooltip placement="topLeft" title={0}>
                                    {0}
                                  </Tooltip>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="info-component-row">
                            <div className="info-component">
                              <div className="info-component-main">
                                <div className="info-component-heading info-add">A_hydrophobic_count </div>
                                <div className="info-component-value">
                                  <Tooltip placement="topLeft" title={0}>
                                    {0}
                                  </Tooltip>
                                </div>
                              </div>
                            </div>
                            <div className="info-component">
                              <div className="info-component-main">
                                <div className="info-component-heading info-add">A_polar_count</div>
                                <div className="info-component-value">
                                  <Tooltip placement="topLeft" title={0}>
                                    {0}
                                  </Tooltip>
                                </div>
                              </div>
                            </div>
                            <div className="info-component">
                              <div className="info-component-main">
                                <div className="info-component-heading info-add">A_weak_polar_count </div>
                                <div className="info-component-value">
                                  <Tooltip placement="topLeft" title={0}>
                                    {0}
                                  </Tooltip>
                                </div>
                              </div>
                            </div>
                            <div className="info-component">
                              <div className="info-component-main">
                                <div className="info-component-heading info-add">A_carbonyl_count </div>
                                <div className="info-component-value">
                                  <Tooltip placement="topLeft" title={0}>
                                    {0}
                                  </Tooltip>
                                </div>
                              </div>
                            </div>
                            <div className="info-component">
                              <div className="info-component-main">
                                <div className="info-component-heading info-add">A_ionic_count</div>
                                <div className="info-component-value">
                                  <Tooltip placement="topLeft" title={0}>
                                    {0}
                                  </Tooltip>
                                </div>
                              </div>
                            </div>
                            <div className="info-component">
                              <div className="info-component-main">
                                <div className="info-component-heading info-add">A_aromatic_count </div>
                                <div className="info-component-value">
                                  <Tooltip placement="topLeft" title={0}>
                                    {0}
                                  </Tooltip>
                                </div>
                              </div>
                            </div>
                            <div className="info-component">
                              <div className="info-component-main">
                                <div className="info-component-heading info-add">A_hbond_count </div>
                                <div className="info-component-value">
                                  <Tooltip placement="topLeft" title={0}>
                                    {0}
                                  </Tooltip>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="info-component-row">
                            <div className="info-component">
                              <div className="info-component-main">
                                <div className="info-component-heading info-add">heavy_chain_total_count</div>
                                <div className="info-component-value">
                                  <Tooltip placement="topLeft" title={0}>
                                    {0}
                                  </Tooltip>
                                </div>
                              </div>
                            </div>
                            <div className="info-component">
                              <div className="info-component-main">
                                <div className="info-component-heading info-add">light_chain_total_count</div>
                                <div className="info-component-value">
                                  <Tooltip placement="topLeft" title={0}>
                                    {0}
                                  </Tooltip>
                                </div>
                              </div>
                            </div>
                            <div className="info-component">
                              <div className="info-component-main">
                                <div className="info-component-heading info-add">Grand_total_count</div>
                                <div className="info-component-value">
                                  <Tooltip placement="topLeft" title={0}>
                                    {0}
                                  </Tooltip>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </Loader>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="proceed-btn">
          <Button onClick={() => setPageIndex(1)}>Proceed to Sequence Generation</Button>
        </div>
      </Loader>
    </div>
  );
}

RawDataAndDockingResults.propTypes = {
  setPageIndex: PropTypes.func.isRequired,
};

export default RawDataAndDockingResults;
