import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../utils';

// my-workspace-lead-generation
const GET_VALIDATION_AUTOMATION_WORKSPACE_STATUS = 'GET_VALIDATION_AUTOMATION_WORKSPACE_STATUS';
const GET_MORE_VALIDATION_AUTOMATION_WORKSPACE_STATUS = 'GET_MORE_VALIDATION_AUTOMATION_WORKSPACE_STATUS';
const GET_VALIDATION_AUTOMATION_WORKSPACE_STATUS_SUCCESS = 'GET_VALIDATION_AUTOMATION_WORKSPACE_STATUS_SUCCESS';
const SAR_MORE_LEAD_GENERATION_WORKSPACE_STATUS_SUCCESS = 'SAR_MORE_LEAD_GENERATION_WORKSPACE_STATUS_SUCCESS';
const GET_VALIDATION_AUTOMATION_WORKSPACE_STATUS_FAILURE = 'GET_VALIDATION_AUTOMATION_WORKSPACE_STATUS_FAILURE';
const GET_VALIDATION_AUTOMATION_WORKSPACE_STATUS_RESET = 'GET_VALIDATION_AUTOMATION_WORKSPACE_STATUS_RESET';

export const getValidationAutomationWorkspaceStatusAction = createAction(GET_VALIDATION_AUTOMATION_WORKSPACE_STATUS);
export const sarMoreLeadGenerationWorkspaceStatusAction = createAction(GET_MORE_VALIDATION_AUTOMATION_WORKSPACE_STATUS);
export const getValidationAutomationWorkspaceStatusResetAction = createAction(GET_VALIDATION_AUTOMATION_WORKSPACE_STATUS_RESET);

const getValidationAutomationWorkspaceStatusSuccess = createAction(GET_VALIDATION_AUTOMATION_WORKSPACE_STATUS_SUCCESS);
const getMoreValidationAutomationWorkspaceStatusSuccess = createAction(SAR_MORE_LEAD_GENERATION_WORKSPACE_STATUS_SUCCESS);
const getValidationAutomationWorkspaceStatusFailure = createAction(GET_VALIDATION_AUTOMATION_WORKSPACE_STATUS_FAILURE);

export const getValidationAutomationWorkspaceStatusEpic = (actions$) => actions$.pipe(
  ofType(GET_VALIDATION_AUTOMATION_WORKSPACE_STATUS),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/v1/d3_modules/searchJob?${action.payload}`, 'get')).pipe(
    map((res) => getValidationAutomationWorkspaceStatusSuccess(res)),
    catchError((err) => of(getValidationAutomationWorkspaceStatusFailure(err))),
  )),
);

export const getMoreValidationAutomationWorkspaceStatusEpic = (actions$) => actions$.pipe(
  ofType(GET_MORE_VALIDATION_AUTOMATION_WORKSPACE_STATUS),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/v1/d3_modules/searchJob?${action.payload}`, 'get')).pipe(
    map((res) => getMoreValidationAutomationWorkspaceStatusSuccess(res)),
    catchError((err) => of(getValidationAutomationWorkspaceStatusFailure(err))),
  )),
);

export const getValidationAutomationWorkspaceStatusReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(getValidationAutomationWorkspaceStatusAction, (state) => {
      state.loading = true;
    })
      .addCase(getValidationAutomationWorkspaceStatusSuccess, (state, action) => {
        state.data = [...action.payload.response.data];
        state.loading = false;
        state.flag = true;
      })
      .addCase(getMoreValidationAutomationWorkspaceStatusSuccess, (state, action) => {
        state.data = {
          total_count: action.payload.response.data.total_count,
          data: [...action.payload.response.data],
        };
        state.loading = false;
        state.flag = true;
      })
      .addCase(getValidationAutomationWorkspaceStatusFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(getValidationAutomationWorkspaceStatusResetAction, (state) => {
        state.data = {};
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);

// update-workspace
const UTILITIES_UPDATE_WORKSPACE_STATUS = 'UTILITIES_UPDATE_WORKSPACE_STATUS';
const UTILITIES_UPDATE_WORKSPACE_STATUS_SUCCESS = 'UTILITIES_UPDATE_WORKSPACE_STATUS_SUCCESS';
const UTILITIES_UPDATE_WORKSPACE_STATUS_FAILURE = 'UTILITIES_UPDATE_WORKSPACE_STATUS_FAILURE';
const UTILITIES_UPDATE_WORKSPACE_STATUS_RESET = 'UTILITIES_UPDATE_WORKSPACE_STATUS_RESET';

export const utilitiesUpdateWorkspaceStatusAction = createAction(UTILITIES_UPDATE_WORKSPACE_STATUS);
export const utilitiesUpdateWorkspaceStatusResetAction = createAction(UTILITIES_UPDATE_WORKSPACE_STATUS_RESET);

const utilitiesUpdateWorkspaceStatusSuccess = createAction(UTILITIES_UPDATE_WORKSPACE_STATUS_SUCCESS);
const utilitiesUpdateWorkspaceStatusFailure = createAction(UTILITIES_UPDATE_WORKSPACE_STATUS_FAILURE);

export const utilitiesUpdateWorkspaceStatusEpic = (actions$) => actions$.pipe(
  ofType(UTILITIES_UPDATE_WORKSPACE_STATUS),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/v1/d3_modules/searchJob?${action.payload}`, 'put')).pipe(
    map((res) => utilitiesUpdateWorkspaceStatusSuccess(res)),
    catchError((err) => of(utilitiesUpdateWorkspaceStatusFailure(err))),
  )),
);

export const utilitiesUpdateWorkspaceStatusReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(utilitiesUpdateWorkspaceStatusAction, (state) => {
      state.loading = true;
    })
      .addCase(utilitiesUpdateWorkspaceStatusSuccess, (state, action) => {
        state.data = { ...action.payload.response };
        state.loading = false;
        state.flag = true;
      })
      .addCase(utilitiesUpdateWorkspaceStatusFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(utilitiesUpdateWorkspaceStatusResetAction, (state) => {
        state.data = [];
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);
