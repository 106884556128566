import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../utils';

// get bioactivity details
const GET_BIOACTIVITY_OUTPUT = 'GET_BIOACTIVITY_OUTPUT';
const GET_BIOACTIVITY_OUTPUT_SUCCESS = 'GET_BIOACTIVITY_OUTPUT_SUCCESS';
const GET_BIOACTIVITY_OUTPUT_FAILURE = 'GET_BIOACTIVITY_OUTPUT_FAILURE';

export const getBioactivityOutputAction = createAction(GET_BIOACTIVITY_OUTPUT);

const getBioactivityOutputSuccess = createAction(GET_BIOACTIVITY_OUTPUT_SUCCESS);

const getBioactivityOutputFaliure = createAction(GET_BIOACTIVITY_OUTPUT_FAILURE);

export const getBioactivityOutputEpic = (actions$) => actions$.pipe(
  ofType(GET_BIOACTIVITY_OUTPUT),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_sar}/getBioactivityOutput?${action.payload}`, 'get')).pipe(
    map((res) => getBioactivityOutputSuccess(res)),
    catchError((err) => of(getBioactivityOutputFaliure(err))),
  )),
);

export const getBioactivityOutputReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(getBioactivityOutputAction, (state) => {
        state.loading = true;
      })
      .addCase(getBioactivityOutputSuccess, (state, action) => {
        state.data = { ...action.payload.response };
        state.loading = false;
        state.flag = true;
      })
      .addCase(getBioactivityOutputFaliure, (state) => {
        state.error = true;
        state.flag = false;
        state.loading = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);
