import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import { Provider } from 'react-redux';
import store from './store';
import ErrorPage from './utils/ErrorPage';
import '../public/styles/main.scss';
import Main from './app/container/Main';
import './utils/app.css';
import Loader from './common/components/Loader';
import MyWorkspacePageSAR from './modules/sar/pages/MyWorkspace';
import MyWorkspacePageUtilities from './modules/utilities/pages/MyWorkspace';

// Lazy load your components
const CTPInputPage = lazy(() => import('./modules/meta-ctp/pages/CTPInputPage'));
const CTPOutputPage = lazy(() => import('./modules/meta-ctp/pages/CTPOutputPage'));
const HomePage = lazy(() => import('./app/pages/HomePage'));
const LandingPage = lazy(() => import('./app/pages/LandingPage'));
const AccessPage = lazy(() => import('./common/pages/AccessPage'));
const DCOutputPage = lazy(() => import('./modules/dc/pages/DCOutputPage'));
const DCInputPage = lazy(() => import('./modules/dc/pages/DCInputPage'));
const ADCOutputPage = lazy(() => import('./modules/adc/pages/ADCOutputPage'));
const IEPInputPage = lazy(() => import('./modules/iep/pages/IEPInputPage'));
const IEPOutputPage = lazy(() => import('./modules/iep/pages/IEPOutputPage'));
const ADCInputPage = lazy(() => import('./modules/adc/pages/ADCInputPage'));
const DROutputPage = lazy(() => import('./modules/dr/pages/DROutputPage'));
const DRInputPage = lazy(() => import('./modules/dr/pages/DRInputPage'));
const TargetIdInputPage = lazy(() => import('./modules/target-id/pages/TargetIdInputPage'));
const TargetIdOutputPage = lazy(() => import('./modules/target-id/pages/TargetIdOutputPage'));
const ProtacInputPage = lazy(() => import('./modules/protac/pages/ProtacInputPage'));
const SARInputPage = lazy(() => import('./modules/sar/pages/SARInputPage'));
const SAROutputPage = lazy(() => import('./modules/sar/pages/SAROutputPage'));
const ProtacOutputPage = lazy(() => import('./modules/protac/pages/ProtacOutputPage'));
const BiomarkerInputPage = lazy(() => import('./modules/biomarker/pages/NewBiomarkerInputPage'));
const BiomarkerOutputPage = lazy(() => import('./modules/biomarker/pages/BiomarkerOutputPage'));
const MyWorkspacePageIEP = lazy(() => import('./modules/iep/pages/MyWorkspace'));
const MyWorkspacePageCTP = lazy(() => import('./modules/meta-ctp/pages/MyWorkspace'));
// const MyWorkspacePageSAR = lazy(() => import('./modules/sar/pages/MyWorkspace'));
const OncoTaggingInputPage = lazy(() => import('./modules/utilities/pages/OncoTaggingInputPage'));
const OncoTaggingOutputPage = lazy(() => import('./modules/utilities/pages/OncoTaggingOutputPage'));
const AntibodyEngineeringInputPage = lazy(() => import('./modules/antibody-engineering/pages/AntibodyEngineeringInputPage'));
const AntibodyEngineeringOutputPage = lazy(() => import('./modules/antibody-engineering/pages/AntibodyEngineeringOutputPage'));
const MyWorkspacePageTI = lazy(() => import('./modules/target-id/pages/MyWorkspace'));
const DrugReformulationInputPage = lazy(() => import('./modules/drug_reformulation/pages/DrugReformulationInputPage'));
const DrugReformulationOutputPage = lazy(() => import('./modules/drug_reformulation/pages/DrugReformulationOutputPage'));
const MyWorkspacePageDrugReformulation = lazy(() => import('./modules/drug_reformulation/pages/MyWorkspace'));

const el = document.getElementById('root');
const root = ReactDOM.createRoot(el);

mixpanel.init(process.env.mixpanelToken, { debug: true });

const router = createBrowserRouter([
  {
    path: '/',
    element: <Main />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '',
        element: <Suspense fallback={<Loader loading />}><LandingPage /></Suspense>,
      },
      {
        path: 'home',
        element: <Suspense fallback={<Loader loading />}><HomePage /></Suspense>,
      },
      {
        path: '/access',
        element: <Suspense fallback={<Loader loading />}><AccessPage /></Suspense>,
      },
    ],
  },
  {
    path: '/meta-ctp',
    element: <Main />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'home',
        element: <Suspense fallback={<Loader loading />}><CTPInputPage module="Meta CTP" /></Suspense>,
      },
      {
        path: 'output',
        element: <Suspense fallback={<Loader loading />}><CTPOutputPage module="Meta CTP" /></Suspense>,
      },
      {
        path: 'my-workspace',
        element: <Suspense fallback={<Loader loading />}><MyWorkspacePageCTP /></Suspense>,
      },
    ],
  },
  {
    path: '/biomarker',
    element: <Main />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'home',
        element: <Suspense fallback={<Loader loading />}><BiomarkerInputPage /></Suspense>,
      },
      {
        path: 'output',
        element: <Suspense fallback={<Loader loading />}><BiomarkerOutputPage /></Suspense>,
      },
    ],
  },
  {
    path: '/adc',
    element: <Main />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'home',
        element: <Suspense fallback={<Loader loading />}><ADCInputPage module="ADC" /></Suspense>,
      },
      {
        path: 'output',
        element: <Suspense fallback={<Loader loading />}><ADCOutputPage module="ADC" /></Suspense>,
      },
    ],
  },
  {
    path: '/dc',
    element: <Main />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'home',
        element: <Suspense fallback={<Loader loading />}><DCInputPage module="DC" /></Suspense>,
      },
      {
        path: 'output',
        element: <Suspense fallback={<Loader loading />}><DCOutputPage module="DC" /></Suspense>,
      },
    ],
  },
  {
    path: '/target-id',
    element: <Main />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'home',
        element: <Suspense fallback={<Loader loading />}><TargetIdInputPage module="Target-Id" /></Suspense>,
      },
      {
        path: 'output',
        element: <Suspense fallback={<Loader loading />}><TargetIdOutputPage module="Target-Id" /></Suspense>,
      },
      {
        path: 'my-workspace',
        element: <Suspense fallback={<Loader loading />}><MyWorkspacePageTI /></Suspense>,
      },
    ],
  },
  {
    path: '/dr',
    element: <Main />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'home',
        element: <Suspense fallback={<Loader loading />}><DRInputPage module="DR" /></Suspense>,
      },
      {
        path: 'output',
        element: <Suspense fallback={<Loader loading />}><DROutputPage module="DR" /></Suspense>,
      },
    ],
  },
  {
    path: '/drug_reformulation',
    element: <Main />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'home',
        element: <Suspense fallback={<Loader loading />}><DrugReformulationInputPage module="Drug Reformulation" /></Suspense>,
      },
      {
        path: 'output',
        element: <Suspense fallback={<Loader loading />}><DrugReformulationOutputPage module="Drug Reformulation" /></Suspense>,
      },
      {
        path: 'my-workspace',
        element: <Suspense fallback={<Loader loading />}><MyWorkspacePageDrugReformulation /></Suspense>,
      },
    ],
  },
  {
    path: '/sar',
    element: <Main />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'home',
        element: <Suspense fallback={<Loader loading />}><SARInputPage module="SAR" /></Suspense>,
      },
      {
        path: 'output',
        element: <Suspense fallback={<Loader loading />}><SAROutputPage module="SAR" /></Suspense>,
      },
      {
        path: 'my-workspace',
        element: <Suspense fallback={<Loader loading />}><MyWorkspacePageSAR /></Suspense>,
      },
    ],
  },
  {
    path: '/iep',
    element: <Main />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'home',
        element: <Suspense fallback={<Loader loading />}><IEPInputPage /></Suspense>,
      },
      {
        path: 'output',
        element: <Suspense fallback={<Loader loading />}><IEPOutputPage module="IEP" /></Suspense>,
      },
      {
        path: 'my-workspace',
        element: <Suspense fallback={<Loader loading />}><MyWorkspacePageIEP /></Suspense>,
      },
    ],
  },
  {
    path: '/protac',
    element: <Main />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'home',
        element: <Suspense fallback={<Loader loading />}><ProtacInputPage /></Suspense>,
      },
      {
        path: 'output',
        element: <Suspense fallback={<Loader loading />}><ProtacOutputPage module="Protac" /></Suspense>,
      },
    ],
  },
  {
    path: '/utilities',
    element: <Main />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'home',
        element: <Suspense fallback={<Loader loading />}><OncoTaggingInputPage /></Suspense>,
      },
      {
        path: 'output',
        element: <Suspense fallback={<Loader loading />}><OncoTaggingOutputPage /></Suspense>,
      },
      {
        path: 'my-workspace',
        element: <Suspense fallback={<Loader loading />}><MyWorkspacePageUtilities /></Suspense>,
      },
    ],
  },
  {
    path: '/antibody',
    element: <Main />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'home',
        element: <Suspense fallback={<Loader loading />}><AntibodyEngineeringInputPage /></Suspense>,
      },
      {
        path: 'output',
        element: <Suspense fallback={<Loader loading />}><AntibodyEngineeringOutputPage /></Suspense>,
      },
    ],
  },
]);
root.render(
  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>,
);
