import React, { useState } from 'react';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import Header from '../../../../common/components/Header';
import { terminalsPath } from '../../../../app/components/Card/data';
import RawDataAndDockingResults from '../../components/LightChainOutputComponents/RawDataAndDockingComponent';
import PrioritizedSequenceLoader from '../../components/LightChainOutputComponents/PrioritizedSequenceLoader';
import PrioritizedSequenceData from '../../components/LightChainOutputComponents/PrioritizedSequenceDataComponent';

function AntibodyEngineeringOutputPage() {
  const navigate = useNavigate();
  const [pageIndex, setPageIndex] = useState(0);
  const handleBack = () => {
    if (pageIndex === 0) {
      navigate(`${terminalsPath.metad3_ae}`);
    } else {
      setPageIndex((prev) => prev - 1);
    }
  };

  const getOuputComponent = (index) => {
    if (index === 0) {
      return <RawDataAndDockingResults setPageIndex={setPageIndex} />;
    }
    if (index === 1) {
      return <PrioritizedSequenceLoader setPageIndex={setPageIndex} />;
    }
    if (index === 2) {
      return <PrioritizedSequenceData setPageIndex={setPageIndex} />;
    }
    return null;
  };

  return (
    <div className="main-page main-page-modules">
      <Header className="header-home adc-header" logo="antibodyeng-logo" width={500} />
      <div className="ct-home-page util-home-page">
        <div className="main-heading">
          <div className="back-btn">
            <Button className="back-btn-icon" onClick={handleBack}>
              Back
            </Button>
          </div>

        </div>
        {/* <RawDataAndDockingResults /> */}
        {getOuputComponent(pageIndex)}

      </div>
    </div>
  );
}

export default AntibodyEngineeringOutputPage;
