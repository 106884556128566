import React from 'react';
import PropTypes from 'prop-types';
import EditAssociatedProteinView from '../../../container/EditAssociatedProteinView';
import LandscapeView from '../../../components/IEPGraphs/LandscapeView';

function GraphicalView({
  isEditView, setIsEditView, serviceName, proteinName, jobId, cachedJobId,
}) {
  return (
    <div className="graphical-view-main" style={{ width: '100%' }}>
      <div className="graphical-view-container">{isEditView ? <EditAssociatedProteinView setIsEditView={setIsEditView} proteinName={proteinName} serviceName={serviceName} jobId={cachedJobId} /> : <LandscapeView jobId={jobId} />}</div>
    </div>
  );
}

GraphicalView.propTypes = {
  isEditView: PropTypes.bool.isRequired,
  setIsEditView: PropTypes.func.isRequired,
  serviceName: PropTypes.string.isRequired,
  proteinName: PropTypes.string.isRequired,
  jobId: PropTypes.string.isRequired,
  cachedJobId: PropTypes.string.isRequired,
};

export default GraphicalView;
