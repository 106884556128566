import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../utils';

// check status
const INHALER_FEASIBILITY_JOBS = 'INHALER_FEASIBILITY_JOBS';
const INHALER_FEASIBILITY_JOBS_SUCCESS = 'INHALER_FEASIBILITY_JOBS_SUCCESS';
const INHALER_FEASIBILITY_JOBS_FAILURE = 'INHALER_FEASIBILITY_JOBS_FAILURE';
const INHALER_FEASIBILITY_JOBS_RESET = 'INHALER_FEASIBILITY_JOBS_RESET';
const GET_MORE_INHALER_FEASIBILITY_JOBS = 'GET_MORE_INHALER_FEASIBILITY_JOBS';
const GET_MORE_INHALER_FEASIBILITY_JOBS_SUCCESS = 'GET_MORE_INHALER_FEASIBILITY_JOBS_SUCCESS';

export const inhalerFeasibilityJobsAction = createAction(INHALER_FEASIBILITY_JOBS);
export const inhalerFeasibilityJobsResetAction = createAction(INHALER_FEASIBILITY_JOBS_RESET);

const inhalerFeasibilityJobsSuccess = createAction(INHALER_FEASIBILITY_JOBS_SUCCESS);
const inhalerFeasibilityJobsFailure = createAction(INHALER_FEASIBILITY_JOBS_FAILURE);

export const getMoreInhalerFeasibilityJobsAction = createAction(GET_MORE_INHALER_FEASIBILITY_JOBS);
const getMoreInhalerFeasibilityJobsSuccess = createAction(GET_MORE_INHALER_FEASIBILITY_JOBS_SUCCESS);

export const inhalerFeasibilityJobsDREpic = (actions$) => actions$.pipe(
  ofType(INHALER_FEASIBILITY_JOBS),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/v1/d3_modules/searchJob?${action.payload}`, 'get')).pipe(
    map((res) => inhalerFeasibilityJobsSuccess(res)),
    catchError((err) => of(inhalerFeasibilityJobsFailure(err))),
  )),
);

export const getMoreInhalerFeasibilityJobsDREpic = (actions$) => actions$.pipe(
  ofType(GET_MORE_INHALER_FEASIBILITY_JOBS),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/v1/d3_modules/searchJob?${action.payload}`, 'get')).pipe(
    map((res) => getMoreInhalerFeasibilityJobsSuccess(res)),
    catchError((err) => of(inhalerFeasibilityJobsFailure(err))),
  )),
);

export const inhalerFeasibilityJobsDRReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(inhalerFeasibilityJobsAction, (state) => {
      state.loading = true;
    })
      .addCase(inhalerFeasibilityJobsSuccess, (state, action) => {
        state.data = { ...action.payload.response };
        state.loading = false;
        state.flag = true;
      })
      .addCase(getMoreInhalerFeasibilityJobsAction, (state) => {
        state.loading = true;
      })
      .addCase(getMoreInhalerFeasibilityJobsSuccess, (state, action) => {
        state.data = {
          total_count: action.payload.response.data.total_count,
          data: [...action.payload.response.data],
        };
        state.loading = false;
        state.flag = true;
      })
      .addCase(inhalerFeasibilityJobsFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(inhalerFeasibilityJobsResetAction, (state) => {
        state.data = [];
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);

// check status
const EXCIPIENT_COMPATIBILITY_JOBS = 'EXCIPIENT_COMPATIBILITY_JOBS';
const EXCIPIENT_COMPATIBILITY_JOBS_SUCCESS = 'EXCIPIENT_COMPATIBILITY_JOBS_SUCCESS';
const EXCIPIENT_COMPATIBILITY_JOBS_FAILURE = 'EXCIPIENT_COMPATIBILITY_JOBS_FAILURE';
const EXCIPIENT_COMPATIBILITY_JOBS_RESET = 'EXCIPIENT_COMPATIBILITY_JOBS_RESET';
const GET_MORE_EXCIPIENT_COMPATIBILITY_JOBS = 'GET_MORE_EXCIPIENT_COMPATIBILITY_JOBS';
const GET_MORE_EXCIPIENT_COMPATIBILITY_JOBS_SUCCESS = 'GET_MORE_EXCIPIENT_COMPATIBILITY_JOBS_SUCCESS';

export const excipientCompatibilityJobsAction = createAction(EXCIPIENT_COMPATIBILITY_JOBS);
export const excipientCompatibilityJobsResetAction = createAction(EXCIPIENT_COMPATIBILITY_JOBS_RESET);

const excipientCompatibilityJobsSuccess = createAction(EXCIPIENT_COMPATIBILITY_JOBS_SUCCESS);
const excipientCompatibilityJobsFailure = createAction(EXCIPIENT_COMPATIBILITY_JOBS_FAILURE);

export const getMoreExcipientCompatibilityJobsAction = createAction(GET_MORE_EXCIPIENT_COMPATIBILITY_JOBS);
const getMoreExcipientCompatibilityJobsSuccess = createAction(GET_MORE_EXCIPIENT_COMPATIBILITY_JOBS_SUCCESS);

export const excipientCompatibilityJobsEpic = (actions$) => actions$.pipe(
  ofType(EXCIPIENT_COMPATIBILITY_JOBS),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/v1/d3_modules/searchJob?${action.payload}`, 'get')).pipe(
    map((res) => excipientCompatibilityJobsSuccess(res)),
    catchError((err) => of(excipientCompatibilityJobsFailure(err))),
  )),
);

export const getMoreExcipientCompatibilityJobsEpic = (actions$) => actions$.pipe(
  ofType(GET_MORE_EXCIPIENT_COMPATIBILITY_JOBS),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/v1/d3_modules/searchJob?${action.payload}`, 'get')).pipe(
    map((res) => getMoreExcipientCompatibilityJobsSuccess(res)),
    catchError((err) => of(excipientCompatibilityJobsFailure(err))),
  )),
);

export const excipientCompatibilityJobsReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(excipientCompatibilityJobsAction, (state) => {
      state.loading = true;
    })
      .addCase(excipientCompatibilityJobsSuccess, (state, action) => {
        state.data = { ...action.payload.response };
        state.loading = false;
        state.flag = true;
      })
      .addCase(getMoreExcipientCompatibilityJobsAction, (state) => {
        state.loading = true;
      })
      .addCase(getMoreExcipientCompatibilityJobsSuccess, (state, action) => {
        state.data = {
          total_count: action.payload.response.data.total_count,
          data: [...action.payload.response.data],
        };
        state.loading = false;
        state.flag = true;
      })
      .addCase(excipientCompatibilityJobsFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(excipientCompatibilityJobsResetAction, (state) => {
        state.data = [];
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);

// update project name
const UPDATE_PROJECT_NAME_DR = 'UPDATE_PROJECT_NAME_DR';
const UPDATE_PROJECT_NAME_DR_SUCCESS = 'UPDATE_PROJECT_NAME_DR_SUCCESS';
const UPDATE_PROJECT_NAME_DR_FAILURE = 'UPDATE_PROJECT_NAME_DR_FAILURE';
const UPDATE_PROJECT_NAME_DR_RESET = 'UPDATE_PROJECT_NAME_DR_RESET';

export const updateProjectNameDRAction = createAction(UPDATE_PROJECT_NAME_DR);
export const updateProjectNameDRResetAction = createAction(UPDATE_PROJECT_NAME_DR_RESET);

const updateProjectNameDRSuccess = createAction(UPDATE_PROJECT_NAME_DR_SUCCESS);
const updateProjectNameDRFailure = createAction(UPDATE_PROJECT_NAME_DR_FAILURE);

export const updateProjectNameDREpic = (actions$) => actions$.pipe(
  ofType(UPDATE_PROJECT_NAME_DR),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/v1/d3_modules/searchJob?${action.payload}`, 'put')).pipe(
    map((res) => updateProjectNameDRSuccess(res)),
    catchError((err) => of(updateProjectNameDRFailure(err))),
  )),
);

export const updateProjectNameDRReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(updateProjectNameDRAction, (state) => {
      state.loading = true;
    })
      .addCase(updateProjectNameDRSuccess, (state, action) => {
        state.data = { ...action.payload.response };
        state.loading = false;
        state.flag = true;
      })
      .addCase(updateProjectNameDRFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(updateProjectNameDRResetAction, (state) => {
        state.data = [];
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);
