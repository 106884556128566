/* eslint-disable */
import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import data from './data';
import useAppSelector from '../../../../../utils/redux-hook';
import { useDispatch } from 'react-redux';
import { getClinicalTrialEnrichmentAction } from './logic';
import _ from 'lodash';
import Loader from '../../../../../common/components/Loader';
import EditCTModal from '../../../container/EditCTModal';

function EnrichmentGraph({ jobId }) {
  const [graphData, setGraphData] = useState([]);
  const [protienName, setProtienName] = useState('');
  const [openCTModal, setOpenCTModal] = useState(false);
  const [drugsList, setDrugsList] = useState([]);

  const dispatch = useDispatch();
  const getClinicalTrialEnrichmentResponse = useAppSelector((state) => state.getClinicalTrialEnrichmentResponse);

  useEffect(() => {
    const body = {
      job_id: jobId,
    };
    dispatch(getClinicalTrialEnrichmentAction(queryString.stringify(body)));
  }, []);

  const parsingData = (data) => {
    const colors = ['#35D8EE', '#52B956', '#B787DD', '#F28B5B', '#1C8F7A', '#4E96EB', '#EA78D8', '#E4908A', '#FBBC08'];

    let dList = [];
    const dData = _.cloneDeep(data);
    for (let x = 0; x < data.length; x++) {
      let clr = colors[x % colors.length];
      let arr = dData[x].children;
      for (let y = 0; y < arr.length; y++) {
        let arr2 = arr[y].children;
        for (let z = 0; z < arr2.length; z++) {
          arr2[z].itemStyle = {
            color: clr + '60',
          };
          arr2[z].type = 'Indication name';
        }
        arr[y].itemStyle = {
          color: clr + '95',
        };
        arr[y].type = 'Clinical Trials';
      }
      dData[x].itemStyle = {
        color: clr,
      };
      dList.push(dData[x].name);
      dData[x].type = 'Drug name';
    }
    setDrugsList((prev) => dList);
    return dData;
  };

  useEffect(() => {
    if (getClinicalTrialEnrichmentResponse.flag) {
      setGraphData(parsingData(getClinicalTrialEnrichmentResponse.data.graphData));
      setProtienName(getClinicalTrialEnrichmentResponse.data.protein_name);
    }
  }, [getClinicalTrialEnrichmentResponse]);

  const option = {
    tooltip: {
      trigger: 'item',
      formatter(param) {
        const name = param.data['name'];
        const type = param.data['type'];
        if (name === undefined || type === undefined) return;
        return (
          '<div style="border-bottom: 1px solid rgba(255,255,255,.3); font-size: 18px;margin-bottom: 7px; display: flex;flex-direction: column">' +
          '<div style="display: flex;flex-direction: column;justify-content: space-between"><p style="font-family: Inter;font-size: 12px;color: #59748A">' +
          `${type}` +
          '</p><p style="font-family: Inter;font-size: 14px;color: #121417">' +
          `${name}` +
          '</p></div>' +
          // + '<div style="display: flex;flex-direction: row;justify-content: space-between"><p style="font-family: Inter;font-size: 14px;color: #59748A">Therapeutic Area: </p><p style="font-family: Inter;font-size: 15px;color: #121417;margin-left:1em">'+` ${ta}`+'</p></div>'
          '</div>'
        );
      },
    },
    emphasis: {
      focus: 'none',
    },
    label: {
      // rotate: 'radial',
      // width: 60,
      color: '#1F1F1F',
      overflow: 'truncate',
      minAngle: 5,
    },
    series: {
      type: 'sunburst',
      data: graphData,
      radius: [100, '95%'],
      // sort: undefined,
      startAngle: 0,
      emphasis: {
        focus: 'none',
      },
      label: {
        // rotate: 'radial',
        width: 60,
        overflow: 'truncate',
      },
    },
  };
  return (
    <div className="ct-enrichment-graph-container iep-enrichment-graph-container" width="100%">
      <div className="card-content-ctp-header">
        <div className="card-content-ctp-title">Clinical Trials Enrichment Graph</div>
        <div
          className="edit-protein-btn"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            // Mixpanel.track('Edit Associated Protein Button Clicked', {
            //   action: 'Edit Associated Protein Button Clicked',
            //   protein_name: proteinName,
            //   service: 'Top Indications',
            //   module: 'IEP',
            // });
            setOpenCTModal(true);
          }}
          aria-hidden
        >
          Edit Associated Drugs
        </div>
      </div>

      <Loader loading={getClinicalTrialEnrichmentResponse.loading || !getClinicalTrialEnrichmentResponse.flag}>
        <div style={{ height: '800px', width: '100%', position: 'relative' }}>
          <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>{protienName}</div>
          <ReactECharts option={option} style={{ height: '800px', width: '100%' }} />
        </div>
      </Loader>
      { drugsList?.length > 0 && <EditCTModal name={protienName} service={"asd"} open={openCTModal} setOpen={setOpenCTModal} drugsList={drugsList} prevJobId={jobId}  />}

    </div>
  );
}

EnrichmentGraph.propTypes = {
  jobId: PropTypes.string.isRequired,
};

export default EnrichmentGraph;
