import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import SvgLoader from '../SvgLoader';
import LoginModalButton from '../LoginModalButton';
import webAuth from '../../../utils/webAuth';
import Mixpanel from '../../../utils/mixpanel';
import { getCookie } from '../../../utils';
import ProfileModal from '../ProfileModal';

function Header({
  className, logo, width, isWorkspace, modulePath,
}) {
  const [showProfileModal, setShowProfileModal] = useState(false);
  const navigate = useNavigate();
  const profileRef = useRef(null);
  const fullName = getCookie('fullName');
  const initials = fullName ? fullName[0]?.toUpperCase() : 'U'; // You can choose a default initial

  window.addEventListener('popstate', () => {
    // Nope, go back to your page
    navigate(1);
  });
  useEffect(() => {
    const handler = (event) => {
      if (!profileRef.current) {
        return;
      }

      if (!profileRef.current.contains(event.target)) {
        setShowProfileModal(false);
      }
    };

    document.addEventListener('click', handler, true);
    return () => {
      document.removeEventListener('click', handler);
    };
  }, []);

  const classes = classNames(className);
  const handleLogoclick = () => {
    Mixpanel.track('Module logo clicked', {
      action: 'Logo Clicked, go to landing page triggered',
      from: window.location.pathname,
      logo,
    });
  };

  return (
    <div className={classes}>
      <Link to="/home">
        <SvgLoader onClick={handleLogoclick} width={width} height={72} svgName={logo} />
      </Link>
      {getCookie('accessToken') ? (
        <div className="log-menu">
          {isWorkspace && (
            <div className="workspace-btn">
              <Button onClick={() => { navigate(modulePath); Mixpanel.track('My Workspace Clicked', { action: 'My workspace button clicked', from: window.location.pathname, module: logo }); }}>
                <div className="workspace-icon" />
                <div className="workspace-btn-text"> My Workspace</div>
              </Button>
            </div>
          )}
          <div className="profile-dropdown" style={{ display: 'flex' }}>
            <div className="log-menu-profile">
              <div aria-hidden className="profile-intials">{initials}</div>
            </div>
            <div className="user-name">{getCookie('fullName')}</div>
            <div className="dropdown-icon">
              <div className="dropdown-icon-img" onClick={() => setShowProfileModal(!showProfileModal)} aria-hidden />
            </div>
          </div>
          {showProfileModal && (
            <div className="log-menu-links" ref={profileRef}>
              <ProfileModal />
            </div>
          )}
        </div>
      ) : (
        <div className="btn-component">
          <LoginModalButton webAuth={webAuth} className="login-btn" />
        </div>
      )}
    </div>
  );
}

Header.propTypes = {
  className: PropTypes.string,
  logo: PropTypes.string,
  width: PropTypes.number,
  isWorkspace: PropTypes.bool,
  modulePath: PropTypes.string,
};

Header.defaultProps = {
  className: '',
  logo: '',
  width: 216,
  isWorkspace: false,
  modulePath: '',
};

export default Header;
