import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../utils';

// HCTP my-workspace
const HCTP_WORKSPACE_STATUS = 'HCTP_WORKSPACE_STATUS';
const HCTP_WORKSPACE_STATUS_SUCCESS = 'HCTP_WORKSPACE_STATUS_SUCCESS';
const HCTP_WORKSPACE_STATUS_FAILURE = 'HCTP_WORKSPACE_STATUS_FAILURE';
const HCTP_WORKSPACE_STATUS_RESET = 'HCTP_WORKSPACE_STATUS_RESET';

export const hctpWorkspaceStatusAction = createAction(HCTP_WORKSPACE_STATUS);
export const hctpWorkspaceStatusResetAction = createAction(HCTP_WORKSPACE_STATUS_RESET);

const hctpWorkspaceStatusSuccess = createAction(HCTP_WORKSPACE_STATUS_SUCCESS);
const hctpWorkspaceStatusFailure = createAction(HCTP_WORKSPACE_STATUS_FAILURE);

export const hctpWorkspaceStatusEpic = (actions$) => actions$.pipe(
  ofType(HCTP_WORKSPACE_STATUS),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/v1/d3_modules/searchJob?${action.payload}`, 'get')).pipe(
    map((res) => hctpWorkspaceStatusSuccess(res)),
    catchError((err) => of(hctpWorkspaceStatusFailure(err))),
  )),
);

export const hctpWorkspaceStatusReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(hctpWorkspaceStatusAction, (state) => {
      state.loading = true;
    })
      .addCase(hctpWorkspaceStatusSuccess, (state, action) => {
        state.data = [...action.payload.response.data];
        state.loading = false;
        state.flag = true;
      })
      .addCase(hctpWorkspaceStatusFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(hctpWorkspaceStatusResetAction, (state) => {
        state.data = [];
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);

// POS my-workspace
const POS_WORKSPACE_STATUS = 'POS_WORKSPACE_STATUS';
const POS_WORKSPACE_STATUS_SUCCESS = 'POS_WORKSPACE_STATUS_SUCCESS';
const POS_WORKSPACE_STATUS_FAILURE = 'POS_WORKSPACE_STATUS_FAILURE';
const POS_WORKSPACE_STATUS_RESET = 'POS_WORKSPACE_STATUS_RESET';

export const posWorkspaceStatusAction = createAction(POS_WORKSPACE_STATUS);
export const posWorkspaceStatusResetAction = createAction(POS_WORKSPACE_STATUS_RESET);

const posWorkspaceStatusSuccess = createAction(POS_WORKSPACE_STATUS_SUCCESS);
const posWorkspaceStatusFailure = createAction(POS_WORKSPACE_STATUS_FAILURE);

export const posWorkspaceStatusEpic = (actions$) => actions$.pipe(
  ofType(POS_WORKSPACE_STATUS),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/v1/d3_modules/searchJob?${action.payload}`, 'get')).pipe(
    map((res) => posWorkspaceStatusSuccess(res)),
    catchError((err) => of(posWorkspaceStatusFailure(err))),
  )),
);

export const posWorkspaceStatusReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(posWorkspaceStatusAction, (state) => {
      state.loading = true;
    })
      .addCase(posWorkspaceStatusSuccess, (state, action) => {
        state.data = [...action.payload.response.data];
        state.loading = false;
        state.flag = true;
      })
      .addCase(posWorkspaceStatusFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(posWorkspaceStatusResetAction, (state) => {
        state.data = [];
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);

// update-workspace
const CTP_UPDATE_WORKSPACE_STATUS = 'CTP_UPDATE_WORKSPACE_STATUS';
const CTP_UPDATE_WORKSPACE_STATUS_SUCCESS = 'CTP_UPDATE_WORKSPACE_STATUS_SUCCESS';
const CTP_UPDATE_WORKSPACE_STATUS_FAILURE = 'CTP_UPDATE_WORKSPACE_STATUS_FAILURE';
const CTP_UPDATE_WORKSPACE_STATUS_RESET = 'CTP_UPDATE_WORKSPACE_STATUS_RESET';

export const ctpUpdateWorkspaceStatusAction = createAction(CTP_UPDATE_WORKSPACE_STATUS);
export const ctpUpdateWorkspaceStatusResetAction = createAction(CTP_UPDATE_WORKSPACE_STATUS_RESET);

const ctpUpdateWorkspaceStatusSuccess = createAction(CTP_UPDATE_WORKSPACE_STATUS_SUCCESS);
const ctpUpdateWorkspaceStatusFailure = createAction(CTP_UPDATE_WORKSPACE_STATUS_FAILURE);

export const ctpUpdateWorkspaceStatusEpic = (actions$) => actions$.pipe(
  ofType(CTP_UPDATE_WORKSPACE_STATUS),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/v1/d3_modules/searchJob?${action.payload}`, 'put')).pipe(
    map((res) => ctpUpdateWorkspaceStatusSuccess(res)),
    catchError((err) => of(ctpUpdateWorkspaceStatusFailure(err))),
  )),
);

export const ctpUpdateWorkspaceStatusReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(ctpUpdateWorkspaceStatusAction, (state) => {
      state.loading = true;
    })
      .addCase(ctpUpdateWorkspaceStatusSuccess, (state, action) => {
        state.data = { ...action.payload.response };
        state.loading = false;
        state.flag = true;
      })
      .addCase(ctpUpdateWorkspaceStatusFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(ctpUpdateWorkspaceStatusResetAction, (state) => {
        state.data = [];
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);
