import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall, { apiCallwithoutCT } from '../../../../utils';

// check status
const IEP_CHECK_STATUS = 'IEP_CHECK_STATUS';
const IEP_CHECK_STATUS_SUCCESS = 'IEP_CHECK_STATUS_SUCCESS';
const IEP_CHECK_STATUS_FAILURE = 'IEP_CHECK_STATUS_FAILURE';
const IEP_CHECK_STATUS_RESET = 'IEP_CHECK_STATUS_RESET';

export const iepCheckStatusAction = createAction(IEP_CHECK_STATUS);
export const iepCheckStatusResetAction = createAction(IEP_CHECK_STATUS_RESET);

const iepCheckStatussSuccess = createAction(IEP_CHECK_STATUS_SUCCESS);
const iepCheckStatusFailure = createAction(IEP_CHECK_STATUS_FAILURE);

export const iepchCheckStatusEpic = (actions$) => actions$.pipe(
  ofType(IEP_CHECK_STATUS),
  switchMap(() => ajax(apiCall(`${process.env.apiUrl_iep}/userIndicationList`, 'get')).pipe(
    map((res) => iepCheckStatussSuccess(res)),
    catchError((err) => of(iepCheckStatusFailure(err))),
  )),
);

export const iepCheckStatusReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(iepCheckStatusAction, (state) => {
        state.loading = true;
      })
      .addCase(iepCheckStatussSuccess, (state, action) => {
        state.data = [...action.payload.response.data];
        state.loading = false;
        state.flag = true;
      })
      .addCase(iepCheckStatusFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(iepCheckStatusResetAction, (state) => {
        state.data = [];
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);

// generate indications

const GENERATE_INDICATIONS = 'GENERATE_INDICATIONS';
const GENERATE_INDICATIONS_SUCCESS = 'GENERATE_INDICATIONS_SUCCESS';
const GENERATE_INDICATIONS_FAILURE = 'GENERATE_INDICATIONS_FAILURE';
const GENERATE_INDICATIONS_RESET = 'GENERATE_INDICATIONS_RESET';

export const generateIndicationsAction = createAction(GENERATE_INDICATIONS);
export const generateIndicationsResetAction = createAction(GENERATE_INDICATIONS_RESET);

const generateIndicationsSuccess = createAction(GENERATE_INDICATIONS_SUCCESS);

const generateIndicationsFailure = createAction(GENERATE_INDICATIONS_FAILURE);
export const generateIndicationsEpic = (actions$) => actions$.pipe(
  ofType(GENERATE_INDICATIONS),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_iep}/generateIndications?${action.payload.params}`, 'POST', action.payload.body)).pipe(
    map((res) => generateIndicationsSuccess(res)),
    catchError((err) => of(generateIndicationsFailure(err))),
  )),
);

export const generateIndicationsReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(generateIndicationsAction, (state) => {
        state.loading = true;
      })
      .addCase(generateIndicationsSuccess, (state, action) => {
        state.data = { ...action.payload.response.data };
        state.loading = false;
        state.flag = true;
      })
      .addCase(generateIndicationsFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(generateIndicationsResetAction, (state) => {
        state.data = {};
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);

// upload CCA document

const UPLOAD_CCA_DOCUMENT = 'UPLOAD_CCA_DOCUMENT';
const UPLOAD_CCA_DOCUMENT_SUCCESS = 'UPLOAD_CCA_DOCUMENT_LIST_SUCCESS';
const UPLOAD_CCA_DOCUMENT_FAILURE = 'UPLOAD_CCA_DOCUMENT_LIST_FAILURE';
const UPLOAD_CCA_DOCUMENT_RESET = 'UPLOAD_CCA_DOCUMENT_RESET';

export const uploadCCADocumentAction = createAction(UPLOAD_CCA_DOCUMENT);
export const uploadCCADocumentResetAction = createAction(UPLOAD_CCA_DOCUMENT_RESET);

const uploadCCADocumentSuccess = createAction(UPLOAD_CCA_DOCUMENT_SUCCESS);

const uploadCCADocumentFailure = createAction(UPLOAD_CCA_DOCUMENT_FAILURE);
export const uploadCCADocumentEpic = (actions$) => actions$.pipe(
  ofType(UPLOAD_CCA_DOCUMENT),
  switchMap((action) => ajax(apiCallwithoutCT(`${process.env.apiUrl_iep}/uploadInputDocument?${action.payload.params}`, 'POST', action.payload.body)).pipe(
    map((res) => uploadCCADocumentSuccess(res)),
    catchError((err) => of(uploadCCADocumentFailure(err))),
  )),
);

export const uploadCCADocumentReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(uploadCCADocumentAction, (state) => {
        state.loading = true;
      })
      .addCase(uploadCCADocumentSuccess, (state, action) => {
        state.data = { ...action.payload.response.data };
        state.loading = false;
        state.flag = true;
      })
      .addCase(uploadCCADocumentFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(uploadCCADocumentResetAction, (state) => {
        state.data = {};
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);

const GET_INPUT_SYNONYMS = 'GET_INPUT_SYNONYMS';
const GET_INPUT_SYNONYMS_SUCCESS = 'GET_INPUT_SYNONYMS_SUCCESS';
const GET_INPUT_SYNONYMS_FAILURE = 'GET_INPUT_SYNONYMS_FAILURE';
const GET_INPUT_SYNONYMS_RESET = 'GET_INPUT_SYNONYMS_RESET';

export const getInputSynonymsAction = createAction(GET_INPUT_SYNONYMS);
export const getInputSynonymsResetAction = createAction(GET_INPUT_SYNONYMS_RESET);

const getInputSynonymsSuccess = createAction(GET_INPUT_SYNONYMS_SUCCESS);
const getInputSynonymsFailure = createAction(GET_INPUT_SYNONYMS_FAILURE);

export const getInputSynonymsEpic = (actions$) => actions$.pipe(
  ofType(GET_INPUT_SYNONYMS),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_iep}/synonyms?${action.payload}`, 'get')).pipe(
    map((res) => getInputSynonymsSuccess(res)),
    catchError((err) => of(getInputSynonymsFailure(err))),
  )),
);

export const getInputSynonymsReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(getInputSynonymsAction, (state) => {
        state.loading = true;
      })
      .addCase(getInputSynonymsSuccess, (state, action) => {
        state.data = [...action.payload.response.data];
        state.loading = false;
        state.flag = true;
      })
      .addCase(getInputSynonymsFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(getInputSynonymsResetAction, (state) => {
        state.data = [];
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);
