import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import ClinicalSuccessOutputPage from '../ClinicalSuccessOutputPage';
import useAppSelector from '../../../../utils/redux-hook';
import PosComponent from '../../components/PosComponent';
import LazyLoadDiv from '../../../../common/components/LazyLoadDiv';
import { getMoreCTPOutputAction } from '../CTPOutputPage/logic';

function MainClinicalSuccessOutputPage({
  isPosDetails, setIsPosDetails, searchQuery, currentPage, setCurrentPage, setNctId,
}) {
  const ctpOutput = useAppSelector((state) => state.ctpOutput);
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [posData2, setPosData2] = useState({});
  const [isEmpty, setIsEmpty] = useState(false);
  const [outputData, setOutputData] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const jobId = searchParams.get('jobId');
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    setOutputData(ctpOutput?.data?.data?.insight_data);
    setData1(ctpOutput?.data?.data?.insight_data[0].endpoint_data);
    setData2(ctpOutput?.data?.data?.insight_data[0].phase_data);
    setData3(ctpOutput?.data?.data?.insight_data[0].shap_analysis);
    setPosData2(ctpOutput?.data?.data?.insight_data[0].pos);
    setSelectedItem(ctpOutput?.data?.data?.insight_data[0]);
    setNctId(ctpOutput?.data?.data?.insight_data[0]?.pos?.trial);
    if (ctpOutput?.data?.data?.insight_data[0].endpoint_data?.length === 0 || ctpOutput?.data?.data?.insight_data[0].phase_data?.length === 0) {
      setIsEmpty(true);
    } else {
      setIsEmpty(false);
    }
    setTotalCount(ctpOutput?.data?.data?.total_count);
  }, [ctpOutput?.data?.data?.insight_data]);
  const handleClick = (item) => {
    setData1(item?.endpoint_data);
    setData2(item?.phase_data);
    setData3(item?.shap_analysis);
    setPosData2(item?.pos);
    setNctId(item?.pos?.trial);
    setIsPosDetails(true);
    setSelectedItem(item);
    if (item.endpoint_data?.length === 0 || item.phase_data?.length === 0) {
      setIsEmpty(true);
    } else {
      setIsEmpty(false);
    }
  };

  const loadMoreData = () => {
    const request = {
      job_id: jobId,
      from: currentPage + 1,
      size: 10,
      search_query: searchQuery,
      filters: JSON.stringify({ PoS: { $gte: 0, $lte: 100 } }),
    };
    dispatch(getMoreCTPOutputAction(queryString.stringify(request)));
    setCurrentPage(currentPage + 1);
  };
  return (
    <div className="clinical-success-output-main">
      {outputData?.length === 1 || isPosDetails ? (
        <ClinicalSuccessOutputPage data1={data1} data2={data2} data3={data3} posData2={posData2} isEmpty={isEmpty} item={selectedItem} />
      ) : (
        <LazyLoadDiv className="datatable-container-inner" loadMoreRows={loadMoreData} id="table-list" total={totalCount} currentTotal={(outputData || []).length} height="calc(100vh - 290px)">
          {outputData?.map((item) => (
            <div className="pos-item" key={item}>
              <PosComponent item={item} handleClick={handleClick} isDetails={isPosDetails} />
            </div>
          ))}
        </LazyLoadDiv>
      )}
    </div>
  );
}

MainClinicalSuccessOutputPage.propTypes = {
  isPosDetails: PropTypes.bool.isRequired,
  setIsPosDetails: PropTypes.func.isRequired,
  searchQuery: PropTypes.string.isRequired,
  currentPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  setNctId: PropTypes.func.isRequired,
};

export default MainClinicalSuccessOutputPage;
