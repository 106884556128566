import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../utils';

// check status
const DISEASE_BASED_JOBS = 'DISEASE_BASED_JOBS';
const DISEASE_BASED_JOBS_SUCCESS = 'DISEASE_BASED_JOBS_SUCCESS';
const DISEASE_BASED_JOBS_FAILURE = 'DISEASE_BASED_JOBS_FAILURE';
const DISEASE_BASED_JOBS_RESET = 'DISEASE_BASED_JOBS_RESET';
const GET_MORE_DISEASE_BASED_JOBS = 'GET_MORE_DISEASE_BASED_JOBS';
const GET_MORE_DISEASE_BASED_JOBS_SUCCESS = 'GET_MORE_DISEASE_BASED_JOBS_SUCCESS';

export const diseaseBasedJobsAction = createAction(DISEASE_BASED_JOBS);
export const diseaseBasedJobsResetAction = createAction(DISEASE_BASED_JOBS_RESET);

const diseaseBasedJobsSuccess = createAction(DISEASE_BASED_JOBS_SUCCESS);
const diseaseBasedJobsFailure = createAction(DISEASE_BASED_JOBS_FAILURE);

export const getMoreDiseaseBasedJobsAction = createAction(GET_MORE_DISEASE_BASED_JOBS);
const getMoreDiseaseBasedJobsSuccess = createAction(GET_MORE_DISEASE_BASED_JOBS_SUCCESS);

export const diseaseBasedJobsTIEpic = (actions$) => actions$.pipe(
  ofType(DISEASE_BASED_JOBS),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/v1/d3_modules/searchJob?${action.payload}`, 'get')).pipe(
    map((res) => diseaseBasedJobsSuccess(res)),
    catchError((err) => of(diseaseBasedJobsFailure(err))),
  )),
);

export const getMorediseaseBasedJobsTIEpic = (actions$) => actions$.pipe(
  ofType(GET_MORE_DISEASE_BASED_JOBS),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/v1/d3_modules/searchJob?${action.payload}`, 'get')).pipe(
    map((res) => getMoreDiseaseBasedJobsSuccess(res)),
    catchError((err) => of(diseaseBasedJobsFailure(err))),
  )),
);

export const diseaseBasedJobsTIReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(diseaseBasedJobsAction, (state) => {
      state.loading = true;
    })
      .addCase(diseaseBasedJobsSuccess, (state, action) => {
        // console.log('res', action.payload.response);

        state.data = { ...action.payload.response };
        state.loading = false;
        state.flag = true;
      })
      .addCase(getMoreDiseaseBasedJobsAction, (state) => {
        state.loading = true;
      })
      .addCase(getMoreDiseaseBasedJobsSuccess, (state, action) => {
        state.data = {
          total_count: action.payload.response.data.total_count,
          data: [...action.payload.response.data],
        };
        state.loading = false;
        state.flag = true;
      })
      .addCase(diseaseBasedJobsFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(diseaseBasedJobsResetAction, (state) => {
        state.data = [];
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);

// update project name
const TI_UPDATE_WORKSPACE_STATUS = 'TI_UPDATE_WORKSPACE_STATUS';
const TI_UPDATE_WORKSPACE_STATUS_SUCCESS = 'TI_UPDATE_WORKSPACE_STATUS_SUCCESS';
const TI_UPDATE_WORKSPACE_STATUS_FAILURE = 'TI_UPDATE_WORKSPACE_STATUS_FAILURE';
const TI_UPDATE_WORKSPACE_STATUS_RESET = 'TI_UPDATE_WORKSPACE_STATUS_RESET';

export const tiUpdateWorkspaceStatusAction = createAction(TI_UPDATE_WORKSPACE_STATUS);
export const tiUpdateWorkspaceStatusResetAction = createAction(TI_UPDATE_WORKSPACE_STATUS_RESET);

const tiUpdateWorkspaceStatusSuccess = createAction(TI_UPDATE_WORKSPACE_STATUS_SUCCESS);
const tiUpdateWorkspaceStatusFailure = createAction(TI_UPDATE_WORKSPACE_STATUS_FAILURE);

export const tiUpdateWorkspaceStatusEpic = (actions$) => actions$.pipe(
  ofType(TI_UPDATE_WORKSPACE_STATUS),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/v1/d3_modules/searchJob?${action.payload}`, 'put')).pipe(
    map((res) => tiUpdateWorkspaceStatusSuccess(res)),
    catchError((err) => of(tiUpdateWorkspaceStatusFailure(err))),
  )),
);

export const tiUpdateWorkspaceStatusReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(tiUpdateWorkspaceStatusAction, (state) => {
      state.loading = true;
    })
      .addCase(tiUpdateWorkspaceStatusSuccess, (state, action) => {
        state.data = { ...action.payload.response };
        state.loading = false;
        state.flag = true;
      })
      .addCase(tiUpdateWorkspaceStatusFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(tiUpdateWorkspaceStatusResetAction, (state) => {
        state.data = [];
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);
