import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { removeSession } from '../../container/Status/logic';
import Mixpanel from '../../../utils/mixpanel';
import { getCookie } from '../../../utils';
import SvgLoader from '../SvgLoader';

function Logout({ webAuth, className }) {
  const params = {
    returnTo: process.env.appUrl,
    clientID: process.env.auth0.clientId,
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onLogoutClick = () => {
    Mixpanel.track('User Logged Out', {
      action: 'Logout button clicked',
      userDetail: `${getCookie('userName')}@${getCookie('org')}`,
    });
    webAuth.logout(params);
    dispatch(removeSession());
    navigate('/', { replace: true });
  };

  const classes = classNames('navbar-group-right-item', className);

  return (
    <div aria-hidden onClick={onLogoutClick} className={classes}>
      <SvgLoader width={20} height={20} svgName="sign-out-icon" />
      {' '}
      Sign out
    </div>
  );
}

Logout.propTypes = {
  webAuth: PropTypes.PropTypes.shape({
    logout: PropTypes.func,
  }).isRequired,
  className: PropTypes.string,
};

Logout.defaultProps = {
  className: '',
};

export default Logout;
