import TextArea from 'antd/es/input/TextArea';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

function InputStudyProtocols({ inputHandler, allInputs, isDisabled }) {
  const inputStyleList = ['Textual Input', 'Upload a Document'];
  const [inputStyle, setInputStyle] = useState(inputStyleList[0]);
  const [inputText, setInputText] = useState('');

  useEffect(() => {
    setInputText(allInputs.drugIndication || '');
  }, []);

  useEffect(() => {
    const inputs = {
      drugIndication: inputText,
    };
    inputHandler(inputs);
  }, [inputText]);
  return (
    <div className={`input-study-protocols-main${isDisabled ? ' disabled' : ''}`}>
      <div className="provide-input-container">
        <div className={`input-container ${isDisabled ? 'disabled' : ''}`}>
          <div className="input-fields">
            <div className="select-dataset" style={{ paddingTop: '0px' }}>
              <div className="select-title" style={{ paddingBottom: '10px' }}>
                Select Input Style
              </div>
              <div className="select-dataset-footer">
                <div className="select-dataset-footer-btn">
                  <button type="button" className={`select-button ${inputStyleList[0] === inputStyle ? 'active' : ''}`} onClick={() => setInputStyle(inputStyleList[0])} disabled={isDisabled}>
                    {inputStyleList[0]}
                  </button>
                </div>
                <div className="select-dataset-footer-btn">
                  <button type="button" className={`select-button ${inputStyleList[1] === inputStyle ? 'active' : ''}`} onClick={() => setInputStyle(inputStyleList[1])} disabled={isDisabled}>
                    {inputStyleList[1]}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="provide-input-container">
        <div className={`input-container ${isDisabled ? 'disabled' : ''}`}>
          <div className="input-title-row" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div className="input-container-title">Provide Input</div>
            <div className="try-example">Try Example</div>
          </div>
          <div className="input-fields">
            <div className="input-fields-value">
              <TextArea rows={8} placeholder={'Enter Drug~Indication pairs here, each pair on new line\nLine 1: Drug Name 1 ~ Indication 1\nLine 2: Drug Name 2 ~ Indication 2\nLine 3: Drug Name 3 ~ Indication 3'} type="textarea" onChange={(e) => setInputText(e.target.value)} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

InputStudyProtocols.propTypes = {
  inputHandler: PropTypes.func.isRequired,
  allInputs: PropTypes.shape({
    drugIndication: PropTypes.string.isRequired,
    indication: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    dataset: PropTypes.string.isRequired,
  }).isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default InputStudyProtocols;
