import { React, useState, useEffect } from 'react';
import {
  Button, Popover, Select, Slider, Input,
} from 'antd';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import Rcookie from 'react-cookies';
import { getCTPOutputAction, isReadCTPOutputAction } from './logic';
import Header from '../../../../common/components/Header';
import useAppSelector from '../../../../utils/redux-hook';
import { terminalsPath } from '../../../../app/components/Card/data';
import Loader from '../../../../common/components/Loader';
import { checkStatusResetAction } from '../CTPInputPage/logic';
import ClinicalFeasibilityOutputPage from '../ClinicalFeasibilityOutputPage';
import MainClinicalSuccessOutputPage from '../MainClinicalSuccessOutputPage';
import HCTPOutputPage from '../HCTPOutputPage';

const { Search } = Input;

function CTPOutputPage() {
  const dispatch = useDispatch();
  const ctpOutput = useAppSelector((state) => state.ctpOutput);
  const [searchParams] = useSearchParams();
  const jobId = searchParams.get('jobId');
  const service = searchParams.get('service');
  // const nctId = searchParams.get('nctId');
  const [indicationName, setIndicationName] = useState(JSON.parse(localStorage.getItem('inputIndications') || '[]')[0] || '');
  const indicationsList = JSON.parse(localStorage.getItem('inputIndications') || '[]');
  // const dataset = searchParams.get('dataset');
  const navigate = useNavigate();
  const [isPendingDownload, setIsPendingDownload] = useState(false);
  const title = {
    PREDICT_CLINICAL_TRIAL_SUCCESS: ['Clinical Trial Success Probability Analysis', 'Explore the Intricacies of Feature Relationships in the Generated Insights'],
    CHECK_TRIAL_FEASIBILITY: [`Clinical Trial Feasibility Analysis for "${indicationName}"`, 'Explore the Intricacies of Feature Relationships in the Generated Insights'],
    HCTP: ['Hypothetical Prediction of Clinical Trial Success', ''],
  };
  const [isPosDetails, setIsPosDetails] = useState(false);
  const [sliderValue, setSliderValue] = useState([0, 100]);
  const [searchQuery, setSearchQuery] = useState(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const [nctId, setNctId] = useState('');

  useEffect(() => {
    if (service === 'PREDICT_CLINICAL_TRIAL_SUCCESS') {
      const request = {
        job_id: jobId,
        is_read: true,
      };
      dispatch(isReadCTPOutputAction(queryString.stringify(request)));
    }
  }, []);
  useEffect(() => {
    if (service === 'PREDICT_CLINICAL_TRIAL_SUCCESS') {
      const request = {
        job_id: jobId,
      };
      setTimeout(() => {
        dispatch(getCTPOutputAction(queryString.stringify(request)));
      }, 50);
      dispatch(checkStatusResetAction());
    }
  }, []);

  const downloadXLSFile = async () => {
    console.log('Inside Response ');
    setIsPendingDownload(true);
    let downloadUrl = '';
    if (service === 'PREDICT_CLINICAL_TRIAL_SUCCESS') {
      downloadUrl = isPosDetails ? `${process.env.apiUrl_ctp}/scoreResultDownload?job_id=${jobId}&nct_id=${nctId}` : `${process.env.apiUrl_ctp}/zipDownload?job_id=${jobId}`;
    }
    if (service === 'CHECK_TRIAL_FEASIBILITY') {
      downloadUrl = `${process.env.apiUrl_ctp}/trialResultDownload?indication_name=${indicationName}`;
    }
    if (service === 'HCTP') {
      downloadUrl = `${process.env.apiUrl_ctp}/zipDownload?job_id=${jobId}`;
    }
    const headers = { Authorization: Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`) ? Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`) : '', 'Content-Type': 'blob', 'Access-Control-Allow-Origin': `${process.env.applicationUrl}` };
    try {
      const response = await axios({
        method: 'GET',
        url: downloadUrl,
        // responseType: service === 'PREDICT_CLINICAL_TRIAL_SUCCESS' && !isPosDetails ? 'json' : 'arraybuffer',
        responseType: service === 'CHECK_TRIAL_FEASIBILITY' ? 'arraybuffer' : service === 'PREDICT_CLINICAL_TRIAL_SUCCESS' && isPosDetails ? 'arraybuffer' : 'json',
        headers,
      });
      setIsPendingDownload(false);
      const outputFilename = service === 'CHECK_TRIAL_FEASIBILITY' ? `${Date.now()}.xls` : service === 'PREDICT_CLINICAL_TRIAL_SUCCESS' && isPosDetails ? `${Date.now()}.xls` : `${Date.now()}.zip`;
      console.log('Response ---->', response);
      // If you want to download file automatically using link attribute.
      const url = service === 'CHECK_TRIAL_FEASIBILITY' ? URL.createObjectURL(new Blob([response.data])) : service === 'PREDICT_CLINICAL_TRIAL_SUCCESS' && isPosDetails ? URL.createObjectURL(new Blob([response.data])) : response.data?.zip_url;
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      throw Error(error);
    }
  };

  const handleBackClick = () => {
    if (isPosDetails) {
      setIsPosDetails(false);
      return;
    }
    navigate(`${terminalsPath.metad3_ctp}`);
  };

  const onSearchClick = (text) => {
    const request = {
      job_id: jobId,
      search_query: text,
      filters: JSON.stringify({ PoS: { $gte: sliderValue[0], $lte: sliderValue[1] } }),
      size: 10,
      from: 1,
    };
    dispatch(getCTPOutputAction(queryString.stringify(request)));
    setCurrentPage(1);
  };

  const onChangeSlider = () => {
    const request = {
      job_id: jobId,
      search_query: searchQuery,
      filters: JSON.stringify({ PoS: { $gte: sliderValue[0], $lte: sliderValue[1] } }),
      size: 10,
      from: 1,
    };
    dispatch(getCTPOutputAction(queryString.stringify(request)));
    setCurrentPage(1);
  };

  const onClearClick = () => {
    const request = {
      job_id: jobId,
      search_query: searchQuery,
      filters: JSON.stringify({ PoS: { $gte: 0, $lte: 100 } }),
      size: 10,
      from: 1,
    };
    dispatch(getCTPOutputAction(queryString.stringify(request)));
    setSliderValue([0, 100]);
    setCurrentPage(1);
  };

  const outputType = () => {
    if (service === 'PREDICT_CLINICAL_TRIAL_SUCCESS') {
      return <MainClinicalSuccessOutputPage isPosDetails={isPosDetails} setIsPosDetails={setIsPosDetails} searchQuery={searchQuery} currentPage={currentPage} setCurrentPage={setCurrentPage} setNctId={setNctId} />;
    }
    if (service === 'CHECK_TRIAL_FEASIBILITY') {
      return <ClinicalFeasibilityOutputPage indicationName={indicationName} jobId={jobId} />;
    }
    if (service === 'HCTP') {
      return <HCTPOutputPage jobId={jobId} />;
    }
    return <MainClinicalSuccessOutputPage isPosDetails={isPosDetails} setIsPosDetails={setIsPosDetails} searchQuery={searchQuery} currentPage={currentPage} setCurrentPage={setCurrentPage} setNctId={setNctId} />;
  };

  const posFilters = () => (
    <div className="pos-filters-main">
      <div className="pos-filters-main-content">
        <div className="headings-row" style={{ display: 'flex', alignItems: 'space-between' }}>
          <div className="pos-title">POS</div>
        </div>
        <div className="pos-slider">
          <Slider
            range
            onChange={(e) => {
              setSliderValue(e);
            }}
            value={sliderValue} // Use 'value' instead of 'defaultValue' to reflect updates
            marks={{
              [sliderValue[0]]: `${sliderValue[0]}`, // Show left slider value
              [sliderValue[1]]: `${sliderValue[1]}`, // Show right slider value
            }}
          />
        </div>
      </div>
      <div className="submit-btns" style={{ display: 'flex' }}>
        <div className="clear-btn">
          <Button onClick={() => onClearClick()}>Clear</Button>
        </div>
        <div className="submit-btn">
          <Button onClick={() => onChangeSlider()}>Submit</Button>
        </div>
      </div>
    </div>
  );

  return (
    <div className="main-page main-page-modules">
      <Header className="header-home ctp-header" logo="meta-ctp" />
      <div className="ct-home-page">
        <div className="main-heading">
          <div className="back-btn">
            <Button className="back-btn-icon" onClick={() => handleBackClick()}>
              Back
            </Button>
          </div>
          <div className="main-heading-content">
            <div className="ctp-header-success-probability-analysis">
              <div className="heading-left-section">
                <div className="main-title-row ct-feasi-row">
                  <div className="headings">
                    <div className="main-title">{title[service][0]}</div>
                  </div>
                </div>
              </div>
              <div className="heading-right-section">
                {service === 'CHECK_TRIAL_FEASIBILITY' && (
                <div className="select-indication-dropdown">
                  <Select value="Select Indication from inputs" onChange={(e) => setIndicationName(e)}>
                    {indicationsList?.map((item) => (
                      <Select.Option key={item}>{item}</Select.Option>
                    ))}
                  </Select>
                </div>
                )}
                <div className="download-btns-row">
                  {!isPosDetails && service === 'PREDICT_CLINICAL_TRIAL_SUCCESS' && (ctpOutput.length > 1 || searchQuery === '') && (
                  <div className="filters-row" style={{ display: 'flex' }}>
                    <div className="search-btn">
                      <div className="search-input">
                        <Search
                          placeholder="Search here for NCT ID"
                          onChange={(e) => setSearchQuery(e.target.value)}
                          value={searchQuery}
                          onSearch={(value) => {
                            onSearchClick(value);
                          }}
                          enterButton="Search"
                        />
                      </div>
                    </div>
                    <div className="filters-btn">
                      <Popover overlayClassName="pos-filters-main-popover" placement="bottomRight" content={posFilters}>
                        <Button>Filters</Button>
                      </Popover>
                    </div>
                  </div>
                  )}
                  <div className="main-download-btn">
                    <Button
                      onClick={() => {
                        setIsPendingDownload(true);
                        console.log('clicked!!');
                        downloadXLSFile();
                      }}
                      disabled={isPendingDownload}
                    >
                      {`${isPendingDownload ? 'Downloading...' : 'Download Output'}`}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Loader loading={ctpOutput?.loading} error={ctpOutput?.error} width="100%" height="600px">
          {outputType()}
        </Loader>
      </div>
    </div>
  );
}

export default CTPOutputPage;
