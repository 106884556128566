import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../utils';

// get Output details
const GET_DP_OUTPUT = 'GET_DP_OUTPUT';
const GET_MORE_DP_OUTPUT = 'GET_MORE_DP_OUTPUT';
const GET_DP_OUTPUT_SUCCESS = 'GET_DP_OUTPUT_SUCCESS';
const GET_DP_OUTPUT_FAILURE = 'GET_DP_OUTPUT_FAILURE';
const GET_MORE_DP_OUTPUT_SUCCESS = 'GET_MORE_DP_OUTPUT_SUCCESS';

export const getDPOutputAction = createAction(GET_DP_OUTPUT);

const getDPOutputSuccess = createAction(GET_DP_OUTPUT_SUCCESS);

export const getMoreDPOutputAction = createAction(GET_MORE_DP_OUTPUT);

const getMoreDPOutputSuccess = createAction(GET_MORE_DP_OUTPUT_SUCCESS);

const getDPOutputFaliure = createAction(GET_DP_OUTPUT_FAILURE);

export const getDPOutputEpic = (actions$) => actions$.pipe(
  ofType(GET_DP_OUTPUT),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_sar}/getDPOutput?${action.payload}`, 'get')).pipe(
    map((res) => getDPOutputSuccess(res)),
    catchError((err) => of(getDPOutputFaliure(err))),
  )),
);

export const getMoreDPOutputEpic = (actions$) => actions$.pipe(
  ofType(GET_MORE_DP_OUTPUT),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_sar}/getDPOutput?${action.payload}`, 'get')).pipe(
    map((res) => getMoreDPOutputSuccess(res)),
    catchError((err) => of(getDPOutputFaliure(err))),
  )),
);

export const getDPOutputReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(getDPOutputAction, (state) => {
        state.loading = true;
      })
      .addCase(getDPOutputSuccess, (state, action) => {
        state.data = { ...action.payload.response };
        state.loading = false;
        state.flag = true;
      })
      .addCase(getMoreDPOutputAction, (state) => {
        state.loading = true;
      })
      .addCase(getMoreDPOutputSuccess, (state, action) => {
        state.data = {
          total_count: action.payload.response.data.total_count,
          data: {
            ...state.data.data,
            data: { ...state.data.data.data, ...action.payload.response.data.data },
          },
        };
        state.loading = false;
        state.flag = true;
      })
      .addCase(getDPOutputFaliure, (state) => {
        state.error = true;
        state.flag = false;
        state.loading = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);
