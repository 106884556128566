import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../../utils';

const GET_CPC_GRAPH_DATA = 'GET_CPC_GRAPH_DATA';
const GET_CPC_GRAPH_DATA_SUCCESS = 'GET_CPC_GRAPH_DATA_SUCCESS';
const GET_CPC_GRAPH_DATA_FAILURE = 'GET_CPC_GRAPH_DATA_FAILURE';

export const getDRCPCGraphDataAction = createAction(GET_CPC_GRAPH_DATA);
const getDRCPCGraphDataSuccess = createAction(GET_CPC_GRAPH_DATA_SUCCESS);
const getDRCPCGraphDataFailure = createAction(GET_CPC_GRAPH_DATA_FAILURE);

export const getDRCPCGraphDataEpic = (actions$) => actions$.pipe(
  ofType(GET_CPC_GRAPH_DATA),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_drug_reform}/getCPCGraphData?${action.payload}`, 'get')).pipe(
    map((res) => getDRCPCGraphDataSuccess(res)),
    catchError((err) => of(getDRCPCGraphDataFailure(err))),
  )),
);

export const getDRCPCGraphDataReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(getDRCPCGraphDataAction, (state) => {
      state.loading = true;
    })
      .addCase(getDRCPCGraphDataSuccess, (state, action) => {
        state.data = [...action.payload.response.data];
        state.loading = false;
        state.flag = true;
      })
      .addCase(getDRCPCGraphDataFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);
