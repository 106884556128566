/* eslint-disable */
import React, { useEffect, useState } from 'react';
import SlopeGraph from '../../container/Graph/SlopeGraph';
import Wordcloud from '../../container/Graph/WordCloud';
import BarGraph from '../../container/Graph/BarGraph';
import BubbleGraphContainer from '../../container/Graph/BubbleGraphContainer';
import PropTypes from 'prop-types';
import GlobalMap from '../../container/Graph/GlobalMap';
import { Radio, Space } from 'antd';
import useAppSelector from '../../../../utils/redux-hook';

function ClinicalFeasibilityOutputPage({ indicationName }) {
  const [mapType, setMapType] = useState('sites');
  const geoCtData = useAppSelector((state) => state.geoCtResponse);
  const geoKolData = useAppSelector((state) => state.geoKolResponse);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    return () => {
      // Select all div elements with id 'tooltips'
    const tooltips = document.querySelectorAll("#tooltip-div");

    // Loop through the selected elements and remove each one from the DOM
    tooltips.forEach(tooltip => {
      tooltip.remove();
    });
  }
  })
  return (
    <div className="ctp-content">
      <div className="ctp-content-lower card-content-ctp card-content-ctp-graph">
        <div className="card-content-ctp-title info-add">
          Phase-wise Distribution of Trials for {indicationName}
          <span className="info-add-icon">{/* <Tooltip content={titleTooltip} /> */}</span>
        </div>
        <BarGraph indicationName={indicationName} />
      </div>
      <div className="ctp-content-lower card-content-ctp card-content-ctp-graph">
        <div className="card-content-ctp-title info-add">
          Top 5 Country-wise Distribution of trials for {indicationName}
          <span className="info-add-icon">{/* <Tooltip content={titleTooltip} /> */}</span>
        </div>
        <BubbleGraphContainer indicationName={indicationName} />
      </div>
      <div className="ctp-content-lower card-content-ctp card-content-ctp-graph">
        <div className="card-content-ctp-title info-add">
          Top 5 KOLs and their Contribution for {indicationName}
          <span className="info-add-icon">{/* <Tooltip content={titleTooltip} /> */}</span>
        </div>

        <SlopeGraph indicationName={indicationName} />
      </div>
      <div className="ctp-content-lower card-content-ctp card-content-ctp-graph">
        <div className="card-content-ctp-title info-add">
          Common Topics of Interest in their Contribution for {indicationName}
          <span className="info-add-icon">{/* <Tooltip content={titleTooltip} /> */}</span>
        </div>
        <div className="wordcloud-content">
          <Wordcloud indicationName={indicationName} />
        </div>
      </div>
      <div className="ctp-content-lower card-content-ctp card-content-ctp-graph">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className="card-content-ctp-title info-add">
            Geographical View of Clinical Trial Sites and KOLs for {indicationName}
            <span className="info-add-icon">{/* <Tooltip content={titleTooltip} /> */}</span>
          </div>
          <Space>
            <Radio.Group value={mapType} onChange={(e) => setMapType(e.target.value)} disabled={geoCtData?.loading || geoKolData?.loading}>
              <Radio.Button value="sites">Sites</Radio.Button>
              <Radio.Button value="kols">Kols</Radio.Button>
            </Radio.Group>
          </Space>
        </div>
        <div className="wordMap-content">
          <GlobalMap indicationName={indicationName} type={mapType} />
        </div>
      </div>
      <div>
        {/* <AssociatedProteinDistributionGraph/> */}
        {/* <PathEnrichmentGraph/> */}
      </div>
    </div>
  );
}

ClinicalFeasibilityOutputPage.propTypes = {
  indicationName: PropTypes.string.isRequired,
};

export default ClinicalFeasibilityOutputPage;
