/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Button, Tooltip } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import Rcookie from 'react-cookies';
import Header from '../../../../common/components/Header';
// import CircularGauge from '../../components/CircularGauge';
import useAppSelector from '../../../../utils/redux-hook';
import { generateLinkersOutputAction } from './logic';
import Loader from '../../../../common/components/Loader';
import { terminalsPath } from '../../../../app/components/Card/data';
import LinearGauge from '../../components/Graphs/LinearGauge';
import Mixpanel from '../../../../utils/mixpanel';

function DCOutputPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const disease = searchParams.get('disease');
  const service = searchParams.get('service');
  const dispatch = useDispatch();
  const [displayListData, setDisplayListData] = useState([]);
  const dcOutputResponse = useAppSelector((state) => state.dcOutputResponse);
  const [isPendingDownload, setIsPendingDownload] = useState(false);
  const [currentCardIndex, setCurrentCardIndex] = useState(0);

  useEffect(() => {
    const request = {
      service: 'DISEASE_ONLY',
      disease_name: disease,
      page_size: 100,
    };
    setTimeout(() => {
      dispatch(generateLinkersOutputAction(queryString.stringify(request)));
    }, 50);
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (dcOutputResponse.flag) {
      setDisplayListData(dcOutputResponse?.data);
    }
  }, [dcOutputResponse]);

  const handleExpandedClick = (index) => {
    setCurrentCardIndex(index === currentCardIndex ? -1 : index);
    if (index === currentCardIndex) {
      Mixpanel.track('DC Drug\'s Summary Closed', {
        action: 'DC Upward arrow clicked',
        drug_1: displayListData[index]?.first_drug,
        drug_2: displayListData[index]?.second_drug,
        service: 'Disease Only',
        module: 'DC',
      });
    } else {
      Mixpanel.track('DC Drug\'s Summary Opened', {
        action: 'DC Downward arrow clicked',
        drug_1: displayListData[index]?.first_drug,
        drug_2: displayListData[index]?.second_drug,
        service: 'Disease Only',
        module: 'DC',
      });
    }
  };

  const displayDrugItem = (item) => (
    <div className="combinations-drugs-info">
      <div className="info-component-row">
        <div className="combination-drug-name">
          <div className="info-component-heading">Drug 1</div>
          <div className="info-component-value">
            <Tooltip placement="topLeft" title={item.first_drug}>
              {item.first_drug}
            </Tooltip>
          </div>
        </div>
        <div className="combination-drug-moa">
          <div className="info-component-heading">Mechanism of Action (MOA of Drug 1)</div>
          <div className="info-component-value">
            <Tooltip placement="topLeft" title={item.drug1_moa.join()}>
              {item.drug1_moa.join()}
            </Tooltip>
          </div>
        </div>
        <div className="combination-drug-targets">
          <div className="info-component-heading">Targets of Drug 1</div>
          <div className="info-component-value">
            <Tooltip placement="topLeft" title={item.target_drug1.join()}>
              {item.target_drug1.join()}
            </Tooltip>
          </div>
        </div>
        <div className="combination-drug-targets">
          <div className="info-component-heading">Cell Line</div>
          <div className="info-component-value">
            <Tooltip placement="topLeft" title={item.cell_line_id}>
              {item.cell_line_id}
            </Tooltip>
          </div>
        </div>
      </div>
      <div className="info-component-row">
        <div className="combination-drug-name">
          <div className="info-component-heading">Drug 2</div>
          <div className="info-component-value">
            <Tooltip placement="topLeft" title={item.second_drug}>
              {item.second_drug}
            </Tooltip>
          </div>
        </div>
        <div className="combination-drug-moa">
          <div className="info-component-heading">Mechanism of Action (MOA of Drug 2)</div>
          <div className="info-component-value">
            <Tooltip placement="topLeft" title={item.drug2_moa.join()}>
              {item.drug2_moa.join()}
            </Tooltip>
          </div>
        </div>
        <div className="combination-drug-targets">
          <div className="info-component-heading">Targets of Drug 2</div>
          <div className="info-component-value">
            <Tooltip placement="topLeft" title={item.target_drug2.join()}>
              {item.target_drug2.join()}
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );

  const linearGaugeItem = (label, tooltipData, type, data) => {
   let typeClass = 'high';
   if(type === 'Synergistic'){
    typeClass = 'high';
   }else if(type === 'Likely Synergistic'){
    typeClass = 'high-low';
   }else if(type === 'Additive'){
    typeClass = 'medium';
   }else if(type === 'Likely Antagonistic'){
    typeClass = 'low-high';
   }else if(type === 'Antagonistic'){
    typeClass = 'low';
   }
   return (
   <div className="data-visualisation-charts-item">
      <div className="linear-gauge-header">{label}</div>
      <LinearGauge data={data} />
      {/* <div className="linear-gauge-tag">{type}</div> */}
      <div className="content-at-center">
        {/* <div className={type === 'Likely Synergistic' ? 'content-at-center-tag high' : type === 'No Synergistic Effect' ? 'content-at-center-tag medium' : 'content-at-center-tag low'}>{type}</div> */}
        <div className={`content-at-center-tag ${typeClass}`}>{type}</div>
      </div>
    </div>);
  };

  const displayOutputList = () =>
    displayListData.map((item, index) => (
      <div className={`combinations-output-item-outer-part ${currentCardIndex === index ? 'open' : 'close'}`}>
        <div className="combinations-output-item" key={crypto.randomUUID()}>
          <div className="combinations-item-content">
            <div className="combinations-drugs-list">{displayDrugItem(item)}</div>
            {/* <CircularGauge value={item.synergy_prob_pred_scaled} /> */}
          </div>
          <div
            className="combinations-output-item-button"
            role="button"
            onClick={() => {
              handleExpandedClick(index);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault(); // Prevent scrolling when Space is pressed
                handleExpandedClick(index);
              }
            }}
            aria-label="expand"
            style={{ color: 'black', cursor: 'pointer' }}
            tabIndex={0}
          >
            <div className="arrow-icon" />
          </div>
        </div>
        {index === currentCardIndex && (
          <div className="combinations-drugs-info-lower">
            <div className="drugs-summary-title">Drug’s Summary:</div>
            <div className="drugs-summary-content">
              <div className="drugs-summary-row" style={{ display: 'flex' }}>
                <div className="individual-drug">
                  <div className="individual-drug-content">
                    <div className="individual-drug-title">{item.first_drug}</div>
                    {/* <div className="individual-drug-description">The references suggest that Acetyldigitoxin (Digitoxin) has a potential role in the treatment of Glioblastoma. Reference 1 mentions that Digitoxin has been shown to induce apoptosis in different malignant cell lines, including glioblastoma cell lines, making it an interesting candidate for further evaluation as a potential anticancer drug. Reference 2 demonstrates that Digitoxin can sensitize glioma cells to TRAIL-mediated apoptosis, which could be a useful approach in the treatment of TRAIL-resistant glioma cells. Reference 3 shows that Digitoxin can suppress the maintenance of stemness and malignancy in human glioma stem cells by inhibiting HIF-1α, which is a key factor in glioblastoma pathogenesis. Overall, these abstracts suggest that Digitoxin has a potential therapeutic role in the treatment of Glioblastoma.</div> */}
                    <div className="individual-drug-description reference-sum-link" dangerouslySetInnerHTML={{ __html: `${item.drug1_llm_comment}` }} />
                  </div>
                </div>
                <div className="individual-drug">
                  <div className="individual-drug-content">
                    <div className="individual-drug-title">{item.second_drug}</div>
                    {/* <div className="individual-drug-description">The references suggest that Acetyldigitoxin (Digitoxin) has a potential role in the treatment of Glioblastoma. Reference 1 mentions that Digitoxin has been shown to induce apoptosis in different malignant cell lines, including glioblastoma cell lines, making it an interesting candidate for further evaluation as a potential anticancer drug. Reference 2 demonstrates that Digitoxin can sensitize glioma cells to TRAIL-mediated apoptosis, which could be a useful approach in the treatment of TRAIL-resistant glioma cells. Reference 3 shows that Digitoxin can suppress the maintenance of stemness and malignancy in human glioma stem cells by inhibiting HIF-1α, which is a key factor in glioblastoma pathogenesis. Overall, these abstracts suggest that Digitoxin has a potential therapeutic role in the treatment of Glioblastoma.</div> */}
                    <div className="individual-drug-description reference-sum-link" dangerouslySetInnerHTML={{ __html: `${item.drug2_llm_comment}` }} />
                  </div>
                </div>
              </div>
              <div className="data-visualisation">
                <div className="data-visualisation-title">Data visualisation for drug combination synergy metrics and interpretation:</div>
                <div className="data-visualisation-charts">
                  {/* <div className="data-visualisation-charts-item">
                <div className="linear-gauge-header">
                  Bliss
                </div>
                <LinearGauge />
                <div className="linear-gauge-tag">Tags</div>
              </div> */}
                  {item?.bliss_score ? linearGaugeItem('Bliss', 'Bliss', item?.bliss_score?.label, item.bliss_score) : null}
                  {item?.loewe_score ? linearGaugeItem('Loewe', 'Loewe', item?.loewe_score?.label, item.loewe_score) : null}
                  {item?.zip_score ? linearGaugeItem('ZIP', 'ZIP', item?.zip_score?.label, item.zip_score) : null}
                  {item?.css_score ? linearGaugeItem('CSS', 'CSS', item?.css_score?.label, item.css_score) : null}
                  {item?.hsa_score ? linearGaugeItem('HSA', 'HSA', item?.hsa_score?.label, item.hsa_score) : null}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    ));
  const downloadXLSFile = async () => {
    setIsPendingDownload(true);
    const headers = { Authorization: Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`) ? Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`) : '', 'Content-Type': 'blob', 'Access-Control-Allow-Origin': `${process.env.applicationUrl}` };

    try {
      const response = await axios({
        method: 'GET',
        url: `${process.env.apiUrl_dc}/scoreResultDownload?disease_name=${disease}&service=DISEASE_ONLY&page_size=10`,
        responseType: 'arraybuffer',
        headers,
      });
      setIsPendingDownload(false);
      const outputFilename = `${Date.now()}.xls`;

      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      throw Error(error);
    }
  };
  return (
    <div className="main-page main-page-modules">
      <Header className="header-home" logo="dc-logo" width={400} />
      <Loader loading={dcOutputResponse.loading} error={dcOutputResponse.error} width="100%">
        <div className="ct-home-page">
          <div className="main-heading">
            <div className="back-btn">
              <Button className="back-btn-icon" onClick={() => navigate(`${terminalsPath.metad3_dc}?service=${service}&disease=${disease}`)}>
                Back
              </Button>
            </div>
            <div className="main-title">{`Prioritised list of drug combinations for "${disease}"`}</div>
            <div className="main-description">Based on the Innoplexus model scores, here is a prioritized list of drug combinations, with scores ranging from 0 to 1, where 0 indicates a low score and 1 indicates a high score.</div>
            <div className="main-download-btn">
              <Button
                onClick={() => {
                  setIsPendingDownload(true);
                  downloadXLSFile();
                  Mixpanel.track('DC Output Downloaded', {
                    action: 'DC Download output button clicked',
                    disease_name: disease,
                    service: 'Disease Only',
                    module: 'DC',
                  });
                }}
                disabled={isPendingDownload}
              >
                {`${isPendingDownload ? 'Downloading...' : 'Download Output'}`}
              </Button>
            </div>
          </div>
          <div className="dc-output-list">{displayOutputList()}</div>
        </div>
      </Loader>
    </div>
  );
}

export default DCOutputPage;
