import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import InputDropdown from '../../components/InputDropdown';
import Mixpanel from '../../../../utils/mixpanel';

function InputBox({ inputHandler, proteinName, isDisabled }) {
  const [values, setValues] = useState({});
  const [defaultProteinName, setDefaultProteinName] = useState('');

  useEffect(() => {
    inputHandler(values);
  }, [values]);

  useEffect(() => {
    setDefaultProteinName(proteinName || '');
  }, []);

  const handleChange = (value) => {
    Mixpanel.track('Protac Protien selected', {
      action: 'Protac dropdown value selected',
      protein_name: value,
      module: 'Protac',
      service: 'Protac Design',
    });
    setValues((prev) => ({ ...prev, protein_name: value }));
  };

  const dropdownRenderer = () => (
    <div className="dropdown-container">
      <InputDropdown index={0} changeHandler={handleChange} proteinName={defaultProteinName} isDisabled={isDisabled} setProteinName={setDefaultProteinName} />
      <div className="input-fields-value try-example-section">
        <text className="try-example-section-title">Try Example: </text>
        <text
          style={{ cursor: 'pointer', color: 'blue' }}
          onClick={() => {
            setDefaultProteinName('BRD7');
          }}
        >
          BRD7
        </text>
      </div>
    </div>
  );

  return (
    <div className="provide-input-container protac-provide-input-container">
      <div className={`input-container ${isDisabled ? 'disabled' : ''}`}>
        <div className="input-container-title">Provide Input</div>
        <div className="input-fields">{dropdownRenderer()}</div>
      </div>
    </div>
  );
}

InputBox.propTypes = {
  inputHandler: PropTypes.func.isRequired,
  proteinName: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default InputBox;
