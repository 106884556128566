import { React, useEffect } from 'react';
import { Button, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import CircularGauge from '../../../components/CircularGauge';
import ImageWithLoader from '../../../components/ImageWithLoader';

function ComponentDetailsPage({ toggleComponent, individualItem }) {
  const handleBack = () => {
    toggleComponent();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="main-page main-page-modules">
      (
      <div className="ct-home-page">
        <div className="main-heading">
          <div className="back-btn">
            <Button className="back-btn-icon" onClick={handleBack}>
              Back
            </Button>
          </div>
          <div className="main-title">Lead Details</div>
        </div>
        <div className="sar-output-list">
          <div className="combinations-output-item-lead">
            <div className="combinations-item-content main-item-content">
              <div className="combinations-drugs-list">
                <div className="combinations-drugs-info">
                  <div className="combination-drug-name">
                    <div className="drug-placeholder">
                      <Tooltip placement="topLeft" title={individualItem.scaffold_lead}>
                        {individualItem.scaffold_lead}
                      </Tooltip>
                    </div>
                  </div>
                </div>
                <div className="combinations-drugs-info-img">
                  <div className="combination-drug-name">
                    <div className="drug-placeholder">
                      <ImageWithLoader src={individualItem.img_url} desc="Scaffold" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="pie-circle lead-pie-circle">
                <CircularGauge value={parseFloat(individualItem.lead_score).toFixed(2)} />
              </div>
            </div>
            <div className="dr-output-list">
              <div className="combinations-output-item">
                <div className="item-title">Property Details </div>
                <div className="combinations-item-content">
                  <div className="combinations-drugs-list">
                    <div className="combinations-drugs-info">
                      <div className="info-component-row">
                        <div className="info-component">
                          <div className="info-component-main">
                            <div className="info-component-heading">Mol Wt</div>
                            <div className="info-component-value">
                              <Tooltip placement="topLeft" title={individualItem.details.molecular_weight}>{`${parseFloat(individualItem.details.molecular_weight).toFixed(2)} g/mol`}</Tooltip>
                            </div>
                          </div>
                        </div>
                        <div className="info-component">
                          <div className="info-component-main">
                            <div className="info-component-heading">Heavy Atom Mol Wt</div>
                            <div className="info-component-value">
                              <Tooltip placement="topLeft" title={`${parseFloat(individualItem.details.heavy_atom_mol_wt).toFixed(2)} g/mol`}>{`${parseFloat(individualItem.details.heavy_atom_mol_wt).toFixed(2)} g/mol`}</Tooltip>
                            </div>
                          </div>
                        </div>
                        <div className="info-component">
                          <div className="info-component-main">
                            <div className="info-component-heading">Exact Mol Wt</div>
                            <div className="info-component-value" title={individualItem.details.exact_molecular_weight}>
                              <Tooltip placement="topLeft" title={`${parseFloat(individualItem.details.exact_molecular_weight).toFixed(2)} g/mol`}>{`${parseFloat(individualItem.details.exact_molecular_weight).toFixed(2)} g/mol`}</Tooltip>
                            </div>
                          </div>
                        </div>
                        <div className="info-component">
                          <div className="info-component-main">
                            <div className="info-component-heading">Mol Log P</div>
                            <div className="info-component-value">
                              <Tooltip placement="topLeft" title={parseFloat(individualItem.details.logp).toFixed(2)}>
                                {parseFloat(individualItem.details.logp).toFixed(2)}
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="info-component-row">
                        <div className="info-component">
                          <div className="info-component-main">
                            <div className="info-component-heading">Fraction CSP 3</div>
                            <div className="info-component-value">
                              <Tooltip placement="topLeft" title={individualItem.details.fraction_of_sp3_carbons}>
                                {parseFloat(individualItem.details.fraction_of_sp3_carbons).toFixed(2)}
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                        <div className="info-component">
                          <div className="info-component-main">
                            <div className="info-component-heading">TPSA</div>
                            <div className="value-combine-text">
                              <div className="info-component-value">
                                <Tooltip placement="topLeft" title={parseFloat(individualItem.details.topological_polar_surface_area).toFixed(2)}>
                                  {parseFloat(individualItem.details.topological_polar_surface_area).toFixed(2)}
                                </Tooltip>
                              </div>
                              <span className="angstrom-text">&#8491;</span>
                            </div>
                          </div>
                        </div>
                        <div className="info-component">
                          <div className="info-component-main">
                            <div className="info-component-heading">qed</div>
                            <div className="info-component-value">
                              <Tooltip placement="topLeft" title={individualItem.details.qed}>
                                {parseFloat(individualItem.details.qed).toFixed(2)}
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="dr-output-list">
              <div className="combinations-output-item">
                <div className="item-title">Molecular Structure Details </div>
                <div className="combinations-item-content">
                  <div className="combinations-drugs-list">
                    <div className="combinations-drugs-info">
                      <div className="info-component-row">
                        <div className="info-component">
                          <div className="info-component-main">
                            <div className="info-component-heading">Heavy Atom Count</div>
                            <div className="info-component-value">
                              <Tooltip placement="topLeft" title={individualItem.details.number_of_heavy_atoms}>
                                {individualItem.details.number_of_heavy_atoms}
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                        <div className="info-component">
                          <div className="info-component-main">
                            <div className="info-component-heading">Num Aliphatic Carbocycles</div>
                            <div className="info-component-value">
                              <Tooltip placement="topLeft" title={individualItem.details.aliphatic_carbocycles}>
                                {individualItem.details.aliphatic_carbocycles}
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                        <div className="info-component">
                          <div className="info-component-main">
                            <div className="info-component-heading">Num Aliphatic Heterocycles</div>
                            <div className="info-component-value">
                              <Tooltip placement="topLeft" title={individualItem.details.aliphatic_heterocycles}>
                                {individualItem.details.aliphatic_heterocycles}
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                        <div className="info-component">
                          <div className="info-component-main">
                            <div className="info-component-heading">Num Aliphatic Rings</div>
                            <div className="info-component-value">
                              <Tooltip placement="topLeft" title={individualItem.details.number_of_aliphatic_rings}>
                                {individualItem.details.number_of_aliphatic_rings}
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="info-component-row">
                        <div className="info-component">
                          <div className="info-component-main">
                            <div className="info-component-heading">Num Aromatic Carbocycles</div>
                            <div className="info-component-value">
                              <Tooltip placement="topLeft" title={individualItem.details.aromatic_carbocycles}>
                                {individualItem.details.aromatic_carbocycles}
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                        <div className="info-component">
                          <div className="info-component-main">
                            <div className="info-component-heading">Num Aromatic Heterocycles</div>
                            <div className="info-component-value" title={individualItem.details.aromatic_heterocycles}>
                              <Tooltip placement="topLeft" title={individualItem.details.aromatic_heterocycles}>
                                {individualItem.details.aromatic_heterocycles}
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                        <div className="info-component">
                          <div className="info-component-main">
                            <div className="info-component-heading">Num Aromatic Rings</div>
                            <div className="info-component-value">
                              <Tooltip placement="topLeft" title={individualItem.details.aromatic_rings}>
                                {individualItem.details.aromatic_rings}
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                        <div className="info-component">
                          <div className="info-component-main">
                            <div className="info-component-heading">Ring Count</div>
                            <div className="info-component-value">
                              <Tooltip placement="topLeft" title={individualItem.details.number_of_ring_counts}>
                                {individualItem.details.number_of_ring_counts}
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="info-component-row">
                        <div className="info-component">
                          <div className="info-component-main">
                            <div className="info-component-heading">Num H Acceptors</div>
                            <div className="info-component-value">
                              <Tooltip placement="topLeft" title={individualItem.details.hydrogen_bond_acceptors}>
                                {individualItem.details.hydrogen_bond_acceptors}
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                        <div className="info-component">
                          <div className="info-component-main">
                            <div className="info-component-heading">Num H Donors</div>
                            <div className="info-component-value">
                              <Tooltip placement="topLeft" title={individualItem.details.hydrogen_bond_donors}>
                                {individualItem.details.hydrogen_bond_donors}
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                        <div className="info-component">
                          <div className="info-component-main">
                            <div className="info-component-heading">Num Heteroatoms</div>
                            <div className="info-component-value">
                              <Tooltip placement="topLeft" title={individualItem.details.number_of_heteroatoms}>
                                {individualItem.details.number_of_heteroatoms}
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                        <div className="info-component">
                          <div className="info-component-main">
                            <div className="info-component-heading">Num Rotatable Bonds</div>
                            <div className="info-component-value">
                              <Tooltip placement="topLeft" title={individualItem.details.rotatable_bonds}>
                                {individualItem.details.rotatable_bonds}
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="info-component-row">
                        <div className="info-component">
                          <div className="info-component-main">
                            <div className="info-component-heading">Num Saturated Carbocycles</div>
                            <div className="info-component-value">
                              <Tooltip placement="topLeft" title={individualItem.details.num_saturated_carbocycles}>
                                {individualItem.details.num_saturated_carbocycles}
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                        <div className="info-component">
                          <div className="info-component-main">
                            <div className="info-component-heading">Num Saturated Heterocycles</div>
                            <div className="info-component-value">
                              <Tooltip placement="topLeft" title={individualItem.details.num_saturated_heterocycles}>
                                {individualItem.details.num_saturated_heterocycles}
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                        <div className="info-component">
                          <div className="info-component-main">
                            <div className="info-component-heading">Num Saturated Rings</div>
                            <div className="info-component-value">
                              <Tooltip placement="topLeft" title={individualItem.details.num_saturated_rings}>
                                {individualItem.details.num_saturated_rings}
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      )
    </div>
  );
}

ComponentDetailsPage.propTypes = {
  toggleComponent: PropTypes.func.isRequired,
  individualItem: PropTypes.objectOf.isRequired,
};

export default ComponentDetailsPage;
