import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../utils';

// get ctp output details
const GET_CTP_OUTPUT = 'GET_CTP_OUTPUT';
const GET_CTP_OUTPUT_SUCCESS = 'GET_CTP_OUTPUT_SUCCESS';
const GET_MORE_CTP_OUTPUT = 'GET_MORE_CTP_OUTPUT';
const GET_MORE_CTP_OUTPUT_SUCCESS = 'GET_MORE_CTP_OUTPUT_SUCCESS';
const GET_CTP_OUTPUT_FAILURE = 'GET_CTP_OUTPUT_FAILURE';

export const getCTPOutputAction = createAction(GET_CTP_OUTPUT);

const getCTPOutputSuccess = createAction(GET_CTP_OUTPUT_SUCCESS);

export const getMoreCTPOutputAction = createAction(GET_MORE_CTP_OUTPUT);

const getMoreCTPOutputSuccess = createAction(GET_MORE_CTP_OUTPUT_SUCCESS);

const getCTPOutputFaliure = createAction(GET_CTP_OUTPUT_FAILURE);

export const getCTPOutputEpic = (actions$) => actions$.pipe(
  ofType(GET_CTP_OUTPUT),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_ctp}/insight?${action.payload}`, 'get')).pipe(
    map((res) => getCTPOutputSuccess(res)),
    catchError((err) => of(getCTPOutputFaliure(err))),
  )),
);

export const getMoreCTPOutputEpic = (actions$) => actions$.pipe(
  ofType(GET_MORE_CTP_OUTPUT),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_ctp}/insight?${action.payload}`, 'get')).pipe(
    map((res) => getMoreCTPOutputSuccess(res)),
    catchError((err) => of(getCTPOutputFaliure(err))),
  )),
);
export const getCTPOutputReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(getCTPOutputAction, (state) => {
        state.loading = true;
      })
      .addCase(getCTPOutputSuccess, (state, action) => {
        state.data = { ...action.payload.response };
        state.loading = false;
        state.flag = true;
      })
      .addCase(getMoreCTPOutputAction, (state) => {
        state.loading = true;
      })
      .addCase(getMoreCTPOutputSuccess, (state, action) => {
        state.data = {
          total_count: action.payload.response.data.total_count,
          data: {
            ...state.data.insight_data,
            insight_data: [...state.data.insight_data, ...action.payload.response.data.insight_data],
          },
        };
        state.loading = false;
        state.flag = true;
      })
      .addCase(getCTPOutputFaliure, (state) => {
        state.error = true;
        state.flag = false;
        state.loading = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);

// isRead CTP
const IS_READ_CTP_OUTPUT = 'IS_READ_CTP_OUTPUT';
const IS_READ_CTP_OUTPUT_SUCCESS = 'IS_READ_CTP_OUTPUT_SUCCESS';
const IS_READ_CTP_OUTPUT_FAILURE = 'IS_READ_CTP_OUTPUT_FAILURE';

export const isReadCTPOutputAction = createAction(IS_READ_CTP_OUTPUT);

const isReadCTPOutputSuccess = createAction(IS_READ_CTP_OUTPUT_SUCCESS);

const isReadCTPOutputFailure = createAction(IS_READ_CTP_OUTPUT_FAILURE);

export const isReadCTPOutputEpic = (actions$) => actions$.pipe(
  ofType(IS_READ_CTP_OUTPUT),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_ctp}/readStatus?${action.payload}`, 'PUT')).pipe(
    map((res) => isReadCTPOutputSuccess(res)),
    catchError((err) => of(isReadCTPOutputFailure(err))),
  )),
);
export const isReadCTPOutputReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(isReadCTPOutputAction, (state) => {
        state.loading = true;
      })
      .addCase(isReadCTPOutputSuccess, (state, action) => {
        state.data = { ...action.payload.response };
        state.loading = false;
        state.flag = true;
      })
      .addCase(isReadCTPOutputFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.loading = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);
