import React, { useEffect, useState } from 'react';
import {
  Button, Divider, Input,
} from 'antd';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import queryString from 'query-string';
import { CloseOutlined } from '@ant-design/icons';
import Header from '../../../../common/components/Header';
import Loader from '../../../../common/components/Loader';
import useAppSelector from '../../../../utils/redux-hook';
// import { terminalsPath } from '../../../../app/components/Card/data';
import LazyLoadDiv from '../../../../common/components/LazyLoadDiv';
import Mixpanel from '../../../../utils/mixpanel';
import DiseaseBasedWorkspaceDetails from '../../container/DiseaseBasedWorkspaceDetails';
import { diseaseBasedJobsAction, diseaseBasedJobsResetAction, tiUpdateWorkspaceStatusAction } from './logic';
import { terminalsPath } from '../../../../app/components/Card/data';
import { generateTargetResetAction } from '../TargetIdInputPage/logic';

function MyWorkspacePageTI() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [currentJob, setCurrentJob] = useState({});
  const [currentService, setCurrentService] = useState([1, 0, 0]);
  const [isLoading, setIsLoading] = useState(true);
  const service = searchParams.get('service');
  const [serviceJobsList, setServiceJobsList] = useState({
    workspace: [],
    diseaseBased: [],
  });
  const diseaseBasedJobsResponse = useAppSelector((state) => state.diseaseBasedJobsResponse);
  const dispatch = useDispatch();
  const [editing, setEditing] = useState({
    isEditing: false,
    editingId: '',
    editingName: '',
  });
  const [searchText, setSearchText] = useState('');
  const [searchAndSort, setSearchAndSort] = useState({
    isSearch: false,
    isSort: false,
    searchValue: '',
    sortValue: 'desc',
  });
  const [diseaseBasedRequest, setDiseaseBasedRequest] = useState({
    filters: JSON.stringify({
      module_id: 'metad3_target_id',
      'user_input.service': 'DISEASE_BASED',
    }),
    searchQuery: (searchAndSort.searchValue.length > 0) ? searchAndSort.searchValue : undefined,
    sort_order: searchAndSort.sortValue,
    size: 10,
    from: 1,
  });

  const onChangedJob = (job) => {
    setCurrentJob(job);
    Mixpanel.track('TI Job selected', {
      action: 'TI Job selected from TI workspace',
      job_name: job.job_name,
      job_id: job.job_id,
      service,
      module: 'TI',
    });
  };

  useEffect(() => {
    setIsLoading(false);
    switch (service) {
      case 'Disease Based':
        setCurrentService([1, 0, 0]);
        break;
      default:
        setCurrentService([1, 0, 0]);
    }
  }, []);

  useEffect(() => {
    // setRequestObj({
    //   topIndications: {
    //     filters: JSON.stringify({
    //       module_id: 'metad3_iep',
    //       'user_input.service': 'Prioritize Indications',
    //     }),
    //     searchQuery: (searchAndSort.searchValue.length > 0) ? searchAndSort.searchValue : undefined,
    //     sort_order: searchAndSort.sortValue,
    //     size: 10,
    //     from: 1,
    //   },
    //   CCA: {
    //     filters: JSON.stringify({
    //       module_id: 'metad3_iep',
    //       'user_input.service': 'CCA',
    //     }),
    //     searchQuery: (searchAndSort.searchValue.length > 0) ? searchAndSort.searchValue : undefined,
    //     sort_order: searchAndSort.sortValue,
    //     // size: 100,
    //     size: 10,
    //     from: 1,
    //   },
    // });
    setDiseaseBasedRequest((prev) => ({
      ...prev,
      searchQuery: (searchAndSort.searchValue.length > 0) ? searchAndSort.searchValue : undefined,
      sort_order: searchAndSort.sortValue,
      // size: 100,
      size: 20,
      from: 1,

    }));
  }, [searchAndSort]);

  useEffect(() => {
    dispatch(diseaseBasedJobsAction(queryString.stringify(diseaseBasedRequest)));

    const intervalId = setInterval(() => {
      if (diseaseBasedJobsResponse.data) {
        dispatch(diseaseBasedJobsAction(queryString.stringify(diseaseBasedRequest)));
      }
    }, 10000);
    return () => clearInterval(intervalId);
  }, [diseaseBasedRequest]);

  useEffect(() => {
    if (diseaseBasedJobsResponse?.flag) {
      setServiceJobsList((prev) => ({
        ...prev,
        diseaseBased: diseaseBasedJobsResponse.data.data,
      }));
      if (!currentJob?.status && (service === 'DISEASE_BASED' || !service)) {
        setCurrentJob(diseaseBasedJobsResponse.data.data[0]);
      }
    }
  }, [diseaseBasedJobsResponse]);

  const formatDate = (epoch) => {
    const date = new Date(epoch * 1000);

    const day = date.getDate();
    const month = date.toLocaleString('en-US', { month: 'short' });

    return `${day} ${month}`;
  };
  const formatTime = (epoch) => {
    const date = new Date(epoch * 1000);

    const time = date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });

    return time;
  };
  const onEditName = (job) => {
    const request = {
      job_id: job.job_id,
      job_name: editing.editingName,
    };
    dispatch(tiUpdateWorkspaceStatusAction(queryString.stringify(request)));
    dispatch(diseaseBasedJobsAction(queryString.stringify(diseaseBasedRequest)));
    setEditing({ isEditing: false, editingId: '', editingName: '' });
    Mixpanel.track('TI Job name edited', {
      action: 'TI Edit Name from TI workspace clicked',
      job_name: request.job_name,
      job_id: request.job_id,
      service,
      module: 'TI',
    });
  };

  const jobsList = (serviceJobList) => (
    <div className="jobs-list">
      {serviceJobList?.map((job, index) => (
        <div className={`job-item${job?.job_id === currentJob?.job_id ? ' active' : ''}`} key={job?.job_id} style={{ display: 'flex', cursor: 'pointer' }} onClick={() => onChangedJob(job)} aria-hidden>
          <div className="job-info">
            {editing.isEditing && editing.editingId === index ? (
              <div>
                <Input
                  placeholder="Job Name"
                  value={editing.editingName ?? job.job_name ?? job.user_input.protein_name}
                  onChange={(e) => setEditing({ ...editing, editingName: e.target.value })}
                  onPressEnter={() => onEditName(job)}
                //   onBlur={() => setEditing({ isEditing: false, editingId: '' })}
                />
              </div>
            ) : (
              <div className="job-title">{job.job_name ?? job.user_input.protein_name}</div>
            )}
            <div className="job-created-at">
              {formatDate(job.created_date_time_ts)}
              <Divider type="vertical" />
              {formatTime(job.created_date_time_ts)}
            </div>
          </div>
          <div className="job-status-main">
            <div className={`job-status${job?.status.toLowerCase() === 'completed' ? ' completed' : job?.status.toLowerCase() === 'rejected' ? ' failed' : ' inprogress'}`}>{job?.status === 'REJECTED' ? 'Failed' : job?.status === 'PENDING' ? 'In Progress' : ''}</div>
          </div>
          <div className="job-name-edit-btn">
            <Button className="rename-edit-icon" onClick={() => setEditing({ isEditing: true, editingId: index })} />
          </div>
        </div>
      ))}
    </div>
  );

  const jobDetails = (job) => (
    <DiseaseBasedWorkspaceDetails job={job} />
  );

  const loadMoreData = (loadService) => {
    if (loadService === 0) {
      setDiseaseBasedRequest((prev) => {
        const { size } = prev;
        return {
          ...prev,
          size: size + 10,
        };
      });
    }
  };

  const serviceDropdowns = () => (
    <div className="service-dropdowns">
      <div role="presentation" className={`service-dropdown ${(currentService[0] === 1 ? 'open' : 'close')}`} onClick={() => { setCurrentService([1, 0, 0]); Mixpanel.track('TI Opened Service Dropdown', { action: 'TI Opened Service Dropdown', service: 'Proritize Indications', module: 'TI' }); }}>
        <div className="service-dropdown-title">
          Disease Based
        </div>
        {currentService[0] === 1 && (
          <div className="service-dropdown-content">
            {diseaseBasedJobsResponse?.data?.total_count >= 20 && (
              <LazyLoadDiv className="datatable-container-inner" loadMoreRows={() => loadMoreData(0)} id="jobs-list" total={diseaseBasedJobsResponse.data.total_count} currentTotal={(serviceJobsList.diseaseBased || []).length} height="calc(100vh - 280px)">

                {jobsList(serviceJobsList.diseaseBased)}
              </LazyLoadDiv>
            )}
            <div className="non-lazyload">
              {diseaseBasedJobsResponse?.data?.total_count < 20 && jobsList(serviceJobsList.diseaseBased)}
            </div>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div className="main-page main-page-modules">
      <Header className="header-home TI-header" logo="ti-logo" />
      <Loader loading={(diseaseBasedJobsResponse.loading) && isLoading}>
        <div className="ct-home-page my-workspace-page">
          <div className="my-workspace-main">
            <div className="jobs-list-view">
              <div className="btns-row">
                {!searchAndSort.isSearch && (
                  <div className="back-btn">
                    <Button
                      onClick={() => {
                        navigate(terminalsPath.metad3_target_id);
                        dispatch(diseaseBasedJobsResetAction());
                        dispatch(generateTargetResetAction());
                        Mixpanel.track('TI Back Button Clicked', {
                          action: 'TI Back Button Clicked from TI workspace',
                          module: 'TI',
                        });
                      }}
                      className="back-btn-icon"
                    >
                      Back
                    </Button>
                  </div>
                )}
                <div className="grouped-btns">
                  {!searchAndSort.isSearch && (
                    <div
                      role="presentation"
                      className="search-btn"
                      onClick={() => {
                        setSearchAndSort((prev) => ({
                          ...prev,
                          isSearch: true,
                        }));
                      }}
                    >
                      <div className="search-btn-icon" />
                    </div>
                  )}
                  {searchAndSort.isSearch && (
                    <Input
                      placeholder="Search Job"
                      value={searchText}
                      allowClear={{
                        clearIcon: <CloseOutlined onClick={() => {
                          setSearchText('');
                          setSearchAndSort((prev) => ({
                            ...prev,
                            isSearch: false,
                            searchValue: '',
                          }));
                        }}
                        />,
                      }}
                      onChange={(e) => {
                        setSearchText(e.target.value);
                      }}
                      onPressEnter={(e) => {
                        setSearchAndSort((prev) => ({
                          ...prev,
                          searchValue: e.target.value,
                        }));
                        Mixpanel.track('TI Job Searched', {
                          action: 'job searched on TI workspace',
                          query: e.target.value,
                          module: 'TI',
                        });
                      }}
                    />
                  )}
                  <div
                    role="presentation"
                    className="sort-btn"
                    onClick={() => {
                      Mixpanel.track('TI Jobs Sorted', {
                        action: 'job sorted on TI workspace',
                        sort: searchAndSort.sortValue,
                        module: 'TI',
                      });
                      if (searchAndSort.sortValue === 'asc') {
                        setSearchAndSort((prev) => ({
                          ...prev,
                          sortValue: 'desc',
                        }));
                      } else {
                        setSearchAndSort((prev) => ({
                          ...prev,
                          sortValue: 'asc',
                        }));
                      }
                      Mixpanel.track('TI Job Sorted', {
                        action: 'job sorted on TI workspace',
                        sort: searchAndSort.sortValue,
                        module: 'TI',
                      });
                    }}
                  >
                    <div className="sort-btn-icon" />
                  </div>
                </div>
              </div>
              {serviceDropdowns()}
            </div>

            {currentJob && <div className="job-details-view">{currentJob?.user_input && jobDetails(currentJob)}</div>}
          </div>
        </div>
      </Loader>
    </div>
  );
}

export default MyWorkspacePageTI;
