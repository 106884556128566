import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Header from '../../../../common/components/Header';
import SelectService from '../../container/SelectService';
import InputBox from '../../container/InputBox';
import { terminalsPath } from '../../../../app/components/Card/data';
import Loader from '../../../../common/components/Loader';
// import PendingStatusModal from '../../components/PendingStatusModal';
import useAppSelector from '../../../../utils/redux-hook';
import { sendNotification } from '../../../../common/components/Notification/logic';
import Mixpanel from '../../../../utils/mixpanel';
import SaveProjectModal from '../../container/SaveProjectModal';

function TargetIdInputPage() {
  const dispatch = useDispatch();

  const [inputs, setInputs] = useState({
    service: '',
    disease_name: '',
  });
  const [searchParams] = useSearchParams();
  const service = searchParams.get('service');
  const diseaseName = searchParams.get('diseaseName');
  const [disableButton, setDisableButton] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [requestBody, setRequestBody] = useState({});

  const generateTITargetResponse = useAppSelector((state) => state.generateTITargetResponse);
  const checkStatusResponse = useAppSelector((state) => state.checkTIStatus);

  const handleClick = () => {
    setRequestBody({
      service: inputs.service,
      disease_name: inputs.disease_name,
    });
    Mixpanel.track('Target ID Input submitted', {
      action: 'Target-Id Generate Target ID button clicked',
      disease_name: inputs.disease_name,
      module: 'Target ID',
      service,
    });
    setIsSubmitted(true);
  };

  const getInputs = (input) => {
    setInputs((prev) => ({
      ...prev,
      ...input,
    }));
  };

  const checkBtnDissabledForAccess = inputs.service === '' || inputs.service === undefined || inputs.disease_name === '' || inputs.disease_name === undefined;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setDisableButton(checkBtnDissabledForAccess);
  }, [inputs]);

  useEffect(() => {
    if (generateTITargetResponse.flag) {
      dispatch(
        sendNotification({
          type: 'REQUEST_SUCCESS',
          notificationType: 'success',
          message: generateTITargetResponse.data,
          showNotification: true,
        }),
      );
    }
  }, [JSON.stringify(generateTITargetResponse)]);

  return (
    <div className="main-page main-page-modules">
      <Header className="header-home ti-header" logo="ti-logo" width={216} isWorkspace modulePath={terminalsPath.metad3_target_id_workspace} />
      <Loader loading={checkStatusResponse.loading && !isSubmitted} error={checkStatusResponse.error}>
        <div className="ct-home-page">
          <div className="main-heading">
            <div className="main-title">Discover potential targets with TargetGenesis</div>
            <div className="main-description">Using our AI model you can discover and prioritise list of potential targets simply by providing the disease name.</div>
          </div>
          <SelectService inputHandler={getInputs} service={service} />
          <InputBox inputHandler={getInputs} cardState={inputs.card} diseaseName={diseaseName !== null ? diseaseName : ''} isDisabled={inputs.service === ''} />
          <div className="ct-home-page-footer">
            <button onClick={handleClick} disabled={disableButton} className="generate-in-btn" type="button">
              GENERATE TARGETS
            </button>
          </div>
        </div>
        {/* <PendingStatusModal open={isPending} /> */}
        <SaveProjectModal name={inputs.disease_name} service={inputs.service} open={isSubmitted} setOpen={setIsSubmitted} requestBody={requestBody} />
      </Loader>
    </div>
  );
}

export default TargetIdInputPage;
