import { Checkbox, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import LazyLoadDiv from '../../../../../../common/components/LazyLoadDiv';
import Mixpanel from '../../../../../../utils/mixpanel';

function StudiesListView({
  studyData, displayFilters, setDisplayFilters, setSelectedStudy, selectedStudy, setSelectedStudies, selectedStudies, loadMoreStudies, totalStudies,
}) {
  const [studiesList, setStudiesList] = useState([]);

  useEffect(() => {
    setStudiesList(selectedStudies?.map((item) => item.study_id));
  }, [selectedStudies]);

  useEffect(() => {
    setStudiesList(selectedStudies?.map((item) => item.study_id));
  }, []);

  const updateSelectedStudies = (studyId, bioprojectId) => {
    if (studiesList?.includes(studyId)) {
      setStudiesList(studiesList.filter((item) => item !== studyId));
      setSelectedStudies(selectedStudies.filter((item) => item.study_id !== studyId));
    } else {
      setStudiesList([...studiesList, studyId]);
      setSelectedStudies([...selectedStudies, { study_id: studyId, bioproject_id: bioprojectId }]);
    }
  };
  const displaytags = (temp) => (
    <div className="study-tag-container">
      {temp?.map((item) => (
        <div key={item} className="study-tag-item">
          {item}
        </div>
      ))}
    </div>
  );
  const studyListItem = (study1) => (
    <div className={`card-list-item-main${displayFilters ? ' inactive' : ''}`}>
      <div
        key={study1.accession}
        className={`card-list-item study-list-item${selectedStudy.accession === study1.accession && !displayFilters ? ' active' : ''}`}
        onClick={() => {
          setDisplayFilters(false);
          setSelectedStudy(study1);
          Mixpanel.track('Biomarker Opened Study Details View', {
            action: 'Clicked on the study Biomarker',
            study: study1.title,
          });
        }}
        aria-hidden
      >
        <div className="checkbox-row card-list-item-checkbox">
          <div role="presentation" onClick={(e) => e.stopPropagation()} onKeyDown={null}>
            <Checkbox
              checked={studiesList?.includes(study1.accession)}
              onChange={(e) => {
                e.stopPropagation();
                updateSelectedStudies(study1.accession, study1.bioproject);
              }}
            >
              {study1.accession}
            </Checkbox>
          </div>
          {displaytags([study1.organism])}
        </div>
        <div className="study-title card-list-item-title">{study1.title}</div>
        <div className="study-key-value card-list-item-value">
          <div className="study-details-key card-list-item-value-title">Experiment type:</div>
          <div className="study-details-value card-list-item-value-title"><Tooltip title={study1.experiment_types}>{study1.experiment_types}</Tooltip></div>
        </div>
        <div className="study-key-value card-list-item-value">
          <div className="study-details-key card-list-item-value-key">Overall design:</div>
          <div className="study-details-value card-list-item-value-val"><Tooltip title={study1.overall_design}>{study1.overall_design}</Tooltip></div>
        </div>
      </div>
    </div>
  );
  const displayStudyList = () => studyData?.map((item) => studyListItem(item));
  return <div className={`study-list-container${displayFilters ? ' parent' : ' child'}`}>{studyData.length === 0 ? <div className="no-studies-found">No studies found</div> : <LazyLoadDiv className="study-list-lazy-loading" id="study-list" total={totalStudies} currentTotal={(studyData || []).length} height="calc(100vh - 206px)" loadMoreRows={loadMoreStudies}>{displayStudyList()}</LazyLoadDiv>}</div>;
}
StudiesListView.propTypes = {
  studyData: PropTypes.arrayOf.isRequired,
  setDisplayFilters: PropTypes.func.isRequired,
  selectedStudy: PropTypes.objectOf.isRequired,
  setSelectedStudy: PropTypes.func.isRequired,
  displayFilters: PropTypes.bool.isRequired,
  setSelectedStudies: PropTypes.func.isRequired,
  selectedStudies: PropTypes.arrayOf.isRequired,
  loadMoreStudies: PropTypes.func.isRequired,
  totalStudies: PropTypes.number.isRequired,
};
export default StudiesListView;
