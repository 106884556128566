import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Mixpanel from '../../../utils/mixpanel';

function TerminalInfo({ current, data, setOpen }) {
  const [cur, setCur] = useState(0);
  const handlClick = () => {
    Mixpanel.track('Demo Requested', {
      action: 'request demo button click',
      view: 'landing page',
      btn: 'Terminals information window',
    });
    setOpen(true);
  };

  let timer = null;
  useEffect(() => {
    if (current !== cur) {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => setCur(current), 500);
    }
  }, [current]);

  const renderItem = () => data.map((item) => {
    if (item.key === current) {
      return (
        <div className="slide-terminal" style={{ position: 'relative' }}>
          {item.content}
          <button className="req-demo-btn" onClick={() => handlClick()} type="button">
            Request a Demo
          </button>
        </div>
      );
    }
    return null;
  });

  return (
    <div className="terminal-info-container">
      <div className="terminal-info-container-slide" rows={1}>
        {renderItem()}
      </div>
    </div>
  );
}

TerminalInfo.propTypes = {
  current: PropTypes.number.isRequired,
  data: PropTypes.instanceOf(Array).isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default TerminalInfo;
