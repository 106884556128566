import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import Status from '../../../common/container/Status';
import Notification from '../../../common/components/Notification';
import Footer from '../../../common/components/Footer';
import CookieSupport from '../../../common/components/CookieSupport';
import { getCookie, setCookie, removeCookies } from '../../../utils';
import Loader from '../../../common/components/Loader';
import { sendNotification } from '../../../common/components/Notification/logic';
import webAuth from '../../../utils/webAuth';
import { removeSession, setSession } from '../../../common/container/Status/logic';
import Mixpanel from '../../../utils/mixpanel';

function Main() {
  const [checkLandingPage, setCheckLandingPage] = useState(false);
  let timer = null;

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const timer2 = null;
  useEffect(() => {
    if (getCookie('pageLoading') === 'true' && location.hash.indexOf('access_token') === -1) {
      setCookie(false, 'pageLoading');
    }
  }, []);

  const getToken = async (token) => {
    try {
      const response = await axios.get(`${process.env.apiUrl}/v1/auth/callback?source=META_D3`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      const fullName = `${response.data.profile.full_name}`;
      const userName = `${response.data.profile.id}`;
      const bearer = `Bearer ${response.data.accessToken}`;
      if (timer2) {
        clearTimeout(timer2);
      }
      setCookie(JSON.stringify(response.data.permissions), 'permissions');
      setCookie(response.data.refreshToken, 'refreshToken');
      setCookie(userName, 'userName');
      setCookie(fullName, 'fullName');
      setCookie(response.data.profile.organization || 'individual', 'org');
      dispatch(setSession(bearer));
      Mixpanel.identify(`${userName}@${response.data.profile.organization || 'individual'}`);
      Mixpanel.people.set({
        $name: fullName,
      });
      setCookie(false, 'pageLoading');
      navigate('/home', { replace: true });
      return 0;
    } catch (err) {
      dispatch(
        sendNotification({
          type: 'LOGIN_FAILURE-1',
          notificationType: 'error',
          message: err?.message || 'Oops something went wrong, please try again',
          showNotification: true,
        }),
      );
      Mixpanel.track('Login Failure', {
        action: 'Login failure',
        error: `${err?.message} || ${err}`,
      });
      if (err?.request?.status === 401) {
        const params = {
          returnTo: process.env.appUrl,
          clientID: process.env.auth0.clientId,
        };
        webAuth.logout(params);
        dispatch(removeSession());
        removeCookies();
        return 1;
      }
      navigate('/', { replace: true });
      return 0;
    }
  };

  const checkSocialSignUp = async (firstName, lastName, emailId, accessToken) => {
    try {
      const response = await axios.post(`${process.env.apiUrl}/v1/auth/socialSignup`, {
        first_name: firstName,
        last_name: lastName || '',
        email: emailId,
      });
      if (response.data.data.is_logout === false) {
        const bearer = `Bearer ${accessToken}`;
        setCookie(bearer, 'accessToken');
        getToken(accessToken);
      } else {
        const params = {
          returnTo: process.env.appUrl,
          clientID: process.env.auth0.clientId,
        };
        webAuth.logout(params);
        dispatch(removeSession());
        removeCookies();
      }
    } catch (err) {
      dispatch(
        sendNotification({
          type: 'LOGIN_FAILURE-2',
          notificationType: 'error',
          message: err?.message || 'Oops something went wrong, please try again',
          showNotification: true,
        }),
      );
      setCookie(false, 'pageLoading');
      Mixpanel.track('Login Failure', {
        action: 'Login failure',
        error: `${err?.message} || ${err}`,
      });
    }
  };

  let timer3 = null;

  const checkAccesToken = () => {
    webAuth.parseHash({ hash: window.location.hash }, (err, authResult) => {
      if (err) {
        dispatch(
          sendNotification({
            type: 'LOGIN_FAILURE-3',
            notificationType: 'error',
            message: err.errorDescription,
            showNotification: true,
          }),
        );
      }
      if (!authResult) {
        return null;
      }

      webAuth.client.userInfo(authResult.accessToken, (error, user) => {
        if (user) {
          let userName = user.name;
          let userNameVal = [];
          if (/\S+@\S+\.\S+/.test(userName)) {
            // check if the user name in email format
            userName = userName.split('@'); // split the email into two

            if (userName[0]) {
              userNameVal = userName[0].split('.'); // split on dot
            }
          } else if (userName) {
            userNameVal = userName.split(' '); // split on space for non email
          }
          const guestUser = ['Guest', 'User'];
          const firstName = user.given_name || (userNameVal.length ? (userNameVal[0] ? userNameVal[0] : guestUser[0]) : guestUser[0]);
          const lastName = user.family_name || (userNameVal.length > 1 ? (userNameVal[1] ? userNameVal[1] : guestUser[1]) : guestUser[1]);

          if (timer3) {
            clearTimeout(timer3);
          }
          timer3 = setTimeout(() => {
            checkSocialSignUp(firstName, lastName, user.email, authResult.accessToken);
          }, 0);
        }
      });
      return undefined;
    });
  };

  useEffect(() => {
    if (location.hash.indexOf('access_token') !== -1) {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        checkAccesToken();
      }, 100);
    }
  }, [location.hash.indexOf('access_token')]);

  useEffect(() => {
    if (window.location.pathname === '/') {
      setCheckLandingPage(true);
    }
  }, [window.location.pathname]);

  return (
    <div className="app">
      <Loader error={false} loading={getCookie('pageLoading') === 'true' && window.location.pathname === '/'}>
        <div className="app-container">
          <Outlet />
          {checkLandingPage && (
            <div className="sample-large full-height-no-header-footer login-page-main">
              <div className="login-page" />
            </div>
          )}
        </div>
        <Status />
        <Notification />
        <CookieSupport />
        <Footer />
      </Loader>
    </div>
  );
}

export default Main;
