import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../../utils';

// check status
const AE_GET_SEQUENCES = 'AE_GET_SEQUENCES';
const AE_GET_SEQUENCES_SUCCESS = 'AE_GET_SEQUENCES_SUCCESS';
const AE_GET_SEQUENCES_FAILURE = 'AE_GET_SEQUENCES_FAILURE';
const AE_GET_SEQUENCES_RESET = 'AE_GET_SEQUENCES_RESET';

export const aeGetSequencesAction = createAction(AE_GET_SEQUENCES);
export const aeGetSequencesResetAction = createAction(AE_GET_SEQUENCES_RESET);

const aeGetSequencessSuccess = createAction(AE_GET_SEQUENCES_SUCCESS);
const aeGetSequencesFailure = createAction(AE_GET_SEQUENCES_FAILURE);

export const aeGetSequencesEpic = (actions$) => actions$.pipe(
  ofType(AE_GET_SEQUENCES),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_ae}/getSequences?${action.payload}`, 'get')).pipe(
    map((res) => aeGetSequencessSuccess(res)),
    catchError((err) => of(aeGetSequencesFailure(err))),
  )),
);

export const aeGetSequencesReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(aeGetSequencesAction, (state) => {
        state.loading = true;
      })
      .addCase(aeGetSequencessSuccess, (state, action) => {
        state.data = { ...action.payload.response.data };
        state.loading = false;
        state.flag = true;
      })
      .addCase(aeGetSequencesFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(aeGetSequencesResetAction, (state) => {
        state.data = {};
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);
