import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Loader from '../../../../common/components/Loader';

function ImageWithLoader({ src, desc }) {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = src;

    img.onload = () => {
      setImageLoaded(true);
    };

    img.onerror = () => {
      setImageLoaded(false); // Handle error state as needed
    };
  }, [src]);

  return (
    <div className="heatmap-img sar-heatmap-img">
      <Loader loading={!imageLoaded} height="calc(100vh - 250px)">
        <img
          src={src}
          style={{
            display: imageLoaded ? 'block' : 'none',
            width: 'inherit',
            height: '295px',
            margin: '0 auto',
          }}
          alt={desc}
        />
      </Loader>
    </div>
  );
}

ImageWithLoader.propTypes = {
  src: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
};

export default ImageWithLoader;
