import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../../utils';

const GET_ASSOCIATED_PROTEINS_DISTRIBUTION_LIST = 'GET_ASSOCIATED_PROTEINS_DISTRIBUTION_LIST';
const GET_ASSOCIATED_PROTEINS_DISTRIBUTION_LIST_SUCCESS = 'GET_ASSOCIATED_PROTEINS_DISTRIBUTION_LIST_SUCCESS';
const GET_MORE_ASSOCIATED_PROTEINS_DISTRIBUTION_LIST = 'GET_MORE_ASSOCIATED_PROTEINS_DISTRIBUTION_LIST';
const GET_MORE_ASSOCIATED_PROTEINS_DISTRIBUTION_LIST_SUCCESS = 'GET_MORE_ASSOCIATED_PROTEINS_DISTRIBUTION_LIST_SUCCESS';
const GET_ASSOCIATED_PROTEINS_DISTRIBUTION_LIST_FAILURE = 'GET_ASSOCIATED_PROTEINS_DISTRIBUTION_LIST_FAILURE';

export const getAssociatedProteinsDistributionListAction = createAction(GET_ASSOCIATED_PROTEINS_DISTRIBUTION_LIST);
const getAssociatedProteinsDistributionListSuccess = createAction(GET_ASSOCIATED_PROTEINS_DISTRIBUTION_LIST_SUCCESS);
export const getMoreAssociatedProteinsDistributionListAction = createAction(GET_MORE_ASSOCIATED_PROTEINS_DISTRIBUTION_LIST);
const getMoreAssociatedProteinsDistributionListSuccess = createAction(GET_MORE_ASSOCIATED_PROTEINS_DISTRIBUTION_LIST_SUCCESS);
const getAssociatedProteinsDistributionListFailure = createAction(GET_ASSOCIATED_PROTEINS_DISTRIBUTION_LIST_FAILURE);

export const getAssociatedProteinsDistributionListEpic = (actions$) => actions$.pipe(
  ofType(GET_ASSOCIATED_PROTEINS_DISTRIBUTION_LIST),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_iep}/associatedProteinListData?${action.payload}`, 'get')).pipe(
    map((res) => getAssociatedProteinsDistributionListSuccess(res)),
    catchError((err) => of(getAssociatedProteinsDistributionListFailure(err))),
  )),
);

export const getMoreAssociatedProteinsDistributionListEpic = (actions$) => actions$.pipe(
  ofType(GET_MORE_ASSOCIATED_PROTEINS_DISTRIBUTION_LIST),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_iep}/associatedProteinListData?${action.payload}`, 'get')).pipe(
    map((res) => getMoreAssociatedProteinsDistributionListSuccess(res)),
    catchError((err) => of(getAssociatedProteinsDistributionListFailure(err))),
  )),
);

export const getAssociatedProteinsDistributionListReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(getAssociatedProteinsDistributionListAction, (state) => {
        state.loading = true;
      })
      .addCase(getAssociatedProteinsDistributionListSuccess, (state, action) => {
        state.data = { ...action.payload.response };
        state.loading = false;
        state.flag = true;
      })
      .addCase(getMoreAssociatedProteinsDistributionListAction, (state) => {
        state.loading = false;
      })
      .addCase(getMoreAssociatedProteinsDistributionListSuccess, (state, action) => {
        state.data = {
          total_count: action.payload.response.total_count,
          data: [
            ...state.data.data,
            ...action.payload.response.data,
          ],
        };
        state.loading = false;
        state.flag = true;
      })
      .addCase(getAssociatedProteinsDistributionListFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);
