import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import InputDropdown from '../../components/InputDropdown';
import Mixpanel from '../../../../utils/mixpanel';
import AdvanceSearchView from '../AdvanceSearchView';

function InputBoxTopIndications({
  inputHandler, proteinName, isDisabled, serviceName, updateProteinItem,
}) {
  const [values, setValues] = useState({});
  const [defaultProteinName, setDefaultProteinName] = useState('');
  const [isAdvanceSearch, setIsAdvanceSearch] = useState(false);

  useEffect(() => {
    inputHandler(values);
  }, [values]);

  useEffect(() => {
    setDefaultProteinName(proteinName || '');
  }, [proteinName]);

  const handleChange = (value) => {
    setValues((prev) => ({ ...prev, protein_name: value }));
  };

  return (
    <div className="provide-input-container dc-provide-input-container">
      <div className={`input-container ${isDisabled ? 'disabled' : ''}`}>
        <div className="input-container-title">Provide Input</div>
        <div className="input-fields">
          <div className="dropdown-container">
            <InputDropdown index={0} changeHandler={handleChange} proteinName={defaultProteinName} isDisabled={isDisabled} setProteinName={setDefaultProteinName} setProteinItem={updateProteinItem} />
            <div className="try-example-row">
              <div className="input-fields-value try-example-section">
                <text className="try-example-section-title">Try Example: </text>
                <text
                  style={{ cursor: 'pointer', color: 'blue' }}
                  onClick={() => {
                    Mixpanel.track('IEP try example clicked', {
                      action: 'IEP Try example at Input page clicked',
                      selected_protein: 'DLL3',
                      service: 'Prioritize Top Indications',
                    });
                    setDefaultProteinName('DLL3');
                    updateProteinItem({ gene_name: 'DLL3', uid: 'PROT_ID_9606_a39ee0dbc72871f2d3c39685fb730753b47045f1' });
                  }}
                >
                  DLL3
                </text>
              </div>
              <div className="advance-search">
                <div
                  className="advance-search-txt"
                  onClick={() => {
                    setIsAdvanceSearch(true);
                    Mixpanel.track('IEP advance search clicked', {
                      action: 'IEP Advance search at Input page clicked',
                      service: 'Prioritize Top Indications',
                    });
                  }}
                  aria-hidden
                  style={{ cursor: 'pointer', color: 'blue' }}
                >
                  Advance Search
                </div>
              </div>
              <AdvanceSearchView isOpen={isAdvanceSearch} setIsAdvanceSearch={setIsAdvanceSearch} serviceName={serviceName} isAdvanceSearch={isAdvanceSearch} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

InputBoxTopIndications.propTypes = {
  inputHandler: PropTypes.func.isRequired,
  proteinName: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  serviceName: PropTypes.string.isRequired,
  updateProteinItem: PropTypes.func.isRequired,
};

export default InputBoxTopIndications;
