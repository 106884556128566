/* eslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import SamplesListView from './SamplesListView';
import SampleDetails from './SampleDetails';
import CategoriesViewSamples from './CategoriesViewSamples';
import { getSampleFiltersAction } from '../../../pages/NewBiomarkerInputPage/logic';
import useAppSelector from '../../../../../utils/redux-hook';
import Loader from '../../../../../common/components/Loader';
import { getMoreSamplesAction, getSamplesAction } from './logic';
import GroupedSamplesView from '../GroupSamples/GroupedSamplesView';
import { checkBiomarkerStatusAction, getJobIdAction } from '../../../pages/BiomarkerInputPage/logic';
import queryString from 'query-string';
import { useNavigate } from 'react-router-dom';
import { FloatButton } from 'antd';
import { terminalsPath } from '../../../../../app/components/Card/data';
import Mixpanel from '../../../../../utils/mixpanel';

function ViewSamples({ currentIndex, setCurrentIndex, studiesSelected, filtersSelected, setFiltersSelected, isTryExample, groupA, groupB, setGroupA, setGroupB, setOpenModal }) {
  const [displayCategories, setDisplayCategories] = useState(true);
  const [selectedSample, setSelectedSample] = useState();
  const [filters, setFilters] = useState({});
  const [samplesData, setSamplesData] = useState([]);
  const [samplesSelected, setSamplesSelected] = useState([]);
  const [isProceedDisabled, setIsProceedDisabled] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const getSampleFiltersResponse = useAppSelector((state) => state.getSampleFiltersResponse);
  const getSamplesResponse = useAppSelector((state) => state.getSamplesResponse);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const checkStatusResponse = useAppSelector((state) => state.getBiomarkerJobIdStatus);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalSamplesCount, setTotalSamplesCount] = useState(0);

  useEffect(() => {
    const body = {
      filters: {
        study: [
          {
            operator: 'OR',
            value: 'GSE108345',
          },
        ],
      },
    };
    body.filters.study = studiesSelected.map((item) => ({ operator: 'OR', value: item.study_id }));
    setFiltersSelected((prev) => ({ ...prev, study: studiesSelected.map((item) => item.study_id), studies: studiesSelected.map((item) => item.study_id) }));
    const request = {
      params: queryString.stringify({
        from: 1,
        size: 10,
      }),
      body,
    };

    dispatch(getSampleFiltersAction(body));
    dispatch(getSamplesAction(request));
  }, []);

  useEffect(() => {
    if (checkStatusResponse.flag && checkStatusResponse.data.length > 0) {
      if (checkStatusResponse.data[0]?.status === 'PENDING') {
        if (checkStatusResponse.data[0]?.is_read === false) {
          setOpenModal(true);
        }
      } else if (checkStatusResponse.data[0]?.status === 'COMPLETED' && checkStatusResponse.data[0]?.is_read !== true) {
        navigate(`${terminalsPath.metad3_biomarker_output}?jobId=${checkStatusResponse.data[0].job_id}&studyGroup=${checkStatusResponse.data[0].user_input.study_group}&service=${checkStatusResponse.data[0].user_input.service}`);
      } else if (checkStatusResponse.data[0]?.status === 'REJECTED') {
        setOpenModal(false);
      }
    }
  }, [JSON.stringify(checkStatusResponse)]);

  const updateSamplesList = (samples, groupName, removeFlag = false) => {
    if (groupName === 'A') {
      setFiltersSelected((prev) => ({ ...prev, group_1_sample_ids: [...samples] }));
      if (removeFlag) {
        setGroupA(() => [...samples]);
      } else {
        setGroupA((prev) => [...prev, ...samples]);
      }
    }
    if (groupName === 'B') {
      setFiltersSelected((prev) => ({ ...prev, group_2_sample_ids: [...samples] }));
      if (removeFlag) {
        setGroupB(() => [...samples]);
      } else {
        setGroupB((prev) => [...prev, ...samples]);
      }
    }
  };

  useEffect(() => {
    const body = {
      filters: {},
    };
    body.filters.study = filtersSelected?.study?.map((item) => ({ operator: filtersSelected?.studies?.includes(item) ? 'OR' : 'NOT', value: item }));
    let sampleIds = [];
    if (filtersSelected?.group_1_sample_ids !== undefined) sampleIds = [...sampleIds, ...filtersSelected.group_1_sample_ids];
    if (filtersSelected?.group_2_sample_ids !== undefined) sampleIds = [...sampleIds, ...filtersSelected.group_2_sample_ids];
    sampleIds = [...new Set(sampleIds)];
    body.filters.sample = sampleIds.map((item) => ({ operator: 'NOT', value: item }));
    if (body.filters.sample.length === 0) {
      delete body.filters.sample;
    }
    const request = {
      params: queryString.stringify({
        from: 1,
        size: 10,
      }),
      body,
    };
    dispatch(getSamplesAction(request));
  }, [groupA, groupB]);

  const updateSampleListName = (name = '', groupName = '') => {
    if (groupName === 'A') {
      setFiltersSelected((prev) => ({ ...prev, group_1_name: name }));
    } else if (groupName === 'B') {
      setFiltersSelected((prev) => ({ ...prev, group_2_name: name }));
    } else {
      setFiltersSelected((prev) => ({ ...prev, group_1_name: 'Group 1', group_2_name: 'Group 2' }));
    }
  };

  const updateProceedBtnStatus = (list1, list2, pipeline) => {
    if (currentIndex === 3) {
      if (list1.length > 0 && list2.length > 0) {
        setIsProceedDisabled(false);
      } else {
        setIsProceedDisabled(true);
      }
    } else if (pipeline !== '') {
      setIsProceedDisabled(false);
    } else {
      setIsProceedDisabled(true);
    }
  };

  useEffect(() => {
    if (getSampleFiltersResponse.loading === false && getSampleFiltersResponse.data.isNotEmpty) {
      setFilters(getSampleFiltersResponse);
    }
  }, [getSampleFiltersResponse]);

  useEffect(() => {
    if (getSamplesResponse.flag) {
      setSamplesData(getSamplesResponse?.data?.data?.documents);
      setTotalSamplesCount(getSamplesResponse?.data?.data?.total);
    }
  }, [getSamplesResponse]);

  useEffect(() => {
    let interval = '';
    if (submitted) {
      interval = setInterval(() => {
        const request = {
          service: 'RNA_SEQ',
        };
        dispatch(checkBiomarkerStatusAction(queryString.stringify(request)));
      }, 2000);
    }
    return () => {
      if (interval !== '') {
        clearInterval(interval);
      }
    };
  }, [submitted]);

  const loadMoreData = () => {
    setCurrentPage(currentPage + 1);
    const body = {
      filters: {},
    };
    body.filters.study = filtersSelected?.study?.map((item) => ({ operator: filtersSelected?.studies?.includes(item) ? 'OR' : 'NOT', value: item }));
    let sampleIds = [];
    if (filtersSelected?.group_1_sample_ids !== undefined) sampleIds = [...sampleIds, ...filtersSelected.group_1_sample_ids];
    if (filtersSelected?.group_2_sample_ids !== undefined) sampleIds = [...sampleIds, ...filtersSelected.group_2_sample_ids];
    sampleIds = [...new Set(sampleIds)];
    body.filters.sample = sampleIds.map((item) => ({ operator: 'NOT', value: item }));
    if (body.filters.sample.length === 0) {
      delete body.filters.sample;
    }
    const request = {
      params: queryString.stringify({
        from: currentPage + 1,
        size: 10,
      }),
      body,
    };
    dispatch(getMoreSamplesAction(request));
  };

  return (
    <div className="select-preferred-studies-container">
      <div className="back-btn-row">
        <div
          className="back-btn-select-study"
          style={{ cursor: 'pointer', color: 'blue' }}
          onClick={() => {
            if (currentIndex === 3) {
              setFiltersSelected((prev) => {
                delete prev.group_1_name;
                delete prev.group_2_name;
                delete prev.group_1_sample_ids;
                delete prev.group_2_sample_ids;
                return prev;
              });
            }
            if (currentIndex === 2) {
              setFiltersSelected((prev) => {
                delete prev.study;
                delete prev.studies;
                return prev;
              });
            }
            setCurrentIndex(currentIndex - 1);
          }}
          aria-hidden
        >
          {'<-- Back'}
        </div>
        <div className="back-btn-beside-title">{`${totalSamplesCount} samples from all ${studiesSelected.length} selected studies`}</div>
      </div>
      <div className="select-preferred-studies-main back-to-filter-btn">
        <Loader loading={filters.length === 0}>
          {!displayCategories && <FloatButton onClick={() => setDisplayCategories(true)} />}
          <CategoriesViewSamples studiesSelected={studiesSelected} filtersSelected={filtersSelected} setFiltersSelected={setFiltersSelected} setSamplesSelected={setSamplesSelected} displayCategoriesFlag={displayCategories} isTryExample={isTryExample} />
          <Loader loading={getSamplesResponse.loading}>
            <SamplesListView sampleData={samplesData} displayCategories={displayCategories} setDisplayCategories={setDisplayCategories} selectedSample={selectedSample} setSelectedSample={setSelectedSample} setSamplesSelected={setSamplesSelected} samplesSelected={samplesSelected} isGrouping={currentIndex !== 2} isTryExample={isTryExample} loadMoreSamples={loadMoreData} totalCount={totalSamplesCount} />
          </Loader>
          <SampleDetails sample={selectedSample} displayCategories={displayCategories} />
        </Loader>
      </div>
      {currentIndex !== 2 && <GroupedSamplesView groupA={groupA} groupB={groupB} samplesSelected={samplesSelected} setSamplesSelected={setSamplesSelected} currentIndex={currentIndex} updateProceedBtnStatus={updateProceedBtnStatus} filtersSelected={filtersSelected} setFiltersSelected={setFiltersSelected} updateSampleListName={updateSampleListName} updateSamplesList={updateSamplesList} isTryExample={isTryExample} />}
      <div className="proceed-footer-btn">
        <button
          onClick={() => {
            if (currentIndex < 5) {
              if (currentIndex === 4) {
                setOpenModal(true);
                const request = {
                  service: 'RNA_SEQ',
                  study_group: 'Study_4',
                  job_name: 'default'
                };
                dispatch(getJobIdAction(queryString.stringify(request)));
                setSubmitted(true);
                Mixpanel.track('Biomarker Generated DEGs', {
                  action: 'Biomarker Generate DEGs button clicked',
                  studies_selected: studiesSelected,
                  filters_selected: filtersSelected,
                  group_1: groupA,
                  group_2: groupB,
                  module: 'Biomarker',
                });
              }
              setCurrentIndex((prev) => prev + 1);
              if (currentIndex === 2) {
                Mixpanel.track('Biomarker Proceeded to Sample Grouping', {
                  action: 'Biomarker Proceed to Sample Grouping button clicked',
                  studies_selected: studiesSelected,
                  filters_selected: filtersSelected,
                  module: 'Biomarker',
                });
              } else if (currentIndex === 3) {
                Mixpanel.track('Biomarker Proceeded to Pipeline Selection', {
                  action: 'Biomarker Proceed to Pipeline Selection button clicked',
                  studies_selected: studiesSelected,
                  filters_selected: filtersSelected,
                  group_1: groupA,
                  group_2: groupB,
                  module: 'Biomarker',
                });
              }
            }
          }}
          className="submit-btn"
          type="button"
          disabled={currentIndex === 2 ? false : isProceedDisabled}
        >
          {currentIndex === 2 ? 'Proceed to Sample Grouping' : currentIndex === 3 ? 'Proceed to Pipeline Selection' : 'Generate DEGs'}
        </button>
      </div>
    </div>
  );
}
ViewSamples.propTypes = {
  currentIndex: PropTypes.number.isRequired,
  setCurrentIndex: PropTypes.func.isRequired,
  studiesSelected: PropTypes.arrayOf.isRequired,
  filtersSelected: PropTypes.objectOf.isRequired,
  setFiltersSelected: PropTypes.func.isRequired,
  isTryExample: PropTypes.bool.isRequired,
  groupA: PropTypes.arrayOf.isRequired,
  groupB: PropTypes.arrayOf.isRequired,
  setGroupA: PropTypes.func.isRequired,
  setGroupB: PropTypes.func.isRequired,
  setOpenModal: PropTypes.func.isRequired,
};
export default ViewSamples;
