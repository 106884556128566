/* eslint-disable */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Tag } from 'antd';
import LazyLoadDiv from '../../../../common/components/LazyLoadDiv';
import Loader from '../../../../common/components/Loader';
import SvgLoader from '../../../../common/components/SvgLoader';

function OncoTaggingTable({ data, loadMoreData, outputResponse }) {
  const [totalCount, setTotalCount] = useState(0);
  //   const [activeSort, setActiveSort] = useState('');
  //   const [filters, setFilters] = useState({
  //     'upRegulated': true,
  //     'downRegulated': true,
  //   });
  useEffect(() => {
    // oncoTaggingOutputResponse?.data?.data
    setTotalCount(outputResponse?.data?.total_count);
  }, []);

  //   const updateParameters = (type, order, key) => {
  //     updateParams(order, type, filters);
  //     setActiveSort(key);
  //   };

  //   const updatedFilters = (e) => {
  //     setFilters({
  //       ...filters,
  //       [e.target.name]: e.target.checked,
  //     });
  //     updateFilters({
  //       ...filters,
  //       [e.target.name]: e.target.checked,
  //     });
  //   };

  //   const filterContent = (
  //     <div className="filters-tooltip" style={{ padding: 10 }}>
  //       <Checkbox className="filter-item" name="upRegulated" onChange={updatedFilters} checked={filters.upRegulated}>
  //         Up Regulated
  //       </Checkbox>
  //       <Checkbox className="filter-item" name="downRegulated" onChange={updatedFilters} checked={filters.downRegulated}>
  //         Down Regulated
  //       </Checkbox>
  //     </div>
  //   );

  const tableRow = (name, preferred_name, id) => (
    <div className="table-row">
      <div className="table-cell">{name}</div>
      <div className="table-cell">{preferred_name}</div>
      <div className="table-cell">{id}</div>
    </div>
  );

  const tableRowList = () => {
    const rows = data.map((item) => <div className="table-row-container">{tableRow(item?.ontology_text, item?.preferred_name, item?.token)}</div>);
    return rows;
  };

  return (
    <div className="table-container" style={{ color: 'black' }}>
      <div className="table-header">
        <div className="table-cell">Ontology Name</div>
        <div className="table-cell">
          <div className="title-with-sort">
            <div className="table-cell-title">
              Preferred Name
            </div>
          </div>
        </div>
        <div className="table-cell">
          <div className="title-with-sort">
            <div className="table-cell-title">Ontology ID</div>
          </div>
        </div>
      </div>
      <div className="table-body-container">
        <Loader loading={outputResponse.loading} error={outputResponse.error} height="calc(100vh - 206px)">
          <LazyLoadDiv className="datatable-container-inner" id="table-list" total={totalCount} currentTotal={(data || []).length} height="calc(100vh - 400px)" loadMoreRows={loadMoreData}>
            {tableRowList()}
          </LazyLoadDiv>
        </Loader>
      </div>
    </div>
  );
}

OncoTaggingTable.propTypes = {
  data: PropTypes.array.isRequired,
  loadMoreData: PropTypes.func.isRequired,
  outputResponse: PropTypes.object.isRequired,
};

export default OncoTaggingTable;
