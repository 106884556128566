/* eslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Input, Modal } from 'antd';
const { TextArea } = Input;
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { generateIndicationsAction, uploadCCADocumentAction } from '../../pages/IEPInputPage/logic';
import { terminalsPath } from '../../../../app/components/Card/data';

function SaveProjectModal({
  name, service, open, setOpen, requestBody, formData,
}) {
  const navigate = useNavigate();
  const [projectName, setProjectName] = useState('');
  const [description, setDescription] = useState('');
  useEffect(() => {
    setProjectName(name);
  }, [name]);
  const dispatch = useDispatch();
  const onSaveAndGenerate = () => {
    const newRequestBody = requestBody.params;
    newRequestBody.job_name = projectName;
    if(description)
      newRequestBody.description = description;
    if(formData) {
      dispatch(uploadCCADocumentAction({ params: queryString.stringify(newRequestBody), body: formData, }));
    } else {

      dispatch(generateIndicationsAction({ ...requestBody, params: queryString.stringify(newRequestBody) }));
    }
    navigate(`${terminalsPath.metad3_iep_workspace}?service=${service}`);
    toast(`Project “${projectName}” is added successfully in My workspace`, {
      position: 'top-right',
      autoClose: 2000,
      pauseOnHover: false,
      draggable: true,
      newestOnTop: true,
      type: 'default',
      hideProgressBar: false,
      closeButton: true,
    });
    setOpen(false);
  };
  return (
    <Modal footer={null} closeIcon={null} closable onCancel={() => setOpen(false)} open={open} className="save-project-modal">
      <div className="save-project-title">Save Project</div>
      <div className="save-project-content">
        <div className="save-project-name">
          <div className="save-project-name-title">Save new project as per new input changes</div>
          <div className="save-project-name-input">
            <Input placeholder="Enter name" value={projectName} onChange={(e) => setProjectName(e.target.value)} />
          </div>
        </div>
        <div className="save-project-description">
          <div className="save-project-description-title">
            Description
            <span className="description-optional">(Optional)</span>
          </div>
          <div className="save-project-description-input">
            <TextArea  placeholder="Enter description" value={description} onChange={(e) => setDescription(e.target.value)} />
          </div>
        </div>
        <div className="btns-row">
          <div className="cancel-btn" onClick={() => setOpen(false) } style={{ cursor: 'pointer' }} >Cancel</div>
          <Button disabled={!projectName} onClick={() => onSaveAndGenerate()} className="save-generate-btn">
            Save & Generate output
          </Button>
        </div>
      </div>
      <ToastContainer />
    </Modal>
  );
}

SaveProjectModal.propTypes = {
  name: PropTypes.string.isRequired,
  service: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  requestBody: PropTypes.objectOf.isRequired,
  formData: PropTypes.objectOf.isRequired,
};

export default SaveProjectModal;
