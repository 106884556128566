import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../utils';

// get Admet Output details
const GET_DRUG_REFORM_ADMET_OUTPUT = 'GET_DRUG_REFORM_ADMET_OUTPUT';
const GET_DRUG_REFORM_ADMET_OUTPUT_SUCCESS = 'GET_DRUG_REFORM_ADMET_OUTPUT_SUCCESS';
const GET_DRUG_REFORM_ADMET_OUTPUT_FAILURE = 'GET_DRUG_REFORM_ADMET_OUTPUT_FAILURE';

export const getDrugReformAdmetOutputAction = createAction(GET_DRUG_REFORM_ADMET_OUTPUT);

const getDrugReformAdmetOutputSuccess = createAction(GET_DRUG_REFORM_ADMET_OUTPUT_SUCCESS);

const getDrugReformAdmetOutputFaliure = createAction(GET_DRUG_REFORM_ADMET_OUTPUT_FAILURE);

export const getDrugReformAdmetOutputEpic = (actions$) => actions$.pipe(
  ofType(GET_DRUG_REFORM_ADMET_OUTPUT),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_sar}/getAdmet?${action.payload}`, 'get')).pipe(
    map((res) => getDrugReformAdmetOutputSuccess(res)),
    catchError((err) => of(getDrugReformAdmetOutputFaliure(err))),
  )),
);
export const getDrugReformAdmetOutputReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(getDrugReformAdmetOutputAction, (state) => {
        state.loading = true;
      })
      .addCase(getDrugReformAdmetOutputSuccess, (state, action) => {
        state.data = [...action.payload.response.data];
        state.loading = false;
        state.flag = true;
      })
      .addCase(getDrugReformAdmetOutputFaliure, (state) => {
        state.error = true;
        state.flag = false;
        state.loading = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);

export const getAdmetCompareOutputReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(getDrugReformAdmetOutputAction, (state) => {
        state.loading = true;
      })
      .addCase(getDrugReformAdmetOutputSuccess, (state, action) => {
        state.data = { ...action.payload.response.data };
        state.loading = false;
        state.flag = true;
      })
      .addCase(getDrugReformAdmetOutputFaliure, (state) => {
        state.error = true;
        state.flag = false;
        state.loading = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);

// get Inhaler Feasibility details
const GET_INHALER_FEASIBILITY_OUTPUT = 'GET_INHALER_FEASIBILITY_OUTPUT';
const GET_INHALER_FEASIBILITY_OUTPUT_SUCCESS = 'GET_INHALER_FEASIBILITY_OUTPUT_SUCCESS';
const GET_INHALER_FEASIBILITY_OUTPUT_FAILURE = 'GET_INHALER_FEASIBILITY_OUTPUT_FAILURE';

export const getInhalerFeasibilityOutputAction = createAction(GET_INHALER_FEASIBILITY_OUTPUT);

const getInhalerFeasibilityOutputSuccess = createAction(GET_INHALER_FEASIBILITY_OUTPUT_SUCCESS);

const getInhalerFeasibilityOutputFaliure = createAction(GET_INHALER_FEASIBILITY_OUTPUT_FAILURE);

export const getInhalerFeasibilityOutputEpic = (actions$) => actions$.pipe(
  ofType(GET_INHALER_FEASIBILITY_OUTPUT),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_drug_reform}/getFeasibility?${action.payload}`, 'get')).pipe(
    map((res) => getInhalerFeasibilityOutputSuccess(res)),
    catchError((err) => of(getInhalerFeasibilityOutputFaliure(err))),
  )),
);
export const getInhalerFeasibilityOutputReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(getInhalerFeasibilityOutputAction, (state) => {
        state.loading = true;
      })
      .addCase(getInhalerFeasibilityOutputSuccess, (state, action) => {
        state.data = { ...action.payload.response.data };
        state.loading = false;
        state.flag = true;
      })
      .addCase(getInhalerFeasibilityOutputFaliure, (state) => {
        state.error = true;
        state.flag = false;
        state.loading = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);
