import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../utils';

// check status
const PROTAC_DESIGN_JOBS = 'PROTAC_DESIGN_JOBS';
const PROTAC_DESIGN_JOBS_SUCCESS = 'PROTAC_DESIGN_JOBS_SUCCESS';
const PROTAC_DESIGN_JOBS_FAILURE = 'PROTAC_DESIGN_JOBS_FAILURE';
const PROTAC_DESIGN_JOBS_RESET = 'PROTAC_DESIGN_JOBS_RESET';
const GET_MORE_PROTAC_DESIGN_JOBS = 'GET_MORE_PROTAC_DESIGN_JOBS';
const GET_MORE_PROTAC_DESIGN_JOBS_SUCCESS = 'GET_MORE_PROTAC_DESIGN_JOBS_SUCCESS';

export const protacDesignJobsAction = createAction(PROTAC_DESIGN_JOBS);
export const protacDesignJobsResetAction = createAction(PROTAC_DESIGN_JOBS_RESET);

const protacDesignJobsSuccess = createAction(PROTAC_DESIGN_JOBS_SUCCESS);
const protacDesignJobsFailure = createAction(PROTAC_DESIGN_JOBS_FAILURE);

export const getMoreprotacDesignJobsAction = createAction(GET_MORE_PROTAC_DESIGN_JOBS);
const getMoreprotacDesignJobsSuccess = createAction(GET_MORE_PROTAC_DESIGN_JOBS_SUCCESS);

export const protacDesignJobsEpic = (actions$) => actions$.pipe(
  ofType(PROTAC_DESIGN_JOBS),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/v1/d3_modules/searchJob?${action.payload}`, 'get')).pipe(
    map((res) => protacDesignJobsSuccess(res)),
    catchError((err) => of(protacDesignJobsFailure(err))),
  )),
);

export const getMoreprotacDesignJobsEpic = (actions$) => actions$.pipe(
  ofType(GET_MORE_PROTAC_DESIGN_JOBS),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/v1/d3_modules/searchJob?${action.payload}`, 'get')).pipe(
    map((res) => getMoreprotacDesignJobsSuccess(res)),
    catchError((err) => of(protacDesignJobsFailure(err))),
  )),
);

export const protacDesignJobsReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(protacDesignJobsAction, (state) => {
      state.loading = true;
    })
      .addCase(protacDesignJobsSuccess, (state, action) => {
        state.data = { ...action.payload.response };
        state.loading = false;
        state.flag = true;
      })
      .addCase(getMoreprotacDesignJobsAction, (state) => {
        state.loading = true;
      })
      .addCase(getMoreprotacDesignJobsSuccess, (state, action) => {
        state.data = {
          total_count: action.payload.response.data.total_count,
          data: [...action.payload.response.data],
        };
        state.loading = false;
        state.flag = true;
      })
      .addCase(protacDesignJobsFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(protacDesignJobsResetAction, (state) => {
        state.data = [];
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);

// update project name
const UPDATE_PROJECT_NAME_PROTAC = 'UPDATE_PROJECT_NAME_PROTAC';
const UPDATE_PROJECT_NAME_PROTAC_SUCCESS = 'UPDATE_PROJECT_NAME_PROTAC_SUCCESS';
const UPDATE_PROJECT_NAME_PROTAC_FAILURE = 'UPDATE_PROJECT_NAME_PROTAC_FAILURE';
const UPDATE_PROJECT_NAME_PROTAC_RESET = 'UPDATE_PROJECT_NAME_PROTAC_RESET';

export const updateProjectNameProtacAction = createAction(UPDATE_PROJECT_NAME_PROTAC);
export const updateProjectNameProtacResetAction = createAction(UPDATE_PROJECT_NAME_PROTAC_RESET);

const updateProjectNameProtacSuccess = createAction(UPDATE_PROJECT_NAME_PROTAC_SUCCESS);
const updateProjectNameProtacFailure = createAction(UPDATE_PROJECT_NAME_PROTAC_FAILURE);

export const updateProjectNameProtacEpic = (actions$) => actions$.pipe(
  ofType(UPDATE_PROJECT_NAME_PROTAC),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/v1/d3_modules/searchJob?${action.payload}`, 'put')).pipe(
    map((res) => updateProjectNameProtacSuccess(res)),
    catchError((err) => of(updateProjectNameProtacFailure(err))),
  )),
);

export const updateProjectNameProtacReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(updateProjectNameProtacAction, (state) => {
      state.loading = true;
    })
      .addCase(updateProjectNameProtacSuccess, (state, action) => {
        state.data = { ...action.payload.response };
        state.loading = false;
        state.flag = true;
      })
      .addCase(updateProjectNameProtacFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(updateProjectNameProtacResetAction, (state) => {
        state.data = [];
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);
