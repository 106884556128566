/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
// import { v4 as uuidv4 } from 'uuid';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Graph from 'react-vis-network-graph';
import useAppSelector from '../../../../../utils/redux-hook';
import { getPPINetworkGraphAction } from './logic';
import Loader from '../../../../../common/components/Loader';
import { XFilled } from '@ant-design/icons';

function PPINetwotkGraph({ jobId }) {
  const dispatch = useDispatch();
  const getPPINetworkGraphResponse = useAppSelector((state) => state.getPPINetworkGraphResponse);
  const [graphData, setGraphData] = useState({});
  const [graphParsing, setGraphParsing] = useState(false);
  const networkRef = useRef(null); // Create a reference for the Graph component

  useEffect(() => {
    const request = {
      // job_id: '47f9d2b3-8bc2-4608-b3cf-069e581f091c',
      job_id: jobId,
    };
    dispatch(getPPINetworkGraphAction(queryString.stringify(request)));
  }, []);

  useEffect(() => {
    if (getPPINetworkGraphResponse.flag) {
      setGraphParsing(true);
      setGraphData((prev) => {
        let zero;
        const nodes = [];
        for (const i of getPPINetworkGraphResponse.data.data.nodes) {
          const title = '<div style="border-bottom: 1px solid rgba(255,255,255,.3); font-size: 28px; padding: 4px; border-radius: 8px">' + '<p style="font-family: Inter;font-size: 12px;color: #59748A; margin-bottom: 5px;">Associated Protein: </p>' + '<p style="font-family: Inter;font-size: 14px; font-weight: 500; color: #121417;">' + `${i.title}` + '</p>' + '</div>';
          // const title = '';
          const font = {
            size: 25,
            bold: true,
          }
          const obj = { ...i, title, font };
          if (i.id === 0) {
            zero = obj;
            continue;
          }
          nodes.push(obj);
        }
        zero.value = 80;
        nodes.push(zero);
        const edges = [];
        for (const i of getPPINetworkGraphResponse.data.data.edges) {
          const obj = { ...i };
          edges.push(obj);
        }
        return { nodes, edges };
      });
      setGraphParsing(false);
    }
  }, [JSON.stringify(getPPINetworkGraphResponse)]);

  const options = {
    physics: {
      enabled: true,
      solver: 'barnesHut', // 'forceAtlas2Based' or 'repulsion' are other options
      barnesHut: {
        gravitationalConstant: -20000, // More negative for more repulsion
        centralGravity: 0.1, // Lower values mean less attraction to the center
        springLength: 550, // Desired distance between connected nodes
        springConstant: 0.05, // Rigidity of the edges
        damping: 0.09, // Reduces the velocity of moving nodes
        avoidOverlap: 1, // Prevents overlap
      },
      stabilization: {
        enabled: true,
        iterations: 100, // Number of iterations to stabilize
        updateInterval: 25,
        onlyDynamicEdges: false,
        fit: true,
      },
      timestep: 0.5,
      adaptiveTimestep: true,
    },
    layout: {
      improvedLayout: false,
    },
    interaction: {
      zoomView: false,
      selectable: true,
    },
    edges: {
      // color: 'red',
      width: 2,

      arrows: {
        to: {
          enabled: false,
        },
        middle: {
          enabled: false,
        },
        from: {
          enabled: false,
        },
      },
    },
    nodes: {
      shape: 'dot',
      scaling: {
        // label: {
        //   enabled: false,
        //   min: 25,
        // },
        customScalingFunction(min, max, total, value) {
          return ((value * 10) / total) * 2.25;
        },
        min: 20,
        max: 150,
      },
    },
    height: '90%',
    width: '100%',
  };

  useEffect(() => {
    if (networkRef.current) {
      const network = networkRef.current.Network;
      network.on('selectNode', (params) => {
        const nodeId = params.nodes[0];
        const connectedEdges = network.getConnectedEdges(nodeId);

        connectedEdges.forEach((edgeId) => {
          network.updateEdge(edgeId, { width: 15 }); // Increase width of the edge
        });
      });
      network.on('deselectNode', (params) => {
        const { edges } = network.body;

        Object.keys(edges).forEach((edgeId) => {
          network.updateEdge(edgeId, { width: 2 }); // Reset width of the edge
        });
      });
      // You can now access the network object and call its methods
    }
  }, [graphData]);

  return (
    <Loader loading={getPPINetworkGraphResponse.loading || Object.keys(graphData).length === 0 || graphParsing}>
      <div className="ctp-content-lower card-content-ctp card-content-ctp-graph" style={{ width: '100%', height: '800px' }}>
        <div className="card-content-ctp-title">PPI Network Graph and 1st - 2nd Degree Associations</div>
        {graphData ? (
          <>
            <div className="pathway-enrichment-graph-header">
              <div className="pathway-enrichment-graph-legend-icon">
                <XFilled style={{ color: '#007DC4', fontSize: '120%' }} />
              </div>
              <div className="pathway-enrichment-graph-legend-name" style={{ paddingLeft: '5px', paddingRight: '50px' }}>
                Input Protein
              </div>
              <div className="pathway-enrichment-graph-legend-icon">
                <XFilled style={{ color: '#1C855F', fontSize: '120%' }} />
              </div>
              <div className="pathway-enrichment-graph-legend-name" style={{ paddingLeft: '5px' }}>
                Associated Protein
              </div>
            </div>
            <Graph key={Math.random()} ref={networkRef} graph={graphData} options={options} />
          </>
        ) : (
          <Loader loading={graphParsing} />
        )}
      </div>
    </Loader>
  );
}

PPINetwotkGraph.propTypes = {
  jobId: PropTypes.string.isRequired,
};

export default PPINetwotkGraph;
