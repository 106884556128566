import {
  Button, Divider, Input,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CloseOutlined } from '@ant-design/icons';
import Loader from '../../../../common/components/Loader';
import Header from '../../../../common/components/Header';
import useAppSelector from '../../../../utils/redux-hook';
import { getCookie } from '../../../../utils';
import { terminalsPath } from '../../../../app/components/Card/data';
import {
  sarAdmetPropertiesWorkspaceStatusAction, sarAdmetPropertiesWorkspaceStatusResetAction, sarLeadGenerationWorkspaceStatusAction, sarLeadGenerationWorkspaceStatusResetAction,
  sarUpdateWorkspaceStatusAction,
} from './logic';
import Mixpanel from '../../../../utils/mixpanel';
// import LazyLoadDiv from '../../../../common/components/LazyLoadDiv';

function MyWorkspacePageSAR() {
  const dispatch = useDispatch();
  const sarLeadGenerationWorkspaceResponse = useAppSelector((state) => state.sarLeadGenerationWorkspaceResponse);
  const sarAdmetPropertiesWorkspaceResponse = useAppSelector((state) => state.sarAdmetPropertiesWorkspaceResponse);
  const [searchParams] = useSearchParams();
  const service = searchParams.get('service');
  const [searchText, setSearchText] = useState('');
  const navigate = useNavigate();
  //   const [searchQuery, setSearchQuery] = useState('');
  const [editing, setEditing] = useState({
    isEditing: false,
    editingId: '',
    editingName: '',
  });
  const [currentJob, setCurrentJob] = useState({});
  const userEmailId = getCookie('usersEmailId');
  const [isLoading, setIsLoading] = useState(true);
  const [currentService, setCurrentService] = useState([1, 0, 0]);
  const [serviceJobsList, setServiceJobsList] = useState({
    lead_generation: [],
    admet_properties: [],
  });
  const [searchAndSort, setSearchAndSort] = useState({
    isSearch: false,
    isSort: false,
    searchValue: '',
    sortValue: 'desc',
  });
  const [leadGenerationRequest, setLeadGenerationRequest] = useState({
    filters: JSON.stringify({ module_id: 'metad3_sar_denovo', 'user_input.service': 'LEAD_GENERATION' }),
    from: 1,
    size: 10,
    searchQuery: (searchAndSort.searchValue.length > 0) ? searchAndSort.searchValue : undefined,
    sort_order: searchAndSort.sortValue,
  });
  const [admetPropertiesRequest, setAdmetPropertiesRequest] = useState({
    filters: JSON.stringify({ module_id: 'metad3_sar_denovo', 'user_input.service': 'ADMET_PROPERTIES' }),
    from: 1,
    size: 10,
    searchQuery: (searchAndSort.searchValue.length > 0) ? searchAndSort.searchValue : undefined,
    sort_order: searchAndSort.sortValue,
  });

  useEffect(() => {
    setIsLoading(false);
    switch (service) {
      case 'LEAD_GENERATION':
        setCurrentService([1, 0, 0]);
        break;
      case 'ADMET_PROPERTIES':
        setCurrentService([0, 1, 0]);
        break;
      default:
        setCurrentService([1, 0, 0]);
    }
  }, []);

  useEffect(() => {
    dispatch(sarLeadGenerationWorkspaceStatusAction(queryString.stringify(leadGenerationRequest)));
    setIsLoading(false);
    const intervalId = setInterval(() => {
      if (sarLeadGenerationWorkspaceResponse.data) {
        dispatch(sarLeadGenerationWorkspaceStatusAction(queryString.stringify(leadGenerationRequest)));
      }
    }, 10000);
    return () => clearInterval(intervalId);
  }, [leadGenerationRequest]);

  useEffect(() => {
    dispatch(sarAdmetPropertiesWorkspaceStatusAction(queryString.stringify(admetPropertiesRequest)));
    setIsLoading(false);
    const intervalId = setInterval(() => {
      if (sarAdmetPropertiesWorkspaceResponse.data) {
        dispatch(sarAdmetPropertiesWorkspaceStatusAction(queryString.stringify(admetPropertiesRequest)));
      }
    }, 10000);
    return () => clearInterval(intervalId);
  }, [admetPropertiesRequest]);

  useEffect(() => {
    if (sarLeadGenerationWorkspaceResponse.flag) {
      if (!currentJob?.status && (service === 'LEAD_GENERATION' || !service)) {
        setCurrentJob(sarLeadGenerationWorkspaceResponse.data[0]);
      }
      setServiceJobsList((prev) => ({
        ...prev,
        lead_generation: sarLeadGenerationWorkspaceResponse.data,
      }));
    }
  }, [sarLeadGenerationWorkspaceResponse]);

  useEffect(() => {
    if (sarAdmetPropertiesWorkspaceResponse.flag) {
      if (!currentJob?.status) {
        setCurrentJob(sarAdmetPropertiesWorkspaceResponse.data[0]);
      }
      setServiceJobsList((prev) => ({
        ...prev,
        admet_properties: sarAdmetPropertiesWorkspaceResponse.data,
      }));
    }
  }, [sarAdmetPropertiesWorkspaceResponse]);

  useEffect(() => {
    setLeadGenerationRequest((prev) => ({
      ...prev,
      from: 1,
      size: 20,
      searchQuery: (searchAndSort.searchValue.length > 0) ? searchAndSort.searchValue : undefined,
      sort_order: searchAndSort.sortValue,
    }));
    setAdmetPropertiesRequest((prev) => ({
      ...prev,
      from: 1,
      size: 20,
      searchQuery: (searchAndSort.searchValue.length > 0) ? searchAndSort.searchValue : undefined,
      sort_order: searchAndSort.sortValue,

    }));
  }, [searchAndSort]);

  const onChangedJob = (job) => {
    setCurrentJob(job);
    Mixpanel.track('SAR Job Selected', {
      action: 'SAR clicked on a new job',
      job_name: job?.job_name,
      service: job?.user_input?.service,
      module: 'SAR',
    });
  };

  const formatDate = (epoch) => {
    const date = new Date(epoch * 1000);

    const day = date.getDate();
    const month = date.toLocaleString('en-US', { month: 'short' });

    return `${day} ${month}`;
  };
  const formatTime = (epoch) => {
    const date = new Date(epoch * 1000);

    const time = date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });

    return time;
  };
  const onEditName = (job) => {
    const request = {
      job_id: job?.job_id,
      job_name: editing.editingName,
    };
    dispatch(sarUpdateWorkspaceStatusAction(queryString.stringify(request)));
    setEditing({ isEditing: false, editingId: '', editingName: '' });
    Mixpanel.track('SAR Job Updated', {
      action: 'SAR job name updated',
      job_name: job?.job_name,
      service: job?.user_input?.service,
      module: 'SAR',
    });
  };

  const smilesGroup = (list1) => (
    <div className="smiles-group">
      <div className="smiles">
        {list1?.map((item) => (
          <div key={item} className="individual-smile">
            {item}
          </div>
        ))}
      </div>
    </div>
  );

  const onViewOutput = (job) => {
    if (job?.status.toLowerCase() === 'completed') {
      navigate(`${terminalsPath.metad3_sar_denovo_output}?jobId=${job?.job_id}&service=${job?.user_input.service}&noOfMolecules=${job?.user_input.molecular_smiles.length}`);
      Mixpanel.track('SAR Viewed Output', {
        action: 'SAR View output button clicked',
        job_name: job?.job_name,
        service: job?.user_input?.service,
        smiles: job?.user_input?.molecular_smiles,
        module: 'SAR',
      });
    } else {
      navigate(terminalsPath.metad3_sar_denovo);
      Mixpanel.track('SAR Resubmit Output Button Clicked', {
        action: 'SAR Resubmit Output Button Clicked',
        job_name: job?.job_name,
        service: job?.user_input?.service,
        smiles: job?.user_input?.molecular_smiles,
        module: 'SAR',
      });
    }
  };

  const jobsList = (data1) => (
    <div className="jobs-list">
      {data1?.map((job, index) => (
        <div className={`job-item${job?.job_id === currentJob?.job_id ? ' active' : ''}${editing.isEditing && editing.editingId === index ? ' input-edit' : ''}`} key={job?.job_id} style={{ display: 'flex', cursor: 'pointer' }} onClick={() => onChangedJob(job)} aria-hidden>
          <div className="job-info">
            {editing.isEditing && editing.editingId === index ? (
              <div>
                <Input
                  placeholder="Job Name"
                  value={editing.editingName ?? job?.job_name ?? job?.module_id}
                  onChange={(e) => setEditing({ ...editing, editingName: e.target.value })}
                  onPressEnter={() => onEditName(job)}
                />
              </div>
            ) : (
              <div className="job-title">{job?.job_name ?? job?.module_id}</div>
            )}
            <div className="job-created-at">
              {formatDate(job?.created_date_time_ts)}
              <Divider type="vertical" />
              {formatTime(job?.created_date_time_ts)}
            </div>
          </div>
          <div className="job-status-main">
            <div className={`job-status${job?.status.toLowerCase() === 'completed' ? ' completed' : job?.status.toLowerCase() === 'rejected' ? ' failed' : ' inprogress'}`}>{job?.status === 'REJECTED' ? 'Failed' : job?.status === 'PENDING' ? 'In Progress' : ''}</div>
          </div>
          <div className="job-name-edit-btn">
            <Button className="rename-edit-icon" onClick={() => setEditing({ isEditing: true, editingId: index })} />
          </div>
        </div>
      ))}
    </div>
  );

  const inputDetails = (job) => (
    <div className="job-details-main">
      {job?.status.toLowerCase() !== 'completed' && <div className={`job-pending-warning ${job?.status.toLowerCase() === 'pending' ? ' pending' : 'failed'}`}>{`${job?.status.toLowerCase() === 'pending' ? `Output generation is in progress. Once it is generated, we will notify you over your email, “${userEmailId}”` : 'Due to a technical error, the output generation failed. Please click the “Resubmit Input” button to try again.'}`}</div>}
      <div className="job-details-title">Input Details</div>
      <div className="job-details-content">
        <div className="job-name-row" style={{ display: 'flex' }}>
          <div className="job-name-group" style={{ display: 'flex' }}>
            <div className="job-name-group-title">{job?.job_name || job?.module_id}</div>
            <div className="job-created-at">
              {formatDate(job?.created_date_time_ts)}
              <Divider type="vertical" />
              {formatTime(job?.created_date_time_ts)}
            </div>
          </div>
          <div className="view-output-btn">
            <Button
              disabled={job?.status.toLowerCase() === 'pending'}
              onClick={() => {
                onViewOutput(job);
                dispatch(sarLeadGenerationWorkspaceStatusResetAction({}));
              }}
            >
              {`${job?.status.toLowerCase() === 'rejected' ? 'Resubmit Input' : 'View Output'}`}
            </Button>
          </div>
        </div>
        {job?.description && (
          <div className="description-main">
            <div className="title">Description</div>
            <div className="content">{job?.description}</div>
          </div>
        )}
        <div className="drug-indication-table">
          {/* <HCTPTableView data={dummyData} /> */}
          <div className="drug-indication-pairs-main">
            <div className="title">
              Input Smile/s
            </div>
            <div className="pairs-list">
              {smilesGroup(job?.user_input?.molecular_smiles)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  //   const loadMoreData = (loadService) => {
  //     console.log('inside');
  //     if (loadService === 0) {
  //       setLeadGenerationRequest((prev) => {
  //         const { size } = prev;
  //         return {
  //           ...prev,
  //           size: size + 10,
  //         };
  //       });
  //     }
  //     if (loadService === 1) {
  //       setAdmetPropertiesRequest((prev) => {
  //         const { size } = prev;
  //         return {
  //           ...prev,
  //           size: size + 10,
  //         };
  //       });
  //     }
  //   };

  const serviceDropdowns = () => (
    <div className="service-dropdowns">
      <div role="presentation" className={`service-dropdown ${(currentService[0] === 1 ? 'open' : 'close')}`} onClick={() => setCurrentService([1, 0, 0])}>
        <div className="service-dropdown-title">
          Lead Generation
        </div>
        { currentService[0] === 1 && (
          <div className="service-dropdown-content">
            {jobsList(serviceJobsList.lead_generation)}
          </div>
        )}

      </div>
      <div role="presentation" className={`service-dropdown ${(currentService[1] === 1 ? 'open' : 'close')}`} onClick={() => setCurrentService([0, 1, 0])}>
        <div className="service-dropdown-title">
          Admet Properties
        </div>
        { currentService[1] === 1 && (
          <div className="service-dropdown-content">
            {jobsList(serviceJobsList.admet_properties)}
          </div>
        )}

      </div>
    </div>
  );

  return (
    <div className="main-page main-page-modules">
      <Header className="header-home iep-header" logo="sar" width={370} />
      <Loader loading={(sarAdmetPropertiesWorkspaceResponse.loading || sarLeadGenerationWorkspaceResponse.loading) && isLoading}>
        <div className="ct-home-page my-workspace-page">
          <div className="my-workspace-main">
            <div className="jobs-list-view">
              {searchAndSort.isSearch ? (
                <div className="search-input">
                  <Input
                    placeholder="Job Name"
                    value={searchText}
                    allowClear={{
                      clearIcon: <CloseOutlined onClick={() => {
                        setSearchText('');
                        setSearchAndSort((prev) => ({
                          ...prev,
                          isSearch: false,
                          searchValue: '',
                        }));
                      }}
                      />,
                    }}
                    onChange={(e) => setSearchText(e.target.value)}
                    onPressEnter={(e) => {
                      setSearchAndSort((prev) => ({
                        ...prev,
                        searchValue: e.target.value,
                      }));
                      Mixpanel.track('SAR Job Searched', {
                        action: 'SAR searched query',
                        query: e.target.value,
                        module: 'SAR',
                      });
                    }}
                  />
                </div>
              ) : (
                <div className="btns-row">
                  <div className="back-btn">
                    <Button
                      onClick={() => {
                        navigate(terminalsPath.metad3_sar_denovo);
                        dispatch(sarLeadGenerationWorkspaceStatusResetAction({}));
                        dispatch(sarAdmetPropertiesWorkspaceStatusResetAction({}));
                      }}
                      className="back-btn-icon"
                    >
                      Back
                    </Button>
                  </div>
                  <div className="grouped-btns">
                    <div className="search-btn" style={{ cursor: 'pointer' }}>
                      <div
                        className="search-btn-icon"
                        onClick={() => {
                          setSearchAndSort((prev) => ({
                            ...prev,
                            isSearch: true,
                          }));
                          dispatch(sarLeadGenerationWorkspaceStatusResetAction({}));
                        }}
                        aria-hidden
                      />
                    </div>
                    <div className="sort-btn" style={{ cursor: 'pointer' }}>
                      <div
                        className="sort-btn-icon"
                        onClick={() => {
                          if (searchAndSort.sortValue === 'asc') {
                            setSearchAndSort((prev) => ({
                              ...prev,
                              sortValue: 'desc',
                            }));
                          } else {
                            setSearchAndSort((prev) => ({
                              ...prev,
                              sortValue: 'asc',
                            }));
                          }
                          Mixpanel.track('SAR Jobs List Sorted', {
                            action: 'SAR Sort button clicked',
                            order: searchAndSort.sortValue === 'asc' ? 'desc' : 'asc',
                            module: 'SAR',
                          });
                        }}
                        aria-hidden
                      />
                    </div>
                  </div>
                </div>
              )}
              {serviceDropdowns()}
              {/* {jobsList()} */}
            </div>

            {currentJob && <div className="job-details-view">{currentJob?.user_input && inputDetails(currentJob)}</div>}
          </div>
        </div>
      </Loader>
    </div>
  );
}

export default MyWorkspacePageSAR;
