import React, { useState, useEffect } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';
import Mixpanel from '../../../../utils/mixpanel';
import { terminalsPath } from '../../../../app/components/Card/data';
import Loader from '../../../../common/components/Loader';
import Header from '../../../../common/components/Header';
import InputBox from '../../container/InputBox';
import SelectService from '../../container/SelectService';

function AntibodyEngineeringInputPage() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const service = searchParams.get('service');
  const [disableButton, setDisableButton] = useState(true);
  // const dispatch = useDispatch();

  const [formData, setFormData] = useState();
  const [inputs, setInputs] = useState({
    card: '',
    inputText: '',
  });
  const handleClick = () => {
    Mixpanel.track('Antibody Engineering Input Submitted', {
      action: 'Antibody Engineering Input submitted',
      service: inputs.card,
      inputText: inputs.inputText,
      module: 'Antibody Engineering',
    });
    navigate(`${terminalsPath.metad3_ae_output}`);
  };

  useEffect(() => {
    let check = false;
    if (!formData) {
      check = inputs.inputText === '' || inputs.inputText === undefined;
    } else {
      check = !(formData !== null && formData && 'get' in formData);
    }
    check = check || !inputs.card;
    setDisableButton(check);
  }, [inputs, formData]);

  const getInputs = (input) => {
    setInputs((prev) => ({
      ...prev,
      ...input,
    }));
  };

  return (
    <div className="main-page main-page-modules">
      <Header className="header-home adc-header" logo="antibodyeng-logo" width={500} modulePath={terminalsPath.metad3_utilities} />
      <Loader loading={false}>
        <div className="ct-home-page">
          <div className="main-heading">
            <div className="main-title">Engineering large molecule for optimal performance</div>
            <div className="main-description">Using our AI models you can optimise antibodies/proteins using the following services</div>
          </div>
          <div>

            <SelectService inputHandler={getInputs} service={service} resetInputs={() => setInputs({})} />
            <InputBox setFormData={setFormData} isDisabled={inputs.card === ''} inputHandler={getInputs} />
            <div className="ct-home-page-footer">
              <button onClick={handleClick} disabled={disableButton} className="generate-in-btn" type="button">
                Raw Data Analysis
              </button>
            </div>
          </div>
        </div>
        {/* <SaveProjectModal name={inputs.protein_name} service={inputs.card} open={isSubmitted} setOpen={setIsSubmitted} requestBody={requestBody} formData={formData} /> */}
      </Loader>
    </div>
  );
}

export default AntibodyEngineeringInputPage;
