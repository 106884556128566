/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Input } from 'antd';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import { getInputSuggestAction } from './logic';
import useAppSelector from '../../../../utils/redux-hook';
import Loader from '../../../../common/components/Loader';
import Mixpanel from '../../../../utils/mixpanel';

const { Search } = Input;

function InputDropdown({ changeHandler, proteinName, isDisabled, setProteinName, setProteinItem }) {
  const [searchText, setSearchText] = useState('');
  const [autosuggestOptions, setAutosuggestOptions] = useState([]);
  const [displayDropdown, setDisplayDropdown] = useState(false);
  const dispatch = useDispatch();
  const getInputSuggestResponse = useAppSelector((reduxState) => reduxState.getInputSuggestResponse || {});

  useEffect(() => {
    if (getInputSuggestResponse.flag) {
      setAutosuggestOptions(getInputSuggestResponse.data);
    }
  }, [getInputSuggestResponse]);

  useEffect(() => {
    if (proteinName !== '') {
      setSearchText(proteinName);
      changeHandler(proteinName);
      setProteinName('');
    }
  }, [proteinName]);

  const onChangeInput = (e) => {
    if (e.target.value.trim().length > 0 && !isDisabled) {
      setAutosuggestOptions([]);
      setDisplayDropdown(true);
      setSearchText(e.target.value);
      const request = {
        input_text: e.target.value,
      };
      dispatch(getInputSuggestAction(queryString.stringify(request)));
    } else {
      setSearchText('');

      setDisplayDropdown(false);

      changeHandler('');
    }
  };

  const renderAutosuggestOptions = () => {
    const options = [];

    /* eslint-disable */
    for (let i = 0; i < Math.min(10, autosuggestOptions.length); i++) {
      const itm = autosuggestOptions[i];
      options.push(
        <div
          key={crypto.randomUUID()}
          onClick={() => {
            Mixpanel.track('IEP input provided', {
              action: 'provide input click',
              proteinName,
            });
            // changeHandler(itm?.gene_name);
            setSearchText(itm?.gene_name);
            setProteinName(itm?.gene_name);
            setProteinItem(itm);
            setDisplayDropdown(false);
          }}
        >
          {itm?.gene_name}
        </div>,
      );
    }
    /* eslint-enable */
    return options;
  };

  return (
    <div className="searchbar-dashboard-content-search joyride-searchBar">
      <Search placeholder="Enter Protein name, Preferred name or UniProt ID" value={searchText} size="large" maxLength="256" onChange={onChangeInput} enterButton={null} allowClear />
      <div className="search-dropdown-result-content">
        {displayDropdown ? (
          <div className="search-dropdown-result">
            <div className="search-dropdown-result-list">
              <Loader loading={getInputSuggestResponse.loading}>{renderAutosuggestOptions()}</Loader>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

InputDropdown.propTypes = {
  changeHandler: PropTypes.func.isRequired,
  proteinName: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  setProteinName: PropTypes.func.isRequired,
  setProteinItem: PropTypes.func.isRequired,
};

export default InputDropdown;
