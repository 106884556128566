import React, { useEffect, useState } from 'react';
import {
  Button, Divider, Input,
} from 'antd';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import queryString from 'query-string';
import { CloseOutlined } from '@ant-design/icons';
import Header from '../../../../common/components/Header';
import Loader from '../../../../common/components/Loader';
import useAppSelector from '../../../../utils/redux-hook';
import { terminalsPath } from '../../../../app/components/Card/data';
import LazyLoadDiv from '../../../../common/components/LazyLoadDiv';
import Mixpanel from '../../../../utils/mixpanel';
import { protacDesignJobsAction, protacDesignJobsResetAction, updateProjectNameProtacAction } from './logic';
import ProtacDesignWorkspaceDetailsView from '../../container/ProtacDesignWorkspaceDetailsView';

function MyWorkspacePageProtac() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [currentJob, setCurrentJob] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const service = searchParams.get('service');
  const [serviceJobsList, setServiceJobsList] = useState({
    protacDesign: [],
    warheadDesign: [],
  });
  const protacDesignJobsResponse = useAppSelector((state) => state.protacDesignJobsResponse);
  const dispatch = useDispatch();
  const [editing, setEditing] = useState({
    isEditing: false,
    editingId: '',
    editingName: '',
  });
  const [searchText, setSearchText] = useState('');
  const [searchAndSort, setSearchAndSort] = useState({
    isSearch: false,
    isSort: false,
    searchValue: '',
    sortValue: 'desc',
  });
  const [protacDesignRequest, setInhalerFeasibilityRequest] = useState({
    filters: JSON.stringify({
      module_id: 'metad3_protac',
    //   'user_input.service': 'Prioritize Indications',
    }),
    searchQuery: (searchAndSort.searchValue.length > 0) ? searchAndSort.searchValue : undefined,
    sort_order: searchAndSort.sortValue,
    size: 10,
    from: 1,
  });

  const onChangedJob = (job) => {
    setCurrentJob(job);
    Mixpanel.track('Protac Job selected', {
      action: 'Protac Job selected from Protac workspace',
      job_name: job.job_name,
      job_id: job.job_id,
      service,
      module: 'Protac',
    });
  };

  useEffect(() => {
    setIsLoading(false);
  }, []);

  useEffect(() => {
    setInhalerFeasibilityRequest((prev) => ({
      ...prev,
      searchQuery: (searchAndSort.searchValue.length > 0) ? searchAndSort.searchValue : undefined,
      sort_order: searchAndSort.sortValue,
      // size: 100,
      size: 20,
      from: 1,

    }));
  }, [searchAndSort]);

  useEffect(() => {
    dispatch(protacDesignJobsAction(queryString.stringify(protacDesignRequest)));

    const intervalId = setInterval(() => {
      if (protacDesignJobsResponse.data.data) {
        dispatch(protacDesignJobsAction(queryString.stringify(protacDesignRequest)));
      }
    }, 10000);
    return () => clearInterval(intervalId);
  }, [protacDesignRequest]);

  useEffect(() => {
    if (protacDesignJobsResponse?.flag) {
      setServiceJobsList((prev) => ({
        ...prev,
        protacDesign: protacDesignJobsResponse.data.data,
      }));
      if (!currentJob?.status) {
        setCurrentJob(protacDesignJobsResponse.data.data[0]);
      }
    }
  }, [protacDesignJobsResponse]);

  const formatDate = (epoch) => {
    const date = new Date(epoch * 1000);

    const day = date.getDate();
    const month = date.toLocaleString('en-US', { month: 'short' });

    return `${day} ${month}`;
  };
  const formatTime = (epoch) => {
    const date = new Date(epoch * 1000);

    const time = date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });

    return time;
  };
  const onEditName = (job) => {
    const request = {
      job_id: job.job_id,
      job_name: editing.editingName,
    };
    dispatch(updateProjectNameProtacAction(queryString.stringify(request)));
    setEditing({ isEditing: false, editingId: '', editingName: '' });
    Mixpanel.track('Protac Job name edited', {
      action: 'Protac Edit Name from Protac workspace clicked',
      job_name: request.job_name,
      job_id: request.job_id,
      service,
      module: 'Protac',
    });
  };

  const jobsList = (myJobsList) => (
    <div className="jobs-list">
      {myJobsList?.map((job, index) => (
        <div className={`job-item${job?.job_id === currentJob?.job_id ? ' active' : ''}`} key={job?.job_id} style={{ display: 'flex', cursor: 'pointer' }} onClick={() => onChangedJob(job)} aria-hidden>
          <div className="job-info">
            {editing.isEditing && editing.editingId === index ? (
              <div>
                <Input
                  placeholder="Job Name"
                  value={editing.editingName ?? job.job_name ?? 'My Project'}
                  onChange={(e) => setEditing({ ...editing, editingName: e.target.value })}
                  onPressEnter={() => onEditName(job)}
                //   onBlur={() => setEditing({ isEditing: false, editingId: '' })}
                />
              </div>
            ) : (
              <div className="job-title">{job.job_name ?? 'My Project'}</div>
            )}
            <div className="job-created-at">
              {formatDate(job.created_date_time_ts)}
              <Divider type="vertical" />
              {formatTime(job.created_date_time_ts)}
            </div>
          </div>
          <div className="job-status-main">
            <div className={`job-status${job?.status.toLowerCase() === 'completed' ? ' completed' : job?.status.toLowerCase() === 'rejected' ? ' failed' : ' inprogress'}`}>{job?.status === 'REJECTED' ? 'Failed' : job?.status === 'PENDING' ? 'In Progress' : ''}</div>
          </div>
          <div className="job-name-edit-btn">
            <Button className="rename-edit-icon" onClick={() => setEditing({ isEditing: true, editingId: index })} />
          </div>
        </div>
      ))}
    </div>
  );

  const jobDetails = (job) => (
    <ProtacDesignWorkspaceDetailsView job={job} />
  );

  const loadMoreData = (loadService) => {
    if (loadService === 0) {
      setInhalerFeasibilityRequest((prev) => {
        const { size } = prev;
        return {
          ...prev,
          size: size + 10,
        };
      });
    }
  };

  const serviceDropdowns = () => (
    <div className="service-dropdowns">
      <div role="presentation" className="service-dropdown open" onClick={() => { Mixpanel.track('Protac Opened Service Dropdown', { action: 'Protac Opened Service Dropdown', service: 'Protac Design', module: 'Protac' }); }}>
        <div className="service-dropdown-title">
          Protac Design
        </div>
        <div className="service-dropdown-content">
          {protacDesignJobsResponse?.data?.total_count >= 20 && (
          <LazyLoadDiv className="datatable-container-inner" loadMoreRows={() => loadMoreData(0)} id="jobs-list" total={protacDesignJobsResponse.data.total_count} currentTotal={(serviceJobsList.protacDesign || []).length} height="calc(100vh - 280px)">

            {jobsList(serviceJobsList.protacDesign)}
          </LazyLoadDiv>
          )}
          <div className="non-lazyload">
            {protacDesignJobsResponse?.data?.total_count < 20 && jobsList(serviceJobsList.protacDesign)}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="main-page main-page-modules">
      <Header className="header-home protac-header" logo="protac" />
      <Loader loading={protacDesignJobsResponse.loading && isLoading}>
        <div className="ct-home-page my-workspace-page">
          <div className="my-workspace-main">
            <div className="jobs-list-view">
              <div className="btns-row">
                {!searchAndSort.isSearch && (
                  <div className="back-btn">
                    <Button
                      onClick={() => {
                        navigate(terminalsPath.metad3_protac);
                        dispatch(protacDesignJobsResetAction());
                        Mixpanel.track('Protac Back Button Clicked', {
                          action: 'Protac Back Button Clicked from Protac workspace',
                          module: 'Protac',
                        });
                      }}
                      className="back-btn-icon"
                    >
                      Back
                    </Button>
                  </div>
                )}
                <div className="grouped-btns">
                  {!searchAndSort.isSearch && (
                    <div
                      role="presentation"
                      className="search-btn"
                      onClick={() => {
                        setSearchAndSort((prev) => ({
                          ...prev,
                          isSearch: true,
                        }));
                      }}
                    >
                      <div className="search-btn-icon" />
                    </div>
                  )}
                  {searchAndSort.isSearch && (
                    <Input
                      placeholder="Search Job"
                      value={searchText}
                      allowClear={{
                        clearIcon: <CloseOutlined onClick={() => {
                          setSearchText('');
                          setSearchAndSort((prev) => ({
                            ...prev,
                            isSearch: false,
                            searchValue: '',
                          }));
                        }}
                        />,
                      }}
                      onChange={(e) => {
                        setSearchText(e.target.value);
                      }}
                      onPressEnter={(e) => {
                        setSearchAndSort((prev) => ({
                          ...prev,
                          searchValue: e.target.value,
                        }));
                        Mixpanel.track('Protac Job Searched', {
                          action: 'job searched on Protac workspace',
                          query: e.target.value,
                          module: 'Protac',
                        });
                      }}
                    />
                  )}
                  <div
                    role="presentation"
                    className="sort-btn"
                    onClick={() => {
                      Mixpanel.track('Protac Jobs Sorted', {
                        action: 'job sorted on Protac workspace',
                        sort: searchAndSort.sortValue,
                        module: 'Protac',
                      });
                      if (searchAndSort.sortValue === 'asc') {
                        setSearchAndSort((prev) => ({
                          ...prev,
                          sortValue: 'desc',
                        }));
                      } else {
                        setSearchAndSort((prev) => ({
                          ...prev,
                          sortValue: 'asc',
                        }));
                      }
                      Mixpanel.track('Protac Job Sorted', {
                        action: 'job sorted on Protac workspace',
                        sort: searchAndSort.sortValue,
                        module: 'Protac',
                      });
                    }}
                  >
                    <div className="sort-btn-icon" />
                  </div>
                </div>
              </div>
              {serviceDropdowns()}
            </div>

            {currentJob && <div className="job-details-view">{currentJob?.user_input && jobDetails(currentJob)}</div>}
          </div>
        </div>
      </Loader>
    </div>
  );
}

export default MyWorkspacePageProtac;
