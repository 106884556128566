import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import queryString from 'query-string';
import Header from '../../../../common/components/Header';
import SelectService from '../../container/SelectService';
import { getInputSynonymsAction } from './logic';
import useAppSelector from '../../../../utils/redux-hook';
import { sendNotification } from '../../../../common/components/Notification/logic';
import Loader from '../../../../common/components/Loader';
import Mixpanel from '../../../../utils/mixpanel';
import SaveProjectModal from '../../container/SaveProjectModal';
import InputBoxTopIndications from '../../container/InputBoxTopIndications';
import InputBoxClinicalAnalysis from '../../container/InputBoxClinicalAnalysis';
import { terminalsPath } from '../../../../app/components/Card/data';

function IEPInputPage() {
  const [searchParams] = useSearchParams();
  const service = searchParams.get('service');
  const proteinName = searchParams.get('proteinName');
  const [disableButton, setDisableButton] = useState(true);
  const dispatch = useDispatch();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [proteinsList, setProteinsList] = useState([]);
  const [requestBody, setRequestBody] = useState({});
  const [formData, setFormData] = useState();
  const [inputs, setInputs] = useState({
    id: '',
    card: '',
    protein_name: '',
  });
  const getInputSynonymsResponse = useAppSelector((state) => state.getInputSynonymsResponse);
  const generateIndicationsResponse = useAppSelector((state) => state.generateIndicationsResponse);
  useEffect(() => {
    if (getInputSynonymsResponse.data) {
      setProteinsList(getInputSynonymsResponse.data[0]?.uniqueSynonymsTerms);
    }
  }, [getInputSynonymsResponse]);
  const handleClick = () => {
    Mixpanel.track('IEP Generate Indications clicked', {
      action: 'Generate Indications button clicked',
      inputs,
      module: 'IEP',
      service: inputs.card,
    });

    const params = {
      service: inputs.card,
      protein_name: inputs.protein_name,
    };
    let body = {
      synonyms_net: proteinsList,
      approach_weightage: {
        literature_mining: 20,
        clinical_trial: 20,
        network_disease_similarity: 15,
        network_ppi: 15,
        network_molecular_function: 15,
        network_literature_mining: 15,
      },
    };
    if (inputs.card === 'CCA') {
      body = {
        indications_for_cca: inputs.indications_for_cca,
      };
    }
    const request = {
      params: {
        ...params,
      },
      body: {
        ...body,
      },
    };
    setRequestBody(request);
    if (!disableButton) {
      setIsSubmitted(true);
    }
  };

  const getInputs = (input) => {
    setInputs((prev) => ({
      ...prev,
      ...input,
    }));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let check = false;
    if (inputs.card === '' || inputs.card === undefined) {
      check = true;
    } else if (inputs.card === 'CCA') {
      if (!formData) {
        check = inputs.indications_for_cca === '' || inputs.indications_for_cca === undefined;
      } else {
        check = !(formData !== null && formData && 'get' in formData);
      }
    } else check = inputs.protein_name === '' || inputs.protein_name === undefined;
    setDisableButton(check);
  }, [inputs, formData]);

  useEffect(() => {
    if (generateIndicationsResponse.flag && generateIndicationsResponse.data) {
      dispatch(
        sendNotification({
          type: 'REQUEST_SUCCESS',
          notificationType: 'success',
          message: generateIndicationsResponse.data,
          showNotification: true,
        }),
      );
    }
  }, [JSON.stringify(generateIndicationsResponse)]);

  const updateProteinItem = (item) => {
    if (item !== '') {
      const request = {
        uid: item.uid,
      };
      dispatch(getInputSynonymsAction(queryString.stringify(request)));
    }
  };

  const selectInputStyle = () => {
    if (inputs.card === 'Prioritize Indications') {
      return (
        <>
          <InputBoxTopIndications inputHandler={getInputs} cardState={inputs.card} proteinName={proteinName !== null ? proteinName : ''} isDisabled={inputs.card === ''} serviceName={inputs.card} updateProteinItem={updateProteinItem} />
          <div className="ct-home-page-footer">
            <button onClick={handleClick} disabled={disableButton || getInputSynonymsResponse.loading} className="generate-in-btn" type="button">
              Generate Indication
            </button>
          </div>
        </>
      );
    }
    if (inputs.card === 'CCA') {
      return (
        <>
          <InputBoxClinicalAnalysis inputHandler={getInputs} serviceName={inputs.card} setFormData={setFormData} />
          <div className="ct-home-page-footer">
            <button onClick={handleClick} disabled={disableButton} className="generate-in-btn" type="button">
              Generate Output
            </button>
          </div>
        </>
      );
    }
    return (
      <>
        <InputBoxTopIndications inputHandler={getInputs} cardState={inputs.card} proteinName={proteinName !== null ? proteinName : ''} isDisabled={inputs.card === ''} serviceName={inputs.card} updateProteinItem={updateProteinItem} />
        <div className="ct-home-page-footer">
          <button onClick={handleClick} disabled={disableButton || getInputSynonymsResponse.loading} className="generate-in-btn" type="button">
            Generate Indication
          </button>
        </div>
      </>
    );
  };

  return (
    <div className="main-page main-page-modules">
      <Header className="header-home iep-header" logo="iep-logo" isWorkspace modulePath={terminalsPath.metad3_iep_workspace} />
      <Loader loading={false}>
        <div className="ct-home-page">
          <div className="main-heading">
            <div className="main-title">Discover potential indications with IEP</div>
            <div className="main-description">Using our AI model you can discover and prioritise list of potential indications by providing the protein information.</div>
          </div>
          <SelectService inputHandler={getInputs} service={service} resetInputs={() => setInputs({})} />
          {selectInputStyle()}
        </div>
        <SaveProjectModal name={inputs.protein_name} service={inputs.card} open={isSubmitted} setOpen={setIsSubmitted} requestBody={requestBody} formData={formData} />
      </Loader>
    </div>
  );
}

export default IEPInputPage;
