import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../../utils';

// check status
const AE_GET_FINAL_MATRIX_DATA = 'AE_GET_FINAL_MATRIX_DATA';
const AE_GET_FINAL_MATRIX_DATA_SUCCESS = 'AE_GET_FINAL_MATRIX_DATA_SUCCESS';
const AE_GET_FINAL_MATRIX_DATA_FAILURE = 'AE_GET_FINAL_MATRIX_DATA_FAILURE';
const AE_GET_FINAL_MATRIX_DATA_RESET = 'AE_GET_FINAL_MATRIX_DATA_RESET';

export const aeGetFinalMatrixDataAction = createAction(AE_GET_FINAL_MATRIX_DATA);
export const aeGetFinalMatrixDataResetAction = createAction(AE_GET_FINAL_MATRIX_DATA_RESET);

const aeGetFinalMatrixDataSuccess = createAction(AE_GET_FINAL_MATRIX_DATA_SUCCESS);
const aeGetFinalMatrixDataFailure = createAction(AE_GET_FINAL_MATRIX_DATA_FAILURE);

export const aeGetFinalMatrixDataEpic = (actions$) => actions$.pipe(
  ofType(AE_GET_FINAL_MATRIX_DATA),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_ae}/getFinalMatrixData?${action.payload}`, 'get')).pipe(
    map((res) => aeGetFinalMatrixDataSuccess(res)),
    catchError((err) => of(aeGetFinalMatrixDataFailure(err))),
  )),
);

export const aeGetFinalMatrixDataReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(aeGetFinalMatrixDataAction, (state) => {
        state.loading = true;
      })
      .addCase(aeGetFinalMatrixDataSuccess, (state, action) => {
        state.data = [...action.payload.response.data];
        state.loading = false;
        state.flag = true;
      })
      .addCase(aeGetFinalMatrixDataFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(aeGetFinalMatrixDataResetAction, (state) => {
        state.data = [];
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);
