import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../utils';

// get Output details
const GET_ONCO_TAGGING_OUTPUT = 'GET_ONCO_TAGGING_OUTPUT';
const GET_ONCO_TAGGING_OUTPUT_SUCCESS = 'GET_ONCO_TAGGING_OUTPUT_SUCCESS';
const GET_ONCO_TAGGING_OUTPUT_FAILURE = 'GET_ONCO_TAGGING_OUTPUT_FAILURE';
const GET_MORE_ONCO_TAGGING_OUTPUT = 'GET_MORE_ONCO_TAGGING_OUTPUT';
const GET_MORE_ONCO_TAGGING_OUTPUT_SUCCESS = 'GET_MORE_ONCO_TAGGING_OUTPUT_SUCCESS';

export const getOncoTaggingOutputAction = createAction(GET_ONCO_TAGGING_OUTPUT);
const getOncoTaggingOutputSuccess = createAction(GET_ONCO_TAGGING_OUTPUT_SUCCESS);
const getOncoTaggingOutputFaliure = createAction(GET_ONCO_TAGGING_OUTPUT_FAILURE);
export const getMoreOncoTaggingOutputAction = createAction(GET_MORE_ONCO_TAGGING_OUTPUT);
const getMoreOncoTaggingOutputSuccess = createAction(GET_MORE_ONCO_TAGGING_OUTPUT_SUCCESS);

export const getOncoTaggingOutputEpic = (actions$) => actions$.pipe(
  ofType(GET_ONCO_TAGGING_OUTPUT),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/v1/utilities/getOntologyTags?${action.payload}`, 'post', {}, true, { Accept: 'application/json' })).pipe(
    map((res) => getOncoTaggingOutputSuccess(res)),
    catchError((err) => of(getOncoTaggingOutputFaliure(err))),
  )),
);
export const getMoreOncoTaggingOutputEpic = (actions$) => actions$.pipe(
  ofType(GET_MORE_ONCO_TAGGING_OUTPUT),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/v1/utilities/getOntologyTags?${action.payload}`, 'post', {}, true, { Accept: 'application/json' })).pipe(
    map((res) => getMoreOncoTaggingOutputSuccess(res)),
    catchError((err) => of(getOncoTaggingOutputFaliure(err))),
  )),
);
export const getOncoTaggingOutputReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(getOncoTaggingOutputAction, (state) => {
        state.loading = true;
      })
      .addCase(getOncoTaggingOutputSuccess, (state, action) => {
        // console.log('sate', state);
        state.data = {
          total_count: action.payload.response.total_count,
          data: [
            ...action.payload.response.data,
          ],
        };
        state.loading = false;
        state.flag = true;
      })
      .addCase(getMoreOncoTaggingOutputAction, (state) => {
        state.loading = false;
      })
      .addCase(getMoreOncoTaggingOutputSuccess, (state, action) => {
        state.data = {
          total_count: action.payload.response.total_count,
          data: [
            ...state.data.data,
            ...action.payload.response.data,
            // data: [...state.data.data.data, ...action.payload.response.data],
          ],
        };
        state.loading = false;
        state.flag = true;
      })
      .addCase(getOncoTaggingOutputFaliure, (state) => {
        state.error = true;
        state.flag = false;
        state.loading = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);
