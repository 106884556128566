/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
// import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import axios from 'axios';
import Rcookie from 'react-cookies';
import Header from '../../../../common/components/Header';
import { terminalsPath } from '../../../../app/components/Card/data';
import ViewDetails from '../ViewDetails';
import { getProtacOutputAction, isReadProtacOutputAction, getMoreProtacOutputAction } from './logic';
import useAppSelector from '../../../../utils/redux-hook';
import { protacCheckStatusResetAction } from '../ProtacInputPage/logic';
import Loader from '../../../../common/components/Loader';
import LazyLoadDiv from '../../../../common/components/LazyLoadDiv';
import ExpandingModal from '../../components/ExpandingModal';
import ImageWithLoader from '../../components/ImageWithLoader';
import PDBViewer from '../../components/MolViewer/PDBViewer';
import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons';
import FullScreenPDBViewer from '../../components/MolViewer/FullScreenPDBViewer';
import Mixpanel from '../../../../utils/mixpanel';

function ProtacOutputPage() {
  const [searchParams] = useSearchParams();
  // const handle = useFullScreenHandle();
  const jobId = searchParams.get('jobId');
  const service = searchParams.get('service');
  const proteinName = searchParams.get('proteinName');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [details, setDetails] = useState(false);
  const [listData, setListData] = useState([]);
  const [itm, setItm] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [id, setId] = useState('');
  const [isPendingDownload, setIsPendingDownload] = useState(false);
  const [isPendingZipDownload, setIsPendingZipDownload] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const getProtacOutputResponse = useAppSelector((state) => state.getProtacOutputResponse);
  // eslint-disable-next-line react/no-unstable-nested-components, react/prop-types
  function Example({ data }) {
    return <img src={`data:image/jpeg;base64,${data}`} alt="" height="50%" />;
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const request = {
      job_id: jobId,
      from: currentPage,
      size: 10,
    };
    dispatch(getProtacOutputAction(queryString.stringify(request)));
    dispatch(protacCheckStatusResetAction());
  }, []);

  useEffect(() => {
    const request = {
      job_id: jobId,
      is_read: true,
    };
    dispatch(isReadProtacOutputAction(queryString.stringify(request)));
  });
  useEffect(() => {
    if (getProtacOutputResponse?.flag) {
      setListData(getProtacOutputResponse?.data?.data?.geneData);
      setTotalCount(getProtacOutputResponse?.data?.data?.totalCount);
    }
  }, [JSON.stringify(getProtacOutputResponse)]);

  const onDetailsChange = () => setDetails(!details);

  const handleBackClick = () => {
    navigate(`${terminalsPath.metad3_protac}?service=${service}&proteinName=${proteinName}`);
  };

  const displayOutputList = () =>
    listData.map((item, index) => (
      <div className="combinations-output-item-outer-part" key={crypto.randomUUID()}>
        <div className="combinations-output-item">
          <div className="item-heading">
            <div className="item-title-box">
              <div className="item-title">{item.display_id}</div>
              <div className={`item-score ${(item.score * 100).toFixed(1) >= 60 ? 'high' : (item.score * 100).toFixed(1) >= 40 && (item.score * 100).toFixed(1) < 60 ? 'medium' : 'low'}`}>{`${(item.score * 100).toFixed(1)}% Confident`}</div>
            </div>
            <div className="view-details-btn">
              <Button
                onClick={() => {
                  setDetails(true);
                  Mixpanel.track('Protac Details Viewed', {
                    action: 'Protac details button clicked',
                    protac_id: item.display_id,
                    protac_details: item,
                    module: 'Protac',
                    service: service,
                  });
                  setItm(item);
                }}
                className="view-btn"
              >
                View Details
              </Button>
            </div>
          </div>
          <div className="structure-container">
            <div className="structure-container-card" style={{ minWidth: '100%' }}>
              <div className="structure-box" style={{ display: 'flex' }}>
                <div className="structure-box-2d">
                  <div className="structure-main">
                    <div className="structure-title">2D Structure</div>
                  </div>
                  {item.protac_image_url === '' ? 'No Image Available' : <ImageWithLoader src={item.protac_image_url} desc="2D Structure of Protac" />}
                </div>
                <div className="structure-box-3d">
                  <div className="structure-main">
                    <div className="structure-title">3D Structure</div>
                    <div className="structure-fullscreen">
                      <button onClick={() => setIsFullScreen(true)}>
                        <FullscreenOutlined style={{ fontSize: '20px', marginTop: '5px' }} />
                      </button>
                    </div>
                  </div>
                  <PDBViewer id={index} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ));

  const loadMoreRows = () => {
    const request = {
      job_id: jobId,
      from: currentPage + 1,
      size: 10,
    };
    dispatch(getMoreProtacOutputAction(queryString.stringify(request)));
    setCurrentPage(currentPage + 1);
  };

  const downloadXLSFile = async ({ isZip }) => {
    if (isZip) {
      setIsPendingZipDownload(true);
      Mixpanel.track('Protac Design Download Zip', {
        action: 'Download zip button clicked',
        module: 'Protac',
        service: service,
      });
    } else {
      setIsPendingDownload(true);
      Mixpanel.track('Protac Design Download File', {
        action: 'Download file button clicked',
        module: 'Protac',
        service: service,
      });
    }
    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`) ? Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`) : '', 'Content-Type': 'blob', 'Access-Control-Allow-Origin': `${process.env.applicationUrl}` };
    const apiUrl = isZip ? `${process.env.apiUrl_protac}/zipDownload?job_id=${jobId}` : `${process.env.apiUrl_protac}/resultDownload?job_id=${jobId}`;
    try {
      const response = await axios({
        method: 'GET',
        url: apiUrl,
        responseType: 'arraybuffer',
        headers,
      });
      setIsPendingDownload(false);
      setIsPendingZipDownload(false);
      const outputFilename = isZip ? `${Date.now()}.zip` : `${Date.now()}.xls`;

      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      throw Error(error);
    }
  };

  return isFullScreen ? (
    <Modal className="molecule-fullscreen-modal" open={isFullScreen} onCancel={() => setIsFullScreen(false)} Header={null} footer={null}>
      <button className="molecule-fullscreen-close-btn" onClick={() => setIsFullScreen(false)}><FullscreenExitOutlined /></button>
      <FullScreenPDBViewer id="full-screen" />
    </Modal>
  ) : details ? (
    <ViewDetails individualItem={itm} handleBack={onDetailsChange} />
  ) : (
    <div className="main-page main-page-modules protac-main-page">
      <Header className="header-home protac-header" logo="protac" />
      <Loader loading={getProtacOutputResponse.loading} error={getProtacOutputResponse.error} height="500px" width="100%">
        <LazyLoadDiv className="protac-lazy-loading" id="protac-lazy-loading-output" total={totalCount} currentTotal={(getProtacOutputResponse.data?.data?.geneData || []).length} loadMoreRows={loadMoreRows} height="500px">
          <div className="ct-home-page">
            <div className="main-heading">
              <div className="back-btn">
                <Button className="back-btn-icon" onClick={() => handleBackClick()}>
                  Back
                </Button>
              </div>
              <div className="main-title">Prioritised list of PROTAC molecules</div>
              <div className="main-description">Based on our Deep Neural Network Model, here is a list of PROTACs, where higher confidence indicates better PROTAC.</div>
              <div className="button-row">
                <div className="download-btns">
                  <div className="main-download-btn">
                    <Button
                      onClick={() => {
                        setIsPendingDownload(true);
                        downloadXLSFile({ isZip: false });
                      }}
                      disabled={isPendingDownload}
                    >
                      {`${isPendingDownload ? 'Downloading...' : 'Download Output'}`}
                    </Button>
                  </div>
                  <div className="main-download-btn">
                    <Button
                      onClick={() => {
                        setIsPendingZipDownload(true);
                        downloadXLSFile({ isZip: true });
                      }}
                      disabled={isPendingZipDownload}
                    >
                      {`${isPendingZipDownload ? 'Downloading...' : 'Download .zip'}`}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="protac-output-list">{displayOutputList()}</div>
          </div>
        </LazyLoadDiv>
      </Loader>
      {id !== '' && <ExpandingModal id={id} closeModal={() => setId('')} />}
    </div>
  );
}

export default ProtacOutputPage;
