import { combineEpics } from 'redux-observable';
import { requestDemoEpic } from './ModalRequestDemo/logic';
import { checkStatusEpic } from '../../modules/meta-ctp/pages/CTPInputPage/logic';
import { getCTPOutputEpic, getMoreCTPOutputEpic, isReadCTPOutputEpic } from '../../modules/meta-ctp/pages/CTPOutputPage/logic';
import { generateLinkersOutputEpic } from '../../modules/dc/pages/DCOutputPage/logic';
import { generateLinkersEpic } from '../../modules/dc/components/InputDropdown/logic';
import { generatePayloadsEpic } from '../../modules/adc/components/InputDropdown/logic';
import { generateADCOutputResponseEpic } from '../../modules/adc/pages/ADCOutputPage/logic';
import { getStudyGroupsEpic } from '../../modules/biomarker/container/SelectStudy/logic';
import { getTerminalsEpic, usersMeEpic } from './Selection/logic';
import { getInputSuggestEpic } from '../../modules/iep/components/InputDropdown/logic';
import {
  generateIndicationsEpic, getInputSynonymsEpic, iepchCheckStatusEpic, uploadCCADocumentEpic,
} from '../../modules/iep/pages/IEPInputPage/logic';
import { checkBiomarkerStatusEpic, getBiomarkerJobIdEpic } from '../../modules/biomarker/pages/BiomarkerInputPage/logic';
import {
  getBiomarkerOutputEpic, getMoreBiomarkerOutputEpic, isReadBiomarkerOutputEpic, volcanoGraphBiomarkerOutputEpic,
} from '../../modules/biomarker/pages/BiomarkerOutputPage/logic';
import { generateTIInputsEpic } from '../../modules/target-id/components/InputDropdown/logic';
import { getInputSuggestDREpic } from '../../modules/dr/components/InputDropdown/logic';
import { getIndicationOutputDREpic, isReadDROutputEpic } from '../../modules/dr/pages/DROutputPage/logic';
import { checkTIStatusEpic, generateTITargetEpic } from '../../modules/target-id/pages/TargetIdInputPage/logic';
import { isReadTIOutputEpic } from '../../modules/target-id/pages/TargetIdOutputPage/logic';
import { checkSARStatusEpic } from '../../modules/sar/pages/SARInputPage/logic';
import { isReadSAROutputEpic } from '../../modules/sar/pages/SAROutputPage/logic';
import { getInputSuggestProtacEpic } from '../../modules/protac/components/InputDropdown/logic';
import { protacCheckStatusEpic, generateDesignProtacEpic } from '../../modules/protac/pages/ProtacInputPage/logic';
import { getMoreProtacOutputEpic, getProtacOutputEpic, isReadProtacOutputEpic } from '../../modules/protac/pages/ProtacOutputPage/logic';
import { uploadSmileDocumentEpic } from '../../modules/sar/container/InputBox/logic';
import { getAutoSuggestEpic } from '../../modules/biomarker/container/BiomarkerInputComponents/SearchDatasets/logic';
import { getFiltersEpic, getSampleFiltersEpic } from '../../modules/biomarker/pages/NewBiomarkerInputPage/logic';
import { getMoreStudiesEpic, getStudiesEpic } from '../../modules/biomarker/container/BiomarkerInputComponents/SelectPreferredStudies/logic';
import { getMoreSamplesEpic, getSamplesEpic } from '../../modules/biomarker/container/BiomarkerInputComponents/ViewSamples/logic';
import { ctpPhaseDistributionEpic } from '../../modules/meta-ctp/container/Graph/BarGraph/logic';
import { ctpKolEpic } from '../../modules/meta-ctp/container/Graph/SlopeGraph/logic';
import { ctpCountryDistributionEpic } from '../../modules/meta-ctp/container/Graph/BubbleGraphContainer/logic';
import { ctpWordCloudEpic } from '../../modules/meta-ctp/container/Graph/WordCloud/logic';
import {
  geoCtDetailsEpic, geoCtEpic, geoCtStatesEpic, geoKolDetailsEpic, geoKolEpic,
  geoKolStatesEpic,
} from '../../modules/meta-ctp/container/Graph/GlobalMap/logic';
import { getMoreSAROutputEpic, getSAROutputEpic } from '../../modules/sar/pages/LeadDetailsPage/logic';
import { getAdmetOutputEpic } from '../../modules/sar/pages/AdmetPropertiesPage/logic';
import {
  getGraphDataEpic, getIndicationOutputEpic, getMoreIndicationOutputEpic,
} from '../../modules/iep/pages/TopIndicationsPage/logic';
import { checkAdmetSARStatusEpic, getAdmetCompareOutputEpic, isReadAdmetSAROutputEpic } from '../../modules/sar/components/AdmetProperties/CompareMoleculeView/logic';
import { getPathwayEnrichmentEpic } from '../../modules/iep/components/TopIndicationGraphs/PathwayEnrichment/logic';
import { getPPINetworkGraphEpic } from '../../modules/iep/components/TopIndicationGraphs/PPINetworkGraph/logic';
import { getLiteratureMiningEpic } from '../../modules/iep/components/TopIndicationGraphs/LiteratureMiningGraph/logic';
import { getMoreTIOutputEpic, getTIOutputEpic } from '../../modules/target-id/pages/DiseaseBasedPage/logic';
import { getClinicalTrialEnrichmentEpic } from '../../modules/iep/components/TopIndicationGraphs/EnrichmentGraph/logic';
import { getAssociatedProteinsDistributionEpic, getMoreAssociatedProteinsDistributionEpic, iepEditCheckStatusEpic } from '../../modules/iep/container/EditAssociatedProteinView/logic';
import { getTargetVisibilityOutputEpic } from '../../modules/target-id/container/TargetVisibilityView/logic';
import { getSafetyProfileOutputEpic } from '../../modules/target-id/container/SafetyProfileView/logic';
import { getDrugDevelopmentOutputEpic } from '../../modules/target-id/container/DrugDevelopmentFeasilibilityView/logic';
import { getTargetFeatureNetworkVisualisationOutputEpic } from '../../modules/target-id/components/DiseaseBasedGraphs/TargetFeaturesNetworkGraph/logic';
import { getTopOrganAffectedOutputEpic } from '../../modules/target-id/container/TopOrganAffectedView/logic';
import { isReadIEPOutputEpic } from '../../modules/iep/pages/IEPOutputPage/logic';
import { getSynonymsEpic, iepAdvanceSearchCheckStatusEpic } from '../../modules/iep/container/AdvanceSearchView/logic';
import {
  clinicalAnalysisJobsIEPEpic, getMoreClinicalAnalysisJobsIEPEpic, getMoreTopIndicationsJobsIEPEpic, iepUpdateWorkspaceStatusEpic, iepWorkspaceStatusEpic, topIndicationsJobsIEPEpic,
} from '../../modules/iep/pages/MyWorkspace/logic';
import { addProteinsFileInputEpic, addProteinsTextualInputEpic } from '../../modules/iep/container/AddProteinsModal/logic';
import { ctpUpdateWorkspaceStatusEpic, hctpWorkspaceStatusEpic, posWorkspaceStatusEpic } from '../../modules/meta-ctp/pages/MyWorkspace/logic';
import { hctpDrugIndicationsEpic } from '../../modules/meta-ctp/pages/HCTPOutputPage/logic';
import { generateHCTPCombinationsEpic, generateInsightsEpic, hctpPairsFileInputEpic } from '../../modules/meta-ctp/container/SaveProjectModal/logic';
import {
  sarAdmetPropertiesWorkspaceStatusEpic, sarBioactivityWorkspaceStatusEpic, sarDrugPredictionWorkspaceStatusEpic, sarLeadGenerationWorkspaceStatusEpic, sarMoreLeadGenerationWorkspaceStatusEpic, sarUpdateWorkspaceStatusEpic,
} from '../../modules/sar/pages/MyWorkspace/logic';
import { generateSARLeadEpic } from '../../modules/sar/container/SaveProjectModal/logic';
import { generateCTPInputsEpic } from '../../modules/meta-ctp/components/InputDropdown/logic';
import { aeVerifySequencesEpic } from '../../modules/antibody-engineering/pages/AntibodyEngineeringInputPage/logic';
import { aeGetSequencesEpic } from '../../modules/antibody-engineering/components/LightChainOutputComponents/RawDataAndDockingComponent/logic';
import { getPhaseDistributionOutputEpic } from '../../modules/target-id/container/PhaseDistributionView/logic';
import { diseaseBasedJobsTIEpic, getMorediseaseBasedJobsTIEpic, tiUpdateWorkspaceStatusEpic } from '../../modules/target-id/pages/MyWorkspace/logic';
import { getExpressionDataEpic } from '../../modules/iep/components/TopIndicationGraphs/ExpressionGraph/logic';
import {
  getMoreOncoTaggingOutputEpic, getOncoTaggingOutputEpic, getUtilitiesPathwayEnrichmentEpic, getUtilitiesPPINetworkEpic, pathwayEnrichmentUploadFileEpic, ppiNetworkUploadFileEpic,
} from '../../modules/utilities/pages/OncoTaggingInputPage/logic';
import { excipientCompatibilityJobsEpic, inhalerFeasibilityJobsDREpic } from '../../modules/drug_reformulation/pages/MyWorkspace/logic';
import { getDPOutputEpic, getMoreDPOutputEpic } from '../../modules/sar/pages/DrugTargetPredictionPage/logic';
import { generateAdmetDREpic, generateAnalysisEpic, generateIndicationsDrugReformEpic } from '../../modules/drug_reformulation/container/SaveProjectModal/logic';
import { drCheckStatusEpic, generateDRIndicationsEpic } from '../../modules/dr/pages/DRInputPage/logic';
import { getDRPatentTimelineEpic } from '../../modules/drug_reformulation/components/PatentAnalysisGraph/PatentLifeTimelineGraph/logic';
import { getDRCPCGraphDataEpic } from '../../modules/drug_reformulation/components/PatentAnalysisGraph/PatentCPCDistributionGraph/logic';
import { getDrPatentsListEpic, getMoreDrPatentsListEpic } from '../../modules/drug_reformulation/container/PatentAnalysis/PatentAnalysisList/logic';
import { getPatentAnalysisDataEpic } from '../../modules/drug_reformulation/components/PatentAnalysisCard/logic';
import { getDrugReformAdmetOutputEpic, getInhalerFeasibilityOutputEpic } from '../../modules/drug_reformulation/container/InhalerFeasibilityCheckView/logic';
import { getBioactivityOutputEpic } from '../../modules/sar/pages/BioactivityPredictionPage/logic';
import { generateOutcomeEpic } from '../../modules/utilities/container/SaveProjectModal/logic';
import { getValidationAutomationWorkspaceStatusEpic, utilitiesUpdateWorkspaceStatusEpic } from '../../modules/utilities/pages/MyWorkspace/logic';
import { getValidationAutomationOutputEpic } from '../../modules/utilities/container/ValidationAutomation/logic';
import { getBioactivityNetworkGraphOutputEpic } from '../../modules/sar/container/BioactivityNetworkGraph/logic';
import { getHCTPDrugInputSuggestEpic, getHCTPIndicationInputSuggestEpic } from '../../modules/meta-ctp/container/HCTPInputDropdown/logic';
import { aeGenerateSequencesEpic, getRawDataAnalysisEpic } from '../../modules/antibody-engineering/pages/LightChainOptimisation/Input/logic';
import {
  getMorelightChainOptimisationJobsEpic, lightChainOptimisationJobsEpic,
} from '../../modules/antibody-engineering/pages/MyWorkspace/logic';
import { aeGetFinalMatrixDataEpic } from '../../modules/antibody-engineering/components/LightChainOutputComponents/PrioritizedSequenceDataComponent/logic';
import { getLcoTableInputDataEpic } from '../../modules/antibody-engineering/container/LightChainOptimisationWorkspaceDetails/logic';
import { getAccuracyScoreEpic } from '../../modules/utilities/pages/OncoTaggingOutputPage/logic';
import { getDiseaseHierarchyDataEpic } from '../../modules/iep/components/DiseaseHierarchyView/logic';
import { protacDesignJobsEpic } from '../../modules/protac/pages/MyWorkspace/logic';
import { getAssociatedProteinsDistributionListEpic, getMoreAssociatedProteinsDistributionListEpic } from '../../modules/iep/components/TopIndicationGraphs/AssociatedProteinDistributionGraph/logic';
import { getMarketAnalysisOutputEpic, getMoreMarketAnalysisOutputEpic } from '../../modules/iep/pages/CCAPage/logic';
import { iepApprovedDrugsGraphEpic } from '../../modules/iep/components/SonarRadarGraph/logic';
import { iepClinicalDrugsTrialGraphEpic } from '../../modules/iep/components/CCATreeMap/logic';

const rootAppEpic = combineEpics(
  requestDemoEpic,
  getTerminalsEpic,
  generateInsightsEpic,
  getCTPOutputEpic,
  getMoreCTPOutputEpic,
  generateLinkersEpic,
  generateLinkersOutputEpic,
  generatePayloadsEpic,
  generateADCOutputResponseEpic,
  checkStatusEpic,
  isReadCTPOutputEpic,
  usersMeEpic,
  getStudyGroupsEpic,
  getInputSuggestEpic,
  generateIndicationsEpic,
  iepchCheckStatusEpic,
  getIndicationOutputEpic,
  isReadIEPOutputEpic,
  checkBiomarkerStatusEpic,
  getBiomarkerJobIdEpic,
  getBiomarkerOutputEpic,
  getMoreBiomarkerOutputEpic,
  isReadBiomarkerOutputEpic,
  volcanoGraphBiomarkerOutputEpic,
  generateTIInputsEpic,
  generateTITargetEpic,
  checkTIStatusEpic,
  getTIOutputEpic,
  isReadTIOutputEpic,
  getInputSuggestDREpic,
  drCheckStatusEpic,
  generateDRIndicationsEpic,
  getIndicationOutputDREpic,
  isReadDROutputEpic,
  generateSARLeadEpic,
  checkSARStatusEpic,
  getSAROutputEpic,
  getAdmetOutputEpic,
  isReadSAROutputEpic,
  checkAdmetSARStatusEpic,
  isReadAdmetSAROutputEpic,
  getAdmetCompareOutputEpic,
  getInputSuggestProtacEpic,
  protacCheckStatusEpic,
  generateDesignProtacEpic,
  getProtacOutputEpic,
  isReadProtacOutputEpic,
  getMoreIndicationOutputEpic,
  getMoreProtacOutputEpic,
  protacDesignJobsEpic,
  getMoreTIOutputEpic,
  getGraphDataEpic,
  uploadSmileDocumentEpic,
  getMoreSAROutputEpic,
  getAutoSuggestEpic,
  getFiltersEpic,
  getStudiesEpic,
  getMoreStudiesEpic,
  getSampleFiltersEpic,
  getSamplesEpic,
  getMoreSamplesEpic,
  ctpPhaseDistributionEpic,
  ctpKolEpic,
  ctpWordCloudEpic,
  geoCtEpic,
  ctpCountryDistributionEpic,
  geoKolEpic,
  getPathwayEnrichmentEpic,
  getMoreAssociatedProteinsDistributionEpic,
  getPPINetworkGraphEpic,
  getLiteratureMiningEpic,
  getClinicalTrialEnrichmentEpic,
  generateCTPInputsEpic,
  iepEditCheckStatusEpic,
  getTargetVisibilityOutputEpic,
  getSafetyProfileOutputEpic,
  getDrugDevelopmentOutputEpic,
  getTargetFeatureNetworkVisualisationOutputEpic,
  getTopOrganAffectedOutputEpic,
  generateCTPInputsEpic,
  getSynonymsEpic,
  iepAdvanceSearchCheckStatusEpic,
  getInputSynonymsEpic,
  iepWorkspaceStatusEpic,
  addProteinsTextualInputEpic,
  addProteinsFileInputEpic,
  uploadCCADocumentEpic,
  hctpWorkspaceStatusEpic,
  posWorkspaceStatusEpic,
  topIndicationsJobsIEPEpic,
  clinicalAnalysisJobsIEPEpic,
  hctpDrugIndicationsEpic,
  generateHCTPCombinationsEpic,
  ctpUpdateWorkspaceStatusEpic,
  getMoreTopIndicationsJobsIEPEpic,
  getMoreClinicalAnalysisJobsIEPEpic,
  hctpPairsFileInputEpic,
  sarLeadGenerationWorkspaceStatusEpic,
  sarMoreLeadGenerationWorkspaceStatusEpic,
  sarAdmetPropertiesWorkspaceStatusEpic,
  sarUpdateWorkspaceStatusEpic,
  getOncoTaggingOutputEpic,
  getMoreOncoTaggingOutputEpic,
  aeVerifySequencesEpic,
  aeGetSequencesEpic,
  getPhaseDistributionOutputEpic,
  diseaseBasedJobsTIEpic,
  getMorediseaseBasedJobsTIEpic,
  tiUpdateWorkspaceStatusEpic,
  iepUpdateWorkspaceStatusEpic,
  geoCtStatesEpic,
  geoCtDetailsEpic,
  geoKolStatesEpic,
  geoKolDetailsEpic,
  getExpressionDataEpic,
  getUtilitiesPathwayEnrichmentEpic,
  getUtilitiesPPINetworkEpic,
  ppiNetworkUploadFileEpic,
  pathwayEnrichmentUploadFileEpic,
  sarDrugPredictionWorkspaceStatusEpic,
  inhalerFeasibilityJobsDREpic,
  excipientCompatibilityJobsEpic,
  getDPOutputEpic,
  generateAnalysisEpic,
  generateAdmetDREpic,
  generateIndicationsDrugReformEpic,
  getDRPatentTimelineEpic,
  getDRCPCGraphDataEpic,
  getDrugReformAdmetOutputEpic,
  getDrPatentsListEpic,
  getMoreDrPatentsListEpic,
  getPatentAnalysisDataEpic,
  getInhalerFeasibilityOutputEpic,
  sarBioactivityWorkspaceStatusEpic,
  getBioactivityOutputEpic,
  generateOutcomeEpic,
  getValidationAutomationWorkspaceStatusEpic,
  getValidationAutomationOutputEpic,
  utilitiesUpdateWorkspaceStatusEpic,
  getBioactivityNetworkGraphOutputEpic,
  getHCTPDrugInputSuggestEpic,
  getHCTPIndicationInputSuggestEpic,
  aeGenerateSequencesEpic,
  lightChainOptimisationJobsEpic,
  getMorelightChainOptimisationJobsEpic,
  aeGetFinalMatrixDataEpic,
  getLcoTableInputDataEpic,
  getAccuracyScoreEpic,
  getDiseaseHierarchyDataEpic,
  getMoreDPOutputEpic,
  getRawDataAnalysisEpic,
  getAssociatedProteinsDistributionListEpic,
  getMoreAssociatedProteinsDistributionListEpic,
  getAssociatedProteinsDistributionEpic,
  getMarketAnalysisOutputEpic,
  getMoreMarketAnalysisOutputEpic,
  iepApprovedDrugsGraphEpic,
  iepClinicalDrugsTrialGraphEpic,
);

export default rootAppEpic;
