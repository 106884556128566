/* eslint-disable */
import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import Rcookie from 'react-cookies';
import { Button, Tooltip } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import MenuItem from 'antd/es/menu/MenuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LazyLoadDiv from '../../../../common/components/LazyLoadDiv';
import Loader from '../../../../common/components/Loader';
import Header from '../../../../common/components/Header';
import { terminalsPath } from '../../../../app/components/Card/data';
import useAppSelector from '../../../../utils/redux-hook';
import { getTIOutputAction, getMoreTIOutputAction } from './logic';
import CircularGauge from '../../components/DiseaseBasedGraphs/CircularGauge';
import TIGraphicalView from '../GraphicalView';
import Mixpanel from '../../../../utils/mixpanel';

function DiseaseBased() {
  // const diseaseName = searchParams.get('diseaseName');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const jobId = searchParams.get('jobId');
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(-1);
  const outputResponse = useAppSelector((state) => state.getTIOutputResponse);
  const [isGraphical, setIsGraphical] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [isPendingDownload, setIsPendingDownload] = useState(false);
  const [isPendingZipDownload, setIsPendingZipDownload] = useState(false);
  const [diseaseName, setDisease] = useState('');
  const [service, setService] = useState('');



  const renderGraphicalContent = useMemo(() => {
    return (
      <TIGraphicalView jobId={jobId} />
    );
  }, [jobId]);

  useEffect(() => {
    const request = {
      job_id: jobId,
      page: currentPage,
      size: 10,
    };
    dispatch(getTIOutputAction(queryString.stringify(request)));
  }, []);

  useEffect(() => {
    if (outputResponse?.flag) {
      setDisease(
        outputResponse?.data?.data?.job_data?.user_input?.disease_name,
      );
      setService(outputResponse?.data?.data?.job_data?.user_input?.service);
      setTotalCount(outputResponse?.data?.data?.total_count);
    }
  }, [JSON.stringify(outputResponse)]);
  const showTooltip = (value) => (
    <Tooltip placement="topLeft" title={value}>
      {value}
    </Tooltip>
  );

  const infoComponent = (heading, value) => (
    <div className="info-component-main">
      <div className="info-component-heading">{heading}</div>
      <div className="info-component-value">{value}</div>
    </div>
  );
  const scoreItem = (title, value, isTitle) => (
    <div className={`score-item-main${isTitle && '-title'}`}>
      <div className="score-item-title">{title}</div>
      <div className="score-item-value">
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      </div>
    </div>
  );

  function decimalConversion(value) {
    return (Math.round(value * 1000) / 1000).toFixed(2);
  }

  function litScore(item) {
    const avg = (item.pub_scaled_score
        + item.ct_scaled_score
        + item.pat_scaled_score
        + item.grant_scaled_score
        + item.cong_scaled_score
        + item.pr_scaled_score)
      / 6;
    return decimalConversion(avg);
  }

  const literatureScoreComponent = (item) => (
    <div className="literature-score-main">
      <div className="inside-card">
        {scoreItem('Literature Score', litScore(item), true)}
        {scoreItem(
          'Publication Score',
          decimalConversion(item.pub_scaled_score),
          false,
        )}
        {scoreItem(
          'Clinical Trial Score',
          decimalConversion(item.ct_scaled_score),
          false,
        )}
        {scoreItem(
          'Patent Score',
          decimalConversion(item.pat_scaled_score),
          false,
        )}
        {scoreItem(
          'Grants Score',
          decimalConversion(item.grant_scaled_score),
          false,
        )}
        {scoreItem(
          'Congress Score',
          decimalConversion(item.cong_scaled_score),
          false,
        )}
        {scoreItem(
          'News Score',
          decimalConversion(item.pr_scaled_score),
          false,
        )}
      </div>
    </div>
  );

  function bioScore(item) {
    const avg = (item.druggability_score
        + item.genomics_score
        + item.pathway_score
        + item.expression_score
        + item.safety_score)
      / 5;
    return decimalConversion(avg);
  }
  function BiologicalParameterScoreComponent(item) {
    return (
      <div className="biological-parameter-score-main">
        <div className="inside-card">
          {scoreItem('Biological Parameter Score', bioScore(item), true)}
          {scoreItem(
            'Druggability score',
            item.druggability_score !== null ? item.druggability_score : 0,
            false,
          )}
          {scoreItem('Genomics score', item.genomics_score, false)}
          {scoreItem('Pathway analysis score', item.pathway_score, false)}
          {scoreItem(
            'Normal expression score (in disease relevant organ)',
            item.expression_score,
            false,
          )}
          {scoreItem('Target safety score', item.safety_score, false)}
        </div>
      </div>
    );
  }
  function OverallScoreComponent(item) {
    const overall_score = 1 - item.cosine_distance;
    const conv = (Math.round(overall_score * 1000) / 1000).toFixed(2);
    return (
      <div className="biological-parameter-score-main">
        <div className="inside-card">
          {scoreItem('Overall Score', conv, true)}
          {scoreItem('Literature Score', litScore(item), false)}
          {scoreItem('Biological Parameter Score', bioScore(item), false)}
        </div>
      </div>
    );
  }
  const displayDrugItem = (item) => (
    <div className="combinations-drugs-info">
      <div className="info-component-row">
        <div className="info-component">
          {infoComponent('Preferred Name', showTooltip(item.preferred_name))}
        </div>
        <div className="info-component">
          {infoComponent('Literature Score', showTooltip(litScore(item)))}
        </div>
        <div className="info-component">
          {infoComponent(
            'Target Safety',
            <div
              className={
                litScore(item) > 0.6
                  ? 'content-at-center-tag high'
                  : litScore(item) <= 0.6 && litScore(item) > 0.4
                    ? 'content-at-center-tag medium'
                    : 'content-at-center-tag low'
              }
            >
              <div className="tag-value">
                {litScore(item) > 0.6
                  ? 'High'
                  : litScore(item) <= 0.6 && litScore(item) > 0.4
                    ? 'Medium'
                    : 'Low'}
              </div>
            </div>,
          )}
        </div>
      </div>
      <div className="info-component-row">
        <div className="info-component">
          {infoComponent('Uniprot ID', showTooltip(item.uni_prot_id))}
        </div>
        <div className="info-component">
          {infoComponent(
            'Biological Parameter Score',
            showTooltip(bioScore(item)),
          )}
        </div>
        <div className="info-component">
          {infoComponent(
            'Druggability',
            <div
              className={
                item.druggability_score > 0.6
                  ? 'content-at-center-tag high'
                  : item.druggability_score <= 0.6
                      && item.druggability_score > 0.4
                    ? 'content-at-center-tag medium'
                    : 'content-at-center-tag low'
              }
            >
              <div className="tag-value">
                {item.druggability_score > 0.6
                  ? 'High'
                  : item.druggability_score <= 0.6
                      && item.druggability_score > 0.4
                    ? 'Medium'
                    : 'Low'}
              </div>
            </div>,
          )}
        </div>
      </div>
    </div>
  );

  const handleButtonClick = (index) => {
    setIsOpen((prev) => {
      if (prev === index) {
        return -1;
      }
      return index;
    });
  };
  const handleClick = (val) => {
    if (val) {
      Mixpanel.track('Target-Id Disease Based graphical view', {
        action: 'Target-Id Disease Based graphical view selected',
        module: 'Target ID',
        service: 'Disease-based Indication',
      });
    } else {
      Mixpanel.track('Target-Id Disease Based list view', {
        action: 'Target-Id Disease Based list view selected',
        module: 'Target ID',
        service: 'Disease-based Indication',
      });
    }
    setIsGraphical(val);
  };
  const downloadXLSFile = async ({ isZip }) => {
    if (isZip) {
      setIsPendingZipDownload(true);
      Mixpanel.track('Target-Id Disease Based download zip', {
        action: 'Target-Id Disease Based download zip clicked',
        module: 'Target ID',
        service: 'Disease-based Indication',
      });
    } else {
      setIsPendingDownload(true);
      Mixpanel.track('Target-Id Disease Based download file', {
        action: 'Target-Id Disease Based download file clicked',
        module: 'Target ID',
        service: 'Disease-based Indication',
      });
    }
    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = {
      Authorization: Rcookie.load(
        `${process.env.app}_accessToken_${process.env.type}`,
      )
        ? Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`)
        : '',
      'Content-Type': 'blob',
      'Access-Control-Allow-Origin': `${process.env.applicationUrl}`,
    };
    const apiUrl = isZip
      ? `${process.env.apiUrl_ti}/scoreZipDownload?job_id=${jobId}`
      : `${process.env.apiUrl_ti}/scoreResultDownload?job_id=${jobId}`;

    try {
      const response = await axios({
        method: 'GET',
        url: apiUrl,
        responseType: 'arraybuffer',
        headers,
      });
      setIsPendingDownload(false);
      setIsPendingZipDownload(false);
      const outputFilename = isZip ? `${Date.now()}.zip` : `${Date.now()}.xls`;

      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      throw Error(error);
    }
  };
  const displayOutputList = () => {
    let arr = outputResponse?.data?.data?.model_results;
    let removeList = [
      'C17orf47',
      'TNFRSF8',
      'CD33',
      'ERBB4',
      'TEK',
      'SLC6A2',
      'F9',
      'WDHD1',
    ];
    if (diseaseName === 'colorectal neoplasms') {
      removeList = removeList.map((item) => item.toLowerCase());
      arr = arr.filter(
        (item) => !removeList.includes(item.target.toLowerCase()),
      );
    }
    return arr.map((item, index) => {
      const overallScore = 1 - item.cosine_distance;
      const conv = (Math.round(overallScore * 1000) / 1000).toFixed(2);
      return (
        <div
          className={`combinations-output-item-outer-part ${isOpen === index ? 'open' : 'close'}`}
        >
          <div className="combinations-output-item">
            <div className="item-title">{item.target}</div>
            <div className="combinations-item-content">
              <div className="combinations-drugs-list">
                {displayDrugItem(item)}
              </div>
              <CircularGauge value={conv} />
            </div>
            <div
              className="combinations-output-item-button"
              onClick={() => {
                handleButtonClick(index);
                Mixpanel.track('Target-Id Proteins card Literature, Biological and Overall Scores viewed', {
                  action: 'Target-Id Disease Based expand click',
                  module: 'Target ID',
                  target: item.target,
                  details: item,
                  service: 'Disease-based Indication',
                });
              }}
              style={{ color: 'black', cursor: 'pointer' }}
            >
              <div className="arrow-icon" />
            </div>
          </div>
          <div className="combinations-output-lower-part">
            {literatureScoreComponent(item)}
            {BiologicalParameterScoreComponent(item)}
            {OverallScoreComponent(item)}
          </div>
        </div>
      );
    });
  };

  const loadMoreRows = () => {
    const request = {
      job_id: jobId,
      page: currentPage + 1,
      size: 10,
    };
    dispatch(getMoreTIOutputAction(queryString.stringify(request)));
    setCurrentPage(currentPage + 1);
  };
  return (
    <div className="main-page main-page-modules">
      <Header className="header-home ti-header" logo="ti-logo" isWorkspace modulePath={terminalsPath.metad3_target_id_workspace} />
      <Loader loading={outputResponse.loading} error={outputResponse.error}>

        <div className="ct-home-page">
          <div className="main-heading">
            <div className="back-btn">
              <Button
                className="back-btn-icon"
                onClick={() => {
                  navigate(
                    `${terminalsPath.metad3_target_id}?service=${service}&diseaseName=${diseaseName}`,
                  );
                }}
              >
                Back
              </Button>
            </div>
            <div className="main-title">{`Prioritized list of target proteins for "${diseaseName}"`}</div>
            <div className="main-description">
              Prioritized list of target proteins based on cosine similarity
              and k-means clustering scores, ranging from 0 to 1, where 0 is
              low and 1 is high.
            </div>
            <div className="button-row">
              <div className="download-btns">
                <div className="main-download-btn">
                  <Button
                    onClick={() => {
                      setIsPendingDownload(true);
                      downloadXLSFile({ isZip: false });
                    }}
                    disabled={isPendingDownload}
                  >
                    {`${isPendingDownload ? 'Downloading...' : 'Download Output'}`}
                  </Button>
                </div>
                <div className="main-download-btn">
                  <Button
                    onClick={() => {
                      setIsPendingZipDownload(true);
                      downloadXLSFile({ isZip: true });
                    }}
                    disabled={isPendingZipDownload}
                  >
                    {`${isPendingZipDownload ? 'Downloading...' : 'Download .zip'}`}
                  </Button>
                </div>
              </div>
              <div className="main-toggle-btn iep-main-toggle-btn" style={{ display: 'flex' }}>
                <Button
                  onClick={() => handleClick(false)}
                  className={`toggle-btn ${!isGraphical ? 'active' : ''}`}
                >
                  <div className="toggle-icon1" aria-hidden="true">
                    <FontAwesomeIcon icon={MenuItem} />
                  </div>
                </Button>
                <Button
                  onClick={() => handleClick(true)}
                  className={`toggle-btn ${isGraphical ? 'active' : ''}`}
                >
                  <div className="toggle-icon2" aria-hidden="true" />
                </Button>
              </div>
            </div>
          </div>

          {!isGraphical ? (
            <LazyLoadDiv
              className="ti-lazy-loading"
              id="ti-lazy-loading-output"
              total={totalCount}
              currentTotal={
                (outputResponse?.data?.data?.model_results || []).length
              }
              loadMoreRows={loadMoreRows}
              height="calc(100vh - 0px)"
            >
              <div className="ti-output-list">
                {outputResponse.flag && displayOutputList()}
              </div>
            </LazyLoadDiv>
          ) : renderGraphicalContent }
        </div>
      </Loader>
    </div>
  );
}

export default DiseaseBased;
