import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../utils';

// get Partner details
const GET_LCO_TABLE_INPUT_DATA = 'GET_LCO_TABLE_INPUT_DATA';
const GET_LCO_TABLE_INPUT_DATA_SUCCESS = 'GET_LCO_TABLE_INPUT_DATA_SUCCESS';
const GET_LCO_TABLE_INPUT_DATA_FAILURE = 'GET_LCO_TABLE_INPUT_DATA_FAILURE';

export const getLcoTableInputDataAction = createAction(GET_LCO_TABLE_INPUT_DATA);

const getLcoTableInputDataSuccess = createAction(GET_LCO_TABLE_INPUT_DATA_SUCCESS);

const getLcoTableInputDataFaliure = createAction(GET_LCO_TABLE_INPUT_DATA_FAILURE);
export const getLcoTableInputDataEpic = (actions$) => actions$.pipe(
  ofType(GET_LCO_TABLE_INPUT_DATA),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_ae}/getInputData?${action.payload}`, 'get')).pipe(
    map((res) => getLcoTableInputDataSuccess(res)),
    catchError((err) => of(getLcoTableInputDataFaliure(err))),
  )),
);

const getLcoTableInputDataReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(getLcoTableInputDataAction, (state) => {
        state.loading = true;
      })
      .addCase(getLcoTableInputDataSuccess, (state, action) => {
        state.data = { ...action.payload.response };
        state.loading = false;
        state.flag = true;
      })
      .addCase(getLcoTableInputDataFaliure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);

export default getLcoTableInputDataReducer;
