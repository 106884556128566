import React from 'react';
import PropTypes from 'prop-types';
import { Button, Divider } from 'antd';
import { useNavigate } from 'react-router-dom';
import { terminalsPath } from '../../../../app/components/Card/data';
import { getCookie } from '../../../../utils';
import Mixpanel from '../../../../utils/mixpanel';

function ProtacDesignWorkspaceDetailsView({ job }) {
  const navigate = useNavigate();
  const userEmailId = getCookie('usersEmailId');

  const formatDate = (epoch) => {
    const date = new Date(epoch * 1000);

    const day = date.getDate();
    const month = date.toLocaleString('en-US', { month: 'short' });

    return `${day} ${month}`;
  };
  const formatTime = (epoch) => {
    const date = new Date(epoch * 1000);

    const time = date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });

    return time;
  };

  return (
    <div className="job-details-main">
      {job?.status.toLowerCase() !== 'completed' && <div className={`job-pending-warning ${job?.status.toLowerCase() === 'pending' ? ' pending' : 'failed'}`}>{`${job?.status.toLowerCase() === 'pending' ? `Output generation is in progress. Once it is generated, we will notify you over your email, “${userEmailId}”` : 'Due to a technical error, the output generation failed. Please click the “Resubmit Input” button to try again.'}`}</div>}
      <div className="job-details-title">Input Details</div>
      <div className="job-details-content">
        <div className="job-name-row" style={{ display: 'flex' }}>
          <div className="job-name-group" style={{ display: 'flex' }}>
            <div className="job-name-group-title">{job?.job_name}</div>
            <div className="job-created-at">
              {formatDate(job?.created_date_time_ts)}
              <Divider
                type="vertical"
              />
              {formatTime(job?.created_date_time_ts)}
            </div>
          </div>
          <div className="download-output-btn">
            <Button
              disabled={job?.status.toLowerCase() === 'pending'}
              onClick={() => {
                if (job?.status.toLowerCase() === 'rejected') {
                  navigate(terminalsPath.metad3_protac);
                  Mixpanel.track('TI Resubmit Input Clicked', {
                    action: 'TI Resubmit input button from TI workspace clicked', job_id: job?.job_id, service: 'Clinical Analysis', module: 'TI',
                  });
                } else {
                  navigate(`${terminalsPath.metad3_protac_output}?jobId=${job.job_id}`);
                }
              }}
            >
              {`${job?.status.toLowerCase() === 'rejected' ? 'Resubmit Input' : 'View Output'}`}
            </Button>
          </div>
        </div>
        {job?.description?.length ? (
          <div className="synonyms-changes">
            <div className="synonyms-changes-title">Description</div>
            <div className="synonyms-changes-content" style={{ display: 'flex' }}>
              <div className="added-synonyms">
                <div className="added-synonyms-list">{job?.description ? job?.description : 'No description'}</div>
              </div>
            </div>
          </div>
        ) : null}
        <div className="synonyms-changes">
          <div className="synonyms-changes-content synonyms-changes-content-new workspace">
            <div className="input-key">Protein Name: </div>
            <div className="input-value">{job?.user_input?.protein_name}</div>
          </div>
          <div className="synonyms-changes-content synonyms-changes-content-new workspace">
            <div className="input-key">Service Name: </div>
            <div className="input-value">{job?.user_input?.service.replace('_', ' ')}</div>
          </div>
        </div>

      </div>
    </div>
  );
}

ProtacDesignWorkspaceDetailsView.propTypes = {
  job: PropTypes.objectOf.isRequired,
};

export default ProtacDesignWorkspaceDetailsView;
