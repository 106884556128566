import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import { sendErrorNotification } from '../Notification/logic';
import Mixpanel from '../../../utils/mixpanel';
import { setCookie } from '../../../utils';

function LoginModalButton({ webAuth, className, flag = false }) {
  const dispatch = useDispatch();
  const openLoginModal = () => {
    Mixpanel.track('User Logged In', {
      action: 'login button clicked',
      view: 'landing page',
    });
    setCookie(true, 'pageLoading');
    webAuth.authorize({
      responseType: 'token',
      redirectUri: `${process.env.redirectUri}`,
    }, (err) => {
      dispatch(sendErrorNotification({
        message: err,
      }));
    });
  };

  const clasess = classnames(className, {
    'navbar-group-right-item': flag,
  });

  return (
    <div data-testid="login" aria-hidden onClick={openLoginModal} className={clasess}>
      Login
    </div>
  );
}

LoginModalButton.propTypes = {
  webAuth: PropTypes.PropTypes.shape({
    authorize: PropTypes.func,
  }).isRequired,
  className: PropTypes.string,
  flag: PropTypes.bool,
};

LoginModalButton.defaultProps = {
  flag: false,
  className: '',
};

export default LoginModalButton;
