import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import { Provider } from 'react-redux';
import store from './store';
import ErrorPage from './utils/ErrorPage';
import '../public/styles/main.scss';
import Main from './app/container/Main';
import './utils/app.css';
import CTPInputPage from './modules/meta-ctp/pages/CTPInputPage';
import CTPOutputPage from './modules/meta-ctp/pages/CTPOutputPage';
import HomePage from './app/pages/HomePage';
import LandingPage from './app/pages/LandingPage';
import AccessPage from './common/pages/AccessPage';
import DCOutputPage from './modules/dc/pages/DCOutputPage';
import DCInputPage from './modules/dc/pages/DCInputPage';
import ADCOutputPage from './modules/adc/pages/ADCOutputPage';
import IEPInputPage from './modules/iep/pages/IEPInputPage';
import IEPOutputPage from './modules/iep/pages/IEPOutputPage';
import ADCInputPage from './modules/adc/pages/ADCInputPage';
import DROutputPage from './modules/dr/pages/DROutputPage';
import DRInputPage from './modules/dr/pages/DRInputPage';
import TargetIdInputPage from './modules/target-id/pages/TargetIdInputPage';
import TargetIdOutputPage from './modules/target-id/pages/TargetIdOutputPage';
import ProtacInputPage from './modules/protac/pages/ProtacInputPage';
import SARInputPage from './modules/sar/pages/SARInputPage';
import SAROutputPage from './modules/sar/pages/SAROutputPage';
import ProtacOutputPage from './modules/protac/pages/ProtacOutputPage';
import BiomarkerInputPage from './modules/biomarker/pages/NewBiomarkerInputPage';
import BiomarkerOutputPage from './modules/biomarker/pages/BiomarkerOutputPage';
import MyWorkspacePageIEP from './modules/iep/pages/MyWorkspace';
import MyWorkspacePageCTP from './modules/meta-ctp/pages/MyWorkspace';
import MyWorkspacePageSAR from './modules/sar/pages/MyWorkspace';
import OncoTaggingInputPage from './modules/utilities/pages/OncoTaggingInputPage';
import OncoTaggingOutputPage from './modules/utilities/pages/OncoTaggingOutputPage';
import AntibodyEngineeringInputPage from './modules/antibody-engineering/pages/AntibodyEngineeringInputPage';
import AntibodyEngineeringOutputPage from './modules/antibody-engineering/pages/AntibodyEngineeringOutputPage';
import MyWorkspacePageTI from './modules/target-id/pages/MyWorkspace';

const el = document.getElementById('root');
const root = ReactDOM.createRoot(el);

mixpanel.init(process.env.mixpanelToken, { debug: true });

const router = createBrowserRouter([
  {
    path: '/',
    element: <Main />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '',
        element: <LandingPage />,
      },
      {
        path: 'home',
        element: <HomePage />,
      },
      {
        path: '/access',
        element: <AccessPage />,
      },
    ],
  },
  {
    path: '/meta-ctp',
    element: <Main />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'home',
        element: <CTPInputPage module="Meta CTP" />,
      },
      {
        path: 'output',
        element: <CTPOutputPage module="Meta CTP" />,
      },
      {
        path: 'my-workspace',
        element: <MyWorkspacePageCTP />,
      },
    ],
  },
  {
    path: '/biomarker',
    element: <Main />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'home',
        element: <BiomarkerInputPage />,
      },
      {
        path: 'output',
        element: <BiomarkerOutputPage />,
      },
    ],
  },
  {
    path: '/adc',
    element: <Main />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'home',
        element: <ADCInputPage module="ADC" />,
      },
      {
        path: 'output',
        element: <ADCOutputPage module="ADC" />,
      },
    ],
  },
  {
    path: '/dc',
    element: <Main />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'home',
        element: <DCInputPage module="DC" />,
      },
      {
        path: 'output',
        element: <DCOutputPage module="DC" />,
      },
    ],
  },
  {
    path: '/target-id',
    element: <Main />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'home',
        element: <TargetIdInputPage module="Target-Id" />,
      },
      {
        path: 'output',
        element: <TargetIdOutputPage module="Target-Id" />,
      },
      {
        path: 'my-workspace',
        element: <MyWorkspacePageTI />,
      },
    ],
  },
  {
    path: '/dr',
    element: <Main />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'home',
        element: <DRInputPage module="DR" />,
      },
      {
        path: 'output',
        element: <DROutputPage module="DR" />,
      },
    ],
  },
  {
    path: '/sar',
    element: <Main />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'home',
        element: <SARInputPage module="SAR" />,
      },
      {
        path: 'output',
        element: <SAROutputPage module="SAR" />,
      },
      {
        path: 'my-workspace',
        element: <MyWorkspacePageSAR />,
      },
    ],
  },
  {
    path: '/iep',
    element: <Main />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'home',
        element: <IEPInputPage />,
      },
      {
        path: 'output',
        element: <IEPOutputPage module="IEP" />,
      },
      {
        path: 'my-workspace',
        element: <MyWorkspacePageIEP />,
      },
    ],
  },
  {
    path: '/protac',
    element: <Main />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'home',
        element: <ProtacInputPage />,
      },
      {
        path: 'output',
        element: <ProtacOutputPage module="Protac" />,
      },
    ],
  },
  {
    path: '/utilities',
    element: <Main />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'home',
        element: <OncoTaggingInputPage />,
      },
      {
        path: 'output',
        element: <OncoTaggingOutputPage />,
      },
    ],
  },
  {
    path: '/antibody',
    element: <Main />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'home',
        element: <AntibodyEngineeringInputPage />,
      },
      {
        path: 'output',
        element: <AntibodyEngineeringOutputPage />,
      },
    ],
  },
]);
root.render(
  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>,
);
