import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../utils';

// check status
const APPROVED_DRUGS_GRAPH = 'APPROVED_DRUGS_GRAPH';
const APPROVED_DRUGS_GRAPH_SUCCESS = 'APPROVED_DRUGS_GRAPH_SUCCESS';
const APPROVED_DRUGS_GRAPH_FAILURE = 'APPROVED_DRUGS_GRAPH_FAILURE';
const APPROVED_DRUGS_GRAPH_RESET = 'APPROVED_DRUGS_GRAPH_RESET';

export const iepApprovedDrugsGraphAction = createAction(APPROVED_DRUGS_GRAPH);
export const iepApprovedDrugsGraphResetAction = createAction(APPROVED_DRUGS_GRAPH_RESET);

const iepApprovedDrugsGraphsSuccess = createAction(APPROVED_DRUGS_GRAPH_SUCCESS);
const iepApprovedDrugsGraphFailure = createAction(APPROVED_DRUGS_GRAPH_FAILURE);

export const iepApprovedDrugsGraphEpic = (actions$) => actions$.pipe(
  ofType(APPROVED_DRUGS_GRAPH),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_iep}/approvedDrugsGraphData?${action.payload}`, 'get')).pipe(
    map((res) => iepApprovedDrugsGraphsSuccess(res)),
    catchError((err) => of(iepApprovedDrugsGraphFailure(err))),
  )),
);

export const iepApprovedDrugsGraphReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(iepApprovedDrugsGraphAction, (state) => {
        state.loading = true;
      })
      .addCase(iepApprovedDrugsGraphsSuccess, (state, action) => {
        state.data = { ...action.payload.response };
        state.loading = false;
        state.flag = true;
      })
      .addCase(iepApprovedDrugsGraphFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(iepApprovedDrugsGraphResetAction, (state) => {
        state.data = [];
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);
