/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
// import { v4 as uuidv4 } from 'uuid';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Graph from 'react-vis-network-graph';
import useAppSelector from '../../../../../utils/redux-hook';
// import { getPPINetworkGraphAction } from './logic';
import Loader from '../../../../../common/components/Loader';
import { XFilled } from '@ant-design/icons';
// import { nodes as allNodes, edges as allEdges } from './data';
import { getPPINetworkGraphAction } from '../PPINetworkGraph/logic';
import _ from 'lodash';

function PPINetwotkGraph({ jobId }) {
  const dispatch = useDispatch();
  const getPPINetworkGraphResponse = useAppSelector((state) => state.getPPINetworkGraphResponse);
  const [graphData, setGraphData] = useState({});
  const [allNodes, setAllNodes] = useState([]);
  const [allEdges, setAllEdges] = useState([]);
  const [graphParsing, setGraphParsing] = useState(false);
  const [highlightActive, setHighlightActive] = useState(false);
  // let highlightActive = false;
  const networkRef = useRef(null); // Create a reference for the Graph component

  useEffect(() => {
    const request = {
      // job_id: '47f9d2b3-8bc2-4608-b3cf-069e581f091c',
      job_id: jobId,
    };
    dispatch(getPPINetworkGraphAction(queryString.stringify(request)));
  }, []);

  useEffect(() => {
    if (getPPINetworkGraphResponse.flag) {
      setGraphParsing(true);
      let zero;
      const nodes = [];
      let cnt = 0;
      for (const i of getPPINetworkGraphResponse.data.data.nodes) {
        const title = '<div style="border-bottom: 1px solid rgba(255,255,255,.3); font-size: 28px; padding: 4px; border-radius: 8px">' + '<p style="font-family: Inter;font-size: 12px;color: #59748A; margin-bottom: 5px;">Associated Protein: </p>' + '<p style="font-family: Inter;font-size: 14px; font-weight: 500; color: #121417;">' + `${i.title}` + '</p>' + '</div>';
        // const title = '';
        const font = {
          size: 35,
          bold: true,
        };
        var obj = { ...i, title, font };
        if (i.id === 0) {
          zero = obj;
          obj = { ...obj, value: 80 };
          // continue;
        }
        nodes.push(obj);
      }
      const edges = [];
      for (const i of getPPINetworkGraphResponse.data.data.edges) {
        const obj = { ...i, color: undefined };
        edges.push(obj);
      }
      setGraphData((prev) => {
        return { nodes: _.cloneDeep(nodes), edges: _.cloneDeep(edges) };
      });
      setAllNodes(nodes);
      setAllEdges(edges);
      setGraphParsing(false);
    }
  }, [JSON.stringify(getPPINetworkGraphResponse)]);

  // const options = {
  //   physics: {
  //     enabled: true,
  //     solver: 'barnesHut', // 'forceAtlas2Based' or 'repulsion' are other options
  //     barnesHut: {
  //       gravitationalConstant: -20000, // More negative for more repulsion
  //       centralGravity: 0.1, // Lower values mean less attraction to the center
  //       springLength: 550, // Desired distance between connected nodes
  //       springConstant: 0.05, // Rigidity of the edges
  //       damping: 0.09, // Reduces the velocity of moving nodes
  //       avoidOverlap: 1, // Prevents overlap
  //     },
  //     stabilization: {
  //       enabled: true,
  //       iterations: 100, // Number of iterations to stabilize
  //       updateInterval: 25,
  //       onlyDynamicEdges: false,
  //       fit: true,
  //     },
  //     timestep: 0.5,
  //     adaptiveTimestep: true,
  //   },
  //   layout: {
  //     improvedLayout: false,
  //   },
  //   interaction: {
  //     zoomView: false,
  //     selectable: true,
  //   },
  //   edges: {
  //     // color: 'red',
  //     width: 2,
  //     color: { inherit: "from" },
  //     smooth: {
  //       type: "continuous",
  //     },
  //     arrows: {
  //       to: {
  //         enabled: false,
  //       },
  //       middle: {
  //         enabled: false,
  //       },
  //       from: {
  //         enabled: false,
  //       },
  //     },
  //   },
  //   nodes: {
  //     shape: 'dot',
  //     scaling: {
  //       // label: {
  //       //   enabled: false,
  //       //   min: 25,
  //       // },
  //       customScalingFunction(min, max, total, value) {
  //         return ((value * 10) / total) * 2.25;
  //       },
  //       min: 20,
  //       max: 150,
  //     },
  //   },
  //   height: '90%',
  //   width: '100%',
  // };

  var options = {
    physics: {
      enabled: true,
      solver: 'barnesHut',
      barnesHut: {
        gravitationalConstant: -2000, // More negative for more repulsion
        centralGravity: 0.05, // Lower values mean less attraction to the center
        springLength: 750, // Desired distance between connected nodes
        springConstant: 0.005, // Rigidity of the edges
        damping: 0.09, // Reduces the velocity of moving nodes
        avoidOverlap: 1, // Prevents overlap
      },
      stabilization: {
        enabled: true,
        iterations: 150, // Number of iterations to stabilize
        updateInterval: 25,
        onlyDynamicEdges: false,
        fit: true,
      },
      timestep: 0.5,
      adaptiveTimestep: true,
    },
    // nodes: {
    //   shape: 'dot',
    //   scaling: {
    //     min: 10,
    //     max: 30,
    //     label: {
    //       min: 8,
    //       max: 30,
    //       drawThreshold: 12,
    //       maxVisible: 20,
    //     },
    //   },
    //   font: {
    //     size: 12,
    //     face: 'Tahoma',
    //   },
    // },
    nodes: {
      shape: 'dot',
      scaling: {
        label: {
          enabled: false,
          min: 35,
          // drawThreshold: 12,
          // maxVisible: 20,
        },
        customScalingFunction(min, max, total, value) {
          return ((value * 10) / total) * 2.25;
        },
        min: 20,
        max: 150,
      },
    },
    edges: {
      width: 0.15,
      color: { inherit: 'from' },
      arrows: {
        to: {
          enabled: false,
        },
        middle: {
          enabled: false,
        },
        from: {
          enabled: false,
        },
      },
      smooth: {
        type: 'continuous',
      },
    },
    layout: {
      improvedLayout: false,
    },
    interaction: {
      tooltipDelay: 200,
      // hideEdgesOnDrag: true,
      // hideEdgesOnZoom: true,
    },
  };

  // function neighbourhoodHighlight(params) {
  //   // if something is selected:
  //   const network = networkRef.current.Network;
  //   if (params.nodes.length > 0) {
  //     highlightActive = true;
  //     var i, j;
  //     var selectedNode = params.nodes[0];
  //     var degrees = 2;

  //     // mark all nodes as hard to read.
  //     for (var nodeId in allNodes) {
  //       allNodes[nodeId].color = 'rgba(200,200,200,0.5)';
  //       if (allNodes[nodeId].hiddenLabel === undefined) {
  //         allNodes[nodeId].hiddenLabel = allNodes[nodeId].label;
  //         allNodes[nodeId].label = undefined;
  //       }
  //     }
  //     var connectedNodes = network.getConnectedNodes(selectedNode);
  //     var allConnectedNodes = [];

  //     // get the second degree nodes
  //     for (i = 1; i < degrees; i++) {
  //       for (j = 0; j < connectedNodes.length; j++) {
  //         allConnectedNodes = allConnectedNodes.concat(network.getConnectedNodes(connectedNodes[j]));
  //       }
  //     }

  //     // all second degree nodes get a different color and their label back
  //     console.log('allConnectedNodes', allConnectedNodes);

  //     for (i = 0; i < allConnectedNodes.length; i++) {
  //       if (allNodes[allConnectedNodes[i]] !== undefined) {
  //         allNodes[allConnectedNodes[i]].color = 'rgba(150,150,150,0.75)';
  //         if (allNodes[allConnectedNodes[i]].hiddenLabel !== undefined) {
  //           allNodes[allConnectedNodes[i]].label = allNodes[allConnectedNodes[i]].hiddenLabel;
  //           allNodes[allConnectedNodes[i]].hiddenLabel = undefined;
  //         }
  //       }
  //     }

  //     // all first degree nodes get their own color and their label back
  //     for (i = 0; i < connectedNodes.length; i++) {
  //       allNodes[connectedNodes[i]].color = undefined;
  //       if (allNodes[connectedNodes[i]].hiddenLabel !== undefined) {
  //         allNodes[connectedNodes[i]].label = allNodes[connectedNodes[i]].hiddenLabel;
  //         allNodes[connectedNodes[i]].hiddenLabel = undefined;
  //       }
  //     }

  //     // the main node gets its own color and its label back.
  //     allNodes[selectedNode].color = undefined;
  //     if (allNodes[selectedNode].hiddenLabel !== undefined) {
  //       allNodes[selectedNode].label = allNodes[selectedNode].hiddenLabel;
  //       allNodes[selectedNode].hiddenLabel = undefined;
  //     }
  //   } else if (highlightActive === true) {
  //     // reset all nodes
  //     for (var nodeId in allNodes) {
  //       allNodes[nodeId].color = undefined;
  //       if (allNodes[nodeId].hiddenLabel !== undefined) {
  //         allNodes[nodeId].label = allNodes[nodeId].hiddenLabel;
  //         allNodes[nodeId].hiddenLabel = undefined;
  //       }
  //     }
  //     highlightActive = false;
  //   }

  //   // transform the object into an array
  //   var updateArray = [];
  //   for (nodeId in allNodes) {
  //     if (allNodes.hasOwnProperty(nodeId)) {
  //       updateArray.push(allNodes[nodeId]);
  //     }
  //   }
  //   // nodesDataset.update(updateArray);
  //   setGraphData((prev) => ({
  //     ...prev,
  //     nodes: updateArray,
  //   }));
  // }

  function resetNodeColorsAndLabels() {
    for (const nodeId in allNodes) {
      if (allNodes.hasOwnProperty(nodeId)) {
        allNodes[nodeId].color = undefined;
        if (allNodes[nodeId].hiddenLabel !== undefined) {
          allNodes[nodeId].label = allNodes[nodeId].hiddenLabel;
          allNodes[nodeId].hiddenLabel = undefined;
        }
      }
    }
  }

  function neighbourhoodHighlight(params) {
    const network = networkRef.current.Network;

    if (params.nodes.length > 0) {
      // highlightActive = true;
      var i, j;
      var selectedNode = params.nodes[0];
      // console.log('selectedNode', selectedNode);

      var degrees = 1;

      // mark all nodes as hard to read.
      for (var nodeId in allNodes) {

        if (allNodes[nodeId].hiddenColor === undefined) {
          allNodes[nodeId].hiddenColor = allNodes[nodeId].color;
        }
        allNodes[nodeId].color = 'rgba(200,200,200,0.1)';
        if (allNodes[nodeId].hiddenLabel === undefined) {
          allNodes[nodeId].hiddenLabel = allNodes[nodeId].label;
          allNodes[nodeId].label = undefined;
        }
      }
      var connectedNodes = network.getConnectedNodes(selectedNode);

      var allConnectedNodes = [];

      // get the second degree nodes
      // for (i = 1; i < degrees; i++) {
      //   for (j = 0; j < connectedNodes.length; j++) {
      //     allConnectedNodes = allConnectedNodes.concat(
      //       network.getConnectedNodes(connectedNodes[j])
      //     );
      //   }
      // }

      // all second degree nodes get a different color and their label back
      // for (i = 0; i < allConnectedNodes.length; i++) {
      //   allNodes[allConnectedNodes[i]].color = "rgba(150,150,150,0.75)";
      //   if (allNodes[allConnectedNodes[i]].hiddenLabel !== undefined) {
      //     allNodes[allConnectedNodes[i]].label =
      //       allNodes[allConnectedNodes[i]].hiddenLabel;
      //     allNodes[allConnectedNodes[i]].hiddenLabel = undefined;
      //   }
      // }

      // all first degree nodes get their own color and their label back
      for (i = 0; i < connectedNodes.length; i++) {
        if (allNodes[connectedNodes[i]]) {
          allNodes[connectedNodes[i]].color = allNodes[connectedNodes[i]].hiddenColor ?? undefined;
          if (allNodes[connectedNodes[i]].hiddenLabel !== undefined) {
            allNodes[connectedNodes[i]].label = allNodes[connectedNodes[i]].hiddenLabel;
            allNodes[connectedNodes[i]].hiddenLabel = undefined;
          }
        }
      }

      // the main node gets its own color and its label back.
      allNodes[selectedNode].color = allNodes[selectedNode].hiddenColor ?? undefined;
      if (allNodes[selectedNode].hiddenLabel !== undefined) {
        allNodes[selectedNode].label = allNodes[selectedNode].hiddenLabel;
        allNodes[selectedNode].hiddenLabel = undefined;
      }
      setHighlightActive(true);
    } else if (highlightActive === true) {
      // reset all nodes

      for (var nodeId in allNodes) {
        allNodes[nodeId].color = allNodes[nodeId].hiddenColor ?? undefined;
        if (allNodes[nodeId].hiddenLabel !== undefined) {
          allNodes[nodeId].label = allNodes[nodeId].hiddenLabel;
          allNodes[nodeId].hiddenLabel = undefined;
        }
      }
      setHighlightActive(false);
      // highlightActive = false;
    }

    // transform the object into an array
    var updateArray = [];
    for (nodeId in allNodes) {
      if (allNodes.hasOwnProperty(nodeId)) {
        updateArray.push(allNodes[nodeId]);
      }
    }
    // nodesDataset.update(updateArray);
    // network.redraw();
    setGraphData((prev) => ({
      ...prev,
      nodes: _.cloneDeep(updateArray),
      // nodes: [],
    }));
  }

  useEffect(() => {
    if (networkRef.current) {
      const network = networkRef.current.Network;

      // network.on('selectNode', (params) => {
      //   const nodeId = params.nodes[0];
      //   const connectedEdges = network.getConnectedEdges(nodeId);

      //   connectedEdges.forEach((edgeId) => {
      //     network.updateEdge(edgeId, { width: 15 }); // Increase width of the edge
      //   });
      // });
      // network.on('selectEdge', neighbourhoodHighlight);
      // network.on('click', neighbourhoodHighlight);
      // network.on('deselectNode', (params) => {
      //   const { edges } = network.body;

      //   Object.keys(edges).forEach((edgeId) => {
      //     network.updateEdge(edgeId, { width: 2 }); // Reset width of the edge
      //   });
      // });
      // You can now access the network object and call its methods
    }
  }, [graphData]);

  return (
    <Loader loading={getPPINetworkGraphResponse.loading || Object.keys(graphData).length === 0 || graphParsing}>
      <div className="ctp-content-lower card-content-ctp card-content-ctp-graph" style={{ width: '100%', height: '800px' }}>
        <div className="card-content-ctp-title">PPI Network Graph and 1st - 2nd Degree Associations</div>
        {graphData ? (
          <>
            <div className="pathway-enrichment-graph-header">
              <div className="pathway-enrichment-graph-legend-icon">
                <XFilled style={{ color: '#CBA5E9', fontSize: '120%' }} />
              </div>
              <div className="pathway-enrichment-graph-legend-name" style={{ paddingLeft: '5px', paddingRight: '50px' }}>
                Molecular functions Protein
              </div>
              <div className="pathway-enrichment-graph-legend-icon">
                <XFilled style={{ color: '#EDC962', fontSize: '120%' }} />
              </div>
              <div className="pathway-enrichment-graph-legend-name" style={{ paddingLeft: '5px', paddingRight: '50px' }}>
                Disease Similarity Protein
              </div>
              <div className="pathway-enrichment-graph-legend-icon">
                <XFilled style={{ color: '#5FD6E7', fontSize: '120%' }} />
              </div>
              <div className="pathway-enrichment-graph-legend-name" style={{ paddingLeft: '5px', paddingRight: '50px' }}>
                PPIs Protein
              </div>
              <div className="pathway-enrichment-graph-legend-icon">
                <XFilled style={{ color: '#85D38D', fontSize: '120%' }} />
              </div>
              <div className="pathway-enrichment-graph-legend-name" style={{ paddingLeft: '5px', paddingRight: '50px' }}>
                Literature Mining Protein
              </div>
              <div className="pathway-enrichment-graph-legend-icon">
                <XFilled style={{ color: '#D79B97', fontSize: '120%' }} />
              </div>
              <div className="pathway-enrichment-graph-legend-name" style={{ paddingLeft: '5px' }}>
                Common Protein
              </div>
            </div>
            <Graph ref={networkRef} graph={graphData} options={options} events={{ select: neighbourhoodHighlight }} />
          </>
        ) : (
          <Loader loading={graphParsing} />
        )}
      </div>
    </Loader>
  );
}

PPINetwotkGraph.propTypes = {
  jobId: PropTypes.string.isRequired,
};

export default PPINetwotkGraph;

// import React, { useEffect, useRef, useState } from 'react';
// import { DataSet, Network } from 'vis-network/standalone/esm/vis-network';
// import { nodes , edges} from './WorldCup2014';

// // import 'vis-network/styles/vis-network.css'; // Optional: Include default styles for the network

// // function PPINetworkGraph() {
// //   const networkRef = useRef(null); // To reference the network container
// //   const networkInstance = useRef(null); // To store the Network instance
// //   const [allNodes, setAllNodes] = useState(null); // To store nodes state
// //   const [highlightActive, setHighlightActive] = useState(false); // Track if highlight is active
// //   const nodesDataset = useRef(null); // To hold DataSet for nodes
// //   const edgesDataset = useRef(null); // To hold DataSet for edges

// //   useEffect(() => {
// //     // Initialize datasets
// //     nodesDataset.current = new DataSet(nodes);
// //     edgesDataset.current = new DataSet(edges);

// //     const container = networkRef.current;
// //     const data = { nodes: nodesDataset.current, edges: edgesDataset.current };

// //     const options = {
// //       nodes: {
// //         shape: 'dot',
// //         scaling: {
// //           min: 10,
// //           max: 30,
// //           label: {
// //             min: 8,
// //             max: 30,
// //             drawThreshold: 12,
// //             maxVisible: 20,
// //           },
// //         },
// //         font: {
// //           size: 12,
// //           face: 'Tahoma',
// //         },
// //       },
// //       edges: {
// //         width: 0.15,
// //         color: { inherit: 'from' },
// //         smooth: {
// //           type: 'continuous',
// //         },
// //       },
// //       physics: false,
// //       interaction: {
// //         tooltipDelay: 200,
// //         hideEdgesOnDrag: true,
// //         hideEdgesOnZoom: true,
// //       },
// //     };

// //     // Initialize the network instance
// //     networkInstance.current = new Network(container, data, options);

// //     // Get all nodes in an object form and store in state
// //     const allNodesObj = nodesDataset.current.get({ returnType: 'Object' });
// //     setAllNodes(allNodesObj);

// //     // Add click event listener to the network
// //     networkInstance.current.on('click', neighbourhoodHighlight);

// //     // Cleanup the effect on component unmount
// //     return () => {
// //       networkInstance.current.off('click', neighbourhoodHighlight);
// //     };
// //   }, [nodes, edges]); // Run only once when component mounts

// //   // Function to handle the neighborhood highlighting
// //   function neighbourhoodHighlight(params) {
// //     if (!allNodes) return; // Guard clause in case nodes haven't been set yet

// //     if (params.nodes.length > 0) {
// //       setHighlightActive(true);
// //       const selectedNode = params.nodes[0];
// //       const connectedNodes = networkInstance.current.getConnectedNodes(selectedNode);
// //       let allConnectedNodes = [];

// //       // Mark all nodes as hard to read
// //       const updatedNodes = { ...allNodes };
// //       for (const nodeId in updatedNodes) {
// //         updatedNodes[nodeId].color = 'rgba(200,200,200,0.5)';
// //         if (updatedNodes[nodeId].hiddenLabel === undefined) {
// //           updatedNodes[nodeId].hiddenLabel = updatedNodes[nodeId].label;
// //           updatedNodes[nodeId].label = undefined;
// //         }
// //       }

// //       // Get second-degree nodes
// //       for (let i = 1; i < 2; i++) {
// //         for (let j = 0; j < connectedNodes.length; j++) {
// //           allConnectedNodes = allConnectedNodes.concat(
// //             networkInstance.current.getConnectedNodes(connectedNodes[j])
// //           );
// //         }
// //       }

// //       // Highlight second-degree nodes
// //       allConnectedNodes.forEach((nodeId) => {
// //         if (updatedNodes[nodeId]) {
// //           updatedNodes[nodeId].color = 'rgba(150,150,150,0.75)';
// //           if (updatedNodes[nodeId].hiddenLabel !== undefined) {
// //             updatedNodes[nodeId].label = updatedNodes[nodeId].hiddenLabel;
// //             updatedNodes[nodeId].hiddenLabel = undefined;
// //           }
// //         }
// //       });

// //       // Highlight first-degree nodes
// //       connectedNodes.forEach((nodeId) => {
// //         updatedNodes[nodeId].color = undefined;
// //         if (updatedNodes[nodeId].hiddenLabel !== undefined) {
// //           updatedNodes[nodeId].label = updatedNodes[nodeId].hiddenLabel;
// //           updatedNodes[nodeId].hiddenLabel = undefined;
// //         }
// //       });

// //       // Highlight the selected node
// //       updatedNodes[selectedNode].color = undefined;
// //       if (updatedNodes[selectedNode].hiddenLabel !== undefined) {
// //         updatedNodes[selectedNode].label = updatedNodes[selectedNode].hiddenLabel;
// //         updatedNodes[selectedNode].hiddenLabel = undefined;
// //       }

// //       // Convert updatedNodes object into an array and update the dataset
// //       nodesDataset.current.update(Object.values(updatedNodes));
// //     } else if (highlightActive) {
// //       // Reset node highlights if no node is selected
// //       const updatedNodes = { ...allNodes };
// //       for (const nodeId in updatedNodes) {
// //         updatedNodes[nodeId].color = undefined;
// //         if (updatedNodes[nodeId].hiddenLabel !== undefined) {
// //           updatedNodes[nodeId].label = updatedNodes[nodeId].hiddenLabel;
// //           updatedNodes[nodeId].hiddenLabel = undefined;
// //         }
// //       }
// //       nodesDataset.current.update(Object.values(updatedNodes));
// //       setHighlightActive(false);
// //     }
// //   }

// //   return (
// //     <div>
// //       <h3>PPI Network Graph</h3>
// //       <div
// //         ref={networkRef}
// //         style={{ width: '100%', height: '600px', border: '1px solid black' }}
// //       ></div>
// //     </div>
// //   );
// // }

// function PPINetworkGraph() {
//   const networkRef = useRef(null); // To reference the network container
//   const networkInstance = useRef(null); // To store the Network instance
//   const [allNodes, setAllNodes] = useState(null); // To store nodes state
//   const [highlightActive, setHighlightActive] = useState(false); // Track if highlight is active
//   const nodesDataset = useRef(null); // To hold DataSet for nodes
//   const edgesDataset = useRef(null);
//   const [rerun,setRerun] = useState(0); // To hold DataSet for edges

//   useEffect(() => {
//     // Ensure that the container exists before initializing the network
//     if (networkRef.current) {
//       // Initialize datasets
//       if(!nodesDataset.current)
//       nodesDataset.current = new DataSet(nodes);

//       if(!edgesDataset.current)
//       edgesDataset.current = new DataSet(edges);

//       const container = networkRef.current;
//       const data = { nodes: nodesDataset.current, edges: edgesDataset.current };

//       const options = {
//         nodes: {
//           shape: 'dot',
//           scaling: {
//             min: 10,
//             max: 30,
//             label: {
//               min: 8,
//               max: 30,
//               drawThreshold: 12,
//               maxVisible: 20,
//             },
//           },
//           font: {
//             size: 12,
//             face: 'Tahoma',
//           },
//         },
//         edges: {
//           width: 0.15,
//           color: { inherit: 'from' },
//           smooth: {
//             type: 'continuous',
//           },
//         },
//         physics: false,
//         interaction: {
//           tooltipDelay: 200,
//           hideEdgesOnDrag: true,
//           hideEdgesOnZoom: true,
//         },
//       };

//       // Initialize the network instance
//       networkInstance.current = new Network(container, data, options);

//       // Get all nodes in an object form and store in state
//       const allNodesObj = nodesDataset.current.get({ returnType: 'Object' });
//       setAllNodes(allNodesObj);

//       // Add click event listener to the network
//       networkInstance.current.on('click', neighbourhoodHighlight);

//       // Cleanup the effect on component unmount
//       return () => {
//         networkInstance.current.off('click', neighbourhoodHighlight);
//       };
//     }
//   }, [nodesDataset, edgesDataset, rerun]); // Run only once when component mounts

//   // Function to handle the neighborhood highlighting
//   function neighbourhoodHighlight(params) {
//     if (!allNodes) return; // Guard clause in case nodes haven't been set yet

//     let updatedNodes = { ...allNodes };

//     if (params.nodes.length > 0) {
//       setHighlightActive(true);
//       const selectedNode = params.nodes[0];
//       const connectedNodes = networkInstance.current.getConnectedNodes(selectedNode);
//       let allConnectedNodes = [];

//       // Mark all nodes as hard to read
//       for (const nodeId in updatedNodes) {
//         updatedNodes[nodeId].color = 'rgba(200,200,200,0.5)';
//         if (updatedNodes[nodeId].hiddenLabel === undefined) {
//           updatedNodes[nodeId].hiddenLabel = updatedNodes[nodeId].label;
//           updatedNodes[nodeId].label = undefined;
//         }
//       }

//       // Get second-degree nodes
//       for (let i = 1; i < 2; i++) {
//         for (let j = 0; j < connectedNodes.length; j++) {
//           allConnectedNodes = allConnectedNodes.concat(
//             networkInstance.current.getConnectedNodes(connectedNodes[j])
//           );
//         }
//       }

//       // Highlight second-degree nodes
//       allConnectedNodes.forEach((nodeId) => {
//         if (updatedNodes[nodeId]) {
//           updatedNodes[nodeId].color = 'rgba(150,150,150,0.75)';
//           if (updatedNodes[nodeId].hiddenLabel !== undefined) {
//             updatedNodes[nodeId].label = updatedNodes[nodeId].hiddenLabel;
//             updatedNodes[nodeId].hiddenLabel = undefined;
//           }
//         }
//       });

//       // Highlight first-degree nodes
//       connectedNodes.forEach((nodeId) => {
//         updatedNodes[nodeId].color = undefined;
//         if (updatedNodes[nodeId].hiddenLabel !== undefined) {
//           updatedNodes[nodeId].label = updatedNodes[nodeId].hiddenLabel;
//           updatedNodes[nodeId].hiddenLabel = undefined;
//         }
//       });

//       // Highlight the selected node
//       updatedNodes[selectedNode].color = undefined;
//       if (updatedNodes[selectedNode].hiddenLabel !== undefined) {
//         updatedNodes[selectedNode].label = updatedNodes[selectedNode].hiddenLabel;
//         updatedNodes[selectedNode].hiddenLabel = undefined;
//       }

//       // Update the nodes and dataset
//       nodesDataset.current.update(Object.values(updatedNodes));
//       setAllNodes(updatedNodes); // Update state to trigger re-render if needed
//     } else if (highlightActive) {
//       // Reset node highlights if no node is selected
//       for (const nodeId in updatedNodes) {
//         updatedNodes[nodeId].color = undefined;
//         if (updatedNodes[nodeId].hiddenLabel !== undefined) {
//           updatedNodes[nodeId].label = updatedNodes[nodeId].hiddenLabel;
//           updatedNodes[nodeId].hiddenLabel = undefined;
//         }
//       }
//       nodesDataset.current.update(Object.values(updatedNodes));
//       setAllNodes(updatedNodes); // Update state to reflect the reset
//       setHighlightActive(false);
//       setRerun((prev) => prev + 1);
//     }
//   }

//   return (
//     <div>
//       {console.log('first')}
//       <h3>PPI Network Graph</h3>
//       <div
//         ref={networkRef}
//         id={Math.random()}
//         style={{ width: '100%', height: '600px', border: '1px solid black' }}
//       ></div>
//     </div>
//   );
// }

// export default PPINetworkGraph;
