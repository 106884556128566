import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import InputCard from '../../components/InputCard';

function SelectService({ inputHandler, service, resetInputs }) {
  const [card, setCard] = useState([-1, -1]);
  const services = ['Prioritize Indications', 'CCA'];

  useEffect(() => {
    services.forEach((item, index) => {
      if (item === service) {
        setCard((prev) => {
          const newCard = prev;
          newCard[index] = 1;
          return newCard;
        });
        inputHandler({ card: services[index] });
      }
    });
  }, []);

  const handleCard = (index) => {
    setCard((prev) => {
      const newCard = prev.map((item, i) => {
        if (index === i) {
          return 1;
        }
        return 0;
      });
      return newCard;
    });
    resetInputs();
    inputHandler({ card: services[index] });
  };
  return (
    <div className="service-container service-container-dr">
      <div className="cards-container">
        <div className="cards-container-title">Select a Service</div>
        <div className="cards-list">
          <InputCard index={0} heading=" Prioritize Top Indications" cardHandler={handleCard} isSelected={card[0]} colorClass="card-one" img="prioritize" status="Open" />
          <InputCard index={1} heading=" Clinical & Commercial Analysis" cardHandler={handleCard} isSelected={card[1]} colorClass="card-three" img="analysis" status="Open" />
          {/* <InputCard index={2} heading=" Optimise Trials" cardHandler={handleCard} isSelected={card[2]} colorClass="card-three" /> */}
        </div>
      </div>
    </div>
  );
}

SelectService.propTypes = {
  inputHandler: PropTypes.func.isRequired,
  service: PropTypes.string.isRequired,
  resetInputs: PropTypes.func.isRequired,
};

export default SelectService;
