import {
  Button, Flex, Input, Modal, Popover, Radio, Slider, Space, Tag,
} from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import InputDropdown from '../../components/InputDropdown';
import Mixpanel from '../../../../utils/mixpanel';
import useAppSelector from '../../../../utils/redux-hook';
import { getSynonymsAction, iepAdvanceSearchCheckStatusAction } from './logic';
import Loader from '../../../../common/components/Loader';
// import { generateIndicationsAction } from '../../pages/IEPInputPage/logic';
import SaveProjectModal from '../SaveProjectModal';

function AdvanceSearchView({
  isOpen, setIsAdvanceSearch, serviceName, isAdvanceSearch,
}) {
  const [protein, setProtein] = useState('');
  const [proteinItem, setProteinItem] = useState({});
  const [proteinList, setProteinList] = useState([]);
  const [defaultProteinList, setDefaultProteinList] = useState([]);
  const [isOpenAddSynonym, setIsOpenAddSynonym] = useState(false);
  const [newSynonym, setNewSynonym] = useState('');
  const [networkApproachScore, setNetworkApproachScore] = useState(60);
  const [literatureMiningScore, setLiteratureMiningScore] = useState(20);
  const [clinicalTrialScore, setClinicalTrialScore] = useState(20);
  const [isOpenEditDetails, setIsOpenEditDetails] = useState(false);
  const [totalWeightage, setTotalWeightage] = useState(100);
  const [proteinsInteractionWeightage, setProteinsInteractionWeightage] = useState(networkApproachScore / 4);
  const [literatureMiningWeightage, setLiteratureMiningWeightage] = useState(networkApproachScore / 4);
  const [diseaseSimilarityWeightage, setDiseaseSimilarityWeightage] = useState(networkApproachScore / 4);
  const [molecularFunctionSimilarityWeightage, setMolecularFunctionSimilarityWeightage] = useState(networkApproachScore / 4);
  const [tempProteinsInteractionWeightage, setTempProteinsInteractionWeightage] = useState(networkApproachScore / 4);
  const [tempLiteratureMiningWeightage, setTempLiteratureMiningWeightage] = useState(networkApproachScore / 4);
  const [tempDiseaseSimilarityWeightage, setTempDiseaseSimilarityWeightage] = useState(networkApproachScore / 4);
  const [tempMolecularFunctionSimilarityWeightage, setTempMolecularFunctionSimilarityWeightage] = useState(networkApproachScore / 4);
  const getSynonymsResponse = useAppSelector((state) => state.getSynonymsResponse);
  const [addedSynonyms, setAddedSynonyms] = useState([]);
  const [removedSynonyms, setRemovedSynonyms] = useState([]);
  const dispatch = useDispatch();
  const indicationsOptions = [
    {
      label: 'All applicable indications',
      value: 'all_applicable_indications',
    },
    {
      label: 'Only rare indications',
      value: 'only_rare_indications',
    },
  ];
  const [indication, setIndication] = useState(indicationsOptions[0].value);
  const [requestBody, setRequestBody] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const marks = (key1, value1) => ({
    0: '0',
    [key1]: value1,
  });

  useEffect(() => {
    dispatch(iepAdvanceSearchCheckStatusAction());
    if (protein) {
      // console.log('Protein ----> ', protein);
    }
  }, []);

  useEffect(() => {
    if (getSynonymsResponse) {
      setProteinList(getSynonymsResponse?.data[0]?.uniqueSynonymsTerms);
      setDefaultProteinList(getSynonymsResponse?.data[0]?.uniqueSynonymsTerms);
    }
  }, [JSON.stringify(getSynonymsResponse)]);

  useEffect(() => {
    setProteinsInteractionWeightage(networkApproachScore / 4);
    setLiteratureMiningWeightage(networkApproachScore / 4);
    setDiseaseSimilarityWeightage(networkApproachScore / 4);
    setMolecularFunctionSimilarityWeightage(networkApproachScore / 4);
    setTempProteinsInteractionWeightage(networkApproachScore / 4);
    setTempLiteratureMiningWeightage(networkApproachScore / 4);
    setTempDiseaseSimilarityWeightage(networkApproachScore / 4);
    setTempMolecularFunctionSimilarityWeightage(networkApproachScore / 4);
  }, [networkApproachScore]);

  const networkApproachChild = (title, value, updateValue) => (
    <div className="network-approach-child">
      <div className="network-approach-child-card">
        <div className="title-row" style={{ display: 'flex' }}>
          <div className="title">{title}</div>
        </div>
        <div className="approach-slider">
          <Slider
            defaultValue={value}
            value={value}
            marks={marks(networkApproachScore, networkApproachScore)}
            tooltip={{ placement: 'bottom' }}
            onChange={(e) => {
              updateValue(e);
            }}
            min={0}
            max={networkApproachScore}
          />
        </div>
      </div>
    </div>
  );
  const handleClose = (removedTag) => {
    setRemovedSynonyms([...removedSynonyms, removedTag]);
    setProteinList(proteinList.filter((item) => item !== removedTag));
  };
  const addSynonym = (item) => {
    if (!addedSynonyms.includes(item)) {
      setAddedSynonyms([item, ...addedSynonyms]);
      setProteinList([item, ...proteinList]);
    }
    setIsOpenAddSynonym(!isOpenAddSynonym);
    setNewSynonym('');
    Mixpanel.track('IEP Add Synonym IEP Advance Search', {
      action: 'IEP Synonym added', name: item, service: 'Prioritize Top Indications', module: 'IEP',
    });
  };

  const addSynonymDropdown = () => (
    <div className="add-protein-dropdown-menu">
      <div className="searchbar-dashboard-content-search">
        <Input
          onChange={(e) => {
            setNewSynonym(e.target.value);
          }}
          value={newSynonym}
          placeholder="Enter synonym here"
        />
      </div>
      <div className="add-synonym-dropdown-menu-footer add-protein-dropdown-menu-footer">
        <Button className="clear-btn" onClick={() => setNewSynonym('')} disabled={newSynonym === ''}>
          Clear
        </Button>
        <Button className="add-btn" onClick={() => addSynonym(newSynonym)} disabled={newSynonym === ''}>
          Add synonym
        </Button>
      </div>
    </div>
  );

  const onCloseModal = () => {
    setIsOpenAddSynonym(false);
    setProteinItem({});
    setProtein('');
    setProteinList([]);
    setAddedSynonyms([]);
    setRemovedSynonyms([]);
    setNetworkApproachScore(60);
    setLiteratureMiningScore(20);
    setClinicalTrialScore(20);
    setIsAdvanceSearch(false);
    Mixpanel.track('IEP Advance Search Modal Closed', {
      action: 'IEP Advance Search Cancelled',
      service: 'Prioritize Top Indications',
      module: 'IEP',
    });
  };

  const onUpdate = () => {
    setProteinsInteractionWeightage(tempProteinsInteractionWeightage);
    setLiteratureMiningWeightage(tempLiteratureMiningWeightage);
    setDiseaseSimilarityWeightage(tempDiseaseSimilarityWeightage);
    setMolecularFunctionSimilarityWeightage(tempMolecularFunctionSimilarityWeightage);
    setIsOpenEditDetails(false);
    Mixpanel.track('IEP Network Approaches Updated', {
      action: 'IEP Network Approaches Updated',
      service: 'Prioritize Top Indications',
      module: 'IEP',
      ppi_appraoch: tempProteinsInteractionWeightage,
      lm_appraoch: tempLiteratureMiningWeightage,
      ds_appraoch: tempDiseaseSimilarityWeightage,
      mfs_appraoch: tempMolecularFunctionSimilarityWeightage,
    });
  };

  const onResetToDefault = () => {
    setTotalWeightage(100);
    setNetworkApproachScore(60);
    setLiteratureMiningScore(20);
    setClinicalTrialScore(20);
    setTempDiseaseSimilarityWeightage(15);
    setTempMolecularFunctionSimilarityWeightage(15);
    setTempProteinsInteractionWeightage(15);
    setTempLiteratureMiningWeightage(15);
    setProteinList(defaultProteinList);
    setAddedSynonyms([]);
    setRemovedSynonyms([]);
    Mixpanel.track('IEP Advance Search Reset', {
      action: 'IEP Reset to default button clicked',
      service: 'Prioritize Top Indications',
      module: 'IEP',
    });
  };

  const generateIndications = () => {
    setIsAdvanceSearch(false);
    const updatedAddedSynonyms = addedSynonyms.filter((item) => !removedSynonyms.includes(item));
    const updatedRemovedSynonyms = removedSynonyms.filter((item) => !addedSynonyms.includes(item));
    const request = {
      params: {
        service: serviceName,
        protein_name: proteinItem.gene_name,
      },
      body: {
        synonyms_added: updatedAddedSynonyms,
        synonyms_removed: updatedRemovedSynonyms,
        synonyms_net: proteinList,
        approach_weightage: {
          literature_mining: literatureMiningScore,
          clinical_trial: clinicalTrialScore,
          network_disease_similarity: diseaseSimilarityWeightage,
          network_ppi: proteinsInteractionWeightage,
          network_molecular_function: molecularFunctionSimilarityWeightage,
          network_literature_mining: literatureMiningWeightage,
        },
        rare_indication_flag: indication === 'only_rare_indications',
      },
    };
    setRequestBody(request);
    setProtein('');
    setProteinList([]);
    setAddedSynonyms([]);
    setRemovedSynonyms([]);
    setNetworkApproachScore(60);
    setLiteratureMiningScore(20);
    setClinicalTrialScore(20);
    setIsSubmitted(true);
    Mixpanel.track('IEP Generate Indications clicked', {
      action: 'IEP Generate Indications button clicked',
      service: 'Prioritize Top Indications',
      module: 'IEP',
      request_body: request,
    });
  };
  const updateProtein = (item) => {
    setProtein(item);
  };
  const updateProteinItem = (item) => {
    if (item !== '') {
      const request = {
        uid: item.uid,
      };
      dispatch(getSynonymsAction(queryString.stringify(request)));
    }
    setProteinItem(item);
  };
  return (
    <>
      <Modal open={isOpen} footer={false} onCancel={() => onCloseModal()} closable className="advance-search-modal">
        <div className="advance-search-container">
          <div className="advance-search-title">Provide input for prioritizing top indications</div>
          <div className="advance-search-input">
            <div className="input-container ">
              <div className="input-fields">
                <div className="dropdown-container">
                  <InputDropdown index={0} changeHandler={updateProtein} proteinName={proteinItem.gene_name} isDisabled={false} setProteinName={() => {}} setProteinItem={updateProteinItem} />
                  <div className="input-fields-value try-example-section">
                    <text className="try-example-section-title">Try Example: </text>
                    <text
                      style={{ cursor: 'pointer', color: 'blue' }}
                      onClick={() => {
                        Mixpanel.track('IEP try example clicked', {
                          action: 'IEP Try example from Advance Search clicked',
                          selected_protein: 'DLL3',
                          service: 'Prioritize Top Indications',
                        });
                        setProtein('DLL3');
                        updateProteinItem({ gene_name: 'DLL3', uid: 'PROT_ID_9606_a39ee0dbc72871f2d3c39685fb730753b47045f1' });
                      }}
                    >
                      DLL3
                    </text>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="advance-search-synonyms">
            <div className="advance-search-synonyms-title-row">
              <div className="advance-search-synonyms-title">Synonyms</div>
              <div className="add-btn-sec">
                <Popover overlayClassName="add-dropdown-popover" open={isOpenAddSynonym && isAdvanceSearch} placement="bottomRight" content={addSynonymDropdown()}>
                  <div className="add-dropdown">
                    <div
                      className="add-btn"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        Mixpanel.track('IEP Add Synonym clicked', {
                          action: 'IEP add synonym dropdown click',
                        });
                        setIsOpenAddSynonym(!isOpenAddSynonym);
                      }}
                      aria-hidden
                    >
                      Add
                    </div>
                  </div>
                </Popover>
              </div>
            </div>
            <Loader loading={getSynonymsResponse.loading}>
              {proteinList?.length > 0 ? (
                <div className="tags-group">
                  <Flex gap="4px 0" wrap="wrap">
                    {proteinList
                      .filter((item) => !removedSynonyms.includes(item))
                      ?.map((item) => (
                        <Tag key={item} className="sample-tags" closable onClose={() => handleClose(item)}>
                          {item}
                        </Tag>
                      ))}
                  </Flex>
                </div>
              ) : (
                <div className="advance-search-synonyms-content">Synonyms of the input term will be generated here....</div>
              )}
            </Loader>
          </div>
          <div className="advance-search-approach-weightage">
            <div className="advance-search-approach-weightage-title-row" style={{ display: 'flex' }}>
              <div className="advance-search-approach-weightage-title">Approach Weightage</div>
              <div className={`advance-search-total-weightage${totalWeightage !== 100 ? ' warning' : ''}`} style={{ display: 'flex' }}>
                <div className="advance-search-total-weightage-key">Total Weightage:</div>
                <div className={`advance-search-total-weightage-value${totalWeightage !== 100 ? ' warning' : ''}`}>{`${totalWeightage}%`}</div>
              </div>
              {totalWeightage !== 100 && (
                <div className="weightage-warning">
                  <div className="warning-icon" />
                  <div className="warning-text">Total weightage should be 100%</div>
                </div>
              )}
            </div>
            <div className="advance-search-approaches" style={{ display: 'flex' }}>
              <div className={`individual-approach${isOpenEditDetails ? ' active' : ''}`}>
                <div className="individual-approach-card">
                  <div className="title-row" style={{ display: 'flex' }}>
                    <div className="title">1. Network Approach</div>
                    <div className="weightage" style={{ display: 'flex' }}>
                      <div className="weightage-value">{`${networkApproachScore}%`}</div>
                      <div className="weightage-key">Weightage</div>
                    </div>
                  </div>
                  <div className="approach-slider">
                    <Slider
                      defaultValue={networkApproachScore}
                      value={networkApproachScore}
                      onChange={(e) => {
                        setNetworkApproachScore(e);
                        setTotalWeightage(e + literatureMiningScore + clinicalTrialScore);
                      }}
                      min={0}
                      max={100}
                    />
                  </div>
                  <div className="edit-details-btn" onClick={() => setIsOpenEditDetails(true)} aria-hidden>
                    Edit Details
                  </div>
                </div>
              </div>
              <div className={`individual-approach${isOpenEditDetails ? ' disabled' : ''}`}>
                <div className="individual-approach-card">
                  <div className="title-row" style={{ display: 'flex' }}>
                    <div className="title">2. Literature Mining</div>
                    <div className="weightage" style={{ display: 'flex' }}>
                      <div className="weightage-value">{`${literatureMiningScore}%`}</div>
                      <div className="weightage-key">Weightage</div>
                    </div>
                  </div>
                  <div className="approach-slider">
                    <Slider
                      defaultValue={literatureMiningScore}
                      value={literatureMiningScore}
                      onChange={(e) => {
                        setLiteratureMiningScore(e);
                        setTotalWeightage(e + networkApproachScore + clinicalTrialScore);
                      }}
                      min={0}
                      max={100}
                    />
                  </div>
                </div>
              </div>
              <div className={`individual-approach${isOpenEditDetails ? ' disabled' : ''}`}>
                <div className="individual-approach-card">
                  <div className="title-row" style={{ display: 'flex' }}>
                    <div className="title">3. Clinical Trial</div>
                    <div className="weightage" style={{ display: 'flex' }}>
                      <div className="weightage-value">{`${clinicalTrialScore}%`}</div>
                      <div className="weightage-key">Weightage</div>
                    </div>
                  </div>
                  <div className="approach-slider">
                    <Slider
                      defaultValue={clinicalTrialScore}
                      value={clinicalTrialScore}
                      onChange={(e) => {
                        setClinicalTrialScore(e);
                        setTotalWeightage(e + literatureMiningScore + networkApproachScore);
                      }}
                      min={0}
                      max={100}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={`individual-approach-contents${isOpenEditDetails ? ' open' : ''}`}>
              <div className="network-approach-content-row" style={{ display: 'flex' }}>
                {networkApproachChild('i. Protein-Protein Interactions', tempProteinsInteractionWeightage, setTempProteinsInteractionWeightage)}
                {networkApproachChild('ii. Literature Mining', tempLiteratureMiningWeightage, setTempLiteratureMiningWeightage)}
                {networkApproachChild('iii. Disease Similarity', tempDiseaseSimilarityWeightage, setTempDiseaseSimilarityWeightage)}
                {networkApproachChild('iv. Molecular Function Similarity', tempMolecularFunctionSimilarityWeightage, setTempMolecularFunctionSimilarityWeightage)}
              </div>
              <div className="update-btn-row">
                <div className="update-btn-row-content">
                  <div className="total-weightage-value">
                    {'Total sum of all four network approach: '}
                    <span className={`total-weightage-percent${tempProteinsInteractionWeightage + tempLiteratureMiningWeightage + tempDiseaseSimilarityWeightage + tempMolecularFunctionSimilarityWeightage !== networkApproachScore ? ' warning' : ''}`}>{`${tempProteinsInteractionWeightage + tempLiteratureMiningWeightage + tempDiseaseSimilarityWeightage + tempMolecularFunctionSimilarityWeightage}%`}</span>
                  </div>
                  {tempProteinsInteractionWeightage + tempLiteratureMiningWeightage + tempDiseaseSimilarityWeightage + tempMolecularFunctionSimilarityWeightage !== networkApproachScore && (
                    <div className="weightage-warning">
                      <div className="warning-icon" />
                      <div className="warning-text">{`Total weightage should be ${networkApproachScore}%`}</div>
                    </div>
                  )}
                </div>
                <Button className="update-btn" onClick={() => onUpdate()} disabled={tempProteinsInteractionWeightage + tempLiteratureMiningWeightage + tempDiseaseSimilarityWeightage + tempMolecularFunctionSimilarityWeightage !== networkApproachScore}>
                  Update
                </Button>
              </div>
            </div>
          </div>
          <div className="advance-search-indications">
            <div className="advance-search-indications-title">Indications</div>
            <div className="advance-search-indications-selection">
              <Radio.Group
                value={indication}
                onChange={(e) => {
                  setIndication(e.target.value); Mixpanel.track('IEP Indication Selection Updated', {
                    action: 'IEP Indication Selection Updated', updated_indication: e.target.value, service: 'Prioritize Top Indications', module: 'IEP',
                  });
                }}
              >
                <Space>
                  {indicationsOptions?.map((item) => (
                    <Radio key={item.value} value={item.value}>
                      {item.label}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            </div>
          </div>
          <div className="advance-search-submit">
            <div className="reset-to-default-btn" onClick={() => onResetToDefault()} aria-hidden>
              Reset to Default
            </div>
            <Button disabled={totalWeightage !== 100 || !proteinList || proteinList.length === 0 || proteinItem.gene_name === undefined} onClick={() => generateIndications()}>
              Generate Indications
            </Button>
          </div>
        </div>
      </Modal>
      {isSubmitted && <SaveProjectModal name={proteinItem.gene_name} open={isSubmitted} setOpen={setIsSubmitted} requestBody={requestBody} />}
    </>
  );
}

AdvanceSearchView.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsAdvanceSearch: PropTypes.func.isRequired,
  serviceName: PropTypes.string.isRequired,
  isAdvanceSearch: PropTypes.bool.isRequired,
};
export default AdvanceSearchView;
