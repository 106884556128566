import { useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import { checkPermission } from '../../../utils';
import Mixpanel from '../../../utils/mixpanel';

function AccessPage() {
  const navigate = useNavigate();

  useEffect(() => {
    if (checkPermission('metad3_app_access')) {
      Mixpanel.track('Accessed Permissions', {
        action: 'access page',
        view: 'You do not have access',
      });
      navigate('/', { replace: true });
      navigate(0);
    }
  }, []);

  return (
    <div className="landing-page">
      You do not have access
    </div>
  );
}

export default AccessPage;
