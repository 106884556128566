import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../utils';

// my-workspace
const CTP_WORKSPACE_STATUS = 'CTP_WORKSPACE_STATUS';
const CTP_WORKSPACE_STATUS_SUCCESS = 'CTP_WORKSPACE_STATUS_SUCCESS';
const CTP_WORKSPACE_STATUS_FAILURE = 'CTP_WORKSPACE_STATUS_FAILURE';
const CTP_WORKSPACE_STATUS_RESET = 'CTP_WORKSPACE_STATUS_RESET';

export const ctpWorkspaceStatusAction = createAction(CTP_WORKSPACE_STATUS);
export const ctpWorkspaceStatusResetAction = createAction(CTP_WORKSPACE_STATUS_RESET);

const ctpWorkspaceStatusSuccess = createAction(CTP_WORKSPACE_STATUS_SUCCESS);
const ctpWorkspaceStatusFailure = createAction(CTP_WORKSPACE_STATUS_FAILURE);

export const ctpWorkspaceStatusEpic = (actions$) => actions$.pipe(
  ofType(CTP_WORKSPACE_STATUS),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/v1/d3_modules/searchJob?${action.payload}`, 'get')).pipe(
    map((res) => ctpWorkspaceStatusSuccess(res)),
    catchError((err) => of(ctpWorkspaceStatusFailure(err))),
  )),
);

export const ctpWorkspaceStatusReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(ctpWorkspaceStatusAction, (state) => {
      state.loading = true;
    })
      .addCase(ctpWorkspaceStatusSuccess, (state, action) => {
        state.data = [...action.payload.response.data];
        state.loading = false;
        state.flag = true;
      })
      .addCase(ctpWorkspaceStatusFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(ctpWorkspaceStatusResetAction, (state) => {
        state.data = [];
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);

// update-workspace
const CTP_UPDATE_WORKSPACE_STATUS = 'CTP_UPDATE_WORKSPACE_STATUS';
const CTP_UPDATE_WORKSPACE_STATUS_SUCCESS = 'CTP_UPDATE_WORKSPACE_STATUS_SUCCESS';
const CTP_UPDATE_WORKSPACE_STATUS_FAILURE = 'CTP_UPDATE_WORKSPACE_STATUS_FAILURE';
const CTP_UPDATE_WORKSPACE_STATUS_RESET = 'CTP_UPDATE_WORKSPACE_STATUS_RESET';

export const ctpUpdateWorkspaceStatusAction = createAction(CTP_UPDATE_WORKSPACE_STATUS);
export const ctpUpdateWorkspaceStatusResetAction = createAction(CTP_UPDATE_WORKSPACE_STATUS_RESET);

const ctpUpdateWorkspaceStatusSuccess = createAction(CTP_UPDATE_WORKSPACE_STATUS_SUCCESS);
const ctpUpdateWorkspaceStatusFailure = createAction(CTP_UPDATE_WORKSPACE_STATUS_FAILURE);

export const ctpUpdateWorkspaceStatusEpic = (actions$) => actions$.pipe(
  ofType(CTP_UPDATE_WORKSPACE_STATUS),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/v1/d3_modules/searchJob?${action.payload}`, 'put')).pipe(
    map((res) => ctpUpdateWorkspaceStatusSuccess(res)),
    catchError((err) => of(ctpUpdateWorkspaceStatusFailure(err))),
  )),
);

export const ctpUpdateWorkspaceStatusReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(ctpUpdateWorkspaceStatusAction, (state) => {
      state.loading = true;
    })
      .addCase(ctpUpdateWorkspaceStatusSuccess, (state, action) => {
        state.data = { ...action.payload.response };
        state.loading = false;
        state.flag = true;
      })
      .addCase(ctpUpdateWorkspaceStatusFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(ctpUpdateWorkspaceStatusResetAction, (state) => {
        state.data = [];
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);
