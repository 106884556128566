import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Rcookie from 'react-cookies';
import MenuItem from 'antd/es/menu/MenuItem';
import queryString from 'query-string';
import { Button, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { terminalsPath } from '../../../../app/components/Card/data';
import useAppSelector from '../../../../utils/redux-hook';
import CircularGauge from '../../components/CircularGauge';
import Loader from '../../../../common/components/Loader';
import LazyLoadDiv from '../../../../common/components/LazyLoadDiv';
import { getIndicationOutputAction, getMoreIndicationOutputAction } from './logic';
import GraphicalView from './GraphicalView';
import Mixpanel from '../../../../utils/mixpanel';
import { iepCheckStatusResetAction } from '../IEPInputPage/logic';

function TopIndicationsPage({ jobId }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [listData, setListData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [isEditView, setIsEditView] = useState(false);
  const [isGraphical, setIsGraphical] = useState(false);
  const [isPendingDownload, setIsPendingDownload] = useState(false);
  const [isPendingZipDownload, setIsPendingZipDownload] = useState(false);
  const [proteinName, setProteinName] = useState('');
  const [service, setService] = useState('');
  const getIndicationOutputResponse = useAppSelector((state) => state.getIndicationOutputResponse);
  const handleClick = (val) => {
    Mixpanel.track('iepToggleButtonClick', {
      action: 'toggle button click',
      isGraphical,
    });
    setIsGraphical(val);
  };

  useEffect(() => {
    const request = {
      job_id: jobId,
      from: currentPage,
      size: 10,
    };
    dispatch(getIndicationOutputAction(queryString.stringify(request)));
    // setRequiredJobId(jobId);
  }, []);

  useEffect(() => {
    if (getIndicationOutputResponse?.flag) {
      setProteinName(getIndicationOutputResponse?.data?.data?.jobData?.user_input?.protein_name);
      setService(getIndicationOutputResponse?.data?.data?.jobData?.user_input?.service);
      setListData(getIndicationOutputResponse?.data?.data?.extractedProtienData);
      setTotalCount(getIndicationOutputResponse?.data?.data?.totalCount);
    }
  }, [JSON.stringify(getIndicationOutputResponse)]);

  const handleBackClick = () => {
    Mixpanel.track('iepBackButtonClick', {
      action: 'IEP back button click',
    });
    if (isEditView) {
      setIsEditView(false);
    } else {
      dispatch(iepCheckStatusResetAction());
      navigate(`${terminalsPath.metad3_iep}?service=${service}&proteinName=${proteinName}`);
    }
  };

  function capitalizeFirstLetterOfEveryWord(str) {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  function capitalizeFirstLetterOfEveryString(arr) {
    return arr.map((str) => str.charAt(0).toUpperCase() + str.slice(1));
  }

  function formatNumber(value) {
    const roundedValue = parseFloat(value.toFixed(2));
    return roundedValue;
  }

  const infoComponent1 = (heading, value) => (
    <div className="info-component-main">
      <div className="info-component-heading">{heading}</div>
      <div className="info-component-value">
        <Tooltip title={value.length ? value.toString() : ''}>{value[0]}</Tooltip>
      </div>
    </div>
  );

  const infoComponent = (heading, value) => (
    <div className="info-component-main">
      <div className="info-component-heading">{heading}</div>
      <div className="info-component-value">
        <Tooltip title={value}>{value}</Tooltip>
      </div>
    </div>
  );

  const displayDrugItem = (item) => (
    <div className="combinations-drugs-info">
      <div className="info-component-row">
        <div className="info-component">{infoComponent1('Therapeutic Area', item.therapeutic_area.length ? capitalizeFirstLetterOfEveryString(item.therapeutic_area) : [])}</div>
        <div className="info-component">{infoComponent('Publication Count', item.publication_count)}</div>
        <div className="info-component">{infoComponent('Clinical Trial Count', item.clinical_count)}</div>
      </div>
      <div className="info-component-row">
        <div className="info-component">{infoComponent('Onco/Non-onco', item.is_onco === true ? 'Onco-Indication' : 'Non-onco indication')}</div>
        <div className="info-component">{infoComponent('Publication Score', formatNumber(item.literature_mining_score))}</div>
        <div className="info-component">{infoComponent('Network Score', formatNumber(item.network_saw_score))}</div>
      </div>
    </div>
  );

  const displayOutputList = () => listData?.map((item) => (
    <div key={crypto.randomUUID()} className="combinations-output-item">
      <div className="item-title">{capitalizeFirstLetterOfEveryWord(item.preferred_name)}</div>
      <div className="combinations-item-content">
        <div className="combinations-drugs-list">{displayDrugItem(item)}</div>
        <CircularGauge value={item.topsis_score} />
      </div>
    </div>
  ));

  const downloadXLSFile = async ({ isZip }) => {
    if (isZip) {
      setIsPendingZipDownload(true);
    } else {
      setIsPendingDownload(true);
    }
    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`) ? Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`) : '', 'Content-Type': 'blob', 'Access-Control-Allow-Origin': `${process.env.applicationUrl}` };
    const apiUrl = isZip ? `${process.env.apiUrl_iep}/zipDownload?job_id=${jobId}` : `${process.env.apiUrl_iep}/resultDownload?job_id=${jobId}`;

    try {
      const response = await axios({
        method: 'GET',
        url: apiUrl,
        responseType: isZip ? 'json' : 'arraybuffer',
        headers,
      });
      const outputFilename = isZip ? `${Date.now()}.zip` : `${Date.now()}.xls`;

      // If you want to download file automatically using link attribute.
      const url = isZip ? response.data?.zip_url : URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      setIsPendingDownload(false);
      setIsPendingZipDownload(false);
    } catch (error) {
      throw Error(error);
    }
  };
  const loadMoreRows = () => {
    const request = {
      job_id: jobId,
      from: currentPage + 1,
      size: 10,
    };
    dispatch(getMoreIndicationOutputAction(queryString.stringify(request)));
    setCurrentPage(currentPage + 1);
  };

  return (
    <div className="ct-home-page">
      {isGraphical ? (
        <>
          <div className="main-heading">
            <div className="back-btn">
              <Button className="back-btn-icon" onClick={() => handleBackClick()}>
                Back
              </Button>
            </div>
            {isEditView && (
              <div className="heading">
                <div className="main-title">List of associated proteins</div>
                <div className="main-description">Select or deselect proteins from the list as needed to generate the outcome based on your chosen proteins</div>
              </div>
            )}
            <div className="button-row button-row-heading">
              {!isGraphical && (
                <div className="main-download-btn">
                  <Button>Download Top 100 Combinations List</Button>
                </div>
              )}
              {!isEditView && (
                <div className="graphical-view-title-row">
                  <div className="graphical-view-title">{`Landscape View for "${proteinName}"`}</div>
                  <div className="graphical-view-btns" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div
                      className="edit-protein-btn"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        Mixpanel.track('iepEditAssociatedProteinClick', {
                          action: 'edit associated protein click',
                        });
                        setIsEditView(true);
                      }}
                      aria-hidden
                    >
                      Edit Associated Protein
                    </div>
                    <div className="main-download-btn">
                      <Button
                        onClick={() => {
                          Mixpanel.track('iepDownloadOutputZipClick', {
                            action: '.zip download output click',
                          });
                          setIsPendingZipDownload(true);
                          downloadXLSFile({ isZip: true });
                        }}
                        disabled={isPendingZipDownload}
                      >
                        {`${isPendingZipDownload ? 'Downloading...' : 'Download Output'}`}
                      </Button>
                    </div>
                  </div>
                  <div className="main-toggle-btn" style={{ display: 'flex' }}>
                    <Button onClick={() => handleClick(false)} className={`toggle-btn ${!isGraphical ? 'active' : ''}`}>
                      <div className="toggle-icon1" aria-hidden="true">
                        <FontAwesomeIcon icon={MenuItem} />
                      </div>
                    </Button>
                    <Button onClick={() => handleClick(true)} className={`toggle-btn ${isGraphical ? 'active' : ''}`}>
                      <div className="toggle-icon2" aria-hidden="true" />
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="bubblechart-tab">
            <GraphicalView isEditView={isEditView} setIsEditView={setIsEditView} serviceName={service} proteinName={proteinName} jobId={jobId} />
          </div>
        </>
      ) : (
        <Loader loading={getIndicationOutputResponse.loading} error={getIndicationOutputResponse.error} width="100%">
          <LazyLoadDiv className="iep-lazy-loading" id="iep-lazy-loading-output" total={totalCount} currentTotal={(getIndicationOutputResponse?.data?.data?.extractedProtienData || []).length} loadMoreRows={loadMoreRows} height="500px">
            <div className="main-heading">
              <div className="back-btn">
                <Button className="back-btn-icon" onClick={() => handleBackClick()}>
                  Back
                </Button>
              </div>
              <div className="main-title">{`Prioritised list of indications for “${proteinName}”`}</div>
              <div className="main-description">Based on the Innoplexus model scores, here is a prioritised list of indications, with scores ranging from 0 to 1, where 0 indicates a low score and 1 indicates a high score.</div>
              <div className="button-row">
                {!isGraphical && (
                  <div className="download-btns">
                    <div className="main-download-btn">
                      <Button
                        onClick={() => {
                          Mixpanel.track('iepDownloadOutputXLSClick', {
                            action: '.xls download output click',
                          });
                          setIsPendingDownload(true);
                          downloadXLSFile({ isZip: false });
                        }}
                        disabled={isPendingDownload}
                      >
                        {`${isPendingDownload ? 'Downloading...' : 'Download Output'}`}
                      </Button>
                    </div>
                    <div className="main-download-btn">
                      <Button
                        onClick={() => {
                          Mixpanel.track('iepDownloadOutputZipClick', {
                            action: '.zip download output click',
                          });
                          setIsPendingZipDownload(true);
                          downloadXLSFile({ isZip: true });
                        }}
                        disabled={isPendingZipDownload}
                      >
                        {`${isPendingZipDownload ? 'Downloading...' : 'Download .zip'}`}
                      </Button>
                    </div>
                  </div>
                )}
                <div className="main-toggle-btn" style={{ display: 'flex' }}>
                  <Button onClick={() => handleClick(false)} className={`toggle-btn ${!isGraphical ? 'active' : ''}`}>
                    <div className="toggle-icon1" aria-hidden="true">
                      <FontAwesomeIcon icon={MenuItem} />
                    </div>
                  </Button>
                  <Button onClick={() => handleClick(true)} className={`toggle-btn ${isGraphical ? 'active' : ''}`}>
                    <div className="toggle-icon2" aria-hidden="true" />
                  </Button>
                </div>
              </div>
            </div>
            <div className="iep-output-list">{displayOutputList()}</div>
          </LazyLoadDiv>
        </Loader>
      )}
    </div>
  );
}

TopIndicationsPage.propTypes = {
  jobId: PropTypes.string.isRequired,
};

export default TopIndicationsPage;
