import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import { apiCallwithoutCT } from '../../../../utils';

const AE_VERIFY_SEQUENCES = 'AE_VERIFY_SEQUENCES';
const AE_VERIFY_SEQUENCES_SUCCESS = 'AE_VERIFY_SEQUENCES_SUCCESS';
const AE_VERIFY_SEQUENCES_FAILURE = 'AE_VERIFY_SEQUENCES_FAILURE';
const AE_VERIFY_SEQUENCES_RESET = 'AE_VERIFY_SEQUENCES_RESET';

export const aeVerifySequencesAction = createAction(AE_VERIFY_SEQUENCES);
export const aeVerifySequencesResetAction = createAction(AE_VERIFY_SEQUENCES_RESET);

const aeVerifySequencesSuccess = createAction(AE_VERIFY_SEQUENCES_SUCCESS);
const aeVerifySequencesFailure = createAction(AE_VERIFY_SEQUENCES_FAILURE);

export const aeVerifySequencesEpic = (actions$) => actions$.pipe(
  ofType(AE_VERIFY_SEQUENCES),
  switchMap((action) => ajax(apiCallwithoutCT(`${process.env.apiUrl_ae}/verifySequences?${action.payload.params}`, 'POST', action.payload.body)).pipe(
    map((res) => aeVerifySequencesSuccess(res)),
    catchError((err) => of(aeVerifySequencesFailure(err))),
  )),
);

export const aeVerifySequencesReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(aeVerifySequencesAction, (state) => {
        state.loading = true;
      })
      .addCase(aeVerifySequencesSuccess, (state, action) => {
        state.data = { ...action.payload.response };
        state.loading = false;
        state.flag = true;
      })
      .addCase(aeVerifySequencesFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(aeVerifySequencesResetAction, (state) => {
        state.data = {};
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);
