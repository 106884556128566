/* eslint-disable */
import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import queryString from "query-string";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { getPathwayEnrichmentAction } from "./logic";
import Loader from "../../../../../common/components/Loader";
import useAppSelector from "../../../../../utils/redux-hook";
import { Select, Tooltip } from "antd";

function PathEnrichmentGraph({ jobId }) {
  const [data, setData] = useState([]);
  const [selectedPathwayType, setSelectedPathwayType] = useState(
    pathwayTypes[0].value
  );

  const dispatch = useDispatch();
  const getPathwayEnrichmentResponse = useAppSelector(
    (state) => state.getPathwayEnrichmentResponse
  );

  useEffect(() => {
    const body = {
      // job_id: 'd2c8a012-e373-4bed-b635-91286327c46d' ,
      job_id: jobId,
      pathway_type: selectedPathwayType,
    };
    dispatch(getPathwayEnrichmentAction(queryString.stringify(body)));
  }, [selectedPathwayType]);

  useEffect(() => {
    if (getPathwayEnrichmentResponse.flag) {
      const arr = [];
      for (let i = 0; i < getPathwayEnrichmentResponse.data?.length; i += 1) {
        arr.push([
          getPathwayEnrichmentResponse.data[i]["-log10_p_value_HyperGeometric"],
          getPathwayEnrichmentResponse.data[i].preferred_name,
          getPathwayEnrichmentResponse.data[i].gene_count,
        ]);
      }
      setData(arr);
    }
  }, [getPathwayEnrichmentResponse]);

  useEffect(() => {
    // window.scrollTo(0, 0);
  }, [data]);

  const option = {
    xAxis: {
      type: "value",
      name: "-Log10(p-value) --->",
      nameLocation: "middle",
      nameGap: 40,
      nameTextStyle: {
        fontFamily: "Inter",
        fontSize: "15px",
        color: "#1F1F1F",
        fontWeight: "500",
      },
      axisLine: {
        borderWidth: 1,
        show: true,
        borderColor: "#FFF",
      },
      splitLine: {
        show: true,
        lineStyle: {
          type: "dashed",
        },
      },
    },
    grid: { containLabel: true, left: 0, right: 20 },
    yAxis: {
      type: "category",
      // onZero: false,
      // name: 'Y-Axis',
      // nameLocation: 'middle',
      // nameGap: 80,
      axisLabel: {
        // rotate: 90,
        width: 150,
        ellipsis: "...",
        overflow: "truncate",
      },
      scale: true,
      axisTick: {
        alignWithLabel: true,
      },
      axisLine: {
        offset: {
          onZero: false,
        },
      },
      boundaryGap: false,
      splitLine: {
        show: true,
        lineStyle: {
          type: "dashed",
        },
        interval: 0,
      },
    },
    tooltip: {
      show: true,
      backgroundColor: "rgba(255,255,255,0.7)",
      position: "top",
      trigger: "item",
      axisPointer: { type: "cross" },
      formatter(param) {
        const { data } = param;
        return (
          '<div style="border-bottom: 1px solid rgba(255,255,255,.3); font-size: 18px;margin-bottom: 7px; display: flex;flex-direction: column">' +
          '<div style="display: flex;flex-direction: row;justify-content: space-between"><p style="font-family: Inter;font-size: 14px;color: #59748A">Path way: </p><p style="font-family: Inter;font-size: 15px;color: #121417;margin-left:1em">' +
          ` ${data[1]?.length > 25 ? `${data[1]?.substr(0, 25)}...` : data[1]}` +
          "</p></div>" +
          '<div style="display: flex;flex-direction: row;justify-content: space-between"><p style="font-family: Inter;font-size: 14px;color: #59748A">Gene Count: </p><p style="font-family: Inter;font-size: 15px;color: #121417;margin-left:1em">' +
          `${data[2]}` +
          "</p></div>" +
          '<div style="display: flex;flex-direction: row;justify-content: space-between"><p style="font-family: Inter;font-size: 14px;color: #59748A">-log10(P-value): </p><p style="font-family: Inter;font-size: 15px;color: #121417;margin-left:1em">' +
          `${data[0]}` +
          "</p></div>" +
          "</div>"
        );
      },
    },
    series: [
      {
        // data: [
        //   ['', '', 0],
        //   [10.0, 'Aus', 3],
        //   [8.07, 'Ind', 7],
        //   [13.0, 'Fra', 16],
        //   [9.05, 'Afr', 10],
        //   [11.0, 'USA', 13],
        //   [14.0, 'Rus', 5],
        //   [13.4, 'Chi', 7],
        //   [10.0, 'Kor', 2],
        // ],
        data: [["", "", 0], ...data],
        type: "scatter",
        symbolSize(data) {
          return Math.sqrt(data[2]) * 3;
        },
      },
    ],
  };
  return (
    <div className="pathway-enrichment-graph-container" width="100%">
      <div className="card-content-ctp-title">
        <div className="card-content-ctp-header">
          Pathway Enrichment Graph
          <Tooltip title='Pathway Enrichment'>
            <div className="info-add-icon" />
          </Tooltip>
        </div>
        <div className="card-content-dropdown-select">
          <Select
            value={selectedPathwayType}
            style={{
              width: 120,
            }}
            onChange={(value) => {
              setSelectedPathwayType(value);
            }}
            options={pathwayTypes}
          />
        </div>
      </div>
      <div className="pathway-enrichment-graph-header">
        <div className="pathway-enrichment-graph-legend">Pathway</div>
        <div className="pathway-enrichment-graph-legend">
          Circle Radius - Gene count
        </div>
      </div>
      <Loader loading={getPathwayEnrichmentResponse.loading}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span
              style={{
                fontFamily: "Inter",
                fontSize: "15px",
                color: "#1F1F1F",
                fontWeight: "500",
                transform: "rotate(180deg)",
                width: "50px",
                writingMode: "vertical-rl",
              }}
            >
              {"Pathway Names --->"}
            </span>
          </div>
          <ReactECharts
            option={option}
            style={{ height: "800px", width: "100%" }}
          />
        </div>
      </Loader>
    </div>
  );
}

const pathwayTypes = [
  {
    value: "PpiSignature",
    label: "Protein-Protein Interaction",
  },
  {
    value: "DiseaseSimilaritySignature",
    label: "Disease Similarity",
  },
  {
    value: "MolecularFunctionSignature",
    label: "Molecular Function Similarity",
  },
  {
    value: "LiteratureSignature",
    label: "Literature Mining",
  },
];

PathEnrichmentGraph.propTypes = {
  jobId: PropTypes.string.isRequired,
};

export default PathEnrichmentGraph;
