import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../utils';

// check status
const TOP_INDICATIONS_JOBS = 'TOP_INDICATIONS_JOBS';
const TOP_INDICATIONS_JOBS_SUCCESS = 'TOP_INDICATIONS_JOBS_SUCCESS';
const TOP_INDICATIONS_JOBS_FAILURE = 'TOP_INDICATIONS_JOBS_FAILURE';
const TOP_INDICATIONS_JOBS_RESET = 'TOP_INDICATIONS_JOBS_RESET';

export const topIndicationsJobsAction = createAction(TOP_INDICATIONS_JOBS);
export const topIndicationsJobsResetAction = createAction(TOP_INDICATIONS_JOBS_RESET);

const topIndicationsJobsSuccess = createAction(TOP_INDICATIONS_JOBS_SUCCESS);
const topIndicationsJobsFailure = createAction(TOP_INDICATIONS_JOBS_FAILURE);

export const topIndicationsJobsIEPEpic = (actions$) => actions$.pipe(
  ofType(TOP_INDICATIONS_JOBS),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/v1/d3_modules/searchJob?${action.payload}`, 'get')).pipe(
    map((res) => topIndicationsJobsSuccess(res)),
    catchError((err) => of(topIndicationsJobsFailure(err))),
  )),
);

export const topIndicationsJobsIEPReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(topIndicationsJobsAction, (state) => {
      state.loading = true;
    })
      .addCase(topIndicationsJobsSuccess, (state, action) => {
        state.data = [...action.payload.response.data];
        state.loading = false;
        state.flag = true;
      })
      .addCase(topIndicationsJobsFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(topIndicationsJobsResetAction, (state) => {
        state.data = [];
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);

// check status
const CLINICAL_ANALYSIS_JOBS = 'CLINICAL_ANALYSIS_JOBS';
const CLINICAL_ANALYSIS_JOBS_SUCCESS = 'CLINICAL_ANALYSIS_JOBS_SUCCESS';
const CLINICAL_ANALYSIS_JOBS_FAILURE = 'CLINICAL_ANALYSIS_JOBS_FAILURE';
const CLINICAL_ANALYSIS_JOBS_RESET = 'CLINICAL_ANALYSIS_JOBS_RESET';

export const clinicalAnalysisJobsAction = createAction(CLINICAL_ANALYSIS_JOBS);
export const clinicalAnalysisJobsResetAction = createAction(CLINICAL_ANALYSIS_JOBS_RESET);

const clinicalAnalysisJobsSuccess = createAction(CLINICAL_ANALYSIS_JOBS_SUCCESS);
const clinicalAnalysisJobsFailure = createAction(CLINICAL_ANALYSIS_JOBS_FAILURE);

export const clinicalAnalysisJobsIEPEpic = (actions$) => actions$.pipe(
  ofType(CLINICAL_ANALYSIS_JOBS),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/v1/d3_modules/searchJob?${action.payload}`, 'get')).pipe(
    map((res) => clinicalAnalysisJobsSuccess(res)),
    catchError((err) => of(clinicalAnalysisJobsFailure(err))),
  )),
);

export const clinicalAnalysisJobsIEPReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(clinicalAnalysisJobsAction, (state) => {
      state.loading = true;
    })
      .addCase(clinicalAnalysisJobsSuccess, (state, action) => {
        state.data = [...action.payload.response.data];
        state.loading = false;
        state.flag = true;
      })
      .addCase(clinicalAnalysisJobsFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(clinicalAnalysisJobsResetAction, (state) => {
        state.data = [];
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);

// check status
const IEP_WORKSPACE_STATUS = 'IEP_WORKSPACE_STATUS';
const IEP_WORKSPACE_STATUS_SUCCESS = 'IEP_WORKSPACE_STATUS_SUCCESS';
const IEP_WORKSPACE_STATUS_FAILURE = 'IEP_WORKSPACE_STATUS_FAILURE';
const IEP_WORKSPACE_STATUS_RESET = 'IEP_WORKSPACE_STATUS_RESET';

export const iepWorkspaceStatusAction = createAction(IEP_WORKSPACE_STATUS);
export const iepWorkspaceStatusResetAction = createAction(IEP_WORKSPACE_STATUS_RESET);

const iepWorkspaceStatusSuccess = createAction(IEP_WORKSPACE_STATUS_SUCCESS);
const iepWorkspaceStatusFailure = createAction(IEP_WORKSPACE_STATUS_FAILURE);

export const iepWorkspaceStatusEpic = (actions$) => actions$.pipe(
  ofType(IEP_WORKSPACE_STATUS),
  switchMap(() => ajax(apiCall(`${process.env.apiUrl_iep}/userIndicationList`, 'get')).pipe(
    map((res) => iepWorkspaceStatusSuccess(res)),
    catchError((err) => of(iepWorkspaceStatusFailure(err))),
  )),
);
export const iepWorkspaceStatusReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(iepWorkspaceStatusAction, (state) => {
      state.loading = true;
    })
      .addCase(iepWorkspaceStatusSuccess, (state, action) => {
        state.data = [...action.payload.response.data];
        state.loading = false;
        state.flag = true;
      })
      .addCase(iepWorkspaceStatusFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(iepWorkspaceStatusResetAction, (state) => {
        state.data = [];
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);
