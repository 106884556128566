/* eslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Upload, Input } from 'antd';
import SvgLoader from '../../../../common/components/SvgLoader';

function InputBoxClinicalAnalysis({
  inputHandler,

  // smiles,
  setFormData,
  // setInputStyle,
}) {
  const { TextArea } = Input;
  const [inputValue, setInputValue] = useState();
  const [dataset, setDataset] = useState([1, 0]);
  const inputStyles = [
    'Textual Input',
    'Upload a Document',
  ];
  const [isFileSelected, setIsFileSelected] = useState(false);

  const onInputFileChange = (fileList) => {
    if (fileList?.length > 1) {
      fileList.shift();
    }
    const files = fileList;
    if (files?.length) {
      const formData = new FormData();
      formData.append('file', files[0].originFileObj);
      setIsFileSelected(true);
      setFormData(formData);
    }
    // change below after api integration
  };

  const handleDatasetChange = (arr) => {
    setDataset(arr);
    let value = {};
    if (arr[0] === 1) {
      // setInputStyle(true);
      setIsFileSelected(false);
      setFormData(null);
      value = { btn: inputStyles[0] };
    } else {
      // setInputStyle(false);
      value = { btn: inputStyles[1] };
    }
    inputHandler(value);
  };

  const props = {
    name: 'file',
    accept: '.xls,.xlsx',
    multiple: true,
    progress: true,
    beforeUpload: () => false,
    onChange(info) {
      if (info.file.status !== 'uploading') {
        onInputFileChange(info.fileList);
      }
    },
    onRemove() {
      setIsFileSelected(false);
      setFormData(null);
    },
  };

  useEffect(() => {
    const splits = inputValue?.split('\n');
    // if (splits?.length <= 5) {
    const inputs = {
      indications_for_cca: splits,
    };
    inputHandler(inputs);
    // }
  }, [inputValue]);

  // useEffect(() => {
  //   inputStyles.forEach((item, index) => {
  //     if (item === inputStyle) {
  //       setDataset((prev) => {
  //         const newDataset = prev;
  //         newDataset[index] = 1;
  //         return newDataset;
  //       });
  //       inputHandler({ dataset: inputStyles[index] });
  //     }
  //   });
  // }, []);

  const handleInputChange = (e) => {
    if (e !== undefined) {
      // if ((e.match(/\n/g) || []).length < 5) {
      setInputValue(e);
      // }
    }
  };

  return (
    <div className="provide-input-container">
      <div className="input-container">
        <div className="input-container-title">Select Input Style</div>
        <div className="input-fields">
          <div className="select-dataset">
            <div className="select-dataset-footer">
              <div className="select-dataset-footer-btn">
                <button
                  type="button"
                  className={`select-button ${dataset[0] === 1 ? 'active' : ''}`}
                  onClick={() => handleDatasetChange([1, 0])}
                >
                  Textual Input
                </button>
              </div>

              <div className="select-dataset-footer-btn">
                <button
                  type="button"
                  className={`select-button ${dataset[1] === 1 ? 'active' : ''}`}
                  onClick={() => handleDatasetChange([0, 1])}
                >
                  Upload a Document
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="provide-input-sec">
          <div className="pro-input-title">
            <div className="input-container-title">Provide Input</div>
            <div className="input-fields-value try-example-section">
              <text
                style={{ cursor: 'pointer', color: 'blue' }}
                onClick={() => {
                  // setIndication('Lung Cancer');
                  // if (!isDisabled) {
                  //   setInputValue('CC1=C(C(C(=C(N1)C)C(=O)OCCN2CCN(CC2)C(C3=CC=CC=C3)C4=CC=CC=C4)C5=CC(=CC=C5)[N+](=O)[O-])C(=O)OC');
                  // }
                  // setDefaultIndication('Lung Cancer');
                }}
              >
                Try Example
              </text>
            </div>
          </div>
          <div className="input-fields">
            <div className="input-fields-value">
              {(dataset[0] === 1 || dataset[0] === -1) && (
                <TextArea
                  rows={8}
                  value={inputValue}
                  placeholder={'Enter Indications here, each indication on new line\nLine 1: Indication 1\nLine 2: Indication 2\nLine 3: Indication 3'}
                  onChange={(e) => {
                    handleInputChange(e.target.value);
                  }}
                />
              )}
              {dataset[1] === 1 && (
                <div
                  className={`input-fields-upload${isFileSelected ? ' upload-file-item' : ''}`}
                >
                  <Upload {...props}>
                    {!isFileSelected && (
                      <p className="ant-upload-drag-icon">
                        <SvgLoader
                          width={216}
                          height={72}
                          svgName="upload-file"
                        />
                      </p>
                    )}
                    {!isFileSelected && (
                      <p className="ant-upload-text">
                        <span className="ant-upload-text-grey">
                          Drag & drop molecule/s file or
                        </span>
                        <span className="ant-upload-text-blue">Browse</span>
                      </p>
                    )}
                    {!isFileSelected && (
                      <p className="ant-upload-hint">
                        The file should be in .xlsx format, and the maximum size
                        limit is 5MB.
                      </p>
                    )}
                  </Upload>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

InputBoxClinicalAnalysis.propTypes = {
  inputHandler: PropTypes.func.isRequired,
  inputValue: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  inputStyle: PropTypes.string.isRequired,
  smiles: PropTypes.string.isRequired,
  setFormData: PropTypes.func.isRequired,
  setInputStyle: PropTypes.func.isRequired,
};

export default InputBoxClinicalAnalysis;
