/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Upload } from 'antd';
import axios from 'axios';
import Rcookie from 'react-cookies';
import { useDispatch, useSelector } from 'react-redux';
import SvgLoader from '../../../../common/components/SvgLoader';
import { aeVerifySequencesAction } from '../../pages/AntibodyEngineeringInputPage/logic';
// import Mixpanel from '../../../../utils/mixpanel';

function InputBox({
  isDisabled,
  setFormData,
  inputHandler,
}) {
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [pdbId, setPdbId] = useState('');
  const dispatch = useDispatch();
  const [fileStats, setFileStats] = useState({});
  const aeVerifySequencesResponse = useSelector((state) => state.aeVerifySequencesResponse);

  const onInputFileChange = async (fileList) => {
    if (fileList?.length > 1) {
      fileList.shift();
    }
    const files = fileList;
    if (files?.length) {
      const formData = new FormData();
      formData.append('file', files[0].originFileObj);
      dispatch(aeVerifySequencesAction({ params: '', body: formData }));
      setIsFileSelected(true);
      setFormData(formData);
    }
    // change below after api integration
  };

  const props = {
    name: 'file',
    accept: '.xls,.xlsx',
    multiple: true,
    progress: true,
    beforeUpload: () => false,
    onChange(info) {
      if (info.file.status !== 'uploading') {
        onInputFileChange(info.fileList);
      }
    },
    onRemove() {
      setIsFileSelected(false);
      setFormData(null);
    },
  };

  useEffect(() => {
    if (aeVerifySequencesResponse?.flag && !aeVerifySequencesResponse?.loading) {
      setFileStats(aeVerifySequencesResponse?.data?.stats);
    }
  }, [aeVerifySequencesResponse]);

  const downloadTemplateFile = async () => {
    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = {
      Authorization: Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`) ? Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`) : '',
      'Content-Type': 'blob',
      'Access-Control-Allow-Origin': `${process.env.applicationUrl}`,
    };
    const apiUrl = `${process.env.apiUrl_ae}/templateDownload`;

    try {
      const response = await axios({
        method: 'GET',
        url: apiUrl,
        responseType: 'arraybuffer',
        headers,
      });
      const outputFilename = 'antibody_engineering_input_template.xlsx';

      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      throw Error(error);
    }
  };

  return (
    <div className="provide-input-container provide-input-container-antibody">
      <div className={`input-container ${isDisabled ? 'disabled' : ''}`}>
        <div className="provide-input-sec">
          <div className="pro-input-title">
            <div className="input-container-title">Provide Input</div>
          </div>
          <div className="input-fields">
            <div className="upload-file">Upload a file</div>
            <div className="input-fields-value">
              <div
                className={`input-fields-upload${isFileSelected ? ' upload-file-item' : ''}`}
              // eslint-disable-next-line react/jsx-no-comment-textnodes
              >
                <Upload {...props}>
                  {!isFileSelected && (
                  <p className="ant-upload-drag-icon">
                    <SvgLoader
                      width={216}
                      height={72}
                      svgName="upload-file"
                    />
                  </p>
                  )}
                  {!isFileSelected && (
                  <p className="ant-upload-text">
                    <span className="ant-upload-text-grey">
                      Drag & drop indications file or
                    </span>
                    <span className="ant-upload-text-blue">Browse</span>
                  </p>
                  )}
                  {!isFileSelected && (
                  <p className="ant-upload-hint">
                    (Acceptable file formats are .xlsx or .csv)
                  </p>
                  )}
                  {!isFileSelected && (
                  <p className="ant-upload-text">
                    <span className="ant-upload-text-grey">
                      For reference
                    </span>
                    <span
                      className="ant-upload-text-blue"
                      onClick={(e) => {
                        e.stopPropagation();
                        downloadTemplateFile();
                      }}
                      aria-hidden
                    >
                      Download a template
                    </span>
                  </p>
                  )}
                  {isFileSelected && Object.keys(fileStats).length !== 0 && (
                  <p className="ant-upload-text-black">
                    The file contains :
                    <ul>
                      {fileStats?.duplicates}
                      {' '}
                      duplicates
                    </ul>
                    <ul>
                      {fileStats?.diffDuplicates}
                      {' '}
                      different duplicates
                    </ul>
                    <ul>
                      {fileStats?.incorrectValues}
                      {' '}
                      incorrect values
                    </ul>
                  </p>
                  )}
                  {isFileSelected && (
                  <p className="ant-upload-text-red">
                    #Note: Only unique sequences with activity value will be processed for structure generation and docking, in case of duplicate, higher activity sequence would be chosen.
                  </p>
                  )}
                </Upload>
              </div>
            </div>
          </div>
          <div className="input-pdb-id">
            <div className="input-pdb-id-title">Provide PDB ID</div>
            <div className="input-pdb-id-input">
              <Input
                placeholder="Enter PDB ID"
                value={pdbId}
                onChange={(e) => {
                  const value = {
                    pdbId: e.target.value,
                  };
                  setPdbId(e.target.value);
                  inputHandler(value);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

InputBox.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  setFormData: PropTypes.func.isRequired,
  inputHandler: PropTypes.func.isRequired,
};

export default InputBox;
