import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../utils';

// disease hierarchy data
const GET_DISEASE_HIERARCHY_DATA = 'GET_DISEASE_HIERARCHY_DATA';
const GET_RESET_DISEASE_HIERARCHY_DATA = 'GET_RESET_DISEASE_HIERARCHY_DATA';
const GET_DISEASE_HIERARCHY_DATA_SUCCESS = 'GET_DISEASE_HIERARCHY_DATA_SUCCESS';
const GET_DISEASE_HIERARCHY_DATA_FAILURE = 'GET_DISEASE_HIERARCHY_DATA_FAILURE';

export const getDiseaseHierarchyDataAction = createAction(GET_DISEASE_HIERARCHY_DATA);

const getDiseaseHierarchyDataSuccess = createAction(GET_DISEASE_HIERARCHY_DATA_SUCCESS);

export const getResetDiseaseHierarchyDataAction = createAction(GET_RESET_DISEASE_HIERARCHY_DATA);

const getDiseaseHierarchyDataFailure = createAction(GET_DISEASE_HIERARCHY_DATA_FAILURE);

export const getDiseaseHierarchyDataEpic = (actions$) => actions$.pipe(
  ofType(GET_DISEASE_HIERARCHY_DATA),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_iep}/diseaseHeirarchy?${action.payload}`, 'get')).pipe(
    map((res) => getDiseaseHierarchyDataSuccess(res)),
    catchError((err) => of(getDiseaseHierarchyDataFailure(err))),
  )),
);

export const getResetDiseaseHierarchyDataEpic = (actions$) => actions$.pipe(
  ofType(GET_DISEASE_HIERARCHY_DATA),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_iep}/diseaseHeirarchy?${action.payload}`, 'get')).pipe(
    map((res) => getDiseaseHierarchyDataSuccess(res)),
    catchError((err) => of(getDiseaseHierarchyDataFailure(err))),
  )),
);
export const getDiseaseHierarchyDataReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(getDiseaseHierarchyDataAction, (state) => {
        state.loading = true;
      })
      .addCase(getDiseaseHierarchyDataSuccess, (state, action) => {
        state.data = { ...action.payload.response };
        state.loading = false;
        state.flag = true;
      })
      .addCase(getResetDiseaseHierarchyDataAction, (state) => {
        state.error = false;
        state.flag = false;
        state.loading = false;
        state.data = {};
      })
      .addCase(getDiseaseHierarchyDataFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.loading = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);
