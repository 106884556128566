import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../../utils';

// get input suggest details
const GET_DR_PATENTS_LIST = 'GET_DR_PATENTS_LIST';
const GET_DR_PATENTS_LIST_SUCCESS = 'GET_DR_PATENTS_LIST_SUCCESS';
const GET_MORE_DR_PATENTS = 'GET_MORE_DR_PATENTS';
const GET_MORE_DR_PATENTS_SUCCESS = 'GET_MORE_DR_PATENTS_SUCCESS';
const GET_DR_PATENTS_LIST_FAILURE = 'GET_DR_PATENTS_LIST_FAILURE';

export const getDrPatentsListAction = createAction(GET_DR_PATENTS_LIST);

const getDrPatentsListSuccess = createAction(GET_DR_PATENTS_LIST_SUCCESS);

export const getMoreDrPatentsListAction = createAction(GET_MORE_DR_PATENTS);

const getMoreDrPatentsListSuccess = createAction(GET_MORE_DR_PATENTS_SUCCESS);

const getDrPatentsListFailure = createAction(GET_DR_PATENTS_LIST_FAILURE);
export const getDrPatentsListEpic = (actions$) => actions$.pipe(
  ofType(GET_DR_PATENTS_LIST),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_drug_reform}/getPatentsList?${action.payload.params}`, 'GET', action.payload.body)).pipe(
    map((res) => getDrPatentsListSuccess(res)),
    catchError((err) => of(getDrPatentsListFailure(err))),
  )),
);
export const getMoreDrPatentsListEpic = (actions$) => actions$.pipe(
  ofType(GET_MORE_DR_PATENTS),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_drug_reform}/getPatentsList?${action.payload.params}`, 'GET', action.payload.body)).pipe(
    map((res) => getMoreDrPatentsListSuccess(res)),
    catchError((err) => of(getDrPatentsListFailure(err))),
  )),
);

export const getDrPatentsListReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(getDrPatentsListAction, (state) => {
        state.loading = true;
      })
      .addCase(getDrPatentsListSuccess, (state, action) => {
        state.data = action.payload.response.data;
        state.total = action.payload.response.total;
        state.loading = false;
        state.flag = true;
      })
      .addCase(getMoreDrPatentsListAction, (state) => {
        state.loading = false;
      })
      .addCase(getMoreDrPatentsListSuccess, (state, action) => {
        state.data = [...state.data, ...action.payload.response.data];
        state.total = action.payload.response.total;
        state.loading = false;
        state.flag = true;
      })
      .addCase(getDrPatentsListFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);
