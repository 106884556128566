import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Header from '../../../../common/components/Header';
import SelectService from '../../container/SelectService';
import InputBox from '../../container/InputBox';
import { terminalsPath } from '../../../../app/components/Card/data';
import Mixpanel from '../../../../utils/mixpanel';

function ADCInputPage() {
  const [inputs, setInputs] = useState({
    smile: '',
    card: '',
  });
  const [isDisabled, setIsDisabled] = useState(true);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const service = searchParams.get('service');
  const payload = searchParams.get('payload');
  const handleClick = () => {
    navigate(`${terminalsPath.metad3_adc_output}?service=${inputs.card}&payload=${inputs.smile.id}`);
    Mixpanel.track('ADC Linkers Generated', {
      action: 'ADC Generate Linkers button clicked',
      service: inputs.card,
      payload: inputs.smile.id,
      module: 'ADC',
    });
  };

  const getInputs = (input) => {
    setInputs((prev) => ({
      ...prev,
      ...input,
    }));
  };
  const checkBtnDissabledForAccess = inputs.smile.id === '' || inputs.smile === undefined || inputs.card === '' || inputs.card === undefined;

  useEffect(() => {
    setIsDisabled(checkBtnDissabledForAccess);
  }, [inputs]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="main-page main-page-modules">
      <Header className="header-home adc-header" logo="adc" />
      <div className="ct-home-page adc-home-page">
        <div className="main-heading">
          <div className="main-title">Revolutionizing Drug Delivery: Design Your Next-Gen ADC</div>
          <div className="main-description">We provide AI driven services in de-novo ADC generation, linker prediction and payload prioritisation</div>
        </div>
        <SelectService inputHandler={getInputs} service={service} />
        <InputBox inputHandler={getInputs} payload={payload !== null ? payload : ''} isDisabled={inputs.card === ''} />
        <div className="ct-home-page-footer">
          <button onClick={handleClick} className="generate-in-btn" type="button" disabled={isDisabled}>
            GENERATE LINKERS
          </button>
        </div>
      </div>
    </div>
  );
}

export default ADCInputPage;
