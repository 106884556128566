import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../utils';

// generate indications
const GENERATE_HCTP_COMBINATIONS = 'GENERATE_HCTP_COMBINATIONS';
const GENERATE_HCTP_COMBINATIONS_SUCCESS = 'GENERATE_HCTP_COMBINATIONS_SUCCESS';
const GENERATE_HCTP_COMBINATIONS_FAILURE = 'GENERATE_HCTP_COMBINATIONS_FAILURE';
const GENERATE_HCTP_COMBINATIONS_RESET = 'GENERATE_HCTP_COMBINATIONS_RESET';

export const generateHCTPCombinationsAction = createAction(GENERATE_HCTP_COMBINATIONS);
export const generateHCTPCombinationsResetAction = createAction(GENERATE_HCTP_COMBINATIONS_RESET);

const generateHCTPCombinationsSuccess = createAction(GENERATE_HCTP_COMBINATIONS_SUCCESS);

const generateHCTPCombinationsFailure = createAction(GENERATE_HCTP_COMBINATIONS_FAILURE);
export const generateHCTPCombinationsEpic = (actions$) => actions$.pipe(
  ofType(GENERATE_HCTP_COMBINATIONS),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_ctp}/generateHCTPCombinations?${action.payload.params}`, 'POST', action.payload.body)).pipe(
    map((res) => generateHCTPCombinationsSuccess(res)),
    catchError((err) => of(generateHCTPCombinationsFailure(err))),
  )),
);

export const generateHCTPCombinationsReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(generateHCTPCombinationsAction, (state) => {
        state.loading = true;
      })
      .addCase(generateHCTPCombinationsSuccess, (state, action) => {
        state.data = { ...action.payload.response.data };
        state.loading = false;
        state.flag = true;
      })
      .addCase(generateHCTPCombinationsFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(generateHCTPCombinationsResetAction, (state) => {
        state.data = [];
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);
