import React, { useEffect, useState } from 'react';
import {
  Button, Divider, Input,
} from 'antd';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import queryString from 'query-string';
import { CloseOutlined } from '@ant-design/icons';
import Header from '../../../../common/components/Header';
import Loader from '../../../../common/components/Loader';
import {
  clinicalAnalysisJobsAction, iepWorkspaceStatusResetAction, topIndicationsJobsAction,
} from './logic';
import useAppSelector from '../../../../utils/redux-hook';
import { terminalsPath } from '../../../../app/components/Card/data';
import { isReadIEPOutputAction } from '../IEPOutputPage/logic';
import TopIndicationsWorkspaceDetails from '../../container/TopIndicationsWorkspaceDetails';
import ClinicalAnalysisWorkspaceDetails from '../../container/ClinicalAnalysisWorkspaceDetails';
// import PropTypes from 'prop-types';

function MyWorkspacePageIEP() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [currentJob, setCurrentJob] = useState({});
  const [currentService, setCurrentService] = useState([1, 0, 0]);
  const [isLoading, setIsLoading] = useState(true);
  const service = searchParams.get('service');
  const [serviceJobsList, setServiceJobsList] = useState({
    workspace: [],
    topIndications: [],
    CCA: [],
  });
  const topIndicationsJobsResponse = useAppSelector((state) => state.topIndicationsIEPResponse);
  const clinicalAnalysisJobsResponse = useAppSelector((state) => state.clinicalAnalysisIEPResponse);
  const dispatch = useDispatch();
  const [editing, setEditing] = useState({
    isEditing: false,
    editingId: '',
    editingName: '',
  });
  const [searchText, setSearchText] = useState('');
  const [searchAndSort, setSearchAndSort] = useState({
    isSearch: false,
    isSort: false,
    searchValue: '',
    sortValue: 'desc',
  });

  const [requestObj, setRequestObj] = useState({
    topIndications: {
      filters: JSON.stringify({
        module_id: 'metad3_iep',
        'user_input.service': 'Prioritize Indications',
      }),
    },
    CCA: {
      filters: JSON.stringify({
        module_id: 'metad3_iep',
        'user_input.service': 'CCA',
      }),
    },
  });

  const onChangedJob = (job) => {
    setCurrentJob(job);
  };

  useEffect(() => {
    setIsLoading(false);
    switch (service) {
      case 'Prioritize Indications':
        setCurrentService([1, 0, 0]);
        break;
      case 'CCA':
        setCurrentService([0, 1, 0]);
        break;
      case 'ADMET_PROPERTIES':
        setCurrentService([0, 0, 1]);
        break;
      default:
        setCurrentService([1, 0, 0]);
    }
  }, []);

  useEffect(() => {
    setRequestObj({
      topIndications: {
        filters: JSON.stringify({
          module_id: 'metad3_iep',
          'user_input.service': 'Prioritize Indications',
        }),
        searchQuery: (searchAndSort.searchValue.length > 0) ? searchAndSort.searchValue : undefined,
        sort_order: searchAndSort.sortValue,
        from: 1,
        size: 100,
      },
      CCA: {
        filters: JSON.stringify({
          module_id: 'metad3_iep',
          'user_input.service': 'CCA',
        }),
        searchQuery: (searchAndSort.searchValue.length > 0) ? searchAndSort.searchValue : undefined,
        sort_order: searchAndSort.sortValue,
        from: 1,
        size: 100,
      },
    });
  }, [searchAndSort]);

  useEffect(() => {
    dispatch(topIndicationsJobsAction(queryString.stringify(requestObj.topIndications)));
    dispatch(clinicalAnalysisJobsAction(queryString.stringify(requestObj.CCA)));

    const intervalId = setInterval(() => {
      if (topIndicationsJobsResponse.data) {
        dispatch(topIndicationsJobsAction(queryString.stringify(requestObj.topIndications)));
      }
      if (clinicalAnalysisJobsResponse.data) {
        dispatch(clinicalAnalysisJobsAction(queryString.stringify(requestObj.CCA)));
      }
    }, 10000);
    return () => clearInterval(intervalId);
  }, [requestObj]);

  useEffect(() => {
    if (topIndicationsJobsResponse?.flag) {
      setServiceJobsList((prev) => ({
        ...prev,
        topIndications: topIndicationsJobsResponse.data,
      }));
      if (!currentJob?.status && (service === 'Prioritize Indications' || !service)) {
        setCurrentJob(topIndicationsJobsResponse.data[0]);
      }
    }
  }, [topIndicationsJobsResponse]);

  useEffect(() => {
    if (clinicalAnalysisJobsResponse?.flag) {
      setServiceJobsList((prev) => ({
        ...prev,
        CCA: clinicalAnalysisJobsResponse.data,
      }));
      if (!currentJob?.status && (service === 'CCA')) {
        setCurrentJob(clinicalAnalysisJobsResponse.data[0]);
      }
    }
  }, [clinicalAnalysisJobsResponse]);

  const formatDate = (epoch) => {
    const date = new Date(epoch * 1000);

    const day = date.getDate();
    const month = date.toLocaleString('en-US', { month: 'short' });

    return `${day} ${month}`;
  };
  const formatTime = (epoch) => {
    const date = new Date(epoch * 1000);

    const time = date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });

    return time;
  };
  const onEditName = (job) => {
    const request = {
      job_id: job.job_id,
      job_name: editing.editingName,
    };
    dispatch(isReadIEPOutputAction(queryString.stringify(request)));
    setEditing({ isEditing: false, editingId: '', editingName: '' });
  };

  const jobsList = (serviceJobList) => (
    <div className="jobs-list">
      {serviceJobList?.map((job, index) => (
        <div className={`job-item${job?.job_id === currentJob?.job_id ? ' active' : ''}`} key={job?.job_id} style={{ display: 'flex', cursor: 'pointer' }} onClick={() => onChangedJob(job)} aria-hidden>
          <div className="job-info">
            {editing.isEditing && editing.editingId === index ? (
              <div>
                <Input
                  placeholder="Job Name"
                  value={editing.editingName ?? job.job_name ?? job.user_input.protein_name}
                  onChange={(e) => setEditing({ ...editing, editingName: e.target.value })}
                  onPressEnter={() => onEditName(job)}
                  //   onBlur={() => setEditing({ isEditing: false, editingId: '' })}
                />
              </div>
            ) : (
              <div className="job-title">{job.job_name ?? job.user_input.protein_name}</div>
            )}
            <div className="job-created-at">
              {formatDate(job.created_date_time_ts)}
              <Divider type="vertical" />
              {formatTime(job.created_date_time_ts)}
            </div>
          </div>
          <div className="job-status-main">
            <div className={`job-status${job?.status.toLowerCase() === 'completed' ? ' completed' : job?.status.toLowerCase() === 'rejected' ? ' failed' : ' inprogress'}`}>{job?.status === 'REJECTED' ? 'Failed' : job?.status === 'PENDING' ? 'In Progress' : ''}</div>
          </div>
          <div className="job-name-edit-btn">
            <Button className="rename-edit-icon" onClick={() => setEditing({ isEditing: true, editingId: index })} />
          </div>
        </div>
      ))}
    </div>
  );

  const jobDetails = (job) => {
    if (job?.user_input?.service === 'CCA') {
      return (
        <ClinicalAnalysisWorkspaceDetails job={job} />
      );
    }
    return (

      <TopIndicationsWorkspaceDetails job={job} />
    );
  };

  const serviceDropdowns = () => (
    <div className="service-dropdowns">
      <div role="presentation" className={`service-dropdown ${(currentService[0] === 1 ? 'open' : 'close')}`} onClick={() => setCurrentService([1, 0, 0])}>
        <div className="service-dropdown-title">
          Prioritize Indications
        </div>
        { currentService[0] === 1 && (
        <div className="service-dropdown-content">
          {jobsList(serviceJobsList.topIndications)}
        </div>
        )}
      </div>
      <div role="presentation" className={`service-dropdown ${(currentService[1] === 1 ? 'open' : 'close')}`} onClick={() => setCurrentService([0, 1, 0])}>
        <div className="service-dropdown-title">
          Clinical Analysis
        </div>
        { currentService[1] === 1 && (
        <div className="service-dropdown-content">
          {jobsList(serviceJobsList.CCA)}
        </div>
        )}
      </div>
    </div>
  );

  return (
    <div className="main-page main-page-modules">
      <Header className="header-home iep-header" logo="iep-logo" />
      <Loader loading={(topIndicationsJobsResponse.loading || clinicalAnalysisJobsResponse.loading) && isLoading}>
        <div className="ct-home-page my-workspace-page">
          <div className="my-workspace-main">
            <div className="jobs-list-view">
              <div className="btns-row">
                { !searchAndSort.isSearch && (
                <div className="back-btn">
                  <Button
                    onClick={() => {
                      navigate(terminalsPath.metad3_iep);
                      dispatch(iepWorkspaceStatusResetAction({}));
                    }}
                    className="back-btn-icon"
                  >
                    Back
                  </Button>
                </div>
                )}
                <div className="grouped-btns">
                  { !searchAndSort.isSearch && (
                  <div
                    role="presentation"
                    className="search-btn"
                    onClick={() => {
                      setSearchAndSort((prev) => ({
                        ...prev,
                        isSearch: true,
                      }));
                    }}
                  >
                    <div className="search-btn-icon" />
                  </div>
                  )}
                  { searchAndSort.isSearch && (
                  <Input
                    placeholder="Search Job"
                    value={searchText}
                    allowClear={{
                      clearIcon: <CloseOutlined onClick={() => {
                        setSearchText('');
                        setSearchAndSort((prev) => ({
                          ...prev,
                          isSearch: false,
                          searchValue: '',
                        }));
                      }}
                      />,
                    }}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                    onPressEnter={(e) => {
                      setSearchAndSort((prev) => ({
                        ...prev,
                        searchValue: e.target.value,
                      }));
                    }}
                  />
                  )}
                  <div
                    role="presentation"
                    className="sort-btn"
                    onClick={() => {
                      if (searchAndSort.sortValue === 'asc') {
                        setSearchAndSort((prev) => ({
                          ...prev,
                          sortValue: 'desc',
                        }));
                      } else {
                        setSearchAndSort((prev) => ({
                          ...prev,
                          sortValue: 'asc',
                        }));
                      }
                    }}
                  >
                    <div className="sort-btn-icon" />
                  </div>
                </div>
              </div>
              {serviceDropdowns()}
            </div>

            { currentJob && <div className="job-details-view">{currentJob?.user_input && jobDetails(currentJob)}</div>}
          </div>
        </div>
      </Loader>
    </div>
  );
}

export default MyWorkspacePageIEP;
