import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../utils';

// drug-indications list
const HCTP_DRUG_INDICATIONS = 'HCTP_DRUG_INDICATIONS';
const HCTP_DRUG_INDICATIONS_SUCCESS = 'HCTP_DRUG_INDICATIONS_SUCCESS';
const HCTP_DRUG_INDICATIONS_FAILURE = 'HCTP_DRUG_INDICATIONS_FAILURE';
const HCTP_DRUG_INDICATIONS_RESET = 'HCTP_DRUG_INDICATIONS_RESET';

export const hctpDrugIndicationsAction = createAction(HCTP_DRUG_INDICATIONS);
export const hctpDrugIndicationsResetAction = createAction(HCTP_DRUG_INDICATIONS_RESET);

const hctpDrugIndicationsSuccess = createAction(HCTP_DRUG_INDICATIONS_SUCCESS);
const hctpDrugIndicationsFailure = createAction(HCTP_DRUG_INDICATIONS_FAILURE);

export const hctpDrugIndicationsEpic = (actions$) => actions$.pipe(
  ofType(HCTP_DRUG_INDICATIONS),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_ctp}/getHCTPData?${action.payload}`, 'get')).pipe(
    map((res) => hctpDrugIndicationsSuccess(res)),
    catchError((err) => of(hctpDrugIndicationsFailure(err))),
  )),
);

export const hctpDrugIndicationsReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(hctpDrugIndicationsAction, (state) => {
      state.loading = true;
    })
      .addCase(hctpDrugIndicationsSuccess, (state, action) => {
        state.data = { ...action.payload.response };
        state.loading = false;
        state.flag = true;
      })
      .addCase(hctpDrugIndicationsFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(hctpDrugIndicationsResetAction, (state) => {
        state.data = [];
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);
