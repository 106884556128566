import React from 'react';
import PropTypes from 'prop-types';
import { Progress, Tooltip } from 'antd';
import PropertiesContainer from '../../../components/AdmetProperties/ParameterValues/PropertiesComponent';
import AbsorptionValuesComponent from '../../../components/AdmetProperties/ParameterValues/AbsorptionValuesComponent';
import DistributionandExcretionComponent from '../../../components/AdmetProperties/ParameterValues/DistributionAndExcretionComponent';
import MetabolismComponent from '../../../components/AdmetProperties/ParameterValues/MetabolismComponent';
import ToxicityComponent from '../../../components/AdmetProperties/ParameterValues/ToxicityComponent';

function ParameterValuesContainer({ admetData, isThreeCol }) {
  const decimalText = (text) => {
    const parsedText = parseFloat(text);
    const textToRender = !Number.isInteger(parsedText) ? `${parsedText.toFixed(2)}` : parsedText;
    return textToRender;
  };

  const createItem = ({
    text, tooltip, isHeader, isGraph, value, isValue, unit,
  }) => {
    let tooltipDiv = null;
    if (tooltip !== undefined) {
      tooltipDiv = <Tooltip placement="top" title={tooltip}><span className="info-add-icon" /></Tooltip>;
    }

    if (isHeader) {
      return (
        <div className="properties-header">
          <div className="properties-header-text">{text}</div>
          {tooltipDiv}
        </div>
      );
    }
    if (isGraph) {
      return (
        <div className="properties-probability" style={{ width: '100px' }}>
          <Tooltip placement="top" title={value}>
            <Progress percent={value * 100} showInfo={false} strokeColor="#3B76EFCC" />
          </Tooltip>
        </div>
      );
    }

    return (
      <div className="properties-item">
        <div className="properties-item-text"><Tooltip title={isValue ? `${decimalText(text)} ${unit !== undefined ? unit : ''}` : text}>{isValue ? `${decimalText(text)} ${unit !== undefined ? unit : ''}` : text}</Tooltip></div>
        {tooltipDiv}
      </div>
    );
  };
  return (
    <div className={`parameter-values-container ${!isThreeCol ? 'two-col' : 'three-col'}`}>
      <PropertiesContainer admetData={admetData} createItem={createItem} />
      <AbsorptionValuesComponent admetData={admetData} createItem={createItem} />
      <DistributionandExcretionComponent admetData={admetData} createItem={createItem} />
      {admetData && <MetabolismComponent admetData={admetData} createItem={createItem} />}
      {admetData && <ToxicityComponent admetData={admetData} createItem={createItem} />}
    </div>
  );
}
ParameterValuesContainer.propTypes = {
  admetData: PropTypes.objectOf.isRequired,
  isThreeCol: PropTypes.bool.isRequired,
};
export default ParameterValuesContainer;
