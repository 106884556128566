import {
  Button, Divider, Input,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CloseOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import Rcookie from 'react-cookies';
import queryString from 'query-string';
import axios from 'axios';
import Loader from '../../../../common/components/Loader';
import Header from '../../../../common/components/Header';
import { getCookie } from '../../../../utils';
import { terminalsPath } from '../../../../app/components/Card/data';
import Mixpanel from '../../../../utils/mixpanel';
import { getValidationAutomationWorkspaceStatusAction, getValidationAutomationWorkspaceStatusResetAction, utilitiesUpdateWorkspaceStatusAction } from './logic';
import useAppSelector from '../../../../utils/redux-hook';
import { getValidationAutomationOutputResetAction } from '../../container/ValidationAutomation/logic';

function MyWorkspacePageUtilities() {
  const dispatch = useDispatch();
  const getValidationAutomationResponse = useAppSelector((state) => state.getValidationAutomationResponse);
  const [searchParams] = useSearchParams();
  const service = searchParams.get('service');
  const [searchText, setSearchText] = useState('');
  const navigate = useNavigate();
  //   const [searchQuery, setSearchQuery] = useState('');
  const [editing, setEditing] = useState({
    isEditing: false,
    editingId: '',
    editingName: '',
  });
  const [currentJob, setCurrentJob] = useState({});
  const userEmailId = getCookie('usersEmailId');
  const [isLoading, setIsLoading] = useState(true);
  const [currentService, setCurrentService] = useState([1, 0, 0]);
  const [serviceJobsList, setServiceJobsList] = useState({
    data_validation: [],
  });
  const [searchAndSort, setSearchAndSort] = useState({
    isSearch: false,
    isSort: false,
    searchValue: '',
    sortValue: 'desc',
  });
  const [isPendingDownload, setIsPendingDownload] = useState(false);
  useEffect(() => {
    console.log(isLoading);
    const request = {
      filters: JSON.stringify({ module_id: 'metad3_utilities' }),
      from: 1,
      size: 10,
      searchQuery: (searchAndSort.searchValue.length > 0) ? searchAndSort.searchValue : undefined,
      sort_order: searchAndSort.sortValue,
    };
    dispatch(getValidationAutomationWorkspaceStatusAction(queryString.stringify(request)));
  }, []);
  useEffect(() => {
    if (getValidationAutomationResponse.data && getValidationAutomationResponse.flag) {
      console.log('getValidationAutomationResponse', getValidationAutomationResponse?.data[0]);
      setServiceJobsList({
        data_validation: getValidationAutomationResponse?.data,
      });
      setCurrentJob(getValidationAutomationResponse?.data[0]);
    }
  }, [getValidationAutomationResponse.data]);
  const [validationAutomationRequest, setValidationAutomationRequest] = useState({
    filters: JSON.stringify({ module_id: 'metad3_utilities' }),
    from: 1,
    size: 10,
    searchQuery: (searchAndSort.searchValue.length > 0) ? searchAndSort.searchValue : undefined,
    sort_order: searchAndSort.sortValue,
  });

  useEffect(() => {
    setIsLoading(false);
    switch (service) {
      default:
        setCurrentService([1, 0, 0]);
    }
  }, []);

  useEffect(() => {
    dispatch(getValidationAutomationOutputResetAction(queryString.stringify(validationAutomationRequest)));
    setIsLoading(false);
    const intervalId = setInterval(() => {
      if (getValidationAutomationResponse.data) {
        dispatch(getValidationAutomationWorkspaceStatusAction(queryString.stringify(validationAutomationRequest)));
      }
    }, 10000);
    return () => clearInterval(intervalId);
  }, [validationAutomationRequest]);

  useEffect(() => {
    if (getValidationAutomationResponse.flag) {
      if (!currentJob?.status && (service === 'LEAD_GENERATION' || !service)) {
        setCurrentJob(getValidationAutomationResponse.data[0]);
      }
      setServiceJobsList((prev) => ({
        ...prev,
        lead_generation: getValidationAutomationResponse.data,
      }));
    }
  }, [getValidationAutomationResponse]);

  useEffect(() => {
    setValidationAutomationRequest((prev) => ({
      ...prev,
      from: 1,
      size: 20,
      searchQuery: (searchAndSort.searchValue.length > 0) ? searchAndSort.searchValue : undefined,
      sort_order: searchAndSort.sortValue,
    }));
    dispatch(getValidationAutomationWorkspaceStatusAction(queryString.stringify({
      filters: JSON.stringify({ module_id: 'metad3_utilities' }),
      from: 1,
      size: 20,
      searchQuery: (searchAndSort.searchValue.length > 0) ? searchAndSort.searchValue : undefined,
      sort_order: searchAndSort.sortValue,
    })));
  }, [searchAndSort]);

  const onChangedJob = (job) => {
    setCurrentJob(job);
    Mixpanel.track('Utilities Job Selected', {
      action: 'Utilities clicked on a new job',
      job_name: job?.job_name,
      service: job?.user_input?.service,
      module: 'Utilities',
    });
  };

  const formatDate = (epoch) => {
    const date = new Date(epoch * 1000);

    const day = date.getDate();
    const month = date.toLocaleString('en-US', { month: 'short' });

    return `${day} ${month}`;
  };
  const formatTime = (epoch) => {
    const date = new Date(epoch * 1000);

    const time = date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });

    return time;
  };
  const onEditName = (job) => {
    const request = {
      job_id: job?.job_id,
      job_name: editing.editingName,
    };
    dispatch(utilitiesUpdateWorkspaceStatusAction(queryString.stringify(request)));
    setEditing({ isEditing: false, editingId: '', editingName: '' });
    Mixpanel.track('Utilities Job Updated', {
      action: 'Utilities job name updated',
      job_name: job?.job_name,
      service: job?.user_input?.service,
      module: 'Utilities',
    });
  };

  const downloadXLSFile = async (jobId) => {
    setIsPendingDownload(true);
    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`) ? Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`) : '', 'Content-Type': 'blob', 'Access-Control-Allow-Origin': `${process.env.applicationUrl}` };
    const apiUrl = `${process.env.apiUrl_utilities}/inputXlsxDownload?job_id=${jobId}`;

    try {
      const response = await axios({
        method: 'GET',
        url: apiUrl,
        responseType: 'arraybuffer',
        headers,
      });
      setIsPendingDownload(false);
      const outputFilename = 'validation.xlsx';
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      throw Error(error);
    }
    Mixpanel.track('Validation Automation Input File Downloaded', {
      action: 'Validation Automation input file download button clicked',
      file_type: '.xlsx',
      module: 'Utilities',
    });
  };

  const onViewOutput = (job) => {
    if (job?.status.toLowerCase() === 'completed') {
      navigate(`${terminalsPath.metad3_utilities_output}?jobId=${job?.job_id}&service=${job?.user_input.service}`);
      Mixpanel.track('Utilities Viewed Output', {
        action: 'Utilities View output button clicked',
        job_name: job?.job_name,
        service: job?.user_input?.service,
        smiles: job?.user_input?.molecular_smiles,
        module: 'Utilities',
      });
    } else {
      navigate(terminalsPath.metad3_utilities);
      Mixpanel.track('Utilities Resubmit Output Button Clicked', {
        action: 'Utilities Resubmit Output Button Clicked',
        job_name: job?.job_name,
        service: job?.user_input?.service,
        smiles: job?.user_input?.molecular_smiles,
        module: 'Utilities',
      });
    }
  };

  const jobsList = (data1) => (
    <div className="jobs-list">
      {data1?.map((job, index) => (
        <div className={`job-item${job?.job_id === currentJob?.job_id ? ' active' : ''}${editing.isEditing && editing.editingId === index ? ' input-edit' : ''}`} key={job?.job_id} style={{ display: 'flex', cursor: 'pointer' }} onClick={() => onChangedJob(job)} aria-hidden>
          <div className="job-info">
            {editing.isEditing && editing.editingId === index ? (
              <div>
                <Input
                  placeholder="Job Name"
                  value={editing.editingName ?? job?.job_name ?? job?.module_id}
                  onChange={(e) => setEditing({ ...editing, editingName: e.target.value })}
                  onPressEnter={() => onEditName(job)}
                />
              </div>
            ) : (
              <div className="job-title">{job?.job_name ?? job?.module_id}</div>
            )}
            <div className="job-created-at">
              {formatDate(job?.created_date_time_ts)}
              <Divider type="vertical" />
              {formatTime(job?.created_date_time_ts)}
            </div>
          </div>
          <div className="job-status-main">
            <div className={`job-status${job?.status.toLowerCase() === 'completed' ? ' completed' : job?.status.toLowerCase() === 'rejected' ? ' failed' : ' inprogress'}`}>{job?.status === 'REJECTED' ? 'Failed' : job?.status === 'PENDING' ? 'In Progress' : ''}</div>
          </div>
          <div className="job-name-edit-btn">
            <Button className="rename-edit-icon" onClick={() => setEditing({ isEditing: true, editingId: index })} />
          </div>
        </div>
      ))}
    </div>
  );

  const inputDetails = (job) => (
    <div className="job-details-main">
      {job?.status.toLowerCase() !== 'completed' && <div className={`job-pending-warning ${job?.status.toLowerCase() === 'pending' ? ' pending' : 'failed'}`}>{`${job?.status.toLowerCase() === 'pending' ? `Output generation is in progress. Once it is generated, we will notify you over your email, “${userEmailId}”` : 'Due to a technical error, the output generation failed. Please click the “Resubmit Input” button to try again.'}`}</div>}
      <div className="job-details-title">Input Details</div>
      <div className="job-details-content">
        <div className="job-name-row" style={{ display: 'flex' }}>
          <div className="job-name-group" style={{ display: 'flex' }}>
            <div className="job-name-group-title">{job?.job_name || job?.module_id}</div>
            <div className="job-created-at">
              {formatDate(job?.created_date_time_ts)}
              <Divider type="vertical" />
              {formatTime(job?.created_date_time_ts)}
            </div>
            {/* <div className="study-tag">Optimize Study Protocols</div> */}
          </div>
          <div className="view-output-btn">
            <Button
              disabled={job?.status.toLowerCase() === 'pending'}
              onClick={() => {
                onViewOutput(job);
                // dispatch(sarLeadGenerationWorkspaceStatusResetAction({}));
              }}
            >
              {`${job?.status.toLowerCase() === 'rejected' ? 'Resubmit Input' : 'View Output'}`}
            </Button>
          </div>
        </div>
        {job?.description && (
          <div className="description-main">
            <div className="title">Description</div>
            <div className="content">{job?.description}</div>
          </div>
        )}
        <div className="drug-indication-table">
          {/* <HCTPTableView data={dummyData} /> */}
          <div className="drug-indication-pairs-main">
            <div className="title">
              Input Details
            </div>
            <div className="input-file-container">
              <div className="input-details-title">Input file for validation</div>
              <div className="input-file-download-text" onClick={() => downloadXLSFile(job?.job_id)} disabled={isPendingDownload} aria-hidden>Validation file.xlsx</div>
            </div>
            <div className="input-prompt-container">
              <div className="input-prompt-title">Prompt for validation</div>
              <div className="input-prompt-text">{job?.user_input?.prompt}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  //   const loadMoreData = (loadService) => {
  //     console.log('inside');
  //     if (loadService === 0) {
  //       setLeadGenerationRequest((prev) => {
  //         const { size } = prev;
  //         return {
  //           ...prev,
  //           size: size + 10,
  //         };
  //       });
  //     }
  //     if (loadService === 1) {
  //       setAdmetPropertiesRequest((prev) => {
  //         const { size } = prev;
  //         return {
  //           ...prev,
  //           size: size + 10,
  //         };
  //       });
  //     }
  //   };

  const serviceDropdowns = () => (
    <div className="service-dropdowns">
      <div role="presentation" className={`service-dropdown ${(currentService[0] === 1 ? 'open' : 'close')}`} onClick={() => setCurrentService([1, 0, 0])}>
        <div className="service-dropdown-title">
          Validation Automation
        </div>
        { currentService[0] === 1 && (
          <div className="service-dropdown-content">
            {console.log('serviceJobsList.data_validation', serviceJobsList.data_validation)}
            {serviceJobsList.data_validation && jobsList(serviceJobsList.data_validation)}
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div className="main-page main-page-modules">
      <Header className="header-home adc-header" logo="utilities-logo" width={500} />
      <Loader loading={false}>
        <div className="ct-home-page my-workspace-page">
          <div className="my-workspace-main">
            <div className="jobs-list-view">
              <div className="btns-row">
                {!searchAndSort.isSearch && (
                <div className="back-btn">
                  <Button
                    onClick={() => {
                      navigate(terminalsPath.metad3_utilities);
                      dispatch(getValidationAutomationWorkspaceStatusResetAction({}));
                      Mixpanel.track('Utilities Back Button Clicked', {
                        action: 'Utilities Back Button Clicked from Utilities workspace',
                        module: 'Utilities',
                      });
                    }}
                    className="back-btn-icon"
                  >
                    Back
                  </Button>
                </div>
                )}
                <div className="grouped-btns">
                  {!searchAndSort.isSearch && (
                  <div
                    role="presentation"
                    className="search-btn"
                    onClick={() => {
                      setSearchAndSort((prev) => ({
                        ...prev,
                        isSearch: true,
                      }));
                    }}
                  >
                    <div className="search-btn-icon" />
                  </div>
                  )}
                  {searchAndSort.isSearch && (
                  <Input
                    placeholder="Search Job"
                    value={searchText}
                    allowClear={{
                      clearIcon: <CloseOutlined onClick={() => {
                        setSearchText('');
                        setSearchAndSort((prev) => ({
                          ...prev,
                          isSearch: false,
                          searchValue: '',
                        }));
                      }}
                      />,
                    }}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                    onPressEnter={(e) => {
                      setSearchAndSort((prev) => ({
                        ...prev,
                        searchValue: e.target.value,
                      }));
                      Mixpanel.track('Utilities Job Searched', {
                        action: 'job searched on Utilities workspace',
                        query: e.target.value,
                        module: 'Utilities',
                      });
                    }}
                  />
                  )}
                  <div
                    role="presentation"
                    className="sort-btn"
                    onClick={() => {
                      Mixpanel.track('Utilities Jobs Sorted', {
                        action: 'job sorted on Utilities workspace',
                        sort: searchAndSort.sortValue,
                        module: 'Utilities',
                      });
                      if (searchAndSort.sortValue === 'asc') {
                        setSearchAndSort((prev) => ({
                          ...prev,
                          sortValue: 'desc',
                        }));
                      } else {
                        setSearchAndSort((prev) => ({
                          ...prev,
                          sortValue: 'asc',
                        }));
                      }
                      Mixpanel.track('Utilities Job Sorted', {
                        action: 'job sorted on Utilities workspace',
                        sort: searchAndSort.sortValue,
                        module: 'Utilities',
                      });
                    }}
                  >
                    <div className="sort-btn-icon" />
                  </div>
                </div>
              </div>
              {serviceDropdowns()}
            </div>
            {currentJob && <div className="job-details-view">{currentJob?.user_input && inputDetails(currentJob)}</div>}
          </div>
        </div>
      </Loader>
    </div>
  );
}

export default MyWorkspacePageUtilities;
