/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Radio, Space } from 'antd';
import EndpointsGraph from './BubbleChart2';
import tooltipData from '../data';
// import MyLineChart from './LinearGauge';
// import LinearGauge2 from './LinearGauge/index1';

function ToxicityComponent({ admetData, createItem }) {
  const [isGraph, setIsGraph] = useState(false);
  const [graphData, setGraphData] = useState(undefined);

  useEffect(() => {
    if (admetData) {
      const arr = [
        {
          key: 'hERG Block',
          value: admetData?.herg_confidence,
        },
        {
          key: 'Clinical Toxicity',
          value: admetData?.Clinical_Toxicity,
        },
        {
          key: 'Mutagenicity',
          value: admetData?.Mutagenicity,
        },
        {
          key: 'DILI',
          value: admetData?.dili_confidence,
        },
        {
          key: 'Carcinogenicity',
          value: admetData?.Carcinogenicity,
        },
        {
          key: 'Acute Toxicity LD50',
          value: admetData?.Acute_Toxicity_LD50,
        },
        {
          key: 'Skin Reaction',
          value: admetData?.Skin_Reaction,
        },
        {
          key: 'NR-AR',
          value: admetData['NR-AR'],
        },
        {
          key: 'NR-AR-AhR',
          value: admetData['NR-AR-AhR'],
        },
        {
          key: 'NR-AR-LBD',
          value: admetData['NR-AR-LBD'],
        },
        {
          key: 'NR-Aromatase',
          value: admetData['NR-Aromatase'],
        },
        {
          key: 'NR-ER',
          value: admetData['NR-ER'],
        },
        {
          key: 'NR-ER-LBD',
          value: admetData['NR-ER-LBD'],
        },
        {
          key: 'NR-PPAR-gamma',
          value: admetData['NR-PPAR-gamma'],
        },
        {
          key: 'SR-ARE',
          value: admetData['SR-ARE'],
        },
        {
          key: 'SR-ATAD5',
          value: admetData['SR-ATAD5'],
        },
        {
          key: 'SR-HSE',
          value: admetData['SR-HSE'],
        },
        {
          key: 'SR-MMP',
          value: admetData['SR-MMP'],
        },
        {
          key: 'SR-p53',
          value: admetData['SR-p53'],
        },
      ];
      setGraphData(arr);
    } else {
      admetData = {};
    }
  }, [admetData]);

  return (
    <div className="container-layout container-layout-fullview">
      <div className="container-layout-card">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className="container-layout-card-title">Toxicity Component</div>
          <div className="metabolism-com-toggle">
            <Space>
              <Radio.Group value={isGraph} onChange={(e) => setIsGraph(e.target.value)}>
                <Radio.Button value={false}><div className="icon-toggle" /></Radio.Button>
                <Radio.Button value><div className="icon-toggle" /></Radio.Button>
              </Radio.Group>
            </Space>
          </div>
        </div>
        {!isGraph ? (
          <div className="properties-item-columns" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className="properties-items-col1">
              <div className="properties-items container-layout-card-item">
                {createItem({ text: 'Properties', isHeader: true })}
                {createItem({ text: 'Probability', tooltip: tooltipData.Probability, isHeader: true })}
              </div>
              <div className="properties-items container-layout-card-item">
                {createItem({ text: 'hERG Block', tooltip: tooltipData['hERG Block'] })}
                {createItem({ isGraph: true, value: admetData?.herg_confidence })}
              </div>
              <div className="properties-items container-layout-card-item">
                {createItem({ text: 'Clinical Toxicity', tooltip: tooltipData['Clinical Toxicity'] })}
                {createItem({ isGraph: true, value: admetData?.ClinTox })}
              </div>
              <div className="properties-items container-layout-card-item">
                {createItem({ text: 'Mutagenicity', tooltip: tooltipData.Mutagenicity })}
                {createItem({ isGraph: true, value: admetData?.ames_confidence })}
              </div>
              <div className="properties-items container-layout-card-item">
                {createItem({ text: 'DILI', tooltip: tooltipData.DILI })}
                {createItem({ isGraph: true, value: admetData?.dili_confidence })}
              </div>
              <div className="properties-items container-layout-card-item">
                {createItem({ text: 'Carcinogenicity', tooltip: tooltipData.Carcinogenicity })}
                {createItem({ isGraph: true, value: admetData?.Carcinogens_Lagunin })}
              </div>
              <div className="properties-items container-layout-card-item">
                {createItem({ text: 'Acute Toxicity LD50', tooltip: tooltipData['Acute Toxicity LD50'] })}
                {createItem({ isGraph: true, value: admetData?.LD50_Zhu })}
              </div>
              <div className="properties-items container-layout-card-item">
                {createItem({ text: 'Skin Reaction', tooltip: tooltipData['Skin Reaction'] })}
                {createItem({ isGraph: true, value: admetData?.Skin_Reaction })}
              </div>
              <div className="properties-items container-layout-card-item">
                {createItem({ text: 'NR-AR', tooltip: tooltipData['NR-AR'] })}
                {createItem({ isGraph: true, value: admetData['NR-AR'] ?? 0 })}
              </div>
              <div className="properties-items container-layout-card-item">
                {createItem({ text: 'NR-AR-LBD', tooltip: tooltipData['NR-AR-LBD'] })}
                {createItem({ isGraph: true, value: admetData['NR-AR-LBD'] ?? 0 })}
              </div>
              <div className="properties-items container-layout-card-item">
                {createItem({ text: 'NR-AhR', tooltip: tooltipData['NR-AhR'] })}
                {createItem({ isGraph: true, value: admetData['NR-AhR'] ?? 0 })}
              </div>
            </div>
            <div className="properties-items-col1">
              <div className="properties-items container-layout-card-item">
                {createItem({ text: 'Properties', isHeader: true })}
                {createItem({ text: 'Probability', tooltip: tooltipData.Probability, isHeader: true })}
              </div>
              <div className="properties-items container-layout-card-item">
                {createItem({ text: 'NR-Aromatase', tooltip: tooltipData['NR-Aromatase'] })}
                {createItem({ isGraph: true, value: admetData['NR-Aromatase'] ?? 0 })}
              </div>
              <div className="properties-items container-layout-card-item">
                {createItem({ text: 'NR-ER', tooltip: tooltipData['NR-ER'] })}
                {createItem({ isGraph: true, value: admetData['NR-ER'] ?? 0 })}
              </div>
              <div className="properties-items container-layout-card-item">
                {createItem({ text: 'NR-ER-LBD', tooltip: tooltipData['NR-ER-LBD'] })}
                {createItem({ isGraph: true, value: admetData['NR-ER-LBD'] ?? 0 })}
              </div>
              <div className="properties-items container-layout-card-item">
                {createItem({ text: 'NR-PPAR-gamma', tooltip: tooltipData['NR-PPAR-gamma'] })}
                {createItem({ isGraph: true, value: admetData['NR-PPAR-gamma'] ?? 0 })}
              </div>
              <div className="properties-items container-layout-card-item">
                {createItem({ text: 'SR-ARE', tooltip: tooltipData['SR-ARE'] })}
                {createItem({ isGraph: true, value: admetData['SR-ARE'] ?? 0 })}
              </div>
              <div className="properties-items container-layout-card-item">
                {createItem({ text: 'SR-ATAD5', tooltip: tooltipData['SR-ATAD5'] })}
                {createItem({ isGraph: true, value: admetData['SR-ATAD5'] ?? 0 })}
              </div>
              <div className="properties-items container-layout-card-item">
                {createItem({ text: 'SR-HSE', tooltip: tooltipData['SR-HSE'] })}
                {createItem({ isGraph: true, value: admetData['SR-HSE'] ?? 0 })}
              </div>
              <div className="properties-items container-layout-card-item">
                {createItem({ text: 'SR-MMP', tooltip: tooltipData['SR-MMP'] })}
                {createItem({ isGraph: true, value: admetData['SR-MMP'] ?? 0 })}
              </div>
              <div className="properties-items container-layout-card-item">
                {createItem({ text: 'SR-p53', tooltip: tooltipData['SR-p53'] })}
                {createItem({ isGraph: true, value: admetData['SR-p53'] ?? 0 })}
              </div>
            </div>
          </div>
        ) : (
          graphData !== undefined && <EndpointsGraph dData={graphData} />
        )}
      </div>
    </div>
  );
}
ToxicityComponent.propTypes = {
  admetData: PropTypes.objectOf.isRequired,
  createItem: PropTypes.func.isRequired,
};
export default ToxicityComponent;
