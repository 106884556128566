import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import CustomPieChart from '../../container/Graph/PieChart/PieChart';

function PosComponent({ item, handleClick, isDetails }) {
  useEffect(() => {
    console.log('item --->', item);
  }, [item]);
  return (
    <div className="pos-component" onClick={() => handleClick(item)} style={{ cursor: isDetails ? 'default' : 'pointer' }} aria-hidden>
      {item && (
      <div className="ctp-content-upper" style={{ display: 'flex' }}>
        <div className="card-content-left-part">
          <div className="ctp-content-upper-heading">Probability of Success</div>
          <div className="ctp-content-upper-card-content-ctp-value">
            <div className="ctp-content-upper-card-content-ctp-value-percentage">
              <CustomPieChart score={parseFloat(item?.pos?.probability).toFixed(2)} status={item?.pos?.prediction} />
              <div className="gauge-content">{item?.pos?.prediction}</div>
            </div>
          </div>
        </div>
        <div className="card-content-ctp-main">
          <div className="ctp-content-upper-card-content-ctp-content">
            <div className="ctp-content-upper-blocks">
              <div className="ctp-content-upper-blocks-values-id">{item?.pos?.trial}</div>
              <div className="ctp-content-upper-blocks-values-phase">
                {`${item?.pos?.phase}`}
                {item?.pos?.trial_status ? ` - ${item?.pos?.trial_status}` : ''}
              </div>
            </div>

            <div className="ctp-content-upper-blocks">
              <div className="ctp-content-upper-blocks-values-title">{item?.pos?.title}</div>
            </div>
            <div className="ctp-content-upper-blocks">
              <div className="ctp-content-upper-blocks-keys">Condition:</div>
              <div className="ctp-content-upper-blocks-values">{item?.pos?.condition}</div>
            </div>
            <div className="ctp-content-upper-blocks">
              <div className="ctp-content-upper-blocks-keys">Start Date:</div>
              <div className="ctp-content-upper-blocks-values">{`${item?.pos?.start_date} | ${item?.pos?.anticipated_enrollment} Anticipated Enrollment`}</div>
            </div>
            <div className="ctp-content-upper-blocks ctp-content-upper-blocks-dual">
              <div className="ctp-content-upper-blocks-keys">(Category) Primary Outcome:</div>
              <div className="ctp-content-upper-blocks-values">
                <div className="ctp-content-upper-blocks-values-box">
                  <div className="ctp-content-upper-blocks-values-right">
                    <Tooltip placement="topLeft" title={`(${item?.pos?.primary_outcome?.predicted_labels})`}>{`(${item?.pos?.primary_outcome?.predicted_labels})`}</Tooltip>
                  </div>
                  <div className="ctp-content-upper-blocks-values-left">
                    <div className="ctp-content-upper-blocks-values-left-text">
                      <Tooltip placement="topLeft" title={item?.pos?.primary_outcome?.measure}>{item?.pos?.primary_outcome?.measure}</Tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      )}
    </div>
  );
}

PosComponent.propTypes = {
  item: PropTypes.objectOf.isRequired,
  handleClick: PropTypes.func.isRequired,
  isDetails: PropTypes.bool.isRequired,
};

export default PosComponent;
