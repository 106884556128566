import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../../utils';

const GET_PATENT_TIMELINE = 'GET_PATENT_TIMELINE';
const GET_PATENT_TIMELINE_SUCCESS = 'GET_PATENT_TIMELINE_SUCCESS';
const GET_PATENT_TIMELINE_FAILURE = 'GET_PATENT_TIMELINE_FAILURE';

export const getDRPatentTimelineAction = createAction(GET_PATENT_TIMELINE);
const getDRPatentTimelineSuccess = createAction(GET_PATENT_TIMELINE_SUCCESS);
const getDRPatentTimelineFailure = createAction(GET_PATENT_TIMELINE_FAILURE);

export const getDRPatentTimelineEpic = (actions$) => actions$.pipe(
  ofType(GET_PATENT_TIMELINE),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_drug_reform}/getPatentTimelineData?${action.payload}`, 'get')).pipe(
    map((res) => getDRPatentTimelineSuccess(res)),
    catchError((err) => of(getDRPatentTimelineFailure(err))),
  )),
);

export const getDRPatentTimelineReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(getDRPatentTimelineAction, (state) => {
      state.loading = true;
    })
      .addCase(getDRPatentTimelineSuccess, (state, action) => {
        state.data = [...action.payload.response.data];
        state.loading = false;
        state.flag = true;
      })
      .addCase(getDRPatentTimelineFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);
