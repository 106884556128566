import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import useAppSelector from '../../../../utils/redux-hook';
import { isReadSAROutputAction } from './logic';
import { checkSARStatusResetAction } from '../SARInputPage/logic';
import LeadDetailsPage from '../LeadDetailsPage';
import Loader from '../../../../common/components/Loader';
import AdmetPropertiesPage from '../AdmetPropertiesPage';
import Header from '../../../../common/components/Header';
import { terminalsPath } from '../../../../app/components/Card/data';

function SAROutputPage() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const service = searchParams.get('service');
  const services = ['LEAD_GENERATION', 'BIOACTIVITY', 'ADMET_PROPERTIES'];
  const [job, setJob] = useState('');
  const checkStatusResponse = useAppSelector((state) => state.checkSARStatusResponse);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(checkSARStatusResetAction());
  }, []);

  useEffect(() => {
    if (checkStatusResponse.flag && checkStatusResponse.data) {
      setJob(checkStatusResponse.data[0]?.job_id);
      const request = {
        job_id: checkStatusResponse.data[0]?.job_id,
        is_read: true,
      };
      dispatch(isReadSAROutputAction(queryString.stringify(request)));
    }
    console.log(job);
  }, [checkStatusResponse]);

  const selectOutputPage = () => {
    if (service === services[0]) {
      return <LeadDetailsPage />;
    }
    if (service === services[2]) {
      return <AdmetPropertiesPage />;
    }
    if (service === services[1]) {
      return <div />;
    }
    return <div>Into second service</div>;
  };

  return (
    <div className="main-page main-page-modules">
      <Header className="header-home" logo="sar" width={370} isWorkspace modulePath={terminalsPath.metad3_sar_denovo_workspace} />
      <Loader loading={checkStatusResponse.loading}>
        {selectOutputPage()}
      </Loader>
    </div>
  );
}

export default SAROutputPage;
