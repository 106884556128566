import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../utils';

// get indication details
const GET_MARKET_ANALYSIS_OUTPUT = 'GET_MARKET_ANALYSIS_OUTPUT';
const GET_MARKET_ANALYSIS_OUTPUT_SUCCESS = 'GET_MARKET_ANALYSIS_OUTPUT_SUCCESS';
const GET_MARKET_ANALYSIS_OUTPUT_FAILURE = 'GET_MARKET_ANALYSIS_OUTPUT_FAILURE';
const GET_MORE_MARKET_ANALYSIS_OUTPUT = 'GET_MORE_MARKET_ANALYSIS_OUTPUT';
const GET_MORE_MARKET_ANALYSIS_OUTPUT_SUCCESS = 'GET_MORE_MARKET_ANALYSIS_OUTPUT_SUCCESS';

export const getMarketAnalysisOutputAction = createAction(GET_MARKET_ANALYSIS_OUTPUT);

const getMarketAnalysisOutputSuccess = createAction(GET_MARKET_ANALYSIS_OUTPUT_SUCCESS);

export const getMoreMarketAnalysisOutputAction = createAction(GET_MORE_MARKET_ANALYSIS_OUTPUT);

const getMoreMarketAnalysisOutputSuccess = createAction(GET_MORE_MARKET_ANALYSIS_OUTPUT_SUCCESS);

const getMarketAnalysisOutputFailure = createAction(GET_MARKET_ANALYSIS_OUTPUT_FAILURE);

export const getMarketAnalysisOutputEpic = (actions$) => actions$.pipe(
  ofType(GET_MARKET_ANALYSIS_OUTPUT),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_iep}/marketAnalysisData?${action.payload}`, 'get')).pipe(
    map((res) => getMarketAnalysisOutputSuccess(res)),
    catchError((err) => of(getMarketAnalysisOutputFailure(err))),
  )),
);
export const getMoreMarketAnalysisOutputEpic = (actions$) => actions$.pipe(
  ofType(GET_MORE_MARKET_ANALYSIS_OUTPUT),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_iep}/marketAnalysisData?${action.payload}`, 'get')).pipe(
    map((res) => getMoreMarketAnalysisOutputSuccess(res)),
    catchError((err) => of(getMarketAnalysisOutputFailure(err))),
  )),
);
export const getMarketAnalysisOutputReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(getMarketAnalysisOutputAction, (state) => {
        state.loading = true;
      })
      .addCase(getMarketAnalysisOutputSuccess, (state, action) => {
        state.data = { ...action.payload.response };
        state.loading = false;
        state.flag = true;
      })
      .addCase(getMoreMarketAnalysisOutputAction, (state) => {
        state.loading = false;
      })
      .addCase(getMoreMarketAnalysisOutputSuccess, (state, action) => {
        state.data = {
          totalCount: action.payload.response.data.totalCount,
          data: {
            ...state.data.data,
            extractedProtienData: [...state.data.data.extractedProtienData, ...action.payload.response.data.extractedProtienData],
          },
        };

        state.loading = false;
        state.flag = true;
      })
      .addCase(getMarketAnalysisOutputFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.loading = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);
