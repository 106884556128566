import {
  Checkbox, Modal, Space, Tooltip,
} from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import RadarChartSAR from '../RadarChartSAR';
import Mixpanel from '../../../../../utils/mixpanel';

function ParameterSelectionView({
  isOpen, defaultLabels, defaultValues, setDefaultLabels, setDefaultValues, setIsOpen, admetData, defaultComparatorValues, setDefaultComparatorValues, comparatorData, setRadarParameters,
}) {
  const [radarData, setRadarData] = useState({});
  const [comparatorRadarData, setComparatorRadarData] = useState({});
  const [labelsList, setLabelsList] = useState([]);
  const [valuesList, setValuesList] = useState([]);
  const [comparatorValuesList, setComparatorValuesList] = useState([]);
  const [defaultParameters, setDefaultParameters] = useState([
    { key: 'Log P', value: admetData?.logP },
    { key: 'CYP3A4 Inhibition', value: admetData?.CYP3A4_Veith },
    { key: 'HIA', value: admetData?.HIA_Hou },
    { key: 'BBB', value: admetData?.BBB_Martins },
    { key: 'hERG Block', value: admetData?.hERG },
  ]);
  const updateData = (data, setData) => {
    setData({
      physicochemical_properties: [
        {
          key: 'Molecular Weight',
          value: data?.molecular_weight,
        },
        {
          key: 'Log P',
          value: data?.logP,
        },
        {
          key: 'Hydrogen Bond Acceptors',
          value: data?.hydrogen_bond_acceptors,
        },
        {
          key: 'Hydrogen Bond Donors',
          value: data?.hydrogen_bond_donors,
        },
        {
          key: 'Lipinski Rule of 5',
          value: data?.Lipinski,
        },
        {
          key: 'QED',
          value: data?.QED,
        },
        {
          key: 'Stereo Centers',
          value: data?.stereo_centers,
        },
        {
          key: 'TPSA',
          value: data?.tpsa,
        },
      ],
      metabolism: [
        {
          key: 'CYP1A2 Inhibition',
          value: data?.CYP1A2_Veith,
        },
        {
          key: 'CYP2C19 Inhibition',
          value: data?.CYP2C19_Veith,
        },
        {
          key: 'CYP2C9 Substrate',
          value: data?.CYP2C9_Substrate_CarbonMangels,
        },
        {
          key: 'CYP2C9 Inhibition',
          value: data?.CYP2C9_Veith,
        },
        {
          key: 'CYP2D6 Substrate',
          value: data?.CYP2D6_Substrate_CarbonMangels,
        },
        {
          key: 'CYP2D6 Inhibition',
          value: data?.CYP2D6_Veith,
        },
        {
          key: 'CYP3A4 Substrate',
          value: data?.CYP3A4_Substrate_CarbonMangels,
        },
        {
          key: 'CYP3A4 Inhibition',
          value: data?.CYP3A4_Veith,
        },
      ],
      absorption: [
        {
          key: 'Aqueous Solubility',
          value: data?.Solubility_AqSolDB,
        },
        {
          key: 'Lipophilicity',
          value: data?.Lipophilicity_AstraZeneca,
        },
        {
          key: 'Hydration Free Energy',
          value: data?.HydrationFreeEnergy_FreeSolv,
        },
        {
          key: 'Caco Permeability',
          value: data?.Caco2_Wang,
        },
        {
          key: 'HIA',
          value: data?.HIA_Hou,
        },
        {
          key: 'Oral Bioavailability',
          value: data?.Bioavailability_Ma,
        },
        {
          key: 'PAMPA Permeability',
          value: data?.PAMPA_NCATS,
        },
        {
          key: 'P-glycoprotein Inhibition',
          value: data?.Pgp_Broccatelli,
        },
      ],
      distribution: [
        {
          key: 'PPBR',
          value: data?.PPBR_AZ_y,
        },
        {
          key: 'VDss',
          value: data?.VDss_Lombardo_y,
        },
        {
          key: 'BBB',
          value: data?.BBB_Martins,
        },
      ],
      excretion: [
        {
          key: 'Half Life',
          value: data?.Half_Life_Obach_y,
        },
        {
          key: 'Drug Clearance',
          value: data?.Clearance,
        },
      ],
      toxicity: [
        {
          key: 'hERG Block',
          value: data?.hERG,
        },
        {
          key: 'Clinical Toxicity',
          value: data?.ClinTox,
        },
        {
          key: 'Mutagenicity',
          value: data?.AMES,
        },
        {
          key: 'DILI',
          value: data?.DILI,
        },
        {
          key: 'Carcinogenicity',
          value: data?.Carcinogens_Lagunin,
        },
        {
          key: 'Acute Toxicity LD50',
          value: data?.LD50_Zhu,
        },
        {
          key: 'Skin Reaction',
          value: data?.Skin_Reaction,
        },
        {
          key: 'NR-AR',
          value: data['NR-AR'] ?? 0,
        },
        {
          key: 'NR-AR-LBD',
          value: data['NR-AR-LBD'] ?? 0,
        },
        {
          key: 'NR-AhR',
          value: data['NR-AhR'] ?? 0,
        },
        {
          key: 'NR-Aromatase',
          value: data['NR-Aromatase'] ?? 0,
        },
        {
          key: 'NR-ER',
          value: data['NR-ER'] ?? 0,
        },
        {
          key: 'NR-ER-LBD',
          value: data['NR-ER-LBD'] ?? 0,
        },
        {
          key: 'NR-PPAR-gamma',
          value: data['NR-PPAR-gamma'] ?? 0,
        },
        {
          key: 'SR-ARE',
          value: data['SR-ARE'] ?? 0,
        },
        {
          key: 'SR-ATAD5',
          value: data['SR-ATAD5'] ?? 0,
        },
        {
          key: 'SR-HSE',
          value: data['SR-HSE'] ?? 0,
        },
        {
          key: 'SR-MMP',
          value: data['SR-MMP'] ?? 0,
        },
        {
          key: 'NR-AR-LBD',
          value: data['NR-AR-LBD'] ?? 0,
        },
        {
          key: 'SR-p53',
          value: data['SR-p53'] ?? 0,
        },
      ],
    });
  };

  useEffect(() => {
    if (admetData === undefined) {
      admetData = {};
    }

    updateData(admetData, setRadarData);
    if (comparatorData !== undefined) {
      updateData(comparatorData, setComparatorRadarData);
    } else {
      updateData(admetData, setComparatorRadarData);
    }
  }, [admetData, comparatorData]);

  useEffect(() => {
    setLabelsList(defaultLabels);
    setValuesList(defaultValues);
    if (defaultComparatorValues[0] !== undefined) {
      setComparatorValuesList(defaultComparatorValues);
    }
  }, [defaultValues, defaultLabels, defaultComparatorValues]);

  const onChangeSelection = () => {
    setDefaultLabels(labelsList);
    setDefaultValues(valuesList);
    setRadarParameters((prev) => ({
      ...prev,
      [admetData.smiles]: {
        labels: labelsList,
        values: valuesList,
      },
    }));
    if (comparatorData !== undefined) {
      setDefaultComparatorValues(comparatorValuesList);
    }
    setIsOpen(false);
    Mixpanel.track('SAR Graph Parameters Selection Submitted', {
      action: 'SAR Submit button from parameters selection view clicked',
      selected_parameters: labelsList,
      service: 'Admet Properties',
      module: 'SAR',
    });
  };

  const onChangeClose = () => {
    setIsOpen(false);
  };

  const onChangeCheckbox = ({ item1, item2 }) => {
    const index = labelsList.indexOf(item1.key);
    const newDefaultLabels = [...labelsList];
    const newDefaultValues = [...valuesList];
    const newDefaultParameters = [...defaultParameters];
    const newDefaultCompratorValues = [...comparatorValuesList];
    if (index > -1) {
      newDefaultLabels.splice(index, 1);
      newDefaultValues.splice(index, 1);
      setLabelsList(newDefaultLabels);
      setValuesList(newDefaultValues);
      setDefaultParameters(newDefaultParameters);
      if (comparatorData !== undefined) {
        newDefaultCompratorValues.splice(index, 1);
        setComparatorValuesList(newDefaultCompratorValues);
      }
    } else {
      newDefaultLabels.push(item1.key);
      newDefaultValues.push(item1.value);
      setLabelsList(newDefaultLabels);
      setValuesList(newDefaultValues);
      setDefaultParameters(newDefaultParameters);
      if (comparatorData !== undefined) {
        newDefaultCompratorValues.push(item2.value);
        setComparatorValuesList(newDefaultCompratorValues);
      }
    }
  };

  return (
    <Modal footer={null} closeIcon={null} maskClosable={false} open={isOpen} className="parameter-selection-modal">
      {defaultValues?.length > 0 && defaultValues[0] !== undefined && (
        <div className="parameter-selection-main">
          <div className="graph-preview">
            <div className="graph-preview-header">
              <div className="graph-preview-title">Graph Preview</div>
              <div className="graph-preview-download" />
            </div>
            <div className="radar-chart">
              <RadarChartSAR labelsList={labelsList} valuesList={valuesList} parametersList={defaultParameters} comparatorValuesList={comparatorValuesList} />
            </div>
          </div>
          <div className="select-parameters-view">
            <div className="select-parameters-headings">
              <div className="select-parameters-header">
                <div className="select-parameters-title">Parameter’s Selection View </div>
                <div className="select-parameters-close" onClick={() => onChangeClose()} aria-hidden />
              </div>
              <div className="select-parameters-sub-title">Select a graph parameter based on your choices</div>
            </div>
            <div className="parameters-listing-view">
              <div className="parameters-col-1">
                <div className="parameters-list">
                  <div className="parameters-list-title">Physicochemical Properties</div>
                  <div className="parameters-list">
                    <Checkbox.Group defaultValue={defaultLabels}>
                      <Space direction="vertical">
                        {radarData.physicochemical_properties?.map((item, index) => (
                          <Checkbox key={item.key} value={item.key} onChange={() => onChangeCheckbox({ item1: item, item2: comparatorRadarData?.physicochemical_properties[index] })}>
                            <Tooltip title={`${item.key}`}>
                              <div className="checkbox-title">{`${item.key}`}</div>
                            </Tooltip>
                          </Checkbox>
                        ))}
                      </Space>
                    </Checkbox.Group>
                  </div>
                </div>
                <div className="parameters-list">
                  <div className="parameters-list-title">Metabolism</div>
                  <div className="parameters-list">
                    <Checkbox.Group defaultValue={defaultLabels}>
                      <Space direction="vertical">
                        {radarData.metabolism?.map((item, index) => (
                          <Checkbox key={item.key} value={item.key} onChange={() => onChangeCheckbox({ item1: item, item2: comparatorRadarData?.metabolism[index] })}>
                            <Tooltip title={`${item.key}`}>
                              <div className="checkbox-title">{`${item.key}`}</div>
                            </Tooltip>
                          </Checkbox>
                        ))}
                      </Space>
                    </Checkbox.Group>
                  </div>
                </div>
              </div>
              <div className="parameters-col-2">
                <div className="parameters-list">
                  <div className="parameters-list-title">Absorption</div>
                  <div className="parameters-list">
                    <Checkbox.Group defaultValue={defaultLabels}>
                      <Space direction="vertical">
                        {radarData.absorption?.map((item, index) => (
                          <Checkbox key={item.key} value={item.key} onChange={() => onChangeCheckbox({ item1: item, item2: comparatorRadarData?.absorption[index] })}>
                            <Tooltip title={`${item.key}`}>
                              <div className="checkbox-title">{`${item.key}`}</div>
                            </Tooltip>
                          </Checkbox>
                        ))}
                      </Space>
                    </Checkbox.Group>
                  </div>
                </div>
                <div className="parameters-list">
                  <div className="parameters-list-title">Distribution</div>
                  <div className="parameters-list">
                    <Checkbox.Group defaultValue={defaultLabels}>
                      <Space direction="vertical">
                        {radarData.distribution?.map((item, index) => (
                          <Checkbox key={item.key} value={item.key} onChange={() => onChangeCheckbox({ item1: item, item2: comparatorRadarData?.distribution[index] })}>
                            <Tooltip title={`${item.key}`}>
                              <div className="checkbox-title">{`${item.key}`}</div>
                            </Tooltip>
                          </Checkbox>
                        ))}
                      </Space>
                    </Checkbox.Group>
                  </div>
                </div>
                <div className="parameters-list">
                  <div className="parameters-list-title">Excretion</div>
                  <div className="parameters-list">
                    <Checkbox.Group defaultValue={defaultLabels}>
                      <Space direction="vertical">
                        {radarData.excretion?.map((item, index) => (
                          <Checkbox key={item.key} value={item.key} onChange={() => onChangeCheckbox({ item1: item, item2: comparatorRadarData?.excretion[index] })}>
                            <Tooltip title={`${item.key}`}>
                              <div className="checkbox-title">{`${item.key}`}</div>
                            </Tooltip>
                          </Checkbox>
                        ))}
                      </Space>
                    </Checkbox.Group>
                  </div>
                </div>
              </div>
              {admetData ? (
                <div className="parameters-col-3">
                  <div className="parameters-list">
                    <div className="parameters-list-title">Toxicity</div>
                    <div className="parameters-list">
                      <Checkbox.Group defaultValue={defaultLabels}>
                        <Space direction="vertical">
                          {radarData.toxicity?.map((item, index) => (
                            <Checkbox key={item.key} value={item.key} onChange={() => onChangeCheckbox({ item1: item, item2: comparatorRadarData?.toxicity[index] })}>
                              <Tooltip title={`${item.key}`}>
                                <div className="checkbox-title">{`${item.key}`}</div>
                              </Tooltip>
                            </Checkbox>
                          ))}
                        </Space>
                      </Checkbox.Group>
                    </div>
                  </div>
                </div>
              ) : (
                <div />
              )}
            </div>
            <div className="submit-background">
              <div className="submit-btn" style={{ cursor: 'pointer' }} onClick={() => onChangeSelection()} aria-hidden>
                Submit
              </div>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
}

ParameterSelectionView.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  defaultLabels: PropTypes.arrayOf.isRequired,
  defaultValues: PropTypes.arrayOf.isRequired,
  setDefaultLabels: PropTypes.func.isRequired,
  setDefaultValues: PropTypes.func.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  admetData: PropTypes.objectOf.isRequired,
  defaultComparatorValues: PropTypes.arrayOf.isRequired,
  setDefaultComparatorValues: PropTypes.func.isRequired,
  comparatorData: PropTypes.objectOf.isRequired,
  setRadarParameters: PropTypes.func.isRequired,
};
export default ParameterSelectionView;
