import {
  Button, Divider, Input,
  Tag,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CloseOutlined } from '@ant-design/icons';
import Loader from '../../../../common/components/Loader';
import Header from '../../../../common/components/Header';
import useAppSelector from '../../../../utils/redux-hook';
import { getCookie } from '../../../../utils';
import { terminalsPath } from '../../../../app/components/Card/data';
import {
  posWorkspaceStatusAction, hctpWorkspaceStatusAction, posWorkspaceStatusResetAction, hctpWorkspaceStatusResetAction,
} from './logic';
// import LazyLoadDiv from '../../../../common/components/LazyLoadDiv';

function MyWorkspacePageCTP() {
  const dispatch = useDispatch();
  const posWorkspaceStatusResponse = useAppSelector((state) => state.posWorkspaceStatusResponse);
  const hctpWorkspaceStatusResponse = useAppSelector((state) => state.hctpWorkspaceStatusResponse);
  const [searchParams] = useSearchParams();
  const service = searchParams.get('service');
  const [searchText, setSearchText] = useState('');
  const navigate = useNavigate();
  //   const [searchQuery, setSearchQuery] = useState('');
  const [editing, setEditing] = useState({
    isEditing: false,
    editingId: '',
    editingName: '',
  });
  const [currentJob, setCurrentJob] = useState({});
  const userEmailId = getCookie('usersEmailId');
  const [isLoading, setIsLoading] = useState(true);
  const [currentService, setCurrentService] = useState([1, 0, 0]);
  const [serviceJobsList, setServiceJobsList] = useState({
    pos: [],
    hctp: [],
  });
  const [searchAndSort, setSearchAndSort] = useState({
    isSearch: false,
    isSort: false,
    searchValue: '',
    sortValue: 'desc',
  });
  const [posRequest, setPosRequest] = useState({
    filters: JSON.stringify({ module_id: 'metad3_ctp', 'user_input.service': 'PREDICT_CLINICAL_TRIAL_SUCCESS' }),
    from: 1,
    size: 20,
    searchQuery: (searchAndSort.searchValue.length > 0) ? searchAndSort.searchValue : undefined,
    sort_order: searchAndSort.sortValue,
  });
  const [hctpRequest, setHctpRequest] = useState({
    filters: JSON.stringify({ module_id: 'metad3_ctp', 'user_input.service': 'HCTP' }),
    from: 1,
    size: 20,
    searchQuery: (searchAndSort.searchValue.length > 0) ? searchAndSort.searchValue : undefined,
    sort_order: searchAndSort.sortValue,
  });

  useEffect(() => {
    setIsLoading(false);
    switch (service) {
      case 'PREDICT_CLINICAL_TRIAL_SUCCESS':
        setCurrentService([1, 0, 0]);
        break;
      case 'HCTP':
        setCurrentService([0, 1, 0]);
        break;
      default:
        setCurrentService([1, 0, 0]);
    }
  }, []);

  useEffect(() => {
    dispatch(hctpWorkspaceStatusAction(queryString.stringify(hctpRequest)));
    setIsLoading(false);
    const intervalId = setInterval(() => {
      if (posWorkspaceStatusResponse.data) {
        dispatch(hctpWorkspaceStatusAction(queryString.stringify(hctpRequest)));
      }
    }, 20000);
    return () => clearInterval(intervalId);
  }, [hctpRequest]);

  useEffect(() => {
    dispatch(posWorkspaceStatusAction(queryString.stringify(posRequest)));
    setIsLoading(false);
    const intervalId = setInterval(() => {
      if (hctpWorkspaceStatusResponse.data) {
        dispatch(posWorkspaceStatusAction(queryString.stringify(posRequest)));
      }
    }, 20000);
    return () => clearInterval(intervalId);
  }, [posRequest]);

  useEffect(() => {
    if (posWorkspaceStatusResponse.flag) {
      if (!currentJob?.status && (service === 'PREDICT_CLINICAL_TRIAL_SUCCESS' || !service)) {
        setCurrentJob(posWorkspaceStatusResponse.data[0]);
      }
      setServiceJobsList((prev) => ({
        ...prev,
        pos: posWorkspaceStatusResponse.data,
      }));
    }
  }, [posWorkspaceStatusResponse]);

  useEffect(() => {
    if (hctpWorkspaceStatusResponse.flag) {
      if (!currentJob?.status) {
        setCurrentJob(hctpWorkspaceStatusResponse.data[0]);
      }
      setServiceJobsList((prev) => ({
        ...prev,
        hctp: hctpWorkspaceStatusResponse.data,
      }));
    }
  }, [hctpWorkspaceStatusResponse]);

  useEffect(() => {
    setPosRequest((prev) => ({
      ...prev,
      from: 1,
      size: 20,
      searchQuery: (searchAndSort.searchValue.length > 0) ? searchAndSort.searchValue : undefined,
      sort_order: searchAndSort.sortValue,
    }));
    setHctpRequest((prev) => ({
      ...prev,
      from: 1,
      size: 20,
      searchQuery: (searchAndSort.searchValue.length > 0) ? searchAndSort.searchValue : undefined,
      sort_order: searchAndSort.sortValue,

    }));
  }, [searchAndSort]);

  const onChangedJob = (job) => {
    setCurrentJob(job);
  };

  const formatDate = (epoch) => {
    const date = new Date(epoch * 1000);

    const day = date.getDate();
    const month = date.toLocaleString('en-US', { month: 'short' });

    return `${day} ${month}`;
  };
  const formatTime = (epoch) => {
    const date = new Date(epoch * 1000);

    const time = date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });

    return time;
  };
  const onEditName = (job) => {
    const request = {
      job_id: job?.job_id,
      job_name: editing.editingName,
    };
    dispatch(posWorkspaceStatusAction(queryString.stringify(request)));
    dispatch(hctpWorkspaceStatusAction(queryString.stringify(request)));
    setEditing({ isEditing: false, editingId: '', editingName: '' });
  };

  const tagsGroup = (list1) => (
    <div className="tags-group">
      <div className="tags">
        {list1?.map((item) => (
          <Tag key={item} className="sample-tags" closable={false}>
            {item}
          </Tag>
        ))}
      </div>
    </div>
  );

  const onViewOutput = (job) => {
    if (job?.status.toLowerCase() === 'completed') {
      navigate(`${terminalsPath.metad3_ctp_output}?jobId=${job?.job_id}&service=${job?.user_input.service}&noOfMolecules=${job?.user_input.molecular_smiles?.length}`);
    } else {
      navigate(terminalsPath.metad3_ctp);
    }
  };

  const jobsList = (data1) => (
    <div className="jobs-list">
      {data1?.map((job, index) => (
        <div className={`job-item${job?.job_id === currentJob?.job_id ? ' active' : ''}${editing.isEditing && editing.editingId === index ? ' input-edit' : ''}`} key={job?.job_id} style={{ display: 'flex', cursor: 'pointer' }} onClick={() => onChangedJob(job)} aria-hidden>
          <div className="job-info">
            {editing.isEditing && editing.editingId === index ? (
              <div>
                <Input
                  placeholder="Job Name"
                  value={editing.editingName ?? job?.job_name ?? job?.module_id}
                  onChange={(e) => setEditing({ ...editing, editingName: e.target.value })}
                  onPressEnter={() => onEditName(job)}
                />
              </div>
            ) : (
              <div className="job-title">{job?.job_name ?? job?.module_id}</div>
            )}
            <div className="job-created-at">
              {formatDate(job?.created_date_time_ts)}
              <Divider type="vertical" />
              {formatTime(job?.created_date_time_ts)}
            </div>
          </div>
          <div className="job-status-main">
            <div className={`job-status${job?.status.toLowerCase() === 'completed' ? ' completed' : job?.status.toLowerCase() === 'rejected' ? ' failed' : ' inprogress'}`}>{job?.status === 'REJECTED' ? 'Failed' : job?.status === 'PENDING' ? 'In Progress' : ''}</div>
          </div>
          <div className="job-name-edit-btn">
            <Button className="rename-edit-icon" onClick={() => setEditing({ isEditing: true, editingId: index })} />
          </div>
        </div>
      ))}
    </div>
  );

  const inputDetails = (job) => (
    <div className="job-details-main">
      {job?.status.toLowerCase() !== 'completed' && <div className={`job-pending-warning ${job?.status.toLowerCase() === 'pending' ? ' pending' : 'failed'}`}>{`${job?.status.toLowerCase() === 'pending' ? `Output generation is in progress. Once it is generated, we will notify you over your email, “${userEmailId}”` : 'Due to a technical error, the output generation failed. Please click the “Resubmit Input” button to try again.'}`}</div>}
      <div className="job-details-title">Input Details</div>
      <div className="job-details-content">
        <div className="job-name-row" style={{ display: 'flex' }}>
          <div className="job-name-group" style={{ display: 'flex' }}>
            <div className="job-name-group-title">{job?.job_name || job?.module_id}</div>
            <div className="job-created-at">
              {formatDate(job?.created_date_time_ts)}
              <Divider type="vertical" />
              {formatTime(job?.created_date_time_ts)}
            </div>
            {/* {job?.user_input?.service === 'HCTP' && <div className="study-tag">Optimize Study Protocols</div> } */}
          </div>
          <div className="view-output-btn">
            <Button
              disabled={job?.status.toLowerCase() === 'pending'}
              onClick={() => {
                onViewOutput(job);
                dispatch(posWorkspaceStatusResetAction({}));
              }}
            >
              {`${job?.status.toLowerCase() === 'rejected' ? 'Resubmit Input' : 'View Output'}`}
            </Button>
          </div>
        </div>
        {job?.description && (
        <div className="description-main">
          <div className="title">Description</div>
          <div className="content">{job?.description}</div>
        </div>
        )}
        <div className="drug-indication-table">
          {/* <HCTPTableView data={dummyData} /> */}
          <div className="drug-indication-pairs-main">
            <div className="title">
              {`${job?.user_input?.service === 'HCTP' ? 'Drug-Indication Pairs' : 'NCT IDs'}`}
            </div>
            <div className="pairs-list">
              {
                job?.user_input?.service === 'PREDICT_CLINICAL_TRIAL_SUCCESS' ? tagsGroup(job?.user_input?.nct_ids) : tagsGroup(job?.user_input?.drugs?.map((drug, index) => `${drug}~${job?.user_input.indications[index]}`))
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  //   const loadMoreData = (loadService) => {
  //     console.log('inside');
  //     if (loadService === 0) {
  //       setPosRequest((prev) => {
  //         const { size } = prev;
  //         return {
  //           ...prev,
  //           size: size + 20,
  //         };
  //       });
  //     }
  //     if (loadService === 1) {
  //       setHCTPRequest((prev) => {
  //         const { size } = prev;
  //         return {
  //           ...prev,
  //           size: size + 20,
  //         };
  //       });
  //     }
  //   };

  const serviceDropdowns = () => (
    <div className="service-dropdowns">
      <div role="presentation" className={`service-dropdown ${(currentService[0] === 1 ? 'open' : 'close')}`} onClick={() => setCurrentService([1, 0, 0])}>
        <div className="service-dropdown-title">
          Predict Clinical Trial Success
        </div>
        { currentService[0] === 1 && (
          <div className="service-dropdown-content">
            {jobsList(serviceJobsList.pos)}
          </div>
        )}

      </div>
      <div role="presentation" className={`service-dropdown ${(currentService[1] === 1 ? 'open' : 'close')}`} onClick={() => setCurrentService([0, 1, 0])}>
        <div className="service-dropdown-title">
          HCTP
        </div>
        { currentService[1] === 1 && (
          <div className="service-dropdown-content">
            {jobsList(serviceJobsList.hctp)}
          </div>
        )}

      </div>
    </div>
  );

  return (
    <div className="main-page main-page-modules">
      <Header className="header-home iep-header" logo="meta-ctp" width={550} />
      <Loader loading={(posWorkspaceStatusResponse.loading || hctpWorkspaceStatusResponse.loading) && isLoading}>
        <div className="ct-home-page my-workspace-page">
          <div className="my-workspace-main">
            <div className="jobs-list-view">
              {searchAndSort.isSearch ? (
                <div className="search-input">
                  <Input
                    placeholder="Job Name"
                    value={searchText}
                    allowClear={{
                      clearIcon: <CloseOutlined onClick={() => {
                        setSearchText('');
                        setSearchAndSort((prev) => ({
                          ...prev,
                          isSearch: false,
                          searchValue: '',
                        }));
                      }}
                      />,
                    }}
                    onChange={(e) => setSearchText(e.target.value)}
                    onPressEnter={(e) => {
                      setSearchAndSort((prev) => ({
                        ...prev,
                        searchValue: e.target.value,
                      }));
                    }}
                  />
                </div>
              ) : (
                <div className="btns-row">
                  <div className="back-btn">
                    <Button
                      onClick={() => {
                        navigate(terminalsPath.metad3_ctp);
                        dispatch(posWorkspaceStatusResetAction({}));
                        dispatch(hctpWorkspaceStatusResetAction({}));
                      }}
                      className="back-btn-icon"
                    >
                      Back
                    </Button>
                  </div>
                  <div className="grouped-btns">
                    <div className="search-btn" style={{ cursor: 'pointer' }}>
                      <div
                        className="search-btn-icon"
                        onClick={() => setSearchAndSort((prev) => ({
                          ...prev,
                          isSearch: true,
                        }))}
                        aria-hidden
                      />
                    </div>
                    <div className="sort-btn" style={{ cursor: 'pointer' }}>
                      <div
                        className="sort-btn-icon"
                        onClick={() => {
                          if (searchAndSort.sortValue === 'asc') {
                            setSearchAndSort((prev) => ({
                              ...prev,
                              sortValue: 'desc',
                            }));
                          } else {
                            setSearchAndSort((prev) => ({
                              ...prev,
                              sortValue: 'asc',
                            }));
                          }
                        }}
                        aria-hidden
                      />
                    </div>
                  </div>
                </div>
              )}
              {serviceDropdowns()}
              {/* {jobsList()} */}
            </div>
            {currentJob && <div className="job-details-view">{currentJob?.user_input && inputDetails(currentJob)}</div>}
          </div>
        </div>
      </Loader>
    </div>
  );
}

export default MyWorkspacePageCTP;
