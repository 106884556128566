/* eslint-disable no-undef */
// src/components/MoleculeViewer.js
/* eslint-disable react/prop-types */
import {
  // CloseOutlined,
  DownloadOutlined,
  ReloadOutlined, ZoomInOutlined, ZoomOutOutlined,
} from '@ant-design/icons';
import { Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import Mixpanel from '../../../../utils/mixpanel';

const pdbData = `
HEADER    VIRAL PROTEIN                           26-JAN-20   6LU7
TITLE     THE CRYSTAL STRUCTURE OF COVID-19 MAIN PROTEASE IN COMPLEX WITH AN
TITLE    2 INHIBITOR N3
COMPND    MOL_ID: 1;
COMPND   2 MOLECULE: 3C-LIKE PROTEINASE;
COMPND   3 CHAIN: A;
COMPND   4 FRAGMENT: 3C-LIKE PROTEINASE;
COMPND   5 SYNONYM: PP1AB,ORF1AB POLYPROTEIN,3CL-PRO,3CLP,MAIN PROTEASE,MPRO,
COMPND   6 NON-STRUCTURAL PROTEIN 5,NSP5,SARS CORONAVIRUS MAIN PROTEINASE;
COMPND   7 EC: 3.4.22.69;
COMPND   8 ENGINEERED: YES;
COMPND   9 MOL_ID: 2;
COMPND  10 MOLECULE: N-[(5-METHYLISOXAZOL-3-YL)CARBONYL]ALANYL-L-VALYL-N~1~-
COMPND  11 ((1R,2Z)-4-(BENZYLOXY)-4-OXO-1-{[(3R)-2-OXOPYRROLIDIN-3-
COMPND  12 YL]METHYL}BUT-2-ENYL)-L-LEUCINAMIDE;
COMPND  13 CHAIN: C;
COMPND  14 ENGINEERED: YES
SOURCE    MOL_ID: 1;
SOURCE   2 ORGANISM_SCIENTIFIC: SEVERE ACUTE RESPIRATORY SYNDROME CORONAVIRUS
SOURCE   3 2;
SOURCE   4 ORGANISM_COMMON: 2019-NCOV;
SOURCE   5 ORGANISM_TAXID: 2697049;
SOURCE   6 GENE: REP, 1A-1B;
SOURCE   7 EXPRESSION_SYSTEM: ESCHERICHIA COLI BL21(DE3);
SOURCE   8 EXPRESSION_SYSTEM_TAXID: 469008;
SOURCE   9 EXPRESSION_SYSTEM_VECTOR_TYPE: PLASMID;
SOURCE  10 EXPRESSION_SYSTEM_PLASMID: PGEX-6P-1;
SOURCE  11 MOL_ID: 2;
SOURCE  12 SYNTHETIC: YES;
SOURCE  13 ORGANISM_SCIENTIFIC: SYNTHETIC CONSTRUCT;
SOURCE  14 ORGANISM_TAXID: 32630
KEYWDS    PROTEASE, VIRAL PROTEIN
EXPDTA    X-RAY DIFFRACTION
AUTHOR    X.LIU,B.ZHANG,Z.JIN,H.YANG,Z.RAO
REVDAT  15   29-NOV-23 6LU7    1       REMARK
REVDAT  14   15-NOV-23 6LU7    1       JRNL   LINK   ATOM
REVDAT  13   10-MAR-21 6LU7    1       COMPND
REVDAT  12   29-JUL-20 6LU7    1       COMPND JRNL   REMARK LINK
REVDAT  12 2                   1       ATOM
REVDAT  11   24-JUN-20 6LU7    1       JRNL
REVDAT  10   10-JUN-20 6LU7    1       COMPND
REVDAT   9   27-MAY-20 6LU7    1       JRNL
REVDAT   8   06-MAY-20 6LU7    1       COMPND SOURCE DBREF
REVDAT   7   22-APR-20 6LU7    1       JRNL
REVDAT   6   18-MAR-20 6LU7    1       JRNL
REVDAT   5   11-MAR-20 6LU7    1       COMPND SOURCE
REVDAT   4   26-FEB-20 6LU7    1       REMARK
REVDAT   3   19-FEB-20 6LU7    1       TITLE  JRNL
REVDAT   2   12-FEB-20 6LU7    1       TITLE  COMPND JRNL   REMARK
REVDAT   2 2                   1       SHEET  LINK   SITE   ATOM
REVDAT   1   05-FEB-20 6LU7    0
JRNL        AUTH   Z.JIN,X.DU,Y.XU,Y.DENG,M.LIU,Y.ZHAO,B.ZHANG,X.LI,L.ZHANG,
JRNL        AUTH 2 C.PENG,Y.DUAN,J.YU,L.WANG,K.YANG,F.LIU,R.JIANG,X.YANG,T.YOU,
JRNL        AUTH 3 X.LIU,X.YANG,F.BAI,H.LIU,X.LIU,L.W.GUDDAT,W.XU,G.XIAO,C.QIN,
JRNL        AUTH 4 Z.SHI,H.JIANG,Z.RAO,H.YANG
JRNL        TITL   STRUCTURE OF MPROFROM SARS-COV-2 AND DISCOVERY OF ITS
JRNL        TITL 2 INHIBITORS.
JRNL        REF    NATURE                        V. 582   289 2020
JRNL        REFN                   ESSN 1476-4687
JRNL        PMID   32272481
JRNL        DOI    10.1038/S41586-020-2223-Y
REMARK   1
REMARK   1 REFERENCE 1
REMARK   1  AUTH   Z.JIN,X.DU,Y.XU,Y.DENG,M.LIU,Y.ZHAO,B.ZHANG,X.LI,L.ZHANG,
REMARK   1  AUTH 2 C.PENG,Y.DUAN,J.YU,L.WANG,K.YANG,F.LIU,R.JIANG,X.YANG,T.YOU,
REMARK   1  AUTH 3 X.LIU,X.YANG,F.BAI,H.LIU,X.LIU,L.GUDDAT,W.XU,G.XIAO,C.QIN,
REMARK   1  AUTH 4 Z.SHI,H.JIANG,Z.RAO,H.YANG
REMARK   1  TITL   STRUCTURE OF MPRO FROM COVID-19 VIRUS AND DISCOVERY OF ITS
REMARK   1  TITL 2 INHIBITORS.
REMARK   1  REF    BIORXIV                                    2020
REMARK   1  REFN                   ISSN 2692-8205
REMARK   1  DOI    10.1101/2020.02.26.964882
REMARK   2
REMARK   2 RESOLUTION.    2.16 ANGSTROMS.
REMARK   3
REMARK   3 REFINEMENT.
REMARK   3   PROGRAM     : PHENIX 1.17.1_3660
REMARK   3   AUTHORS     : PAUL ADAMS,PAVEL AFONINE,VINCENT CHEN,IAN
REMARK   3               : DAVIS,KRESHNA GOPAL,RALF GROSSE-KUNSTLEVE,
REMARK   3               : LI-WEI HUNG,ROBERT IMMORMINO,TOM IOERGER,
REMARK   3               : AIRLIE MCCOY,ERIK MCKEE,NIGEL MORIARTY,
REMARK   3               : REETAL PAI,RANDY READ,JANE RICHARDSON,
REMARK   3               : DAVID RICHARDSON,TOD ROMO,JIM SACCHETTINI,
REMARK   3               : NICHOLAS SAUTER,JACOB SMITH,LAURENT
REMARK   3               : STORONI,TOM TERWILLIGER,PETER ZWART
REMARK   3
REMARK   3    REFINEMENT TARGET : ML
REMARK   3
REMARK   3  DATA USED IN REFINEMENT.
REMARK   3   RESOLUTION RANGE HIGH (ANGSTROMS) : 2.16
REMARK   3   RESOLUTION RANGE LOW  (ANGSTROMS) : 27.81
REMARK   3   MIN(FOBS/SIGMA_FOBS)              : 1.340
REMARK   3   COMPLETENESS FOR RANGE        (%) : 99.5
REMARK   3   NUMBER OF REFLECTIONS             : 19432
REMARK   3
REMARK   3  FIT TO DATA USED IN REFINEMENT.
REMARK   3   R VALUE     (WORKING + TEST SET) : 0.204
REMARK   3   R VALUE            (WORKING SET) : 0.202
REMARK   3   FREE R VALUE                     : 0.235
REMARK   3   FREE R VALUE TEST SET SIZE   (%) : 5.130
REMARK   3   FREE R VALUE TEST SET COUNT      : 997
REMARK   3
REMARK   3  FIT TO DATA USED IN REFINEMENT (IN BINS).
REMARK   3   BIN  RESOLUTION RANGE  COMPL.    NWORK NFREE   RWORK  RFREE
REMARK   3     1 27.8100 -  4.1200    1.00     2689   147  0.1725 0.2049
REMARK   3     2  4.1200 -  3.2700    1.00     2646   148  0.1897 0.2261
REMARK   3     3  3.2700 -  2.8600    1.00     2616   158  0.2163 0.2408
REMARK   3     4  2.8600 -  2.6000    1.00     2644   134  0.2320 0.2628
REMARK   3     5  2.6000 -  2.4100    1.00     2662   123  0.2333 0.2748
REMARK   3     6  2.4100 -  2.2700    1.00     2610   148  0.2456 0.2742
REMARK   3     7  2.2700 -  2.1600    0.97     2568   139  0.2617 0.2969
REMARK   3
REMARK   3  BULK SOLVENT MODELLING.
REMARK   3   METHOD USED        : FLAT BULK SOLVENT MODEL
REMARK   3   SOLVENT RADIUS     : 1.11
REMARK   3   SHRINKAGE RADIUS   : 0.90
REMARK   3   K_SOL              : NULL
REMARK   3   B_SOL              : NULL
REMARK   3
REMARK   3  ERROR ESTIMATES.
REMARK   3   COORDINATE ERROR (MAXIMUM-LIKELIHOOD BASED)     : 0.210
REMARK   3   PHASE ERROR (DEGREES, MAXIMUM-LIKELIHOOD BASED) : 27.660
REMARK   3
REMARK   3  B VALUES.
REMARK   3   FROM WILSON PLOT           (A**2) : NULL
REMARK   3   MEAN B VALUE      (OVERALL, A**2) : 42.82
REMARK   3   OVERALL ANISOTROPIC B VALUE.
REMARK   3    B11 (A**2) : NULL
REMARK   3    B22 (A**2) : NULL
REMARK   3    B33 (A**2) : NULL
REMARK   3    B12 (A**2) : NULL
REMARK   3    B13 (A**2) : NULL
REMARK   3    B23 (A**2) : NULL
REMARK   3
REMARK   3  TWINNING INFORMATION.
REMARK   3   FRACTION: NULL
REMARK   3   OPERATOR: NULL
REMARK   3
REMARK   3  DEVIATIONS FROM IDEAL VALUES.
REMARK   3                 RMSD          COUNT
REMARK   3   BOND      :   NULL           NULL
REMARK   3   ANGLE     :   NULL           NULL
REMARK   3   CHIRALITY :   NULL           NULL
REMARK   3   PLANARITY :   NULL           NULL
REMARK   3   DIHEDRAL  :   NULL           NULL
REMARK   3
REMARK   3  TLS DETAILS
REMARK   3   NUMBER OF TLS GROUPS  : NULL
REMARK   3
REMARK   3  NCS DETAILS
REMARK   3   NUMBER OF NCS GROUPS : NULL
REMARK   3
REMARK   3  OTHER REFINEMENT REMARKS: NULL
REMARK   4
REMARK   4 6LU7 COMPLIES WITH FORMAT V. 3.30, 13-JUL-11
REMARK 100
REMARK 100 THIS ENTRY HAS BEEN PROCESSED BY PDBJ ON 27-JAN-20.
REMARK 100 THE DEPOSITION ID IS D_1300015462.
REMARK 200
REMARK 200 EXPERIMENTAL DETAILS
REMARK 200  EXPERIMENT TYPE                : X-RAY DIFFRACTION
REMARK 200  DATE OF DATA COLLECTION        : 12-JAN-20
REMARK 200  TEMPERATURE           (KELVIN) : 100
REMARK 200  PH                             : 6.0
REMARK 200  NUMBER OF CRYSTALS USED        : 1
REMARK 200
REMARK 200  SYNCHROTRON              (Y/N) : Y
REMARK 200  RADIATION SOURCE               : SSRF
REMARK 200  BEAMLINE                       : BL17U1
REMARK 200  X-RAY GENERATOR MODEL          : NULL
REMARK 200  MONOCHROMATIC OR LAUE    (M/L) : M
REMARK 200  WAVELENGTH OR RANGE        (A) : 1.07180
REMARK 200  MONOCHROMATOR                  : NULL
REMARK 200  OPTICS                         : NULL
REMARK 200
REMARK 200  DETECTOR TYPE                  : PIXEL
REMARK 200  DETECTOR MANUFACTURER          : DECTRIS EIGER X 16M
REMARK 200  INTENSITY-INTEGRATION SOFTWARE : XIA2
REMARK 200  DATA SCALING SOFTWARE          : XIA2
REMARK 200
REMARK 200  NUMBER OF UNIQUE REFLECTIONS   : 19455
REMARK 200  RESOLUTION RANGE HIGH      (A) : 2.160
REMARK 200  RESOLUTION RANGE LOW       (A) : 42.290
REMARK 200  REJECTION CRITERIA  (SIGMA(I)) : NULL
REMARK 200
REMARK 200 OVERALL.
REMARK 200  COMPLETENESS FOR RANGE     (%) : 100.0
REMARK 200  DATA REDUNDANCY                : 6.600
REMARK 200  R MERGE                    (I) : 0.18900
REMARK 200  R SYM                      (I) : NULL
REMARK 200  <I/SIGMA(I)> FOR THE DATA SET  : 6.3000
REMARK 200
REMARK 200 IN THE HIGHEST RESOLUTION SHELL.
REMARK 200  HIGHEST RESOLUTION SHELL, RANGE HIGH (A) : 2.16
REMARK 200  HIGHEST RESOLUTION SHELL, RANGE LOW  (A) : 2.22
REMARK 200  COMPLETENESS FOR SHELL     (%) : 100.0
REMARK 200  DATA REDUNDANCY IN SHELL       : 6.10
REMARK 200  R MERGE FOR SHELL          (I) : 1.47200
REMARK 200  R SYM FOR SHELL            (I) : NULL
REMARK 200  <I/SIGMA(I)> FOR SHELL         : NULL
REMARK 200
REMARK 200 DIFFRACTION PROTOCOL: SINGLE WAVELENGTH
REMARK 200 METHOD USED TO DETERMINE THE STRUCTURE: MOLECULAR REPLACEMENT
REMARK 200 SOFTWARE USED: PHASER
REMARK 200 STARTING MODEL: 2HOB
REMARK 200
REMARK 200 REMARK: NULL
REMARK 280
REMARK 280 CRYSTAL
REMARK 280 SOLVENT CONTENT, VS   (%): 53.53
REMARK 280 MATTHEWS COEFFICIENT, VM (ANGSTROMS**3/DA): 2.65
REMARK 280
REMARK 280 CRYSTALLIZATION CONDITIONS: 2% POLYETHYLENE GLYCOL (PEG) 6000, 3%
REMARK 280  DMSO, 1MM DTT, 0.1M MES BUFFER (PH 6.0), PROTEIN CONCENTRATION
REMARK 280  5MG/ML, VAPOR DIFFUSION, HANGING DROP, TEMPERATURE 293K,
REMARK 280  EVAPORATION
REMARK 290
REMARK 290 CRYSTALLOGRAPHIC SYMMETRY
REMARK 290 SYMMETRY OPERATORS FOR SPACE GROUP: C 1 2 1
REMARK 290
REMARK 290      SYMOP   SYMMETRY
REMARK 290     NNNMMM   OPERATOR
REMARK 290       1555   X,Y,Z
REMARK 290       2555   -X,Y,-Z
REMARK 290       3555   X+1/2,Y+1/2,Z
REMARK 290       4555   -X+1/2,Y+1/2,-Z
REMARK 290
REMARK 290     WHERE NNN -> OPERATOR NUMBER
REMARK 290           MMM -> TRANSLATION VECTOR
REMARK 290
REMARK 290 CRYSTALLOGRAPHIC SYMMETRY TRANSFORMATIONS
REMARK 290 THE FOLLOWING TRANSFORMATIONS OPERATE ON THE ATOM/HETATM
REMARK 290 RECORDS IN THIS ENTRY TO PRODUCE CRYSTALLOGRAPHICALLY
REMARK 290 RELATED MOLECULES.
REMARK 290   SMTRY1   1  1.000000  0.000000  0.000000        0.00000
REMARK 290   SMTRY2   1  0.000000  1.000000  0.000000        0.00000
REMARK 290   SMTRY3   1  0.000000  0.000000  1.000000        0.00000
REMARK 290   SMTRY1   2 -1.000000  0.000000  0.000000        0.00000
REMARK 290   SMTRY2   2  0.000000  1.000000  0.000000        0.00000
REMARK 290   SMTRY3   2  0.000000  0.000000 -1.000000        0.00000
REMARK 290   SMTRY1   3  1.000000  0.000000  0.000000       48.96550
REMARK 290   SMTRY2   3  0.000000  1.000000  0.000000       39.73850
REMARK 290   SMTRY3   3  0.000000  0.000000  1.000000        0.00000
REMARK 290   SMTRY1   4 -1.000000  0.000000  0.000000       48.96550
REMARK 290   SMTRY2   4  0.000000  1.000000  0.000000       39.73850
REMARK 290   SMTRY3   4  0.000000  0.000000 -1.000000        0.00000
REMARK 290
REMARK 290 REMARK: NULL
REMARK 300
REMARK 300 BIOMOLECULE: 1
REMARK 300 SEE REMARK 350 FOR THE AUTHOR PROVIDED AND/OR PROGRAM
REMARK 300 GENERATED ASSEMBLY INFORMATION FOR THE STRUCTURE IN
REMARK 300 THIS ENTRY. THE REMARK MAY ALSO PROVIDE INFORMATION ON
REMARK 300 BURIED SURFACE AREA.
REMARK 350
REMARK 350 COORDINATES FOR A COMPLETE MULTIMER REPRESENTING THE KNOWN
REMARK 350 BIOLOGICALLY SIGNIFICANT OLIGOMERIZATION STATE OF THE
REMARK 350 MOLECULE CAN BE GENERATED BY APPLYING BIOMT TRANSFORMATIONS
REMARK 350 GIVEN BELOW.  BOTH NON-CRYSTALLOGRAPHIC AND
REMARK 350 CRYSTALLOGRAPHIC OPERATIONS ARE GIVEN.
REMARK 350
REMARK 350 BIOMOLECULE: 1
REMARK 350 AUTHOR DETERMINED BIOLOGICAL UNIT: TETRAMERIC
REMARK 350 SOFTWARE DETERMINED QUATERNARY STRUCTURE: TETRAMERIC
REMARK 350 SOFTWARE USED: PISA
REMARK 350 TOTAL BURIED SURFACE AREA: 5190 ANGSTROM**2
REMARK 350 SURFACE AREA OF THE COMPLEX: 25220 ANGSTROM**2
REMARK 350 CHANGE IN SOLVENT FREE ENERGY: -27.0 KCAL/MOL
REMARK 350 APPLY THE FOLLOWING TO CHAINS: A, C
REMARK 350   BIOMT1   1  1.000000  0.000000  0.000000        0.00000
REMARK 350   BIOMT2   1  0.000000  1.000000  0.000000        0.00000
REMARK 350   BIOMT3   1  0.000000  0.000000  1.000000        0.00000
REMARK 350   BIOMT1   2 -1.000000  0.000000  0.000000      -43.04696
REMARK 350   BIOMT2   2  0.000000  1.000000  0.000000        0.00000
REMARK 350   BIOMT3   2  0.000000  0.000000 -1.000000       94.23992
REMARK 375
REMARK 375 SPECIAL POSITION
REMARK 375 THE FOLLOWING ATOMS ARE FOUND TO BE WITHIN 0.15 ANGSTROMS
REMARK 375 OF A SYMMETRY RELATED ATOM AND ARE ASSUMED TO BE ON SPECIAL
REMARK 375 POSITIONS.
REMARK 375
REMARK 375 ATOM RES CSSEQI
REMARK 375      HOH A 473  LIES ON A SPECIAL POSITION.
REMARK 400
REMARK 400 COMPOUND
REMARK 400
REMARK 400 THE N-[(5-METHYLISOXAZOL-3-YL)CARBONYL]ALANYL-L-VALYL-N~1~-((1R,2Z)-
REMARK 400 4-(BENZYLOXY)-4-OXO-1-{[(3R)-2-OXOPYRROLIDIN-3-YL]METHYL}BUT-2-ENYL)
REMARK 400 -L-LEUCINAMIDE IS PEPTIDE-LIKE, A MEMBER OF INHIBITOR CLASS.
REMARK 400
REMARK 400  GROUP: 1
REMARK 400   NAME: N-[(5-METHYLISOXAZOL-3-YL)CARBONYL]ALANYL-L-VALYL-N~1~-((1R,
REMARK 400         2Z)-4-(BENZYLOXY)-4-OXO-1-{[(3R)-2-OXOPYRROLIDIN-3-YL]
REMARK 400         METHYL}BUT-2-ENYL)-L-LEUCINAMIDE
REMARK 400   CHAIN: C
REMARK 400   COMPONENT_1: PEPTIDE LIKE POLYMER
REMARK 400   DESCRIPTION: NULL
REMARK 500
REMARK 500 GEOMETRY AND STEREOCHEMISTRY
REMARK 500 SUBTOPIC: COVALENT BOND ANGLES
REMARK 500
REMARK 500 THE STEREOCHEMICAL PARAMETERS OF THE FOLLOWING RESIDUES
REMARK 500 HAVE VALUES WHICH DEVIATE FROM EXPECTED VALUES BY MORE
REMARK 500 THAN 6*RMSD (M=MODEL NUMBER; RES=RESIDUE NAME; C=CHAIN
REMARK 500 IDENTIFIER; SSEQ=SEQUENCE NUMBER; I=INSERTION CODE).
REMARK 500
REMARK 500 STANDARD TABLE:
REMARK 500 FORMAT: (10X,I3,1X,A3,1X,A1,I4,A1,3(1X,A4,2X),12X,F5.1)
REMARK 500
REMARK 500 EXPECTED VALUES PROTEIN: ENGH AND HUBER, 1999
REMARK 500 EXPECTED VALUES NUCLEIC ACID: CLOWNEY ET AL 1996
REMARK 500
REMARK 500  M RES CSSEQI ATM1   ATM2   ATM3
REMARK 500    LEU C   4   CA  -  C   -  N   ANGL. DEV. =  28.9 DEGREES
REMARK 500    LEU C   4   O   -  C   -  N   ANGL. DEV. = -25.9 DEGREES
REMARK 500    PJE C   5   C   -  N   -  CA  ANGL. DEV. =  19.9 DEGREES
REMARK 500
REMARK 500 REMARK: NULL
REMARK 500
REMARK 500 GEOMETRY AND STEREOCHEMISTRY
REMARK 500 SUBTOPIC: TORSION ANGLES
REMARK 500
REMARK 500 TORSION ANGLES OUTSIDE THE EXPECTED RAMACHANDRAN REGIONS:
REMARK 500 (M=MODEL NUMBER; RES=RESIDUE NAME; C=CHAIN IDENTIFIER;
REMARK 500 SSEQ=SEQUENCE NUMBER; I=INSERTION CODE).
REMARK 500
REMARK 500 STANDARD TABLE:
REMARK 500 FORMAT:(10X,I3,1X,A3,1X,A1,I4,A1,4X,F7.2,3X,F7.2)
REMARK 500
REMARK 500 EXPECTED VALUES: GJ KLEYWEGT AND TA JONES (1996). PHI/PSI-
REMARK 500 CHOLOGY: RAMACHANDRAN REVISITED. STRUCTURE 4, 1395 - 1400
REMARK 500
REMARK 500  M RES CSSEQI        PSI       PHI
REMARK 500    ASP A  33     -127.41     56.48
REMARK 500    ASN A  51       80.30   -153.07
REMARK 500    ASN A  84     -110.54     57.92
REMARK 500    TYR A 154      -78.77   -129.98
REMARK 500    PRO A 184       48.48    -85.77
REMARK 500    THR A 304      -61.82   -127.16
REMARK 500
REMARK 500 REMARK: NULL
DBREF  6LU7 A    1   306  UNP    P0DTD1   R1AB_SARS2    3264   3569
DBREF  6LU7 C    1     6  PDB    6LU7     6LU7             1      6
SEQRES   1 A  306  SER GLY PHE ARG LYS MET ALA PHE PRO SER GLY LYS VAL
SEQRES   2 A  306  GLU GLY CYS MET VAL GLN VAL THR CYS GLY THR THR THR
SEQRES   3 A  306  LEU ASN GLY LEU TRP LEU ASP ASP VAL VAL TYR CYS PRO
SEQRES   4 A  306  ARG HIS VAL ILE CYS THR SER GLU ASP MET LEU ASN PRO
SEQRES   5 A  306  ASN TYR GLU ASP LEU LEU ILE ARG LYS SER ASN HIS ASN
SEQRES   6 A  306  PHE LEU VAL GLN ALA GLY ASN VAL GLN LEU ARG VAL ILE
SEQRES   7 A  306  GLY HIS SER MET GLN ASN CYS VAL LEU LYS LEU LYS VAL
SEQRES   8 A  306  ASP THR ALA ASN PRO LYS THR PRO LYS TYR LYS PHE VAL
SEQRES   9 A  306  ARG ILE GLN PRO GLY GLN THR PHE SER VAL LEU ALA CYS
SEQRES  10 A  306  TYR ASN GLY SER PRO SER GLY VAL TYR GLN CYS ALA MET
SEQRES  11 A  306  ARG PRO ASN PHE THR ILE LYS GLY SER PHE LEU ASN GLY
SEQRES  12 A  306  SER CYS GLY SER VAL GLY PHE ASN ILE ASP TYR ASP CYS
SEQRES  13 A  306  VAL SER PHE CYS TYR MET HIS HIS MET GLU LEU PRO THR
SEQRES  14 A  306  GLY VAL HIS ALA GLY THR ASP LEU GLU GLY ASN PHE TYR
SEQRES  15 A  306  GLY PRO PHE VAL ASP ARG GLN THR ALA GLN ALA ALA GLY
SEQRES  16 A  306  THR ASP THR THR ILE THR VAL ASN VAL LEU ALA TRP LEU
SEQRES  17 A  306  TYR ALA ALA VAL ILE ASN GLY ASP ARG TRP PHE LEU ASN
SEQRES  18 A  306  ARG PHE THR THR THR LEU ASN ASP PHE ASN LEU VAL ALA
SEQRES  19 A  306  MET LYS TYR ASN TYR GLU PRO LEU THR GLN ASP HIS VAL
SEQRES  20 A  306  ASP ILE LEU GLY PRO LEU SER ALA GLN THR GLY ILE ALA
SEQRES  21 A  306  VAL LEU ASP MET CYS ALA SER LEU LYS GLU LEU LEU GLN
SEQRES  22 A  306  ASN GLY MET ASN GLY ARG THR ILE LEU GLY SER ALA LEU
SEQRES  23 A  306  LEU GLU ASP GLU PHE THR PRO PHE ASP VAL VAL ARG GLN
SEQRES  24 A  306  CYS SER GLY VAL THR PHE GLN
SEQRES   1 C    6  02J ALA VAL LEU PJE 010
HET    02J  C   1       8
HET    PJE  C   5      13
HET    010  C   6       8
HETNAM     02J 5-METHYL-1,2-OXAZOLE-3-CARBOXYLIC ACID
HETNAM     PJE (E,4S)-4-AZANYL-5-[(3S)-2-OXIDANYLIDENEPYRROLIDIN-3-
HETNAM   2 PJE  YL]PENT-2-ENOIC ACID
HETNAM     010 PHENYLMETHANOL
FORMUL   2  02J    C5 H5 N O3
FORMUL   2  PJE    C9 H14 N2 O3
FORMUL   2  010    C7 H8 O
FORMUL   3  HOH   *84(H2 O)
HELIX    1 AA1 SER A   10  GLY A   15  1                                   6
HELIX    2 AA2 HIS A   41  CYS A   44  5                                   4
HELIX    3 AA3 ASN A   53  ARG A   60  1                                   8
HELIX    4 AA4 SER A   62  PHE A   66  5                                   5
HELIX    5 AA5 ILE A  200  ASN A  214  1                                  15
HELIX    6 AA6 THR A  226  TYR A  237  1                                  12
HELIX    7 AA7 THR A  243  LEU A  250  1                                   8
HELIX    8 AA8 LEU A  250  GLY A  258  1                                   9
HELIX    9 AA9 ALA A  260  GLY A  275  1                                  16
HELIX   10 AB1 THR A  292  SER A  301  1                                  10
SHEET    1 AA1 7 VAL A  73  LEU A  75  0
SHEET    2 AA1 7 LEU A  67  ALA A  70 -1  N  ALA A  70   O  VAL A  73
SHEET    3 AA1 7 MET A  17  CYS A  22 -1  N  THR A  21   O  LEU A  67
SHEET    4 AA1 7 THR A  25  LEU A  32 -1  O  LEU A  27   N  VAL A  20
SHEET    5 AA1 7 VAL A  35  PRO A  39 -1  O  TYR A  37   N  LEU A  30
SHEET    6 AA1 7 VAL A  86  VAL A  91 -1  O  LEU A  87   N  CYS A  38
SHEET    7 AA1 7 VAL A  77  GLN A  83 -1  N  SER A  81   O  LYS A  88
SHEET    1 AA2 5 TYR A 101  PHE A 103  0
SHEET    2 AA2 5 VAL A 157  GLU A 166  1  O  VAL A 157   N  LYS A 102
SHEET    3 AA2 5 VAL A 148  ILE A 152 -1  N  ASN A 151   O  SER A 158
SHEET    4 AA2 5 THR A 111  TYR A 118 -1  N  SER A 113   O  PHE A 150
SHEET    5 AA2 5 SER A 121  ALA A 129 -1  O  CYS A 128   N  PHE A 112
SHEET    1 AA3 3 TYR A 101  PHE A 103  0
SHEET    2 AA3 3 VAL A 157  GLU A 166  1  O  VAL A 157   N  LYS A 102
SHEET    3 AA3 3 HIS A 172  THR A 175 -1  O  ALA A 173   N  MET A 165
LINK         SG  CYS A 145                 C20 PJE C   5     1555   1555  1.79
LINK         C   02J C   1                 N   ALA C   2     1555   1555  1.47
LINK         C   LEU C   4                 N   PJE C   5     1555   1555  1.42
LINK         C   PJE C   5                 O   010 C   6     1555   1555  1.45
CRYST1   97.931   79.477   51.803  90.00 114.55  90.00 C 1 2 1       4
ORIGX1      1.000000  0.000000  0.000000        0.00000
ORIGX2      0.000000  1.000000  0.000000        0.00000
ORIGX3      0.000000  0.000000  1.000000        0.00000
SCALE1      0.010211  0.000000  0.004665        0.00000
SCALE2      0.000000  0.012582  0.000000        0.00000
SCALE3      0.000000  0.000000  0.021223        0.00000
ATOM      1  N   SER A   1     -32.073   9.085  33.695  1.00 38.90           N
ATOM      2  CA  SER A   1     -32.156   8.073  34.741  1.00 37.44           C
ATOM      3  C   SER A   1     -30.857   8.000  35.536  1.00 34.96           C
ATOM      4  O   SER A   1     -30.047   8.926  35.507  1.00 33.29           O
ATOM      5  CB  SER A   1     -32.483   6.704  34.140  1.00 44.07           C
ATOM      6  OG  SER A   1     -31.312   6.067  33.660  1.00 47.56           O
ATOM      7  N   GLY A   2     -30.665   6.892  36.240  1.00 36.02           N
ATOM      8  CA  GLY A   2     -29.510   6.712  37.092  1.00 34.67           C
ATOM      9  C   GLY A   2     -29.828   6.998  38.551  1.00 38.34           C
ATOM     10  O   GLY A   2     -30.810   7.663  38.892  1.00 45.40           O
ATOM     11  N   PHE A   3     -28.974   6.479  39.430  1.00 38.38           N
ATOM     12  CA  PHE A   3     -29.155   6.661  40.866  1.00 36.10           C
ATOM     13  C   PHE A   3     -27.790   6.744  41.527  1.00 44.18           C
ATOM     14  O   PHE A   3     -26.981   5.820  41.399  1.00 40.82           O
ATOM     15  CB  PHE A   3     -29.978   5.522  41.468  1.00 38.52           C
ATOM     16  CG  PHE A   3     -30.635   5.875  42.770  1.00 40.78           C
ATOM     17  CD1 PHE A   3     -31.642   6.824  42.816  1.00 43.38           C
ATOM     18  CD2 PHE A   3     -30.247   5.261  43.949  1.00 40.00           C
ATOM     19  CE1 PHE A   3     -32.251   7.155  44.012  1.00 42.94           C
ATOM     20  CE2 PHE A   3     -30.851   5.586  45.148  1.00 40.35           C
ATOM     21  CZ  PHE A   3     -31.854   6.534  45.179  1.00 43.94           C
ATOM     22  N   ARG A   4     -27.541   7.844  42.233  1.00 39.42           N
ATOM     23  CA  ARG A   4     -26.277   8.066  42.915  1.00 38.88           C
ATOM     24  C   ARG A   4     -26.545   8.642  44.296  1.00 40.55           C
ATOM     25  O   ARG A   4     -27.552   9.320  44.517  1.00 36.11           O
ATOM     26  CB  ARG A   4     -25.367   9.020  42.127  1.00 36.87           C
ATOM     27  CG  ARG A   4     -24.669   8.388  40.936  1.00 42.81           C
ATOM     28  CD  ARG A   4     -23.342   7.771  41.340  1.00 42.72           C
ATOM     29  NE  ARG A   4     -22.460   7.579  40.193  1.00 47.79           N
ATOM     30  CZ  ARG A   4     -21.235   7.068  40.270  1.00 53.27           C
ATOM     31  NH1 ARG A   4     -20.744   6.693  41.443  1.00 47.42           N
ATOM     32  NH2 ARG A   4     -20.502   6.930  39.173  1.00 49.36           N
ATOM     33  N   LYS A   5     -25.636   8.362  45.227  1.00 34.78           N
ATOM     34  CA  LYS A   5     -25.667   9.020  46.528  1.00 36.92           C
ATOM     35  C   LYS A   5     -25.399  10.504  46.317  1.00 32.31           C
ATOM     36  O   LYS A   5     -24.261  10.908  46.053  1.00 37.11           O
ATOM     37  CB  LYS A   5     -24.643   8.396  47.471  1.00 39.24           C
ATOM     38  CG  LYS A   5     -25.062   8.413  48.934  1.00 39.96           C
ATOM     39  CD  LYS A   5     -24.326   7.353  49.740  1.00 43.24           C
ATOM     40  CE  LYS A   5     -24.965   5.984  49.571  1.00 48.83           C
ATOM     41  NZ  LYS A   5     -24.162   4.914  50.226  1.00 58.11           N
ATOM     42  N   MET A   6     -26.446  11.316  46.414  1.00 25.57           N
ATOM     43  CA  MET A   6     -26.400  12.716  46.023  1.00 36.94           C
ATOM     44  C   MET A   6     -26.467  13.607  47.255  1.00 30.91           C
ATOM     45  O   MET A   6     -27.368  13.459  48.088  1.00 28.98           O
ATOM     46  CB  MET A   6     -27.548  13.047  45.068  1.00 32.35           C
ATOM     47  CG  MET A   6     -27.370  14.341  44.297  1.00 41.91           C
ATOM     48  SD  MET A   6     -28.586  14.521  42.977  1.00 50.06           S
ATOM     49  CE  MET A   6     -28.245  13.061  41.999  1.00 42.04           C
ATOM     50  N   ALA A   7     -25.516  14.525  47.363  1.00 24.70           N
ATOM     51  CA  ALA A   7     -25.496  15.526  48.417  1.00 31.84           C
ATOM     52  C   ALA A   7     -26.118  16.824  47.917  1.00 39.13           C
ATOM     53  O   ALA A   7     -26.143  17.108  46.717  1.00 30.67           O
ATOM     54  CB  ALA A   7     -24.065  15.781  48.898  1.00 31.91           C
ATOM     55  N   PHE A   8     -26.629  17.612  48.859  1.00 35.53           N
ATOM     56  CA  PHE A   8     -27.182  18.909  48.513  1.00 34.19           C
ATOM     57  C   PHE A   8     -26.060  19.874  48.132  1.00 35.86           C
ATOM     58  O   PHE A   8     -24.929  19.740  48.607  1.00 37.71           O
ATOM     59  CB  PHE A   8     -27.983  19.479  49.680  1.00 31.88           C
ATOM     60  CG  PHE A   8     -29.339  18.855  49.850  1.00 34.04           C
ATOM     61  CD1 PHE A   8     -30.396  19.234  49.041  1.00 37.68           C
ATOM     62  CD2 PHE A   8     -29.557  17.893  50.822  1.00 32.78           C
ATOM     63  CE1 PHE A   8     -31.646  18.663  49.196  1.00 38.75           C
ATOM     64  CE2 PHE A   8     -30.804  17.319  50.982  1.00 35.47           C
ATOM     65  CZ  PHE A   8     -31.849  17.705  50.168  1.00 40.42           C
ATOM     66  N   PRO A   9     -26.342  20.843  47.261  1.00 35.98           N
ATOM     67  CA  PRO A   9     -25.340  21.872  46.956  1.00 37.27           C
ATOM     68  C   PRO A   9     -24.923  22.612  48.219  1.00 33.84           C
ATOM     69  O   PRO A   9     -25.761  23.012  49.030  1.00 38.14           O
ATOM     70  CB  PRO A   9     -26.066  22.790  45.966  1.00 38.03           C
ATOM     71  CG  PRO A   9     -27.105  21.918  45.343  1.00 42.20           C
ATOM     72  CD  PRO A   9     -27.551  20.986  46.433  1.00 40.32           C
ATOM     73  N   SER A  10     -23.613  22.787  48.383  1.00 35.35           N
ATOM     74  CA  SER A  10     -23.037  23.278  49.626  1.00 38.41           C
ATOM     75  C   SER A  10     -22.730  24.770  49.600  1.00 37.76           C
ATOM     76  O   SER A  10     -22.106  25.275  50.538  1.00 34.95           O
ATOM     77  CB  SER A  10     -21.762  22.498  49.953  1.00 33.59           C
ATOM     78  OG  SER A  10     -20.780  22.691  48.950  1.00 36.17           O
ATOM     79  N   GLY A  11     -23.156  25.485  48.556  1.00 41.13           N
ATOM     80  CA  GLY A  11     -22.802  26.892  48.440  1.00 38.60           C
ATOM     81  C   GLY A  11     -23.316  27.735  49.592  1.00 38.39           C
ATOM     82  O   GLY A  11     -22.598  28.590  50.117  1.00 46.36           O
ATOM     83  N   LYS A  12     -24.564  27.505  50.005  1.00 38.09           N
ATOM     84  CA  LYS A  12     -25.146  28.293  51.086  1.00 36.59           C
ATOM     85  C   LYS A  12     -24.552  27.946  52.445  1.00 39.48           C
ATOM     86  O   LYS A  12     -24.652  28.754  53.375  1.00 40.17           O
ATOM     87  CB  LYS A  12     -26.663  28.106  51.107  1.00 42.83           C
ATOM     88  CG  LYS A  12     -27.354  28.626  49.856  1.00 42.78           C
ATOM     89  CD  LYS A  12     -28.638  27.867  49.562  1.00 57.42           C
ATOM     90  CE  LYS A  12     -29.857  28.610  50.085  1.00 57.45           C
ATOM     91  NZ  LYS A  12     -30.058  28.392  51.544  1.00 55.48           N
ATOM     92  N   VAL A  13     -23.940  26.773  52.582  1.00 38.78           N
ATOM     93  CA  VAL A  13     -23.264  26.421  53.825  1.00 36.52           C
ATOM     94  C   VAL A  13     -21.793  26.832  53.796  1.00 35.77           C
ATOM     95  O   VAL A  13     -21.223  27.157  54.843  1.00 39.87           O
ATOM     96  CB  VAL A  13     -23.418  24.915  54.103  1.00 33.81           C
ATOM     97  CG1 VAL A  13     -22.767  24.538  55.426  1.00 35.72           C
ATOM     98  CG2 VAL A  13     -24.889  24.527  54.104  1.00 33.49           C
ATOM     99  N   GLU A  14     -21.172  26.840  52.613  1.00 31.88           N
ATOM    100  CA  GLU A  14     -19.769  27.233  52.505  1.00 38.31           C
ATOM    101  C   GLU A  14     -19.544  28.649  53.024  1.00 39.80           C
ATOM    102  O   GLU A  14     -18.514  28.932  53.648  1.00 36.53           O
ATOM    103  CB  GLU A  14     -19.305  27.122  51.052  1.00 41.13           C
ATOM    104  CG  GLU A  14     -18.957  25.712  50.607  1.00 41.37           C
ATOM    105  CD  GLU A  14     -18.897  25.579  49.098  1.00 41.45           C
ATOM    106  OE1 GLU A  14     -19.869  25.067  48.504  1.00 37.79           O
ATOM    107  OE2 GLU A  14     -17.877  25.989  48.505  1.00 40.99           O
ATOM    108  N   GLY A  15     -20.495  29.549  52.781  1.00 34.24           N
ATOM    109  CA  GLY A  15     -20.362  30.928  53.211  1.00 40.10           C
ATOM    110  C   GLY A  15     -20.501  31.162  54.700  1.00 43.58           C
ATOM    111  O   GLY A  15     -20.387  32.309  55.140  1.00 42.28           O
ATOM    112  N   CYS A  16     -20.739  30.114  55.488  1.00 36.43           N
ATOM    113  CA  CYS A  16     -20.917  30.244  56.927  1.00 35.87           C
ATOM    114  C   CYS A  16     -19.815  29.573  57.732  1.00 33.79           C
ATOM    115  O   CYS A  16     -19.841  29.644  58.965  1.00 36.56           O
ATOM    116  CB  CYS A  16     -22.275  29.667  57.345  1.00 36.64           C
ATOM    117  SG  CYS A  16     -23.654  30.190  56.312  1.00 50.68           S
ATOM    118  N   MET A  17     -18.852  28.928  57.079  1.00 30.44           N
ATOM    119  CA  MET A  17     -17.799  28.220  57.794  1.00 32.33           C
ATOM    120  C   MET A  17     -16.675  29.177  58.171  1.00 39.30           C
ATOM    121  O   MET A  17     -16.188  29.942  57.332  1.00 39.57           O
ATOM    122  CB  MET A  17     -17.256  27.071  56.946  1.00 35.47           C
ATOM    123  CG  MET A  17     -18.289  26.004  56.621  1.00 34.34           C
ATOM    124  SD  MET A  17     -19.267  25.517  58.058  1.00 44.04           S
ATOM    125  CE  MET A  17     -17.998  24.861  59.141  1.00 32.95           C
ATOM    126  N   VAL A  18     -16.269  29.134  59.440  1.00 30.96           N
ATOM    127  CA  VAL A  18     -15.179  29.950  59.953  1.00 35.69           C
ATOM    128  C   VAL A  18     -14.221  29.049  60.721  1.00 36.49           C
ATOM    129  O   VAL A  18     -14.511  27.885  60.999  1.00 32.93           O
ATOM    130  CB  VAL A  18     -15.676  31.100  60.854  1.00 33.25           C
ATOM    131  CG1 VAL A  18     -16.514  32.083  60.052  1.00 34.40           C
ATOM    132  CG2 VAL A  18     -16.465  30.548  62.031  1.00 32.86           C
ATOM    133  N   GLN A  19     -13.064  29.608  61.058  1.00 35.39           N
ATOM    134  CA  GLN A  19     -12.057  28.923  61.855  1.00 36.84           C
ATOM    135  C   GLN A  19     -12.040  29.523  63.253  1.00 40.23           C
ATOM    136  O   GLN A  19     -11.925  30.743  63.408  1.00 37.54           O
ATOM    137  CB  GLN A  19     -10.673  29.035  61.211  1.00 40.75           C
ATOM    138  CG  GLN A  19      -9.561  28.393  62.024  1.00 44.72           C
ATOM    139  CD  GLN A  19      -8.189  28.636  61.429  1.00 52.55           C
ATOM    140  OE1 GLN A  19      -7.581  27.734  60.852  1.00 53.65           O
ATOM    141  NE2 GLN A  19      -7.693  29.860  61.566  1.00 57.38           N
ATOM    142  N   VAL A  20     -12.162  28.667  64.263  1.00 37.22           N
ATOM    143  CA  VAL A  20     -12.179  29.083  65.661  1.00 37.75           C
ATOM    144  C   VAL A  20     -10.928  28.533  66.329  1.00 39.41           C
ATOM    145  O   VAL A  20     -10.678  27.322  66.294  1.00 40.62           O
ATOM    146  CB  VAL A  20     -13.447  28.601  66.381  1.00 35.93           C
ATOM    147  CG1 VAL A  20     -13.469  29.112  67.814  1.00 35.66           C
ATOM    148  CG2 VAL A  20     -14.688  29.051  65.627  1.00 29.32           C
ATOM    149  N   THR A  21     -10.144  29.419  66.937  1.00 42.33           N
ATOM    150  CA  THR A  21      -8.913  29.045  67.619  1.00 39.43           C
ATOM    151  C   THR A  21      -8.969  29.519  69.064  1.00 42.16           C
ATOM    152  O   THR A  21      -9.258  30.691  69.327  1.00 39.79           O
ATOM    153  CB  THR A  21      -7.684  29.636  66.921  1.00 43.87           C
ATOM    154  OG1 THR A  21      -7.653  29.201  65.555  1.00 49.18           O
ATOM    155  CG2 THR A  21      -6.406  29.187  67.616  1.00 46.67           C
ATOM    156  N   CYS A  22      -8.699  28.605  69.992  1.00 46.16           N
ATOM    157  CA  CYS A  22      -8.591  28.916  71.416  1.00 42.65           C
ATOM    158  C   CYS A  22      -7.289  28.291  71.905  1.00 47.48           C
ATOM    159  O   CYS A  22      -7.209  27.073  72.087  1.00 47.27           O
ATOM    160  CB  CYS A  22      -9.794  28.394  72.196  1.00 35.21           C
ATOM    161  SG  CYS A  22      -9.850  28.921  73.924  1.00 57.47           S
ATOM    162  N   GLY A  23      -6.271  29.123  72.106  1.00 43.57           N
ATOM    163  CA  GLY A  23      -4.951  28.628  72.441  1.00 48.54           C
ATOM    164  C   GLY A  23      -4.291  27.961  71.253  1.00 48.65           C
ATOM    165  O   GLY A  23      -4.089  28.592  70.211  1.00 58.32           O
ATOM    166  N   THR A  24      -3.956  26.681  71.392  1.00 46.27           N
ATOM    167  CA  THR A  24      -3.402  25.902  70.294  1.00 54.16           C
ATOM    168  C   THR A  24      -4.429  24.989  69.640  1.00 51.12           C
ATOM    169  O   THR A  24      -4.066  24.193  68.768  1.00 57.86           O
ATOM    170  CB  THR A  24      -2.213  25.067  70.778  1.00 52.71           C
ATOM    171  OG1 THR A  24      -2.574  24.354  71.966  1.00 57.38           O
ATOM    172  CG2 THR A  24      -1.016  25.962  71.073  1.00 54.72           C
ATOM    173  N   THR A  25      -5.695  25.080  70.036  1.00 50.73           N
ATOM    174  CA  THR A  25      -6.750  24.247  69.473  1.00 48.80           C
ATOM    175  C   THR A  25      -7.468  25.013  68.371  1.00 45.57           C
ATOM    176  O   THR A  25      -7.907  26.147  68.585  1.00 42.11           O
ATOM    177  CB  THR A  25      -7.747  23.818  70.551  1.00 45.82           C
ATOM    178  OG1 THR A  25      -7.040  23.274  71.673  1.00 52.62           O
ATOM    179  CG2 THR A  25      -8.709  22.779  70.002  1.00 44.98           C
ATOM    180  N   THR A  26      -7.589  24.392  67.200  1.00 46.56           N
ATOM    181  CA  THR A  26      -8.224  25.031  66.060  1.00 43.98           C
ATOM    182  C   THR A  26      -9.218  24.075  65.426  1.00 39.56           C
ATOM    183  O   THR A  26      -8.883  22.919  65.137  1.00 42.53           O
ATOM    184  CB  THR A  26      -7.184  25.490  65.034  1.00 45.90           C
ATOM    185  OG1 THR A  26      -7.836  25.859  63.814  1.00 50.51           O
ATOM    186  CG2 THR A  26      -6.155  24.392  64.757  1.00 47.20           C
ATOM    187  N   LEU A  27     -10.433  24.568  65.192  1.00 36.95           N
ATOM    188  CA  LEU A  27     -11.471  23.771  64.549  1.00 37.04           C
ATOM    189  C   LEU A  27     -12.390  24.710  63.786  1.00 38.51           C
ATOM    190  O   LEU A  27     -12.154  25.920  63.720  1.00 29.66           O
ATOM    191  CB  LEU A  27     -12.225  22.902  65.570  1.00 37.70           C
ATOM    192  CG  LEU A  27     -12.639  23.447  66.937  1.00 36.32           C
ATOM    193  CD1 LEU A  27     -13.847  24.364  66.831  1.00 32.79           C
ATOM    194  CD2 LEU A  27     -12.936  22.285  67.873  1.00 31.17           C
ATOM    195  N   ASN A  28     -13.448  24.141  63.222  1.00 30.29           N
ATOM    196  CA  ASN A  28     -14.372  24.876  62.380  1.00 32.66           C
ATOM    197  C   ASN A  28     -15.551  25.393  63.193  1.00 27.62           C
ATOM    198  O   ASN A  28     -15.959  24.793  64.189  1.00 31.18           O
ATOM    199  CB  ASN A  28     -14.877  23.989  61.240  1.00 30.89           C
ATOM    200  CG  ASN A  28     -13.749  23.365  60.446  1.00 32.19           C
ATOM    201  OD1 ASN A  28     -13.172  24.000  59.565  1.00 33.71           O
ATOM    202  ND2 ASN A  28     -13.426  22.114  60.755  1.00 35.87           N
ATOM    203  N   GLY A  29     -16.098  26.525  62.749  1.00 28.97           N
ATOM    204  CA  GLY A  29     -17.267  27.095  63.375  1.00 29.43           C
ATOM    205  C   GLY A  29     -18.309  27.457  62.335  1.00 29.30           C
ATOM    206  O   GLY A  29     -18.006  27.655  61.159  1.00 30.37           O
ATOM    207  N   LEU A  30     -19.555  27.536  62.795  1.00 30.94           N
ATOM    208  CA  LEU A  30     -20.692  27.883  61.949  1.00 31.77           C
ATOM    209  C   LEU A  30     -21.108  29.312  62.273  1.00 31.91           C
ATOM    210  O   LEU A  30     -21.612  29.586  63.367  1.00 32.56           O
ATOM    211  CB  LEU A  30     -21.848  26.907  62.161  1.00 31.92           C
ATOM    212  CG  LEU A  30     -22.986  26.989  61.143  1.00 31.71           C
ATOM    213  CD1 LEU A  30     -22.489  26.629  59.750  1.00 32.72           C
ATOM    214  CD2 LEU A  30     -24.140  26.089  61.552  1.00 30.45           C
ATOM    215  N   TRP A  31     -20.902  30.218  61.319  1.00 33.46           N
ATOM    216  CA  TRP A  31     -21.166  31.644  61.503  1.00 31.27           C
ATOM    217  C   TRP A  31     -22.534  31.965  60.907  1.00 34.76           C
ATOM    218  O   TRP A  31     -22.679  32.092  59.688  1.00 31.78           O
ATOM    219  CB  TRP A  31     -20.062  32.478  60.860  1.00 34.28           C
ATOM    220  CG  TRP A  31     -20.199  33.957  61.074  1.00 38.48           C
ATOM    221  CD1 TRP A  31     -20.963  34.587  62.015  1.00 35.41           C
ATOM    222  CD2 TRP A  31     -19.548  34.993  60.329  1.00 37.93           C
ATOM    223  NE1 TRP A  31     -20.829  35.949  61.899  1.00 38.91           N
ATOM    224  CE2 TRP A  31     -19.965  36.225  60.871  1.00 41.14           C
ATOM    225  CE3 TRP A  31     -18.654  34.998  59.254  1.00 40.41           C
ATOM    226  CZ2 TRP A  31     -19.519  37.448  60.376  1.00 40.49           C
ATOM    227  CZ3 TRP A  31     -18.212  36.214  58.763  1.00 47.09           C
ATOM    228  CH2 TRP A  31     -18.645  37.421  59.324  1.00 42.02           C
ATOM    229  N   LEU A  32     -23.535  32.098  61.773  1.00 29.78           N
ATOM    230  CA  LEU A  32     -24.892  32.456  61.377  1.00 31.50           C
ATOM    231  C   LEU A  32     -25.290  33.722  62.120  1.00 39.34           C
ATOM    232  O   LEU A  32     -25.246  33.758  63.355  1.00 36.42           O
ATOM    233  CB  LEU A  32     -25.874  31.323  61.679  1.00 38.19           C
ATOM    234  CG  LEU A  32     -25.647  30.004  60.938  1.00 34.57           C
ATOM    235  CD1 LEU A  32     -26.558  28.924  61.493  1.00 33.34           C
ATOM    236  CD2 LEU A  32     -25.868  30.181  59.445  1.00 32.67           C
ATOM    237  N   ASP A  33     -25.681  34.750  61.369  1.00 39.86           N
ATOM    238  CA  ASP A  33     -26.000  36.076  61.916  1.00 40.96           C
ATOM    239  C   ASP A  33     -24.764  36.546  62.682  1.00 39.17           C
ATOM    240  O   ASP A  33     -23.659  36.526  62.117  1.00 41.90           O
ATOM    241  CB  ASP A  33     -27.293  36.008  62.721  1.00 41.67           C
ATOM    242  CG  ASP A  33     -28.466  35.509  61.898  1.00 50.38           C
ATOM    243  OD1 ASP A  33     -28.621  35.962  60.745  1.00 52.37           O
ATOM    244  OD2 ASP A  33     -29.231  34.661  62.404  1.00 53.30           O
ATOM    245  N   ASP A  34     -24.885  36.953  63.945  1.00 36.41           N
ATOM    246  CA  ASP A  34     -23.749  37.410  64.736  1.00 40.57           C
ATOM    247  C   ASP A  34     -23.337  36.386  65.790  1.00 40.01           C
ATOM    248  O   ASP A  34     -22.807  36.750  66.843  1.00 37.93           O
ATOM    249  CB  ASP A  34     -24.063  38.754  65.393  1.00 42.76           C
ATOM    250  CG  ASP A  34     -25.304  38.701  66.263  1.00 51.09           C
ATOM    251  OD1 ASP A  34     -26.354  38.231  65.777  1.00 57.36           O
ATOM    252  OD2 ASP A  34     -25.229  39.131  67.434  1.00 52.82           O
ATOM    253  N   VAL A  35     -23.574  35.103  65.523  1.00 35.15           N
ATOM    254  CA  VAL A  35     -23.243  34.028  66.449  1.00 34.04           C
ATOM    255  C   VAL A  35     -22.415  32.985  65.712  1.00 35.49           C
ATOM    256  O   VAL A  35     -22.720  32.631  64.568  1.00 32.04           O
ATOM    257  CB  VAL A  35     -24.510  33.385  67.055  1.00 33.33           C
ATOM    258  CG1 VAL A  35     -24.134  32.278  68.031  1.00 31.88           C
ATOM    259  CG2 VAL A  35     -25.366  34.437  67.745  1.00 32.07           C
ATOM    260  N   VAL A  36     -21.366  32.496  66.369  1.00 31.67           N
ATOM    261  CA  VAL A  36     -20.511  31.442  65.835  1.00 34.48           C
ATOM    262  C   VAL A  36     -20.680  30.210  66.712  1.00 34.24           C
ATOM    263  O   VAL A  36     -20.465  30.269  67.929  1.00 34.29           O
ATOM    264  CB  VAL A  36     -19.039  31.878  65.775  1.00 31.91           C
ATOM    265  CG1 VAL A  36     -18.153  30.695  65.414  1.00 30.09           C
ATOM    266  CG2 VAL A  36     -18.862  33.006  64.773  1.00 31.39           C
ATOM    267  N   TYR A  37     -21.067  29.096  66.097  1.00 30.91           N
ATOM    268  CA  TYR A  37     -21.262  27.834  66.796  1.00 25.81           C
ATOM    269  C   TYR A  37     -20.089  26.911  66.499  1.00 33.31           C
ATOM    270  O   TYR A  37     -19.680  26.776  65.342  1.00 33.64           O
ATOM    271  CB  TYR A  37     -22.573  27.166  66.374  1.00 31.93           C
ATOM    272  CG  TYR A  37     -23.799  28.049  66.479  1.00 28.04           C
ATOM    273  CD1 TYR A  37     -24.106  28.970  65.485  1.00 30.82           C
ATOM    274  CD2 TYR A  37     -24.657  27.951  67.567  1.00 29.93           C
ATOM    275  CE1 TYR A  37     -25.225  29.775  65.576  1.00 24.79           C
ATOM    276  CE2 TYR A  37     -25.780  28.750  67.665  1.00 28.93           C
ATOM    277  CZ  TYR A  37     -26.059  29.660  66.667  1.00 30.20           C
ATOM    278  OH  TYR A  37     -27.174  30.458  66.762  1.00 32.62           O
ATOM    279  N   CYS A  38     -19.555  26.277  67.537  1.00 28.03           N
ATOM    280  CA  CYS A  38     -18.419  25.382  67.383  1.00 29.55           C
ATOM    281  C   CYS A  38     -18.436  24.379  68.527  1.00 30.64           C
ATOM    282  O   CYS A  38     -19.055  24.631  69.566  1.00 29.00           O
ATOM    283  CB  CYS A  38     -17.094  26.158  67.362  1.00 33.99           C
ATOM    284  SG  CYS A  38     -16.612  26.882  68.946  1.00 39.36           S
ATOM    285  N   PRO A  39     -17.786  23.226  68.357  1.00 28.03           N
ATOM    286  CA  PRO A  39     -17.701  22.266  69.465  1.00 30.12           C
ATOM    287  C   PRO A  39     -16.998  22.878  70.667  1.00 30.67           C
ATOM    288  O   PRO A  39     -16.035  23.635  70.530  1.00 33.30           O
ATOM    289  CB  PRO A  39     -16.894  21.107  68.869  1.00 27.83           C
ATOM    290  CG  PRO A  39     -17.094  21.224  67.394  1.00 32.19           C
ATOM    291  CD  PRO A  39     -17.189  22.695  67.120  1.00 32.72           C
ATOM    292  N   ARG A  40     -17.494  22.542  71.859  1.00 30.74           N
ATOM    293  CA  ARG A  40     -16.988  23.157  73.078  1.00 37.68           C
ATOM    294  C   ARG A  40     -15.606  22.656  73.474  1.00 35.98           C
ATOM    295  O   ARG A  40     -14.947  23.300  74.296  1.00 38.97           O
ATOM    296  CB  ARG A  40     -17.967  22.928  74.233  1.00 36.25           C
ATOM    297  CG  ARG A  40     -18.007  21.502  74.755  1.00 35.21           C
ATOM    298  CD  ARG A  40     -18.840  21.416  76.025  1.00 38.69           C
ATOM    299  NE  ARG A  40     -19.094  20.034  76.424  1.00 38.85           N
ATOM    300  CZ  ARG A  40     -19.794  19.685  77.498  1.00 36.84           C
ATOM    301  NH1 ARG A  40     -20.308  20.617  78.289  1.00 37.14           N
ATOM    302  NH2 ARG A  40     -19.975  18.403  77.785  1.00 35.64           N
ATOM    303  N   HIS A  41     -15.145  21.538  72.916  1.00 35.84           N
ATOM    304  CA  HIS A  41     -13.820  21.037  73.265  1.00 38.89           C
ATOM    305  C   HIS A  41     -12.697  21.814  72.589  1.00 37.13           C
ATOM    306  O   HIS A  41     -11.539  21.391  72.668  1.00 39.19           O
ATOM    307  CB  HIS A  41     -13.705  19.545  72.937  1.00 42.38           C
ATOM    308  CG  HIS A  41     -13.826  19.231  71.479  1.00 37.90           C
ATOM    309  ND1 HIS A  41     -14.947  18.638  70.939  1.00 36.57           N
ATOM    310  CD2 HIS A  41     -12.964  19.414  70.451  1.00 37.33           C
ATOM    311  CE1 HIS A  41     -14.774  18.477  69.640  1.00 41.55           C
ATOM    312  NE2 HIS A  41     -13.579  18.940  69.318  1.00 41.88           N
ATOM    313  N   VAL A  42     -13.011  22.932  71.930  1.00 35.20           N
ATOM    314  CA  VAL A  42     -11.972  23.826  71.436  1.00 39.75           C
ATOM    315  C   VAL A  42     -11.252  24.518  72.587  1.00 40.51           C
ATOM    316  O   VAL A  42     -10.132  25.010  72.411  1.00 45.18           O
ATOM    317  CB  VAL A  42     -12.576  24.854  70.458  1.00 34.88           C
ATOM    318  CG1 VAL A  42     -13.447  25.854  71.200  1.00 28.67           C
ATOM    319  CG2 VAL A  42     -11.482  25.565  69.669  1.00 37.02           C
ATOM    320  N   ILE A  43     -11.866  24.557  73.776  1.00 39.40           N
ATOM    321  CA  ILE A  43     -11.235  25.160  74.945  1.00 45.41           C
ATOM    322  C   ILE A  43     -10.322  24.190  75.681  1.00 44.13           C
ATOM    323  O   ILE A  43      -9.723  24.569  76.697  1.00 53.91           O
ATOM    324  CB  ILE A  43     -12.293  25.705  75.927  1.00 38.96           C
ATOM    325  CG1 ILE A  43     -13.044  24.551  76.594  1.00 39.31           C
ATOM    326  CG2 ILE A  43     -13.257  26.636  75.211  1.00 39.80           C
ATOM    327  CD1 ILE A  43     -14.181  24.995  77.490  1.00 37.22           C
ATOM    328  N   CYS A  44     -10.196  22.955  75.208  1.00 49.20           N
ATOM    329  CA  CYS A  44      -9.356  21.954  75.847  1.00 50.36           C
ATOM    330  C   CYS A  44      -8.022  21.818  75.124  1.00 56.74           C
ATOM    331  O   CYS A  44      -7.859  22.241  73.976  1.00 54.23           O
ATOM    332  CB  CYS A  44     -10.057  20.592  75.883  1.00 52.03           C
ATOM    333  SG  CYS A  44     -11.790  20.639  76.366  1.00 57.89           S
ATOM    334  N   THR A  45      -7.064  21.219  75.820  1.00 61.24           N
ATOM    335  CA  THR A  45      -5.783  20.824  75.253  1.00 65.40           C
ATOM    336  C   THR A  45      -5.623  19.313  75.424  1.00 65.43           C
ATOM    337  O   THR A  45      -6.547  18.617  75.855  1.00 65.90           O
ATOM    338  CB  THR A  45      -4.635  21.607  75.896  1.00 69.65           C
ATOM    339  OG1 THR A  45      -3.438  21.416  75.131  1.00 75.39           O
ATOM    340  CG2 THR A  45      -4.399  21.145  77.328  1.00 66.61           C
ATOM    341  N   SER A  46      -4.437  18.804  75.082  1.00 68.52           N
ATOM    342  CA  SER A  46      -4.225  17.360  75.096  1.00 68.51           C
ATOM    343  C   SER A  46      -4.359  16.773  76.496  1.00 69.36           C
ATOM    344  O   SER A  46      -4.830  15.640  76.649  1.00 71.42           O
ATOM    345  CB  SER A  46      -2.855  17.028  74.504  1.00 67.95           C
ATOM    346  OG  SER A  46      -2.750  17.507  73.174  1.00 67.72           O
ATOM    347  N   GLU A  47      -3.965  17.521  77.527  1.00 70.96           N
ATOM    348  CA  GLU A  47      -4.049  17.023  78.895  1.00 70.54           C
ATOM    349  C   GLU A  47      -5.452  17.117  79.482  1.00 69.09           C
ATOM    350  O   GLU A  47      -5.693  16.560  80.559  1.00 69.43           O
ATOM    351  CB  GLU A  47      -3.065  17.782  79.791  1.00 71.46           C
ATOM    352  CG  GLU A  47      -2.573  16.982  80.987  1.00 79.64           C
ATOM    353  CD  GLU A  47      -1.180  17.384  81.429  1.00 85.00           C
ATOM    354  OE1 GLU A  47      -1.058  18.045  82.481  1.00 85.44           O
ATOM    355  OE2 GLU A  47      -0.209  17.036  80.725  1.00 82.68           O
ATOM    356  N   ASP A  48      -6.379  17.796  78.807  1.00 67.45           N
ATOM    357  CA  ASP A  48      -7.738  17.946  79.309  1.00 66.01           C
ATOM    358  C   ASP A  48      -8.702  16.904  78.758  1.00 61.87           C
ATOM    359  O   ASP A  48      -9.706  16.606  79.414  1.00 57.55           O
ATOM    360  CB  ASP A  48      -8.281  19.339  78.967  1.00 67.91           C
ATOM    361  CG  ASP A  48      -7.577  20.447  79.727  1.00 70.82           C
ATOM    362  OD1 ASP A  48      -7.511  20.368  80.972  1.00 74.63           O
ATOM    363  OD2 ASP A  48      -7.100  21.402  79.079  1.00 65.47           O
ATOM    364  N   MET A  49      -8.413  16.332  77.588  1.00 64.80           N
ATOM    365  CA  MET A  49      -9.418  15.600  76.823  1.00 61.58           C
ATOM    366  C   MET A  49      -9.793  14.249  77.421  1.00 54.59           C
ATOM    367  O   MET A  49     -10.736  13.623  76.925  1.00 50.82           O
ATOM    368  CB  MET A  49      -8.930  15.410  75.386  1.00 62.15           C
ATOM    369  CG  MET A  49      -8.934  16.691  74.567  1.00 60.24           C
ATOM    370  SD  MET A  49      -8.229  16.487  72.920  1.00 77.15           S
ATOM    371  CE  MET A  49      -8.703  18.038  72.158  1.00 60.34           C
ATOM    372  N   LEU A  50      -9.098  13.777  78.459  1.00 55.58           N
ATOM    373  CA  LEU A  50      -9.472  12.497  79.056  1.00 50.20           C
ATOM    374  C   LEU A  50     -10.771  12.620  79.843  1.00 50.23           C
ATOM    375  O   LEU A  50     -11.702  11.828  79.656  1.00 53.98           O
ATOM    376  CB  LEU A  50      -8.348  11.972  79.949  1.00 56.38           C
ATOM    377  CG  LEU A  50      -8.686  10.683  80.706  1.00 56.22           C
ATOM    378  CD1 LEU A  50      -9.083   9.574  79.738  1.00 54.68           C
ATOM    379  CD2 LEU A  50      -7.524  10.242  81.582  1.00 61.06           C
ATOM    380  N   ASN A  51     -10.852  13.606  80.734  1.00 54.23           N
ATOM    381  CA  ASN A  51     -12.073  13.874  81.495  1.00 56.71           C
ATOM    382  C   ASN A  51     -12.099  15.344  81.875  1.00 53.53           C
ATOM    383  O   ASN A  51     -11.754  15.724  83.001  1.00 59.55           O
ATOM    384  CB  ASN A  51     -12.165  12.987  82.737  1.00 60.04           C
ATOM    385  CG  ASN A  51     -13.429  13.238  83.538  1.00 66.55           C
ATOM    386  OD1 ASN A  51     -14.540  13.012  83.056  1.00 68.23           O
ATOM    387  ND2 ASN A  51     -13.266  13.713  84.768  1.00 64.37           N
ATOM    388  N   PRO A  52     -12.498  16.214  80.948  1.00 51.24           N
ATOM    389  CA  PRO A  52     -12.552  17.645  81.255  1.00 51.92           C
ATOM    390  C   PRO A  52     -13.895  18.065  81.828  1.00 44.57           C
ATOM    391  O   PRO A  52     -14.959  17.609  81.403  1.00 48.93           O
ATOM    392  CB  PRO A  52     -12.320  18.290  79.883  1.00 53.34           C
ATOM    393  CG  PRO A  52     -12.940  17.322  78.923  1.00 53.26           C
ATOM    394  CD  PRO A  52     -12.813  15.939  79.534  1.00 52.84           C
ATOM    395  N   ASN A  53     -13.837  18.944  82.824  1.00 42.70           N
ATOM    396  CA  ASN A  53     -15.027  19.629  83.318  1.00 41.54           C
ATOM    397  C   ASN A  53     -15.147  20.918  82.518  1.00 45.98           C
ATOM    398  O   ASN A  53     -14.410  21.880  82.750  1.00 43.49           O
ATOM    399  CB  ASN A  53     -14.935  19.889  84.817  1.00 47.59           C
ATOM    400  CG  ASN A  53     -16.245  20.385  85.400  1.00 50.78           C
ATOM    401  OD1 ASN A  53     -16.730  21.458  85.043  1.00 49.11           O
ATOM    402  ND2 ASN A  53     -16.830  19.598  86.295  1.00 58.51           N
ATOM    403  N   TYR A  54     -16.074  20.929  81.559  1.00 41.02           N
ATOM    404  CA  TYR A  54     -16.130  22.020  80.594  1.00 37.74           C
ATOM    405  C   TYR A  54     -16.556  23.333  81.234  1.00 36.00           C
ATOM    406  O   TYR A  54     -16.115  24.400  80.794  1.00 38.89           O
ATOM    407  CB  TYR A  54     -17.070  21.652  79.448  1.00 36.00           C
ATOM    408  CG  TYR A  54     -16.478  20.641  78.495  1.00 32.02           C
ATOM    409  CD1 TYR A  54     -15.662  21.044  77.448  1.00 36.25           C
ATOM    410  CD2 TYR A  54     -16.725  19.284  78.648  1.00 36.73           C
ATOM    411  CE1 TYR A  54     -15.118  20.125  76.574  1.00 40.78           C
ATOM    412  CE2 TYR A  54     -16.184  18.357  77.779  1.00 37.10           C
ATOM    413  CZ  TYR A  54     -15.380  18.784  76.744  1.00 36.90           C
ATOM    414  OH  TYR A  54     -14.835  17.868  75.874  1.00 37.87           O
ATOM    415  N   GLU A  55     -17.407  23.288  82.262  1.00 37.41           N
ATOM    416  CA  GLU A  55     -17.793  24.529  82.925  1.00 46.21           C
ATOM    417  C   GLU A  55     -16.624  25.125  83.697  1.00 40.08           C
ATOM    418  O   GLU A  55     -16.481  26.352  83.769  1.00 45.60           O
ATOM    419  CB  GLU A  55     -18.986  24.296  83.851  1.00 47.59           C
ATOM    420  CG  GLU A  55     -20.340  24.679  83.246  1.00 53.59           C
ATOM    421  CD  GLU A  55     -20.351  26.046  82.565  1.00 58.43           C
ATOM    422  OE1 GLU A  55     -19.654  26.974  83.035  1.00 62.86           O
ATOM    423  OE2 GLU A  55     -21.061  26.193  81.549  1.00 60.77           O
ATOM    424  N   ASP A  56     -15.777  24.274  84.281  1.00 40.14           N
ATOM    425  CA  ASP A  56     -14.560  24.768  84.913  1.00 46.72           C
ATOM    426  C   ASP A  56     -13.630  25.396  83.885  1.00 46.75           C
ATOM    427  O   ASP A  56     -13.065  26.470  84.120  1.00 41.56           O
ATOM    428  CB  ASP A  56     -13.852  23.632  85.650  1.00 47.88           C
ATOM    429  CG  ASP A  56     -14.054  23.692  87.150  1.00 55.58           C
ATOM    430  OD1 ASP A  56     -14.694  24.652  87.627  1.00 58.88           O
ATOM    431  OD2 ASP A  56     -13.573  22.778  87.852  1.00 63.71           O
ATOM    432  N   LEU A  57     -13.470  24.746  82.729  1.00 42.40           N
ATOM    433  CA  LEU A  57     -12.561  25.256  81.710  1.00 39.63           C
ATOM    434  C   LEU A  57     -13.090  26.528  81.064  1.00 40.16           C
ATOM    435  O   LEU A  57     -12.300  27.389  80.662  1.00 40.96           O
ATOM    436  CB  LEU A  57     -12.313  24.186  80.646  1.00 42.67           C
ATOM    437  CG  LEU A  57     -11.561  22.936  81.105  1.00 50.37           C
ATOM    438  CD1 LEU A  57     -11.538  21.886  80.006  1.00 49.87           C
ATOM    439  CD2 LEU A  57     -10.148  23.290  81.543  1.00 49.56           C
ATOM    440  N   LEU A  58     -14.413  26.670  80.954  1.00 38.25           N
ATOM    441  CA  LEU A  58     -14.972  27.838  80.281  1.00 40.40           C
ATOM    442  C   LEU A  58     -14.925  29.078  81.165  1.00 39.95           C
ATOM    443  O   LEU A  58     -14.673  30.183  80.671  1.00 35.67           O
ATOM    444  CB  LEU A  58     -16.408  27.563  79.840  1.00 36.53           C
ATOM    445  CG  LEU A  58     -17.019  28.704  79.024  1.00 40.35           C
ATOM    446  CD1 LEU A  58     -16.204  28.953  77.763  1.00 39.04           C
ATOM    447  CD2 LEU A  58     -18.469  28.424  78.686  1.00 45.58           C
ATOM    448  N   ILE A  59     -15.171  28.920  82.469  1.00 42.81           N
ATOM    449  CA  ILE A  59     -15.132  30.065  83.370  1.00 40.37           C
ATOM    450  C   ILE A  59     -13.727  30.640  83.487  1.00 35.67           C
ATOM    451  O   ILE A  59     -13.564  31.788  83.915  1.00 46.91           O
ATOM    452  CB  ILE A  59     -15.689  29.679  84.755  1.00 42.33           C
ATOM    453  CG1 ILE A  59     -16.261  30.909  85.466  1.00 48.47           C
ATOM    454  CG2 ILE A  59     -14.614  29.017  85.607  1.00 37.96           C
ATOM    455  CD1 ILE A  59     -17.496  31.479  84.802  1.00 52.04           C
ATOM    456  N   ARG A  60     -12.704  29.876  83.107  1.00 37.38           N
ATOM    457  CA  ARG A  60     -11.325  30.345  83.122  1.00 39.45           C
ATOM    458  C   ARG A  60     -10.927  31.042  81.829  1.00 40.28           C
ATOM    459  O   ARG A  60      -9.770  31.449  81.690  1.00 42.72           O
ATOM    460  CB  ARG A  60     -10.372  29.178  83.392  1.00 37.15           C
ATOM    461  CG  ARG A  60     -10.613  28.483  84.719  1.00 36.47           C
ATOM    462  CD  ARG A  60      -9.772  27.226  84.842  1.00 36.22           C
ATOM    463  NE  ARG A  60     -10.147  26.436  86.010  1.00 42.15           N
ATOM    464  CZ  ARG A  60      -9.663  25.229  86.281  1.00 54.61           C
ATOM    465  NH1 ARG A  60      -8.779  24.669  85.466  1.00 54.73           N
ATOM    466  NH2 ARG A  60     -10.061  24.583  87.368  1.00 53.63           N
ATOM    467  N   LYS A  61     -11.851  31.186  80.886  1.00 38.95           N
ATOM    468  CA  LYS A  61     -11.614  31.878  79.631  1.00 39.80           C
ATOM    469  C   LYS A  61     -12.501  33.114  79.543  1.00 39.96           C
ATOM    470  O   LYS A  61     -13.520  33.229  80.229  1.00 41.63           O
ATOM    471  CB  LYS A  61     -11.880  30.960  78.431  1.00 42.50           C
ATOM    472  CG  LYS A  61     -11.268  29.575  78.554  1.00 40.30           C
ATOM    473  CD  LYS A  61     -10.068  29.419  77.632  1.00 43.28           C
ATOM    474  CE  LYS A  61      -9.474  28.023  77.733  1.00 50.52           C
ATOM    475  NZ  LYS A  61      -9.547  27.496  79.124  1.00 57.16           N
ATOM    476  N   SER A  62     -12.092  34.048  78.691  1.00 36.02           N
ATOM    477  CA  SER A  62     -12.873  35.239  78.389  1.00 45.54           C
ATOM    478  C   SER A  62     -12.968  35.395  76.875  1.00 46.47           C
ATOM    479  O   SER A  62     -12.510  34.540  76.110  1.00 42.97           O
ATOM    480  CB  SER A  62     -12.264  36.486  79.042  1.00 50.61           C
ATOM    481  OG  SER A  62     -10.909  36.647  78.674  1.00 52.06           O
ATOM    482  N   ASN A  63     -13.570  36.504  76.440  1.00 45.75           N
ATOM    483  CA  ASN A  63     -13.822  36.700  75.016  1.00 46.75           C
ATOM    484  C   ASN A  63     -12.531  36.844  74.220  1.00 48.42           C
ATOM    485  O   ASN A  63     -12.477  36.432  73.056  1.00 43.99           O
ATOM    486  CB  ASN A  63     -14.714  37.922  74.809  1.00 48.34           C
ATOM    487  CG  ASN A  63     -16.120  37.707  75.325  1.00 48.12           C
ATOM    488  OD1 ASN A  63     -16.538  36.575  75.568  1.00 47.86           O
ATOM    489  ND2 ASN A  63     -16.859  38.794  75.499  1.00 52.25           N
ATOM    490  N   HIS A  64     -11.487  37.413  74.824  1.00 42.81           N
ATOM    491  CA  HIS A  64     -10.226  37.603  74.116  1.00 47.58           C
ATOM    492  C   HIS A  64      -9.485  36.297  73.861  1.00 45.70           C
ATOM    493  O   HIS A  64      -8.532  36.293  73.074  1.00 44.67           O
ATOM    494  CB  HIS A  64      -9.326  38.562  74.897  1.00 55.48           C
ATOM    495  CG  HIS A  64      -8.487  37.890  75.939  1.00 57.45           C
ATOM    496  ND1 HIS A  64      -9.018  37.366  77.098  1.00 65.64           N
ATOM    497  CD2 HIS A  64      -7.155  37.652  75.995  1.00 62.77           C
ATOM    498  CE1 HIS A  64      -8.049  36.837  77.824  1.00 67.36           C
ATOM    499  NE2 HIS A  64      -6.909  36.998  77.177  1.00 70.85           N
ATOM    500  N   ASN A  65      -9.891  35.199  74.500  1.00 46.87           N
ATOM    501  CA  ASN A  65      -9.232  33.913  74.312  1.00 42.60           C
ATOM    502  C   ASN A  65      -9.683  33.191  73.049  1.00 43.44           C
ATOM    503  O   ASN A  65      -9.116  32.144  72.720  1.00 45.20           O
ATOM    504  CB  ASN A  65      -9.479  33.014  75.528  1.00 43.59           C
ATOM    505  CG  ASN A  65      -8.871  33.572  76.800  1.00 48.45           C
ATOM    506  OD1 ASN A  65      -9.554  33.725  77.813  1.00 45.49           O
ATOM    507  ND2 ASN A  65      -7.579  33.875  76.756  1.00 48.19           N
ATOM    508  N   PHE A  66     -10.676  33.718  72.338  1.00 38.08           N
ATOM    509  CA  PHE A  66     -11.234  33.076  71.155  1.00 40.63           C
ATOM    510  C   PHE A  66     -10.905  33.913  69.926  1.00 41.32           C
ATOM    511  O   PHE A  66     -11.373  35.049  69.799  1.00 44.74           O
ATOM    512  CB  PHE A  66     -12.747  32.898  71.288  1.00 38.85           C
ATOM    513  CG  PHE A  66     -13.155  31.991  72.414  1.00 39.29           C
ATOM    514  CD1 PHE A  66     -13.187  30.616  72.245  1.00 37.23           C
ATOM    515  CD2 PHE A  66     -13.506  32.517  73.646  1.00 37.37           C
ATOM    516  CE1 PHE A  66     -13.564  29.783  73.286  1.00 36.49           C
ATOM    517  CE2 PHE A  66     -13.883  31.691  74.689  1.00 35.33           C
ATOM    518  CZ  PHE A  66     -13.912  30.323  74.509  1.00 43.59           C
ATOM    519  N   LEU A  67     -10.106  33.350  69.026  1.00 37.46           N
ATOM    520  CA  LEU A  67      -9.759  33.989  67.760  1.00 39.60           C
ATOM    521  C   LEU A  67     -10.605  33.356  66.658  1.00 45.47           C
ATOM    522  O   LEU A  67     -10.447  32.170  66.353  1.00 47.86           O
ATOM    523  CB  LEU A  67      -8.267  33.836  67.459  1.00 41.67           C
ATOM    524  CG  LEU A  67      -7.852  34.306  66.063  1.00 50.78           C
ATOM    525  CD1 LEU A  67      -7.697  35.811  66.075  1.00 54.65           C
ATOM    526  CD2 LEU A  67      -6.565  33.632  65.596  1.00 53.28           C
ATOM    527  N   VAL A  68     -11.503  34.139  66.068  1.00 41.73           N
ATOM    528  CA  VAL A  68     -12.392  33.669  65.012  1.00 41.94           C
ATOM    529  C   VAL A  68     -11.961  34.319  63.703  1.00 48.24           C
ATOM    530  O   VAL A  68     -11.912  35.551  63.596  1.00 48.40           O
ATOM    531  CB  VAL A  68     -13.860  33.983  65.332  1.00 43.81           C
ATOM    532  CG1 VAL A  68     -14.762  33.503  64.202  1.00 38.39           C
ATOM    533  CG2 VAL A  68     -14.269  33.341  66.650  1.00 37.29           C
ATOM    534  N   GLN A  69     -11.657  33.492  62.708  1.00 42.65           N
ATOM    535  CA  GLN A  69     -11.207  33.957  61.403  1.00 40.20           C
ATOM    536  C   GLN A  69     -12.207  33.536  60.335  1.00 45.19           C
ATOM    537  O   GLN A  69     -12.541  32.352  60.222  1.00 41.18           O
ATOM    538  CB  GLN A  69      -9.818  33.404  61.068  1.00 42.53           C
ATOM    539  CG  GLN A  69      -8.666  34.243  61.596  1.00 48.54           C
ATOM    540  CD  GLN A  69      -7.348  33.493  61.584  1.00 53.50           C
ATOM    541  OE1 GLN A  69      -7.203  32.459  62.237  1.00 58.04           O
ATOM    542  NE2 GLN A  69      -6.378  34.008  60.836  1.00 54.98           N
ATOM    543  N   ALA A  70     -12.681  34.506  59.560  1.00 47.87           N
ATOM    544  CA  ALA A  70     -13.549  34.265  58.410  1.00 48.51           C
ATOM    545  C   ALA A  70     -12.748  34.645  57.168  1.00 53.00           C
ATOM    546  O   ALA A  70     -12.752  35.799  56.736  1.00 57.30           O
ATOM    547  CB  ALA A  70     -14.847  35.058  58.519  1.00 42.49           C
ATOM    548  N   GLY A  71     -12.057  33.663  56.597  1.00 51.17           N
ATOM    549  CA  GLY A  71     -11.104  33.933  55.542  1.00 53.14           C
ATOM    550  C   GLY A  71      -9.906  34.691  56.072  1.00 60.48           C
ATOM    551  O   GLY A  71      -9.224  34.222  56.987  1.00 64.43           O
ATOM    552  N   ASN A  72      -9.640  35.867  55.512  1.00 64.84           N
ATOM    553  CA  ASN A  72      -8.597  36.742  56.027  1.00 65.70           C
ATOM    554  C   ASN A  72      -9.132  37.775  57.010  1.00 67.64           C
ATOM    555  O   ASN A  72      -8.343  38.534  57.581  1.00 70.47           O
ATOM    556  CB  ASN A  72      -7.880  37.452  54.873  1.00 67.25           C
ATOM    557  CG  ASN A  72      -6.610  36.741  54.452  1.00 69.63           C
ATOM    558  OD1 ASN A  72      -6.627  35.888  53.564  1.00 69.66           O
ATOM    559  ND2 ASN A  72      -5.498  37.089  55.090  1.00 66.84           N
ATOM    560  N   VAL A  73     -10.444  37.816  57.223  1.00 62.71           N
ATOM    561  CA  VAL A  73     -11.062  38.773  58.132  1.00 57.40           C
ATOM    562  C   VAL A  73     -11.174  38.141  59.513  1.00 57.48           C
ATOM    563  O   VAL A  73     -11.630  36.999  59.649  1.00 52.71           O
ATOM    564  CB  VAL A  73     -12.442  39.212  57.614  1.00 56.38           C
ATOM    565  CG1 VAL A  73     -13.041  40.275  58.524  1.00 55.15           C
ATOM    566  CG2 VAL A  73     -12.335  39.721  56.185  1.00 57.14           C
ATOM    567  N   GLN A  74     -10.758  38.880  60.536  1.00 55.65           N
ATOM    568  CA  GLN A  74     -10.859  38.437  61.918  1.00 54.17           C
ATOM    569  C   GLN A  74     -12.094  39.047  62.567  1.00 52.79           C
ATOM    570  O   GLN A  74     -12.364  40.241  62.413  1.00 57.72           O
ATOM    571  CB  GLN A  74      -9.607  38.823  62.709  1.00 51.64           C
ATOM    572  CG  GLN A  74      -9.667  38.459  64.183  1.00 59.47           C
ATOM    573  CD  GLN A  74      -8.486  39.001  64.964  1.00 65.67           C
ATOM    574  OE1 GLN A  74      -7.464  39.373  64.387  1.00 68.08           O
ATOM    575  NE2 GLN A  74      -8.624  39.053  66.284  1.00 60.42           N
ATOM    576  N   LEU A  75     -12.844  38.219  63.287  1.00 49.98           N
ATOM    577  CA  LEU A  75     -14.034  38.661  64.001  1.00 45.97           C
ATOM    578  C   LEU A  75     -13.722  38.758  65.487  1.00 51.19           C
ATOM    579  O   LEU A  75     -13.122  37.842  66.061  1.00 51.25           O
ATOM    580  CB  LEU A  75     -15.203  37.701  63.771  1.00 45.98           C
ATOM    581  CG  LEU A  75     -15.397  37.124  62.366  1.00 46.40           C
ATOM    582  CD1 LEU A  75     -16.637  36.245  62.327  1.00 43.70           C
ATOM    583  CD2 LEU A  75     -15.490  38.228  61.323  1.00 46.98           C
ATOM    584  N   ARG A  76     -14.125  39.864  66.105  1.00 47.70           N
ATOM    585  CA  ARG A  76     -13.924  40.058  67.533  1.00 48.38           C
ATOM    586  C   ARG A  76     -15.060  39.403  68.306  1.00 39.67           C
ATOM    587  O   ARG A  76     -16.236  39.687  68.055  1.00 42.34           O
ATOM    588  CB  ARG A  76     -13.840  41.545  67.879  1.00 50.15           C
ATOM    589  CG  ARG A  76     -13.612  41.813  69.360  1.00 52.87           C
ATOM    590  CD  ARG A  76     -13.416  43.294  69.645  1.00 55.14           C
ATOM    591  NE  ARG A  76     -14.581  44.088  69.265  1.00 64.31           N
ATOM    592  CZ  ARG A  76     -14.626  44.888  68.204  1.00 67.73           C
ATOM    593  NH1 ARG A  76     -13.568  45.003  67.413  1.00 66.74           N
ATOM    594  NH2 ARG A  76     -15.728  45.573  67.934  1.00 66.20           N
ATOM    595  N   VAL A  77     -14.705  38.525  69.239  1.00 40.40           N
ATOM    596  CA  VAL A  77     -15.688  37.865  70.092  1.00 41.40           C
ATOM    597  C   VAL A  77     -16.126  38.862  71.160  1.00 42.42           C
ATOM    598  O   VAL A  77     -15.323  39.286  71.994  1.00 41.41           O
ATOM    599  CB  VAL A  77     -15.122  36.588  70.720  1.00 42.39           C
ATOM    600  CG1 VAL A  77     -16.166  35.927  71.607  1.00 42.45           C
ATOM    601  CG2 VAL A  77     -14.650  35.629  69.637  1.00 45.27           C
ATOM    602  N   ILE A  78     -17.404  39.247  71.130  1.00 45.11           N
ATOM    603  CA  ILE A  78     -17.956  40.173  72.111  1.00 47.98           C
ATOM    604  C   ILE A  78     -18.777  39.464  73.176  1.00 45.95           C
ATOM    605  O   ILE A  78     -19.370  40.129  74.037  1.00 46.80           O
ATOM    606  CB  ILE A  78     -18.805  41.263  71.426  1.00 45.44           C
ATOM    607  CG1 ILE A  78     -19.998  40.633  70.705  1.00 46.53           C
ATOM    608  CG2 ILE A  78     -17.953  42.068  70.458  1.00 49.79           C
ATOM    609  CD1 ILE A  78     -21.254  41.473  70.759  1.00 46.96           C
ATOM    610  N   GLY A  79     -18.825  38.136  73.148  1.00 44.35           N
ATOM    611  CA  GLY A  79     -19.570  37.368  74.125  1.00 40.60           C
ATOM    612  C   GLY A  79     -19.483  35.882  73.851  1.00 41.13           C
ATOM    613  O   GLY A  79     -19.364  35.467  72.694  1.00 40.70           O
ATOM    614  N   HIS A  80     -19.532  35.067  74.901  1.00 38.25           N
ATOM    615  CA  HIS A  80     -19.459  33.623  74.754  1.00 43.22           C
ATOM    616  C   HIS A  80     -20.447  32.967  75.705  1.00 45.13           C
ATOM    617  O   HIS A  80     -20.775  33.515  76.761  1.00 41.76           O
ATOM    618  CB  HIS A  80     -18.040  33.094  75.012  1.00 41.39           C
ATOM    619  CG  HIS A  80     -17.537  33.357  76.396  1.00 48.48           C
ATOM    620  ND1 HIS A  80     -16.769  34.457  76.711  1.00 51.98           N
ATOM    621  CD2 HIS A  80     -17.684  32.660  77.547  1.00 46.89           C
ATOM    622  CE1 HIS A  80     -16.469  34.429  77.998  1.00 48.92           C
ATOM    623  NE2 HIS A  80     -17.012  33.348  78.528  1.00 50.90           N
ATOM    624  N   SER A  81     -20.917  31.785  75.316  1.00 38.75           N
ATOM    625  CA  SER A  81     -21.886  31.042  76.106  1.00 41.79           C
ATOM    626  C   SER A  81     -21.831  29.580  75.694  1.00 40.92           C
ATOM    627  O   SER A  81     -21.705  29.270  74.506  1.00 45.55           O
ATOM    628  CB  SER A  81     -23.301  31.598  75.918  1.00 43.04           C
ATOM    629  OG  SER A  81     -24.271  30.573  76.035  1.00 52.68           O
ATOM    630  N   MET A  82     -21.922  28.691  76.677  1.00 35.70           N
ATOM    631  CA  MET A  82     -21.948  27.257  76.429  1.00 40.92           C
ATOM    632  C   MET A  82     -23.390  26.778  76.399  1.00 37.18           C
ATOM    633  O   MET A  82     -24.151  27.020  77.342  1.00 43.24           O
ATOM    634  CB  MET A  82     -21.159  26.492  77.492  1.00 35.75           C
ATOM    635  CG  MET A  82     -21.076  24.998  77.240  1.00 31.49           C
ATOM    636  SD  MET A  82     -19.971  24.146  78.382  1.00 52.99           S
ATOM    637  CE  MET A  82     -18.380  24.771  77.848  1.00 46.40           C
ATOM    638  N   GLN A  83     -23.763  26.115  75.310  1.00 36.53           N
ATOM    639  CA  GLN A  83     -25.081  25.506  75.169  1.00 38.76           C
ATOM    640  C   GLN A  83     -24.859  24.008  75.017  1.00 35.47           C
ATOM    641  O   GLN A  83     -24.610  23.522  73.909  1.00 38.88           O
ATOM    642  CB  GLN A  83     -25.835  26.093  73.985  1.00 42.05           C
ATOM    643  CG  GLN A  83     -27.334  26.150  74.186  1.00 49.29           C
ATOM    644  CD  GLN A  83     -28.094  25.956  72.894  1.00 56.32           C
ATOM    645  OE1 GLN A  83     -27.511  25.956  71.809  1.00 59.39           O
ATOM    646  NE2 GLN A  83     -29.407  25.796  73.002  1.00 59.16           N
ATOM    647  N   ASN A  84     -24.943  23.286  76.135  1.00 35.48           N
ATOM    648  CA  ASN A  84     -24.667  21.854  76.163  1.00 28.78           C
ATOM    649  C   ASN A  84     -23.255  21.576  75.665  1.00 32.51           C
ATOM    650  O   ASN A  84     -22.282  21.926  76.339  1.00 37.59           O
ATOM    651  CB  ASN A  84     -25.704  21.085  75.339  1.00 33.36           C
ATOM    652  CG  ASN A  84     -27.120  21.394  75.766  1.00 31.17           C
ATOM    653  OD1 ASN A  84     -27.467  21.251  76.939  1.00 37.19           O
ATOM    654  ND2 ASN A  84     -27.944  21.838  74.824  1.00 32.50           N
ATOM    655  N   CYS A  85     -23.115  20.964  74.490  1.00 31.33           N
ATOM    656  CA  CYS A  85     -21.798  20.585  73.993  1.00 29.75           C
ATOM    657  C   CYS A  85     -21.272  21.497  72.891  1.00 27.93           C
ATOM    658  O   CYS A  85     -20.211  21.212  72.327  1.00 30.14           O
ATOM    659  CB  CYS A  85     -21.806  19.130  73.517  1.00 30.66           C
ATOM    660  SG  CYS A  85     -22.241  17.929  74.804  1.00 41.25           S
ATOM    661  N   VAL A  86     -21.956  22.597  72.589  1.00 26.22           N
ATOM    662  CA  VAL A  86     -21.462  23.546  71.599  1.00 31.73           C
ATOM    663  C   VAL A  86     -21.217  24.889  72.270  1.00 35.24           C
ATOM    664  O   VAL A  86     -21.880  25.253  73.248  1.00 34.26           O
ATOM    665  CB  VAL A  86     -22.422  23.712  70.400  1.00 34.22           C
ATOM    666  CG1 VAL A  86     -22.536  22.411  69.618  1.00 32.41           C
ATOM    667  CG2 VAL A  86     -23.786  24.197  70.866  1.00 37.90           C
ATOM    668  N   LEU A  87     -20.248  25.624  71.735  1.00 34.20           N
ATOM    669  CA  LEU A  87     -19.981  26.989  72.159  1.00 38.10           C
ATOM    670  C   LEU A  87     -20.746  27.959  71.272  1.00 37.42           C
ATOM    671  O   LEU A  87     -20.863  27.752  70.060  1.00 39.21           O
ATOM    672  CB  LEU A  87     -18.483  27.293  72.093  1.00 39.41           C
ATOM    673  CG  LEU A  87     -17.705  27.345  73.407  1.00 41.24           C
ATOM    674  CD1 LEU A  87     -16.210  27.344  73.135  1.00 39.84           C
ATOM    675  CD2 LEU A  87     -18.101  28.566  74.221  1.00 45.07           C
ATOM    676  N   LYS A  88     -21.273  29.017  71.882  1.00 35.57           N
ATOM    677  CA  LYS A  88     -21.956  30.086  71.160  1.00 35.57           C
ATOM    678  C   LYS A  88     -21.167  31.370  71.373  1.00 42.07           C
ATOM    679  O   LYS A  88     -21.201  31.953  72.462  1.00 39.09           O
ATOM    680  CB  LYS A  88     -23.403  30.235  71.629  1.00 39.36           C
ATOM    681  CG  LYS A  88     -24.281  29.038  71.296  1.00 41.29           C
ATOM    682  CD  LYS A  88     -25.665  29.167  71.909  1.00 46.58           C
ATOM    683  CE  LYS A  88     -26.601  29.951  71.005  1.00 43.72           C
ATOM    684  NZ  LYS A  88     -27.998  29.944  71.521  1.00 51.46           N
ATOM    685  N   LEU A  89     -20.451  31.801  70.337  1.00 35.18           N
ATOM    686  CA  LEU A  89     -19.587  32.974  70.406  1.00 35.95           C
ATOM    687  C   LEU A  89     -20.274  34.136  69.697  1.00 39.94           C
ATOM    688  O   LEU A  89     -20.422  34.124  68.471  1.00 37.92           O
ATOM    689  CB  LEU A  89     -18.225  32.683  69.778  1.00 35.83           C
ATOM    690  CG  LEU A  89     -17.443  31.484  70.316  1.00 34.70           C
ATOM    691  CD1 LEU A  89     -16.114  31.346  69.590  1.00 35.27           C
ATOM    692  CD2 LEU A  89     -17.225  31.612  71.813  1.00 34.21           C
ATOM    693  N   LYS A  90     -20.694  35.133  70.470  1.00 37.44           N
ATOM    694  CA  LYS A  90     -21.231  36.355  69.888  1.00 37.63           C
ATOM    695  C   LYS A  90     -20.093  37.183  69.306  1.00 44.75           C
ATOM    696  O   LYS A  90     -19.088  37.432  69.979  1.00 43.60           O
ATOM    697  CB  LYS A  90     -21.990  37.156  70.944  1.00 45.52           C
ATOM    698  CG  LYS A  90     -23.177  37.940  70.409  1.00 47.69           C
ATOM    699  CD  LYS A  90     -24.005  38.518  71.547  1.00 55.70           C
ATOM    700  CE  LYS A  90     -25.345  39.037  71.053  1.00 59.25           C
ATOM    701  NZ  LYS A  90     -26.228  39.447  72.181  1.00 57.77           N
ATOM    702  N   VAL A  91     -20.244  37.605  68.052  1.00 39.26           N
ATOM    703  CA  VAL A  91     -19.193  38.332  67.355  1.00 38.65           C
ATOM    704  C   VAL A  91     -19.715  39.699  66.932  1.00 46.65           C
ATOM    705  O   VAL A  91     -20.920  39.964  66.930  1.00 44.94           O
ATOM    706  CB  VAL A  91     -18.655  37.554  66.137  1.00 43.56           C
ATOM    707  CG1 VAL A  91     -17.792  36.387  66.598  1.00 41.53           C
ATOM    708  CG2 VAL A  91     -19.802  37.068  65.267  1.00 38.87           C
ATOM    709  N   ASP A  92     -18.775  40.571  66.563  1.00 47.50           N
ATOM    710  CA  ASP A  92     -19.097  41.962  66.265  1.00 55.99           C
ATOM    711  C   ASP A  92     -19.813  42.133  64.931  1.00 55.99           C
ATOM    712  O   ASP A  92     -20.446  43.172  64.713  1.00 51.50           O
ATOM    713  CB  ASP A  92     -17.816  42.800  66.282  1.00 56.73           C
ATOM    714  CG  ASP A  92     -18.082  44.282  66.104  1.00 71.09           C
ATOM    715  OD1 ASP A  92     -18.456  44.945  67.094  1.00 74.54           O
ATOM    716  OD2 ASP A  92     -17.917  44.784  64.971  1.00 72.25           O
ATOM    717  N   THR A  93     -19.741  41.146  64.042  1.00 53.36           N
ATOM    718  CA  THR A  93     -20.267  41.272  62.689  1.00 50.11           C
ATOM    719  C   THR A  93     -21.298  40.186  62.421  1.00 48.58           C
ATOM    720  O   THR A  93     -21.081  39.019  62.764  1.00 44.46           O
ATOM    721  CB  THR A  93     -19.142  41.188  61.653  1.00 51.85           C
ATOM    722  OG1 THR A  93     -17.981  41.863  62.151  1.00 61.92           O
ATOM    723  CG2 THR A  93     -19.574  41.829  60.342  1.00 53.12           C
ATOM    724  N   ALA A  94     -22.416  40.574  61.812  1.00 44.58           N
ATOM    725  CA  ALA A  94     -23.384  39.608  61.318  1.00 45.34           C
ATOM    726  C   ALA A  94     -22.932  39.095  59.958  1.00 41.83           C
ATOM    727  O   ALA A  94     -22.536  39.880  59.092  1.00 44.83           O
ATOM    728  CB  ALA A  94     -24.773  40.237  61.218  1.00 37.38           C
ATOM    729  N   ASN A  95     -22.978  37.777  59.777  1.00 42.65           N
ATOM    730  CA  ASN A  95     -22.552  37.160  58.527  1.00 40.38           C
ATOM    731  C   ASN A  95     -23.452  37.626  57.389  1.00 40.35           C
ATOM    732  O   ASN A  95     -24.653  37.326  57.383  1.00 39.89           O
ATOM    733  CB  ASN A  95     -22.572  35.634  58.650  1.00 42.27           C
ATOM    734  CG  ASN A  95     -21.926  34.936  57.464  1.00 43.20           C
ATOM    735  OD1 ASN A  95     -21.591  35.563  56.459  1.00 44.92           O
ATOM    736  ND2 ASN A  95     -21.754  33.625  57.578  1.00 42.09           N
ATOM    737  N   PRO A  96     -22.914  38.362  56.412  1.00 42.52           N
ATOM    738  CA  PRO A  96     -23.755  38.820  55.295  1.00 43.84           C
ATOM    739  C   PRO A  96     -24.223  37.695  54.392  1.00 43.73           C
ATOM    740  O   PRO A  96     -25.225  37.868  53.687  1.00 44.92           O
ATOM    741  CB  PRO A  96     -22.840  39.796  54.544  1.00 41.66           C
ATOM    742  CG  PRO A  96     -21.459  39.322  54.857  1.00 41.64           C
ATOM    743  CD  PRO A  96     -21.512  38.793  56.265  1.00 39.46           C
ATOM    744  N   LYS A  97     -23.537  36.555  54.387  1.00 45.99           N
ATOM    745  CA  LYS A  97     -23.938  35.402  53.596  1.00 45.32           C
ATOM    746  C   LYS A  97     -24.868  34.462  54.351  1.00 44.01           C
ATOM    747  O   LYS A  97     -25.065  33.325  53.911  1.00 46.12           O
ATOM    748  CB  LYS A  97     -22.704  34.628  53.123  1.00 43.79           C
ATOM    749  CG  LYS A  97     -21.662  35.472  52.411  1.00 48.51           C
ATOM    750  CD  LYS A  97     -20.651  34.588  51.697  1.00 47.89           C
ATOM    751  CE  LYS A  97     -19.319  35.297  51.519  1.00 51.57           C
ATOM    752  NZ  LYS A  97     -18.351  34.464  50.753  1.00 64.60           N
ATOM    753  N   THR A  98     -25.435  34.903  55.468  1.00 42.46           N
ATOM    754  CA  THR A  98     -26.312  34.054  56.263  1.00 39.45           C
ATOM    755  C   THR A  98     -27.555  33.682  55.465  1.00 38.73           C
ATOM    756  O   THR A  98     -28.349  34.570  55.120  1.00 41.52           O
ATOM    757  CB  THR A  98     -26.728  34.751  57.556  1.00 43.49           C
ATOM    758  OG1 THR A  98     -25.574  34.995  58.365  1.00 42.89           O
ATOM    759  CG2 THR A  98     -27.706  33.882  58.333  1.00 38.44           C
ATOM    760  N   PRO A  99     -27.766  32.410  55.155  1.00 38.95           N
ATOM    761  CA  PRO A  99     -29.005  32.004  54.492  1.00 38.09           C
ATOM    762  C   PRO A  99     -30.127  31.867  55.511  1.00 41.90           C
ATOM    763  O   PRO A  99     -29.914  31.905  56.724  1.00 39.74           O
ATOM    764  CB  PRO A  99     -28.640  30.650  53.883  1.00 40.27           C
ATOM    765  CG  PRO A  99     -27.665  30.080  54.873  1.00 44.92           C
ATOM    766  CD  PRO A  99     -26.901  31.254  55.452  1.00 39.70           C
ATOM    767  N   LYS A 100     -31.343  31.718  54.996  1.00 36.74           N
ATOM    768  CA  LYS A 100     -32.447  31.325  55.856  1.00 42.54           C
ATOM    769  C   LYS A 100     -32.197  29.902  56.336  1.00 42.21           C
ATOM    770  O   LYS A 100     -31.913  29.008  55.533  1.00 40.24           O
ATOM    771  CB  LYS A 100     -33.777  31.423  55.113  1.00 40.57           C
ATOM    772  CG  LYS A 100     -34.989  31.181  55.998  1.00 49.85           C
ATOM    773  CD  LYS A 100     -36.118  30.512  55.235  1.00 57.75           C
ATOM    774  CE  LYS A 100     -37.472  30.904  55.803  1.00 58.16           C
ATOM    775  NZ  LYS A 100     -38.084  29.783  56.571  1.00 62.23           N
ATOM    776  N   TYR A 101     -32.267  29.692  57.647  1.00 37.56           N
ATOM    777  CA  TYR A 101     -31.842  28.424  58.217  1.00 37.16           C
ATOM    778  C   TYR A 101     -32.766  28.016  59.353  1.00 37.23           C
ATOM    779  O   TYR A 101     -33.559  28.809  59.867  1.00 36.86           O
ATOM    780  CB  TYR A 101     -30.390  28.492  58.709  1.00 33.76           C
ATOM    781  CG  TYR A 101     -30.181  29.414  59.891  1.00 39.07           C
ATOM    782  CD1 TYR A 101     -29.935  30.768  59.704  1.00 33.22           C
ATOM    783  CD2 TYR A 101     -30.223  28.929  61.193  1.00 36.68           C
ATOM    784  CE1 TYR A 101     -29.742  31.614  60.779  1.00 38.66           C
ATOM    785  CE2 TYR A 101     -30.032  29.768  62.274  1.00 35.64           C
ATOM    786  CZ  TYR A 101     -29.791  31.108  62.062  1.00 42.74           C
ATOM    787  OH  TYR A 101     -29.599  31.947  63.136  1.00 44.17           O
ATOM    788  N   LYS A 102     -32.647  26.746  59.734  1.00 34.77           N
ATOM    789  CA  LYS A 102     -33.343  26.180  60.877  1.00 35.68           C
ATOM    790  C   LYS A 102     -32.405  25.213  61.582  1.00 36.28           C
ATOM    791  O   LYS A 102     -31.485  24.664  60.972  1.00 34.40           O
ATOM    792  CB  LYS A 102     -34.628  25.446  60.464  1.00 40.86           C
ATOM    793  CG  LYS A 102     -35.760  26.352  60.011  1.00 49.31           C
ATOM    794  CD  LYS A 102     -37.092  25.614  60.025  1.00 49.25           C
ATOM    795  CE  LYS A 102     -37.465  25.108  58.640  1.00 61.49           C
ATOM    796  NZ  LYS A 102     -36.684  23.899  58.253  1.00 62.18           N
ATOM    797  N   PHE A 103     -32.639  25.016  62.876  1.00 31.56           N
ATOM    798  CA  PHE A 103     -31.916  24.031  63.672  1.00 34.73           C
ATOM    799  C   PHE A 103     -32.868  22.876  63.951  1.00 31.35           C
ATOM    800  O   PHE A 103     -33.804  23.015  64.745  1.00 32.98           O
ATOM    801  CB  PHE A 103     -31.397  24.641  64.973  1.00 34.30           C
ATOM    802  CG  PHE A 103     -30.156  25.473  64.804  1.00 32.28           C
ATOM    803  CD1 PHE A 103     -29.508  25.539  63.583  1.00 30.49           C
ATOM    804  CD2 PHE A 103     -29.635  26.186  65.873  1.00 35.60           C
ATOM    805  CE1 PHE A 103     -28.366  26.303  63.428  1.00 30.40           C
ATOM    806  CE2 PHE A 103     -28.494  26.951  65.725  1.00 31.28           C
ATOM    807  CZ  PHE A 103     -27.858  27.010  64.501  1.00 32.20           C
ATOM    808  N   VAL A 104     -32.639  21.743  63.295  1.00 28.64           N
ATOM    809  CA  VAL A 104     -33.501  20.580  63.455  1.00 32.71           C
ATOM    810  C   VAL A 104     -32.669  19.403  63.943  1.00 37.01           C
ATOM    811  O   VAL A 104     -31.476  19.285  63.645  1.00 37.44           O
ATOM    812  CB  VAL A 104     -34.243  20.218  62.148  1.00 35.46           C
ATOM    813  CG1 VAL A 104     -34.967  21.437  61.592  1.00 38.21           C
ATOM    814  CG2 VAL A 104     -33.279  19.645  61.119  1.00 38.51           C
ATOM    815  N   ARG A 105     -33.310  18.537  64.720  1.00 27.53           N
ATOM    816  CA  ARG A 105     -32.716  17.283  65.158  1.00 30.16           C
ATOM    817  C   ARG A 105     -33.377  16.153  64.381  1.00 31.64           C
ATOM    818  O   ARG A 105     -34.589  15.941  64.495  1.00 36.99           O
ATOM    819  CB  ARG A 105     -32.889  17.086  66.664  1.00 29.60           C
ATOM    820  CG  ARG A 105     -32.252  15.817  67.206  1.00 31.99           C
ATOM    821  CD  ARG A 105     -32.393  15.729  68.718  1.00 31.57           C
ATOM    822  NE  ARG A 105     -31.731  14.547  69.267  1.00 29.72           N
ATOM    823  CZ  ARG A 105     -30.480  14.532  69.716  1.00 30.68           C
ATOM    824  NH1 ARG A 105     -29.748  15.638  69.683  1.00 28.54           N
ATOM    825  NH2 ARG A 105     -29.959  13.412  70.199  1.00 29.69           N
ATOM    826  N   ILE A 106     -32.584  15.441  63.587  1.00 25.77           N
ATOM    827  CA  ILE A 106     -33.120  14.410  62.707  1.00 29.24           C
ATOM    828  C   ILE A 106     -33.326  13.118  63.484  1.00 33.97           C
ATOM    829  O   ILE A 106     -32.914  13.000  64.644  1.00 37.23           O
ATOM    830  CB  ILE A 106     -32.201  14.192  61.492  1.00 33.86           C
ATOM    831  CG1 ILE A 106     -30.825  13.704  61.946  1.00 28.97           C
ATOM    832  CG2 ILE A 106     -32.072  15.474  60.686  1.00 41.61           C
ATOM    833  CD1 ILE A 106     -30.830  12.308  62.499  1.00 40.69           C
ATOM    834  N   GLN A 107     -33.961  12.151  62.849  1.00 29.20           N
ATOM    835  CA  GLN A 107     -34.263  10.844  63.402  1.00 31.86           C
ATOM    836  C   GLN A 107     -33.318   9.796  62.837  1.00 35.06           C
ATOM    837  O   GLN A 107     -32.727   9.986  61.770  1.00 32.68           O
ATOM    838  CB  GLN A 107     -35.711  10.456  63.080  1.00 34.98           C
ATOM    839  CG  GLN A 107     -36.752  11.168  63.925  1.00 33.40           C
ATOM    840  CD  GLN A 107     -36.793  10.647  65.347  1.00 40.18           C
ATOM    841  OE1 GLN A 107     -36.614   9.454  65.587  1.00 42.80           O
ATOM    842  NE2 GLN A 107     -37.030  11.542  66.299  1.00 39.77           N
ATOM    843  N   PRO A 108     -33.136   8.678  63.540  1.00 32.40           N
ATOM    844  CA  PRO A 108     -32.407   7.553  62.945  1.00 33.04           C
ATOM    845  C   PRO A 108     -33.084   7.093  61.663  1.00 31.54           C
ATOM    846  O   PRO A 108     -34.312   7.033  61.573  1.00 30.44           O
ATOM    847  CB  PRO A 108     -32.466   6.477  64.034  1.00 33.49           C
ATOM    848  CG  PRO A 108     -32.630   7.241  65.303  1.00 35.33           C
ATOM    849  CD  PRO A 108     -33.472   8.435  64.954  1.00 38.44           C
ATOM    850  N   GLY A 109     -32.267   6.778  60.660  1.00 30.37           N
ATOM    851  CA  GLY A 109     -32.757   6.413  59.353  1.00 31.93           C
ATOM    852  C   GLY A 109     -32.860   7.561  58.373  1.00 36.89           C
ATOM    853  O   GLY A 109     -32.925   7.320  57.161  1.00 34.94           O
ATOM    854  N   GLN A 110     -32.882   8.799  58.858  1.00 34.68           N
ATOM    855  CA  GLN A 110     -32.911   9.958  57.984  1.00 28.85           C
ATOM    856  C   GLN A 110     -31.503  10.294  57.503  1.00 33.18           C
ATOM    857  O   GLN A 110     -30.500   9.919  58.116  1.00 31.04           O
ATOM    858  CB  GLN A 110     -33.526  11.160  58.700  1.00 32.11           C
ATOM    859  CG  GLN A 110     -35.021  11.034  58.939  1.00 34.62           C
ATOM    860  CD  GLN A 110     -35.781  10.671  57.679  1.00 48.98           C
ATOM    861  OE1 GLN A 110     -36.293   9.559  57.548  1.00 50.45           O
ATOM    862  NE2 GLN A 110     -35.857  11.610  56.743  1.00 47.85           N
ATOM    863  N   THR A 111     -31.438  11.013  56.388  1.00 30.03           N
ATOM    864  CA  THR A 111     -30.174  11.341  55.749  1.00 31.68           C
ATOM    865  C   THR A 111     -29.918  12.841  55.794  1.00 26.77           C
ATOM    866  O   THR A 111     -30.797  13.645  56.118  1.00 28.89           O
ATOM    867  CB  THR A 111     -30.151  10.848  54.298  1.00 33.24           C
ATOM    868  OG1 THR A 111     -31.279  11.377  53.591  1.00 31.13           O
ATOM    869  CG2 THR A 111     -30.191   9.330  54.253  1.00 30.08           C
ATOM    870  N   PHE A 112     -28.683  13.202  55.458  1.00 25.75           N
ATOM    871  CA  PHE A 112     -28.249  14.590  55.396  1.00 24.19           C
ATOM    872  C   PHE A 112     -26.898  14.635  54.701  1.00 25.60           C
ATOM    873  O   PHE A 112     -26.188  13.628  54.618  1.00 29.54           O
ATOM    874  CB  PHE A 112     -28.162  15.227  56.788  1.00 25.66           C
ATOM    875  CG  PHE A 112     -27.238  14.509  57.732  1.00 31.15           C
ATOM    876  CD1 PHE A 112     -27.672  13.402  58.442  1.00 33.13           C
ATOM    877  CD2 PHE A 112     -25.937  14.949  57.918  1.00 28.12           C
ATOM    878  CE1 PHE A 112     -26.826  12.742  59.313  1.00 35.25           C
ATOM    879  CE2 PHE A 112     -25.087  14.293  58.788  1.00 33.87           C
ATOM    880  CZ  PHE A 112     -25.532  13.188  59.486  1.00 32.49           C
ATOM    881  N   SER A 113     -26.554  15.817  54.203  1.00 27.87           N
ATOM    882  CA  SER A 113     -25.286  16.046  53.527  1.00 26.39           C
ATOM    883  C   SER A 113     -24.273  16.620  54.508  1.00 31.32           C
ATOM    884  O   SER A 113     -24.619  17.427  55.374  1.00 23.80           O
ATOM    885  CB  SER A 113     -25.460  16.995  52.340  1.00 29.74           C
ATOM    886  OG  SER A 113     -26.449  16.512  51.449  1.00 31.08           O
ATOM    887  N   VAL A 114     -23.020  16.198  54.367  1.00 29.58           N
ATOM    888  CA  VAL A 114     -21.937  16.611  55.249  1.00 29.24           C
ATOM    889  C   VAL A 114     -20.945  17.436  54.444  1.00 29.20           C
ATOM    890  O   VAL A 114     -20.467  16.992  53.395  1.00 29.25           O
ATOM    891  CB  VAL A 114     -21.241  15.399  55.891  1.00 26.68           C
ATOM    892  CG1 VAL A 114     -19.953  15.834  56.583  1.00 30.26           C
ATOM    893  CG2 VAL A 114     -22.173  14.711  56.873  1.00 26.58           C
ATOM    894  N   LEU A 115     -20.638  18.631  54.934  1.00 26.73           N
ATOM    895  CA  LEU A 115     -19.599  19.477  54.354  1.00 28.06           C
ATOM    896  C   LEU A 115     -18.365  19.348  55.243  1.00 31.91           C
ATOM    897  O   LEU A 115     -18.268  19.997  56.286  1.00 33.35           O
ATOM    898  CB  LEU A 115     -20.062  20.926  54.246  1.00 31.46           C
ATOM    899  CG  LEU A 115     -18.982  21.877  53.731  1.00 34.58           C
ATOM    900  CD1 LEU A 115     -18.587  21.434  52.340  1.00 38.33           C
ATOM    901  CD2 LEU A 115     -19.451  23.325  53.720  1.00 37.35           C
ATOM    902  N   ALA A 116     -17.429  18.494  54.841  1.00 28.72           N
ATOM    903  CA  ALA A 116     -16.209  18.305  55.616  1.00 36.61           C
ATOM    904  C   ALA A 116     -15.293  19.509  55.429  1.00 36.10           C
ATOM    905  O   ALA A 116     -14.879  19.816  54.306  1.00 36.85           O
ATOM    906  CB  ALA A 116     -15.506  17.018  55.194  1.00 35.24           C
ATOM    907  N   CYS A 117     -14.976  20.189  56.527  1.00 31.42           N
ATOM    908  CA  CYS A 117     -14.143  21.380  56.496  1.00 35.33           C
ATOM    909  C   CYS A 117     -12.897  21.176  57.345  1.00 37.63           C
ATOM    910  O   CYS A 117     -12.895  20.398  58.304  1.00 33.01           O
ATOM    911  CB  CYS A 117     -14.908  22.610  56.999  1.00 36.22           C
ATOM    912  SG  CYS A 117     -16.563  22.801  56.305  1.00 50.70           S
ATOM    913  N   TYR A 118     -11.833  21.886  56.978  1.00 41.48           N
ATOM    914  CA  TYR A 118     -10.576  21.862  57.717  1.00 38.25           C
ATOM    915  C   TYR A 118     -10.033  23.280  57.770  1.00 41.43           C
ATOM    916  O   TYR A 118      -9.813  23.898  56.723  1.00 40.83           O
ATOM    917  CB  TYR A 118      -9.566  20.912  57.064  1.00 32.92           C
ATOM    918  CG  TYR A 118      -9.998  19.462  57.089  1.00 37.18           C
ATOM    919  CD1 TYR A 118     -10.765  18.928  56.060  1.00 37.78           C
ATOM    920  CD2 TYR A 118      -9.644  18.629  58.142  1.00 35.64           C
ATOM    921  CE1 TYR A 118     -11.166  17.606  56.080  1.00 35.34           C
ATOM    922  CE2 TYR A 118     -10.039  17.304  58.170  1.00 37.65           C
ATOM    923  CZ  TYR A 118     -10.800  16.798  57.136  1.00 37.82           C
ATOM    924  OH  TYR A 118     -11.196  15.481  57.160  1.00 39.89           O
ATOM    925  N   ASN A 119      -9.826  23.791  58.986  1.00 44.86           N
ATOM    926  CA  ASN A 119      -9.350  25.159  59.202  1.00 42.76           C
ATOM    927  C   ASN A 119     -10.291  26.181  58.567  1.00 41.56           C
ATOM    928  O   ASN A 119      -9.857  27.147  57.936  1.00 39.63           O
ATOM    929  CB  ASN A 119      -7.920  25.337  58.687  1.00 49.38           C
ATOM    930  CG  ASN A 119      -6.915  24.506  59.460  1.00 51.44           C
ATOM    931  OD1 ASN A 119      -6.614  23.372  59.090  1.00 56.00           O
ATOM    932  ND2 ASN A 119      -6.392  25.069  60.544  1.00 48.44           N
ATOM    933  N   GLY A 120     -11.594  25.964  58.736  1.00 39.01           N
ATOM    934  CA  GLY A 120     -12.598  26.855  58.194  1.00 36.10           C
ATOM    935  C   GLY A 120     -12.758  26.823  56.691  1.00 42.88           C
ATOM    936  O   GLY A 120     -13.523  27.632  56.153  1.00 41.11           O
ATOM    937  N   SER A 121     -12.073  25.920  55.994  1.00 37.25           N
ATOM    938  CA  SER A 121     -12.134  25.844  54.539  1.00 41.19           C
ATOM    939  C   SER A 121     -12.780  24.531  54.120  1.00 42.94           C
ATOM    940  O   SER A 121     -12.235  23.454  54.418  1.00 38.68           O
ATOM    941  CB  SER A 121     -10.737  25.972  53.929  1.00 39.49           C
ATOM    942  OG  SER A 121     -10.804  26.471  52.605  1.00 58.46           O
ATOM    943  N   PRO A 122     -13.928  24.561  53.445  1.00 45.78           N
ATOM    944  CA  PRO A 122     -14.554  23.310  52.997  1.00 41.91           C
ATOM    945  C   PRO A 122     -13.688  22.585  51.977  1.00 41.77           C
ATOM    946  O   PRO A 122     -13.072  23.202  51.104  1.00 44.19           O
ATOM    947  CB  PRO A 122     -15.877  23.779  52.379  1.00 45.61           C
ATOM    948  CG  PRO A 122     -16.126  25.132  52.974  1.00 48.15           C
ATOM    949  CD  PRO A 122     -14.771  25.736  53.169  1.00 44.74           C
ATOM    950  N   SER A 123     -13.646  21.256  52.093  1.00 40.84           N
ATOM    951  CA  SER A 123     -12.852  20.428  51.199  1.00 40.73           C
ATOM    952  C   SER A 123     -13.640  19.332  50.498  1.00 39.49           C
ATOM    953  O   SER A 123     -13.140  18.779  49.512  1.00 37.35           O
ATOM    954  CB  SER A 123     -11.685  19.777  51.960  1.00 42.66           C
ATOM    955  OG  SER A 123     -12.107  18.608  52.639  1.00 40.82           O
ATOM    956  N   GLY A 124     -14.838  19.001  50.965  1.00 34.94           N
ATOM    957  CA  GLY A 124     -15.622  17.959  50.331  1.00 36.97           C
ATOM    958  C   GLY A 124     -17.024  17.915  50.891  1.00 35.80           C
ATOM    959  O   GLY A 124     -17.290  18.400  51.996  1.00 34.45           O
ATOM    960  N   VAL A 125     -17.922  17.321  50.108  1.00 28.17           N
ATOM    961  CA  VAL A 125     -19.322  17.178  50.492  1.00 28.26           C
ATOM    962  C   VAL A 125     -19.792  15.780  50.111  1.00 31.29           C
ATOM    963  O   VAL A 125     -19.449  15.266  49.041  1.00 30.39           O
ATOM    964  CB  VAL A 125     -20.205  18.269  49.846  1.00 34.00           C
ATOM    965  CG1 VAL A 125     -20.116  18.216  48.329  1.00 34.39           C
ATOM    966  CG2 VAL A 125     -21.649  18.144  50.315  1.00 32.78           C
ATOM    967  N   TYR A 126     -20.561  15.155  51.001  1.00 29.87           N
ATOM    968  CA  TYR A 126     -21.078  13.816  50.757  1.00 27.60           C
ATOM    969  C   TYR A 126     -22.326  13.610  51.601  1.00 30.08           C
ATOM    970  O   TYR A 126     -22.582  14.348  52.557  1.00 29.92           O
ATOM    971  CB  TYR A 126     -20.029  12.739  51.064  1.00 29.36           C
ATOM    972  CG  TYR A 126     -19.484  12.790  52.475  1.00 31.82           C
ATOM    973  CD1 TYR A 126     -18.368  13.557  52.782  1.00 36.26           C
ATOM    974  CD2 TYR A 126     -20.084  12.070  53.500  1.00 33.37           C
ATOM    975  CE1 TYR A 126     -17.866  13.607  54.069  1.00 33.14           C
ATOM    976  CE2 TYR A 126     -19.589  12.115  54.789  1.00 33.73           C
ATOM    977  CZ  TYR A 126     -18.481  12.884  55.068  1.00 32.44           C
ATOM    978  OH  TYR A 126     -17.986  12.931  56.351  1.00 34.56           O
ATOM    979  N   GLN A 127     -23.097  12.590  51.236  1.00 26.24           N
ATOM    980  CA  GLN A 127     -24.341  12.272  51.923  1.00 29.01           C
ATOM    981  C   GLN A 127     -24.090  11.281  53.053  1.00 36.22           C
ATOM    982  O   GLN A 127     -23.270  10.367  52.926  1.00 34.05           O
ATOM    983  CB  GLN A 127     -25.365  11.701  50.940  1.00 35.77           C
ATOM    984  CG  GLN A 127     -26.783  11.617  51.485  1.00 35.54           C
ATOM    985  CD  GLN A 127     -27.555  12.914  51.326  1.00 36.56           C
ATOM    986  OE1 GLN A 127     -26.970  13.994  51.231  1.00 39.37           O
ATOM    987  NE2 GLN A 127     -28.879  12.813  51.296  1.00 39.16           N
ATOM    988  N   CYS A 128     -24.805  11.471  54.160  1.00 34.64           N
ATOM    989  CA  CYS A 128     -24.686  10.612  55.328  1.00 36.77           C
ATOM    990  C   CYS A 128     -26.075  10.273  55.846  1.00 37.19           C
ATOM    991  O   CYS A 128     -27.039  11.008  55.624  1.00 34.84           O
ATOM    992  CB  CYS A 128     -23.862  11.274  56.441  1.00 38.70           C
ATOM    993  SG  CYS A 128     -22.145  10.732  56.529  1.00 50.17           S
ATOM    994  N   ALA A 129     -26.166   9.147  56.549  1.00 36.72           N
ATOM    995  CA  ALA A 129     -27.407   8.712  57.171  1.00 34.50           C
ATOM    996  C   ALA A 129     -27.171   8.455  58.650  1.00 38.35           C
ATOM    997  O   ALA A 129     -26.155   7.867  59.034  1.00 35.86           O
ATOM    998  CB  ALA A 129     -27.959   7.447  56.504  1.00 35.38           C
ATOM    999  N   MET A 130     -28.110   8.903  59.478  1.00 31.95           N
ATOM   1000  CA  MET A 130     -28.039   8.638  60.908  1.00 34.80           C
ATOM   1001  C   MET A 130     -28.416   7.185  61.173  1.00 34.79           C
ATOM   1002  O   MET A 130     -29.550   6.772  60.908  1.00 32.27           O
ATOM   1003  CB  MET A 130     -28.963   9.587  61.667  1.00 34.04           C
ATOM   1004  CG  MET A 130     -28.954   9.416  63.181  1.00 37.20           C
ATOM   1005  SD  MET A 130     -27.369   9.810  63.951  1.00 39.63           S
ATOM   1006  CE  MET A 130     -27.092  11.482  63.378  1.00 29.75           C
ATOM   1007  N   ARG A 131     -27.462   6.409  61.682  1.00 31.26           N
ATOM   1008  CA  ARG A 131     -27.706   5.007  61.960  1.00 27.15           C
ATOM   1009  C   ARG A 131     -28.658   4.859  63.146  1.00 31.08           C
ATOM   1010  O   ARG A 131     -28.791   5.770  63.966  1.00 31.54           O
ATOM   1011  CB  ARG A 131     -26.390   4.285  62.245  1.00 29.07           C
ATOM   1012  CG  ARG A 131     -25.361   4.385  61.129  1.00 34.50           C
ATOM   1013  CD  ARG A 131     -25.937   3.938  59.800  1.00 31.99           C
ATOM   1014  NE  ARG A 131     -26.509   2.598  59.876  1.00 34.57           N
ATOM   1015  CZ  ARG A 131     -27.092   1.978  58.856  1.00 40.72           C
ATOM   1016  NH1 ARG A 131     -27.182   2.579  57.677  1.00 35.13           N
ATOM   1017  NH2 ARG A 131     -27.587   0.758  59.013  1.00 34.74           N
ATOM   1018  N   PRO A 132     -29.344   3.717  63.248  1.00 31.94           N
ATOM   1019  CA  PRO A 132     -30.232   3.495  64.402  1.00 32.67           C
ATOM   1020  C   PRO A 132     -29.533   3.587  65.749  1.00 30.17           C
ATOM   1021  O   PRO A 132     -30.204   3.849  66.755  1.00 34.15           O
ATOM   1022  CB  PRO A 132     -30.783   2.086  64.151  1.00 35.36           C
ATOM   1023  CG  PRO A 132     -30.727   1.929  62.669  1.00 30.83           C
ATOM   1024  CD  PRO A 132     -29.499   2.670  62.222  1.00 31.83           C
ATOM   1025  N   ASN A 133     -28.217   3.378  65.809  1.00 28.62           N
ATOM   1026  CA  ASN A 133     -27.464   3.550  67.044  1.00 32.37           C
ATOM   1027  C   ASN A 133     -26.894   4.959  67.189  1.00 31.42           C
ATOM   1028  O   ASN A 133     -25.970   5.166  67.985  1.00 32.54           O
ATOM   1029  CB  ASN A 133     -26.348   2.504  67.139  1.00 31.04           C
ATOM   1030  CG  ASN A 133     -25.351   2.592  65.996  1.00 34.72           C
ATOM   1031  OD1 ASN A 133     -25.406   3.501  65.169  1.00 33.90           O
ATOM   1032  ND2 ASN A 133     -24.429   1.638  65.948  1.00 34.83           N
ATOM   1033  N   PHE A 134     -27.417   5.918  66.422  1.00 26.93           N
ATOM   1034  CA  PHE A 134     -27.065   7.335  66.535  1.00 30.85           C
ATOM   1035  C   PHE A 134     -25.603   7.605  66.190  1.00 30.75           C
ATOM   1036  O   PHE A 134     -24.990   8.534  66.723  1.00 37.79           O
ATOM   1037  CB  PHE A 134     -27.390   7.880  67.927  1.00 30.04           C
ATOM   1038  CG  PHE A 134     -28.851   7.841  68.270  1.00 28.50           C
ATOM   1039  CD1 PHE A 134     -29.719   8.797  67.770  1.00 32.28           C
ATOM   1040  CD2 PHE A 134     -29.356   6.849  69.094  1.00 31.25           C
ATOM   1041  CE1 PHE A 134     -31.064   8.766  68.084  1.00 30.50           C
ATOM   1042  CE2 PHE A 134     -30.701   6.812  69.413  1.00 34.66           C
ATOM   1043  CZ  PHE A 134     -31.556   7.772  68.906  1.00 39.29           C
ATOM   1044  N   THR A 135     -25.031   6.806  65.298  1.00 29.58           N
ATOM   1045  CA  THR A 135     -23.727   7.085  64.718  1.00 28.22           C
ATOM   1046  C   THR A 135     -23.886   7.364  63.230  1.00 32.13           C
ATOM   1047  O   THR A 135     -24.944   7.136  62.639  1.00 31.70           O
ATOM   1048  CB  THR A 135     -22.756   5.918  64.938  1.00 32.25           C
ATOM   1049  OG1 THR A 135     -23.259   4.744  64.289  1.00 31.33           O
ATOM   1050  CG2 THR A 135     -22.584   5.641  66.420  1.00 30.07           C
ATOM   1051  N   ILE A 136     -22.820   7.880  62.625  1.00 28.12           N
ATOM   1052  CA  ILE A 136     -22.771   8.078  61.184  1.00 32.40           C
ATOM   1053  C   ILE A 136     -21.455   7.521  60.664  1.00 33.86           C
ATOM   1054  O   ILE A 136     -20.433   7.535  61.358  1.00 29.44           O
ATOM   1055  CB  ILE A 136     -22.935   9.563  60.777  1.00 30.33           C
ATOM   1056  CG1 ILE A 136     -21.760  10.402  61.281  1.00 31.67           C
ATOM   1057  CG2 ILE A 136     -24.257  10.122  61.286  1.00 31.37           C
ATOM   1058  CD1 ILE A 136     -21.685  11.774  60.648  1.00 32.53           C
ATOM   1059  N   LYS A 137     -21.491   7.011  59.436  1.00 33.03           N
ATOM   1060  CA  LYS A 137     -20.306   6.485  58.764  1.00 34.40           C
ATOM   1061  C   LYS A 137     -19.824   7.561  57.797  1.00 36.09           C
ATOM   1062  O   LYS A 137     -20.184   7.575  56.620  1.00 37.73           O
ATOM   1063  CB  LYS A 137     -20.615   5.173  58.052  1.00 39.92           C
ATOM   1064  CG  LYS A 137     -20.622   3.960  58.968  1.00 42.67           C
ATOM   1065  CD  LYS A 137     -21.819   3.065  58.694  1.00 48.77           C
ATOM   1066  CE  LYS A 137     -21.664   1.713  59.370  1.00 47.32           C
ATOM   1067  NZ  LYS A 137     -22.790   0.796  59.040  1.00 54.99           N
ATOM   1068  N   GLY A 138     -19.006   8.480  58.310  1.00 38.65           N
ATOM   1069  CA  GLY A 138     -18.490   9.579  57.540  1.00 31.62           C
ATOM   1070  C   GLY A 138     -17.015   9.433  57.225  1.00 32.08           C
ATOM   1071  O   GLY A 138     -16.431   8.347  57.314  1.00 36.03           O
ATOM   1072  N   SER A 139     -16.406  10.554  56.841  1.00 30.94           N
ATOM   1073  CA  SER A 139     -14.974  10.612  56.546  1.00 34.67           C
ATOM   1074  C   SER A 139     -14.441  11.890  57.186  1.00 35.77           C
ATOM   1075  O   SER A 139     -14.612  12.983  56.638  1.00 34.43           O
ATOM   1076  CB  SER A 139     -14.714  10.584  55.045  1.00 32.16           C
ATOM   1077  OG  SER A 139     -13.436  11.114  54.738  1.00 38.59           O
ATOM   1078  N   PHE A 140     -13.801  11.748  58.344  1.00 35.40           N
ATOM   1079  CA  PHE A 140     -13.339  12.882  59.131  1.00 32.87           C
ATOM   1080  C   PHE A 140     -11.914  12.636  59.601  1.00 35.47           C
ATOM   1081  O   PHE A 140     -11.601  11.554  60.109  1.00 36.85           O
ATOM   1082  CB  PHE A 140     -14.252  13.122  60.337  1.00 30.41           C
ATOM   1083  CG  PHE A 140     -15.641  13.561  59.972  1.00 31.56           C
ATOM   1084  CD1 PHE A 140     -15.874  14.835  59.480  1.00 31.97           C
ATOM   1085  CD2 PHE A 140     -16.716  12.702  60.129  1.00 29.36           C
ATOM   1086  CE1 PHE A 140     -17.153  15.243  59.148  1.00 27.22           C
ATOM   1087  CE2 PHE A 140     -17.997  13.103  59.798  1.00 31.61           C
ATOM   1088  CZ  PHE A 140     -18.215  14.376  59.308  1.00 31.05           C
ATOM   1089  N   LEU A 141     -11.059  13.638  59.435  1.00 34.91           N
ATOM   1090  CA  LEU A 141      -9.684  13.609  59.906  1.00 39.01           C
ATOM   1091  C   LEU A 141      -9.519  14.591  61.062  1.00 40.38           C
ATOM   1092  O   LEU A 141     -10.482  15.206  61.530  1.00 36.02           O
ATOM   1093  CB  LEU A 141      -8.716  13.934  58.766  1.00 41.14           C
ATOM   1094  CG  LEU A 141      -8.832  13.083  57.500  1.00 46.39           C
ATOM   1095  CD1 LEU A 141      -7.882  13.589  56.425  1.00 48.33           C
ATOM   1096  CD2 LEU A 141      -8.563  11.619  57.811  1.00 44.19           C
ATOM   1097  N   ASN A 142      -8.278  14.733  61.524  1.00 43.31           N
ATOM   1098  CA  ASN A 142      -7.978  15.712  62.559  1.00 43.18           C
ATOM   1099  C   ASN A 142      -8.280  17.118  62.054  1.00 38.14           C
ATOM   1100  O   ASN A 142      -7.951  17.473  60.919  1.00 41.06           O
ATOM   1101  CB  ASN A 142      -6.512  15.609  62.983  1.00 38.76           C
ATOM   1102  CG  ASN A 142      -6.236  14.393  63.847  1.00 50.71           C
ATOM   1103  OD1 ASN A 142      -7.080  13.508  63.983  1.00 51.97           O
ATOM   1104  ND2 ASN A 142      -5.048  14.345  64.438  1.00 55.08           N
ATOM   1105  N   GLY A 143      -8.918  17.918  62.905  1.00 39.21           N
ATOM   1106  CA  GLY A 143      -9.293  19.269  62.548  1.00 38.72           C
ATOM   1107  C   GLY A 143     -10.611  19.402  61.819  1.00 37.47           C
ATOM   1108  O   GLY A 143     -10.969  20.518  61.421  1.00 38.50           O
ATOM   1109  N   SER A 144     -11.343  18.306  61.628  1.00 35.11           N
ATOM   1110  CA  SER A 144     -12.628  18.343  60.945  1.00 32.51           C
ATOM   1111  C   SER A 144     -13.788  18.683  61.871  1.00 31.55           C
ATOM   1112  O   SER A 144     -14.921  18.811  61.394  1.00 30.39           O
ATOM   1113  CB  SER A 144     -12.901  16.998  60.267  1.00 30.85           C
ATOM   1114  OG  SER A 144     -13.231  16.013  61.229  1.00 30.21           O
ATOM   1115  N   CYS A 145     -13.539  18.826  63.171  1.00 31.83           N
ATOM   1116  CA  CYS A 145     -14.607  19.162  64.101  1.00 29.71           C
ATOM   1117  C   CYS A 145     -15.167  20.542  63.788  1.00 30.11           C
ATOM   1118  O   CYS A 145     -14.451  21.446  63.350  1.00 29.28           O
ATOM   1119  CB  CYS A 145     -14.100  19.110  65.541  1.00 31.75           C
ATOM   1120  SG  CYS A 145     -14.043  17.445  66.228  1.00 39.68           S
ATOM   1121  N   GLY A 146     -16.467  20.699  64.023  1.00 24.04           N
ATOM   1122  CA  GLY A 146     -17.189  21.858  63.556  1.00 27.34           C
ATOM   1123  C   GLY A 146     -17.833  21.683  62.200  1.00 29.22           C
ATOM   1124  O   GLY A 146     -18.678  22.506  61.824  1.00 31.51           O
ATOM   1125  N   SER A 147     -17.454  20.646  61.454  1.00 31.09           N
ATOM   1126  CA  SER A 147     -18.140  20.326  60.210  1.00 29.06           C
ATOM   1127  C   SER A 147     -19.613  20.069  60.492  1.00 28.41           C
ATOM   1128  O   SER A 147     -19.968  19.419  61.478  1.00 29.87           O
ATOM   1129  CB  SER A 147     -17.507  19.098  59.554  1.00 26.05           C
ATOM   1130  OG  SER A 147     -16.269  19.423  58.945  1.00 30.42           O
ATOM   1131  N   VAL A 148     -20.476  20.592  59.628  1.00 25.32           N
ATOM   1132  CA  VAL A 148     -21.911  20.554  59.865  1.00 26.26           C
ATOM   1133  C   VAL A 148     -22.584  19.656  58.838  1.00 30.06           C
ATOM   1134  O   VAL A 148     -22.147  19.544  57.686  1.00 28.78           O
ATOM   1135  CB  VAL A 148     -22.529  21.969  59.846  1.00 28.80           C
ATOM   1136  CG1 VAL A 148     -21.922  22.824  60.950  1.00 30.53           C
ATOM   1137  CG2 VAL A 148     -22.327  22.622  58.490  1.00 29.80           C
ATOM   1138  N   GLY A 149     -23.650  18.994  59.276  1.00 27.96           N
ATOM   1139  CA  GLY A 149     -24.540  18.274  58.391  1.00 28.23           C
ATOM   1140  C   GLY A 149     -25.803  19.086  58.180  1.00 31.16           C
ATOM   1141  O   GLY A 149     -26.241  19.814  59.068  1.00 26.13           O
ATOM   1142  N   PHE A 150     -26.389  18.963  56.992  1.00 23.38           N
ATOM   1143  CA  PHE A 150     -27.490  19.848  56.642  1.00 31.49           C
ATOM   1144  C   PHE A 150     -28.366  19.206  55.578  1.00 30.93           C
ATOM   1145  O   PHE A 150     -27.928  18.340  54.816  1.00 27.90           O
ATOM   1146  CB  PHE A 150     -26.972  21.204  56.149  1.00 27.04           C
ATOM   1147  CG  PHE A 150     -26.192  21.125  54.865  1.00 30.67           C
ATOM   1148  CD1 PHE A 150     -24.842  20.814  54.876  1.00 30.93           C
ATOM   1149  CD2 PHE A 150     -26.810  21.359  53.646  1.00 32.96           C
ATOM   1150  CE1 PHE A 150     -24.122  20.737  53.698  1.00 31.18           C
ATOM   1151  CE2 PHE A 150     -26.096  21.284  52.464  1.00 33.04           C
ATOM   1152  CZ  PHE A 150     -24.750  20.973  52.491  1.00 36.84           C
ATOM   1153  N   ASN A 151     -29.619  19.653  55.547  1.00 30.01           N
ATOM   1154  CA  ASN A 151     -30.535  19.417  54.444  1.00 35.52           C
ATOM   1155  C   ASN A 151     -31.061  20.760  53.956  1.00 36.96           C
ATOM   1156  O   ASN A 151     -31.091  21.740  54.707  1.00 32.38           O
ATOM   1157  CB  ASN A 151     -31.705  18.512  54.857  1.00 28.64           C
ATOM   1158  CG  ASN A 151     -31.300  17.057  54.989  1.00 32.83           C
ATOM   1159  OD1 ASN A 151     -31.020  16.385  53.996  1.00 34.81           O
ATOM   1160  ND2 ASN A 151     -31.273  16.559  56.220  1.00 29.52           N
ATOM   1161  N   ILE A 152     -31.466  20.808  52.691  1.00 34.85           N
ATOM   1162  CA  ILE A 152     -31.988  22.025  52.078  1.00 41.87           C
ATOM   1163  C   ILE A 152     -33.430  21.754  51.677  1.00 46.04           C
ATOM   1164  O   ILE A 152     -33.688  21.003  50.728  1.00 52.68           O
ATOM   1165  CB  ILE A 152     -31.150  22.471  50.873  1.00 40.85           C
ATOM   1166  CG1 ILE A 152     -29.720  22.793  51.311  1.00 39.00           C
ATOM   1167  CG2 ILE A 152     -31.784  23.682  50.206  1.00 50.84           C
ATOM   1168  CD1 ILE A 152     -28.825  23.255  50.183  1.00 43.80           C
ATOM   1169  N   ASP A 153     -34.371  22.360  52.401  1.00 52.69           N
ATOM   1170  CA  ASP A 153     -35.790  22.190  52.117  1.00 60.52           C
ATOM   1171  C   ASP A 153     -36.142  22.824  50.778  1.00 67.41           C
ATOM   1172  O   ASP A 153     -36.488  22.124  49.821  1.00 75.21           O
ATOM   1173  CB  ASP A 153     -36.634  22.799  53.240  1.00 65.02           C
ATOM   1174  CG  ASP A 153     -37.904  22.015  53.504  1.00 75.87           C
ATOM   1175  OD1 ASP A 153     -38.568  21.610  52.527  1.00 79.94           O
ATOM   1176  OD2 ASP A 153     -38.237  21.804  54.689  1.00 77.44           O
ATOM   1177  N   TYR A 154     -36.061  24.151  50.708  1.00 64.25           N
ATOM   1178  CA  TYR A 154     -36.246  24.881  49.460  1.00 62.43           C
ATOM   1179  C   TYR A 154     -35.086  25.848  49.280  1.00 62.70           C
ATOM   1180  O   TYR A 154     -34.151  25.583  48.517  1.00 64.83           O
ATOM   1181  CB  TYR A 154     -37.580  25.629  49.462  1.00 64.26           C
ATOM   1182  CG  TYR A 154     -38.651  24.978  48.620  1.00 76.60           C
ATOM   1183  CD1 TYR A 154     -39.239  23.783  49.011  1.00 76.32           C
ATOM   1184  CD2 TYR A 154     -39.078  25.561  47.434  1.00 76.70           C
ATOM   1185  CE1 TYR A 154     -40.222  23.184  48.244  1.00 81.64           C
ATOM   1186  CE2 TYR A 154     -40.060  24.971  46.661  1.00 80.18           C
ATOM   1187  CZ  TYR A 154     -40.628  23.783  47.070  1.00 86.72           C
ATOM   1188  OH  TYR A 154     -41.606  23.193  46.302  1.00 86.80           O
ATOM   1189  N   ASP A 155     -35.146  26.976  49.984  1.00 60.02           N
ATOM   1190  CA  ASP A 155     -34.013  27.876  50.141  1.00 63.44           C
ATOM   1191  C   ASP A 155     -33.515  27.902  51.580  1.00 54.86           C
ATOM   1192  O   ASP A 155     -32.618  28.687  51.905  1.00 53.92           O
ATOM   1193  CB  ASP A 155     -34.386  29.289  49.689  1.00 63.17           C
ATOM   1194  CG  ASP A 155     -35.485  29.899  50.537  1.00 67.12           C
ATOM   1195  OD1 ASP A 155     -36.403  29.158  50.949  1.00 65.31           O
ATOM   1196  OD2 ASP A 155     -35.432  31.120  50.794  1.00 67.10           O
ATOM   1197  N   CYS A 156     -34.076  27.059  52.443  1.00 51.88           N
ATOM   1198  CA  CYS A 156     -33.808  27.088  53.873  1.00 47.11           C
ATOM   1199  C   CYS A 156     -32.890  25.929  54.241  1.00 40.27           C
ATOM   1200  O   CYS A 156     -33.222  24.765  53.995  1.00 40.45           O
ATOM   1201  CB  CYS A 156     -35.112  27.015  54.665  1.00 48.72           C
ATOM   1202  SG  CYS A 156     -34.916  26.550  56.396  1.00 55.79           S
ATOM   1203  N   VAL A 157     -31.744  26.248  54.832  1.00 37.71           N
ATOM   1204  CA  VAL A 157     -30.794  25.228  55.263  1.00 40.48           C
ATOM   1205  C   VAL A 157     -31.224  24.697  56.622  1.00 37.91           C
ATOM   1206  O   VAL A 157     -31.343  25.457  57.591  1.00 37.72           O
ATOM   1207  CB  VAL A 157     -29.365  25.786  55.319  1.00 39.56           C
ATOM   1208  CG1 VAL A 157     -28.378  24.675  55.644  1.00 27.46           C
ATOM   1209  CG2 VAL A 157     -29.003  26.458  54.006  1.00 42.10           C
ATOM   1210  N   SER A 158     -31.451  23.391  56.703  1.00 37.64           N
ATOM   1211  CA  SER A 158     -31.802  22.730  57.956  1.00 35.25           C
ATOM   1212  C   SER A 158     -30.540  22.086  58.517  1.00 34.22           C
ATOM   1213  O   SER A 158     -30.177  20.971  58.135  1.00 33.58           O
ATOM   1214  CB  SER A 158     -32.911  21.706  57.742  1.00 39.56           C
ATOM   1215  OG  SER A 158     -34.152  22.344  57.498  1.00 46.93           O
ATOM   1216  N   PHE A 159     -29.865  22.797  59.418  1.00 30.35           N
ATOM   1217  CA  PHE A 159     -28.686  22.252  60.075  1.00 28.89           C
ATOM   1218  C   PHE A 159     -29.103  21.192  61.086  1.00 30.48           C
ATOM   1219  O   PHE A 159     -29.973  21.431  61.929  1.00 31.69           O
ATOM   1220  CB  PHE A 159     -27.896  23.363  60.765  1.00 29.95           C
ATOM   1221  CG  PHE A 159     -27.286  24.355  59.817  1.00 27.12           C
ATOM   1222  CD1 PHE A 159     -26.139  24.041  59.107  1.00 23.30           C
ATOM   1223  CD2 PHE A 159     -27.857  25.606  59.642  1.00 27.13           C
ATOM   1224  CE1 PHE A 159     -25.574  24.954  58.236  1.00 22.49           C
ATOM   1225  CE2 PHE A 159     -27.298  26.523  58.772  1.00 21.46           C
ATOM   1226  CZ  PHE A 159     -26.154  26.197  58.069  1.00 25.42           C
ATOM   1227  N   CYS A 160     -28.477  20.018  61.007  1.00 27.59           N
ATOM   1228  CA  CYS A 160     -28.876  18.893  61.837  1.00 29.38           C
ATOM   1229  C   CYS A 160     -27.729  18.182  62.538  1.00 27.74           C
ATOM   1230  O   CYS A 160     -27.997  17.319  63.381  1.00 32.03           O
ATOM   1231  CB  CYS A 160     -29.647  17.861  61.000  1.00 31.79           C
ATOM   1232  SG  CYS A 160     -28.664  17.112  59.689  1.00 35.39           S
ATOM   1233  N   TYR A 161     -26.476  18.509  62.235  1.00 29.51           N
ATOM   1234  CA  TYR A 161     -25.358  17.750  62.774  1.00 24.96           C
ATOM   1235  C   TYR A 161     -24.121  18.631  62.848  1.00 27.16           C
ATOM   1236  O   TYR A 161     -23.894  19.470  61.974  1.00 25.90           O
ATOM   1237  CB  TYR A 161     -25.074  16.515  61.911  1.00 27.30           C
ATOM   1238  CG  TYR A 161     -23.908  15.673  62.380  1.00 27.33           C
ATOM   1239  CD1 TYR A 161     -24.061  14.756  63.411  1.00 24.56           C
ATOM   1240  CD2 TYR A 161     -22.659  15.785  61.783  1.00 27.43           C
ATOM   1241  CE1 TYR A 161     -23.004  13.980  63.839  1.00 23.21           C
ATOM   1242  CE2 TYR A 161     -21.594  15.013  62.205  1.00 21.74           C
ATOM   1243  CZ  TYR A 161     -21.772  14.113  63.234  1.00 26.15           C
ATOM   1244  OH  TYR A 161     -20.715  13.340  63.658  1.00 24.39           O
ATOM   1245  N   MET A 162     -23.334  18.436  63.904  1.00 25.59           N
ATOM   1246  CA  MET A 162     -22.006  19.022  64.028  1.00 26.04           C
ATOM   1247  C   MET A 162     -21.066  17.931  64.516  1.00 23.77           C
ATOM   1248  O   MET A 162     -21.362  17.252  65.504  1.00 23.47           O
ATOM   1249  CB  MET A 162     -22.000  20.218  64.990  1.00 24.61           C
ATOM   1250  CG  MET A 162     -20.620  20.824  65.217  1.00 25.23           C
ATOM   1251  SD  MET A 162     -20.661  22.392  66.114  1.00 33.95           S
ATOM   1252  CE  MET A 162     -21.109  23.529  64.803  1.00 28.77           C
ATOM   1253  N   HIS A 163     -19.947  17.753  63.819  1.00 22.67           N
ATOM   1254  CA  HIS A 163     -19.055  16.637  64.100  1.00 27.27           C
ATOM   1255  C   HIS A 163     -18.229  16.896  65.353  1.00 26.21           C
ATOM   1256  O   HIS A 163     -17.765  18.016  65.590  1.00 27.47           O
ATOM   1257  CB  HIS A 163     -18.129  16.377  62.914  1.00 26.00           C
ATOM   1258  CG  HIS A 163     -17.215  15.210  63.116  1.00 26.66           C
ATOM   1259  ND1 HIS A 163     -17.675  13.959  63.466  1.00 26.97           N
ATOM   1260  CD2 HIS A 163     -15.868  15.106  63.030  1.00 29.24           C
ATOM   1261  CE1 HIS A 163     -16.651  13.132  63.581  1.00 30.44           C
ATOM   1262  NE2 HIS A 163     -15.543  13.804  63.323  1.00 30.00           N
ATOM   1263  N   HIS A 164     -18.035  15.849  66.145  1.00 26.00           N
ATOM   1264  CA  HIS A 164     -17.329  15.988  67.414  1.00 34.12           C
ATOM   1265  C   HIS A 164     -16.213  14.974  67.613  1.00 31.14           C
ATOM   1266  O   HIS A 164     -15.161  15.336  68.144  1.00 36.46           O
ATOM   1267  CB  HIS A 164     -18.324  15.889  68.582  1.00 28.25           C
ATOM   1268  CG  HIS A 164     -19.098  17.148  68.820  1.00 33.09           C
ATOM   1269  ND1 HIS A 164     -18.888  17.954  69.918  1.00 29.04           N
ATOM   1270  CD2 HIS A 164     -20.086  17.737  68.106  1.00 29.37           C
ATOM   1271  CE1 HIS A 164     -19.711  18.985  69.869  1.00 28.86           C
ATOM   1272  NE2 HIS A 164     -20.448  18.878  68.777  1.00 31.33           N
ATOM   1273  N   MET A 165     -16.406  13.719  67.212  1.00 28.31           N
ATOM   1274  CA  MET A 165     -15.411  12.702  67.535  1.00 34.30           C
ATOM   1275  C   MET A 165     -15.651  11.445  66.709  1.00 31.53           C
ATOM   1276  O   MET A 165     -16.653  11.315  66.000  1.00 29.92           O
ATOM   1277  CB  MET A 165     -15.435  12.364  69.027  1.00 39.09           C
ATOM   1278  CG  MET A 165     -16.781  11.878  69.510  1.00 36.67           C
ATOM   1279  SD  MET A 165     -16.725  11.270  71.202  1.00 60.86           S
ATOM   1280  CE  MET A 165     -18.459  10.934  71.465  1.00 42.06           C
ATOM   1281  N   GLU A 166     -14.706  10.515  66.826  1.00 29.94           N
ATOM   1282  CA  GLU A 166     -14.746   9.219  66.167  1.00 28.52           C
ATOM   1283  C   GLU A 166     -14.686   8.122  67.218  1.00 33.40           C
ATOM   1284  O   GLU A 166     -13.884   8.192  68.156  1.00 30.18           O
ATOM   1285  CB  GLU A 166     -13.578   9.064  65.189  1.00 29.90           C
ATOM   1286  CG  GLU A 166     -13.555   7.770  64.398  1.00 36.08           C
ATOM   1287  CD  GLU A 166     -12.432   7.748  63.378  1.00 45.17           C
ATOM   1288  OE1 GLU A 166     -11.440   7.019  63.595  1.00 50.70           O
ATOM   1289  OE2 GLU A 166     -12.536   8.469  62.363  1.00 40.54           O
ATOM   1290  N   LEU A 167     -15.536   7.116  67.061  1.00 31.24           N
ATOM   1291  CA  LEU A 167     -15.557   5.995  67.983  1.00 31.34           C
ATOM   1292  C   LEU A 167     -14.508   4.960  67.582  1.00 37.94           C
ATOM   1293  O   LEU A 167     -14.042   4.948  66.439  1.00 38.96           O
ATOM   1294  CB  LEU A 167     -16.951   5.377  68.012  1.00 30.02           C
ATOM   1295  CG  LEU A 167     -18.065   6.349  68.410  1.00 32.99           C
ATOM   1296  CD1 LEU A 167     -19.406   5.641  68.485  1.00 35.09           C
ATOM   1297  CD2 LEU A 167     -17.742   7.034  69.731  1.00 38.72           C
ATOM   1298  N   PRO A 168     -14.101   4.090  68.515  1.00 40.04           N
ATOM   1299  CA  PRO A 168     -13.058   3.099  68.191  1.00 37.38           C
ATOM   1300  C   PRO A 168     -13.368   2.232  66.981  1.00 42.32           C
ATOM   1301  O   PRO A 168     -12.436   1.774  66.307  1.00 43.53           O
ATOM   1302  CB  PRO A 168     -12.975   2.260  69.473  1.00 41.24           C
ATOM   1303  CG  PRO A 168     -13.394   3.190  70.551  1.00 45.84           C
ATOM   1304  CD  PRO A 168     -14.451   4.071  69.947  1.00 33.25           C
ATOM   1305  N   THR A 169     -14.643   1.994  66.680  1.00 40.94           N
ATOM   1306  CA  THR A 169     -15.014   1.178  65.531  1.00 38.12           C
ATOM   1307  C   THR A 169     -14.893   1.920  64.206  1.00 39.50           C
ATOM   1308  O   THR A 169     -15.177   1.330  63.158  1.00 39.33           O
ATOM   1309  CB  THR A 169     -16.446   0.658  65.692  1.00 43.58           C
ATOM   1310  OG1 THR A 169     -17.318   1.747  66.019  1.00 42.26           O
ATOM   1311  CG2 THR A 169     -16.511  -0.390  66.795  1.00 42.72           C
ATOM   1312  N   GLY A 170     -14.482   3.187  64.220  1.00 39.12           N
ATOM   1313  CA  GLY A 170     -14.354   3.965  63.009  1.00 38.41           C
ATOM   1314  C   GLY A 170     -15.560   4.807  62.656  1.00 36.73           C
ATOM   1315  O   GLY A 170     -15.482   5.605  61.713  1.00 39.19           O
ATOM   1316  N   VAL A 171     -16.673   4.654  63.372  1.00 32.47           N
ATOM   1317  CA  VAL A 171     -17.859   5.467  63.130  1.00 29.31           C
ATOM   1318  C   VAL A 171     -17.684   6.804  63.837  1.00 37.09           C
ATOM   1319  O   VAL A 171     -16.757   6.980  64.635  1.00 30.46           O
ATOM   1320  CB  VAL A 171     -19.140   4.752  63.594  1.00 31.56           C
ATOM   1321  CG1 VAL A 171     -19.397   3.520  62.739  1.00 42.63           C
ATOM   1322  CG2 VAL A 171     -19.039   4.380  65.062  1.00 35.89           C
ATOM   1323  N   HIS A 172     -18.573   7.750  63.554  1.00 33.02           N
ATOM   1324  CA  HIS A 172     -18.444   9.111  64.049  1.00 29.85           C
ATOM   1325  C   HIS A 172     -19.693   9.516  64.819  1.00 28.73           C
ATOM   1326  O   HIS A 172     -20.794   9.023  64.557  1.00 28.16           O
ATOM   1327  CB  HIS A 172     -18.188  10.085  62.898  1.00 26.76           C
ATOM   1328  CG  HIS A 172     -16.989   9.734  62.074  1.00 28.76           C
ATOM   1329  ND1 HIS A 172     -17.070   8.967  60.931  1.00 29.32           N
ATOM   1330  CD2 HIS A 172     -15.678  10.030  62.237  1.00 29.36           C
ATOM   1331  CE1 HIS A 172     -15.861   8.814  60.420  1.00 32.44           C
ATOM   1332  NE2 HIS A 172     -14.998   9.449  61.194  1.00 33.77           N
ATOM   1333  N   ALA A 173     -19.507  10.418  65.779  1.00 29.16           N
ATOM   1334  CA  ALA A 173     -20.584  10.891  66.630  1.00 26.75           C
ATOM   1335  C   ALA A 173     -20.528  12.406  66.731  1.00 26.43           C
ATOM   1336  O   ALA A 173     -19.460  13.014  66.626  1.00 28.60           O
ATOM   1337  CB  ALA A 173     -20.510  10.272  68.034  1.00 32.55           C
ATOM   1338  N   GLY A 174     -21.691  13.009  66.934  1.00 26.18           N
ATOM   1339  CA  GLY A 174     -21.774  14.443  67.066  1.00 26.05           C
ATOM   1340  C   GLY A 174     -23.081  14.855  67.700  1.00 24.76           C
ATOM   1341  O   GLY A 174     -23.815  14.031  68.247  1.00 24.68           O
ATOM   1342  N   THR A 175     -23.363  16.149  67.615  1.00 16.91           N
ATOM   1343  CA  THR A 175     -24.546  16.742  68.216  1.00 19.69           C
ATOM   1344  C   THR A 175     -25.379  17.433  67.146  1.00 24.03           C
ATOM   1345  O   THR A 175     -24.942  17.622  66.008  1.00 25.67           O
ATOM   1346  CB  THR A 175     -24.167  17.756  69.306  1.00 24.54           C
ATOM   1347  OG1 THR A 175     -23.698  18.964  68.692  1.00 26.54           O
ATOM   1348  CG2 THR A 175     -23.072  17.203  70.202  1.00 20.58           C
ATOM   1349  N   ASP A 176     -26.597  17.810  67.524  1.00 25.13           N
ATOM   1350  CA  ASP A 176     -27.340  18.787  66.746  1.00 29.00           C
ATOM   1351  C   ASP A 176     -26.767  20.177  67.025  1.00 33.50           C
ATOM   1352  O   ASP A 176     -25.829  20.342  67.810  1.00 30.82           O
ATOM   1353  CB  ASP A 176     -28.833  18.706  67.062  1.00 27.46           C
ATOM   1354  CG  ASP A 176     -29.127  18.779  68.551  1.00 37.54           C
ATOM   1355  OD1 ASP A 176     -28.308  19.340  69.309  1.00 34.75           O
ATOM   1356  OD2 ASP A 176     -30.193  18.278  68.967  1.00 40.56           O
ATOM   1357  N   LEU A 177     -27.336  21.196  66.384  1.00 31.64           N
ATOM   1358  CA  LEU A 177     -26.765  22.531  66.521  1.00 33.12           C
ATOM   1359  C   LEU A 177     -27.097  23.194  67.852  1.00 32.72           C
ATOM   1360  O   LEU A 177     -26.627  24.310  68.094  1.00 33.84           O
ATOM   1361  CB  LEU A 177     -27.213  23.423  65.359  1.00 27.51           C
ATOM   1362  CG  LEU A 177     -26.192  23.530  64.223  1.00 33.83           C
ATOM   1363  CD1 LEU A 177     -24.893  24.130  64.742  1.00 35.02           C
ATOM   1364  CD2 LEU A 177     -25.936  22.174  63.580  1.00 35.14           C
ATOM   1365  N   GLU A 178     -27.881  22.545  68.708  1.00 36.73           N
ATOM   1366  CA  GLU A 178     -28.137  23.000  70.069  1.00 36.85           C
ATOM   1367  C   GLU A 178     -27.211  22.346  71.086  1.00 38.02           C
ATOM   1368  O   GLU A 178     -27.408  22.527  72.293  1.00 34.89           O
ATOM   1369  CB  GLU A 178     -29.596  22.728  70.455  1.00 41.16           C
ATOM   1370  CG  GLU A 178     -30.603  22.994  69.339  1.00 47.87           C
ATOM   1371  CD  GLU A 178     -31.132  24.411  69.373  1.00 55.20           C
ATOM   1372  OE1 GLU A 178     -30.472  25.274  69.991  1.00 59.22           O
ATOM   1373  OE2 GLU A 178     -32.202  24.659  68.777  1.00 54.25           O
ATOM   1374  N   GLY A 179     -26.234  21.562  70.630  1.00 30.25           N
ATOM   1375  CA  GLY A 179     -25.243  20.969  71.502  1.00 31.66           C
ATOM   1376  C   GLY A 179     -25.615  19.635  72.107  1.00 32.94           C
ATOM   1377  O   GLY A 179     -24.836  19.097  72.903  1.00 31.20           O
ATOM   1378  N   ASN A 180     -26.770  19.079  71.762  1.00 30.48           N
ATOM   1379  CA  ASN A 180     -27.215  17.821  72.345  1.00 28.25           C
ATOM   1380  C   ASN A 180     -26.740  16.665  71.474  1.00 30.27           C
ATOM   1381  O   ASN A 180     -27.064  16.602  70.282  1.00 27.11           O
ATOM   1382  CB  ASN A 180     -28.734  17.811  72.497  1.00 33.31           C
ATOM   1383  CG  ASN A 180     -29.230  18.923  73.398  1.00 35.42           C
ATOM   1384  OD1 ASN A 180     -28.937  18.946  74.594  1.00 31.56           O
ATOM   1385  ND2 ASN A 180     -29.981  19.855  72.827  1.00 37.17           N
ATOM   1386  N   PHE A 181     -25.981  15.753  72.077  1.00 24.02           N
ATOM   1387  CA  PHE A 181     -25.398  14.642  71.338  1.00 28.64           C
ATOM   1388  C   PHE A 181     -26.479  13.740  70.757  1.00 27.74           C
ATOM   1389  O   PHE A 181     -27.564  13.582  71.323  1.00 27.75           O
ATOM   1390  CB  PHE A 181     -24.481  13.819  72.247  1.00 26.92           C
ATOM   1391  CG  PHE A 181     -23.017  14.038  71.995  1.00 26.64           C
ATOM   1392  CD1 PHE A 181     -22.363  13.354  70.983  1.00 25.04           C
ATOM   1393  CD2 PHE A 181     -22.293  14.923  72.777  1.00 26.06           C
ATOM   1394  CE1 PHE A 181     -21.015  13.554  70.752  1.00 27.50           C
ATOM   1395  CE2 PHE A 181     -20.946  15.127  72.550  1.00 29.98           C
ATOM   1396  CZ  PHE A 181     -20.306  14.442  71.537  1.00 29.14           C
ATOM   1397  N   TYR A 182     -26.172  13.150  69.604  1.00 29.30           N
ATOM   1398  CA  TYR A 182     -26.962  12.046  69.071  1.00 25.62           C
ATOM   1399  C   TYR A 182     -26.467  10.762  69.725  1.00 24.04           C
ATOM   1400  O   TYR A 182     -25.329  10.342  69.494  1.00 28.52           O
ATOM   1401  CB  TYR A 182     -26.837  11.965  67.552  1.00 26.72           C
ATOM   1402  CG  TYR A 182     -27.578  13.040  66.788  1.00 25.22           C
ATOM   1403  CD1 TYR A 182     -28.946  12.945  66.566  1.00 23.72           C
ATOM   1404  CD2 TYR A 182     -26.905  14.139  66.271  1.00 23.16           C
ATOM   1405  CE1 TYR A 182     -29.624  13.923  65.860  1.00 28.59           C
ATOM   1406  CE2 TYR A 182     -27.575  15.121  65.564  1.00 22.77           C
ATOM   1407  CZ  TYR A 182     -28.934  15.008  65.362  1.00 24.08           C
ATOM   1408  OH  TYR A 182     -29.603  15.983  64.659  1.00 27.97           O
ATOM   1409  N   GLY A 183     -27.305  10.147  70.556  1.00 25.88           N
ATOM   1410  CA  GLY A 183     -26.962   8.895  71.186  1.00 27.44           C
ATOM   1411  C   GLY A 183     -26.318   9.060  72.547  1.00 28.90           C
ATOM   1412  O   GLY A 183     -26.182  10.171  73.068  1.00 29.68           O
ATOM   1413  N   PRO A 184     -25.898   7.935  73.152  1.00 30.61           N
ATOM   1414  CA  PRO A 184     -25.339   7.951  74.509  1.00 35.89           C
ATOM   1415  C   PRO A 184     -23.844   8.253  74.541  1.00 36.43           C
ATOM   1416  O   PRO A 184     -23.060   7.562  75.201  1.00 45.10           O
ATOM   1417  CB  PRO A 184     -25.635   6.528  75.004  1.00 24.72           C
ATOM   1418  CG  PRO A 184     -25.508   5.698  73.760  1.00 30.83           C
ATOM   1419  CD  PRO A 184     -25.977   6.568  72.606  1.00 30.89           C
ATOM   1420  N   PHE A 185     -23.436   9.298  73.830  1.00 27.29           N
ATOM   1421  CA  PHE A 185     -22.032   9.643  73.683  1.00 32.30           C
ATOM   1422  C   PHE A 185     -21.727  10.963  74.380  1.00 36.71           C
ATOM   1423  O   PHE A 185     -22.596  11.829  74.517  1.00 31.19           O
ATOM   1424  CB  PHE A 185     -21.649   9.727  72.203  1.00 32.57           C
ATOM   1425  CG  PHE A 185     -22.037   8.512  71.409  1.00 36.05           C
ATOM   1426  CD1 PHE A 185     -21.517   7.268  71.722  1.00 34.58           C
ATOM   1427  CD2 PHE A 185     -22.924   8.614  70.350  1.00 26.99           C
ATOM   1428  CE1 PHE A 185     -21.874   6.148  70.995  1.00 34.92           C
ATOM   1429  CE2 PHE A 185     -23.284   7.499  69.618  1.00 32.50           C
ATOM   1430  CZ  PHE A 185     -22.759   6.264  69.942  1.00 30.45           C
ATOM   1431  N   VAL A 186     -20.479  11.103  74.826  1.00 36.48           N
ATOM   1432  CA  VAL A 186     -20.018  12.289  75.535  1.00 34.53           C
ATOM   1433  C   VAL A 186     -18.704  12.748  74.921  1.00 37.15           C
ATOM   1434  O   VAL A 186     -17.918  11.941  74.414  1.00 33.71           O
ATOM   1435  CB  VAL A 186     -19.850  12.026  77.049  1.00 41.23           C
ATOM   1436  CG1 VAL A 186     -21.209  11.867  77.714  1.00 34.01           C
ATOM   1437  CG2 VAL A 186     -18.990  10.794  77.281  1.00 36.94           C
ATOM   1438  N   ASP A 187     -18.462  14.058  74.974  1.00 35.60           N
ATOM   1439  CA  ASP A 187     -17.285  14.649  74.335  1.00 38.81           C
ATOM   1440  C   ASP A 187     -16.080  14.562  75.275  1.00 37.16           C
ATOM   1441  O   ASP A 187     -15.610  15.547  75.849  1.00 38.69           O
ATOM   1442  CB  ASP A 187     -17.570  16.085  73.910  1.00 37.81           C
ATOM   1443  CG  ASP A 187     -18.234  16.899  75.003  1.00 33.44           C
ATOM   1444  OD1 ASP A 187     -18.687  16.304  76.002  1.00 35.30           O
ATOM   1445  OD2 ASP A 187     -18.304  18.138  74.859  1.00 36.30           O
ATOM   1446  N   ARG A 188     -15.579  13.337  75.418  1.00 41.07           N
ATOM   1447  CA  ARG A 188     -14.355  13.079  76.161  1.00 45.49           C
ATOM   1448  C   ARG A 188     -13.724  11.804  75.623  1.00 43.19           C
ATOM   1449  O   ARG A 188     -14.419  10.908  75.136  1.00 36.57           O
ATOM   1450  CB  ARG A 188     -14.612  12.971  77.671  1.00 44.09           C
ATOM   1451  CG  ARG A 188     -15.279  11.681  78.112  1.00 47.40           C
ATOM   1452  CD  ARG A 188     -15.488  11.659  79.620  1.00 57.54           C
ATOM   1453  NE  ARG A 188     -16.780  12.223  80.002  1.00 56.65           N
ATOM   1454  CZ  ARG A 188     -16.946  13.445  80.498  1.00 62.33           C
ATOM   1455  NH1 ARG A 188     -15.900  14.242  80.676  1.00 58.47           N
ATOM   1456  NH2 ARG A 188     -18.161  13.872  80.816  1.00 62.46           N
ATOM   1457  N   GLN A 189     -12.396  11.733  75.709  1.00 40.15           N
ATOM   1458  CA  GLN A 189     -11.641  10.633  75.105  1.00 38.41           C
ATOM   1459  C   GLN A 189     -11.563   9.455  76.078  1.00 45.05           C
ATOM   1460  O   GLN A 189     -10.526   9.149  76.671  1.00 49.99           O
ATOM   1461  CB  GLN A 189     -10.259  11.109  74.679  1.00 37.59           C
ATOM   1462  CG  GLN A 189     -10.292  12.189  73.610  1.00 45.60           C
ATOM   1463  CD  GLN A 189      -9.031  12.221  72.770  1.00 47.27           C
ATOM   1464  OE1 GLN A 189      -9.029  12.743  71.655  1.00 41.09           O
ATOM   1465  NE2 GLN A 189      -7.950  11.661  73.302  1.00 44.45           N
ATOM   1466  N   THR A 190     -12.702   8.787  76.228  1.00 41.89           N
ATOM   1467  CA  THR A 190     -12.814   7.564  77.007  1.00 42.08           C
ATOM   1468  C   THR A 190     -13.344   6.446  76.119  1.00 49.27           C
ATOM   1469  O   THR A 190     -13.749   6.665  74.974  1.00 47.54           O
ATOM   1470  CB  THR A 190     -13.733   7.754  78.222  1.00 45.73           C
ATOM   1471  OG1 THR A 190     -15.045   8.123  77.779  1.00 46.25           O
ATOM   1472  CG2 THR A 190     -13.185   8.833  79.143  1.00 45.44           C
ATOM   1473  N   ALA A 191     -13.336   5.232  76.664  1.00 45.12           N
ATOM   1474  CA  ALA A 191     -13.843   4.070  75.942  1.00 47.47           C
ATOM   1475  C   ALA A 191     -15.358   4.175  75.817  1.00 44.46           C
ATOM   1476  O   ALA A 191     -16.083   4.018  76.804  1.00 47.06           O
ATOM   1477  CB  ALA A 191     -13.439   2.785  76.657  1.00 48.89           C
ATOM   1478  N   GLN A 192     -15.837   4.449  74.607  1.00 42.30           N
ATOM   1479  CA  GLN A 192     -17.263   4.548  74.333  1.00 42.18           C
ATOM   1480  C   GLN A 192     -17.611   3.644  73.162  1.00 43.69           C
ATOM   1481  O   GLN A 192     -16.900   3.617  72.153  1.00 41.21           O
ATOM   1482  CB  GLN A 192     -17.677   5.991  74.025  1.00 38.85           C
ATOM   1483  CG  GLN A 192     -17.435   6.959  75.168  1.00 39.81           C
ATOM   1484  CD  GLN A 192     -17.848   8.375  74.827  1.00 39.03           C
ATOM   1485  OE1 GLN A 192     -19.034   8.669  74.678  1.00 40.68           O
ATOM   1486  NE2 GLN A 192     -16.869   9.262  74.699  1.00 37.82           N
ATOM   1487  N   ALA A 193     -18.708   2.907  73.301  1.00 43.81           N
ATOM   1488  CA  ALA A 193     -19.155   1.967  72.287  1.00 46.29           C
ATOM   1489  C   ALA A 193     -20.565   2.321  71.839  1.00 38.09           C
ATOM   1490  O   ALA A 193     -21.434   2.620  72.664  1.00 36.20           O
ATOM   1491  CB  ALA A 193     -19.121   0.528  72.810  1.00 40.37           C
ATOM   1492  N   ALA A 194     -20.782   2.292  70.530  1.00 44.33           N
ATOM   1493  CA  ALA A 194     -22.125   2.458  70.000  1.00 41.14           C
ATOM   1494  C   ALA A 194     -22.943   1.195  70.236  1.00 38.55           C
ATOM   1495  O   ALA A 194     -22.406   0.087  70.320  1.00 41.94           O
ATOM   1496  CB  ALA A 194     -22.075   2.776  68.507  1.00 40.77           C
ATOM   1497  N   GLY A 195     -24.253   1.373  70.352  1.00 45.01           N
ATOM   1498  CA  GLY A 195     -25.147   0.237  70.444  1.00 44.26           C
ATOM   1499  C   GLY A 195     -25.122  -0.599  69.179  1.00 40.94           C
ATOM   1500  O   GLY A 195     -24.599  -0.208  68.136  1.00 36.27           O
ATOM   1501  N   THR A 196     -25.699  -1.793  69.288  1.00 46.36           N
ATOM   1502  CA  THR A 196     -25.764  -2.699  68.149  1.00 43.74           C
ATOM   1503  C   THR A 196     -26.551  -2.055  67.016  1.00 40.82           C
ATOM   1504  O   THR A 196     -27.701  -1.644  67.201  1.00 43.52           O
ATOM   1505  CB  THR A 196     -26.405  -4.025  68.560  1.00 47.36           C
ATOM   1506  OG1 THR A 196     -27.692  -3.777  69.141  1.00 56.08           O
ATOM   1507  CG2 THR A 196     -25.527  -4.749  69.572  1.00 46.11           C
ATOM   1508  N   ASP A 197     -25.925  -1.955  65.848  1.00 38.21           N
ATOM   1509  CA  ASP A 197     -26.566  -1.321  64.709  1.00 43.61           C
ATOM   1510  C   ASP A 197     -27.552  -2.279  64.046  1.00 46.04           C
ATOM   1511  O   ASP A 197     -27.460  -3.503  64.178  1.00 42.52           O
ATOM   1512  CB  ASP A 197     -25.524  -0.854  63.692  1.00 42.39           C
ATOM   1513  CG  ASP A 197     -26.014   0.305  62.848  1.00 48.19           C
ATOM   1514  OD1 ASP A 197     -27.002   0.957  63.247  1.00 43.28           O
ATOM   1515  OD2 ASP A 197     -25.414   0.563  61.783  1.00 52.59           O
ATOM   1516  N   THR A 198     -28.512  -1.699  63.331  1.00 44.88           N
ATOM   1517  CA  THR A 198     -29.509  -2.459  62.593  1.00 42.59           C
ATOM   1518  C   THR A 198     -29.668  -1.846  61.209  1.00 41.18           C
ATOM   1519  O   THR A 198     -29.206  -0.734  60.940  1.00 40.92           O
ATOM   1520  CB  THR A 198     -30.858  -2.491  63.326  1.00 45.63           C
ATOM   1521  OG1 THR A 198     -31.228  -1.160  63.707  1.00 45.23           O
ATOM   1522  CG2 THR A 198     -30.774  -3.365  64.569  1.00 43.99           C
ATOM   1523  N   THR A 199     -30.329  -2.587  60.324  1.00 36.67           N
ATOM   1524  CA  THR A 199     -30.578  -2.128  58.965  1.00 31.64           C
ATOM   1525  C   THR A 199     -31.920  -1.410  58.905  1.00 34.02           C
ATOM   1526  O   THR A 199     -32.924  -1.914  59.418  1.00 36.37           O
ATOM   1527  CB  THR A 199     -30.558  -3.300  57.984  1.00 36.63           C
ATOM   1528  OG1 THR A 199     -29.278  -3.942  58.029  1.00 37.11           O
ATOM   1529  CG2 THR A 199     -30.819  -2.814  56.567  1.00 31.73           C
ATOM   1530  N   ILE A 200     -31.932  -0.232  58.283  1.00 31.92           N
ATOM   1531  CA  ILE A 200     -33.151   0.562  58.139  1.00 27.61           C
ATOM   1532  C   ILE A 200     -33.977  -0.076  57.025  1.00 32.15           C
ATOM   1533  O   ILE A 200     -33.675   0.083  55.841  1.00 31.56           O
ATOM   1534  CB  ILE A 200     -32.842   2.032  57.850  1.00 30.51           C
ATOM   1535  CG1 ILE A 200     -31.835   2.567  58.872  1.00 34.99           C
ATOM   1536  CG2 ILE A 200     -34.118   2.857  57.879  1.00 32.45           C
ATOM   1537  CD1 ILE A 200     -30.780   3.474  58.276  1.00 32.07           C
ATOM   1538  N   THR A 201     -35.034  -0.798  57.407  1.00 32.97           N
ATOM   1539  CA  THR A 201     -35.790  -1.590  56.441  1.00 28.43           C
ATOM   1540  C   THR A 201     -36.511  -0.710  55.427  1.00 28.55           C
ATOM   1541  O   THR A 201     -36.513  -1.014  54.228  1.00 28.53           O
ATOM   1542  CB  THR A 201     -36.789  -2.490  57.167  1.00 33.11           C
ATOM   1543  OG1 THR A 201     -36.114  -3.221  58.198  1.00 38.48           O
ATOM   1544  CG2 THR A 201     -37.429  -3.469  56.193  1.00 33.05           C
ATOM   1545  N   VAL A 202     -37.131   0.382  55.885  1.00 24.18           N
ATOM   1546  CA  VAL A 202     -37.892   1.234  54.974  1.00 28.40           C
ATOM   1547  C   VAL A 202     -36.980   1.869  53.933  1.00 29.17           C
ATOM   1548  O   VAL A 202     -37.407   2.141  52.804  1.00 29.13           O
ATOM   1549  CB  VAL A 202     -38.685   2.294  55.768  1.00 28.41           C
ATOM   1550  CG1 VAL A 202     -37.748   3.308  56.412  1.00 35.77           C
ATOM   1551  CG2 VAL A 202     -39.700   2.988  54.868  1.00 38.10           C
ATOM   1552  N   ASN A 203     -35.710   2.096  54.280  1.00 25.67           N
ATOM   1553  CA  ASN A 203     -34.771   2.653  53.312  1.00 26.61           C
ATOM   1554  C   ASN A 203     -34.387   1.620  52.260  1.00 26.96           C
ATOM   1555  O   ASN A 203     -34.223   1.958  51.082  1.00 25.39           O
ATOM   1556  CB  ASN A 203     -33.528   3.176  54.031  1.00 26.02           C
ATOM   1557  CG  ASN A 203     -33.765   4.514  54.705  1.00 29.29           C
ATOM   1558  OD1 ASN A 203     -34.905   4.945  54.874  1.00 27.39           O
ATOM   1559  ND2 ASN A 203     -32.683   5.179  55.095  1.00 27.49           N
ATOM   1560  N   VAL A 204     -34.234   0.358  52.667  1.00 26.81           N
ATOM   1561  CA  VAL A 204     -33.919  -0.700  51.711  1.00 32.26           C
ATOM   1562  C   VAL A 204     -35.050  -0.858  50.704  1.00 28.76           C
ATOM   1563  O   VAL A 204     -34.814  -1.000  49.497  1.00 28.91           O
ATOM   1564  CB  VAL A 204     -33.630  -2.020  52.449  1.00 31.36           C
ATOM   1565  CG1 VAL A 204     -33.509  -3.170  51.460  1.00 31.24           C
ATOM   1566  CG2 VAL A 204     -32.367  -1.898  53.286  1.00 25.72           C
ATOM   1567  N   LEU A 205     -36.297  -0.825  51.181  1.00 23.97           N
ATOM   1568  CA  LEU A 205     -37.438  -0.964  50.284  1.00 29.72           C
ATOM   1569  C   LEU A 205     -37.516   0.199  49.305  1.00 31.96           C
ATOM   1570  O   LEU A 205     -37.776  -0.001  48.113  1.00 29.24           O
ATOM   1571  CB  LEU A 205     -38.729  -1.076  51.094  1.00 28.11           C
ATOM   1572  CG  LEU A 205     -38.919  -2.390  51.854  1.00 31.29           C
ATOM   1573  CD1 LEU A 205     -40.077  -2.286  52.833  1.00 32.67           C
ATOM   1574  CD2 LEU A 205     -39.133  -3.543  50.885  1.00 33.54           C
ATOM   1575  N   ALA A 206     -37.290   1.424  49.789  1.00 23.26           N
ATOM   1576  CA  ALA A 206     -37.275   2.578  48.896  1.00 27.13           C
ATOM   1577  C   ALA A 206     -36.149   2.464  47.877  1.00 28.64           C
ATOM   1578  O   ALA A 206     -36.312   2.848  46.713  1.00 33.46           O
ATOM   1579  CB  ALA A 206     -37.142   3.867  49.707  1.00 28.39           C
ATOM   1580  N   TRP A 207     -34.999   1.934  48.298  1.00 27.23           N
ATOM   1581  CA  TRP A 207     -33.892   1.717  47.375  1.00 29.46           C
ATOM   1582  C   TRP A 207     -34.220   0.636  46.352  1.00 30.50           C
ATOM   1583  O   TRP A 207     -33.796   0.732  45.194  1.00 27.71           O
ATOM   1584  CB  TRP A 207     -32.633   1.360  48.164  1.00 25.98           C
ATOM   1585  CG  TRP A 207     -31.469   0.929  47.333  1.00 33.09           C
ATOM   1586  CD1 TRP A 207     -30.689   1.718  46.539  1.00 34.56           C
ATOM   1587  CD2 TRP A 207     -30.935  -0.396  47.230  1.00 35.87           C
ATOM   1588  NE1 TRP A 207     -29.709   0.964  45.940  1.00 33.32           N
ATOM   1589  CE2 TRP A 207     -29.839  -0.337  46.348  1.00 37.47           C
ATOM   1590  CE3 TRP A 207     -31.282  -1.628  47.794  1.00 33.55           C
ATOM   1591  CZ2 TRP A 207     -29.087  -1.462  46.017  1.00 41.96           C
ATOM   1592  CZ3 TRP A 207     -30.535  -2.743  47.463  1.00 41.15           C
ATOM   1593  CH2 TRP A 207     -29.449  -2.652  46.585  1.00 41.36           C
ATOM   1594  N   LEU A 208     -34.974  -0.391  46.754  1.00 24.10           N
ATOM   1595  CA  LEU A 208     -35.429  -1.392  45.794  1.00 32.57           C
ATOM   1596  C   LEU A 208     -36.387  -0.781  44.781  1.00 30.65           C
ATOM   1597  O   LEU A 208     -36.365  -1.144  43.599  1.00 34.37           O
ATOM   1598  CB  LEU A 208     -36.095  -2.559  46.522  1.00 30.22           C
ATOM   1599  CG  LEU A 208     -35.190  -3.465  47.360  1.00 29.05           C
ATOM   1600  CD1 LEU A 208     -35.997  -4.582  48.006  1.00 32.79           C
ATOM   1601  CD2 LEU A 208     -34.061  -4.033  46.516  1.00 31.48           C
ATOM   1602  N   TYR A 209     -37.239   0.145  45.226  1.00 29.01           N
ATOM   1603  CA  TYR A 209     -38.120   0.845  44.298  1.00 31.92           C
ATOM   1604  C   TYR A 209     -37.319   1.679  43.309  1.00 35.92           C
ATOM   1605  O   TYR A 209     -37.668   1.753  42.125  1.00 32.34           O
ATOM   1606  CB  TYR A 209     -39.105   1.723  45.069  1.00 31.20           C
ATOM   1607  CG  TYR A 209     -40.334   0.986  45.545  1.00 37.03           C
ATOM   1608  CD1 TYR A 209     -41.285   0.526  44.643  1.00 40.06           C
ATOM   1609  CD2 TYR A 209     -40.544   0.748  46.896  1.00 28.79           C
ATOM   1610  CE1 TYR A 209     -42.410  -0.151  45.073  1.00 40.63           C
ATOM   1611  CE2 TYR A 209     -41.666   0.072  47.337  1.00 34.89           C
ATOM   1612  CZ  TYR A 209     -42.595  -0.374  46.421  1.00 39.51           C
ATOM   1613  OH  TYR A 209     -43.714  -1.047  46.852  1.00 40.05           O
ATOM   1614  N   ALA A 210     -36.238   2.311  43.774  1.00 32.32           N
ATOM   1615  CA  ALA A 210     -35.374   3.062  42.869  1.00 33.39           C
ATOM   1616  C   ALA A 210     -34.761   2.159  41.808  1.00 33.08           C
ATOM   1617  O   ALA A 210     -34.537   2.597  40.674  1.00 31.00           O
ATOM   1618  CB  ALA A 210     -34.276   3.774  43.659  1.00 38.63           C
ATOM   1619  N   ALA A 211     -34.482   0.901  42.156  1.00 29.95           N
ATOM   1620  CA  ALA A 211     -33.960  -0.041  41.173  1.00 38.71           C
ATOM   1621  C   ALA A 211     -34.998  -0.357  40.104  1.00 38.27           C
ATOM   1622  O   ALA A 211     -34.666  -0.448  38.917  1.00 39.96           O
ATOM   1623  CB  ALA A 211     -33.500  -1.321  41.868  1.00 37.27           C
ATOM   1624  N   VAL A 212     -36.259  -0.530  40.507  1.00 37.46           N
ATOM   1625  CA  VAL A 212     -37.313  -0.821  39.540  1.00 41.82           C
ATOM   1626  C   VAL A 212     -37.554   0.381  38.635  1.00 43.94           C
ATOM   1627  O   VAL A 212     -37.760   0.232  37.424  1.00 43.74           O
ATOM   1628  CB  VAL A 212     -38.600  -1.251  40.268  1.00 36.71           C
ATOM   1629  CG1 VAL A 212     -39.731  -1.458  39.272  1.00 41.97           C
ATOM   1630  CG2 VAL A 212     -38.353  -2.518  41.074  1.00 31.36           C
ATOM   1631  N   ILE A 213     -37.528   1.589  39.205  1.00 38.15           N
ATOM   1632  CA  ILE A 213     -37.674   2.796  38.398  1.00 39.62           C
ATOM   1633  C   ILE A 213     -36.529   2.914  37.400  1.00 44.72           C
ATOM   1634  O   ILE A 213     -36.690   3.498  36.321  1.00 51.05           O
ATOM   1635  CB  ILE A 213     -37.774   4.035  39.312  1.00 44.90           C
ATOM   1636  CG1 ILE A 213     -38.956   3.896  40.273  1.00 44.60           C
ATOM   1637  CG2 ILE A 213     -37.941   5.297  38.496  1.00 50.73           C
ATOM   1638  CD1 ILE A 213     -39.157   5.095  41.178  1.00 40.25           C
ATOM   1639  N   ASN A 214     -35.368   2.346  37.726  1.00 41.68           N
ATOM   1640  CA  ASN A 214     -34.215   2.350  36.837  1.00 47.03           C
ATOM   1641  C   ASN A 214     -34.057   1.051  36.055  1.00 49.42           C
ATOM   1642  O   ASN A 214     -33.087   0.912  35.304  1.00 48.17           O
ATOM   1643  CB  ASN A 214     -32.938   2.638  37.632  1.00 43.76           C
ATOM   1644  CG  ASN A 214     -32.853   4.080  38.091  1.00 45.61           C
ATOM   1645  OD1 ASN A 214     -32.174   4.900  37.474  1.00 45.51           O
ATOM   1646  ND2 ASN A 214     -33.549   4.398  39.177  1.00 48.13           N
ATOM   1647  N   GLY A 215     -34.972   0.100  36.216  1.00 55.59           N
ATOM   1648  CA  GLY A 215     -34.999  -1.067  35.358  1.00 50.39           C
ATOM   1649  C   GLY A 215     -34.272  -2.290  35.860  1.00 50.72           C
ATOM   1650  O   GLY A 215     -34.046  -3.218  35.076  1.00 55.25           O
ATOM   1651  N   ASP A 216     -33.883  -2.324  37.129  1.00 46.39           N
ATOM   1652  CA  ASP A 216     -33.368  -3.546  37.740  1.00 49.22           C
ATOM   1653  C   ASP A 216     -34.560  -4.257  38.365  1.00 52.58           C
ATOM   1654  O   ASP A 216     -35.069  -3.844  39.409  1.00 51.20           O
ATOM   1655  CB  ASP A 216     -32.280  -3.237  38.763  1.00 53.88           C
ATOM   1656  CG  ASP A 216     -30.882  -3.415  38.197  1.00 60.12           C
ATOM   1657  OD1 ASP A 216     -30.761  -3.766  37.004  1.00 63.39           O
ATOM   1658  OD2 ASP A 216     -29.903  -3.208  38.946  1.00 58.15           O
ATOM   1659  N   ARG A 217     -35.026  -5.318  37.708  1.00 48.18           N
ATOM   1660  CA  ARG A 217     -36.243  -6.010  38.114  1.00 43.89           C
ATOM   1661  C   ARG A 217     -36.021  -7.493  38.384  1.00 40.80           C
ATOM   1662  O   ARG A 217     -36.995  -8.217  38.627  1.00 41.42           O
ATOM   1663  CB  ARG A 217     -37.330  -5.845  37.047  1.00 40.56           C
ATOM   1664  CG  ARG A 217     -37.746  -4.408  36.784  1.00 43.72           C
ATOM   1665  CD  ARG A 217     -38.826  -4.346  35.714  1.00 42.23           C
ATOM   1666  NE  ARG A 217     -40.026  -5.077  36.111  1.00 41.31           N
ATOM   1667  CZ  ARG A 217     -41.128  -4.504  36.583  1.00 43.64           C
ATOM   1668  NH1 ARG A 217     -41.187  -3.186  36.717  1.00 45.45           N
ATOM   1669  NH2 ARG A 217     -42.172  -5.248  36.921  1.00 41.32           N
ATOM   1670  N   TRP A 218     -34.774  -7.966  38.352  1.00 36.38           N
ATOM   1671  CA  TRP A 218     -34.523  -9.396  38.493  1.00 41.06           C
ATOM   1672  C   TRP A 218     -34.910  -9.926  39.868  1.00 44.70           C
ATOM   1673  O   TRP A 218     -35.140 -11.132  40.009  1.00 40.75           O
ATOM   1674  CB  TRP A 218     -33.050  -9.704  38.209  1.00 43.64           C
ATOM   1675  CG  TRP A 218     -32.097  -9.108  39.201  1.00 47.86           C
ATOM   1676  CD1 TRP A 218     -31.435  -7.920  39.089  1.00 43.05           C
ATOM   1677  CD2 TRP A 218     -31.690  -9.678  40.451  1.00 43.40           C
ATOM   1678  NE1 TRP A 218     -30.645  -7.713  40.194  1.00 48.32           N
ATOM   1679  CE2 TRP A 218     -30.784  -8.778  41.045  1.00 44.70           C
ATOM   1680  CE3 TRP A 218     -32.005 -10.862  41.127  1.00 46.52           C
ATOM   1681  CZ2 TRP A 218     -30.191  -9.023  42.281  1.00 49.87           C
ATOM   1682  CZ3 TRP A 218     -31.417 -11.102  42.354  1.00 49.27           C
ATOM   1683  CH2 TRP A 218     -30.519 -10.187  42.918  1.00 54.03           C
ATOM   1684  N   PHE A 219     -34.991  -9.062  40.879  1.00 39.97           N
ATOM   1685  CA  PHE A 219     -35.325  -9.491  42.230  1.00 38.29           C
ATOM   1686  C   PHE A 219     -36.825  -9.558  42.488  1.00 34.23           C
ATOM   1687  O   PHE A 219     -37.232 -10.074  43.535  1.00 41.36           O
ATOM   1688  CB  PHE A 219     -34.677  -8.557  43.261  1.00 35.64           C
ATOM   1689  CG  PHE A 219     -34.950  -7.100  43.018  1.00 34.08           C
ATOM   1690  CD1 PHE A 219     -36.108  -6.507  43.495  1.00 33.57           C
ATOM   1691  CD2 PHE A 219     -34.047  -6.322  42.315  1.00 35.98           C
ATOM   1692  CE1 PHE A 219     -36.361  -5.167  43.273  1.00 36.10           C
ATOM   1693  CE2 PHE A 219     -34.292  -4.982  42.092  1.00 41.53           C
ATOM   1694  CZ  PHE A 219     -35.452  -4.404  42.569  1.00 38.37           C
ATOM   1695  N   LEU A 220     -37.649  -9.047  41.578  1.00 34.04           N
ATOM   1696  CA  LEU A 220     -39.092  -9.102  41.762  1.00 34.67           C
ATOM   1697  C   LEU A 220     -39.590 -10.539  41.667  1.00 42.10           C
ATOM   1698  O   LEU A 220     -38.970 -11.395  41.030  1.00 37.53           O
ATOM   1699  CB  LEU A 220     -39.798  -8.235  40.721  1.00 37.23           C
ATOM   1700  CG  LEU A 220     -39.579  -6.726  40.837  1.00 34.79           C
ATOM   1701  CD1 LEU A 220     -40.188  -6.006  39.647  1.00 39.78           C
ATOM   1702  CD2 LEU A 220     -40.157  -6.201  42.141  1.00 38.56           C
ATOM   1703  N   ASN A 221     -40.724 -10.802  42.312  1.00 41.05           N
ATOM   1704  CA  ASN A 221     -41.298 -12.138  42.314  1.00 49.57           C
ATOM   1705  C   ASN A 221     -42.815 -12.038  42.374  1.00 50.99           C
ATOM   1706  O   ASN A 221     -43.382 -10.984  42.674  1.00 47.82           O
ATOM   1707  CB  ASN A 221     -40.759 -12.979  43.479  1.00 46.91           C
ATOM   1708  CG  ASN A 221     -40.893 -12.282  44.824  1.00 52.27           C
ATOM   1709  OD1 ASN A 221     -41.860 -11.564  45.079  1.00 56.83           O
ATOM   1710  ND2 ASN A 221     -39.917 -12.500  45.697  1.00 52.91           N
ATOM   1711  N   ARG A 222     -43.469 -13.164  42.084  1.00 55.29           N
ATOM   1712  CA  ARG A 222     -44.923 -13.232  42.155  1.00 55.44           C
ATOM   1713  C   ARG A 222     -45.430 -13.272  43.590  1.00 58.31           C
ATOM   1714  O   ARG A 222     -46.604 -12.969  43.826  1.00 60.54           O
ATOM   1715  CB  ARG A 222     -45.425 -14.465  41.404  1.00 59.44           C
ATOM   1716  CG  ARG A 222     -44.941 -15.773  42.010  1.00 64.22           C
ATOM   1717  CD  ARG A 222     -45.221 -16.957  41.105  1.00 63.67           C
ATOM   1718  NE  ARG A 222     -44.228 -18.013  41.282  1.00 64.36           N
ATOM   1719  CZ  ARG A 222     -44.288 -18.945  42.228  1.00 68.65           C
ATOM   1720  NH1 ARG A 222     -45.296 -18.954  43.090  1.00 70.23           N
ATOM   1721  NH2 ARG A 222     -43.338 -19.866  42.315  1.00 66.89           N
ATOM   1722  N   PHE A 223     -44.577 -13.636  44.545  1.00 55.79           N
ATOM   1723  CA  PHE A 223     -45.010 -13.794  45.923  1.00 57.06           C
ATOM   1724  C   PHE A 223     -45.434 -12.459  46.527  1.00 54.34           C
ATOM   1725  O   PHE A 223     -45.062 -11.379  46.060  1.00 54.33           O
ATOM   1726  CB  PHE A 223     -43.897 -14.390  46.789  1.00 54.56           C
ATOM   1727  CG  PHE A 223     -43.201 -15.568  46.175  1.00 61.54           C
ATOM   1728  CD1 PHE A 223     -43.776 -16.828  46.208  1.00 67.27           C
ATOM   1729  CD2 PHE A 223     -41.957 -15.421  45.587  1.00 62.61           C
ATOM   1730  CE1 PHE A 223     -43.128 -17.914  45.650  1.00 63.74           C
ATOM   1731  CE2 PHE A 223     -41.305 -16.501  45.027  1.00 67.45           C
ATOM   1732  CZ  PHE A 223     -41.891 -17.750  45.059  1.00 63.58           C
ATOM   1733  N   THR A 224     -46.234 -12.555  47.585  1.00 53.15           N
ATOM   1734  CA  THR A 224     -46.495 -11.455  48.498  1.00 54.87           C
ATOM   1735  C   THR A 224     -46.470 -12.025  49.909  1.00 57.84           C
ATOM   1736  O   THR A 224     -46.354 -13.238  50.107  1.00 57.11           O
ATOM   1737  CB  THR A 224     -47.827 -10.759  48.195  1.00 58.32           C
ATOM   1738  OG1 THR A 224     -47.906  -9.536  48.940  1.00 55.79           O
ATOM   1739  CG2 THR A 224     -48.995 -11.653  48.574  1.00 59.49           C
ATOM   1740  N   THR A 225     -46.568 -11.146  50.901  1.00 54.55           N
ATOM   1741  CA  THR A 225     -46.491 -11.579  52.287  1.00 52.09           C
ATOM   1742  C   THR A 225     -47.276 -10.614  53.160  1.00 52.22           C
ATOM   1743  O   THR A 225     -47.565  -9.480  52.767  1.00 51.74           O
ATOM   1744  CB  THR A 225     -45.037 -11.668  52.770  1.00 52.66           C
ATOM   1745  OG1 THR A 225     -45.002 -12.191  54.104  1.00 57.56           O
ATOM   1746  CG2 THR A 225     -44.384 -10.294  52.751  1.00 48.22           C
ATOM   1747  N   THR A 226     -47.630 -11.086  54.350  1.00 48.89           N
ATOM   1748  CA  THR A 226     -48.220 -10.218  55.351  1.00 49.18           C
ATOM   1749  C   THR A 226     -47.131  -9.399  56.035  1.00 47.11           C
ATOM   1750  O   THR A 226     -45.949  -9.757  56.027  1.00 49.91           O
ATOM   1751  CB  THR A 226     -48.995 -11.034  56.387  1.00 52.38           C
ATOM   1752  OG1 THR A 226     -48.089 -11.880  57.105  1.00 53.45           O
ATOM   1753  CG2 THR A 226     -50.048 -11.896  55.705  1.00 52.43           C
ATOM   1754  N   LEU A 227     -47.543  -8.276  56.626  1.00 44.28           N
ATOM   1755  CA  LEU A 227     -46.585  -7.415  57.310  1.00 46.11           C
ATOM   1756  C   LEU A 227     -45.959  -8.114  58.510  1.00 48.45           C
ATOM   1757  O   LEU A 227     -44.781  -7.892  58.814  1.00 47.38           O
ATOM   1758  CB  LEU A 227     -47.265  -6.116  57.743  1.00 48.35           C
ATOM   1759  CG  LEU A 227     -46.384  -5.090  58.458  1.00 53.13           C
ATOM   1760  CD1 LEU A 227     -45.169  -4.753  57.611  1.00 51.92           C
ATOM   1761  CD2 LEU A 227     -47.177  -3.836  58.787  1.00 50.91           C
ATOM   1762  N   ASN A 228     -46.723  -8.969  59.194  1.00 48.68           N
ATOM   1763  CA  ASN A 228     -46.198  -9.644  60.376  1.00 50.79           C
ATOM   1764  C   ASN A 228     -45.209 -10.740  60.000  1.00 49.09           C
ATOM   1765  O   ASN A 228     -44.176 -10.902  60.659  1.00 51.86           O
ATOM   1766  CB  ASN A 228     -47.347 -10.217  61.205  1.00 52.01           C
ATOM   1767  CG  ASN A 228     -48.001  -9.176  62.089  1.00 58.59           C
ATOM   1768  OD1 ASN A 228     -49.181  -8.861  61.930  1.00 63.31           O
ATOM   1769  ND2 ASN A 228     -47.236  -8.631  63.028  1.00 64.61           N
ATOM   1770  N   ASP A 229     -45.508 -11.504  58.946  1.00 51.91           N
ATOM   1771  CA  ASP A 229     -44.587 -12.553  58.521  1.00 54.27           C
ATOM   1772  C   ASP A 229     -43.296 -11.972  57.962  1.00 50.38           C
ATOM   1773  O   ASP A 229     -42.241 -12.611  58.052  1.00 48.25           O
ATOM   1774  CB  ASP A 229     -45.259 -13.460  57.491  1.00 52.46           C
ATOM   1775  CG  ASP A 229     -46.033 -14.594  58.134  1.00 63.17           C
ATOM   1776  OD1 ASP A 229     -47.225 -14.397  58.453  1.00 67.92           O
ATOM   1777  OD2 ASP A 229     -45.449 -15.682  58.325  1.00 71.71           O
ATOM   1778  N   PHE A 230     -43.355 -10.770  57.384  1.00 45.69           N
ATOM   1779  CA  PHE A 230     -42.130 -10.100  56.962  1.00 47.77           C
ATOM   1780  C   PHE A 230     -41.323  -9.630  58.165  1.00 44.56           C
ATOM   1781  O   PHE A 230     -40.097  -9.789  58.198  1.00 47.05           O
ATOM   1782  CB  PHE A 230     -42.459  -8.923  56.045  1.00 44.71           C
ATOM   1783  CG  PHE A 230     -41.269  -8.073  55.703  1.00 46.06           C
ATOM   1784  CD1 PHE A 230     -40.377  -8.472  54.721  1.00 39.89           C
ATOM   1785  CD2 PHE A 230     -41.040  -6.877  56.364  1.00 46.70           C
ATOM   1786  CE1 PHE A 230     -39.280  -7.695  54.405  1.00 44.09           C
ATOM   1787  CE2 PHE A 230     -39.944  -6.095  56.053  1.00 43.17           C
ATOM   1788  CZ  PHE A 230     -39.063  -6.504  55.072  1.00 46.40           C
ATOM   1789  N   ASN A 231     -41.994  -9.049  59.163  1.00 47.09           N
ATOM   1790  CA  ASN A 231     -41.304  -8.588  60.362  1.00 48.54           C
ATOM   1791  C   ASN A 231     -40.692  -9.738  61.151  1.00 46.93           C
ATOM   1792  O   ASN A 231     -39.744  -9.517  61.913  1.00 51.54           O
ATOM   1793  CB  ASN A 231     -42.265  -7.794  61.248  1.00 44.45           C
ATOM   1794  CG  ASN A 231     -42.510  -6.390  60.729  1.00 46.53           C
ATOM   1795  OD1 ASN A 231     -41.604  -5.743  60.206  1.00 49.46           O
ATOM   1796  ND2 ASN A 231     -43.741  -5.912  60.872  1.00 48.18           N
ATOM   1797  N   LEU A 232     -41.212 -10.957  60.990  1.00 49.26           N
ATOM   1798  CA  LEU A 232     -40.596 -12.111  61.635  1.00 48.59           C
ATOM   1799  C   LEU A 232     -39.250 -12.439  61.000  1.00 50.95           C
ATOM   1800  O   LEU A 232     -38.296 -12.796  61.702  1.00 54.89           O
ATOM   1801  CB  LEU A 232     -41.534 -13.316  61.561  1.00 51.33           C
ATOM   1802  CG  LEU A 232     -42.569 -13.440  62.681  1.00 54.42           C
ATOM   1803  CD1 LEU A 232     -43.539 -14.575  62.391  1.00 56.04           C
ATOM   1804  CD2 LEU A 232     -41.886 -13.644  64.024  1.00 48.57           C
ATOM   1805  N   VAL A 233     -39.155 -12.323  59.675  1.00 46.36           N
ATOM   1806  CA  VAL A 233     -37.884 -12.556  59.000  1.00 48.55           C
ATOM   1807  C   VAL A 233     -36.952 -11.363  59.172  1.00 48.47           C
ATOM   1808  O   VAL A 233     -35.728 -11.531  59.241  1.00 48.19           O
ATOM   1809  CB  VAL A 233     -38.124 -12.874  57.514  1.00 51.22           C
ATOM   1810  CG1 VAL A 233     -36.822 -13.265  56.829  1.00 47.79           C
ATOM   1811  CG2 VAL A 233     -39.160 -13.978  57.369  1.00 51.84           C
ATOM   1812  N   ALA A 234     -37.504 -10.150  59.254  1.00 49.48           N
ATOM   1813  CA  ALA A 234     -36.671  -8.965  59.431  1.00 48.29           C
ATOM   1814  C   ALA A 234     -35.930  -9.001  60.762  1.00 47.50           C
ATOM   1815  O   ALA A 234     -34.783  -8.550  60.855  1.00 46.36           O
ATOM   1816  CB  ALA A 234     -37.527  -7.703  59.325  1.00 46.76           C
ATOM   1817  N   MET A 235     -36.570  -9.538  61.803  1.00 52.01           N
ATOM   1818  CA  MET A 235     -35.920  -9.622  63.107  1.00 49.80           C
ATOM   1819  C   MET A 235     -34.730 -10.573  63.074  1.00 52.99           C
ATOM   1820  O   MET A 235     -33.694 -10.302  63.693  1.00 54.42           O
ATOM   1821  CB  MET A 235     -36.929 -10.066  64.166  1.00 54.15           C
ATOM   1822  CG  MET A 235     -37.574  -8.925  64.933  1.00 63.16           C
ATOM   1823  SD  MET A 235     -38.598  -9.508  66.297  1.00 89.99           S
ATOM   1824  CE  MET A 235     -40.140  -8.666  65.948  1.00 71.61           C
ATOM   1825  N   LYS A 236     -34.859 -11.692  62.356  1.00 53.54           N
ATOM   1826  CA  LYS A 236     -33.779 -12.672  62.308  1.00 53.13           C
ATOM   1827  C   LYS A 236     -32.551 -12.125  61.592  1.00 54.14           C
ATOM   1828  O   LYS A 236     -31.424 -12.529  61.901  1.00 56.65           O
ATOM   1829  CB  LYS A 236     -34.269 -13.953  61.630  1.00 52.75           C
ATOM   1830  CG  LYS A 236     -33.263 -15.094  61.637  1.00 64.88           C
ATOM   1831  CD  LYS A 236     -33.221 -15.801  60.290  1.00 62.89           C
ATOM   1832  CE  LYS A 236     -32.220 -16.946  60.292  1.00 68.06           C
ATOM   1833  NZ  LYS A 236     -30.849 -16.487  59.928  1.00 65.62           N
ATOM   1834  N   TYR A 237     -32.741 -11.203  60.647  1.00 51.24           N
ATOM   1835  CA  TYR A 237     -31.645 -10.635  59.874  1.00 48.39           C
ATOM   1836  C   TYR A 237     -31.255  -9.239  60.351  1.00 44.17           C
ATOM   1837  O   TYR A 237     -30.624  -8.489  59.598  1.00 45.24           O
ATOM   1838  CB  TYR A 237     -32.009 -10.612  58.390  1.00 46.67           C
ATOM   1839  CG  TYR A 237     -32.051 -11.987  57.763  1.00 47.83           C
ATOM   1840  CD1 TYR A 237     -33.224 -12.730  57.749  1.00 49.26           C
ATOM   1841  CD2 TYR A 237     -30.915 -12.545  57.192  1.00 45.38           C
ATOM   1842  CE1 TYR A 237     -33.266 -13.989  57.180  1.00 52.16           C
ATOM   1843  CE2 TYR A 237     -30.947 -13.803  56.621  1.00 54.25           C
ATOM   1844  CZ  TYR A 237     -32.125 -14.520  56.618  1.00 53.00           C
ATOM   1845  OH  TYR A 237     -32.160 -15.773  56.050  1.00 56.94           O
ATOM   1846  N   ASN A 238     -31.619  -8.883  61.584  1.00 41.18           N
ATOM   1847  CA  ASN A 238     -31.241  -7.606  62.194  1.00 43.30           C
ATOM   1848  C   ASN A 238     -31.773  -6.418  61.394  1.00 42.30           C
ATOM   1849  O   ASN A 238     -31.087  -5.410  61.210  1.00 39.45           O
ATOM   1850  CB  ASN A 238     -29.725  -7.504  62.376  1.00 43.06           C
ATOM   1851  CG  ASN A 238     -29.339  -6.684  63.590  1.00 55.19           C
ATOM   1852  OD1 ASN A 238     -30.093  -6.598  64.559  1.00 61.37           O
ATOM   1853  ND2 ASN A 238     -28.160  -6.076  63.544  1.00 54.07           N
ATOM   1854  N   TYR A 239     -33.005  -6.538  60.914  1.00 38.35           N
ATOM   1855  CA  TYR A 239     -33.696  -5.443  60.254  1.00 38.80           C
ATOM   1856  C   TYR A 239     -34.716  -4.833  61.206  1.00 37.82           C
ATOM   1857  O   TYR A 239     -35.311  -5.527  62.035  1.00 36.96           O
ATOM   1858  CB  TYR A 239     -34.400  -5.920  58.980  1.00 31.71           C
ATOM   1859  CG  TYR A 239     -33.530  -5.922  57.744  1.00 32.97           C
ATOM   1860  CD1 TYR A 239     -32.316  -6.593  57.728  1.00 35.06           C
ATOM   1861  CD2 TYR A 239     -33.928  -5.258  56.590  1.00 30.21           C
ATOM   1862  CE1 TYR A 239     -31.519  -6.601  56.600  1.00 32.54           C
ATOM   1863  CE2 TYR A 239     -33.139  -5.261  55.456  1.00 29.91           C
ATOM   1864  CZ  TYR A 239     -31.935  -5.934  55.466  1.00 37.95           C
ATOM   1865  OH  TYR A 239     -31.143  -5.940  54.341  1.00 37.23           O
ATOM   1866  N   GLU A 240     -34.907  -3.523  61.084  1.00 38.44           N
ATOM   1867  CA  GLU A 240     -35.923  -2.854  61.876  1.00 42.23           C
ATOM   1868  C   GLU A 240     -37.312  -3.344  61.467  1.00 40.61           C
ATOM   1869  O   GLU A 240     -37.544  -3.674  60.302  1.00 44.38           O
ATOM   1870  CB  GLU A 240     -35.836  -1.342  61.700  1.00 43.45           C
ATOM   1871  CG  GLU A 240     -34.515  -0.739  62.137  1.00 46.13           C
ATOM   1872  CD  GLU A 240     -34.532   0.776  62.102  1.00 53.52           C
ATOM   1873  OE1 GLU A 240     -33.846   1.398  62.939  1.00 59.47           O
ATOM   1874  OE2 GLU A 240     -35.234   1.346  61.239  1.00 57.69           O
ATOM   1875  N   PRO A 241     -38.249  -3.410  62.410  1.00 47.91           N
ATOM   1876  CA  PRO A 241     -39.607  -3.838  62.060  1.00 45.17           C
ATOM   1877  C   PRO A 241     -40.286  -2.823  61.155  1.00 44.29           C
ATOM   1878  O   PRO A 241     -40.181  -1.611  61.358  1.00 49.06           O
ATOM   1879  CB  PRO A 241     -40.311  -3.939  63.418  1.00 48.34           C
ATOM   1880  CG  PRO A 241     -39.537  -3.023  64.311  1.00 49.80           C
ATOM   1881  CD  PRO A 241     -38.112  -3.119  63.848  1.00 43.55           C
ATOM   1882  N   LEU A 242     -40.980  -3.330  60.141  1.00 49.39           N
ATOM   1883  CA  LEU A 242     -41.715  -2.484  59.213  1.00 48.40           C
ATOM   1884  C   LEU A 242     -43.097  -2.190  59.782  1.00 47.01           C
ATOM   1885  O   LEU A 242     -43.827  -3.110  60.164  1.00 49.95           O
ATOM   1886  CB  LEU A 242     -41.834  -3.149  57.842  1.00 47.22           C
ATOM   1887  CG  LEU A 242     -42.174  -2.196  56.695  1.00 47.45           C
ATOM   1888  CD1 LEU A 242     -40.953  -1.385  56.295  1.00 40.14           C
ATOM   1889  CD2 LEU A 242     -42.730  -2.957  55.505  1.00 45.10           C
ATOM   1890  N   THR A 243     -43.447  -0.912  59.839  1.00 48.07           N
ATOM   1891  CA  THR A 243     -44.722  -0.472  60.381  1.00 49.82           C
ATOM   1892  C   THR A 243     -45.686  -0.117  59.255  1.00 49.20           C
ATOM   1893  O   THR A 243     -45.311  -0.016  58.084  1.00 48.55           O
ATOM   1894  CB  THR A 243     -44.526   0.731  61.309  1.00 48.03           C
ATOM   1895  OG1 THR A 243     -43.999   1.833  60.558  1.00 53.04           O
ATOM   1896  CG2 THR A 243     -43.562   0.385  62.433  1.00 47.32           C
ATOM   1897  N   GLN A 244     -46.955   0.068  59.629  1.00 51.96           N
ATOM   1898  CA  GLN A 244     -47.948   0.513  58.657  1.00 52.08           C
ATOM   1899  C   GLN A 244     -47.639   1.916  58.154  1.00 51.01           C
ATOM   1900  O   GLN A 244     -47.957   2.249  57.006  1.00 47.96           O
ATOM   1901  CB  GLN A 244     -49.347   0.460  59.273  1.00 51.18           C
ATOM   1902  CG  GLN A 244     -50.475   0.670  58.275  1.00 54.26           C
ATOM   1903  CD  GLN A 244     -50.549  -0.432  57.237  1.00 54.72           C
ATOM   1904  OE1 GLN A 244     -50.313  -1.602  57.538  1.00 57.20           O
ATOM   1905  NE2 GLN A 244     -50.878  -0.062  56.005  1.00 57.47           N
ATOM   1906  N   ASP A 245     -47.015   2.748  58.993  1.00 45.99           N
ATOM   1907  CA  ASP A 245     -46.618   4.082  58.558  1.00 47.89           C
ATOM   1908  C   ASP A 245     -45.512   4.024  57.512  1.00 47.57           C
ATOM   1909  O   ASP A 245     -45.474   4.865  56.606  1.00 46.82           O
ATOM   1910  CB  ASP A 245     -46.171   4.915  59.759  1.00 51.97           C
ATOM   1911  CG  ASP A 245     -45.823   6.340  59.382  1.00 57.42           C
ATOM   1912  OD1 ASP A 245     -46.503   6.902  58.497  1.00 55.98           O
ATOM   1913  OD2 ASP A 245     -44.871   6.900  59.966  1.00 65.93           O
ATOM   1914  N   HIS A 246     -44.607   3.048  57.621  1.00 45.42           N
ATOM   1915  CA  HIS A 246     -43.579   2.876  56.600  1.00 43.93           C
ATOM   1916  C   HIS A 246     -44.199   2.517  55.255  1.00 48.62           C
ATOM   1917  O   HIS A 246     -43.751   2.998  54.207  1.00 45.31           O
ATOM   1918  CB  HIS A 246     -42.583   1.798  57.030  1.00 44.30           C
ATOM   1919  CG  HIS A 246     -41.765   2.170  58.227  1.00 47.71           C
ATOM   1920  ND1 HIS A 246     -41.313   1.238  59.137  1.00 46.96           N
ATOM   1921  CD2 HIS A 246     -41.312   3.370  58.662  1.00 45.28           C
ATOM   1922  CE1 HIS A 246     -40.619   1.847  60.081  1.00 47.59           C
ATOM   1923  NE2 HIS A 246     -40.604   3.142  59.817  1.00 49.38           N
ATOM   1924  N   VAL A 247     -45.230   1.670  55.267  1.00 47.35           N
ATOM   1925  CA  VAL A 247     -45.919   1.311  54.031  1.00 48.04           C
ATOM   1926  C   VAL A 247     -46.585   2.538  53.421  1.00 47.65           C
ATOM   1927  O   VAL A 247     -46.571   2.728  52.198  1.00 46.30           O
ATOM   1928  CB  VAL A 247     -46.935   0.185  54.298  1.00 46.32           C
ATOM   1929  CG1 VAL A 247     -47.749  -0.111  53.048  1.00 51.18           C
ATOM   1930  CG2 VAL A 247     -46.221  -1.067  54.786  1.00 48.81           C
ATOM   1931  N   ASP A 248     -47.170   3.393  54.262  1.00 47.34           N
ATOM   1932  CA  ASP A 248     -47.792   4.615  53.763  1.00 51.44           C
ATOM   1933  C   ASP A 248     -46.756   5.559  53.164  1.00 50.16           C
ATOM   1934  O   ASP A 248     -47.027   6.229  52.161  1.00 46.82           O
ATOM   1935  CB  ASP A 248     -48.562   5.308  54.888  1.00 55.15           C
ATOM   1936  CG  ASP A 248     -49.762   4.508  55.353  1.00 58.06           C
ATOM   1937  OD1 ASP A 248     -50.023   3.433  54.774  1.00 59.26           O
ATOM   1938  OD2 ASP A 248     -50.445   4.954  56.299  1.00 63.30           O
ATOM   1939  N   ILE A 249     -45.566   5.625  53.765  1.00 45.19           N
ATOM   1940  CA  ILE A 249     -44.513   6.485  53.235  1.00 44.04           C
ATOM   1941  C   ILE A 249     -44.048   5.981  51.873  1.00 39.55           C
ATOM   1942  O   ILE A 249     -43.749   6.772  50.971  1.00 41.30           O
ATOM   1943  CB  ILE A 249     -43.350   6.582  54.240  1.00 46.18           C
ATOM   1944  CG1 ILE A 249     -43.793   7.338  55.494  1.00 49.70           C
ATOM   1945  CG2 ILE A 249     -42.148   7.270  53.614  1.00 42.94           C
ATOM   1946  CD1 ILE A 249     -42.780   7.304  56.619  1.00 50.92           C
ATOM   1947  N   LEU A 250     -43.996   4.661  51.697  1.00 42.25           N
ATOM   1948  CA  LEU A 250     -43.636   4.068  50.415  1.00 39.38           C
ATOM   1949  C   LEU A 250     -44.776   4.105  49.404  1.00 43.67           C
ATOM   1950  O   LEU A 250     -44.617   3.573  48.299  1.00 39.75           O
ATOM   1951  CB  LEU A 250     -43.175   2.623  50.618  1.00 38.35           C
ATOM   1952  CG  LEU A 250     -41.896   2.426  51.436  1.00 37.43           C
ATOM   1953  CD1 LEU A 250     -41.737   0.968  51.836  1.00 34.63           C
ATOM   1954  CD2 LEU A 250     -40.681   2.908  50.659  1.00 33.70           C
ATOM   1955  N   GLY A 251     -45.907   4.714  49.754  1.00 45.30           N
ATOM   1956  CA  GLY A 251     -47.060   4.795  48.888  1.00 43.60           C
ATOM   1957  C   GLY A 251     -46.779   5.379  47.516  1.00 44.79           C
ATOM   1958  O   GLY A 251     -47.056   4.750  46.489  1.00 45.76           O
ATOM   1959  N   PRO A 252     -46.246   6.607  47.468  1.00 41.67           N
ATOM   1960  CA  PRO A 252     -45.926   7.211  46.161  1.00 42.38           C
ATOM   1961  C   PRO A 252     -45.035   6.345  45.286  1.00 45.83           C
ATOM   1962  O   PRO A 252     -45.278   6.240  44.078  1.00 45.57           O
ATOM   1963  CB  PRO A 252     -45.236   8.524  46.554  1.00 39.06           C
ATOM   1964  CG  PRO A 252     -45.842   8.874  47.864  1.00 43.74           C
ATOM   1965  CD  PRO A 252     -46.072   7.568  48.573  1.00 43.29           C
ATOM   1966  N   LEU A 253     -44.007   5.717  45.862  1.00 43.10           N
ATOM   1967  CA  LEU A 253     -43.181   4.803  45.081  1.00 42.15           C
ATOM   1968  C   LEU A 253     -43.956   3.555  44.678  1.00 44.40           C
ATOM   1969  O   LEU A 253     -43.698   2.984  43.612  1.00 40.00           O
ATOM   1970  CB  LEU A 253     -41.928   4.420  45.869  1.00 40.83           C
ATOM   1971  CG  LEU A 253     -40.964   5.563  46.185  1.00 39.67           C
ATOM   1972  CD1 LEU A 253     -39.773   5.057  46.981  1.00 35.17           C
ATOM   1973  CD2 LEU A 253     -40.508   6.244  44.904  1.00 41.25           C
ATOM   1974  N   SER A 254     -44.904   3.120  45.510  1.00 44.93           N
ATOM   1975  CA  SER A 254     -45.713   1.954  45.171  1.00 42.80           C
ATOM   1976  C   SER A 254     -46.633   2.244  43.992  1.00 44.95           C
ATOM   1977  O   SER A 254     -46.858   1.374  43.142  1.00 46.98           O
ATOM   1978  CB  SER A 254     -46.526   1.510  46.386  1.00 42.20           C
ATOM   1979  OG  SER A 254     -47.106   0.235  46.173  1.00 46.27           O
ATOM   1980  N   ALA A 255     -47.174   3.463  43.925  1.00 43.76           N
ATOM   1981  CA  ALA A 255     -48.091   3.809  42.845  1.00 51.27           C
ATOM   1982  C   ALA A 255     -47.350   4.013  41.529  1.00 49.66           C
ATOM   1983  O   ALA A 255     -47.828   3.589  40.471  1.00 55.93           O
ATOM   1984  CB  ALA A 255     -48.883   5.062  43.216  1.00 41.24           C
ATOM   1985  N   GLN A 256     -46.183   4.660  41.576  1.00 50.98           N
ATOM   1986  CA  GLN A 256     -45.441   4.942  40.350  1.00 47.61           C
ATOM   1987  C   GLN A 256     -44.943   3.662  39.691  1.00 48.35           C
ATOM   1988  O   GLN A 256     -44.931   3.555  38.459  1.00 54.33           O
ATOM   1989  CB  GLN A 256     -44.273   5.882  40.652  1.00 48.26           C
ATOM   1990  CG  GLN A 256     -43.358   6.146  39.465  1.00 51.42           C
ATOM   1991  CD  GLN A 256     -42.163   7.005  39.830  1.00 59.01           C
ATOM   1992  OE1 GLN A 256     -42.108   7.582  40.916  1.00 63.84           O
ATOM   1993  NE2 GLN A 256     -41.197   7.092  38.923  1.00 57.07           N
ATOM   1994  N   THR A 257     -44.534   2.678  40.492  1.00 42.42           N
ATOM   1995  CA  THR A 257     -43.998   1.433  39.957  1.00 44.98           C
ATOM   1996  C   THR A 257     -45.061   0.368  39.730  1.00 40.52           C
ATOM   1997  O   THR A 257     -44.793  -0.612  39.026  1.00 40.68           O
ATOM   1998  CB  THR A 257     -42.927   0.869  40.895  1.00 43.73           C
ATOM   1999  OG1 THR A 257     -43.527   0.514  42.147  1.00 41.88           O
ATOM   2000  CG2 THR A 257     -41.837   1.898  41.137  1.00 44.22           C
ATOM   2001  N   GLY A 258     -46.251   0.530  40.303  1.00 41.89           N
ATOM   2002  CA  GLY A 258     -47.260  -0.503  40.199  1.00 42.08           C
ATOM   2003  C   GLY A 258     -46.953  -1.753  40.989  1.00 48.21           C
ATOM   2004  O   GLY A 258     -47.523  -2.810  40.707  1.00 49.12           O
ATOM   2005  N   ILE A 259     -46.062  -1.663  41.972  1.00 41.66           N
ATOM   2006  CA  ILE A 259     -45.675  -2.794  42.807  1.00 43.98           C
ATOM   2007  C   ILE A 259     -46.094  -2.484  44.235  1.00 46.45           C
ATOM   2008  O   ILE A 259     -45.639  -1.492  44.819  1.00 42.48           O
ATOM   2009  CB  ILE A 259     -44.166  -3.073  42.727  1.00 45.86           C
ATOM   2010  CG1 ILE A 259     -43.769  -3.441  41.297  1.00 45.12           C
ATOM   2011  CG2 ILE A 259     -43.774  -4.177  43.698  1.00 43.52           C
ATOM   2012  CD1 ILE A 259     -42.287  -3.351  41.039  1.00 45.86           C
ATOM   2013  N   ALA A 260     -46.962  -3.325  44.793  1.00 42.04           N
ATOM   2014  CA  ALA A 260     -47.414  -3.131  46.162  1.00 45.82           C
ATOM   2015  C   ALA A 260     -46.246  -3.260  47.133  1.00 41.51           C
ATOM   2016  O   ALA A 260     -45.269  -3.969  46.875  1.00 42.00           O
ATOM   2017  CB  ALA A 260     -48.506  -4.143  46.512  1.00 45.92           C
ATOM   2018  N   VAL A 261     -46.354  -2.555  48.262  1.00 45.44           N
ATOM   2019  CA  VAL A 261     -45.277  -2.556  49.249  1.00 40.11           C
ATOM   2020  C   VAL A 261     -45.039  -3.962  49.781  1.00 40.40           C
ATOM   2021  O   VAL A 261     -43.893  -4.413  49.896  1.00 40.00           O
ATOM   2022  CB  VAL A 261     -45.592  -1.565  50.386  1.00 44.87           C
ATOM   2023  CG1 VAL A 261     -44.536  -1.655  51.477  1.00 37.21           C
ATOM   2024  CG2 VAL A 261     -45.685  -0.147  49.843  1.00 39.65           C
ATOM   2025  N   LEU A 262     -46.116  -4.683  50.102  1.00 41.06           N
ATOM   2026  CA  LEU A 262     -45.973  -6.045  50.600  1.00 40.25           C
ATOM   2027  C   LEU A 262     -45.451  -6.998  49.532  1.00 41.73           C
ATOM   2028  O   LEU A 262     -44.880  -8.039  49.875  1.00 41.71           O
ATOM   2029  CB  LEU A 262     -47.307  -6.544  51.153  1.00 44.86           C
ATOM   2030  CG  LEU A 262     -47.781  -5.814  52.413  1.00 45.85           C
ATOM   2031  CD1 LEU A 262     -49.092  -6.394  52.919  1.00 45.98           C
ATOM   2032  CD2 LEU A 262     -46.712  -5.864  53.497  1.00 43.73           C
ATOM   2033  N   ASP A 263     -45.635  -6.671  48.251  1.00 40.62           N
ATOM   2034  CA  ASP A 263     -44.984  -7.444  47.199  1.00 42.40           C
ATOM   2035  C   ASP A 263     -43.481  -7.200  47.198  1.00 41.38           C
ATOM   2036  O   ASP A 263     -42.693  -8.133  47.001  1.00 38.61           O
ATOM   2037  CB  ASP A 263     -45.583  -7.096  45.836  1.00 46.15           C
ATOM   2038  CG  ASP A 263     -47.021  -7.555  45.695  1.00 53.38           C
ATOM   2039  OD1 ASP A 263     -47.641  -7.900  46.722  1.00 47.26           O
ATOM   2040  OD2 ASP A 263     -47.531  -7.573  44.555  1.00 56.35           O
ATOM   2041  N   MET A 264     -43.066  -5.950  47.419  1.00 41.82           N
ATOM   2042  CA  MET A 264     -41.642  -5.645  47.502  1.00 42.49           C
ATOM   2043  C   MET A 264     -41.015  -6.244  48.753  1.00 39.15           C
ATOM   2044  O   MET A 264     -39.842  -6.633  48.732  1.00 39.74           O
ATOM   2045  CB  MET A 264     -41.427  -4.132  47.466  1.00 38.06           C
ATOM   2046  CG  MET A 264     -39.986  -3.717  47.231  1.00 44.12           C
ATOM   2047  SD  MET A 264     -39.393  -4.188  45.594  1.00 43.04           S
ATOM   2048  CE  MET A 264     -40.049  -2.855  44.595  1.00 39.43           C
ATOM   2049  N   CYS A 265     -41.775  -6.325  49.848  1.00 37.07           N
ATOM   2050  CA  CYS A 265     -41.266  -6.973  51.053  1.00 36.83           C
ATOM   2051  C   CYS A 265     -41.021  -8.458  50.817  1.00 38.56           C
ATOM   2052  O   CYS A 265     -40.050  -9.022  51.335  1.00 37.06           O
ATOM   2053  CB  CYS A 265     -42.240  -6.766  52.212  1.00 40.31           C
ATOM   2054  SG  CYS A 265     -42.478  -5.039  52.677  1.00 47.47           S
ATOM   2055  N   ALA A 266     -41.892  -9.107  50.039  1.00 40.63           N
ATOM   2056  CA  ALA A 266     -41.666 -10.505  49.688  1.00 41.15           C
ATOM   2057  C   ALA A 266     -40.402 -10.670  48.857  1.00 39.93           C
ATOM   2058  O   ALA A 266     -39.728 -11.702  48.951  1.00 47.79           O
ATOM   2059  CB  ALA A 266     -42.875 -11.062  48.936  1.00 47.71           C
ATOM   2060  N   SER A 267     -40.066  -9.670  48.040  1.00 41.06           N
ATOM   2061  CA  SER A 267     -38.810  -9.712  47.301  1.00 43.82           C
ATOM   2062  C   SER A 267     -37.622  -9.502  48.230  1.00 39.34           C
ATOM   2063  O   SER A 267     -36.579 -10.147  48.070  1.00 40.48           O
ATOM   2064  CB  SER A 267     -38.817  -8.661  46.191  1.00 38.53           C
ATOM   2065  OG  SER A 267     -39.821  -8.940  45.232  1.00 48.72           O
ATOM   2066  N   LEU A 268     -37.760  -8.601  49.206  1.00 38.23           N
ATOM   2067  CA  LEU A 268     -36.692  -8.394  50.178  1.00 35.14           C
ATOM   2068  C   LEU A 268     -36.489  -9.631  51.043  1.00 37.02           C
ATOM   2069  O   LEU A 268     -35.353  -9.971  51.394  1.00 38.62           O
ATOM   2070  CB  LEU A 268     -37.003  -7.174  51.045  1.00 36.68           C
ATOM   2071  CG  LEU A 268     -36.013  -6.846  52.164  1.00 33.77           C
ATOM   2072  CD1 LEU A 268     -34.598  -6.756  51.616  1.00 30.10           C
ATOM   2073  CD2 LEU A 268     -36.402  -5.553  52.864  1.00 31.19           C
ATOM   2074  N   LYS A 269     -37.580 -10.317  51.395  1.00 38.58           N
ATOM   2075  CA  LYS A 269     -37.463 -11.549  52.169  1.00 40.10           C
ATOM   2076  C   LYS A 269     -36.655 -12.597  51.415  1.00 42.05           C
ATOM   2077  O   LYS A 269     -35.828 -13.300  52.008  1.00 41.92           O
ATOM   2078  CB  LYS A 269     -38.852 -12.084  52.513  1.00 46.02           C
ATOM   2079  CG  LYS A 269     -38.846 -13.426  53.224  1.00 51.23           C
ATOM   2080  CD  LYS A 269     -40.253 -13.987  53.349  1.00 53.17           C
ATOM   2081  CE  LYS A 269     -41.180 -13.000  54.039  1.00 51.26           C
ATOM   2082  NZ  LYS A 269     -42.571 -13.519  54.134  1.00 56.30           N
ATOM   2083  N   GLU A 270     -36.877 -12.714  50.104  1.00 43.04           N
ATOM   2084  CA  GLU A 270     -36.107 -13.665  49.310  1.00 46.48           C
ATOM   2085  C   GLU A 270     -34.655 -13.222  49.176  1.00 43.84           C
ATOM   2086  O   GLU A 270     -33.743 -14.057  49.190  1.00 45.21           O
ATOM   2087  CB  GLU A 270     -36.747 -13.842  47.933  1.00 45.69           C
ATOM   2088  CG  GLU A 270     -38.113 -14.505  47.972  1.00 51.79           C
ATOM   2089  CD  GLU A 270     -38.161 -15.683  48.926  1.00 60.94           C
ATOM   2090  OE1 GLU A 270     -38.830 -15.573  49.975  1.00 63.77           O
ATOM   2091  OE2 GLU A 270     -37.529 -16.718  48.627  1.00 65.14           O
ATOM   2092  N   LEU A 271     -34.419 -11.914  49.046  1.00 45.19           N
ATOM   2093  CA  LEU A 271     -33.048 -11.415  49.005  1.00 42.45           C
ATOM   2094  C   LEU A 271     -32.335 -11.666  50.327  1.00 43.35           C
ATOM   2095  O   LEU A 271     -31.127 -11.929  50.351  1.00 45.28           O
ATOM   2096  CB  LEU A 271     -33.039  -9.925  48.665  1.00 41.98           C
ATOM   2097  CG  LEU A 271     -33.507  -9.528  47.264  1.00 41.27           C
ATOM   2098  CD1 LEU A 271     -33.534  -8.014  47.117  1.00 37.30           C
ATOM   2099  CD2 LEU A 271     -32.622 -10.159  46.201  1.00 46.99           C
ATOM   2100  N   LEU A 272     -33.068 -11.591  51.440  1.00 42.10           N
ATOM   2101  CA  LEU A 272     -32.461 -11.854  52.740  1.00 42.09           C
ATOM   2102  C   LEU A 272     -32.172 -13.338  52.926  1.00 48.89           C
ATOM   2103  O   LEU A 272     -31.127 -13.710  53.472  1.00 53.66           O
ATOM   2104  CB  LEU A 272     -33.373 -11.343  53.856  1.00 40.00           C
ATOM   2105  CG  LEU A 272     -33.439  -9.825  54.033  1.00 41.91           C
ATOM   2106  CD1 LEU A 272     -34.425  -9.451  55.129  1.00 35.76           C
ATOM   2107  CD2 LEU A 272     -32.058  -9.269  54.332  1.00 44.46           C
ATOM   2108  N   GLN A 273     -33.082 -14.202  52.471  1.00 52.50           N
ATOM   2109  CA  GLN A 273     -32.925 -15.635  52.691  1.00 51.77           C
ATOM   2110  C   GLN A 273     -31.999 -16.272  51.661  1.00 51.92           C
ATOM   2111  O   GLN A 273     -31.243 -17.192  51.990  1.00 56.61           O
ATOM   2112  CB  GLN A 273     -34.292 -16.321  52.672  1.00 49.12           C
ATOM   2113  CG  GLN A 273     -35.164 -16.003  53.876  1.00 46.31           C
ATOM   2114  CD  GLN A 273     -36.599 -16.457  53.693  1.00 52.57           C
ATOM   2115  OE1 GLN A 273     -37.063 -16.649  52.569  1.00 59.44           O
ATOM   2116  NE2 GLN A 273     -37.311 -16.631  54.800  1.00 57.13           N
ATOM   2117  N   ASN A 274     -32.041 -15.799  50.414  1.00 50.67           N
ATOM   2118  CA  ASN A 274     -31.258 -16.399  49.342  1.00 56.22           C
ATOM   2119  C   ASN A 274     -29.996 -15.622  48.997  1.00 58.82           C
ATOM   2120  O   ASN A 274     -29.052 -16.215  48.461  1.00 62.53           O
ATOM   2121  CB  ASN A 274     -32.108 -16.537  48.071  1.00 54.86           C
ATOM   2122  CG  ASN A 274     -33.184 -17.594  48.200  1.00 59.96           C
ATOM   2123  OD1 ASN A 274     -33.065 -18.687  47.647  1.00 71.24           O
ATOM   2124  ND2 ASN A 274     -34.247 -17.272  48.928  1.00 60.96           N
ATOM   2125  N   GLY A 275     -29.951 -14.326  49.285  1.00 58.14           N
ATOM   2126  CA  GLY A 275     -28.851 -13.511  48.817  1.00 56.72           C
ATOM   2127  C   GLY A 275     -29.093 -13.035  47.395  1.00 56.27           C
ATOM   2128  O   GLY A 275     -30.207 -13.098  46.864  1.00 59.13           O
ATOM   2129  N   MET A 276     -28.021 -12.553  46.768  1.00 60.37           N
ATOM   2130  CA  MET A 276     -28.094 -12.061  45.399  1.00 63.91           C
ATOM   2131  C   MET A 276     -27.532 -13.040  44.377  1.00 67.41           C
ATOM   2132  O   MET A 276     -27.698 -12.811  43.174  1.00 65.06           O
ATOM   2133  CB  MET A 276     -27.361 -10.720  45.279  1.00 61.86           C
ATOM   2134  CG  MET A 276     -27.916  -9.635  46.185  1.00 58.29           C
ATOM   2135  SD  MET A 276     -27.474  -7.976  45.639  1.00 64.45           S
ATOM   2136  CE  MET A 276     -28.134  -6.996  46.984  1.00 53.58           C
ATOM   2137  N   ASN A 277     -26.874 -14.113  44.825  1.00 70.86           N
ATOM   2138  CA  ASN A 277     -26.324 -15.145  43.943  1.00 69.91           C
ATOM   2139  C   ASN A 277     -25.333 -14.571  42.935  1.00 69.73           C
ATOM   2140  O   ASN A 277     -25.185 -15.093  41.827  1.00 72.21           O
ATOM   2141  CB  ASN A 277     -27.436 -15.911  43.219  1.00 69.32           C
ATOM   2142  CG  ASN A 277     -28.562 -16.322  44.148  1.00 71.51           C
ATOM   2143  OD1 ASN A 277     -29.702 -15.881  43.995  1.00 71.38           O
ATOM   2144  ND2 ASN A 277     -28.249 -17.174  45.117  1.00 68.46           N
ATOM   2145  N   GLY A 278     -24.644 -13.492  43.306  1.00 69.46           N
ATOM   2146  CA  GLY A 278     -23.637 -12.887  42.462  1.00 66.66           C
ATOM   2147  C   GLY A 278     -24.118 -11.726  41.617  1.00 62.86           C
ATOM   2148  O   GLY A 278     -23.292 -11.080  40.961  1.00 66.43           O
ATOM   2149  N   ARG A 279     -25.418 -11.444  41.608  1.00 62.00           N
ATOM   2150  CA  ARG A 279     -25.946 -10.330  40.837  1.00 58.14           C
ATOM   2151  C   ARG A 279     -25.826  -9.033  41.636  1.00 54.97           C
ATOM   2152  O   ARG A 279     -25.415  -9.020  42.800  1.00 57.06           O
ATOM   2153  CB  ARG A 279     -27.394 -10.601  40.435  1.00 58.33           C
ATOM   2154  CG  ARG A 279     -27.543 -11.605  39.305  1.00 61.48           C
ATOM   2155  CD  ARG A 279     -28.893 -12.299  39.360  1.00 64.48           C
ATOM   2156  NE  ARG A 279     -29.126 -12.935  40.653  1.00 68.07           N
ATOM   2157  CZ  ARG A 279     -30.050 -13.865  40.872  1.00 66.61           C
ATOM   2158  NH1 ARG A 279     -30.832 -14.271  39.881  1.00 65.70           N
ATOM   2159  NH2 ARG A 279     -30.192 -14.388  42.081  1.00 64.26           N
ATOM   2160  N   THR A 280     -26.187  -7.920  41.000  1.00 53.01           N
ATOM   2161  CA  THR A 280     -26.103  -6.607  41.618  1.00 54.41           C
ATOM   2162  C   THR A 280     -27.418  -5.863  41.438  1.00 47.56           C
ATOM   2163  O   THR A 280     -28.215  -6.167  40.546  1.00 47.45           O
ATOM   2164  CB  THR A 280     -24.955  -5.771  41.030  1.00 51.50           C
ATOM   2165  OG1 THR A 280     -25.136  -5.632  39.615  1.00 46.19           O
ATOM   2166  CG2 THR A 280     -23.611  -6.431  41.305  1.00 48.75           C
ATOM   2167  N   ILE A 281     -27.634  -4.877  42.305  1.00 44.13           N
ATOM   2168  CA  ILE A 281     -28.798  -4.001  42.249  1.00 42.36           C
ATOM   2169  C   ILE A 281     -28.294  -2.566  42.272  1.00 45.61           C
ATOM   2170  O   ILE A 281     -27.695  -2.133  43.264  1.00 43.79           O
ATOM   2171  CB  ILE A 281     -29.770  -4.251  43.414  1.00 41.15           C
ATOM   2172  CG1 ILE A 281     -30.359  -5.660  43.331  1.00 44.80           C
ATOM   2173  CG2 ILE A 281     -30.877  -3.214  43.410  1.00 43.10           C
ATOM   2174  CD1 ILE A 281     -31.258  -6.012  44.497  1.00 39.65           C
ATOM   2175  N   LEU A 282     -28.531  -1.834  41.182  1.00 43.16           N
ATOM   2176  CA  LEU A 282     -28.071  -0.451  41.038  1.00 45.23           C
ATOM   2177  C   LEU A 282     -26.561  -0.343  41.244  1.00 47.58           C
ATOM   2178  O   LEU A 282     -26.058   0.635  41.801  1.00 45.25           O
ATOM   2179  CB  LEU A 282     -28.821   0.485  41.990  1.00 41.21           C
ATOM   2180  CG  LEU A 282     -30.264   0.819  41.604  1.00 38.59           C
ATOM   2181  CD1 LEU A 282     -30.929   1.671  42.675  1.00 39.34           C
ATOM   2182  CD2 LEU A 282     -30.310   1.518  40.254  1.00 40.02           C
ATOM   2183  N   GLY A 283     -25.832  -1.362  40.794  1.00 47.85           N
ATOM   2184  CA  GLY A 283     -24.388  -1.368  40.897  1.00 43.98           C
ATOM   2185  C   GLY A 283     -23.833  -1.782  42.240  1.00 46.91           C
ATOM   2186  O   GLY A 283     -22.619  -1.676  42.448  1.00 52.36           O
ATOM   2187  N   SER A 284     -24.674  -2.251  43.157  1.00 46.38           N
ATOM   2188  CA  SER A 284     -24.242  -2.652  44.488  1.00 45.39           C
ATOM   2189  C   SER A 284     -24.530  -4.131  44.699  1.00 45.33           C
ATOM   2190  O   SER A 284     -25.546  -4.651  44.224  1.00 44.86           O
ATOM   2191  CB  SER A 284     -24.939  -1.822  45.570  1.00 46.80           C
ATOM   2192  OG  SER A 284     -24.953  -2.511  46.809  1.00 52.75           O
ATOM   2193  N   ALA A 285     -23.633  -4.806  45.416  1.00 46.95           N
ATOM   2194  CA  ALA A 285     -23.763  -6.230  45.690  1.00 49.63           C
ATOM   2195  C   ALA A 285     -24.365  -6.522  47.058  1.00 46.92           C
ATOM   2196  O   ALA A 285     -24.538  -7.694  47.404  1.00 46.50           O
ATOM   2197  CB  ALA A 285     -22.401  -6.918  45.562  1.00 45.38           C
ATOM   2198  N   LEU A 286     -24.680  -5.496  47.844  1.00 43.38           N
ATOM   2199  CA  LEU A 286     -25.411  -5.663  49.091  1.00 46.74           C
ATOM   2200  C   LEU A 286     -26.575  -4.681  49.119  1.00 45.35           C
ATOM   2201  O   LEU A 286     -26.685  -3.785  48.277  1.00 45.40           O
ATOM   2202  CB  LEU A 286     -24.505  -5.478  50.320  1.00 44.86           C
ATOM   2203  CG  LEU A 286     -23.537  -4.295  50.403  1.00 50.21           C
ATOM   2204  CD1 LEU A 286     -24.237  -3.026  50.871  1.00 52.98           C
ATOM   2205  CD2 LEU A 286     -22.381  -4.638  51.330  1.00 50.75           C
ATOM   2206  N   LEU A 287     -27.454  -4.861  50.100  1.00 43.05           N
ATOM   2207  CA  LEU A 287     -28.671  -4.065  50.194  1.00 40.31           C
ATOM   2208  C   LEU A 287     -28.367  -2.731  50.866  1.00 40.74           C
ATOM   2209  O   LEU A 287     -27.907  -2.696  52.013  1.00 40.08           O
ATOM   2210  CB  LEU A 287     -29.743  -4.836  50.960  1.00 39.07           C
ATOM   2211  CG  LEU A 287     -30.148  -6.166  50.315  1.00 45.82           C
ATOM   2212  CD1 LEU A 287     -30.899  -7.046  51.299  1.00 37.38           C
ATOM   2213  CD2 LEU A 287     -30.980  -5.927  49.065  1.00 39.23           C
ATOM   2214  N   GLU A 288     -28.626  -1.637  50.153  1.00 43.94           N
ATOM   2215  CA  GLU A 288     -28.346  -0.303  50.666  1.00 39.65           C
ATOM   2216  C   GLU A 288     -29.501   0.199  51.522  1.00 35.61           C
ATOM   2217  O   GLU A 288     -30.673   0.001  51.187  1.00 33.13           O
ATOM   2218  CB  GLU A 288     -28.094   0.667  49.512  1.00 40.34           C
ATOM   2219  CG  GLU A 288     -27.034   0.202  48.527  1.00 43.71           C
ATOM   2220  CD  GLU A 288     -25.631   0.311  49.088  1.00 54.24           C
ATOM   2221  OE1 GLU A 288     -25.415   1.147  49.991  1.00 57.11           O
ATOM   2222  OE2 GLU A 288     -24.744  -0.438  48.627  1.00 55.38           O
ATOM   2223  N   ASP A 289     -29.164   0.864  52.632  1.00 34.58           N
ATOM   2224  CA  ASP A 289     -30.169   1.388  53.551  1.00 31.69           C
ATOM   2225  C   ASP A 289     -29.940   2.860  53.881  1.00 32.60           C
ATOM   2226  O   ASP A 289     -30.467   3.352  54.884  1.00 32.84           O
ATOM   2227  CB  ASP A 289     -30.212   0.557  54.837  1.00 34.39           C
ATOM   2228  CG  ASP A 289     -29.010   0.794  55.732  1.00 36.52           C
ATOM   2229  OD1 ASP A 289     -27.932   1.160  55.215  1.00 34.85           O
ATOM   2230  OD2 ASP A 289     -29.143   0.606  56.960  1.00 37.93           O
ATOM   2231  N   GLU A 290     -29.171   3.577  53.060  1.00 30.06           N
ATOM   2232  CA  GLU A 290     -28.884   4.986  53.298  1.00 37.11           C
ATOM   2233  C   GLU A 290     -29.628   5.902  52.332  1.00 36.71           C
ATOM   2234  O   GLU A 290     -29.228   7.055  52.144  1.00 36.45           O
ATOM   2235  CB  GLU A 290     -27.377   5.240  53.224  1.00 42.67           C
ATOM   2236  CG  GLU A 290     -26.565   4.420  54.218  1.00 39.72           C
ATOM   2237  CD  GLU A 290     -25.689   5.277  55.113  1.00 49.42           C
ATOM   2238  OE1 GLU A 290     -25.329   6.400  54.700  1.00 46.04           O
ATOM   2239  OE2 GLU A 290     -25.366   4.829  56.235  1.00 45.74           O
ATOM   2240  N   PHE A 291     -30.701   5.412  51.714  1.00 32.45           N
ATOM   2241  CA  PHE A 291     -31.556   6.214  50.846  1.00 30.40           C
ATOM   2242  C   PHE A 291     -32.960   6.229  51.430  1.00 28.93           C
ATOM   2243  O   PHE A 291     -33.613   5.183  51.508  1.00 33.65           O
ATOM   2244  CB  PHE A 291     -31.584   5.660  49.419  1.00 35.18           C
ATOM   2245  CG  PHE A 291     -30.269   5.747  48.702  1.00 35.16           C
ATOM   2246  CD1 PHE A 291     -29.843   6.943  48.148  1.00 41.40           C
ATOM   2247  CD2 PHE A 291     -29.464   4.628  48.568  1.00 39.83           C
ATOM   2248  CE1 PHE A 291     -28.634   7.023  47.481  1.00 40.28           C
ATOM   2249  CE2 PHE A 291     -28.255   4.701  47.904  1.00 42.31           C
ATOM   2250  CZ  PHE A 291     -27.839   5.900  47.359  1.00 44.36           C
ATOM   2251  N   THR A 292     -33.421   7.405  51.836  1.00 29.18           N
ATOM   2252  CA  THR A 292     -34.782   7.545  52.324  1.00 32.67           C
ATOM   2253  C   THR A 292     -35.765   7.531  51.157  1.00 34.56           C
ATOM   2254  O   THR A 292     -35.377   7.732  50.003  1.00 31.43           O
ATOM   2255  CB  THR A 292     -34.930   8.843  53.114  1.00 34.07           C
ATOM   2256  OG1 THR A 292     -34.881   9.960  52.217  1.00 35.62           O
ATOM   2257  CG2 THR A 292     -33.818   8.973  54.142  1.00 33.60           C
ATOM   2258  N   PRO A 293     -37.047   7.272  51.428  1.00 31.23           N
ATOM   2259  CA  PRO A 293     -38.054   7.428  50.366  1.00 33.28           C
ATOM   2260  C   PRO A 293     -38.059   8.815  49.749  1.00 36.53           C
ATOM   2261  O   PRO A 293     -38.328   8.948  48.549  1.00 33.40           O
ATOM   2262  CB  PRO A 293     -39.371   7.119  51.089  1.00 31.22           C
ATOM   2263  CG  PRO A 293     -38.975   6.190  52.181  1.00 34.10           C
ATOM   2264  CD  PRO A 293     -37.614   6.646  52.636  1.00 35.28           C
ATOM   2265  N   PHE A 294     -37.763   9.854  50.535  1.00 37.82           N
ATOM   2266  CA  PHE A 294     -37.644  11.195  49.974  1.00 37.77           C
ATOM   2267  C   PHE A 294     -36.470  11.283  49.007  1.00 36.09           C
ATOM   2268  O   PHE A 294     -36.580  11.898  47.939  1.00 35.93           O
ATOM   2269  CB  PHE A 294     -37.494  12.222  51.098  1.00 39.53           C
ATOM   2270  CG  PHE A 294     -37.046  13.579  50.629  1.00 46.89           C
ATOM   2271  CD1 PHE A 294     -37.962  14.491  50.131  1.00 46.71           C
ATOM   2272  CD2 PHE A 294     -35.710  13.946  50.694  1.00 46.02           C
ATOM   2273  CE1 PHE A 294     -37.555  15.740  49.699  1.00 45.80           C
ATOM   2274  CE2 PHE A 294     -35.297  15.193  50.263  1.00 50.10           C
ATOM   2275  CZ  PHE A 294     -36.221  16.091  49.766  1.00 49.44           C
ATOM   2276  N   ASP A 295     -35.335  10.677  49.366  1.00 35.46           N
ATOM   2277  CA  ASP A 295     -34.169  10.702  48.488  1.00 36.98           C
ATOM   2278  C   ASP A 295     -34.461  10.007  47.164  1.00 39.00           C
ATOM   2279  O   ASP A 295     -33.961  10.422  46.112  1.00 37.31           O
ATOM   2280  CB  ASP A 295     -32.974  10.046  49.182  1.00 35.68           C
ATOM   2281  CG  ASP A 295     -32.512  10.817  50.404  1.00 43.97           C
ATOM   2282  OD1 ASP A 295     -32.531  12.065  50.364  1.00 48.13           O
ATOM   2283  OD2 ASP A 295     -32.130  10.174  51.406  1.00 36.81           O
ATOM   2284  N   VAL A 296     -35.273   8.949  47.197  1.00 35.51           N
ATOM   2285  CA  VAL A 296     -35.595   8.217  45.976  1.00 38.02           C
ATOM   2286  C   VAL A 296     -36.552   9.025  45.107  1.00 40.16           C
ATOM   2287  O   VAL A 296     -36.359   9.146  43.891  1.00 45.87           O
ATOM   2288  CB  VAL A 296     -36.174   6.833  46.321  1.00 35.27           C
ATOM   2289  CG1 VAL A 296     -36.681   6.141  45.066  1.00 39.63           C
ATOM   2290  CG2 VAL A 296     -35.128   5.982  47.023  1.00 29.11           C
ATOM   2291  N   VAL A 297     -37.598   9.589  45.718  1.00 38.46           N
ATOM   2292  CA  VAL A 297     -38.575  10.374  44.966  1.00 45.90           C
ATOM   2293  C   VAL A 297     -37.904  11.567  44.297  1.00 49.13           C
ATOM   2294  O   VAL A 297     -38.184  11.887  43.135  1.00 52.12           O
ATOM   2295  CB  VAL A 297     -39.724  10.819  45.892  1.00 45.77           C
ATOM   2296  CG1 VAL A 297     -40.575  11.887  45.220  1.00 44.51           C
ATOM   2297  CG2 VAL A 297     -40.577   9.627  46.294  1.00 44.40           C
ATOM   2298  N   ARG A 298     -36.998  12.232  45.015  1.00 46.70           N
ATOM   2299  CA  ARG A 298     -36.320  13.412  44.491  1.00 44.22           C
ATOM   2300  C   ARG A 298     -35.428  13.088  43.296  1.00 50.73           C
ATOM   2301  O   ARG A 298     -35.115  13.988  42.508  1.00 54.75           O
ATOM   2302  CB  ARG A 298     -35.529  14.070  45.631  1.00 51.51           C
ATOM   2303  CG  ARG A 298     -34.202  14.721  45.273  1.00 49.71           C
ATOM   2304  CD  ARG A 298     -33.661  15.490  46.472  1.00 57.86           C
ATOM   2305  NE  ARG A 298     -32.345  16.073  46.226  1.00 54.95           N
ATOM   2306  CZ  ARG A 298     -31.228  15.674  46.826  1.00 55.85           C
ATOM   2307  NH1 ARG A 298     -31.264  14.684  47.709  1.00 53.66           N
ATOM   2308  NH2 ARG A 298     -30.074  16.264  46.545  1.00 43.01           N
ATOM   2309  N   GLN A 299     -35.053  11.819  43.113  1.00 53.74           N
ATOM   2310  CA  GLN A 299     -34.139  11.428  42.050  1.00 49.75           C
ATOM   2311  C   GLN A 299     -34.768  10.576  40.954  1.00 52.89           C
ATOM   2312  O   GLN A 299     -34.167  10.449  39.884  1.00 57.18           O
ATOM   2313  CB  GLN A 299     -32.947  10.655  42.635  1.00 46.73           C
ATOM   2314  CG  GLN A 299     -31.643  11.431  42.674  1.00 41.40           C
ATOM   2315  CD  GLN A 299     -30.438  10.526  42.853  1.00 41.78           C
ATOM   2316  OE1 GLN A 299     -29.830  10.082  41.879  1.00 37.77           O
ATOM   2317  NE2 GLN A 299     -30.087  10.248  44.104  1.00 37.06           N
ATOM   2318  N   CYS A 300     -35.950   9.995  41.181  1.00 53.23           N
ATOM   2319  CA  CYS A 300     -36.463   8.962  40.288  1.00 54.39           C
ATOM   2320  C   CYS A 300     -37.876   9.184  39.762  1.00 66.24           C
ATOM   2321  O   CYS A 300     -38.303   8.426  38.886  1.00 64.02           O
ATOM   2322  CB  CYS A 300     -36.423   7.591  40.983  1.00 55.59           C
ATOM   2323  SG  CYS A 300     -34.767   7.016  41.416  1.00 58.53           S
ATOM   2324  N   SER A 301     -38.616  10.177  40.253  1.00 63.98           N
ATOM   2325  CA  SER A 301     -40.000  10.343  39.824  1.00 68.50           C
ATOM   2326  C   SER A 301     -40.132  10.916  38.418  1.00 66.76           C
ATOM   2327  O   SER A 301     -41.234  10.888  37.858  1.00 67.80           O
ATOM   2328  CB  SER A 301     -40.761  11.225  40.816  1.00 66.83           C
ATOM   2329  OG  SER A 301     -41.110  10.491  41.978  1.00 66.17           O
ATOM   2330  N   GLY A 302     -39.050  11.428  37.837  1.00 66.59           N
ATOM   2331  CA  GLY A 302     -39.082  11.879  36.459  1.00 70.74           C
ATOM   2332  C   GLY A 302     -38.753  10.763  35.488  1.00 70.30           C
ATOM   2333  O   GLY A 302     -38.308  11.013  34.363  1.00 72.05           O
ATOM   2334  N   VAL A 303     -38.967   9.520  35.922  1.00 70.54           N
ATOM   2335  CA  VAL A 303     -38.671   8.330  35.137  1.00 70.96           C
ATOM   2336  C   VAL A 303     -39.919   7.456  35.097  1.00 71.79           C
ATOM   2337  O   VAL A 303     -40.791   7.537  35.964  1.00 75.15           O
ATOM   2338  CB  VAL A 303     -37.475   7.536  35.717  1.00 71.23           C
ATOM   2339  CG1 VAL A 303     -36.869   6.615  34.672  1.00 65.59           C
ATOM   2340  CG2 VAL A 303     -36.415   8.479  36.278  1.00 67.41           C
ATOM   2341  N   THR A 304     -40.002   6.614  34.069  1.00 78.33           N
ATOM   2342  CA  THR A 304     -41.037   5.589  34.051  1.00 83.04           C
ATOM   2343  C   THR A 304     -40.429   4.204  33.814  1.00 78.65           C
ATOM   2344  O   THR A 304     -40.511   3.349  34.700  1.00 79.54           O
ATOM   2345  CB  THR A 304     -42.140   5.955  33.037  1.00 83.63           C
ATOM   2346  OG1 THR A 304     -43.200   4.988  33.088  1.00 85.88           O
ATOM   2347  CG2 THR A 304     -41.611   6.143  31.607  1.00 79.08           C
ATOM   2348  N   PHE A 305     -39.779   3.983  32.668  1.00 77.77           N
ATOM   2349  CA  PHE A 305     -39.123   2.709  32.349  1.00 80.32           C
ATOM   2350  C   PHE A 305     -40.037   1.518  32.626  1.00 79.61           C
ATOM   2351  O   PHE A 305     -39.623   0.503  33.191  1.00 76.29           O
ATOM   2352  CB  PHE A 305     -37.799   2.567  33.104  1.00 75.88           C
ATOM   2353  CG  PHE A 305     -36.716   1.894  32.306  1.00 75.68           C
ATOM   2354  CD1 PHE A 305     -36.485   2.260  30.994  1.00 79.28           C
ATOM   2355  CD2 PHE A 305     -35.930   0.902  32.865  1.00 71.73           C
ATOM   2356  CE1 PHE A 305     -35.492   1.649  30.250  1.00 79.40           C
ATOM   2357  CE2 PHE A 305     -34.931   0.285  32.126  1.00 73.41           C
ATOM   2358  CZ  PHE A 305     -34.712   0.661  30.818  1.00 75.84           C
ATOM   2359  N   GLN A 306     -41.298   1.653  32.232  1.00 80.67           N
ATOM   2360  CA  GLN A 306     -42.296   0.612  32.439  1.00 77.22           C
ATOM   2361  C   GLN A 306     -42.032  -0.599  31.550  1.00 70.87           C
ATOM   2362  O   GLN A 306     -42.774  -1.581  31.583  1.00 68.39           O
ATOM   2363  CB  GLN A 306     -43.702   1.159  32.175  1.00 77.17           C
ATOM   2364  CG  GLN A 306     -44.077   1.275  30.699  1.00 78.18           C
ATOM   2365  CD  GLN A 306     -43.097   2.109  29.893  1.00 78.00           C
ATOM   2366  OE1 GLN A 306     -42.557   3.100  30.385  1.00 83.17           O
ATOM   2367  NE2 GLN A 306     -42.864   1.710  28.648  1.00 71.18           N
TER    2368      GLN A 306
HETATM 2369  C4  02J C   1     -10.425   3.420  72.447  1.00 54.38           C
HETATM 2370  C5  02J C   1      -9.924   2.857  73.642  1.00 63.03           C
HETATM 2371  C6  02J C   1      -9.345   1.458  73.806  1.00 64.36           C
HETATM 2372  O1  02J C   1     -10.048   3.755  74.575  1.00 63.01           O
HETATM 2373  N   02J C   1     -10.585   4.861  74.114  1.00 56.79           N
HETATM 2374  CA  02J C   1     -10.848   4.738  72.810  1.00 50.26           C
HETATM 2375  C   02J C   1     -11.475   5.798  71.891  1.00 47.56           C
HETATM 2376  O   02J C   1     -11.062   5.943  70.793  1.00 47.13           O
ATOM   2377  N   ALA C   2     -12.583   6.625  72.374  1.00 41.65           N
ATOM   2378  CA  ALA C   2     -13.166   7.625  71.495  1.00 42.30           C
ATOM   2379  C   ALA C   2     -12.115   8.750  71.241  1.00 41.96           C
ATOM   2380  O   ALA C   2     -11.466   9.159  72.125  1.00 41.95           O
ATOM   2381  CB  ALA C   2     -14.397   8.218  72.132  1.00 36.98           C
ATOM   2382  N   VAL C   3     -11.943   9.281  69.911  1.00 34.91           N
ATOM   2383  CA  VAL C   3     -10.955  10.336  69.666  1.00 36.29           C
ATOM   2384  C   VAL C   3     -11.663  11.606  69.273  1.00 40.78           C
ATOM   2385  O   VAL C   3     -12.381  11.617  68.328  1.00 36.69           O
ATOM   2386  CB  VAL C   3     -10.004   9.895  68.515  1.00 37.53           C
ATOM   2387  CG1 VAL C   3      -9.130  11.086  68.012  1.00 40.26           C
ATOM   2388  CG2 VAL C   3      -9.116   8.687  68.955  1.00 43.10           C
ATOM   2389  N   LEU C   4     -11.480  12.678  70.044  1.00 37.32           N
ATOM   2390  CA  LEU C   4     -12.170  13.963  69.746  1.00 39.95           C
ATOM   2391  C   LEU C   4     -11.804  14.454  68.341  1.00 38.38           C
ATOM   2392  O   LEU C   4     -10.606  14.442  68.036  1.00 44.55           O
ATOM   2393  CB  LEU C   4     -11.795  14.995  70.811  1.00 41.97           C
ATOM   2394  CG  LEU C   4     -12.566  14.876  72.122  1.00 45.86           C
ATOM   2395  CD1 LEU C   4     -12.470  16.162  72.925  1.00 43.70           C
ATOM   2396  CD2 LEU C   4     -14.017  14.514  71.861  1.00 43.98           C
HETATM 2397  CA  PJE C   5     -11.832  15.491  65.878  1.00 20.00           C
HETATM 2398  C20 PJE C   5     -12.422  16.890  65.699  1.00 20.00           C
HETATM 2399  C21 PJE C   5     -11.667  17.948  65.902  1.00 20.00           C
HETATM 2400  C   PJE C   5     -10.208  17.820  66.347  1.00 20.00           C
HETATM 2401  C25 PJE C   5     -12.437  14.594  64.806  1.00 20.00           C
HETATM 2402  C26 PJE C   5     -11.835  13.199  64.838  1.00 20.00           C
HETATM 2403  C27 PJE C   5     -10.368  13.241  64.572  1.00 20.00           C
HETATM 2404  C28 PJE C   5     -10.110  11.892  63.885  1.00 20.00           C
HETATM 2405  N6  PJE C   5     -11.386  11.531  63.232  1.00 20.00           N
HETATM 2406  C29 PJE C   5     -12.428  12.306  63.787  1.00 20.00           C
HETATM 2407  O8  PJE C   5     -13.559  12.260  63.495  1.00 20.00           O
HETATM 2408  N   PJE C   5     -12.282  14.994  67.123  1.00 20.00           N
HETATM 2409  O   PJE C   5      -9.943  17.785  67.572  1.00 20.00           O
HETATM 2410  C   010 C   6      -7.901  17.649  66.696  1.00 63.14           C
HETATM 2411  O   010 C   6      -8.911  17.849  65.703  1.00 52.13           O
HETATM 2412  C1  010 C   6      -5.810  18.997  67.259  1.00 68.45           C
HETATM 2413  C2  010 C   6      -5.138  20.205  67.439  1.00 70.02           C
HETATM 2414  C3  010 C   6      -5.815  21.414  67.257  1.00 58.91           C
HETATM 2415  C4  010 C   6      -7.156  21.406  66.898  1.00 57.63           C
HETATM 2416  C5  010 C   6      -7.831  20.201  66.715  1.00 62.65           C
HETATM 2417  C6  010 C   6      -7.153  18.994  66.896  1.00 66.95           C
TER    2418      010 C   6
HETATM 2419  O   HOH A 401     -11.396  36.755  67.015  1.00 44.82           O
HETATM 2420  O   HOH A 402     -31.222  19.553  70.556  0.50 39.23           O
HETATM 2421  O   HOH A 403     -47.862  -5.632  43.371  1.00 42.02           O
HETATM 2422  O   HOH A 404     -26.339   2.976  51.107  1.00 43.61           O
HETATM 2423  O   HOH A 405     -28.990  -6.912  54.661  1.00 49.02           O
HETATM 2424  O   HOH A 406     -35.910   6.292  56.674  1.00 35.79           O
HETATM 2425  O   HOH A 407     -38.600  -1.119  35.531  1.00 40.87           O
HETATM 2426  O   HOH A 408     -30.477  14.159  52.626  1.00 36.39           O
HETATM 2427  O   HOH A 409     -24.109   6.893  58.019  1.00 33.26           O
HETATM 2428  O   HOH A 410      -7.786  25.011  83.208  1.00 51.94           O
HETATM 2429  O   HOH A 411      -7.908  25.459  73.866  1.00 49.73           O
HETATM 2430  O   HOH A 412     -33.454  22.196  67.094  1.00 42.72           O
HETATM 2431  O   HOH A 413     -11.106  11.922  55.222  1.00 43.64           O
HETATM 2432  O   HOH A 414     -32.723   3.709  66.874  1.00 49.24           O
HETATM 2433  O   HOH A 415     -24.947  11.967  75.516  1.00 44.74           O
HETATM 2434  O   HOH A 416      -9.872  22.828  61.517  1.00 42.58           O
HETATM 2435  O   HOH A 417     -36.126  20.846  58.232  1.00 54.40           O
HETATM 2436  O   HOH A 418     -28.841  14.028  73.530  1.00 35.86           O
HETATM 2437  O   HOH A 419     -25.942   4.008  70.311  1.00 29.32           O
HETATM 2438  O   HOH A 420     -33.000  18.562  45.717  1.00 50.95           O
HETATM 2439  O   HOH A 421     -18.980  22.168  57.582  1.00 30.35           O
HETATM 2440  O   HOH A 422     -21.040  15.276  76.601  1.00 38.79           O
HETATM 2441  O   HOH A 423      -8.936  31.124  64.275  1.00 45.45           O
HETATM 2442  O   HOH A 424     -26.136  25.492  48.183  1.00 43.24           O
HETATM 2443  O   HOH A 425     -23.746  11.016  67.468  1.00 27.37           O
HETATM 2444  O   HOH A 426      -6.321  25.450  86.112  1.00 63.03           O
HETATM 2445  O   HOH A 427     -42.521   3.816  61.535  1.00 43.86           O
HETATM 2446  O   HOH A 428     -48.693  -1.344  48.701  1.00 48.99           O
HETATM 2447  O   HOH A 429     -28.692  -9.242  57.896  1.00 49.31           O
HETATM 2448  O   HOH A 430     -11.939  15.977  52.102  1.00 47.83           O
HETATM 2449  O   HOH A 431     -36.176 -11.443  45.735  1.00 41.94           O
HETATM 2450  O   HOH A 432     -33.951  26.668  64.570  1.00 47.43           O
HETATM 2451  O   HOH A 433     -11.376   4.722  78.466  1.00 54.07           O
HETATM 2452  O   HOH A 434     -21.887   9.966  45.107  1.00 41.00           O
HETATM 2453  O   HOH A 435     -36.238  11.940  32.850  1.00 45.64           O
HETATM 2454  O   HOH A 436     -47.189 -13.765  54.665  1.00 55.09           O
HETATM 2455  O   HOH A 437     -29.663  21.018  64.739  1.00 34.81           O
HETATM 2456  O   HOH A 438     -50.213  -7.644  56.288  1.00 46.21           O
HETATM 2457  O   HOH A 439     -22.095   1.932  64.485  1.00 45.51           O
HETATM 2458  O   HOH A 440     -33.652  13.184  56.103  1.00 44.94           O
HETATM 2459  O   HOH A 441     -21.340  -2.148  71.596  1.00 52.72           O
HETATM 2460  O   HOH A 442     -28.890  10.992  47.714  1.00 38.14           O
HETATM 2461  O   HOH A 443     -26.992  -6.965  51.875  1.00 52.25           O
HETATM 2462  O   HOH A 444     -22.766  11.524  48.675  1.00 39.36           O
HETATM 2463  O   HOH A 445     -17.336  19.119  72.308  1.00 31.79           O
HETATM 2464  O   HOH A 446     -34.776   9.513  33.078  1.00 45.72           O
HETATM 2465  O   HOH A 447     -23.961  31.293  52.318  1.00 46.53           O
HETATM 2466  O   HOH A 448     -27.427  16.910  75.823  1.00 41.86           O
HETATM 2467  O   HOH A 449     -27.091   1.523  45.046  1.00 45.53           O
HETATM 2468  O   HOH A 450     -23.673  13.892  76.124  1.00 39.89           O
HETATM 2469  O   HOH A 451     -35.400   8.273  67.861  1.00 33.83           O
HETATM 2470  O   HOH A 452     -20.247  15.161  79.391  1.00 48.26           O
HETATM 2471  O   HOH A 453     -25.278  16.075  74.807  1.00 30.25           O
HETATM 2472  O   HOH A 454     -26.097   1.261  53.052  1.00 42.12           O
HETATM 2473  O   HOH A 455     -31.428   2.735  50.894  1.00 35.66           O
HETATM 2474  O   HOH A 456     -38.302   9.890  53.337  1.00 39.24           O
HETATM 2475  O   HOH A 457     -31.386  11.649  46.303  1.00 38.03           O
HETATM 2476  O   HOH A 458     -20.120   1.283  65.649  1.00 48.59           O
HETATM 2477  O   HOH A 459     -50.101  -3.677  39.790  1.00 49.35           O
HETATM 2478  O   HOH A 460     -48.679  -3.407  49.869  1.00 45.88           O
HETATM 2479  O   HOH A 461     -22.166  29.458  79.439  1.00 38.05           O
HETATM 2480  O   HOH A 462     -17.937  18.744  81.787  1.00 42.38           O
HETATM 2481  O   HOH A 463     -31.822  18.384  58.400  1.00 44.80           O
HETATM 2482  O   HOH A 464     -29.412   9.966  50.411  1.00 39.71           O
HETATM 2483  O   HOH A 465     -17.334   5.659  56.689  1.00 43.30           O
HETATM 2484  O   HOH A 466     -22.985  43.446  61.307  1.00 49.15           O
HETATM 2485  O   HOH A 467     -31.526  34.297  57.711  1.00 43.66           O
HETATM 2486  O   HOH A 468     -38.016   0.615  58.807  1.00 36.74           O
HETATM 2487  O   HOH A 469     -33.439  12.382  67.634  1.00 33.96           O
HETATM 2488  O   HOH A 470     -18.865  36.012  55.044  1.00 53.31           O
HETATM 2489  O   HOH A 471     -21.918   4.651  74.966  1.00 48.67           O
HETATM 2490  O   HOH A 472     -33.555  32.086  59.190  1.00 40.80           O
HETATM 2491  O   HOH A 473     -21.527  -3.206  47.118  0.50 43.20           O
HETATM 2492  O   HOH A 474     -31.617  20.843  66.736  1.00 39.00           O
HETATM 2493  O   HOH A 475     -23.984   4.531  39.514  1.00 49.49           O
HETATM 2494  O   HOH A 476     -50.778  -5.153  56.973  1.00 57.15           O
HETATM 2495  O   HOH A 477      -4.345  30.608  60.191  1.00 58.55           O
HETATM 2496  O   HOH A 478      -4.609  28.863  58.266  1.00 60.66           O
HETATM 2497  O   HOH A 479     -39.000  13.626  63.293  1.00 43.80           O
HETATM 2498  O   HOH A 480     -29.483   5.661  73.412  1.00 47.54           O
HETATM 2499  O   HOH A 481     -28.037   4.052  71.546  1.00 39.18           O
HETATM 2500  O   HOH A 482     -27.210  -9.165  50.684  1.00 51.98           O
HETATM 2501  O   HOH A 483     -36.053   4.725  65.510  1.00 47.25           O
HETATM 2502  O   HOH A 484     -17.642  38.250  54.728  1.00 63.41           O
CONECT 1120 2398
CONECT 2369 2370 2374
CONECT 2370 2369 2371 2372
CONECT 2371 2370
CONECT 2372 2370 2373
CONECT 2373 2372 2374
CONECT 2374 2369 2373 2375
CONECT 2375 2374 2376 2377
CONECT 2376 2375
CONECT 2377 2375
CONECT 2391 2408
CONECT 2397 2398 2401 2408
CONECT 2398 1120 2397 2399
CONECT 2399 2398 2400
CONECT 2400 2399 2409 2411
CONECT 2401 2397 2402
CONECT 2402 2401 2403 2406
CONECT 2403 2402 2404
CONECT 2404 2403 2405
CONECT 2405 2404 2406
CONECT 2406 2402 2405 2407
CONECT 2407 2406
CONECT 2408 2391 2397
CONECT 2409 2400
CONECT 2410 2411 2417
CONECT 2411 2400 2410
CONECT 2412 2413 2417
CONECT 2413 2412 2414
CONECT 2414 2413 2415
CONECT 2415 2414 2416
CONECT 2416 2415 2417
CONECT 2417 2410 2412 2416
MASTER      280    0    3   10   15    0    0    6 2500    2   32   25
END

`;
export default function FullScreenPDBViewer({ id, selectedData }) {
  const [viewer, setViewer] = useState(null);
  const [isCopy, setIsCopy] = useState(false);
  useEffect(() => {
    const vViewer = $3Dmol.createViewer(`viewer-container-${id}`);
    vViewer.addModel(pdbData, 'pdb');
    vViewer.setBackgroundColor(0xffffff);
    // viewer.rotate(90, 'y', 3000);
    vViewer.setStyle(
      // { stick: {}, cartoon: { colorscheme: 'Jmol' } },
      { cartoon: { color: 'spectrum' } },
    );
    // vViewer.setStyle({}, { cartoon: { color: 'spectrum' } });
    vViewer.zoomTo();
    vViewer.render();
    setViewer(vViewer);
  }, [pdbData]);

  const copyToClipboard = (text) => {
    setIsCopy(true);
    navigator.clipboard.writeText(text);
    Mixpanel.track('Antibody Engineering Smile Copied to Clipboard', {
      action: 'Antibody Engineering Copy smile button clicked',
      smile: text,
      module: 'Antibody Engineering',
    });
  };

  const zoomIn = () => {
    if (viewer) {
      viewer.zoom(1.5, 300);
    }
  };
  const zoomOut = () => {
    if (viewer) {
      viewer.zoom(0.5, 300);
    }
  };

  const reset = () => {
    if (viewer) {
      viewer.rotate(0, 'y', 300);
      viewer.translate(0, 0, 300);
      viewer.zoomTo();
    }
  };

  const downloadImg = () => {
    if (viewer) {
      const img = viewer.pngURI();
      const image = new Image();
      image.src = img;
      image.onload = () => {
        const a = document.createElement('a');
        a.href = image.src;
        a.download = 'img';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      };
    }
  };

  return (
    <div className="modal-container">
      <div className="header-container">
        <div className="header-name">{selectedData}</div>
        {/* <CloseOutlined onClick={closed} /> */}
      </div>
      <div className="interactive-container">
        <div className="info-component-row info-component-row-full">
          <div className="info-component">
            <div className="info-component-main">
              <div className="smile-component">
                <div className="info-component-value">
                  <Tooltip placement="topLeft" title={selectedData}>
                    {selectedData}
                  </Tooltip>
                </div>
                <Tooltip placement="topLeft" title={isCopy ? 'copied' : 'copy'}>
                  <div className="smile-copy-sec">
                    <div role="presentation" className="info-copy-btn" style={{ cursor: 'pointer' }} onClick={() => { copyToClipboard(selectedData); }} />
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
        <div
          className="btns-container"
        >
          <div role="presentation" style={{ zIndex: '99', fontSize: '28px' }} onClick={reset}>
            <ReloadOutlined />
          </div>
          <div role="presentation" style={{ zIndex: '99', fontSize: '28px' }} onClick={zoomIn}>
            <ZoomInOutlined />
          </div>
          <div role="presentation" style={{ zIndex: '99', fontSize: '28px' }} onClick={zoomOut}>
            <ZoomOutOutlined />
          </div>
          <div role="presentation" style={{ zIndex: '99', fontSize: '28px' }} onClick={downloadImg}>
            <DownloadOutlined />
          </div>

        </div>
      </div>
      <div className="full-screen-container-data">
        <div className={`viewer-container-${id}`} id={`viewer-container-${id}`} style={{ position: 'absolute', width: '100vw', height: '100vh' }} />
      </div>
    </div>
  );
}
