const tooltipData = {
  'Molecular Weight': 'Total mass of the compound; Optimal 100-600',
  'Log P': 'Logarithm of the partition coefficient of a solute between water and octanol; optimal <5',
  'Hydrogen Bond Acceptors': 'Number of hydrogen bond acceptor; Optimal 0-10',
  'Hydrogen Bond Donors': 'Number of hydrogen bond donors; Optimal 0-5',
  'Lipinski Rule of 5': 'Lipinski Rule of 5 states that orally administered drugs typically have:  Mw < 500, nON ≤ 10, nOHNH ≤ 5, LogP < 5\nIt is a poor absorption, if two properties are out of range.',
  QED: 'Potential drug-likeness of molecules, Value 1: a higher likelihood of drug-likeness,\nValue 0.5: less conducive to drug-likeness  \nValue 0: no drug-likeness',
  'Stereo Centers': 'Drugs are chiral carbon atoms that have four different groups attached to them.',
  TPSA: 'A measure of a molecule\'s polarity, which can influence solubility and absorption.  Optimal ≤ 140Å',
  'Aqueous Solubility': 'Molecule\'s ability to dissolve in water. Optimal <-5-5',
  Lipophilicity: 'Molecule\'s affinity for lipids or nonpolar environments.\nValue 1: Higher lipophilicity, \nValue 0: Less lipophilicity',
  'Hydration Free Energy': 'Energy required to transfer a molecule from a nonpolar environment to water; Optimal < -5-5',
  'Caco Permeability': 'Molecule\'s ability to pass through a cell monolayer; Optimal > -5 log unit',
  HIA: 'Drug\'s ability to be absorbed from the intestines into the bloodstream\nValue 1: Higher probability of absorption, \nValue 0: Less probability of absorption',
  'Oral Bioavailability': 'Drug that is absorbed from the gastrointestinal tract into the bloodstream after oral administration.\nValue 1: Higher probability of absorption, \nValue 0: Less probability of absorption',
  'PAMPA Permeability': 'Drug permeability across the cellular membrane\nValue 1: Higher probability that the compound has cellular membrane permeability \nValue 0: Less probability that the compound has cellular membrane permeability',
  'P-glycoprotein Inhibition': 'Ability of a molecule to block the activity of Pgp,\nValue 1: Higher probability of Pgp inhibitor, \nValue 0: Less probability of Pgp inhibitor',
  PPBR: 'Drug binds to proteins in the blood plasma. \nValue <85%: Larger proportion of the drug is bound to proteins and low rate means that a larger proportion is free in the blood.',
  Vdss: 'How widely a drug is distributed throughout the body after administration.\nHigh Vd (>1 L/kg)  Low Vd (<1 L/kg)   Very high Vd (>10 L/kg)',
  BBB: 'Highly selective membrane that separates the circulating blood from the extracellular fluid in the central nervous system.\nValue 1: probability of penetrate the blood-brain barrier, \nValue 0: Less probability of penetrate the blood-brain barrier',
  'Half Life': 'The time required for the concentration of a drug in the bloodstream to decrease by half.  Optimal 12-48 hr',
  'Drug Clearance': 'Rate at which a drug is removed from the body. High clearance rate is > 15 mL/min/kg; Moderate clearance rate is  5–15 mL/min/kg; Low clearance rate < 5 mL/min/kg.',
  'CYP1A2 Inhibition': 'The output value is the probability of being inhibitor\nValue 1: Inhibitor     \nValue 0: Non-Inhibitor',
  'CYP2C19 Inhibition': 'The output value is the probability of being inhibitor \nValue 1: Inhibitor     \nValue 0: Non-Inhibitor',
  'CYP2C9 Substrate': 'The output value is the probability of being substrate\nValue 1: Substrate     \nValue 0: Non-substrate',
  'CYP2C9 Inhibition': 'The output value is the probability of being inhibitor\nValue 1: Inhibitor     \nValue 0: Non-Inhibitor',
  'CYP2D6 Substrate': 'The output value is the probability of being substrate\nValue 1: Substrate     \nValue 0: Non-substrate',
  'CYP2D6 Inhibition': 'The output value is the probability of being inhibitor\nValue 1: Inhibitor    \nValue 0: Non-inhibitor',
  'CYP3A4 Substrate': 'The output value is the probability of being substrate\nValue 1: Substrate     \nValue 0: Non-substrate',
  'CYP3A4 Inhibition': 'The output value is the probability of being inhibitor\nValue 1: Inhibitor     \nValue 0: Non-Inhibitor',
  'hERG Block': 'The output value is the probability of being Inhibition of the hERG potassium channel, which can increase the risk of heart arrhythmias.\nValue 1: blocks;  \nValue 0:  Not blocks',
  'Clinical Toxicity': 'A measure of the likelihood that a drug will cause adverse side effects in humans.\nValue 0: Indicates a very low probability of clinical toxicity.     \nValue 1: Indicates a very high probability of clinical toxicity.',
  Mutagenicity: 'The ability of a substance to induce genetic mutations in an organism.\nValue 0: Indicates a very low probability of mutagenicity.   \nValue 1: Indicates a very high probability of mutagenicity.',
  DILI: 'A drug will cause damage to the liver.\nValue 0: Indicates a very low probability of DILI.   \nValue 1: Indicates a very high probability of DILI.',
  Carcinogenicity: 'Carcinogenicity is the ability of a substance to cause cancer.\nValue 0: Indicates a very low probability of carcinogenicity. \nValue 1: Indicates a very high probability of carcinogenicity.',
  'Acute Toxicity LD50': 'The most conservative dose that can lead to lethal adverse effects',
  'Skin Reaction': 'A wide range of adverse responses that can occur on the skin.\nValue 0: Indicates a very low probability of a skin reaction.  \nValue 1: Indicates a very high probability of a skin reaction.',
  'NR-AR': 'NR-AR is a nuclear receptor that binds to androgen hormones.\nValue 0: Indicates a very low probability of binding to NR-AR.  \nValue 1: Indicates a very high probability of binding to NR-AR.',
  'NR-AR-LBD': 'Nuclear Receptor Androgen Receptor Ligand Binding Domain is the region of the NR-AR protein that binds to androgen hormones and other ligands.\nValue 0: Indicates a very low probability of binding to NR-AR-LBD.  \nValue 1: Indicates a very high probability of binding to NR-AR-LBD.',
  'NR-AhR': 'Nuclear Receptor Androgen Receptor Ligand Binding Domain is the region of the NR-AR protein that binds to androgen hormones and other ligands.\nValue 0: Indicates a very low probability of binding to NR-AR-LBD.  \nValue 1: Indicates a very high probability of binding to NR-AR-LBD.',
  'NR-Aromatase': 'NR-Aromatase is an enzyme that converts testosterone into estradiol, a female sex hormone.\nValue 0: Indicates a very low probability of inhibiting NR-Aromatase. \nValue 1: Indicates a very high probability of inhibiting NR-Aromatase.',
  'NR-ER': 'NR-ER is a nuclear receptor that binds to estrogen hormones.\nValue 0: Indicates a very low probability of binding to NR-ER. \nValue 1: Indicates a very high probability of binding to NR-ER.',
  'NR-ER-LBD': 'NR-ER protein that binds to estrogen hormones and other ligands.\nValue 0: Indicates a very low probability of binding to NR-ER-LBD. Value 1: Indicates a very high probability of binding to NR-ER-LBD.',
  'NR-PPAR-gamma': 'Nuclear Receptor Peroxisome Proliferator-Activated Receptor Gamma is a key target for drugs used to treat type 2 diabetes, obesity, and other metabolic disorders.\nValue 0: Indicates a very low probability of binding to NR-PPAR-gamma. \nValue 1: Indicates a very high probability of binding to NR-PPAR-gamma.',
  'SR-ARE': 'Steroid Receptor-ARE (Androgen Response Element) is a DNA sequence that is recognized and bound by steroid receptors, such as the androgen receptor (AR), leading to the activation of genes involved in androgen-dependent processes.\nValue 0: Indicates a very low probability of binding to SR-ARE.  \nValue 1: Indicates a very high probability of binding to SR-ARE',
  'SR-ATAD5': 'Steroid Receptor-Associated Protein 5 is a protein that interacts with steroid receptors.\nValue 0: Indicates a very low probability of binding to SR-ATAD5.  \nValue 1: Indicates a very high probability of binding to SR-ATAD5.',
  'SR-HSE': 'Steroid Receptor-Heat Shock Element is a DNA sequence that is recognized and bound by steroid receptors, and plays a role in regulating gene expression.\nValue 0: Indicates a very low probability of binding to SR-HSE. \nValue 1: Indicates a very high probability of binding to SR-HSE.',
  'SR-MMP': 'Steroid Receptor-Matrix Metalloproteinase  is a complex that involves the interaction between a steroid receptor and a matrix metalloproteinase.\nValue 0: Indicates a very low probability of SR-MMP interaction. \nValue 1: Indicates a very high probability of SR-MMP interaction.',
  'SR-p53': 'Steroid Receptor-p53 interaction is a tumor suppressor protein that plays a crucial role in preventing cancer\nValue 0: Indicates a very low probability of SR-p53 interaction. \nValue 1: Indicates a very high probability of SR-p53 interaction',
};

export default tooltipData;
