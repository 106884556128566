import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import queryString from 'query-string';
import axios from 'axios';
import Rcookie from 'react-cookies';
import Header from '../../../../common/components/Header';
import useAppSelector from '../../../../utils/redux-hook';
import { generateResultsADCOutputAction } from './logic';
import Loader from '../../../../common/components/Loader';
import { terminalsPath } from '../../../../app/components/Card/data';
import Mixpanel from '../../../../utils/mixpanel';

function ADCOutputPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const payload = searchParams.get('payload');
  const service = searchParams.get('service');
  const dispatch = useDispatch();
  const [displayListData, setDisplayListData] = useState([]);
  const adcOutputResponse = useAppSelector((state) => state.adcOutputResponse);
  const [isPendingDownload, setIsPendingDownload] = useState(false);
  useEffect(() => {
    const request = {
      payload_name: payload,
      page_size: 100,
    };
    setTimeout(() => {
      dispatch(generateResultsADCOutputAction(queryString.stringify(request)));
    }, 50);
  }, []);
  useEffect(() => {
    if (adcOutputResponse.flag) {
      setDisplayListData(adcOutputResponse?.data);
    }
  }, [adcOutputResponse]);
  const displayStructure = (structureList) => structureList.map((item) => (
    <div className="structure-item" key={crypto.randomUUID()}>
      <div className="title">{item.linker_name}</div>
      <div className="percentage">{`${(item.linker_score * 100).toFixed(0)} %`}</div>
    </div>
  ));
  const displayDrugItemNew = (item) => (
    <div className="combinations-drugs-info">
      <div className="combinations-drugs-info-title">
        <div className="known-antibody">
          <div className="known-antibody-title">Known Antibody</div>
          <div className="known-antibody-value">{item.antibody}</div>
        </div>
        <div className="known-linker">
          <div className="known-antibody-title">Known Linker</div>
          <div className="known-antibody-value">{item.linker}</div>
        </div>
      </div>
      <div className="combinations-drugs-info-content">
        <div className="content-title">
          <div>Top 5 Structurally Similar Linkers</div>
          <div>Similarity Percentage</div>
        </div>
        <div className="content-list">{displayStructure(item.scored_linker_data)}</div>
      </div>
    </div>
  );

  const displayOutputList = () => displayListData?.map((item) => (
    <div className="combinations-output-item" key={crypto.randomUUID()}>
      <div className="combinations-item-content">
        <div className="combinations-drugs-list">{displayDrugItemNew(item)}</div>
      </div>
    </div>
  ));
  const downloadXLSFile = async () => {
    setIsPendingDownload(true);
    const headers = { Authorization: Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`) ? Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`) : '', 'Content-Type': 'blob', 'Access-Control-Allow-Origin': `${process.env.applicationUrl}` };

    try {
      const response = await axios({
        method: 'GET',
        url: `${process.env.apiUrl_adc}/v0/scoreResultDownload?payload_name=${payload}`,
        responseType: 'arraybuffer',
        headers,
      });
      setIsPendingDownload(false);
      const outputFilename = `${Date.now()}.xls`;

      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      throw Error(error);
    }
  };
  return (
    <div className="main-page main-page-modules">
      <Header className="header-home adc-header" logo="adc" />
      <div className="ct-home-page">
        <div className="main-heading">
          <div className="back-btn">
            <Button className="back-btn-icon" onClick={() => navigate(`${terminalsPath.metad3_adc}?service=${service}&payload=${payload}`)}>
              Back
            </Button>
          </div>
          <div className="main-title">{`Known pairs of antibody-linkers and structurally similar linkers for "${payload}"`}</div>
          <div className="main-description">Based on the Transformer model where higher the percentage, higher the similarity.</div>
          <div className="main-download-btn">
            <Button
              onClick={() => {
                setIsPendingDownload(true);
                downloadXLSFile();
                Mixpanel.track('ADC Output Downloaded', {
                  action: 'ADC Download output button clicked',
                  service,
                  payload,
                  module: 'ADC',
                });
              }}
              disabled={isPendingDownload}
            >
              {`${isPendingDownload ? 'Downloading...' : 'Download Output'}`}
            </Button>
          </div>
        </div>
        <Loader loading={adcOutputResponse.loading} error={adcOutputResponse.error} width="100%" height="500px">
          <div className="dc-output-list adc-content">{displayOutputList()}</div>
        </Loader>
      </div>
    </div>
  );
}

export default ADCOutputPage;
