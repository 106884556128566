import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../utils';

// get job id details
const GENERATE_ANALYSIS = 'GENERATE_ANALYSIS';
const GENERATE_ANALYSIS_SUCCESS = 'GENERATE_ANALYSIS_SUCCESS';
const GENERATE_ANALYSIS_FAILURE = 'GENERATE_ANALYSIS_FAILURE';
const GENERATE_ANALYSIS_RESET = 'GENERATE_ANALYSIS_RESET';

export const generateAnalysisAction = createAction(GENERATE_ANALYSIS);
export const generateAnalysisResetAction = createAction(GENERATE_ANALYSIS_RESET);

const generateAnalysisSuccess = createAction(GENERATE_ANALYSIS_SUCCESS);
const generateAnalysisFailure = createAction(GENERATE_ANALYSIS_FAILURE);

export const generateAnalysisEpic = (actions$) => actions$.pipe(
  ofType(GENERATE_ANALYSIS),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_drug_reform}/generateAnalysis?${action.payload.params}`, 'POST', action.payload.body)).pipe(
    map((res) => generateAnalysisSuccess(res)),
    catchError((err) => of(generateAnalysisFailure(err))),
  )),
);

export const generateAnalysisReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(generateAnalysisAction, (state) => {
        state.loading = true;
      })
      .addCase(generateAnalysisSuccess, (state, action) => {
        state.data = { ...action.payload.response };
        state.loading = false;
        state.flag = true;
      })
      .addCase(generateAnalysisFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(generateAnalysisResetAction, (state) => {
        state.data = [];
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);

// generate lead
const GENERATE_ADMET_DR = 'GENERATE_ADMET_DR';
const GENERATE_ADMET_DR_SUCCESS = 'GENERATE_ADMET_DR_SUCCESS';
const GENERATE_ADMET_DR_FAILURE = 'GENERATE_ADMET_DR_FAILURE';
const GENERATE_ADMET_DR_RESET = 'GENERATE_ADMET_DR_RESET';

export const generateAdmetDRAction = createAction(GENERATE_ADMET_DR);
export const generateAdmetDRResetAction = createAction(GENERATE_ADMET_DR_RESET);

const generateAdmetDRSuccess = createAction(GENERATE_ADMET_DR_SUCCESS);

const generateAdmetDRFailure = createAction(GENERATE_ADMET_DR_FAILURE);
export const generateAdmetDREpic = (actions$) => actions$.pipe(
  ofType(GENERATE_ADMET_DR),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_sar}/generateLead?${action.payload.params}`, 'POST', action.payload.body)).pipe(
    map((res) => generateAdmetDRSuccess(res)),
    catchError((err) => of(generateAdmetDRFailure(err))),
  )),
);

export const generateAdmetDRReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(generateAdmetDRAction, (state) => {
        state.loading = true;
      })
      .addCase(generateAdmetDRSuccess, (state, action) => {
        state.data = { ...action.payload.response.data };
        state.loading = false;
        state.flag = true;
      })
      .addCase(generateAdmetDRFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(generateAdmetDRResetAction, (state) => {
        state.data = {};
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);

// generate indications
const GENERATE_INDICATIONS_DR = 'GENERATE_INDICATIONS_DR';
const GENERATE_INDICATIONS_DR_SUCCESS = 'GENERATE_INDICATIONS_DR_SUCCESS';
const GENERATE_INDICATIONS_DR_FAILURE = 'GENERATE_INDICATIONS_DR_FAILURE';
const GENERATE_INDICATIONS_DR_RESET = 'GENERATE_INDICATIONS_DR_RESET';

export const generateIndicationsDrugReformAction = createAction(GENERATE_INDICATIONS_DR);
export const generateIndicationsDrugReformResetAction = createAction(GENERATE_INDICATIONS_DR_RESET);

const generateIndicationsDrugReformSuccess = createAction(GENERATE_INDICATIONS_DR_SUCCESS);

const generateIndicationsDrugReformFailure = createAction(GENERATE_INDICATIONS_DR_FAILURE);
export const generateIndicationsDrugReformEpic = (actions$) => actions$.pipe(
  ofType(GENERATE_INDICATIONS_DR),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_iep}/generateIndications?${action.payload.params}`, 'POST', action.payload.body)).pipe(
    map((res) => generateIndicationsDrugReformSuccess(res)),
    catchError((err) => of(generateIndicationsDrugReformFailure(err))),
  )),
);

export const generateIndicationsDrugReformReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(generateIndicationsDrugReformAction, (state) => {
        state.loading = true;
      })
      .addCase(generateIndicationsDrugReformSuccess, (state, action) => {
        state.data = { ...action.payload.response.data };
        state.loading = false;
        state.flag = true;
      })
      .addCase(generateIndicationsDrugReformFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(generateIndicationsDrugReformResetAction, (state) => {
        state.data = {};
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);
