/* eslint-disable */
import React, { useEffect } from 'react';
import useAppSelector from '../../../../utils/redux-hook';

function WorkspaceLCOInputDataTable({ tableData }) {
  const data = tableData;
  const getLcoTableInputDataResponse = useAppSelector((state) => state.getLcoTableInputDataResponse);



  function tableRowText(obj, index) {
    let str = obj['Light chain sequence'];
    const arr = [];
    let s = '';

    for (let i = 0; i < str.length; i += 1) {
      if (i !== 0 && i % 10 === 0) {
        arr.push(arr[arr.length - 1]);
        arr.push(arr[arr.length - 1]);
        s += '  ';
      }
      arr.push(0);
      s += str[i];
    }

    const charArr = [];
    for (let i = 0; i < arr.length; i += 1) {
      if (arr[i] !== 0) charArr.push(<span style={{ fontFamily: 'courier-prime', whiteSpace: 'pre', padding: '4px 0' }}>{s[i] === ' ' ? ' ' : s[i]}</span>);
      else charArr.push(<span style={{ color: 'black', fontFamily: 'courier-prime', whiteSpace: 'pre', padding: '4px 0' }}>{s[i] === ' ' ? ' ' : s[i]}</span>);
    }
    return (
      <div
        role="presentation"
        style={{ display: 'flex', flexDirection: 'row', backgroundColor: obj?.color }}
        className={`sequ-title ${false && 'active'}`}
        onClick={() => {
          if (selectedRows.includes(index)) {
            setSelectedRows(selectedRows.filter((i) => i !== index));
          } else {
            setSelectedRows([...selectedRows, index]);
          }
        }}
      >
        {charArr}
      </div>
    );
  }

  function sortData(arr, key, order = 'asc') {
    if (!Array.isArray(arr) || arr.length === 0) {
      return []; // Return empty array for invalid input
    }

    const sortedArr = [...arr]; // Create a copy to avoid modifying the original array

    sortedArr.sort((a, b) => {
      const aValue = a[key];
      const bValue = b[key];

      if (order === 'asc') {
        if (typeof aValue === 'string' && typeof bValue === 'string') {
          return aValue.localeCompare(bValue); // String comparison
        }
        return aValue - bValue; // Numeric or other type comparison
      } else if (order === 'desc') {
        if (typeof aValue === 'string' && typeof bValue === 'string') {
          return bValue.localeCompare(aValue); // String comparison (descending)
        }
        return bValue - aValue; // Numeric or other type comparison (descending)
      } else {
        return 0; // Invalid order
      }
    });
    setData((i) => sortedArr);
  }

  function tableRowScale(str) {
    let s = '           ';
    let len = str.length + (str.length / 10) * 2;
    for (let i = 0; i < len; i += 1) {
      if (i !== 0 && i % 10 === 0) {
        let ss = i.toString() + '           ';
        s += ss.substring(0, 12);
      }
    }
    return (
      <pre
        style={{
          height: '20px',
          color: 'black',
          whiteSpace: 'pre',
          fontFamily: 'courier-prime',
        }}
      >
        {s}
      </pre>
    );
  }

  return (
    <div className="util-home-page" style={{ padding: '20px' }}>
      <div className="data-container">
        <div className={`data-row`}>
          <div className="data-table-container">
            <div className="antibody-engineering-output-main">
              <div className="main-title">{`List of input sequences(${getLcoTableInputDataResponse?.data?.data?.length})`}</div>
              <div className="legends-row">
                <div className="legend-duplicate">
                  <div className="legend-color-duplicate" />
                  <div className="legend-title">{`Duplicate(${getLcoTableInputDataResponse?.data?.stats?.duplicates})`}</div>
                </div>
                <div className="legend-duplicate-different-value">
                  <div className="legend-color-duplicate-different-value" />
                  <div className="legend-title">{`Duplicate (different activity values)(${getLcoTableInputDataResponse?.data?.stats?.diffDuplicates})`}</div>
                </div>
                <div className="legend-incorrect">
                  <div className="legend-color-incorrect" />
                  <div className="legend-title">{`NA(${getLcoTableInputDataResponse?.data?.stats?.incorrectValues})`}</div>
                </div>
              </div>
            </div>
            <div className="raw-data-table-main">
              <div className="raw-table-container">
                <div className="raw-table-headings header-value-row">
                  <div className="table-heading-left">
                    <div className="title">Light chain sequences</div>
                    <div className="info-icon" />
                  </div>
                  <div className="table-heading-right">
                    <div className="table-heading-parameter">
                      <div style={{ display: 'flex' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                          <div className="title">Binding Affinity</div>
                          <span style={{ fontSize: '13px' }}>(Normailise values)</span>
                        </div>
                        <div className="info-icon" />
                      </div>
                    </div>
                  </div>
                </div>
                {data && (
                  <div className="raw-table-body">
                    {/* <div className="raw-table-radio">
                      <div style={{ height: '20px', backgroundColor: '#fff' }} />
                      {data.map((item, index) => (
                        <Checkbox
                          checked={selectedRows.includes(index)}
                          value={index}
                          style={{ 'background-color': item?.color }}
                          className={`radio ${selectedRows?.seq_id === item['seq_id'] && 'active'}`}
                          onClick={() => {
                            if (selectedRows.includes(index)) {
                              setSelectedRows(selectedRows.filter((i) => i !== index));
                            } else {
                              setSelectedRows([...selectedRows, index]);
                            }
                          }}
                        />
                      ))}
                    </div> */}
                    <div className="raw-table-seq" style={{ minWidth: '70%' }}>
                      {data && tableRowScale(data[0]['Light chain sequence'])}
                      {data.map((item, index) => tableRowText(item, index))}
                    </div>
                    <div className="raw-table-parameters">
                      <div style={{ width: '100%' }}>
                        <div style={{ height: '20px', backgroundColor: '#fff' }} />
                        {data.map((item) => (
                          <div
                            // style={{ 'background-color': item.color }}
                            style={{ 'background-color': item?.color }}
                            className={`table-row-right ${false && 'active'}`}
                          >
                            {`${item['Binding affinity'] === '-' ? '-' : (item['Binding affinity'] * 100).toFixed(0)} ${item['Parent'] === 'yes' ? '(Parent)' : ''}`}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
                <div />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

WorkspaceLCOInputDataTable.propTypes = {
  // setPageIndex: PropTypes.func.isRequired,
};

export default WorkspaceLCOInputDataTable;
