import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../utils';

// get hctp drug input suggestions
const GET_HCTP_DRUG_INPUT_SUGGEST = 'GET_HCTP_DRUG_INPUT_SUGGEST';
const GET_HCTP_DRUG_INPUT_SUGGEST_SUCCESS = 'GET_HCTP_DRUG_INPUT_SUGGEST_SUCCESS';
const GET_HCTP_DRUG_INPUT_SUGGEST_FAILURE = 'GET_HCTP_DRUG_INPUT_SUGGEST_FAILURE';

export const getHCTPDrugInputSuggestAction = createAction(GET_HCTP_DRUG_INPUT_SUGGEST);

const getHCTPDrugInputSuggestSuccess = createAction(GET_HCTP_DRUG_INPUT_SUGGEST_SUCCESS);

const getHCTPDrugInputSuggestFailure = createAction(GET_HCTP_DRUG_INPUT_SUGGEST_FAILURE);
export const getHCTPDrugInputSuggestEpic = (actions$) => actions$.pipe(
  ofType(GET_HCTP_DRUG_INPUT_SUGGEST),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_ctp}/autoSuggest?${action.payload}`, 'get')).pipe(
    map((res) => getHCTPDrugInputSuggestSuccess(res)),
    catchError((err) => of(getHCTPDrugInputSuggestFailure(err))),
  )),
);

export const getHCTPDrugInputSuggestReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(getHCTPDrugInputSuggestAction, (state) => {
        state.loading = true;
      })
      .addCase(getHCTPDrugInputSuggestSuccess, (state, action) => {
        state.data = { ...action.payload.response.data };
        state.loading = false;
        state.flag = true;
      })
      .addCase(getHCTPDrugInputSuggestFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);

// get hctp indications input suggestions
const GET_HCTP_INDICATION_INPUT_SUGGEST = 'GET_HCTP_INDICATION_INPUT_SUGGEST';
const GET_HCTP_INDICATION_INPUT_SUGGEST_SUCCESS = 'GET_HCTP_INDICATION_INPUT_SUGGEST_SUCCESS';
const GET_HCTP_INDICATION_INPUT_SUGGEST_FAILURE = 'GET_HCTP_INDICATION_INPUT_SUGGEST_FAILURE';

export const getHCTPIndicationInputSuggestAction = createAction(GET_HCTP_INDICATION_INPUT_SUGGEST);

const getHCTPIndicationInputSuggestSuccess = createAction(GET_HCTP_INDICATION_INPUT_SUGGEST_SUCCESS);

const getHCTPIndicationInputSuggestFailure = createAction(GET_HCTP_INDICATION_INPUT_SUGGEST_FAILURE);
export const getHCTPIndicationInputSuggestEpic = (actions$) => actions$.pipe(
  ofType(GET_HCTP_INDICATION_INPUT_SUGGEST),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_ctp}/autoSuggest?${action.payload}`, 'get')).pipe(
    map((res) => getHCTPIndicationInputSuggestSuccess(res)),
    catchError((err) => of(getHCTPIndicationInputSuggestFailure(err))),
  )),
);

export const getHCTPIndicationInputSuggestReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(getHCTPIndicationInputSuggestAction, (state) => {
        state.loading = true;
      })
      .addCase(getHCTPIndicationInputSuggestSuccess, (state, action) => {
        state.data = { ...action.payload.response.data };
        state.loading = false;
        state.flag = true;
      })
      .addCase(getHCTPIndicationInputSuggestFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);
