/* eslint-disable */
import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { useDispatch } from 'react-redux';
import useAppSelector from '../../../../../utils/redux-hook';
import { ctpKolAction } from './logic';
import queryString from 'query-string';
import Loader from '../../../../../common/components/Loader';
import PropTypes from 'prop-types';
import { XFilled } from '@ant-design/icons';

function SlopeGraph({ indicationName }) {
  const [data, setData] = useState([]);
  const [authorsName, setAuthorsName] = useState([]);
  const [seriesData, setSeriesData] = useState([]);
  const [highest, setHighest] = useState([0, 0, 0, 0, 0, 0]);
  const [affiliations, setAffiliations] = useState({});
  const dispatch = useDispatch();
  const ctpKolResponse = useAppSelector((state) => state.ctpKolResponse);

  useEffect(() => {
    const body = {
      indication_name: indicationName,
    };
    dispatch(ctpKolAction(queryString.stringify(body)));
  }, [indicationName]);

  useEffect(() => {
    if (ctpKolResponse.flag) {
      setData(ctpKolResponse.data);
    }
  }, [ctpKolResponse]);

  function parsingData(data) {
    let series = [];
    let authors = [];
    let findHighest = [5, 5, 5, 5, 5, 5];
    const storeAffiliations = {};
    let authorsName = ['', '', '', '', '', ''];
    for (let i = 0; i < data.length; i++) {
      authorsName[i] = data[i].name;
      let author = [0, 0, 0, 0, 0, 0];
      for (let j = 0; j < data[i]?.kol_details?.score_profile?.length; j++) {
        let assetClass = data[i]?.kol_details?.score_profile[j];
        if (assetClass['asset_class'] === 'Publications') {
          author[0] = assetClass['doc_count'];
          findHighest[0] = Math.max(findHighest[0], assetClass['doc_count']);
        }
        if (assetClass['asset_class'] === 'Clinical Trials') {
          author[1] = assetClass['doc_count'];
          findHighest[1] = Math.max(findHighest[1], assetClass['doc_count']);
        }
        if (assetClass['asset_class'] === 'Congress Presentations') {
          author[2] = assetClass['doc_count'];
          findHighest[2] = Math.max(findHighest[2], assetClass['doc_count']);
        }
        if (assetClass['asset_class'] === 'Guidelines') {
          author[3] = assetClass['doc_count'];
          findHighest[3] = Math.max(findHighest[3], assetClass['doc_count']);
        }
        if (assetClass['asset_class'] === 'Hospitals') {
          author[4] = assetClass['doc_count'];
          findHighest[4] = Math.max(findHighest[4], assetClass['doc_count']);
        }
        if (assetClass['asset_class'] === 'Advocacy') {
          author[5] = assetClass['doc_count'];
          findHighest[5] = Math.max(findHighest[5], assetClass['doc_count']);
        }
      }
      authors.push(author);
      let authorSeries = {
        name: data[i].name,
        type: 'parallel',
        lineStyle: lineStyle,
        data: [author],
      };
      storeAffiliations[data[i].name] = [data[i].affiliation, data[i].country];
      series.push(authorSeries);
    }
    setHighest(findHighest);
    setAffiliations(storeAffiliations);
    setAuthorsName(authorsName);
    setSeriesData(series);
  }

  useEffect(() => {
    parsingData(data);
  }, [data]);

  var option;

  var schema = [
    { name: 'Clinical Trials', index: 0, text: 'Clinical Trials' },
    { name: 'Publications', index: 1, text: 'Publications' },
    { name: 'Congresses', index: 2, text: 'Congresses' },
    { name: 'Guidelines', index: 3, text: 'Guidelines' },
    { name: 'Advocacy', index: 4, text: ' Advocacy' },
    { name: 'Hospitals', index: 5, text: 'Hospitals' },
  ];
  var lineStyle = {
    width: 2.5,
    opacity: 1,
  };
  option = {
    color: ['#D07C76', '#FBBC08', '#52B956', '#876BF8', '#35D8EE'],
    tooltip: {
      extraCssText: 'box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);',
      borderWidth: 0,
      position: function (point, params, dom, rect, size) {
        // fixed at top
        return [point[0], point[1] - 70];
      },
      formatter: (params) => {
        return '<span style="color:black;font-size:14px;font-family:Inter">' + `${params.seriesName}` + `<br/>` + ` <span style="color:grey;font-size:12px">` + `${affiliations[params.seriesName][0]}, ${affiliations[params.seriesName][1]}` + `</span></span>`;
      },
    },
    parallelAxis: [
      {
        dim: 0,
        name: schema[0].text,
        nameLocation: 'start',
        max: highest[0],
      },
      { dim: 1, name: schema[1].text, nameLocation: 'start', max: highest[1] },
      { dim: 2, name: schema[2].text, nameLocation: 'start', max: highest[2] },
      { dim: 3, name: schema[3].text, nameLocation: 'start', max: highest[3] },
      { dim: 4, name: schema[4].text, nameLocation: 'start', max: highest[4] },
      { dim: 5, name: schema[5].text, nameLocation: 'start', max: highest[5] },
    ],
    parallel: {
      top: '5%',
      left: '5%',
      right: '5%',
      bottom: '15%',
      parallelAxisDefault: {
        type: 'value',
        nameLocation: 'end',
        nameGap: 20,
        nameTextStyle: {
          fontFamily: 'Inter',
          fontSize: '16px',
        },
        axisLine: {
          lineStyle: {
            color: '#121417',
          },
        },
        axisTick: {
          lineStyle: {
            color: '#121417',
          },
        },
        splitLine: {
          show: false,
        },
        axisLabel: {},
      },
    },
    series: seriesData,
  };

  const noDataFound = () => {
    return (
      <div className="no-data-found" style={{ color: '#000' }}>
        No data found
      </div>
    );
  };

  return (
    <Loader loading={ctpKolResponse.loading}>
      <div className="mixed-chart">
        {data.length > 0 && (
          <div
            className="slopegraph-header"
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-evenly',
              flexDirection: 'row',
            }}
          >
            <div className="slopegraph-title">
              <XFilled />
              <div className="slopegraph-trialtype">{authorsName[0]}</div>
            </div>
            <div className="slopegraph-title">
              <XFilled />
              <div className="slopegraph-trialtype">{authorsName[1]}</div>
            </div>
            <div className="slopegraph-title">
              <XFilled />
              <div className="bubble-charts-trialtype">{authorsName[2]}</div>
            </div>
            <div className="slopegraph-title">
              <XFilled />
              <div className="bubble-charts-trialtype">{authorsName[3]}</div>
            </div>
            <div className="slopegraph-title">
              <XFilled />
              <div className="bubble-charts-trialtype">{authorsName[4]}</div>
            </div>
          </div>
        )}
        {data.length > 0 ? <ReactECharts option={option} style={{ height: '400px', width: '100%' }} /> : noDataFound()}
      </div>
    </Loader>
  );
}

SlopeGraph.propTypes = {
  indicationName: PropTypes.string.isRequired,
};

export default SlopeGraph;
