import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
// import apiCall from '../../../../../utils';
import apiCall, { apiCallwithoutCT } from '../../../../../utils';

const AE_GENERATE_SEQUENCES = 'AE_GENERATE_SEQUENCES';
const AE_GENERATE_SEQUENCES_SUCCESS = 'AE_GENERATE_SEQUENCES_SUCCESS';
const AE_GENERATE_SEQUENCES_FAILURE = 'AE_GENERATE_SEQUENCES_FAILURE';
const AE_GENERATE_SEQUENCES_RESET = 'AE_GENERATE_SEQUENCES_RESET';

export const aeGenerateSequencesAction = createAction(AE_GENERATE_SEQUENCES);
export const aeGenerateSequencesResetAction = createAction(AE_GENERATE_SEQUENCES_RESET);

const aeGenerateSequencesSuccess = createAction(AE_GENERATE_SEQUENCES_SUCCESS);
const aeGenerateSequencesFailure = createAction(AE_GENERATE_SEQUENCES_FAILURE);

export const aeGenerateSequencesEpic = (actions$) => actions$.pipe(
  ofType(AE_GENERATE_SEQUENCES),
  switchMap((action) => ajax(apiCallwithoutCT(`${process.env.apiUrl_ae}/generateSequences?${action.payload.params}`, 'POST', action.payload.body)).pipe(
    map((res) => aeGenerateSequencesSuccess(res)),
    catchError((err) => of(aeGenerateSequencesFailure(err))),
  )),
);

export const aeGenerateSequencesReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(aeGenerateSequencesAction, (state) => {
        state.loading = true;
      })
      .addCase(aeGenerateSequencesSuccess, (state, action) => {
        state.data = { ...action.payload.response };
        state.loading = false;
        state.flag = true;
      })
      .addCase(aeGenerateSequencesFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(aeGenerateSequencesResetAction, (state) => {
        state.data = {};
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);

const RAW_DATA_ANALYSIS = 'RAW_DATA_ANALYSIS';
const RAW_DATA_ANALYSIS_SUCCESS = 'RAW_DATA_ANALYSIS_SUCCESS';
const RAW_DATA_ANALYSIS_FAILURE = 'RAW_DATA_ANALYSIS_FAILURE';
const RAW_DATA_ANALYSIS_RESET = 'RAW_DATA_ANALYSIS_RESET';

export const getRawDataAnalysisAction = createAction(RAW_DATA_ANALYSIS);
export const getRawDataAnalysisResetAction = createAction(RAW_DATA_ANALYSIS_RESET);

const getRawDataAnalysisSuccess = createAction(RAW_DATA_ANALYSIS_SUCCESS);
const getRawDataAnalysisFailure = createAction(RAW_DATA_ANALYSIS_FAILURE);

export const getRawDataAnalysisEpic = (actions$) => actions$.pipe(
  ofType(RAW_DATA_ANALYSIS),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_ae}/getRawDataAnalysis?${action.payload}`, 'get')).pipe(
    map((res) => getRawDataAnalysisSuccess(res)),
    catchError((err) => of(getRawDataAnalysisFailure(err))),
  )),
);

export const getRawDataAnalysisReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(getRawDataAnalysisAction, (state) => {
        state.loading = true;
      })
      .addCase(getRawDataAnalysisSuccess, (state, action) => {
        state.data = { ...action.payload.response };
        state.loading = false;
        state.flag = true;
      })
      .addCase(getRawDataAnalysisFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(getRawDataAnalysisResetAction, (state) => {
        state.data = [];
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);
