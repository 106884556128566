/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import * as d3 from 'd3';
import {
  GeoJSON,
  // TileLayer,
  MapContainer,
  Tooltip,
  CircleMarker,
  Popup,
} from 'react-leaflet';
import { geoCtAction, geoKolAction } from './logic';
import { getCenterOfGeoJson } from './MapUtils';
import worldData from './worlddata.json';
import './leaflet.scss';
import useAppSelector from '../../../../../utils/redux-hook';
import Loader from '../../../../../common/components/Loader';
import { ColorLegend } from './ColorLegend';

function GlobalMap({ indicationName, type }) {
  const dispatch = useDispatch();
  const geoCtData = useAppSelector((state) => state.geoCtResponse);
  const geoKolData = useAppSelector((state) => state.geoKolResponse);
  const [countriesCtData, setCountriesCtData] = useState([]);
  const [countriesKolData, setCountriesKolData] = useState([]);

  const [valueRange, setValueRange] = useState({ min: undefined, max: undefined });
  const popupData = useRef([]);
  const markerRef = useRef();
  const COUNTRY_VIEW_ID = 'world-data';

  const bounds = [
    [-90, -180],
    [90, 180],
  ];

  const mapOptions = {
    worldCopyJump: false, // Prevent map wrapping
    maxBounds: bounds, // Set the map bounds
    maxBoundsViscosity: 1.0, // Enforce the bounds strictly
    zoomSnap: 0.1,
  };
  const mapCenter = getCenterOfGeoJson(worldData);
  const mapRef = useRef(null);
  const geoJsonRef = useRef(null);

  // useEffect(() => {
  //   if (mapRef.current && geoJsonRef.current) {
  //     mapRef.current.leafletElement.fitBounds(geoJsonRef.current.leafletElement.getBounds());
  //   }
  // });

  useEffect(() => {
    // make indication dynamic
    if (type === 'sites') {
      dispatch(
        geoCtAction(
          queryString.stringify({
            indication_name: indicationName,
          }),
        ),
      );
    }
    if (type === 'kols') {
      dispatch(
        geoKolAction(
          queryString.stringify({
            indication_name: indicationName,
          }),
        ),
      );
    }
  }, [type, indicationName]);

  useEffect(() => {
    if (geoCtData.flag) {
      popupData.current = [];
      if (geoCtData?.data?.countries) {
        setCountriesCtData(geoCtData?.data?.countries);
      }
      const countryData = worldData.features;
      countryData.map((e) => {
        if (Object.keys(geoCtData?.data?.countries).includes(e?.properties?.NAME_1)) {
          if (geoCtData?.data?.countries[e?.properties?.NAME_1]?.sites_count) {
            popupData.current.push({ ...geoCtData?.data?.countries[e?.properties?.NAME_1], country: e?.properties?.NAME_1, center: getCenterOfGeoJson(e) });
          }
        }
        return null;
      });
      if (geoCtData?.data?.max_count) {
        setValueRange({ max: geoCtData?.data?.max_count, min: geoCtData?.data?.min_count });
      }
    }
  }, [geoCtData]);

  useEffect(() => {
    if (geoKolData.flag) {
      popupData.current = [];
      if (geoKolData?.data?.countries) {
        setCountriesKolData(geoKolData?.data?.countries);
      }
      const countryData = worldData.features;
      countryData.map((e) => {
        if (Object.keys(geoKolData?.data?.countries).includes(e?.properties?.NAME_1)) {
          if (geoKolData?.data?.countries[e?.properties?.NAME_1]?.sites_count) {
            popupData.current.push({ ...geoKolData?.data?.countries[e?.properties?.NAME_1], country: e?.properties?.NAME_1, center: getCenterOfGeoJson(e) });
          }
        }
        return null;
      });
      if (geoKolData?.data?.max_count) {
        setValueRange({ max: geoKolData?.data?.max_count, min: geoKolData?.data?.min_count });
      }
    }
  }, [geoKolData]);

  function getColor(d) {
    // const obj = rangeObj.find((itm) => ((itm.lowerBound <= d) && (d <= itm.lowerBound)))
    if (d < 1) return '#CDDCFB';
    if (d < 100) return '#93B2F0';
    if (d > 100) return '#3B76EF';
    return '#CDDCFB';
  }
  // const processName = (e) => e.toLowerCase().replaceAll(' ', '').replaceAll('-', '');

  function geoJSONStyle(e) {
    if (type === 'sites') {
      if (Object.keys(countriesCtData).includes(e?.properties?.NAME_1)) {
        return {
          // color: '#1f2021',
          weight: 1,
          fillOpacity: 0.7,
          fillColor: getColor(countriesCtData[e?.properties?.NAME_1]?.sites_count),
        };
      }
    }
    if (type === 'kols') {
      if (Object.keys(countriesKolData).includes(e?.properties?.NAME_1)) {
        return {
          // color: '#1f2021',
          weight: 1,
          fillOpacity: 0.7,
          fillColor: getColor(countriesKolData[e?.properties?.NAME_1]?.sites_count),
        };
      }
    }
    return {
      // color: '#CDDCFB',
      weight: 1,
      // fillOpacity: 0,
      fillOpacity: 0.7,
      fillColor: '#3B76EF',
    };
  }

  const noDataFound = () => {
    return (
      <div className="no-data-found" style={{ color: '#000' }}>
        No data found
      </div>
    );
  };

  const popUpData = (itm) => {
    return (
      <Popup className="pimap-main pimap-main-anlysis map-center" minWidth={200} closeButton={false}>
        <div className="popUp-first" tabIndex={0} role="button" onKeyDown={null}>
          <div className="popup-container">
            <div className="popup-country-name">{itm.country}</div>
            <div className="popup-count-section">
              <div className="popup-count-number">{type === 'sites' ? itm.sites_count : itm.kol_count}</div>
              <div className="popup-count-text">{type === 'sites' ? 'Sites' : 'KOLs'}</div>
            </div>
            <div className="popup-count-section">
              <div className="popup-count-number">{itm.trial_count}</div>
              <div className="popup-count-text">Trials</div>
            </div>
          </div>
        </div>
      </Popup>
    );
  };

  const colorScale = d3.scaleLinear().domain([valueRange?.min, valueRange?.max]).range(['#CDDCFB', '#3B76EF']);

  return (
    <Loader loading={geoCtData.loading || geoKolData.loading}>
      <div className="map-container">
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <MapContainer className="map" center={mapCenter} zoom={2} minZoom={2} ref={mapRef} zoomControl={false} {...mapOptions}>
          {/* <TileLayer
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}"
            // attribution= downloadPreviewModalOpen ? '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            // attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            // url="https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}"
            minZoom={2}
            maxZoom={19}
          /> */}
          <GeoJSON
            data={worldData}
            key={COUNTRY_VIEW_ID}
            style={(e) => geoJSONStyle(e)}
            // onEachFeature={currentView !== 'state' ? onEachFeature : null}
            ref={geoJsonRef}
            id={COUNTRY_VIEW_ID}
          />
          {popupData.current.length
            ? popupData?.current.map(
                (item) =>
                  (item?.sites_count || item?.kol_count) && (
                    <CircleMarker center={item?.center} markerRef={markerRef} fillOpacity={0.5} className="circle-marker-map" radius={7} key={type === 'sites' ? item?.sites_count : item?.kol_count}>
                      <Tooltip direction="top" permanent interactive className="run-analysis-map-tooltip">
                        <b>{type === 'sites' ? item?.sites_count : item?.kol_count}</b>
                      </Tooltip>
                      {(type === 'sites' ? item?.sites_count : item?.kol_count) && popUpData(item)}
                    </CircleMarker>
                  ),
              )
            : null}
          <div className="color-legend-container">
            <div className="n-number-section">
              <div className="n-number-section-icon" />
              <div className="n-number-section-text">
                {' '}
                - Number of <div className="n-number-section-text-com"> {`Clinical Trial ${type}`}</div>
              </div>
            </div>
            <ColorLegend width={400} height={100} colorScale={colorScale} />
          </div>
        </MapContainer>
      </div>
      )
    </Loader>
  );
}

GlobalMap.propTypes = {
  indicationName: PropTypes.string.isRequired,
};

export default GlobalMap;
