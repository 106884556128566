/* eslint-disable */
import { Button, Radio, Space } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import queryString from "query-string";
import { useNavigate, useSearchParams } from "react-router-dom";
import MolecularStructure from "../../components/AdmetProperties/MolecularStructure";
import ParameterGraphicalViewContanier from "../../components/AdmetProperties/ParameterGraphicalView";
import ParameterValuesContainer from "../../container/AdmetProperties/ParameterValuesContainer";
import CompareMoleculeView from "../../components/AdmetProperties/CompareMoleculeView";
import useAppSelector from "../../../../utils/redux-hook";
import { getAdmetOutputAction } from "./logic";
import Loader from "../../../../common/components/Loader";
import { terminalsPath } from "../../../../app/components/Card/data";
import downloadXLSFile from "../../components/XLSXDownloader";
import OverviewValuesContainer from "../../container/AdmetProperties/OverviewValuesContainer";
import ScrollTo from "react-scroll-into-view";
import { set } from "lodash";

function AdmetPropertiesPage() {
  const SectionRef = useRef(null);
  const [admetData, setAdmetData] = useState([]);
  const [threeCol, setThreeCol] = useState(true);
  const [scrollPos, setScrollPos] = useState(0);
  const [isPendingDownload, setIsPendingDownload] = useState(false);
  const [isPendingZipDownload, setIsPendingZipDownload] = useState(false);
  const getAdmetOutputResponse = useAppSelector(
    (state) => state.getAdmetOutputResponse
  );
  const [searchParams] = useSearchParams();
  const jobId = searchParams.get("jobId");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedSmile, setSelectedSmile] = useState(-1);
  const [scrollSmile, setScrollSmile] = useState(-1);
  const [comparatorSmileData, setComparatorSmileData] = useState({});
  const [radarParameters, setRadarParameters] = useState({});

  useEffect(() => {
    const request = {
      job_id: jobId,
    };
    dispatch(getAdmetOutputAction(queryString.stringify(request)));
  }, [jobId]);
  useEffect(() => {
    if (getAdmetOutputResponse) {
      setAdmetData(getAdmetOutputResponse.data);
    }
  }, [JSON.stringify(getAdmetOutputResponse)]);

  useEffect(() => {
    if (selectedSmile === -1) {
      manageScrollBack();
    } else {
      manageScroll();
    }
  }, [selectedSmile]);

  const manageScroll = () => {
    if (selectedSmile !== -1) {
      const ref = document.getElementById("top-div");
      console.log("SectionRef.current", ref);
      setTimeout(function () {
        ref.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 100);
    }
  };

  const manageScrollBack = () => {
    if (selectedSmile === -1 && scrollSmile !== -1) {
      const ref = document.getElementById(`card-${scrollSmile}`);
      console.log("SectionRef.current", ref);
      setTimeout(function () {
        ref?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 100);
    }
    setScrollSmile(-1);
  };
  const handleBack = () => {
    if (selectedSmile !== -1) {
      setScrollSmile(selectedSmile);
      setSelectedSmile(-1);
    } else navigate(`${terminalsPath.metad3_sar_denovo}`);
  };
  return (
    <Loader loading={getAdmetOutputResponse.loading}>
      <div className="ct-home-page">
        <div className="main-heading">
          <div className="back-btn">
            <Button
              className="back-btn-icon"
              id="top-div"
              onClick={() => handleBack()}
            >
              Back
            </Button>
          </div>
        </div>
        <div className="combinations-output-list">
          {/* <div className="combinations-output-overview overview-layout-section">
          <div className="overview-header">
            <div className="main-title">Overview</div>
            <div className="download-btns">
              <div className="main-download-btn">
                <Button
                  onClick={() => {
                    setIsPendingDownload(true);
                    downloadXLSFile({ isZip: false, jobId: jobId, setIsPendingDownload, setIsPendingZipDownload });
                  }}
                >
                  {`${isPendingDownload ? 'Downloading...' : 'Download Output'}`}
                </Button>
              </div>
              <div className="main-download-btn">
                <Button
                  onClick={() => {
                    setIsPendingZipDownload(true);
                    downloadXLSFile({ isZip: true, jobId: jobId, setIsPendingDownload, setIsPendingZipDownload });
                  }}
                >
                  {`${isPendingZipDownload ? 'Downloading...' : 'Download .zip'}`}
                </Button>
              </div>
            </div>
          </div>
          <OverviewValuesContainer admetData={admetData} isOverview={true}/>
        </div>
        <div className="combinations-output-overview">
          <div className="parameter-header">
            <div className="main-title">Parameter</div>
            <div className="header-toggle-sec">
              <Space>
                <Radio.Group value={threeCol} onChange={(e) => setThreeCol(e.target.value)}>
                  <Radio.Button value={false}>
                    <div className="icon-toggle" />
                  </Radio.Button>
                  <Radio.Button value>
                    <div className="icon-toggle" />
                  </Radio.Button>
                </Radio.Group>
              </Space>
            </div>
          </div>
          <ParameterValuesContainer admetData={admetData} isThreeCol={threeCol} />
        </div> */}

          {admetData?.map((item, index) => {
            if (selectedSmile !== -1 && selectedSmile !== index) return <div />;
            return (
              <div
                className={`combinations-output-list-item${selectedSmile !== index ? ' combinations-output-list-item-parent' : ''}`}
                id={`card-${index}`}
                onClick={(e) => {
                  console.log(e.clientY);
                  if (selectedSmile === -1) {
                    setSelectedSmile(index);
                    // manageScroll();
                    // setScrollPos(e.clientY);
                  }
                }}
              >
                <div className={`combinations-output-overview overview-layout-section${selectedSmile !== index ? ' overview-layout-section-parent' : ''}`}>
                  {selectedSmile === index && (
                    <div className="overview-header">
                      <div className="main-title">Overview</div>
                      <div className="download-btns">
                        <div className="main-download-btn">
                          <Button
                            onClick={() => {
                              setIsPendingDownload(true);
                              downloadXLSFile({
                                isZip: false,
                                jobId,
                                setIsPendingDownload,
                                setIsPendingZipDownload,
                              });
                            }}
                          >
                            {`${isPendingDownload ? "Downloading..." : "Download Output"}`}
                          </Button>
                        </div>
                        <div className="main-download-btn">
                          <Button
                            onClick={() => {
                              setIsPendingZipDownload(true);
                              downloadXLSFile({
                                isZip: true,
                                jobId,
                                setIsPendingDownload,
                                setIsPendingZipDownload,
                              });
                            }}
                          >
                            {`${isPendingZipDownload ? "Downloading..." : "Download .zip"}`}
                          </Button>
                        </div>
                      </div>
                      {/* {manageScroll()} */}
                    </div>
                  )}
                  <OverviewValuesContainer
                    admetData={item}
                    isOverview={selectedSmile === index}
                    comparatorSmileData={comparatorSmileData}
                    setComparatorSmileData={setComparatorSmileData}
                    radarParameters={radarParameters}
                    setRadarParameters={setRadarParameters}
                  />
                </div>
                {selectedSmile === index && (
                  <div className="combinations-output-overview">
                    <div className="parameter-header">
                      <div className="main-title">Parameter</div>
                      <div className="header-toggle-sec">
                        <Space>
                          <Radio.Group
                            value={threeCol}
                            onChange={(e) => setThreeCol(e.target.value)}
                          >
                            <Radio.Button value={false}>
                              <div className="icon-toggle" />
                            </Radio.Button>
                            <Radio.Button value>
                              <div className="icon-toggle" />
                            </Radio.Button>
                          </Radio.Group>
                        </Space>
                      </div>
                    </div>
                    <ParameterValuesContainer
                      admetData={item}
                      isThreeCol={threeCol}
                    />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </Loader>
  );
}

export default AdmetPropertiesPage;
