/* eslint-disable */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import html2canvas from 'html2canvas';
import PropTypes from 'prop-types';
import { UpSetJS, extractSets, ISetLike } from '@upsetjs/react';
// import './styles.css';
import { lab } from 'd3-color';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import useAppSelector from '../../../../../utils/redux-hook';
import data from './data';
import { getAssociatedProteinsDistributionAction } from './logic';
import Loader from '../../../../../common/components/Loader';
import { Button } from 'antd';

function AssociatedProteinDistributionGraph({ jobId }) {
  const [tooltip, setTooltip] = useState(null);
  const getAssociatedProteinDistributionResponse = useAppSelector((state) => state.getAssociatedProteinDistributionResponse);
  const [graphData, setGraphData] = useState([]);
  const dispatch = useDispatch();
  const chartRef = useRef(null);
  useEffect(() => {
    const request = {
      job_id: jobId,
      from: 1,
      size: 10,
      filters: JSON.stringify(['DiseaseSimilaritySignature', 'PpiSignature', 'MolecularFunctionSignature', 'LiteratureSignature']),
    };
    dispatch(getAssociatedProteinsDistributionAction(queryString.stringify(request)));
  }, []);

  useEffect(() => {
    if (getAssociatedProteinDistributionResponse.flag) {
      const arr = getAssociatedProteinDistributionResponse.data?.graph_data;
      const res = [];
      for (let i = 0; i < arr?.length; i++) {
        if (typeof arr[i].sets === 'string') {
          continue;
        }
        res.push(arr[i]);
      }
      setGraphData(res);
    }
  }, [JSON.stringify(getAssociatedProteinDistributionResponse)]);
  const handleExport = () => {
    const chart = chartRef.current;
    html2canvas(chart).then((canvas) => {
      const link = document.createElement('a');
      link.href = canvas.toDataURL('image/png');
      link.download = 'chart.png';
      link.click();
    });
  };

  function mergeColors(colors) {
    if (colors.length === 0) {
      return undefined;
    }
    if (colors.length === 1) {
      return '#2270c2';
    }
    // const cc = colors.reduce(
    //   (acc, d) => {
    //     const c = lab(d || 'transparent');
    //     return {
    //       l: acc.l + c.l,
    //       a: acc.a + c.a,
    //       b: acc.b + c.b,
    //     };
    //   },
    //   { l: 0, a: 0, b: 0 },
    // );
    // return lab(cc.l / colors.length, cc.a / colors.length, cc.b / colors.length).toString();
    // return null;
  }

  function UpSetPlot() {
    // const [selection, setSelection] = useState(null);
    const sets = extractSets(graphData);
    if (sets.length > 0) sets[0].color = '#CBA5E9';
    if (sets.length > 1) sets[1].color = '#EDC962';
    if (sets.length > 2) sets[2].color = '#5FD6E7';
    if (sets.length > 3) sets[3].color = '#85D38D';
    const handleClick = (set, event) => {
      const tooltipData = {
        set: set,
        position: {
          x: event.clientX,
          y: event.clientY,
        },
      };
      setTooltip(tooltipData);
    };
    return (
      <>
        <UpSetJS
          ref={chartRef}
          sets={sets}
          width={window.innerWidth * 0.85}
          height={800}
          // selectionColor="#2270c2"
          // hasSelectionOpacity={0.5}
          color="#2270c2"
          combinationName="Intersection Count --->"
          dotPadding={0.6}
          combinations={{
            mergeColors,
            type: 'distinctIntersection',
          }}
          combinationNameAxisOffset={80}
          classNames={{
            combinationName: 'combination-name',
            combinationNameAxisOffset: 'combination-name-axis-offset',
            setLabel: 'set-label',
            chartLabel: 'chart-label',
          }}
          // onClick={handleClick}
        />
        {/* {tooltip && (
          <div className="tooltip" style={{ position: 'absolute', left: tooltip.position.x, top: tooltip.position.y }}>
            <p>{`Set: ${tooltip.set.name}`}</p>
            <p>{`Count: ${tooltip.set.count}`}</p>
          </div>
        )} */}
      </>
      // <div/>
    );
  }

  return (
    <div className="graph-sl">
      <div className="card-content-ctp-title">Associated Proteins Distribution</div>
      {/* <Button onClick={handleExport}>Export Chart</Button> */}
      <Loader loading={getAssociatedProteinDistributionResponse.loading || graphData.length === 0}>{graphData.length !== 0 && UpSetPlot()}</Loader>
    </div>
  );
}

AssociatedProteinDistributionGraph.propTypes = {
  jobId: PropTypes.string.isRequired,
};

export default AssociatedProteinDistributionGraph;
