import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import { apiCallwithoutCT } from '../../../../utils';

// upload a document
const UPLOAD_SMILE_DOCUMENT = 'UPLOAD_DOCUMENT';
const UPLOAD_SMILE_DOCUMENT_SUCCESS = 'UPLOAD_DOCUMENT_SUCCESS';
const UPLOAD_SMILE_DOCUMENT_FAILURE = 'UPLOAD_DOCUMENT_FAILURE';
const UPLOAD_SMILE_DOCUMENT_RESET = 'UPLOAD_DOCUMENT_RESET';

export const uploadSmileDocumentAction = createAction(UPLOAD_SMILE_DOCUMENT);
export const uploadSmileDocumentResetAction = createAction(UPLOAD_SMILE_DOCUMENT_RESET);

const uploadSmileDocumentSuccess = createAction(UPLOAD_SMILE_DOCUMENT_SUCCESS);

const uploadSmileDocumentFailure = createAction(UPLOAD_SMILE_DOCUMENT_FAILURE);
export const uploadSmileDocumentEpic = (actions$) => actions$.pipe(
  ofType(UPLOAD_SMILE_DOCUMENT),
  mergeMap((action) => ajax(apiCallwithoutCT(`${process.env.apiUrl_sar}/uploadSmileDocument?${action.payload.params}`, 'POST', action.payload.body)).pipe(
    map((res) => uploadSmileDocumentSuccess(res)),
    catchError((err) => of(uploadSmileDocumentFailure(err))),
  )),
);

export const uploadSmileDocumentReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(uploadSmileDocumentAction, (state) => {
        state.loading = true;
      })
      .addCase(uploadSmileDocumentSuccess, (state, action) => {
        state.data = { ...action.payload.response.data };
        state.loading = false;
        state.flag = true;
      })
      .addCase(uploadSmileDocumentFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(uploadSmileDocumentResetAction, (state) => {
        state.data = {};
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);
