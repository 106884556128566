import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Header from '../../../../common/components/Header';
import SelectService from '../../container/SelectService';
import InputBox from '../../container/InputBox';
import { terminalsPath } from '../../../../app/components/Card/data';
import useAppSelector from '../../../../utils/redux-hook';
import { sendNotification } from '../../../../common/components/Notification/logic';
import Loader from '../../../../common/components/Loader';
import PendingStatusModal from '../../components/PendingStatusModal';
import SaveProjectModal from '../../container/SaveProjectModal';
import Mixpanel from '../../../../utils/mixpanel';

function SARInputPage() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const service = searchParams.get('service');
  const inputStyle = searchParams.get('inputStyle');

  const smiles = window.location.href.split('smiles')[1]?.slice(1) ?? '';
  const [inputs, setInputs] = useState({
    smile: smiles !== null ? smiles : '',
    card: service !== null ? service : '',
    dataset: '',
    btn: inputStyle !== null ? inputStyle : '',
  });
  const [isDisabled, setIsDisabled] = useState(true);
  const navigate = useNavigate();
  const [isPending, setIsPending] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const generateSARLeadResponse = useAppSelector((state) => state.generateSARLeadResponse);
  const checkStatusResponse = useAppSelector((state) => state.checkSARStatusResponse);
  const [isTextInput, setIsTextInput] = useState(false);
  const [formdata, setFormData] = useState(null);
  const [requestBody, setRequestBody] = useState({});

  const handleInputStyle = (value) => {
    setIsTextInput(value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (inputStyle === 'Textual Input') {
      setIsTextInput(true);
    }
  }, []);

  useEffect(() => {
    if (generateSARLeadResponse.flag) {
      dispatch(
        sendNotification({
          type: 'REQUEST_SUCCESS',
          notificationType: 'success',
          message: generateSARLeadResponse.data,
          showNotification: true,
        }),
      );
    }
  }, [JSON.stringify(generateSARLeadResponse)]);
  useEffect(() => {
    if (checkStatusResponse.flag && checkStatusResponse.data.length > 0) {
      if (checkStatusResponse.data[0]?.status === 'PENDING') {
        if (checkStatusResponse.data[0]?.is_read === false) {
          setIsPending(true);
        }
      } else if (checkStatusResponse.data[0]?.status === 'COMPLETED' && checkStatusResponse.data[0]?.is_read !== true) {
        navigate(`${terminalsPath.metad3_sar_denovo_output}?jobId=${checkStatusResponse.data[0].job_id}&service=${inputs.card}&noOfMolecules=${inputs.smile.length}&inputStyle=${inputs.btn}&smile=${inputs.smile}`);
      } else if (checkStatusResponse.data[0]?.status === 'REJECTED') {
        setIsPending(false);
      }
    }
  }, [JSON.stringify(checkStatusResponse)]);

  const getInputs = (input) => {
    setInputs((prev) => ({
      ...prev,
      ...input,
    }));
  };
  const checkBtnDissabledForAccess = () => {
    if (inputs.btn && inputs.btn === 'Textual Input') {
      return inputs.btn === undefined || inputs.btn === '' || inputs.smile[0] === undefined || inputs.smile[0].trim() === '' || inputs.smile === undefined || inputs.card === '' || inputs.card === undefined;
    }
    if (inputs.btn && inputs.btn === 'Upload a Document') {
      return inputs.btn === undefined || inputs.btn === '' || inputs.card === '' || inputs.card === undefined || !(formdata !== null && formdata && 'get' in formdata);
    }
    return true;
  };

  useEffect(() => {
    setIsDisabled(checkBtnDissabledForAccess());
  }, [inputs, formdata]);
  const handleClick = () => {
    Mixpanel.track('SAR Submit Button Clicked', {
      action: 'SAR Submit button clicked',
      inputs,
      service: inputs.card,
      module: 'SAR',
    });

    let request;
    if (isTextInput) {
      request = {
        params: {
          service: inputs.card,
        },
        body: inputs.smile,
      };
      // dispatch(generateSARLeadAction(request));
    } else {
      request = {
        params: {
          service: inputs.card,
        },
        body: formdata,
      };
      // dispatch(uploadSmileDocumentAction(request));
    }
    setRequestBody(request);
    setIsPending(true);
    setIsSubmitted(true);
    checkBtnDissabledForAccess();
  };

  return (
    <div className="main-page main-page-modules">
      <Header className="header-home" logo="sar" width={370} isWorkspace modulePath={terminalsPath.metad3_sar_denovo_workspace} />
      <Loader loading={checkStatusResponse.loading && !isSubmitted} error={checkStatusResponse.error}>
        <div className="ct-home-page">
          <div className="main-heading">
            <div className="main-title">Discover a novel drug with SAR & Denovo</div>
            <div className="main-description">Using our AI model you can discover a novel drug with just four simple steps.</div>
          </div>
          <SelectService inputHandler={getInputs} service={service} />
          <InputBox inputHandler={getInputs} isDisabled={inputs.card === ''} smiles={smiles} inputStyle={inputStyle} setFormData={setFormData} setInputStyle={handleInputStyle} enableButton={checkBtnDissabledForAccess} />
          <div className="ct-home-page-footer">
            <button onClick={() => handleClick()} className="generate-in-btn" type="button" disabled={isDisabled}>
              SUBMIT
            </button>
          </div>
        </div>
        <PendingStatusModal open={isPending} />
        <SaveProjectModal service={inputs.card} open={isSubmitted} setOpen={setIsSubmitted} requestBody={requestBody} formData={formdata} />
      </Loader>
    </div>
  );
}

export default SARInputPage;
