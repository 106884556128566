import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { Button } from 'antd';
import Rcookie from 'react-cookies';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import useAppSelector from '../../../../utils/redux-hook';
import { hctpDrugIndicationsAction } from '../../pages/HCTPOutputPage/logic';
import Loader from '../../../../common/components/Loader';

function HCTPTableView() {
  const [currentSortKey, setCurrentSortKey] = useState('');
  const [searchParams] = useSearchParams();
  const jobId = searchParams.get('jobId');
  const [sortKey, setSortKey] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const dispatch = useDispatch();
  const hctpDrugIndicationsResponse = useAppSelector((state) => state.hctpDrugIndicationsResponse);
  const [hctpData, setHctpData] = useState([]);
  useEffect(() => {
    const request = {
      job_id: jobId,
      from: 1,
      size: 100,
      sort_key: sortKey || undefined,
      sort_order: sortOrder || undefined,
    };
    dispatch(hctpDrugIndicationsAction(queryString.stringify(request)));
  }, []);
  useEffect(() => {
    if (hctpDrugIndicationsResponse.flag) {
      setHctpData(hctpDrugIndicationsResponse.data.data);
    }
  });

  const downloadXLSFile = async (fileId) => {
    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`) ? Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`) : '', 'Content-Type': 'blob', 'Access-Control-Allow-Origin': `${process.env.applicationUrl}` };
    const apiUrl = `${process.env.apiUrl_ctp}/fileDownload?job_id=${jobId}&file_id=${fileId}`;

    try {
      const response = await axios({
        method: 'GET',
        url: apiUrl,
        responseType: 'json',
        headers,
      });
      const outputFilename = `${Date.now()}.zip`;

      // If you want to download file automatically using link attribute.
      const url = response.data?.zip_url;
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      throw Error(error);
    }
  };

  const updateParameters = (order, key, activeClass) => {
    setCurrentSortKey(activeClass);
    setSortKey(key);
    setSortOrder(order);
    const request = {
      job_id: jobId,
      sort_key: key,
      sort_order: order,
      from: 1,
      size: 100,
    };
    dispatch(hctpDrugIndicationsAction(queryString.stringify(request)));
  };
  const eachRow = (item) => (
    (
      <div className="drug-indication-table-row" style={{ display: 'flex' }}>
        <div className="drug-indication-table-cell">{item.id.replace('_', '-')}</div>
        <div className="drug-indication-table-cell">
          <div className="cell-value">{`${Number(item.pos).toFixed(2)}%`}</div>
          <div className="download-icon" onClick={() => downloadXLSFile(item.id)} aria-hidden />
        </div>
        <div className="drug-indication-table-cell"><Button disabled>Run</Button></div>
        <div className="drug-indication-table-cell">
          <div className="cell-value">-</div>
          <div className="download-icon" disabled />
        </div>
      </div>
    )
  );

  const renderTableRows = () => (
    <div className="table-rows-main">
      {hctpData.map((item) => eachRow(item))}
    </div>
  );
  return (
    <Loader loading={hctpDrugIndicationsResponse.loading && hctpData.length === 0}>
      <div className="drug-indication-table-main">
        <div className="drug-indication-header" style={{ display: 'flex' }}>
          <div className="header-key-row">
            <div>Drug-Indication Pair</div>
            <div className="info-icon" />
          </div>
          <div className="header-value-row">
            <div>HCTP POS</div>
            <div className="info-icon" />
            <div className="sort-icons">
              <div className={`up-pos${currentSortKey === 'up-pos' ? ' active' : ''}`} onClick={() => updateParameters('asc', 'pos', 'up-pos')} aria-hidden />
              <div className={`down-pos${currentSortKey === 'down-pos' ? ' active' : ''}`} onClick={() => updateParameters('desc', 'pos', 'down-pos')} aria-hidden />
            </div>
          </div>
          <div className="header-key-row">
            <div>Simulation</div>
            <div className="info-icon" />
          </div>
          <div className="header-value-row">
            <div>Optimised HCTP POS</div>
            <div className="info-icon" />
            <div className="sort-icons">
              <div className={`up-pos up-optimised-pos${currentSortKey === 'up-pos up-optimised-pos' ? ' active' : ''}`} onClick={() => setCurrentSortKey('up-pos up-optimised-pos')} aria-hidden />
              <div className={`down-pos down-optimised-pos${currentSortKey === 'down-pos down-optimised-pos' ? ' active' : ''}`} onClick={() => setCurrentSortKey('down-pos down-optimised-pos')} aria-hidden />
            </div>
          </div>
        </div>
        <div className="drug-indication-rows">
          {renderTableRows()}
        </div>
      </div>
    </Loader>
  );
}

// HCTPTableView.propTypes = {
//   data: PropTypes.arrayOf.isRequired,
// };

export default HCTPTableView;
