import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TextArea from 'antd/es/input/TextArea';

function InputDropdown({ changeHandler, indicationName, setDefaultIndication }) {
  const [inputText, setInputText] = useState('');
  const [indicationsList, setIndicationsList] = useState('');

  useEffect(() => {
    setInputText(indicationName);
    setIndicationsList(indicationName);
  }, [indicationName]);

  useEffect(() => {
    changeHandler(indicationsList);
  }, [indicationsList]);

  const onChangeInput = (e) => {
    if (e.target.value.trim().length > 0) {
      setInputText(e.target.value);
      setIndicationsList(e.target.value);
      setDefaultIndication(e.target.value);
    } else {
      setInputText('');
      changeHandler('');
    }
  };

  return (
    <div className="searchbar-dashboard-content-search joyride-searchBar">
      <TextArea rows={4} placeholder="Enter indication name" type="textarea" value={inputText} onChange={onChangeInput} />
    </div>
  );
}

InputDropdown.propTypes = {
  changeHandler: PropTypes.func.isRequired,
  indicationName: PropTypes.string.isRequired,
  setDefaultIndication: PropTypes.func.isRequired,
};

export default InputDropdown;
