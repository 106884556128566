import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../utils';

// my-workspace-lead-generation
const SAR_LEAD_GENERATION_WORKSPACE_STATUS = 'SAR_LEAD_GENERATION_WORKSPACE_STATUS';
const SAR_MORE_LEAD_GENERATION_WORKSPACE_STATUS = 'SAR_MORE_LEAD_GENERATION_WORKSPACE_STATUS';
const SAR_LEAD_GENERATION_WORKSPACE_STATUS_SUCCESS = 'SAR_LEAD_GENERATION_WORKSPACE_STATUS_SUCCESS';
const SAR_MORE_LEAD_GENERATION_WORKSPACE_STATUS_SUCCESS = 'SAR_MORE_LEAD_GENERATION_WORKSPACE_STATUS_SUCCESS';
const SAR_LEAD_GENERATION_WORKSPACE_STATUS_FAILURE = 'SAR_LEAD_GENERATION_WORKSPACE_STATUS_FAILURE';
const SAR_LEAD_GENERATION_WORKSPACE_STATUS_RESET = 'SAR_LEAD_GENERATION_WORKSPACE_STATUS_RESET';

export const sarLeadGenerationWorkspaceStatusAction = createAction(SAR_LEAD_GENERATION_WORKSPACE_STATUS);
export const sarMoreLeadGenerationWorkspaceStatusAction = createAction(SAR_MORE_LEAD_GENERATION_WORKSPACE_STATUS);
export const sarLeadGenerationWorkspaceStatusResetAction = createAction(SAR_LEAD_GENERATION_WORKSPACE_STATUS_RESET);

const sarLeadGenerationWorkspaceStatusSuccess = createAction(SAR_LEAD_GENERATION_WORKSPACE_STATUS_SUCCESS);
const sarMoreLeadGenerationWorkspaceStatusSuccess = createAction(SAR_MORE_LEAD_GENERATION_WORKSPACE_STATUS_SUCCESS);
const sarLeadGenerationWorkspaceStatusFailure = createAction(SAR_LEAD_GENERATION_WORKSPACE_STATUS_FAILURE);

export const sarLeadGenerationWorkspaceStatusEpic = (actions$) => actions$.pipe(
  ofType(SAR_LEAD_GENERATION_WORKSPACE_STATUS),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/v1/d3_modules/searchJob?${action.payload}`, 'get')).pipe(
    map((res) => sarLeadGenerationWorkspaceStatusSuccess(res)),
    catchError((err) => of(sarLeadGenerationWorkspaceStatusFailure(err))),
  )),
);

export const sarMoreLeadGenerationWorkspaceStatusEpic = (actions$) => actions$.pipe(
  ofType(SAR_MORE_LEAD_GENERATION_WORKSPACE_STATUS),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/v1/d3_modules/searchJob?${action.payload}`, 'get')).pipe(
    map((res) => sarMoreLeadGenerationWorkspaceStatusSuccess(res)),
    catchError((err) => of(sarLeadGenerationWorkspaceStatusFailure(err))),
  )),
);

export const sarLeadGenerationWorkspaceStatusReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(sarLeadGenerationWorkspaceStatusAction, (state) => {
      state.loading = true;
    })
      .addCase(sarLeadGenerationWorkspaceStatusSuccess, (state, action) => {
        state.data = [...action.payload.response.data];
        state.loading = false;
        state.flag = true;
      })
      .addCase(sarMoreLeadGenerationWorkspaceStatusSuccess, (state, action) => {
        state.data = {
          total_count: action.payload.response.data.total_count,
          data: [...action.payload.response.data],
        };
        state.loading = false;
        state.flag = true;
      })
      .addCase(sarLeadGenerationWorkspaceStatusFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(sarLeadGenerationWorkspaceStatusResetAction, (state) => {
        state.data = {};
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);

// my-workspace-admet-properties
const SAR_ADMET_PROPERTIES_WORKSPACE_STATUS = 'SAR_ADMET_PROPERTIES_WORKSPACE_STATUS';
const SAR_ADMET_PROPERTIES_WORKSPACE_STATUS_SUCCESS = 'SAR_ADMET_PROPERTIES_WORKSPACE_STATUS_SUCCESS';
const SAR_ADMET_PROPERTIES_WORKSPACE_STATUS_FAILURE = 'SAR_ADMET_PROPERTIES_WORKSPACE_STATUS_FAILURE';
const SAR_ADMET_PROPERTIES_WORKSPACE_STATUS_RESET = 'SAR_ADMET_PROPERTIES_WORKSPACE_STATUS_RESET';

export const sarAdmetPropertiesWorkspaceStatusAction = createAction(SAR_ADMET_PROPERTIES_WORKSPACE_STATUS);
export const sarAdmetPropertiesWorkspaceStatusResetAction = createAction(SAR_ADMET_PROPERTIES_WORKSPACE_STATUS_RESET);

const sarAdmetPropertiesWorkspaceStatusSuccess = createAction(SAR_ADMET_PROPERTIES_WORKSPACE_STATUS_SUCCESS);
const sarAdmetPropertiesWorkspaceStatusFailure = createAction(SAR_ADMET_PROPERTIES_WORKSPACE_STATUS_FAILURE);

export const sarAdmetPropertiesWorkspaceStatusEpic = (actions$) => actions$.pipe(
  ofType(SAR_ADMET_PROPERTIES_WORKSPACE_STATUS),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/v1/d3_modules/searchJob?${action.payload}`, 'get')).pipe(
    map((res) => sarAdmetPropertiesWorkspaceStatusSuccess(res)),
    catchError((err) => of(sarAdmetPropertiesWorkspaceStatusFailure(err))),
  )),
);

export const sarAdmetPropertiesWorkspaceStatusReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(sarAdmetPropertiesWorkspaceStatusAction, (state) => {
      state.loading = true;
    })
      .addCase(sarAdmetPropertiesWorkspaceStatusSuccess, (state, action) => {
        state.data = [...action.payload.response.data];
        state.loading = false;
        state.flag = true;
      })
      .addCase(sarAdmetPropertiesWorkspaceStatusFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(sarAdmetPropertiesWorkspaceStatusResetAction, (state) => {
        state.data = {};
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);

// my-workspace-admet-properties
const SAR_DRUG_PREDICTION_WORKSPACE_STATUS = 'SAR_DRUG_PREDICTION_WORKSPACE_STATUS';
const SAR_DRUG_PREDICTION_WORKSPACE_STATUS_SUCCESS = 'SAR_DRUG_PREDICTION_WORKSPACE_STATUS_SUCCESS';
const SAR_DRUG_PREDICTION_WORKSPACE_STATUS_FAILURE = 'SAR_DRUG_PREDICTION_WORKSPACE_STATUS_FAILURE';
const SAR_DRUG_PREDICTION_WORKSPACE_STATUS_RESET = 'SAR_DRUG_PREDICTION_WORKSPACE_STATUS_RESET';

export const sarDrugPredictionWorkspaceStatusAction = createAction(SAR_DRUG_PREDICTION_WORKSPACE_STATUS);
export const sarDrugPredictionWorkspaceStatusResetAction = createAction(SAR_DRUG_PREDICTION_WORKSPACE_STATUS_RESET);

const sarDrugPredictionWorkspaceStatusSuccess = createAction(SAR_DRUG_PREDICTION_WORKSPACE_STATUS_SUCCESS);
const sarDrugPredictionWorkspaceStatusFailure = createAction(SAR_DRUG_PREDICTION_WORKSPACE_STATUS_FAILURE);

export const sarDrugPredictionWorkspaceStatusEpic = (actions$) => actions$.pipe(
  ofType(SAR_DRUG_PREDICTION_WORKSPACE_STATUS),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/v1/d3_modules/searchJob?${action.payload}`, 'get')).pipe(
    map((res) => sarDrugPredictionWorkspaceStatusSuccess(res)),
    catchError((err) => of(sarDrugPredictionWorkspaceStatusFailure(err))),
  )),
);

export const sarDrugPredictionWorkspaceStatusReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(sarDrugPredictionWorkspaceStatusAction, (state) => {
      state.loading = true;
    })
      .addCase(sarDrugPredictionWorkspaceStatusSuccess, (state, action) => {
        state.data = [...action.payload.response.data];
        state.loading = false;
        state.flag = true;
      })
      .addCase(sarDrugPredictionWorkspaceStatusFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(sarDrugPredictionWorkspaceStatusResetAction, (state) => {
        state.data = {};
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);

// my-workspace-bioactivity-prediction
const SAR_BIOACTIVITY_WORKSPACE_STATUS = 'SAR_BIOACTIVITY_WORKSPACE_STATUS';
const SAR_BIOACTIVITY_WORKSPACE_STATUS_SUCCESS = 'SAR_BIOACTIVITY_WORKSPACE_STATUS_SUCCESS';
const SAR_BIOACTIVITY_WORKSPACE_STATUS_FAILURE = 'SAR_BIOACTIVITY_WORKSPACE_STATUS_FAILURE';
const SAR_BIOACTIVITY_WORKSPACE_STATUS_RESET = 'SAR_BIOACTIVITY_WORKSPACE_STATUS_RESET';

export const sarBioactivityWorkspaceStatusAction = createAction(SAR_BIOACTIVITY_WORKSPACE_STATUS);
export const sarBioactivityWorkspaceStatusResetAction = createAction(SAR_BIOACTIVITY_WORKSPACE_STATUS_RESET);

const sarBioactivityWorkspaceStatusSuccess = createAction(SAR_BIOACTIVITY_WORKSPACE_STATUS_SUCCESS);
const sarBioactivityWorkspaceStatusFailure = createAction(SAR_BIOACTIVITY_WORKSPACE_STATUS_FAILURE);

export const sarBioactivityWorkspaceStatusEpic = (actions$) => actions$.pipe(
  ofType(SAR_BIOACTIVITY_WORKSPACE_STATUS),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/v1/d3_modules/searchJob?${action.payload}`, 'get')).pipe(
    map((res) => sarBioactivityWorkspaceStatusSuccess(res)),
    catchError((err) => of(sarBioactivityWorkspaceStatusFailure(err))),
  )),
);

export const sarBioactivityWorkspaceStatusReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(sarBioactivityWorkspaceStatusAction, (state) => {
      state.loading = true;
    })
      .addCase(sarBioactivityWorkspaceStatusSuccess, (state, action) => {
        state.data = [...action.payload.response.data];
        state.loading = false;
        state.flag = true;
      })
      .addCase(sarBioactivityWorkspaceStatusFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(sarBioactivityWorkspaceStatusResetAction, (state) => {
        state.data = {};
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);

// update-workspace
const SAR_UPDATE_WORKSPACE_STATUS = 'SAR_UPDATE_WORKSPACE_STATUS';
const SAR_UPDATE_WORKSPACE_STATUS_SUCCESS = 'SAR_UPDATE_WORKSPACE_STATUS_SUCCESS';
const SAR_UPDATE_WORKSPACE_STATUS_FAILURE = 'SAR_UPDATE_WORKSPACE_STATUS_FAILURE';
const SAR_UPDATE_WORKSPACE_STATUS_RESET = 'SAR_UPDATE_WORKSPACE_STATUS_RESET';

export const sarUpdateWorkspaceStatusAction = createAction(SAR_UPDATE_WORKSPACE_STATUS);
export const sarUpdateWorkspaceStatusResetAction = createAction(SAR_UPDATE_WORKSPACE_STATUS_RESET);

const sarUpdateWorkspaceStatusSuccess = createAction(SAR_UPDATE_WORKSPACE_STATUS_SUCCESS);
const sarUpdateWorkspaceStatusFailure = createAction(SAR_UPDATE_WORKSPACE_STATUS_FAILURE);

export const sarUpdateWorkspaceStatusEpic = (actions$) => actions$.pipe(
  ofType(SAR_UPDATE_WORKSPACE_STATUS),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/v1/d3_modules/searchJob?${action.payload}`, 'put')).pipe(
    map((res) => sarUpdateWorkspaceStatusSuccess(res)),
    catchError((err) => of(sarUpdateWorkspaceStatusFailure(err))),
  )),
);

export const sarUpdateWorkspaceStatusReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(sarUpdateWorkspaceStatusAction, (state) => {
      state.loading = true;
    })
      .addCase(sarUpdateWorkspaceStatusSuccess, (state, action) => {
        state.data = { ...action.payload.response };
        state.loading = false;
        state.flag = true;
      })
      .addCase(sarUpdateWorkspaceStatusFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(sarUpdateWorkspaceStatusResetAction, (state) => {
        state.data = [];
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);
