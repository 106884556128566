import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../utils';

// check status
const LIGHT_CHAIN_OPTIMISATION_JOBS = 'LIGHT_CHAIN_OPTIMISATION_JOBS';
const LIGHT_CHAIN_OPTIMISATION_JOBS_SUCCESS = 'LIGHT_CHAIN_OPTIMISATION_JOBS_SUCCESS';
const LIGHT_CHAIN_OPTIMISATION_JOBS_FAILURE = 'LIGHT_CHAIN_OPTIMISATION_JOBS_FAILURE';
const LIGHT_CHAIN_OPTIMISATION_JOBS_RESET = 'LIGHT_CHAIN_OPTIMISATION_JOBS_RESET';
const GET_MORE_LIGHT_CHAIN_OPTIMISATION_JOBS = 'GET_MORE_LIGHT_CHAIN_OPTIMISATION_JOBS';
const GET_MORE_LIGHT_CHAIN_OPTIMISATION_JOBS_SUCCESS = 'GET_MORE_LIGHT_CHAIN_OPTIMISATION_JOBS_SUCCESS';

export const lightChainOptimisationJobsAction = createAction(LIGHT_CHAIN_OPTIMISATION_JOBS);
export const lightChainOptimisationJobsResetAction = createAction(LIGHT_CHAIN_OPTIMISATION_JOBS_RESET);

const lightChainOptimisationJobsSuccess = createAction(LIGHT_CHAIN_OPTIMISATION_JOBS_SUCCESS);
const lightChainOptimisationJobsFailure = createAction(LIGHT_CHAIN_OPTIMISATION_JOBS_FAILURE);

export const getMorelightChainOptimisationJobsAction = createAction(GET_MORE_LIGHT_CHAIN_OPTIMISATION_JOBS);
const getMorelightChainOptimisationJobsSuccess = createAction(GET_MORE_LIGHT_CHAIN_OPTIMISATION_JOBS_SUCCESS);

export const lightChainOptimisationJobsEpic = (actions$) => actions$.pipe(
  ofType(LIGHT_CHAIN_OPTIMISATION_JOBS),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/v1/d3_modules/searchJob?${action.payload}`, 'get')).pipe(
    map((res) => lightChainOptimisationJobsSuccess(res)),
    catchError((err) => of(lightChainOptimisationJobsFailure(err))),
  )),
);

export const getMorelightChainOptimisationJobsEpic = (actions$) => actions$.pipe(
  ofType(GET_MORE_LIGHT_CHAIN_OPTIMISATION_JOBS),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/v1/d3_modules/searchJob?${action.payload}`, 'get')).pipe(
    map((res) => getMorelightChainOptimisationJobsSuccess(res)),
    catchError((err) => of(lightChainOptimisationJobsFailure(err))),
  )),
);

export const lightChainOptimisationJobsReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(lightChainOptimisationJobsAction, (state) => {
      state.loading = true;
    })
      .addCase(lightChainOptimisationJobsSuccess, (state, action) => {
        state.data = { ...action.payload.response };
        state.loading = false;
        state.flag = true;
      })
      .addCase(getMorelightChainOptimisationJobsAction, (state) => {
        state.loading = true;
      })
      .addCase(getMorelightChainOptimisationJobsSuccess, (state, action) => {
        state.data = {
          total_count: action.payload.response.data.total_count,
          data: [...action.payload.response.data],
        };
        state.loading = false;
        state.flag = true;
      })
      .addCase(lightChainOptimisationJobsFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(lightChainOptimisationJobsResetAction, (state) => {
        state.data = [];
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);

// check status
const CLINICAL_ANALYSIS_JOBS = 'CLINICAL_ANALYSIS_JOBS';
const CLINICAL_ANALYSIS_JOBS_SUCCESS = 'CLINICAL_ANALYSIS_JOBS_SUCCESS';
const CLINICAL_ANALYSIS_JOBS_FAILURE = 'CLINICAL_ANALYSIS_JOBS_FAILURE';
const CLINICAL_ANALYSIS_JOBS_RESET = 'CLINICAL_ANALYSIS_JOBS_RESET';
const GET_MORE_CLINICAL_ANALYSIS_JOBS = 'GET_MORE_CLINICAL_ANALYSIS_JOBS';
const GET_MORE_CLINICAL_ANALYSIS_JOBS_SUCCESS = 'GET_MORE_CLINICAL_ANALYSIS_JOBS_SUCCESS';

export const clinicalAnalysisJobsAction = createAction(CLINICAL_ANALYSIS_JOBS);
export const clinicalAnalysisJobsResetAction = createAction(CLINICAL_ANALYSIS_JOBS_RESET);

const clinicalAnalysisJobsSuccess = createAction(CLINICAL_ANALYSIS_JOBS_SUCCESS);
const clinicalAnalysisJobsFailure = createAction(CLINICAL_ANALYSIS_JOBS_FAILURE);

export const getMoreClinicalAnalysisJobsAction = createAction(GET_MORE_CLINICAL_ANALYSIS_JOBS);
const getMoreClinicalAnalysisJobsSuccess = createAction(GET_MORE_CLINICAL_ANALYSIS_JOBS_SUCCESS);

export const clinicalAnalysisJobsIEPEpic = (actions$) => actions$.pipe(
  ofType(CLINICAL_ANALYSIS_JOBS),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/v1/d3_modules/searchJob?${action.payload}`, 'get')).pipe(
    map((res) => clinicalAnalysisJobsSuccess(res)),
    catchError((err) => of(clinicalAnalysisJobsFailure(err))),
  )),
);

export const getMoreClinicalAnalysisJobsIEPEpic = (actions$) => actions$.pipe(
  ofType(GET_MORE_CLINICAL_ANALYSIS_JOBS),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/v1/d3_modules/searchJob?${action.payload}`, 'get')).pipe(
    map((res) => getMoreClinicalAnalysisJobsSuccess(res)),
    catchError((err) => of(clinicalAnalysisJobsFailure(err))),
  )),
);

export const clinicalAnalysisJobsIEPReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(clinicalAnalysisJobsAction, (state) => {
      state.loading = true;
    })
      .addCase(clinicalAnalysisJobsSuccess, (state, action) => {
        state.data = { ...action.payload.response };
        state.loading = false;
        state.flag = true;
      })
      .addCase(getMoreClinicalAnalysisJobsAction, (state) => {
        state.loading = true;
      })
      .addCase(getMoreClinicalAnalysisJobsSuccess, (state, action) => {
        state.data = {
          total_count: action.payload.response.data.total_count,
          data: [...action.payload.response.data],
        };
        state.loading = false;
        state.flag = true;
      })
      .addCase(clinicalAnalysisJobsFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(clinicalAnalysisJobsResetAction, (state) => {
        state.data = [];
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);

// update project name
const IEP_UPDATE_WORKSPACE_STATUS = 'IEP_UPDATE_WORKSPACE_STATUS';
const IEP_UPDATE_WORKSPACE_STATUS_SUCCESS = 'IEP_UPDATE_WORKSPACE_STATUS_SUCCESS';
const IEP_UPDATE_WORKSPACE_STATUS_FAILURE = 'IEP_UPDATE_WORKSPACE_STATUS_FAILURE';
const IEP_UPDATE_WORKSPACE_STATUS_RESET = 'IEP_UPDATE_WORKSPACE_STATUS_RESET';

export const iepUpdateWorkspaceStatusAction = createAction(IEP_UPDATE_WORKSPACE_STATUS);
export const iepUpdateWorkspaceStatusResetAction = createAction(IEP_UPDATE_WORKSPACE_STATUS_RESET);

const iepUpdateWorkspaceStatusSuccess = createAction(IEP_UPDATE_WORKSPACE_STATUS_SUCCESS);
const iepUpdateWorkspaceStatusFailure = createAction(IEP_UPDATE_WORKSPACE_STATUS_FAILURE);

export const iepUpdateWorkspaceStatusEpic = (actions$) => actions$.pipe(
  ofType(IEP_UPDATE_WORKSPACE_STATUS),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/v1/d3_modules/searchJob?${action.payload}`, 'put')).pipe(
    map((res) => iepUpdateWorkspaceStatusSuccess(res)),
    catchError((err) => of(iepUpdateWorkspaceStatusFailure(err))),
  )),
);

export const iepUpdateWorkspaceStatusReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(iepUpdateWorkspaceStatusAction, (state) => {
      state.loading = true;
    })
      .addCase(iepUpdateWorkspaceStatusSuccess, (state, action) => {
        state.data = { ...action.payload.response };
        state.loading = false;
        state.flag = true;
      })
      .addCase(iepUpdateWorkspaceStatusFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(iepUpdateWorkspaceStatusResetAction, (state) => {
        state.data = [];
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);
