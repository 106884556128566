import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { XFilled } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { ctpCountryDistributionAction } from './logic';
import Loader from '../../../../../common/components/Loader';
import BubbleChart from '../BubbleGraph';
import useAppSelector from '../../../../../utils/redux-hook';

export default function BubbleGraphContainer({ indicationName }) {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const ctpCountryDistributionResponse = useAppSelector((state) => state.ctpCountryDistributionResponse);

  useEffect(() => {
    const body = {
      indication_name: indicationName,
    };
    dispatch(ctpCountryDistributionAction(queryString.stringify(body)));
  }, [indicationName]);

  useEffect(() => {
    if (ctpCountryDistributionResponse.flag) {
      setData(ctpCountryDistributionResponse.data);
    }
  }, [ctpCountryDistributionResponse]);

  const noDataFound = () => (
    <div className="no-data-found" style={{ color: '#000' }}>
      No data found
    </div>
  );

  return (
    <Loader loading={ctpCountryDistributionResponse.loading}>
      {data.length > 0 && (
        <div
          className="bubble-charts-header"
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row',
          }}
        >
          <div className="bubble-charts-title">
            <XFilled color="#608FF0" />
            <div className="bubble-charts-trialtype">Total Trials</div>
          </div>
          <div className="bubble-charts-title">
            <XFilled />
            <div className="bubble-charts-trialtype">Pass Trials</div>
          </div>
          <div className="bubble-charts-title">
            <XFilled />
            <div className="bubble-charts-trialtype">Fail Trials</div>
          </div>
          <div className="bubble-charts-title">
            <XFilled />
            <div className="bubble-charts-trialtype">Inconclusive Trials</div>
          </div>
        </div>
      )}
      {data.length > 0 ? (
        <div
          className="bubble-content"
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-around',
            flexDirection: 'row',
          }}
        >
          <div className="bubble-chart-box">
            <div className="bubble-chart-box-content">
              {data[0] && <BubbleChart data={[data[0]]} />}
              <div className="bubble-chart-title" style={{ textAlign: 'center', color: 'black' }}>
                {data[0] && data[0].key}
              </div>
            </div>
          </div>
          <div className="bubble-chart-box">
            <div className="bubble-chart-box-content">
              {data[1] && <BubbleChart data={[data[1]]} />}
              <div className="bubble-chart-title">{data[1] && data[1].key}</div>
            </div>
          </div>
          <div className="bubble-chart-box">
            <div className="bubble-chart-box-content">
              {data[2] && <BubbleChart data={[data[2]]} />}
              <div className="bubble-chart-title">{data[2] && data[2].key}</div>
            </div>
          </div>
          <div className="bubble-chart-box">
            <div className="bubble-chart-box-content">
              {data[3] && <BubbleChart data={[data[3]]} />}
              <div className="bubble-chart-title">{data[3] && data[3].key}</div>
            </div>
          </div>
          <div className="bubble-chart-box">
            <div className="bubble-chart-box-content">
              {data[4] && <BubbleChart data={[data[4]]} />}
              <div className="bubble-chart-title">{data[4] && data[4].key}</div>
            </div>
          </div>
        </div>
      ) : (
        noDataFound()
      )}
    </Loader>
  );
}

BubbleGraphContainer.propTypes = {
  indicationName: PropTypes.string.isRequired,
};
