import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall, { apiCallwithoutCT } from '../../../../utils';

// generate Job Id
const GENERATE_OUTCOME = 'GENERATE_OUTCOME';
const GENERATE_OUTCOME_SUCCESS = 'GENERATE_OUTCOME_SUCCESS';
const GENERATE_OUTCOME_FAILURE = 'GENERATE_OUTCOME_FAILURE';
const GENERATE_OUTCOME_RESET = 'GENERATE_OUTCOME_RESET';

export const generateOutcomeAction = createAction(GENERATE_OUTCOME);
export const generateOutcomeResetAction = createAction(GENERATE_OUTCOME_RESET);

const generateOutcomeSuccess = createAction(GENERATE_OUTCOME_SUCCESS);

const generateOutcomeFailure = createAction(GENERATE_OUTCOME_FAILURE);
export const generateOutcomeEpic = (actions$) => actions$.pipe(
  ofType(GENERATE_OUTCOME),
  switchMap((action) => ajax(apiCallwithoutCT(`${process.env.apiUrl_utilities}/generateOutcome?${action.payload.params}`, 'POST', action.payload.body)).pipe(
    map((res) => generateOutcomeSuccess(res)),
    catchError((err) => of(generateOutcomeFailure(err))),
  )),
);

export const generateOutcomeReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(generateOutcomeAction, (state) => {
        state.loading = true;
      })
      .addCase(generateOutcomeSuccess, (state, action) => {
        state.data = { ...action.payload.response.data };
        state.loading = false;
        state.flag = true;
      })
      .addCase(generateOutcomeFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(generateOutcomeResetAction, (state) => {
        state.data = {};
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);

// upload a document
const UPLOAD_SMILE_DOCUMENT = 'UPLOAD_DOCUMENT';
const UPLOAD_SMILE_DOCUMENT_SUCCESS = 'UPLOAD_DOCUMENT_SUCCESS';
const UPLOAD_SMILE_DOCUMENT_FAILURE = 'UPLOAD_DOCUMENT_FAILURE';

export const uploadSmileDocumentAction = createAction(UPLOAD_SMILE_DOCUMENT);

const uploadSmileDocumentSuccess = createAction(UPLOAD_SMILE_DOCUMENT_SUCCESS);

const uploadSmileDocumentFailure = createAction(UPLOAD_SMILE_DOCUMENT_FAILURE);
export const uploadSmileDocumentEpic = (actions$) => actions$.pipe(
  ofType(UPLOAD_SMILE_DOCUMENT),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_sar}/uploadSmileDocument?${action.payload.params}`, 'POST', action.payload.body)).pipe(
    map((res) => uploadSmileDocumentSuccess(res)),
    catchError((err) => of(uploadSmileDocumentFailure(err))),
  )),
);

export const uploadSmileDocumentReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(uploadSmileDocumentAction, (state) => {
        state.loading = true;
      })
      .addCase(uploadSmileDocumentSuccess, (state, action) => {
        state.data = { ...action.payload.response.data };
        state.loading = false;
        state.flag = true;
      })
      .addCase(uploadSmileDocumentFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);
