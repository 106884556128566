import React from 'react';
import PropTypes from 'prop-types';
import { Button, Divider, Tag } from 'antd';
import { useNavigate } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
import Rcookie from 'react-cookies';
import axios from 'axios';
import { terminalsPath } from '../../../../app/components/Card/data';
// import { iepWorkspaceStatusResetAction } from '../../pages/MyWorkspace/logic';
// import { generateIndicationsResetAction } from '../../pages/IEPInputPage/logic';
import { getCookie } from '../../../../utils';
import Mixpanel from '../../../../utils/mixpanel';

function ClinicalAnalysisWorkspaceDetails({ job }) {
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const userEmailId = getCookie('usersEmailId');

  const formatDate = (epoch) => {
    const date = new Date(epoch * 1000);

    const day = date.getDate();
    const month = date.toLocaleString('en-US', { month: 'short' });

    return `${day} ${month}`;
  };
  const formatTime = (epoch) => {
    const date = new Date(epoch * 1000);

    const time = date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });

    return time;
  };

  const onViewOutput = async (jobId) => {
    const isZip = true;
    const headers = { Authorization: Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`) ? Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`) : '', 'Content-Type': 'blob', 'Access-Control-Allow-Origin': `${process.env.applicationUrl}` };
    const apiUrl = isZip ? `${process.env.apiUrl_iep}/zipDownload?job_id=${jobId}` : `${process.env.apiUrl_iep}/resultDownload?job_id=${jobId}`;

    try {
      const response = await axios({
        method: 'GET',
        url: apiUrl,
        responseType: isZip ? 'json' : 'arraybuffer',
        headers,
      });
      const outputFilename = isZip ? `${Date.now()}.zip` : `${Date.now()}.xls`;

      // If you want to download file automatically using link attribute.
      const url = isZip ? response.data?.zip_url : URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      // setIsPendingDownload(false);
      // setIsPendingZipDownload(false);
    } catch (error) {
      throw Error(error);
    }
    Mixpanel.track('IEP Download Output Clicked', {
      action: 'IEP Download output button from IEP workspace clicked', job_id: job?.job_id, service: 'Clinical Analysis', module: 'IEP',
    });
  };

  // const eachApproachWeightage = (title, weightage) => (
  //   <div className="each-approach-weightage">
  //     <div className="weightage-title">{title}</div>
  //     <div className="weightage-value">{`${weightage}%`}</div>
  //   </div>
  // );

  const tagsGroup = (list1) => (
    <div className="tags-group">
      <div className="tags">
        {list1.map((item) => (
          <Tag key={item} className="sample-tags" closable={false}>
            {item}
          </Tag>
        ))}
      </div>
    </div>
  );

  return (
    <div className="job-details-main">
      {job?.status.toLowerCase() !== 'completed' && <div className={`job-pending-warning ${job?.status.toLowerCase() === 'pending' ? ' pending' : 'failed'}`}>{`${job?.status.toLowerCase() === 'pending' ? `Output generation is in progress. Once it is generated, we will notify you over your email, “${userEmailId}”` : 'Due to a technical error, the output generation failed. Please click the “Resubmit Input” button to try again.'}`}</div>}
      <div className="job-details-title">Input Details</div>
      <div className="job-details-content">
        <div className="job-name-row" style={{ display: 'flex' }}>
          <div className="job-name-group" style={{ display: 'flex' }}>
            <div className="job-name-group-title">{job?.job_name}</div>
            <div className="job-created-at">
              {formatDate(job?.created_date_time_ts)}
              <Divider
                type="vertical"
              />
              {formatTime(job?.created_date_time_ts)}
            </div>
          </div>
          <div className="download-output-btn">
            <Button
              disabled={job?.status.toLowerCase() === 'pending'}
              onClick={() => {
                if (job?.status.toLowerCase() === 'rejected') {
                  navigate(terminalsPath.metad3_iep);
                  Mixpanel.track('IEP Resubmit Input Clicked', {
                    action: 'IEP Resubmit input button from IEP workspace clicked', job_id: job?.job_id, service: 'Clinical Analysis', module: 'IEP',
                  });
                } else {
                  onViewOutput(job?.job_id);
                }
              }}
            >
              {`${job?.status.toLowerCase() === 'rejected' ? 'Resubmit Input' : 'Download Output'}`}
            </Button>
          </div>
        </div>
        {job?.description?.length ? (
          <div className="synonyms-changes">
            <div className="synonyms-changes-title">Description</div>
            <div className="synonyms-changes-content" style={{ display: 'flex' }}>
              <div className="added-synonyms">
                <div className="added-synonyms-list">{job?.description ? job?.description : 'No description'}</div>
              </div>
            </div>
          </div>
        ) : null}
        {job?.user_input?.indication_name_list?.length ? (
          <div className="synonyms-changes">
            <div className="synonyms-changes-title">Input Indications</div>
            <div className="synonyms-changes-content" style={{ display: 'flex' }}>
              <div className="added-synonyms">
                <div className="added-synonyms-list">{job?.user_input.indication_name_list ? tagsGroup(job?.user_input.indication_name_list) : 'No Inputs'}</div>
              </div>
            </div>
          </div>
        ) : null}

      </div>
    </div>
  );
}

ClinicalAnalysisWorkspaceDetails.propTypes = {
  job: PropTypes.objectOf.isRequired,
};

export default ClinicalAnalysisWorkspaceDetails;
