import React, { useEffect, useState } from 'react';
import Mixpanel from '../../../utils/mixpanel';

function Footer() {
  const [isBlack, setIsBlack] = useState(false);
  useEffect(() => {
    if (window.location.pathname === '/' || window.location.pathname === '/home') {
      setIsBlack(true);
    } else {
      setIsBlack(false);
    }
  }, [window.location.pathname]);
  return (
    <div className={`footer-main disp-flex vcenter ${isBlack ? 'footer-black' : ''}`} style={{ minHeight: 35 }}>
      <div className="container-fluid full-width">
        <div className="footer-links-content">
          <div className="logo-center">
            <div className="footer-option-container">
              <div className="option-text">Copyright © 2024 Partex</div>
              <div className="footer-links">
                <a
                  aria-label="innoplexus-logo"
                  label="innoplexus"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.innoplexus.com/"
                  className="footer-innoplexus-logo"
                  onClick={() => {
                    Mixpanel.track('Privacy Policy Page Visited', {
                      action: 'Privacy policy button clicked',
                      link: 'https://www.innoplexus.com/',
                    });
                  }}
                >
                  Privacy Policy
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
