const dummyData = [
  {
    _id: '0',
    terminal: 'metad3_ctp',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolor, Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolor',
    __v: 0,
    status: 'Active',
  },
  {
    _id: '1',
    terminal: 'metad3_biomarker',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolor, Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolor',
    __v: 0,
    status: 'Active',
  },
  {
    _id: '2',
    terminal: 'metad3_adc',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolor, Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolor',
    __v: 0,
    status: 'Active',
  },
  {
    _id: '3',
    terminal: 'metad3_dc',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolor, Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolor',
    __v: 0,
    status: 'Active',
  },
  {
    _id: '4',
    terminal: 'metad3_dr',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolor, Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolor',
    __v: 0,
    status: 'Active',
  },
  {
    _id: '5',
    terminal: 'metad3_iep',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolor, Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolor',
    __v: 0,
    status: 'active',
  },
  {
    _id: '6',
    terminal: 'metad3_protac',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolor, Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolor',
    __v: 0,
    status: 'Coming Soon',
  },
  {
    _id: '7',
    terminal: 'metad3_sar_denovo',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolor, Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolor',
    __v: 0,
    status: 'Coming Soon',
  },
  {
    _id: '8',
    terminal: 'metad3_target_id',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolor, Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolor',
    __v: 0,
    status: 'Coming Soon',
  },
];

export const appPermission = {
  metad3_ctp: 'Clinical Trial Prediction',
  metad3_biomarker: 'Biomarker Candidates',
  metad3_adc: 'ADC Design',
  metad3_dc: 'Drug Combination',
  metad3_target_id: 'Target ID',
  metad3_dr: 'Drug Repurposing',
  metad3_sar_denovo: 'SAR & Denovo',
  metad3_iep: 'Indication Prioritisation',
  metad3_protac: 'PROTAC Design',
};

export const terminalsPath = {
  metad3_ctp: '/meta-ctp/home',
  metad3_ctp_output: '/meta-ctp/output',
  metad3_ctp_workspace: '/meta-ctp/my-workspace',
  metad3_biomarker: '/biomarker/home',
  metad3_biomarker_output: '/biomarker/output',
  metad3_adc: '/adc/home',
  metad3_adc_output: '/adc/output',
  metad3_dc: '/dc/home',
  metad3_dc_output: '/dc/output',
  metad3_target_id: '/target-id/home',
  metad3_target_id_output: '/target-id/output',
  metad3_dr: '/dr/home',
  metad3_dr_output: '/dr/output',
  metad3_sar_denovo: '/sar/home',
  metad3_sar_denovo_output: '/sar/output',
  metad3_iep: '/iep/home',
  metad3_iep_output: '/iep/output',
  metad3_iep_workspace: '/iep/my-workspace',
  metad3_protac: '/protac/home',
  metad3_protac_output: '/protac/output',
};

export default dummyData;
