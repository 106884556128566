import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Divider } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import axios from 'axios';
import Rcookie from 'react-cookies';
import { terminalsPath } from '../../../../app/components/Card/data';
import { getCookie } from '../../../../utils';
import Mixpanel from '../../../../utils/mixpanel';
import useAppSelector from '../../../../utils/redux-hook';
import { getLcoTableInputDataAction } from './logic';
import Loader from '../../../../common/components/Loader';
import WorkspaceLCOInputDataTable from '../../components/WorkspaceLCOInputDataTable';

function LightChainOptimisationWorkspaceDetails({ job }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getLcoTableInputDataResponse = useAppSelector((state) => state.getLcoTableInputDataResponse);
  const userEmailId = getCookie('usersEmailId');
  const [tableData, setTableData] = useState(null);

  useEffect(() => {
    const request = {
      job_id: job?.job_id,
    };
    dispatch(getLcoTableInputDataAction(queryString.stringify(request)));
  }, [job]);

  useEffect(() => {
    if (getLcoTableInputDataResponse.flag && !getLcoTableInputDataResponse.loading) {
      setTableData(getLcoTableInputDataResponse?.data?.data);
    }
  }, [getLcoTableInputDataResponse]);

  const formatDate = (epoch) => {
    const date = new Date(epoch * 1000);

    const day = date.getDate();
    const month = date.toLocaleString('en-US', { month: 'short' });

    return `${day} ${month}`;
  };
  const formatTime = (epoch) => {
    const date = new Date(epoch * 1000);

    const time = date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });

    return time;
  };

  const downloadXLSFile = async (payload, extenstion) => {
    // setIsPendingDownload(true);
    const headers = { Authorization: Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`) ? Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`) : '', 'Content-Type': 'blob', 'Access-Control-Allow-Origin': `${process.env.applicationUrl}` };

    try {
      const response = await axios({
        method: 'GET',
        url: `${process.env.apiUrl_ae}/downloadOutput?job_id=${job.job_id}&download_type=${payload}`,
        responseType: 'json',
        headers,
      });
      //   setIsPendingDownload(false);
      const outputFilename = `${Date.now()}.${extenstion}`;
      // If you want to download file automatically using link attribute.
      const url = response?.data?.data?.signed_url;
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      throw Error(error);
    }
  };

  const onViewOutput = async (jobId) => {
    if (job?.status.toLowerCase() === 'completed') {
      navigate(`${terminalsPath.metad3_ae_output}?jobId=${job.job_id}`);
      Mixpanel.track('AE View Output Clicked', {
        action: 'AE View Output Clicked from AE workspace',
        job_name: job.job_name,
        job_id: jobId,
        service: 'Light Chain Optimisation',
        module: 'AE',
      });
    } else {
      navigate(terminalsPath.metad3_AE);
      Mixpanel.track('AE Resubmit Output Clicked', {
        action: 'AE Resubmit Output Clicked from AE workspace',
        job_name: job.job_name,
        job_id: jobId,
        service: 'Light Chain Optimisation',
        module: 'AE',
      });
    }
  };

/* eslint-disable */
  const eachApproachWeightage = (title, weightage) => {
    if (title === 'Target Information Details' && job?.user_input?.pdb_bucket_path) return  (
        <div className="each-approach-weightage">
          <div className="weightage-title">{title}</div>
          <div onClick={() => downloadXLSFile('TARGET_PDB','pdb')} className="weightage-value" style={{ color: '#3B76EF', cursor: 'pointer'}}>Target.pdb</div>
        </div>
      );;
    return (
      <div className="each-approach-weightage">
        <div className="weightage-title">{title}</div>
        <div className="weightage-value">{`${weightage}`}</div>
      </div>
    );
  };
  /* eslint-enable */

  const outputStatusItem = (title, value, payload) => (
    <div className="output-status-item" style={{ display: 'flex' }}>
      <div className="output-status-key">{title}</div>
      <div className={`output-status-value ${value === 'PENDING' ? 'pending' : value === 'REJECTED' ? 'failed' : 'completed'}`}>{value === 'PENDING' ? 'In Progress' : value === 'REJECTED' ? 'Failed' : 'Completed'}</div>
      <div role="presentation" onClick={() => downloadXLSFile(payload, 'xls')} className="download-icon" />
    </div>
  );

  return (
    <div className="job-details-main">
      {job?.status.toLowerCase() !== 'completed' && <div className={`job-pending-warning ${job?.status.toLowerCase() === 'pending' ? ' pending' : 'failed'}`}>{`${job?.status.toLowerCase() === 'pending' ? `Output generation is in progress. Once it is generated, we will notify you over your email, “${userEmailId}”` : 'Due to a technical error, the output generation failed. Please click the “Resubmit Input” button to try again.'}`}</div>}
      <div className="job-details-title">Input Details</div>
      <Loader loading={getLcoTableInputDataResponse.loading}>
        <div className="job-details-content" style={{ minHeight: 'calc(100vh - 180px)' }}>
          <div className="job-name-row" style={{ display: 'flex' }}>
            <div className="job-name-group" style={{ display: 'flex' }}>
              <div className="job-name-group-title">{job?.job_name}</div>
              <div className="job-created-at">
                {formatDate(job?.created_date_time_ts)}
                <Divider type="vertical" />
                {formatTime(job?.created_date_time_ts)}
              </div>
            </div>
            <div className="download-output-btn">
              <Button
                disabled={job?.status.toLowerCase() === 'pending'}
                onClick={() => {
                  if (job?.status.toLowerCase() === 'rejected') {
                    navigate(terminalsPath.metad3_ae);
                    Mixpanel.track('AE Resubmit Input Clicked', {
                      action: 'AE Resubmit input button from AE workspace clicked',
                      job_id: job?.job_id,
                      service: 'Light Chain Optimisation',
                      module: 'AE',
                    });
                  } else {
                    onViewOutput(job?.job_id);
                  }
                }}
              >
                {`${job?.status.toLowerCase() === 'rejected' ? 'Resubmit Input' : 'View Prioritised Sequences'}`}
              </Button>
            </div>
          </div>
          <div className="job-description-lower-part" style={{ display: 'flex' }}>
            <div className="description-column">
              {job?.description?.length ? (
                <div className="description-main">
                  <div className="synonyms-changes">
                    <div className="synonyms-changes-title">Description</div>
                    <div className="synonyms-changes-content" style={{ display: 'flex' }}>
                      <div className="added-synonyms">
                        <div className="added-synonyms-list">{job?.description ? job?.description : 'No description'}</div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="approach-weightage">
                <div className="approach-weightage-title">Input sequence file details</div>
                <div className="approach-weightage-row" style={{ display: 'flex' }}>
                  {eachApproachWeightage('Input Sequences', job?.user_input?.input_count)}
                  {eachApproachWeightage('Light chain Sequences', 'Provided')}
                  {eachApproachWeightage('Heavy chain Sequences', 'Provided')}
                  {eachApproachWeightage('Target Information Details', 'Provided')}
                </div>
              </div>
            </div>
            <div className="output-status-column">
              <div className="output-status-main">
                <div className="output-status-title">Output Status</div>
                <div className="output-status-content">
                  {outputStatusItem('Sequence generation & AI scoring:', 'Completed', 'SEQ_SCORE')}
                  {outputStatusItem('AlphaFold2:', 'Completed', 'ALPHAFOLD')}
                  {outputStatusItem('Ab-Ag docking & docking analysis:', 'Completed', 'DOCKING')}
                  {outputStatusItem('Binding free energy calculation:', 'Completed', 'BINDING_ENERGY')}
                </div>
                <div className="output-status-note">
                  <div className="output-status-note-content">
                    <span className="output-status-note-title">#Note: </span>
                    We will notify you when the process is complete. Queued jobs start after the previous step finishes. Once completed, the output file will be available for download.
                  </div>
                </div>
              </div>
            </div>
          </div>
          {tableData && <WorkspaceLCOInputDataTable tableData={tableData} />}
        </div>
      </Loader>
    </div>
  );
}

LightChainOptimisationWorkspaceDetails.propTypes = {
  job: PropTypes.objectOf.isRequired,
};

export default LightChainOptimisationWorkspaceDetails;
