import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import Rcookie from 'react-cookies';
import axios from 'axios';
import Mixpanel from '../../../../utils/mixpanel';
import { terminalsPath } from '../../../../app/components/Card/data';
import useAppSelector from '../../../../utils/redux-hook';
import Header from '../../../../common/components/Header';
import Loader from '../../../../common/components/Loader';
import OncoTaggingTable from '../../components/OncoTaggingTable';
import { getMoreOncoTaggingOutputAction, getOncoTaggingOutputAction } from './logic';

function OncoTaggingOutputPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const oncoTaggingOutputResponse = useAppSelector((state) => state.getOncoTaggingOutputResponse);
  const [searchParams] = useSearchParams();
  const inputText = searchParams.get('inputText');
  const [currentPage, setCurrentPage] = useState(1);
  const [isEmptyList, setIsEmptyList] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [isPendingDownload, setIsPendingDownload] = useState(false);
  //   const [isPendingZipDownload, setIsPendingZipDownload] = useState(false);

  const loadMoreTableData = () => {
    const request = {
      input_text: inputText,
      page_size: 10,
      page_number: currentPage + 1,
    };
    setCurrentPage(currentPage + 1);
    dispatch(getMoreOncoTaggingOutputAction(queryString.stringify(request)));
  };

  useEffect(() => {
    const request = {
      input_text: inputText,
      page_size: 10,
      page_number: 1,
    };
    setTimeout(() => {
      dispatch(getOncoTaggingOutputAction(queryString.stringify(request)));
    }, 50);
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (oncoTaggingOutputResponse?.flag && oncoTaggingOutputResponse?.data) {
      // console.log('oncoTaggingOutputResponse', oncoTaggingOutputResponse?.data?.data);
      setTableData(oncoTaggingOutputResponse?.data?.data);
      setIsEmptyList(oncoTaggingOutputResponse.data?.data?.length === 0);
    }
  }, [JSON.stringify(oncoTaggingOutputResponse)]);

  const handleBack = () => {
    navigate(`${terminalsPath.metad3_utilities}?inputText=${inputText}&page_size=10&page_number=1`);
  };
  const downloadXLSFile = async ({ isZip }) => {
    if (isZip) {
    //   setIsPendingZipDownload(true);
    } else {
      setIsPendingDownload(true);
    }
    // // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = {
      Authorization: Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`) ? Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`) : '', 'Content-Type': 'blob', 'Access-Control-Allow-Origin': `${process.env.applicationUrl}`, Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    };
    const apiUrl = isZip ? `${process.env.apiUrl_biomarker}/jobs/zip/` : `${process.env.apiUrl}/v1/utilities/getOntologyTags?input_text=${inputText}`;

    try {
      const response = await axios({
        method: 'POST',
        url: apiUrl,
        responseType: 'arraybuffer',
        headers,
      });
      setIsPendingDownload(false);
      //   setIsPendingZipDownload(false);
      const outputFilename = isZip ? `${Date.now()}.zip` : `${Date.now()}.xls`;

      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      throw Error(error);
    }
  };

  return (
    <div className="main-page main-page-modules">
      <Header className="header-home adc-header" logo="utilities-logo" width={500} />
      <Loader loading={oncoTaggingOutputResponse.loading && (isEmptyList ?? false)} error={oncoTaggingOutputResponse.error} width="100%" height="1000px">
        <div className="ct-home-page util-home-page">
          <div className="main-heading">
            <div className="back-btn">
              <Button className="back-btn-icon" onClick={handleBack}>
                Back
              </Button>
            </div>
            <div>
              <div className="main-title">Ontology Tagging Output</div>
              <div className="download-btns">
                <div className="main-download-btn">
                  <Button
                    onClick={() => {
                      downloadXLSFile({ isZip: false });
                      Mixpanel.track('Ontology Tagging Output Downloaded', {
                        action: 'Ontology Tagging Download output button clicked',
                        service: 'Ontology Tagging Output',
                        module: 'Utilites',
                      });
                    }}
                    disabled={isPendingDownload}
                  >
                    {`${isPendingDownload ? 'Downloading...' : 'Download List'}`}
                  </Button>
                </div>
              </div>
            </div>
            {/* <div className="main-description">Volcano Plot, Heatmap and List for DEGs in</div> */}
          </div>
          <div className="datatable-container">
            <OncoTaggingTable data={tableData} outputResponse={oncoTaggingOutputResponse} loadMoreData={loadMoreTableData} />
          </div>
        </div>
      </Loader>
    </div>
  );
}

export default OncoTaggingOutputPage;
