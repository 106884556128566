import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../utils';

// get Output details
const GET_SAR_OUTPUT = 'GET_SAR_OUTPUT';
const GET_SAR_OUTPUT_SUCCESS = 'GET_SAR_OUTPUT_SUCCESS';
const GET_MORE_SAR_OUTPUT = 'GET_MORE_SAR_OUTPUT';
const GET_MORE_SAR_OUTPUT_SUCCESS = 'GET_MORE_SAR_OUTPUT_SUCCESS';
const GET_SAR_OUTPUT_FAILURE = 'GET_SAR_OUTPUT_FAILURE';

export const getSAROutputAction = createAction(GET_SAR_OUTPUT);

const getSAROutputSuccess = createAction(GET_SAR_OUTPUT_SUCCESS);

export const getMoreSAROutputAction = createAction(GET_MORE_SAR_OUTPUT);

const getMoreSAROutputSuccess = createAction(GET_MORE_SAR_OUTPUT_SUCCESS);

const getSAROutputFaliure = createAction(GET_SAR_OUTPUT_FAILURE);

export const getSAROutputEpic = (actions$) => actions$.pipe(
  ofType(GET_SAR_OUTPUT),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_sar}/getLead?${action.payload}`, 'get')).pipe(
    map((res) => getSAROutputSuccess(res)),
    catchError((err) => of(getSAROutputFaliure(err))),
  )),
);

export const getMoreSAROutputEpic = (actions$) => actions$.pipe(
  ofType(GET_MORE_SAR_OUTPUT),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_sar}/getLead?${action.payload}`, 'get')).pipe(
    map((res) => getMoreSAROutputSuccess(res)),
    catchError((err) => of(getSAROutputFaliure(err))),
  )),
);
export const getSAROutputReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(getSAROutputAction, (state) => {
        state.loading = true;
      })
      .addCase(getSAROutputSuccess, (state, action) => {
        state.data = { ...action.payload.response };
        state.loading = false;
        state.flag = true;
      })
      .addCase(getMoreSAROutputAction, (state) => {
        state.error = false;
      })
      .addCase(getMoreSAROutputSuccess, (state, action) => {
        state.data = {
          total_count: action.payload.response.data.total_count,
          data: {
            ...state.data.data,
            data: { ...state.data.data.data, ...action.payload.response.data.data },
          },
        };
      })
      .addCase(getSAROutputFaliure, (state) => {
        state.error = true;
        state.flag = false;
        state.loading = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);
